<div class="create-enrollment-component-wrapper">
  <div class="container px-0">
    <div class="create-enrollment-box no-results" *ngIf="sumInsuredType === ''">
      <h6>Please select Sum Insured to save/edit details</h6>
    </div>
    <!-- <div class="create-enrollment-box no-results" *ngIf="sumInsuredType === 'SI'">
      <h6>Premium not applicable</h6>
    </div> -->

    <div class="create-enrollment-box" *ngIf="sumInsuredType !== '' && isLoading" class="spinner-center-wrapper">
      <div class="spinner-center"></div>
    </div>

    <div
      class="create-enrollment-box"
      [hidden]="
        sumInsuredType === '' ||
        sumInsuredType === 'PD' ||
        isLoading ||
        isExtraBenefitsApplicable === undefined ||
        !isExtraBenefitsApplicable
      "
    >
      <div class="list-component-wrapper" *ngIf="premiumDetails.isPremiumApplicable && !isLoading">
        <div class="row" *ngIf="relationshipType != '' && sumInsuredType === 'SI' && !isLoading">
          <div class="container">
            <div class="list-component-body">
              <table>
                <thead [hidden]="selfSiPremiumDetails.length === 0">
                  <tr>
                    <th>No of Members</th>
                    <th>Relationship</th>
                    <th>Age</th>
                    <th>Sum Insured</th>
                    <th *ngIf="selfSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined'">Grade</th>
                    <th>Loading</th>
                    <th>Premium Amount</th>
                    <th>Edit</th>
                    <th><input type="checkbox" id="checkAll" (click)="selectAll()" /></th>
                  </tr>
                </thead>
                <tbody
                  *ngIf="selfSiPremiumDetails.length > 0"
                  [hidden]="selfSiPremiumDetails.length === 0 && isLoading"
                >
                  <tr *ngFor="let si of selfSiPremiumDetails">
                    <td *ngIf="si.numberOfMembers !== -1">{{ si.numberOfMembers }}</td>
                    <td *ngIf="si.numberOfMembers === -1">All</td>
                    <td>{{ si.relationship }}</td>
                    <td *ngIf="si.minAge !== -1">{{ si.minAge }} - {{ si.maxAge }}</td>
                    <td *ngIf="si.minAge === -1">All</td>
                    <td>{{ si.sumInsured }}</td>
                    <td *ngIf="selfSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined'">
                      {{ si.grade }}
                    </td>
                    <td>{{ si.loading }}</td>
                    <td>{{ si.premiumAmount }}</td>
                    <td>
                      <div class="position-relative">
                        <button
                          type="button"
                          class="btn btn-link float-right dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="material-icons">more_vert</i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <button class="dropdown-item" (click)="addNewData(si)">Edit</button>
                        </div>
                      </div>
                    </td>
                    <td><input type="checkbox" name="chk" (click)="selectForDelete(si, $event)" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row" *ngIf="selfSiPremiumDetails.length > 0">
            <div class="col">
              <div class="mt-4">
                <button [disabled]="deleteList.length === 0" (click)="deletePremium()" class="btn btn-primary px-4">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="relationshipType != '' && sumInsuredType === 'ESI' && !isLoading">
          <div class="container">
            <div class="list-component-body">
              <table [hidden]="esiPremiumDetails.length === 0">
                <thead>
                  <tr>
                    <th>No of Members</th>
                    <th>Relationship</th>
                    <th>Age</th>
                    <th>Sum Insured</th>
                    <th
                      *ngIf="
                        enhancedSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined' ||
                        enhancedSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseMultiples' ||
                        (enhancedSumInsured.peExtraBenefits.sumInsuredType === 'EqualToSumInsured' &&
                          allowedSumInsured.selfSumInsuredType === 'GradeWiseDefined')
                      "
                    >
                      Grade
                    </th>
                    <th>Loading</th>
                    <th>Premium Amount</th>
                    <th>Edit</th>
                    <th><input type="checkbox" id="checkAll" (click)="selectAll()" /></th>
                  </tr>
                </thead>
                <tbody *ngIf="esiPremiumDetails.length > 0" [hidden]="esiPremiumDetails.length === 0 && isLoading">
                  <tr *ngFor="let esi of esiPremiumDetails">
                    <td *ngIf="esi.minAge !== -1">{{ esi.numberOfMembers }}</td>
                    <td *ngIf="esi.numberOfMembers === -1">All</td>
                    <td>{{ esi.relationship }}</td>
                    <td *ngIf="esi.minAge !== -1">{{ esi.minAge }} - {{ esi.maxAge }}</td>
                    <td *ngIf="esi.minAge === -1">All</td>
                    <td>{{ esi.sumInsured }}</td>
                    <td
                      *ngIf="
                        enhancedSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined' ||
                        enhancedSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseMultiples' ||
                        (enhancedSumInsured.peExtraBenefits.sumInsuredType === 'EqualToSumInsured' &&
                          allowedSumInsured.selfSumInsuredType === 'GradeWiseDefined')
                      "
                    >
                      {{ esi.grade }}
                    </td>
                    <td>{{ esi.loading }}</td>
                    <td>{{ esi.premiumAmount }}</td>
                    <td>
                      <div class="position-relative">
                        <button
                          type="button"
                          class="btn btn-link float-right dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="material-icons">more_vert</i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <button class="dropdown-item" (click)="addNewData(esi)">Edit</button>
                        </div>
                      </div>
                    </td>
                    <td><input type="checkbox" name="chk" (click)="selectForDelete(esi, $event)" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row" *ngIf="esiPremiumDetails.length > 0">
            <div class="col">
              <div class="mt-4">
                <button [disabled]="deleteList.length === 0" (click)="deletePremium()" class="btn btn-primary px-4">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="relationshipType != '' && sumInsuredType === 'PSI' && !isLoading">
          <div class="container">
            <div class="list-component-body">
              <table>
                <thead [hidden]="psiPremiumDetails.length === 0">
                  <tr>
                    <th>No of Members</th>
                    <th>Relationship</th>
                    <th>Age</th>
                    <th>Sum Insured</th>
                    <th
                      *ngIf="
                        parentalSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined' ||
                        parentalSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseMultiples' ||
                        (parentalSumInsured.peExtraBenefits.sumInsuredType === 'EqualToSumInsured' &&
                          allowedSumInsured.selfSumInsuredType === 'GradeWiseDefined')
                      "
                    >
                      Grade
                    </th>
                    <th>Loading</th>
                    <th>Premium Amount</th>
                    <th>Edit</th>
                    <th><input type="checkbox" id="checkAll" (click)="selectAll()" /></th>
                  </tr>
                </thead>
                <tbody *ngIf="psiPremiumDetails.length > 0" [hidden]="psiPremiumDetails.length === 0 && isLoading">
                  <tr *ngFor="let psi of psiPremiumDetails">
                    <td *ngIf="psi.numberOfMembers !== -1">{{ psi.numberOfMembers }}</td>
                    <td *ngIf="psi.numberOfMembers === -1">All</td>
                    <td>{{ psi.relationship }}</td>
                    <td *ngIf="psi.minAge !== -1">{{ psi.minAge }} - {{ psi.maxAge }}</td>
                    <td *ngIf="psi.minAge === -1">All</td>
                    <td>{{ psi.sumInsured }}</td>
                    <td
                      *ngIf="
                        parentalSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined' ||
                        parentalSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseMultiples' ||
                        (parentalSumInsured.peExtraBenefits.sumInsuredType === 'EqualToSumInsured' &&
                          allowedSumInsured.selfSumInsuredType === 'GradeWiseDefined')
                      "
                    >
                      {{ psi.grade }}
                    </td>
                    <td>{{ psi.loading }}</td>
                    <td>{{ psi.premiumAmount }}</td>
                    <td>
                      <div class="position-relative">
                        <button
                          type="button"
                          class="btn btn-link float-right dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="material-icons">more_vert</i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <button class="dropdown-item" (click)="addNewData(psi)">Edit</button>
                        </div>
                      </div>
                    </td>
                    <td><input type="checkbox" name="chk" (click)="selectForDelete(psi, $event)" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row" *ngIf="psiPremiumDetails.length > 0">
            <div class="col">
              <div class="mt-4">
                <button [disabled]="deleteList.length === 0" (click)="deletePremium()" class="btn btn-primary px-4">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="relationshipType != '' && sumInsuredType === 'TopUp' && !isLoading">
          <div class="container">
            <div class="list-component-body">
              <table>
                <thead [hidden]="topupPremiumDetails.length === 0">
                  <tr>
                    <th>No of Members</th>
                    <th>Relationship</th>
                    <th>Age</th>
                    <th>Sum Insured</th>
                    <th
                      *ngIf="
                        topupOption.peExtraBenefits.sumInsuredType === 'GradeWiseDefined' ||
                        topupOption.peExtraBenefits.sumInsuredType === 'GradeWiseMultiples' ||
                        (topupOption.peExtraBenefits.sumInsuredType === 'EqualToSumInsured' &&
                          allowedSumInsured.selfSumInsuredType === 'GradeWiseDefined')
                      "
                    >
                      Grade
                    </th>
                    <th>Loading</th>
                    <th>Premium Amount</th>
                    <th>Edit</th>
                    <th><input type="checkbox" id="checkAll" (click)="selectAll()" /></th>
                  </tr>
                </thead>
                <tbody *ngIf="topupPremiumDetails.length > 0" [hidden]="topupPremiumDetails.length === 0 && isLoading">
                  <tr *ngFor="let topup of topupPremiumDetails">
                    <td *ngIf="topup.numberOfMembers !== -1">{{ topup.numberOfMembers }}</td>
                    <td *ngIf="topup.numberOfMembers === -1">All</td>
                    <td>{{ topup.relationship }}</td>
                    <td *ngIf="topup.minAge !== -1">{{ topup.minAge }} - {{ topup.maxAge }}</td>
                    <td *ngIf="topup.minAge === -1">All</td>
                    <td>{{ topup.sumInsured }}</td>
                    <td
                      *ngIf="
                        topupOption.peExtraBenefits.sumInsuredType === 'GradeWiseDefined' ||
                        topupOption.peExtraBenefits.sumInsuredType === 'GradeWiseMultiples' ||
                        (topupOption.peExtraBenefits.sumInsuredType === 'EqualToSumInsured' &&
                          allowedSumInsured.selfSumInsuredType === 'GradeWiseDefined')
                      "
                    >
                      {{ topup.grade }}
                    </td>
                    <td>{{ topup.loading }}</td>
                    <td>{{ topup.premiumAmount }}</td>
                    <td>
                      <div class="position-relative">
                        <button
                          type="button"
                          class="btn btn-link float-right dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="material-icons">more_vert</i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <button class="dropdown-item" (click)="addNewData(topup)">Edit</button>
                        </div>
                      </div>
                    </td>
                    <td><input type="checkbox" name="chk" (click)="selectForDelete(topup, $event)" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row" *ngIf="topupPremiumDetails.length > 0">
            <div class="col">
              <div class="mt-4">
                <button [disabled]="deleteList.length === 0" (click)="deletePremium()" class="btn btn-primary px-4">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <button
          [hidden]="currentPremiumType === undefined || currentPremiumType === null || isLoading"
          (click)="addNewData('')"
          class="add-new-row-btn"
        >
          <i class="material-icons">add</i> <span>Add new</span>
        </button>
      </div>
      <div
        class="create-enrollment-box no-results"
        *ngIf="relationshipType === 'Self' && !premiumDetails.isPremiumApplicable && !isLoading"
      >
        <h6>Premium is not applicable for Self</h6>
      </div>
      <div
        class="create-enrollment-box no-results"
        *ngIf="relationshipType === 'Parent' && !premiumDetails.isPremiumApplicable && !isLoading"
      >
        <h6>Premium is not applicable for Parent</h6>
      </div>
      <div
        class="create-enrollment-box no-results"
        *ngIf="relationshipType === 'Parent-In-Law' && !premiumDetails.isPremiumApplicable && !isLoading"
      >
        <h6>Premium is not applicable for Parent in law</h6>
      </div>
    </div>

    <div
      class="create-enrollment-box"
      *ngIf="sumInsuredType !== '' && sumInsuredType !== 'PD' && !isLoading && !isExtraBenefitsApplicable"
    >
      <div class="create-enrollment-box no-results">
        <h6 *ngIf="sumInsuredType === 'SI'">Sum Insured is not allowed</h6>
        <h6 *ngIf="sumInsuredType === 'PSI'">Parental Sum Insured is not allowed</h6>
        <h6 *ngIf="sumInsuredType === 'ESI'">Enhanced Sum Insured is not allowed</h6>
        <h6 *ngIf="sumInsuredType === 'TopUp'">Topup Sum Insured is not allowed</h6>
      </div>
    </div>
  </div>
</div>
