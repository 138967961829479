<app-popup-model
  title="Create document"
  [isDisableSaveButton]="disableCreateButton"
  saveButton="Create document"
  (close)="close()"
  (save)="save()"
  [showLoader]="showLoader"
>
  <div class="note-msg">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="white" />
      <rect x="5.5" y="3.25" width="4.5" height="9" fill="#FF7D37" />
      <path
        d="M12.979 5.11371C12.4648 4.2328 11.7674 3.53533 10.8864 3.02116C10.0053 2.50702 9.0435 2.25 8.00018 2.25C6.95697 2.25 5.99484 2.50702 5.11391 3.02116C4.23292 3.53525 3.53544 4.23272 3.02124 5.11371C2.50702 5.99475 2.25 6.95687 2.25 8C2.25 9.04318 2.5071 10.0051 3.02116 10.8862C3.53534 11.7671 4.23281 12.4646 5.1138 12.9788C5.99485 13.493 6.95689 13.75 8.00008 13.75C9.04326 13.75 10.0054 13.493 10.8864 12.9788C11.7673 12.4648 12.4648 11.7671 12.9789 10.8862C13.493 10.0052 13.75 9.04313 13.75 8C13.75 6.95677 13.493 5.99462 12.979 5.11371ZM7.04181 3.6874C7.04181 3.61749 7.06423 3.56014 7.1092 3.51524C7.1541 3.47037 7.21155 3.44785 7.28141 3.44785H8.71901C8.78881 3.44785 8.84627 3.47037 8.89106 3.51524C8.93593 3.56014 8.95853 3.61749 8.95853 3.6874V4.88535C8.95853 4.95521 8.93593 5.01264 8.89106 5.05749C8.84627 5.10238 8.78884 5.12483 8.71901 5.12483H7.28141C7.21155 5.12483 7.15412 5.10238 7.1092 5.05749C7.06423 5.01267 7.04181 4.95521 7.04181 4.88535V3.6874ZM9.91678 11.5938C9.91678 11.6636 9.89415 11.721 9.84938 11.7658C9.8047 11.8108 9.74724 11.8332 9.67728 11.8332H6.32301C6.25307 11.8332 6.19572 11.8108 6.1508 11.7658C6.1059 11.721 6.08346 11.6636 6.08346 11.5937V10.3957C6.08346 10.3257 6.1059 10.2685 6.1508 10.2235C6.19572 10.1787 6.25315 10.1561 6.32301 10.1561H7.04181V7.76037H6.32301C6.25307 7.76037 6.19575 7.73795 6.1508 7.69303C6.1059 7.64816 6.08346 7.59073 6.08346 7.52081V6.32294C6.08346 6.25303 6.1059 6.19568 6.1508 6.15073C6.19577 6.10581 6.2532 6.08339 6.32301 6.08339H8.71901C8.78881 6.08339 8.84627 6.10581 8.89106 6.15073C8.93593 6.19568 8.95853 6.25303 8.95853 6.32294V10.1563H9.67709C9.74708 10.1563 9.80452 10.1787 9.84938 10.2236C9.89407 10.2685 9.91668 10.3259 9.91668 10.3958V11.5938H9.91678V11.5938Z"
        fill="white"
      />
    </svg>
    <span>Max 5 documents for each document type with 15mb size of each doc-type</span>
  </div>

  <div class="error-msg" *ngFor="let error of errorList">
    <p>{{ error.message }}</p>
  </div>
  <div class="form-group">
    <label>Document Name<sup>*</sup></label>
    <input
      required="true"
      type="text"
      (input)="isDocumentNameValid($event)"
      [(ngModel)]="documentName"
      class="form-control"
    />
    <p [hidden]="isNameValid" class="error-msg mb-0">
      <small>{{ invalidNameMsg }}</small>
    </p>
  </div>
  <div class="form-group">
    <label>Document Type<sup>*</sup></label>
    <select required="true" [(ngModel)]="documentType" (ngModelChange)="check()" class="form-control">
      <option>Claims</option
      >isDocumentTypeNameValid
      <option>Enrollment</option>
      <option>Policy</option>
      <option>Others</option>
    </select>
  </div>
  <!-- only open when Document Name is other -->
  <div class="form-group" *ngIf="documentType === 'Others'">
    <label>Document Type Name<sup>*</sup></label>
    <input
      required="false"
      type="text"
      (input)="isDocumentTypeNameValid($event)"
      [(ngModel)]="documentTypeName"
      class="form-control"
    />
    <p [hidden]="isTypeNameValid" class="error-msg mb-0">
      <small>{{ invalidTypeNameMsg }}</small>
    </p>
  </div>
  <!-- And a button called "Upload" will upload the document. Max 5 documents for
  each document type with 15mb size of each doc. File types allowed:
  Excel, PDF, Docs, JPG and PNG -->
  <div class="form-group">
    <button class="btn px-4 btn-sm mb-2 btn-primary" *ngIf="true" type="button" (click)="fileInput.click()">
      <span>Upload Document<sup>*</sup></span>
      <input
        #fileInput
        id="file"
        type="file"
        (change)="validateFile($event.target.files)"
        style="display:none;"
        accept=".xlsx,.jpg, .png, .doc, .docx,.pdf"
      />
    </button>
    <div class="position-relative" *ngIf="file !== ''">
      <input
        id="fileText"
        type="text"
        class="form-control"
        [(ngModel)]="fileName"
        [ngModelOptions]="{ standalone: true }"
        placeholder=""
        readonly
      />
    </div>

    <p [hidden]="isSizeValid" class="error-msg mb-0">
      <small>{{ uploadImageInvalidMsg }}</small>
    </p>
  </div>
  <!-- <div class="form-group">
    <label>Upload Document<sup>*</sup></label>
    <input
      required="true"
      type="file"
      accept=".xlsx,.jpg, .png, .doc, .docx,.pdf"
      (change)="validateFile($event.target.files)"
      class="form-control"
    />
    <p [hidden]="isSizeValid" class="error-msg mb-0">
      <small>{{ uploadImageInvalidMsg }}</small>
    </p>
  </div> -->
</app-popup-model>
