import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'src/app/shared/services/role.service';
import { BrokerService } from 'src/app/broker/service/broker.service';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EditNotificationComponent } from './edit-notification/edit-notification.component';
import { Guid } from 'src/app/shared/enums/guid.enum';
import { CreateNotificationsComponent } from './create-notifications/create-notifications.component';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { CPPolicyService } from '../../services/cp-policy.service';
import { Notification } from 'src/app/shared/models/notification/notification.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent extends BaseComponent implements OnInit {
  currentPolicy: Policy;
  policies = [];
  policyNumber: any;

  role: string;

  isHr: boolean;
  isMember: boolean;
  isHrView = false;
  isEmployeeBelongsToPolicy = false;

  notifications: Notification[] = [];
  isCommonNoificationSelected = false;
  isNotificationLoaded = false;

  notificationsCreatedByUser: Notification[] = [];
  isNotificationCreatedByUserLoaded = false;

  modalOption: NgbModalOptions = {};

  constructor(
    injector: Injector,
    private authService: AuthService,
    private cpMemberService: CPMemberService,
    private policyService: CPPolicyService,
    private readonly alertService: AlertService,
    private modalService: NgbModal,
    private roleService: RoleService,
    private brokerService: BrokerService
  ) {
    super(injector);
  }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  async ngOnInit() {
    this.role = this.authService.role;

    this.policyService.policies.subscribe(policies => {
      this.policies = policies;
    });

    this.cpMemberService.notifications.subscribe(x => {
      this.notificationsCreatedByUser = x;
    });

    this.cpMemberService.isHrView.subscribe(async isHr => {
      this.isHrView = isHr;
      this.isCommonNoificationSelected = false;
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.notifications = [];
      this.isNotificationLoaded = false;
      this.currentPolicy = policy;
      this.policyNumber = this.currentPolicy.policyNumber;

      // console.log('Get employee allowed type/role.');
      this.isHr = this.cpMemberService.isHr;
      this.isMember = this.cpMemberService.isMember;

      // console.log('Does employee belongs to current policy.');
      this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;
      this.isNotificationLoaded = false;
      this.isNotificationCreatedByUserLoaded = false;

      if (this.role === Roles.CPHR || this.role === Roles.CPMember) {
        this.getMemberHrRoleNotification();
      }
      if (this.role === Roles.Broker || (this.isHrView && this.role === Roles.CPHR) || this.role === Roles.SM) {
        this.getNotificationCreatedByUser();
      }
    });
  }

  getMemberHrRoleNotification() {
    if (this.policyNumber !== undefined && this.isMember && !this.isHrView && this.isEmployeeBelongsToPolicy) {
      this.cpMemberService
        .getMemberNotification(this.currentPolicy.policyId)
        .then(x => {
          this.notifications = x;
          this.isNotificationLoaded = true;
          this.UpdateMemberSeenNotification();
        })
        .catch(x => {
          console.log('error');
        });
    } else if (this.isHr && this.isHrView) {
      this.cpMemberService
        .getHrNotification()
        .then(x => {
          this.updateHRSeenNotification();
          this.notifications = x;
          this.isNotificationLoaded = true;
        })
        .catch(x => {
          console.log('error');
        });
    }
  }

  async getCommonNotification() {
    this.isCommonNoificationSelected = true;
    await this.cpMemberService
      .getMemberCommonNotification()
      .then(x => {
        this.notifications = x;
        this.isNotificationLoaded = true;
        this.UpdateMemberSeenCommonNotification();
      })
      .catch(x => {
        console.log('error');
      });
  }

  getNotificationCreatedByUser() {
    this.isNotificationCreatedByUserLoaded = false;
    this.cpMemberService
      .getNotificationCreatedByUser()
      .then(x => {
        this.notificationsCreatedByUser = x;
        this.isNotificationCreatedByUserLoaded = true;
      })
      .catch(x => {
        console.log('error');
      });
  }

  deleteNotfication(notification) {
    const id = notification.id;
    const name = notification.subject;
    let result;
    const message = `Are you sure you want to delete ${name} ?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        this.cpMemberService
          .deleteNotification(id)
          .then(r => {
            this.getNotificationCreatedByUser();
            this.alertService.success(`Notification ${name} deleted successfully.`);
          })
          .catch(error => {
            this.alertService.error('Error occured');
          });
      }
    });
  }

  editNotification(notification) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(EditNotificationComponent, this.modalOption);
    modalRef.componentInstance.notification = notification;
  }

  UpdateMemberSeenNotification() {
    this.cpMemberService
      .UpdateMemberSeenNotification(this.currentPolicy.policyId)
      .then(x => {})
      .catch(x => {
        console.log('error');
      });
  }

  UpdateMemberSeenCommonNotification() {
    this.cpMemberService
      .UpdateMemberSeenNotification(Guid.Default)
      .then(x => {})
      .catch(x => {
        console.log('error');
      });
  }

  updateHRSeenNotification() {
    this.cpMemberService
      .UpdateHrSeenNotification()
      .then(x => {})
      .catch(x => {
        console.log('error');
      });
  }

  open() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(CreateNotificationsComponent, this.modalOption);
    modalRef.componentInstance.role = this.role;
    modalRef.componentInstance.createdSucessfully.subscribe(isDocAdded => {
      if (isDocAdded === true) {
        this.getNotificationCreatedByUser();
      }
    });
  }

  onNamePolicyChanged(policy) {
    this.isCommonNoificationSelected = false;
    this.policyService.currentPolicy.next(policy);
  }
}
