import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CPDownloadDocument } from 'src/app/corporate-portal/models/cp-download-document.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import * as FileSaver from 'file-saver';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-view-uploaded-documents',
  templateUrl: './view-uploaded-documents.component.html',
  styleUrls: ['./view-uploaded-documents.component.scss']
})
export class ViewUploadedDocumentsComponent implements OnInit {

  @Input() trial: string;
  @Input() documents: Array<CPDownloadDocument> = [];
  title = "View All Uploaded Documents";
  saveButtonText = "Close";
  isDocumentLoading = false;
  downloadDocumentDetailId = '';
  
  constructor(private modalService: NgbModal,
    private cpMemberService: CPMemberService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    console.log(this.documents);
  }

  downloadDocument(document){
    this.isDocumentLoading = true;
    this.downloadDocumentDetailId = document.DocumentDetailId;
    console.log("The documentDetailId is "+ document.DocumentDetailId);
    this.cpMemberService.downloadUploadedDocuments(document.DocumentDetailId)
        .then(result => {
          const blob = this.dataURItoBlob(result.FileBytes);
          FileSaver.saveAs(blob, document.FileName);
          this.isDocumentLoading = false;
        })
        .catch(error => {
          this.alertService.error("Error occured!");
          this.isDocumentLoading = false;
        });
  }

  dataURItoBlob(base64str) {
    const dataURI = 'data:application/pdf;base64, ' + encodeURI(base64str);
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  close(){
    console.log(this.documents);
    this.modalService.dismissAll(ViewUploadedDocumentsComponent);
  }
}
