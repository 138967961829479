<div class="edit-member-component-wrapper">
  <!-- Spinner -->
  <div *ngIf="isLoading" class="spinner-center-wrapper">
    <div class="spinner-center"></div>
  </div>

  <div *ngIf="!isLoading && isPolicyStatusActive" class="card w-100 bg-white">
    <div class="card-body pt-0">
      <!-- <a class="link-go-back" routerLink="/member/enrollment"
        ><svg fill="none" height="11" viewBox="0 0 10 11" width="10" xmlns="http://www.w3.org/2000/svg">
          <path
            clip-rule="evenodd"
            d="M5.44212 1.56699C5.68619 1.32291 5.68619 0.927191 5.44212 0.683117C5.19804 0.439042 4.80232 0.439042 4.55824 0.683117L0.1833 5.05806C-0.0607745 5.30213 -0.0607745 5.69786 0.1833 5.94193L4.55824 10.3169C4.80232 10.561 5.19804 10.561 5.44212 10.3169C5.68619 10.0728 5.68619 9.67708 5.44212 9.433L2.1341 6.12498H9.37513C9.7203 6.12498 10.0001 5.84517 10.0001 5.49999C10.0001 5.15482 9.7203 4.875 9.37513 4.875H2.1341L5.44212 1.56699Z"
            fill-rule="evenodd"
          ></path>
        </svg>
        Back
      </a> -->
      <div class="card-title mb-4">
        <h4 class="m-0 header-title">{{ pageTitle }} Dependent</h4>
      </div>
      <div class="row">
        <div class="col">
          <table *ngIf="addError">
            <thead>
              <tr>
                <th>Error Message</th>
              </tr>
            </thead>
            <ng-container *ngFor="let row of addError">
              <tr *ngFor="let errorField of row.errors">
                <td class="error-msg">{{ errorField.message }}</td>
              </tr>
            </ng-container>
          </table>

          <div class="form">
            <dynamic-form
              [fields]="dependentFields"
              [dependent]="dependentPolicyModel.members[0]"
              (submit)="submit($event)"
              submitButtonName="Save Details"
              [submitButtonClicked]="submitButtonClicked"
            ></dynamic-form>
            
            <div class="margin-top">
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isfinalSubmissionLocked">
    <h3 class="no-permission-msg">Policy do not allow you to edit your profile</h3>
  </div>
  <div class="row" *ngIf="!isLoading && !isPolicyStatusActive">
    <h3 class="no-permission-msg">You cannot edit your Profile as period is not active</h3>
  </div>
</div>
