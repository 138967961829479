import { PreEnrollmentMemberPolicyModel } from '../model/preEnrollment-member-policy.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MemberValidationHelper {
  constructor() {}

  isProfileEditable(policy: PreEnrollmentPolicyModel) {
    if (policy === null) {
      return true;
    }

    return policy.allowEmployeeEditProfile;
  }

  isFinalSubmissionLocked(selfPolicyModel: PreEnrollmentMemberPolicyModel) {
    if (selfPolicyModel == null) {
      return true;
    }

    try {
      return selfPolicyModel.members[0].isFinalSubmissionLocked;
    } catch (e) {
      return true;
    }
  }
}
