<div class="create-enrollment-component-wrapper" *ngIf="initialized">
  <div class="container">
    <div class="error-msg" *ngFor="let error of errorR">
      <p>{{ error.message }}</p>
    </div>
    <div class="create-enrollment-box">
      <div class="enrollment-box-row">
        <div class="box-body">
          <h6>REMINDER EMAIL</h6>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [(ngModel)]="reminderEmailData.sendReminderEmail"
                      (ngModelChange)="sendReminderEmail()"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Send reminder email to the employees</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" [hidden]="!reminderEmailData.sendReminderEmail">
            <div class="col-md-12">
              <div class="form-group date-time-parent">
                <div [hidden]="!reminderEmailData.sendReminderEmail">
                  <table>
                    <thead>
                      <tr>
                        <th class="text-center">
                          <label>Reminder Email Send Date <sup>*</sup> </label>
                        </th>
                        <th class="text-center"><label>Hours</label></th>
                        <th class="text-center"><label>Minutes</label></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let reminderData of reminderEmailData.pEReminderDateTime; let i = index">
                        <td>
                          <div class="date-time-wrapper">
                            <input
                              value="{{ reminderData.ReminderEmailDate | date: 'dd MMMM yyyy' }}"
                              [(ngModel)]="reminderData.ReminderEmailDate"
                              type="text"
                              [minDate]="reminderEmailMinDate"
                              class="form-control"
                              readonly
                              bsDatepicker
                              (ngModelChange)="checkDateValidity(reminderData.ReminderEmailDate, i)"
                              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                            />
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="timepicker-wrapper">
                            <select
                              [(ngModel)]="reminderData.ReminderEmailHrs"
                              type="number"
                              class="form-control"
                              (input)="reminderData.checkHrsValidity()"
                            >
                              <option *ngFor="let hr of hrsOptions" [ngValue]="hr">{{ hr }}</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div class="timepicker-wrapper">
                            <select
                              [(ngModel)]="reminderData.ReminderEmailMin"
                              type="number"
                              class="form-control"
                              (input)="reminderData.checkMinsValidity()"
                            >
                              <option *ngFor="let min of reminderMinsOptions" [ngValue]="min">{{ min }}</option>
                            </select>
                          </div>
                        </td>
                        <td class="text-center">
                          <a
                            [hidden]="reminderEmailData.pEReminderDateTime.length === 1"
                            (click)="removeDateTime(i)"
                            class="text-secondary"
                            ><i class="material-icons">delete</i></a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button (click)="addNewDate()" class="add-new-row-btn">
                    <i class="material-icons">add</i> <span>Add new</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row" [hidden]="!reminderEmailData.sendReminderEmail">
            <div class="col-md-12">
              <form [formGroup]="form">
                <div class="form-group">
                  <label>Reminder Email Subject <sup>*</sup> </label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="reminderEmailData.reminderEmailSubject"
                    formControlName="reminderMessage"
                    (input)="isReminderSubjectValid($event)"
                  />
                  <span
                    class="error-msg small"
                    *ngIf="
                      form.get('reminderMessage').dirty &&
                      form.get('reminderMessage').status === 'INVALID' &&
                      reminderEmailSubjectLength
                    "
                    >Invalid Subject
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                  <span class="error-msg small" *ngIf="!reminderEmailSubjectLength">
                    {{ reminderEmailSubjectErrorMsg }}
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                </div>
              </form>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Reminder Email template <sup>*</sup></label>
                <editor
                  apiKey="65447p1khods6dtyql8kuu3wf9xhgw1hbekv2nj8xibdxfob"
                  initialValue="{{ reminderEmailData.reminderEmailTemplate }}"
                  [(ngModel)]="reminderEmailData.reminderEmailTemplate"
                  (ngModelChange)="checkSaveButtonDisplay()"
                  [init]="{
                    height: 340,
                    menubar: false,
                    plugins: ['table'],
                    toolbar:
                      'undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | forecolor backcolor  permanentpen  removeformat '
                  }"
                >
                </editor>
              </div>
            </div>
          </div>

          <div class="row pl-3" [hidden]="!reminderEmailData.sendReminderEmail">
            <div class="form-group">
              <button class="btn px-4 btn-sm mb-2 btn-primary" *ngIf="true" type="button" (click)="fileInput.click()">
                <span>Upload file</span>
                <input
                  #fileInput
                  id="file"
                  type="file"
                  (change)="onFileChange($event.target.files)"
                  style="display:none;"
                  accept=".xlsx,.jpg, .png, .doc, .docx,.pdf"
                />
              </button>
              <div *ngIf="fileName !== '' && fileName !== null && fileName !== undefined" class="position-relative">
                <label>File Name/Link</label>
                <input
                  id="fileText"
                  type="text"
                  class="form-control"
                  [(ngModel)]="fileName"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder=""
                  readonly
                />
                <button
                  class="btn btn-sm remove-file-wrapper"
                  [hidden]="file === '' || file === null || file === undefined"
                >
                  <i class="material-icons" (click)="deleteFile()">delete</i>
                </button>
                <button
                  type="submit"
                  (click)="downloadFile()"
                  class="btn btn-sm import-btn"
                  [hidden]="file === '' || file === null || file === undefined"
                >
                  <i class="material-icons">cloud_download</i>
                  <span>Download</span>
                </button>
              </div>

              <p [hidden]="isSizeValid" class="error-msg mb-0">
                <small>{{ uploadImageInvalidMsg }}</small>
              </p>
            </div>
          </div>

          <div class="row pl-3">
            <button (click)="submit()" [disabled]="!isSaveButtonEnable" class="btn btn-secondary">
              {{ displaySave }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
