import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from './shared/components/base.component';
import { AuthService } from './shared/services/auth.service';
import { BnNgIdleService } from 'bn-ng-idle';

declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnDestroy, OnInit {
  isLoadingg = false;
  constructor(injector: Injector, private authService: AuthService, private bnIdle: BnNgIdleService) {
    super(injector);
    this.bnIdle.startWatching(900).subscribe(res => {
      if (res) {
        console.log('session expired after 15 minutes of inactivity');
        this.authService.signout();
      }
    });
  }

  async ngOnInit() {
    super.ngOnInit();
    const path = window.location.pathname;
    const pathUrl = window.location.href;
    if (path.indexOf('/auth-callback') !== 0 && path.indexOf('/silent-refresh.html') !== 0) {
      this.authService.isLoggedIn.subscribe(l => {
        this.isLoadingg = !l;
      });

      let loginType =
        path.indexOf('/biz-admin') === 0 || path.indexOf('/operation') === 0 || path.indexOf('/sm') === 0
          ? 'Internal'
          : 'External';

      const sso = window.location.search.indexOf('sso');
      if (sso >= 0) {
        loginType = 'SSO';
      }
      await this.authService.init(loginType).then(x => {
        window.sessionStorage.setItem('loginType', loginType);
        let role = '';
        if (path.indexOf('/biz-admin') === 0) {
          role = 'BusinessAdmin';
        } else if (path.indexOf('/operation') === 0 || path.indexOf('/Operation') === 0) {
          role = 'Operation';
        } else if (path.indexOf('/broker') === 0 || path.indexOf('/Broker') === 0) {
          role = 'Broker';
        } else if (path.indexOf('/sm') === 0 || path.indexOf('/SM') === 0) {
          role = 'SM';
        } else if (path.indexOf('/member') === 0 || pathUrl === 'https://preenroll.reliancegeneral.co.in/') {
          role = 'Member';
        } //if (
        // path.indexOf('/cp-member') === 0 ||
        //pathUrl === 'https://customer.reliancegeneral.co.in/' ||
        //pathUrl === 'https://customer.reliancegeneral.co.in/member'
        //)
        else {
          role = 'CPMember';
        }

        window.sessionStorage.setItem('role', role);
        window.localStorage.setItem('status', 'login');
        this.authService.isLoggedIn.subscribe(l => {
          this.isLoadingg = !l;
        });
      });
    } else {
      // once init of auth service is done will come here again as authorisation server will redirect to auth callback
      this.isLoadingg = false;
      await this.authService.init().then(x => {});
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
