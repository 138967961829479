import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { BehaviorSubject } from 'rxjs';
import { CorporatePolicyModel } from '../models/corporate-policy.model';
import { Policy } from 'src/app/cp-member/models/policy.model';

@Injectable({
  providedIn: 'root',
})
export class CPPolicyService {
  cpmemberbaseapi = 'CorporatePortal';
  allPolicies: BehaviorSubject<any[]>;
  policies: BehaviorSubject<any[]>;
  currentPolicy: BehaviorSubject<any>;
  intimationType: BehaviorSubject<any>;

  corporates: CorporatePolicyModel[] = [];
  currentCorporate: BehaviorSubject<any>;

  constructor(private readonly backendService: BackendService) {
    this.currentCorporate = new BehaviorSubject<any>([]);
    this.allPolicies = new BehaviorSubject<any[]>([]);
    this.policies = new BehaviorSubject<any[]>([]);
    this.currentPolicy = new BehaviorSubject<any>({});
    this.intimationType =new BehaviorSubject<any>({});
  }

  async getMemberHrPolicies(): Promise<any> {
    return new Promise(async resolve => {
      if (this.allPolicies.value.length > 0) {
        resolve(this.allPolicies.value);
      }
      this.backendService.get(this.cpmemberbaseapi + '/member/policies').then(result => {
        this.corporates = result;
        this.currentCorporate.next(this.corporates[0]);
        // this.allPolicies.next(this.currentCorporate.value.policies);
        // this.policies.next(this.currentCorporate.value.policies);
        const policies = this.currentCorporate.value.policies;
        const sortedPolicies = this.sortPolicies(policies);
        this.policies.next(sortedPolicies);
        this.allPolicies.next(sortedPolicies);

        if (this.policies.value.length > 0) {
          this.currentPolicy.next(this.policies.value[0]);
        }

        resolve(result);
      });
    });
  }

  sortPolicies(policies) {
    policies.sort(function compare(a, b) {
      const dateA = new Date(a.riskStartDate);
      const dateB = new Date(b.riskStartDate);
      return <any>dateB - <any>dateA;
    });
    policies.sort(policy => {
      return policy.isActive === false ? 0 : policy ? -1 : 1;
    });
    const activePolicyArray = [];
    const inactivePolicyArray = [];
    policies.forEach(policy => {
      if (policy.isActive) {
        activePolicyArray.push(policy);
      } else {
        inactivePolicyArray.push(policy);
      }
    });
    activePolicyArray.reverse();
    policies = [];
    policies = [...activePolicyArray, ...inactivePolicyArray];

    return policies;
  }

  async getBrokerCorporatesAndPolicies(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.corporates.length > 0) {
        resolve(this.corporates);
      }
      this.backendService.get('Broker/CorporateAndPolicy').then(
        result => {
          this.corporates = result;
          this.currentCorporate.next(this.corporates[0]);
          const policies = this.currentCorporate.value.policies;
          const sortedPolicies = this.sortPolicies(policies);
          this.allPolicies.next(sortedPolicies);
          this.policies.next(sortedPolicies);
          if (this.policies.value.length > 0) {
            this.currentPolicy.next(this.policies.value[0]);
          }
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  setEmptyPolicy() {
    //   this.currentPolicy.next(new Policy());
  }

  async getSMCorporatesAndPolicies(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.corporates.length > 0) {
        resolve(this.corporates);
      }
      this.backendService.get('SM/CorporateAndPolicy').then(
        result => {
          this.corporates = result;
          this.currentCorporate.next(this.corporates[0]);
          const policies = this.currentCorporate.value.policies;
          const sortedPolicies = this.sortPolicies(policies);
          this.allPolicies.next(sortedPolicies);
          this.policies.next(sortedPolicies);
          if (this.policies.value.length > 0) {
            this.currentPolicy.next(this.policies.value[0]);
          }
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async getBrokerPolicies(): Promise<any> {
    if (this.allPolicies.value.length > 0) {
      return new Promise(async (resolve, reject) => {
        resolve(this.allPolicies.value);
      });
    }
    return new Promise(async (resolve, reject) => {
      await this.backendService.get('Broker/policy').then(
        resolv => {
          this.allPolicies.next(resolv);
          this.policies.next(resolv);
          this.currentPolicy.next(resolv[0]);
          return resolve(resolv);
        },
        error => {
          return reject(error);
        }
      );
    });
  }


  async getPrepostAndAddendum(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.post('/CorporatePortal/isprepostapplicable/', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getClaimDetails(data): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.post('CorporatePortal/getclaimdetailsprepost', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
  public async submitClaimDetails(data): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.post('CorporatePortal/submitprepost', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  sendGPAMail(data,policyNumber): Promise<any> {
    return new Promise(async(resolve,reject) =>{
      this.backendService.post('CorporatePortal/'+policyNumber+'/GPA/', data).then(
        Response =>{
          return resolve(Response);
        },
        error => {
          return reject(error);
        }
      )
    })
  }
  public async getAllAttendance(data): Promise<any> {
    return new Promise(async resolve => {
      const attendance = await this.backendService.post('CorporatePortal/GetAttendance',data);
      resolve(attendance);
    });
  }
}
