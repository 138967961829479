<div class="row">
  <div class="col-12 mb-3">
    <a class="link-go-back" (click)="back()"
      ><svg fill="none" height="11" viewBox="0 0 10 11" width="10" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M5.44212 1.56699C5.68619 1.32291 5.68619 0.927191 5.44212 0.683117C5.19804 0.439042 4.80232 0.439042 4.55824 0.683117L0.1833 5.05806C-0.0607745 5.30213 -0.0607745 5.69786 0.1833 5.94193L4.55824 10.3169C4.80232 10.561 5.19804 10.561 5.44212 10.3169C5.68619 10.0728 5.68619 9.67708 5.44212 9.433L2.1341 6.12498H9.37513C9.7203 6.12498 10.0001 5.84517 10.0001 5.49999C10.0001 5.15482 9.7203 4.875 9.37513 4.875H2.1341L5.44212 1.56699Z"
          fill-rule="evenodd"
        ></path>
      </svg>
      Back
    </a>
  </div>
  <div class="col-12">
    <div class="form-group">
      <div class="custom-checkbox">
        <label class="pb-0"
          ><input
            type="checkbox"
            [(ngModel)]="user.reportAllowed"
            (change)="checkCheckboxesValidity()"
            class="custom-control-input"
          /><span>Reports</span></label
        >
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="user.reportAllowed">
    <div class="form-group d-flex flex-wrap">
      <div class="custom-checkbox mr-3">
        <label class="pb-0"
          ><input
            type="checkbox"
            [(ngModel)]="user.cashlessReport"
            (change)="checkCheckboxesValidity()"
            class="custom-control-input"
          /><span>Cashless report</span></label
        >
      </div>

      <div class="custom-checkbox mr-3">
        <label class="pb-0"
          ><input
            type="checkbox"
            [(ngModel)]="user.reimbersementReport"
            (change)="checkCheckboxesValidity()"
            class="custom-control-input"
          /><span>Reimbersement Report</span></label
        >
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="form-group">
      <div class="custom-checkbox">
        <label class="pb-0"
          ><input
            type="checkbox"
            [(ngModel)]="user.endoresment"
            (change)="checkCheckboxesValidity()"
            class="custom-control-input"
          /><span>Endorsement</span></label
        >
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="form-group">
      <div class="custom-checkbox">
        <label class="pb-0"
          ><input
            type="checkbox"
            [(ngModel)]="user.dashboard"
            (change)="checkCheckboxesValidity()"
            class="custom-control-input"
          /><span>Dashboard</span></label
        >
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="user.dashboard">
    <div class="form-group d-flex flex-wrap">
      <div class="custom-checkbox mr-3">
        <label class="pb-0"
          ><input
            type="checkbox"
            [(ngModel)]="user.staticDashboard"
            (change)="checkCheckboxesValidity()"
            class="custom-control-input"
          /><span>Static(documents)</span></label
        >
      </div>

      <!-- <div class="custom-checkbox mr-3">
        <label class="pb-0"
          ><input
            type="checkbox"
            [(ngModel)]="user.dyanamicDashboard"
            (change)="checkCheckboxesValidity()"
            class="custom-control-input"
          /><span>Dynamic(documents)</span></label
        >
      </div> -->
    </div>
  </div>
</div>
