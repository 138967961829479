<div class="member-component-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="card w-100">
            <div class="card-body">
              <app-policies-dropdown heading="Profile" class="w-100"> </app-policies-dropdown>
              <div class="row">
                <div class="col-12">
                  <ul class="list-unstyled shimmer-wrapper" *ngIf="!isProfileLoaded">
                    <li class="shimmer"></li>
                    <li class="shimmer"></li>
                    <li class="shimmer"></li>
                  </ul>
                  <ul class="inner-cards profile-details" *ngIf="isProfileLoaded">
                    <li>
                      <span class="field-name mr-4">Name:</span>
                      <span class="field-data">{{ broker.name }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-4">Mobile:</span>
                      <span class="field-data">{{ broker.mobileNo }}</span>
                    </li>
                    <li class="w-100">
                      <span class="field-name mr-4">Email:</span>
                      <span class="field-data">{{ broker.email }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- end row -->
            </div>
          </div>
          <!-- end card -->
          <!-- <div class="card w-100" *ngIf="isHr && isHrView">
              <div class="card-body depedent-details">
                <div class="card-title row justify-content-between align-items-center">
                  <h4 class="m-0 header-title">
                    Policy Information
                  </h4>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ul class="list-unstyled shimmer-wrapper" *ngIf="!isPolicyInfoLoaded">
                      <li class="shimmer"></li>
                      <li class="shimmer"></li>
                      <li class="shimmer"></li>
                      <li class="shimmer"></li>
                      <li class="shimmer"></li>
                      <li class="shimmer"></li>
                    </ul>
                    <ul class="policy-infocards" *ngIf="isPolicyInfoLoaded">
                      <li>
                        <span class="field-data">
                          Active Insured
                          <strong>{{ policyInfo.activeInsured }} </strong>
                        </span>
                      </li>
                      <li>
                        <span class="field-data">
                          Total Endorsements
                          <strong>{{ policyInfo.totalEndorsements }} </strong>
                        </span>
                      </li>
                      <li>
                        <span class="field-data">
                          Cashless Claims
                          <strong>{{ policyInfo.cashlessClaims }} </strong>
                        </span>
                      </li>
                      <li>
                        <span class="field-data">
                          Reimbursement Claims
                          <strong>{{ policyInfo.reimbursementClaims }} </strong>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> -->
          <!-- end card -->
        </div>
      </div>
    </div>
  </div>
</div>
