import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { PreEnrollmentMemberService } from '../../services/preEnrollment-member.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { DynamicFormComponent } from 'src/app/dyanamic-fields-form/components/dynamic-form/dynamic-form.component';
import { Validators } from '@angular/forms';
import { FieldConfig, Validator } from 'src/app/dyanamic-fields-form/model/form-fields';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent extends BaseComponent implements OnInit, OnDestroy {
  selfMember: any;
  policy: PreEnrollmentPolicyModel;
  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
  regConfig: FieldConfig[] = [];
  errorR: any;
  isLoading = true;
  isPolicyStatusActive = false;

  constructor(
    injector: Injector,
    private readonly preEnrollmentMemberService: PreEnrollmentMemberService,
    private readonly alertService: AlertService,
    private readonly router: Router
  ) {
    super(injector);
  }
  async ngOnInit() {
    super.ngOnInit();

    this.policy = this.preEnrollmentMemberService.policy;
    if (this.policy === null) {
      this.policy = await this.preEnrollmentMemberService.getPolicy();
    }

    if (this.preEnrollmentMemberService.editProfileFields === null) {
      this.regConfig = await this.preEnrollmentMemberService.getMemberProfile();
    } else {
      this.regConfig = this.preEnrollmentMemberService.editProfileFields;
    }
    this.isPolicyStatusActive = this.policy.period.status;
    // this.preEnrollmentMemberService.selfMember.next(this.regConfig);
    this.isLoading = false;

    this.regConfig.forEach(x => {
      // we do not need section, so replace them
      x.section = 'Edit Profile';
    });
  }

  async submit(value: any) {
    const data = value.getRawValue();
    this.preEnrollmentMemberService
      .updateMember(this.policy.id, data)
      .then(x => {
        this.preEnrollmentMemberService.memberUpdate.next(true);
        this.router.navigate(['/member/home']);
        this.alert.success('Member profile updated successfully.');
      })
      .catch(errorData => {
        this.errorR = errorData;
        this.alertService.error('Error occured');
      });
  }

  toJson(str) {
    return JSON.parse(str);
  }
}
