import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { PreEnrollmentService } from 'src/app/business-admin/services/preEnrollment.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { NoteMsgDialogModel } from 'src/app/shared/components/note-msg/note-msg-dialog-model.model';
import { NoteMsgComponent } from 'src/app/shared/components/note-msg/note-msg.component';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { PremiumDetails } from 'src/app/shared/models/preEnrollment/premium/premium-details.model';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-premium-details',
  templateUrl: './premium-details.component.html',
  styleUrls: ['./premium-details.component.scss'],
})
export class PremiumDetailsComponent extends BaseComponent implements OnInit {
  @Input() relationshipType: string;
  @Input() sumInsuredType = '';
  @Input() policy: PreEnrollmentPolicyModel;
  @Input() corporate: CorporateModel;
  @Output() public cancelConfigurePremium = new EventEmitter<boolean>();
  currentPremiumType: string;

  constructor(
    injector: Injector,
    private preEnrollmentService: PreEnrollmentService,
    private alertService: AlertService
  ) {
    super(injector);
  }

  isLoading: boolean;
  premiumDetails: PremiumDetails = new PremiumDetails();
  psiPremiumLength: number;
  esiPremiumLength: number;
  topupPremiumLength: number;
  siPremiumLength: number;

  isPSIApplicable: boolean;
  isESIApplicable: boolean;
  isTopupApplicable: boolean;
  isSIApplicable: boolean;

  isEdit = true;

  async ngOnInit() {
    console.log(this.relationshipType);
    console.log(this.sumInsuredType);
  }

  async ngOnChanges() {
    this.isLoading = true;
    await this.getPSIExtraBenefits();
    await this.getESIExtraBenefits();
    await this.getTopupExtraBenefits();
    if (this.relationshipType === 'Self') {
      await this.getSIExtraBenefits();
    }
    if (this.isESIApplicable || this.isPSIApplicable || this.isTopupApplicable || this.isSIApplicable) {
      await this.getPremiumDetails();
    } else {
      this.isLoading = false;
    }
  }

  getPremiumDetails() {
    let rship;
    if (this.relationshipType === 'Self') {
      rship = 'self';
    } else if (this.relationshipType === 'Parent') {
      rship = 'parent';
    } else if (this.relationshipType === 'Parent-In-Law') {
      rship = 'parentinlaw';
    }
    this.preEnrollmentService
      .getPremiumDetails(this.policy.id, rship)
      .then(x => {
        console.log(x);
        if (x !== null) {
          this.premiumDetails = x;
        } else {
          this.premiumDetails.isPremiumApplicable = false;
          this.premiumDetails.isPremiumVisible = false;
          this.premiumDetails.isRefundApplicable = false;
          this.premiumDetails.premiumType = null;
        }
        this.currentPremiumType = this.premiumDetails.premiumType !== null ? this.premiumDetails.premiumType : null;
        this.disablePremiumUI();
        this.isLoading = false;
      })
      .catch(error => {
        console.log('Error while fetching premium details', error);
      });
  }

  displayAlertText(premiumType, event) {
    let premiumLength;
    if (this.relationshipType === 'Self' && (this.esiPremiumLength > 0 || this.topupPremiumLength > 0)) {
      premiumLength = true;
    } else if (
      this.relationshipType !== 'Self' &&
      (this.psiPremiumLength > 0 || this.esiPremiumLength > 0 || this.topupPremiumLength > 0)
    ) {
      premiumLength = true;
    } else {
      premiumLength = false;
    }

    if (this.currentPremiumType !== undefined && this.currentPremiumType !== premiumType) {
      if (this.sumInsuredType === 'PD' && premiumLength) {
        this.setInputValue(event);
        this.premiumDetails.premiumType = this.currentPremiumType;
        this.openAlertBox();
      }
    }
  }

  checkPremiumsAdded() {
    if (!this.premiumDetails.isPremiumApplicable) {
      let premiumLength;
      if (this.relationshipType === 'Self' && (this.esiPremiumLength > 0 || this.topupPremiumLength > 0)) {
        premiumLength = true;
      } else if (
        this.relationshipType !== 'Self' &&
        (this.psiPremiumLength > 0 || this.esiPremiumLength > 0 || this.topupPremiumLength > 0)
      ) {
        premiumLength = true;
      } else {
        premiumLength = false;
      }

      if (this.sumInsuredType === 'PD' && premiumLength) {
        this.premiumDetails.isPremiumApplicable = true;
        const ele1 = document.getElementById('premiumApplicable') as HTMLInputElement;
        ele1.checked = true;
        this.openAlertBox();
      }
    }
  }

  setInputValue(event) {
    if (event.target.value === 'Selective') {
      const ele1 = document.getElementById('inputSelective') as HTMLInputElement;
      ele1.checked = false;
      const ele2 = document.getElementById('inputFlat') as HTMLInputElement;
      ele2.checked = true;
    } else if (event.target.value === 'Flat') {
      const ele1 = document.getElementById('inputSelective') as HTMLInputElement;
      ele1.checked = true;
      const ele2 = document.getElementById('inputFlat') as HTMLInputElement;
      ele2.checked = false;
    }
  }

  openAlertBox() {
    const message = `You can only change the Premium type after deleting all the details`;
    const dialogData = new NoteMsgDialogModel('Alert', message);
    const dialogRef = this.dialog.open(NoteMsgComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  savePremiumDetails() {
    if (this.premiumDetails.isPremiumApplicable === false) {
      this.premiumDetails.isPremiumVisible = false;
      this.premiumDetails.isRefundApplicable = false;
      this.premiumDetails.premiumType = null;
    }
    this.preEnrollmentService
      .updatePremiumDetails(this.policy.id, this.relationshipType, this.premiumDetails)
      .then(x => {
        console.log(x);
        this.alertService.success('Details saved successfully');
        this.getPremiumDetails();
        this.disablePremiumUI();
      })
      .catch(error => {
        this.alertService.error('Error occured');
      });
  }

  enableEdit() {
    this.isEdit = true;
    document.getElementById('premiumDetails1').removeAttribute('disabled');
    document.getElementById('premiumDetails2').removeAttribute('disabled');
    document.getElementById('premiumDetails3').removeAttribute('disabled');
  }

  disablePremiumUI() {
    if (this.premiumDetails.isPremiumApplicable === true) {
      this.isEdit = false;
      document.getElementById('premiumDetails1').setAttribute('disabled', 'disabled');
      document.getElementById('premiumDetails2').setAttribute('disabled', 'disabled');
      document.getElementById('premiumDetails3').setAttribute('disabled', 'disabled');
    } else {
      this.isEdit = true;
      document.getElementById('premiumDetails1').removeAttribute('disabled');
      document.getElementById('premiumDetails2').removeAttribute('disabled');
      document.getElementById('premiumDetails3').removeAttribute('disabled');
    }
  }

  async getSIExtraBenefits() {
    await this.preEnrollmentService
      .getSIextraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.isSIApplicable = result.peExtraBenefits.isAllowed;
          this.getSelfSIPremium(result.peExtraBenefits.id);
        }
      })
      .catch(error => {
        console.log('error occured!!');
      });
  }

  async getPSIExtraBenefits() {
    await this.preEnrollmentService
      .getPSIExtraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.isPSIApplicable = result.peExtraBenefits.isAllowed;
          this.getPSIPremiumData(result.peExtraBenefits.id);
        }
      })
      .catch(error => {
        console.log('error occured!!');
      });
  }

  async getESIExtraBenefits() {
    await this.preEnrollmentService
      .getESIExtraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.isESIApplicable = result.peExtraBenefits.isAllowed;
          this.getESIPremiumData(result.peExtraBenefits.id);
        }
      })
      .catch(error => {
        console.log('error occured!!');
      });
  }

  async getTopupExtraBenefits() {
    await this.preEnrollmentService
      .getTopupExtraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.isTopupApplicable = result.peExtraBenefits.isAllowed;
          this.getTopupPremiumData(result.peExtraBenefits.id);
        }
      })
      .catch(error => {
        console.log('error occured!!');
      });
  }

  async getESIPremiumData(extraBenefitId) {
    await this.preEnrollmentService
      .getESIPremium(extraBenefitId)
      .then(result => {
        this.esiPremiumLength = result.length;
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getPSIPremiumData(extraBenefitId) {
    await this.preEnrollmentService
      .getPSIPremium(extraBenefitId)
      .then(result => {
        this.psiPremiumLength = result.length;
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getSelfSIPremium(extraBenefitId) {
    await this.preEnrollmentService
      .getSelfSIPremium(extraBenefitId)
      .then(result => {
        this.siPremiumLength = result.length;
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getTopupPremiumData(extraBenefitId) {
    await this.preEnrollmentService
      .getTopupPremium(extraBenefitId)
      .then(result => {
        this.topupPremiumLength = result.length;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
