<div class="demo-full-width margin-top" [formGroup]="group">
  <mat-checkbox
    [disabled]="(!isEditable && field.options.length == 0) || disableFloaterField"
    (change)="OnChange($event)"
    [(checked)]="isChecked"
    [hidden]="!isVisible || !isEditable"
    >{{ field.label }}</mat-checkbox
  >
</div>

<ng-container>
  <mat-form-field
    *ngIf="isChecked && typeof(field.options) !== 'string'"
    class="demo-full-width margin-top"
    [formGroup]="group"
  >
    <mat-select
      [disabled]="disableFloaterField"
      [placeholder]="field.label"
      [(ngModel)]="extraBenefitSelectedValue"
      (ngModelChange)="changeExtraBenefit()"
      [formControlName]="field.name"
    >
      <mat-option *ngFor="let item of field.options | keyvalue: 0" [value]="item.value">{{ item.value }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div [hidden]="!isChecked" class="instructions">
    <p>{{ field.instructions }}</p>
  </div>

  <div *ngIf="isChecked && totalPremium !== undefined && totalPremium !== null" class="demo-full-width">
    <p *ngIf="!isPremiumVisible"> 
      <b>Premium Amount - {{ totalPremium }}</b>
    </p>

    <p *ngIf="isPremiumVisible && premiumAmountFormula === 1" matTooltip="Net Premium = Premium Amount * Loading&#13;Net Premium = {{premiumAmount}} * {{loading}}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{gst}} / 100) * {{premiumAmount * loading}}&#13;Total Premium = Net Premium + Premium with GST"> 
      <b>Premium Amount - {{ totalPremium }}</b>
    </p>

    <p *ngIf="isPremiumVisible && premiumAmountFormula === 2" 
    matTooltip="Date Difference = End Date - Date of Joining&#13;
    Net Premium = Premium Amt. * (Date Diff/365) * Loading&#13;
    Net Premium = {{premiumAmount}} * ({{ dateDiff }} / 365) * {{loading}}&#13;
    Premium with GST = (GST Percentage / 100) * Net Premium&#13;
    Premium with GST = ({{gst}} / 100) * {{premiumAmount * (dateDiff / 365) * loading}}&#13;
    Total Premium = Net Premium + Premium with GST">
      <b>Premium Amount - {{ totalPremium }}</b>
    </p>
  </div>
</ng-container>
