import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as FileSaver from 'file-saver';
import { PreEnrollmentService } from 'src/app/business-admin/services/preEnrollment.service';
import { JobStatusDialog } from './job-status-dialog.model';

@Component({
  selector: 'app-show-job-status',
  templateUrl: './show-job-status.component.html',
  styleUrls: ['./show-job-status.component.scss']
})
export class ShowJobStatusComponent implements OnInit {
  jobStatus: string;
  url: string;
  policyId: number;
  statusId: number;

  constructor(
    public dialogRef: MatDialogRef<ShowJobStatusComponent>,
    public preenrollmentService: PreEnrollmentService,
    @Inject(MAT_DIALOG_DATA) public data: JobStatusDialog
  ) {
    // Update view with given values
    this.jobStatus = data.jobStatus;
    this.url = data.url;
    this.policyId = data.policyId;
    this.statusId = data.statusId;
  }

  ngOnInit() {}

  async downloadJobStatusFile() {
    this.downloadURI();
    this.preenrollmentService.deleteJobStatus(this.policyId, this.statusId)
    .then(x => {console.log(x);
      })
    .catch(error => {
        console.log(error);
      });
  }

  downloadURI() {
    const link = document.createElement('a');
    link.download = 'member.xlsx';
    link.href = this.url;
    link.click();
  }


  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
