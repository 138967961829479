import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-biz-admin-header',
  templateUrl: './biz-admin-header.component.html',
  styleUrls: ['./biz-admin-header.component.scss'],
})
export class BizAdminHeaderComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  subscription: Subscription;
  userName: string;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.subscription = this.authService.isLoggedIn.subscribe(status => (this.isAuthenticated = status));
    this.subscription = this.authService.userName.subscribe(name => (this.userName = name));
  }

  async signout() {
    await this.authService.signout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
