import { Component, OnInit, Injector, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { CredentialsFormat } from 'src/app/shared/models/CorporatePortal/credentials-format.model';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MasterService } from 'src/app/business-admin/services/master.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss'],
})
export class CredentialsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  form: FormGroup;
  @Input() corporate: CorporateModel;
  @Input() policyNumber: any;

  policy: any;
  policyList = [];
  username: string;
  password: string;
  disableAddButton: boolean;
  credentialsFormat: CredentialsFormat;
  disableUsernameButton = false;
  disablePasswordButton = false;
  errorList = [];
  emailTemplate = '';
  emailSubject = '';
  disableSaveButton = false;
  file: any;
  fileName: string;
  isSizeValid = false;
  isNameValid = false;
  isTypeNameValid = false;
  uploadImageInvalidMsg: string;
  invalidNameMsg: string;
  credentialForm = this.fb.group({
    file: [''],
  });
  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly corporatePortalService: CorporatePortalService,
    private readonly alertService: AlertService,
    private readonly masterService: MasterService,
    private fb: FormBuilder
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.createForm();
    if (this.policyNumber !== 'Attach Policy') {
      this.credentialsFormat = new CredentialsFormat();
      if (this.policy.userNameType !== null && this.policy.passwordType !== null) {
        this.username = this.policy.userNameType;
        this.password = this.policy.passwordType;
        this.disableUsernameButton = true;
        this.disablePasswordButton = true;
        this.disableAddButton = true;
      } else {
        this.credentialsFormat.id = this.policy.id;
        this.credentialsFormat.policyNumber = this.policy.policyNumber;
        this.credentialsFormat.key = this.policy.key;
        this.checkAddButtonVisibility();
      }
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    // const templates = await this.masterService.getEmailTemplate();
    // this.emailTemplate = templates.welcomeEmailTemplate;
    this.corporatePortalService.updatedPolicies.subscribe((policyList: any[]) => {
      this.policy = policyList.find(policy => policy.policyNumber === this.policyNumber);
    });
    this.emailTemplate = this.policy.welcomeEmailTemplate;
    this.emailSubject = this.policy.welcomeEmailSubject;
    if (changes['policyNumber'] !== undefined && changes['corporate'] === undefined) {
      this.setCredsValue();
    }
  }

  createForm() {
    this.form = this.fb.group({
      welcomeSubject: [
        '',
        [Validators.required, Validators.maxLength(254), Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')],
      ],
    });
  }

  checkAddButtonVisibility() {
    this.disableAddButton =
      this.username !== undefined && this.password !== undefined && this.username !== null && this.password !== null
        ? false
        : true;
  }

  async addCreds() {
    let result;
    const message = `Confirm credentials
    Username Type ${this.username},
    Password Type ${this.password}`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.credentialsFormat.UserNameType = this.username;
          this.credentialsFormat.PasswordType = this.password;
          this.corporatePortalService
            .addCredentialFormat(this.policy.id, this.credentialsFormat)
            .then(x => {
              this.alertService.success('Credentials added successfully');
              this.getPolicyByNumber();
            })
            .catch(errorData => {
              this.errorList = errorData.error.errors;
              this.alertService.error('Error occured');
            });
        } catch (error) {
          this.alertService.error('Error occured');
        }
      }
    });
  }

  setCredsValue() {
    if (this.policyNumber !== 'Attach Policy') {
      this.credentialsFormat = new CredentialsFormat();
      if (this.policy.userNameType !== null && this.policy.passwordType !== null) {
        this.username = this.policy.userNameType;
        this.password = this.policy.passwordType;
        this.disableUsernameButton = true;
        this.disablePasswordButton = true;
        this.disableAddButton = true;
      } else {
        this.credentialsFormat.id = this.policy.id;
        this.credentialsFormat.policyNumber = this.policy.policyNumber;
        this.credentialsFormat.key = this.policy.key;
        this.username = null;
        this.password = null;
        this.disableUsernameButton = false;
        this.disablePasswordButton = false;
        this.checkAddButtonVisibility();
      }
    }
  }

  async getPolicyByNumber() {
    const policy = await this.corporatePortalService.getPolicyByNumber(this.policy.policyNumber);
    if (policy !== null || policy !== undefined) {
      this.corporatePortalService.updatedPolicies.subscribe((policyList: any[]) => {
        this.policyList = policyList;
      });
      const index = this.policyList.findIndex(policyNew => policyNew.policyNumber === this.policyNumber);
      this.policyList.splice(index, 1, policy);
      this.corporatePortalService.updatedPolicies.next(this.policyList);
      this.policy = policy;
      this.username = this.policy.userNameType;
      this.password = this.policy.passwordType;
      this.disableUsernameButton = true;
      this.disablePasswordButton = true;
      this.disableAddButton = true;
    }
  }

  addTemplate() {
    this.disableSaveButton = true;
    const json = {
      WelcomeEmailTemplate: this.emailTemplate,
      WelcomeEmailSubject: this.emailSubject,
    };
    const stringJson = JSON.stringify(json);
    console.log(stringJson);
    const data = new FormData();
    data.append('File', this.credentialForm.value.file[0]);
    data.append('Json', stringJson);
    this.credentialForm.get('file').setErrors({ invalid: true });
    this.corporatePortalService
      .addEmailTemplateAPI(this.policy.id, data)
      .then(x => {
        this.alertService.success('Template added successfully');
        this.disableSaveButton = false;
      })
      .catch(errorData => {
        this.errorList = errorData.error.errors;
        this.alertService.error('Error occured');
        this.disableSaveButton = false;
      });
  }
  onFileChange(file) {
    if (file.length > 0) {
      this.credentialForm.controls.file.setValue(file);
      const name = file[0].name;
      const size = file[0].size;
      this.fileName = name;
      if (this.fileName.length > 30) {
        this.fileName = this.fileName.slice(0, 25) + '...';
      }
      if (size > 5000000) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Maximum size must be 5 Mb';
        this.credentialForm.get('file').setErrors({ invalid: true });
      } else {
        this.isSizeValid = true;
        this.uploadImageInvalidMsg = '';
        this.credentialForm.get('file').setErrors(null);
      }
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const fileExtensionArr = ['jpg', 'jpeg', 'png', 'pdf'];
      if (!fileExtensionArr.includes(ext.toLowerCase())) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Upload valid format file';
        this.credentialForm.get('file').setErrors({ invalid: true });
      }
    }
    console.log(this.credentialForm);
  }
  deleteFile() {
    (<HTMLInputElement>document.getElementById('file')).value = '';
    this.credentialForm.controls.file.setValue('');
    this.isSizeValid = true;
    this.uploadImageInvalidMsg = '';
    this.fileName = '';
  }
}
