import { Component, OnInit } from '@angular/core';
import { CPMemberService } from '../../services/CPMember.service';
import { SelfMember } from 'src/app/cp-member/models/self-member.model';
import { Policy } from '../../models/policy.model';
import { HRProfile } from '../../models/hr.profile.model';
import { PolicyInfoModel } from '../../models/policy-Info.model';
import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { CPWellnessService } from '../../services/CPWellness.service';
import { PolicyAllowedSumInsured } from 'src/app/cp-member/models/policy-suminsured.model';
import * as moment from 'moment';
@Component({
  selector: 'app-cp-member-profile',
  templateUrl: './cp-member-profile.component.html',
  styleUrls: ['./cp-member-profile.component.scss'],
})
export class CpMemberProfileComponent implements OnInit {
  policies = [];
  policyNumber: any;
  currentPolicy: Policy;
  isHr: boolean;
  isMember: boolean;
  isHrView = false;
  policyAllowedSumInsureds: PolicyAllowedSumInsured[] = [];
  coveredUptoSumInsured: number;
  memberAge: any;
  memberDoj: any;

  policyInfo: PolicyInfoModel = new PolicyInfoModel();
  isPolicyInfoLoaded = false;

  hrProfile: HRProfile = new HRProfile();
  ishrProfileLoaded = false;

  memberSelf: SelfMember = new SelfMember();
  ismemberLoaded = false;
  isDependentLoaded = false;

  isEmployeeBelongsToPolicy = false;
  dependent: SelfMember[] = [];

  rhealthAssist: string;
  rhealthCircle: string;

  constructor(
    private policyService: CPPolicyService,
    private cpWellnessService: CPWellnessService,
    private cpMemberService: CPMemberService
  ) {}

  async ngOnInit() {
    this.memberSelf = new SelfMember();

    this.policyService.policies.subscribe(policies => {
      this.policies = policies;
    });

    this.cpMemberService.isHrView.subscribe(v => {
      this.isHrView = v;
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.currentPolicy = policy;
      this.policyNumber = this.currentPolicy.policyNumber;
      this.isHr = this.cpMemberService.isHr;
      this.isMember = this.cpMemberService.isMember;
      this.isPolicyInfoLoaded = false;
      this.ismemberLoaded = false;

      this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;
      if (this.policyNumber !== undefined && this.isHr === true && this.isHrView) {
        this.cpMemberService.hrDetails.subscribe(hr => {
          this.hrProfile = hr;
          this.ishrProfileLoaded = true;

          this.calculateAge(this.hrProfile.dob);
          this.calculateDoj(this.hrProfile.doj);
        });

        await this.cpMemberService
          .getPolicyInfo(this.policyNumber)
          .then(x => {
            this.policyInfo = x;
            const numberFormatPipe1 = new NumberFormatPipe();
            this.policyInfo.activeInsured = numberFormatPipe1.transform(this.policyInfo.activeInsured);
            this.policyInfo.totalEndorsements = numberFormatPipe1.transform(this.policyInfo.totalEndorsements);
            this.policyInfo.cashlessClaims = numberFormatPipe1.transform(this.policyInfo.cashlessClaims);
            this.policyInfo.reimbursementClaims = numberFormatPipe1.transform(this.policyInfo.reimbursementClaims);
            this.isPolicyInfoLoaded = true;
          })
          .catch(x => {
            console.log('error');
          });

        await this.cpMemberService
          .getPolicyAllowedSumInsured(this.currentPolicy.policyNumber)
          .then(x => {
            this.policyAllowedSumInsureds = x;
            const SI = this.policyAllowedSumInsureds.map(x => x.sumInsured);
            if (!SI.length) {
              this.coveredUptoSumInsured = 0;
            } else {
              this.coveredUptoSumInsured = Math.max.apply(null, SI);
            }
          })
          .catch(x => {
            this.policyAllowedSumInsureds = null;
            this.coveredUptoSumInsured = 0;
            console.log('error');
          });
      }
      if (
        this.policyNumber !== undefined &&
        this.isMember === true &&
        this.currentPolicy.isMemberEnrolled
      ) {
        this.cpMemberService.selfMemberDetails.subscribe(m => {
          this.memberSelf = m;
          this.ismemberLoaded = JSON.stringify(this.memberSelf) === '{}' ? false : true;
        });

        this.cpMemberService.dependentDetails.subscribe(d => {
          this.dependent = d;
          this.isDependentLoaded = JSON.stringify(this.memberSelf) === '{}' ? false : true;
        });
        if (policy.wellness) {
          if (policy.rHealthAssist) {
            this.cpWellnessService.getRhealthAssist(policy.policyNumber).then(x => {
              this.rhealthAssist = x.url;
            });
          }
          if (policy.rHealthCircle) {
            this.cpWellnessService.getRhealthCircle(policy.policyNumber).then(x => {
              this.rhealthCircle = x.url;
            });
          }
        }
      }
    });
  }

  calculateAge(dob) {
    if (dob) {
      const memberDOB = moment(dob).format('L');
      const age = Math.floor((new Date().valueOf() - new Date(memberDOB).getTime()) / 3.15576e10);
      this.memberAge = age;
    }
  }

  calculateDoj(doj) {
    if (doj) {
      this.memberDoj = doj === '0001-01-01T00:00:00' ? 'NA' : this.formatDate(new Date(doj));
    }
  }

  formatDate(date) {
    const day = date.getDate().toString().length === 2 ? date.getDate() : '0' + date.getDate().toString();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let ddate;
    if (month.toString().length === 1) {
      ddate = day + '-0' + month + '-' + year;
    } else {
      ddate = day + '-' + month + '-' + year;
    }
    return ddate;
  }

  formatDoj(doj) {
    return this.formatDate(new Date(doj));
  }

  onNamePolicyChanged(policy) {
    this.policyService.currentPolicy.next(policy);
  }
}
