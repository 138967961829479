import { Component, OnInit, Input, OnDestroy, Injector, SimpleChanges, OnChanges } from '@angular/core';
import { PreEnrollmentPolicyModel } from '../../../shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { SumInsuredModel } from '../../../shared/models/preEnrollment/policies/SumInsuredModel';
import { MasterService } from '../../services/master.service';
import { CorporateModel } from '../../../shared/models/corporate/corporate.model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { PreEnrollmentPolicyRelationshipModel } from '../../../shared/models/preEnrollment/policies/PreEnrollmentPolicyRelationshipModel';
import { PreEnrollmentPolicyMemberFieldModel } from '../../../shared/models/preEnrollment/policies/PreEnrollmentPolicyMemberFieldModel';
import { PreEnrollmentAdditionSumOptionModel } from '../../../shared/models/preEnrollment/policies/PreEnrollmentAdditionSumOptionModel';
import { PreEnrollmentService } from '../../services/preEnrollment.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { datepickerAnimation } from 'ngx-bootstrap/datepicker/datepicker-animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnhancedSumInsured } from 'src/app/shared/models/preEnrollment/policies/enhanced-sum-insured.model';
import { TopupModel } from 'src/app/shared/models/preEnrollment/policies/topup-model.model';
import { TopupValues } from 'src/app/shared/models/preEnrollment/policies/topup-values.model';
import { GstDetails } from 'src/app/shared/models/preEnrollment/premium/gst-details.model';
import { AllowedSumInsuredModel } from 'src/app/shared/models/preEnrollment/policies/allowedSumInsuredModel';

@Component({
  selector: 'app-create-pre-enrollment',
  templateUrl: './create-pre-enrollment.component.html',
  styleUrls: ['./create-pre-enrollment.component.scss'],
})
export class CreatePreEnrollmentComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  form: FormGroup;
  @Input() corporate: CorporateModel;
  @Input() policy: PreEnrollmentPolicyModel;
  editMode = false;

  initialized = false;

  welcomeEmailMinDate = new Date();
  reminderEmailMinDate = new Date();
  currentStep = 1;
  allStepsCompleted = false;

  setPolicyName = 1;
  policyStartDateInvalid = false;
  policyEndDateInvalid = false;
  policyNameInvalid = false;
  sumInsuredValuesMissing = false;

  maxMembersPerFamilyInvalid = false;
  totalMembersInvalid = false;

  configureMemberSettings = 2;
  memberFieldsMaster: PreEnrollmentPolicyMemberFieldModel[] = [];

  configurePasswordOption = 3;

  configureRelationships = 4;
  relationshipTypeMaster: PreEnrollmentPolicyRelationshipModel[] = [];
  // maxChildernAllowedInvalid = false;
  isMaxChildrenValid = true;
  invalidMessage = '';

  parentalSumInsuredOptionAvailable = false;
  areTwinsAllowed = false;
  parentInLawConditionAllowed = false;

  // configureEnhancedSumInsured = 5;
  // configureTopups = 6;
  // configureEmails = 7;
  // configurePremiumCalculator = 8;
  configureEmails = 5;

  hrsOptions: number[] = [];
  minsOptions: number[] = [];
  yearOptions: number[] = [];
  errorR: any;
  reminderMinsOptions: number[] = [0, 30];

  isSaveButtonEnable = true;
  isPlanNull = false;
  isGSTPercentageNull = false;
  displaySave = '';
  maxAgeValid: boolean[] = [];
  minAgeValid: boolean[] = [];
  minAgeInvalidMsg: string[] = [];
  maxAgeInvalidMsg: string[] = [];
  isCountValid: boolean[] = [];
  isCountValidMsg: string[] = [];
  rshipFieldsValid = true;
  isCcEmailValid: boolean;
  isBccEmailValid: boolean;
  invalidEmailBccMsg: string;
  dynamicArray: any;
  populateArray: any;
  esiPremiums: any;

  isWelcomeEmailValid: boolean;
  isConfirmationEmailValid: boolean;
  isReminderEmailValid: boolean;
  emailSubjectLength = true;
  subjectErrorMsg: string;
  confirmationEmailSubjectLength = true;
  confirmationSubjectErrorMsg: string;

  reminderEmailSubjectErrorMsg: string;
  reminderEmailSubjectLength = true;
  repeatedSumInsuredValues = false;
  enhancedSumInsured: EnhancedSumInsured;
  topupOption: TopupModel;

  fileName: any;
  uploadImageInvalidMsg: string;
  isSizeValid: boolean;

  gstDetails: GstDetails = new GstDetails();

  constructor(
    injector: Injector,
    private readonly masterService: MasterService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly preenrollmentService: PreEnrollmentService,
    private fb: FormBuilder
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.createForm();
    super.ngOnInit();

    const dt = new Date();
    const currentYear = dt.getFullYear();
    this.yearOptions.push(currentYear);
    this.yearOptions.push(currentYear + 1);
    for (let index = 0; index < 24; index++) {
      this.hrsOptions.push(index);
    }
    for (let index = 0; index < 60; index++) {
      this.minsOptions.push(index);
    }
    if (!this.policy) {
      // create
      this.displaySave = 'Save';
      this.memberFieldsMaster = await this.masterService.getMemberFieldsMaster();
      this.relationshipTypeMaster = await this.masterService.getRelationshipMaster();

      this.policy = new PreEnrollmentPolicyModel(null);
      this.policy.year = this.yearOptions[0];
      const templates = await this.masterService.getEmailTemplate();
      this.policy.welcomeEmailTemplate = templates.welcomeEmailTemplate;
      this.policy.registrationConfirmationEmailTemplate = templates.confirmationEmailTemplate;
      this.policy.reminderEmailTemplate = templates.reminderEmailTemplate;
    } else {
      console.log(this.policy);
      // this.populateArray = this.alignDataByPremiumCalculatorModel(this.policy.parentInsuranceModel);

      this.displaySave = 'Save Changes';
      this.policy = new PreEnrollmentPolicyModel(this.policy);
      if (!this.policy.reminderEmailTemplate) {
        const templates = await this.masterService.getEmailTemplate();
        this.policy.reminderEmailTemplate = templates.reminderEmailTemplate;
      }
      if (this.policy.reminderEmailDate === '0001-01-01T00:00:00') {
        this.policy.reminderEmailDate = new Date();
      }
      this.editMode = true;
      this.policy.startDate = moment(this.policy.startDate).toDate();
      this.policy.endDate = moment(this.policy.endDate).toDate();
      this.policy.welcomeEmailDate = moment(this.policy.welcomeEmailDate).toDate();
      this.policy.reminderEmailDate = moment(this.policy.reminderEmailDate).toDate();
      this.memberFieldsMaster = this.policy.memberFields;
      this.relationshipTypeMaster = this.policy.relationships;
      console.log('relationshpType', this.relationshipTypeMaster);
      console.log('memberfield', this.memberFieldsMaster);
      this.loadDataFromExistingPolicy();
      this.sortMemberFields();
      this.sortRelationships();
      this.gstDetails.gSTPercentage = this.policy.gstPercentage;
      this.gstDetails.isGSTExempted = this.policy.isGSTExempted;
      this.fileName = this.policy.gstFileUpload;
      this.gstDetails.gSTFileUpload = this.fileName;
      if (this.gstDetails.isGSTExempted) {
        this.gstDetails.gSTPercentage = 0;
      } else {
        this.gstDetails.gSTPercentage = this.policy.gstPercentage;
      }
    }

    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['policy'] !== undefined && changes['corporate'] === undefined) {
      this.currentStep = 1;
      this.allStepsCompleted = false;
      this.setPolicyName = 1;
      this.policyStartDateInvalid = false;
      this.policyEndDateInvalid = false;
      this.policyNameInvalid = false;

      this.sumInsuredValuesMissing = false;
      this.repeatedSumInsuredValues = false;
      this.maxMembersPerFamilyInvalid = false;
      this.totalMembersInvalid = false;
      this.isPlanNull = false;
      this.isGSTPercentageNull = false;
      this.isMaxChildrenValid = true;
      this.isSaveButtonEnable = true;
      this.maxAgeValid = [];
      this.minAgeValid = [];
      this.minAgeInvalidMsg = [];
      this.maxAgeInvalidMsg = [];
      this.isCountValid = [];
      this.isCountValidMsg = [];
      this.rshipFieldsValid = true;
      this.invalidMessage = '';
      this.parentalSumInsuredOptionAvailable = false;
      this.areTwinsAllowed = false;
      this.parentInLawConditionAllowed = false;
      this.isBccEmailValid = true;
      this.isCcEmailValid = true;

      this.ngOnInit();
    }
  }
  createForm() {
    this.form = this.fb.group({
      message: ['', [Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')]],
      welcomeMessage: ['', [Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')]],
      reminderMessage: ['', [Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')]],
    });
  }
  isSubjectNameValid(event) {
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.emailSubjectLength = false;
      this.subjectErrorMsg = 'Subject cannot be empty!!!';
    } else if (fieldValue.length > 254) {
      this.emailSubjectLength = false;
      this.subjectErrorMsg = 'Max length of 254 exceeded';
    } else {
      this.emailSubjectLength = true;
      this.subjectErrorMsg = '';
    }
  }
  isConfirmationSubjectValid(event) {
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.confirmationEmailSubjectLength = false;
      this.confirmationSubjectErrorMsg = 'Subject cannot be empty!!!';
    } else if (fieldValue.length > 254) {
      this.confirmationEmailSubjectLength = false;
      this.confirmationSubjectErrorMsg = 'Max length of 254 exceeded';
    } else {
      this.confirmationEmailSubjectLength = true;
      this.confirmationSubjectErrorMsg = '';
    }
  }
  isReminderSubjectValid(event) {
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.reminderEmailSubjectLength = false;
      this.reminderEmailSubjectErrorMsg = 'Subject cannot be empty!!!';
    } else if (fieldValue.length > 254) {
      this.reminderEmailSubjectLength = false;
      this.reminderEmailSubjectErrorMsg = 'Max length of 254 exceeded';
    } else {
      this.reminderEmailSubjectLength = true;
      this.reminderEmailSubjectErrorMsg = '';
    }
  }

  relationshipSelectionChanged(relationship: PreEnrollmentPolicyRelationshipModel) {
    // Self-Parent-In-Law, Parent-In-Law, Self-Parent, Parent

    if (
      (relationship.type.toLocaleLowerCase() === 'parent' ||
        relationship.type.toLocaleLowerCase() === 'parent-in-law') &&
      relationship.isEnabled
    ) {
      for (let index = 0; index < this.relationshipTypeMaster.length; index++) {
        const rship = this.relationshipTypeMaster[index];
        if (
          (rship.type.toLocaleLowerCase() === 'self-parent' ||
            rship.type.toLocaleLowerCase() === 'self-parent-in-law') &&
          rship.isEnabled
        ) {
          this.relationshipTypeMaster[index].isEnabled = false;
        }
      }
    }

    if (
      (relationship.type.toLocaleLowerCase() === 'self-parent' ||
        relationship.type.toLocaleLowerCase() === 'self-parent-in-law') &&
      relationship.isEnabled
    ) {
      for (let index = 0; index < this.relationshipTypeMaster.length; index++) {
        const rship = this.relationshipTypeMaster[index];
        if (
          (rship.type.toLocaleLowerCase() === 'parent' || rship.type.toLocaleLowerCase() === 'parent-in-law') &&
          rship.isEnabled
        ) {
          this.relationshipTypeMaster[index].isEnabled = false;
        }
      }
    }

    if (relationship.type.toLocaleLowerCase().indexOf('parent') > -1) {
      this.parentalSumInsuredOptionAvailable = false;
      for (let index = 0; index < this.relationshipTypeMaster.length; index++) {
        const rship = this.relationshipTypeMaster[index];
        if (rship.type.toLocaleLowerCase().indexOf('parent') > -1 && rship.isEnabled) {
          this.parentalSumInsuredOptionAvailable = true;
          break;
        }
      }
    }

    if (relationship.type.toLocaleLowerCase().indexOf('children') > -1) {
      this.areTwinsAllowed = false;
      for (let index = 0; index < this.relationshipTypeMaster.length; index++) {
        const rship = this.relationshipTypeMaster[index];
        if (rship.type.toLocaleLowerCase().indexOf('children') > -1 && rship.isEnabled) {
          this.areTwinsAllowed = true;
          break;
        }
      }
    }

    if (
      relationship.type.toLocaleLowerCase() === 'parent-in-law' ||
      relationship.type.toLocaleLowerCase() === 'parent'
    ) {
      this.parentInLawConditionAllowed = false;
      for (let index = 0; index < this.relationshipTypeMaster.length; index++) {
        const rship = this.relationshipTypeMaster[index];
        if (rship.type.toLocaleLowerCase() === 'parent' || rship.type.toLocaleLowerCase() === 'parent-in-law') {
          if (rship.isEnabled) {
            this.parentInLawConditionAllowed = true;
          } else {
            this.parentInLawConditionAllowed = false;
            break;
          }
        }
      }
    }

    if (
      relationship.type.toLocaleLowerCase() === 'self-parent-in-law' ||
      relationship.type.toLocaleLowerCase() === 'self-parent'
    ) {
      this.parentInLawConditionAllowed = false;
      for (let index = 0; index < this.relationshipTypeMaster.length; index++) {
        const rship = this.relationshipTypeMaster[index];
        if (rship.type.toLocaleLowerCase() === 'parent' || rship.type.toLocaleLowerCase() === 'parent-in-law') {
          if (rship.isEnabled) {
            this.parentInLawConditionAllowed = true;
          } else {
            this.parentInLawConditionAllowed = false;
            break;
          }
        }
      }
      if (!this.parentInLawConditionAllowed) {
        for (let index = 0; index < this.relationshipTypeMaster.length; index++) {
          const rship = this.relationshipTypeMaster[index];
          if (
            rship.type.toLocaleLowerCase() === 'self-parent-in-law' ||
            rship.type.toLocaleLowerCase() === 'self-parent'
          ) {
            if (rship.isEnabled) {
              this.parentInLawConditionAllowed = true;
            } else {
              this.parentInLawConditionAllowed = false;
              break;
            }
          }
        }
      }
    }
  }

  comparer(otherArray) {
    return function(current) {
      return (
        otherArray.filter(function(other) {
          return other.value === current.value;
        }).length === 0
      );
    };
  }

  async getESIExtraBenefits() {
    await this.preenrollmentService
      .getESIExtraBenefits(this.policy.id, 'SELF')
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.enhancedSumInsured = new EnhancedSumInsured(result);
        } else {
          this.enhancedSumInsured = new EnhancedSumInsured(null);
        }
        this.addUpdateESI();
      })
      .catch(error => {
        this.enhancedSumInsured = new EnhancedSumInsured(null);
        this.addUpdateESI();
      });
  }

  async getTopupExtraBenefits() {
    await this.preenrollmentService
      .getTopupExtraBenefits(this.policy.id, 'SELF')
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.topupOption = new TopupModel(result);
        } else {
          this.topupOption = new TopupModel(null);
        }
        this.addUpdateTopupSumInsured();
      })
      .catch(error => {
        this.topupOption = new TopupModel(null);
        this.addUpdateTopupSumInsured();
      });
  }

  async addUpdateESI() {
    this.enhancedSumInsured.peExtraBenefits.benefitType = 'ESI';
    this.enhancedSumInsured.peExtraBenefits.relationship = 'SELF';
    this.enhancedSumInsured.peExtraBenefits.plan = this.policy.plan;
    for (let i = 0; i < this.enhancedSumInsured.peEsi.length; i++) {
      this.enhancedSumInsured.peEsi[i].id = 0;
    }
    if (this.enhancedSumInsured.peExtraBenefits.id !== 0) {
      await this.preenrollmentService
        .updateESIExtraBenefits(this.policy.id, this.enhancedSumInsured)
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      for (let i = 0; i < this.enhancedSumInsured.peEsi.length; i++) {
        this.enhancedSumInsured.peEsi[i].id = 0;
        this.enhancedSumInsured.peEsi[i].singlePremium = 0;
        this.enhancedSumInsured.peEsi[i].doublePremium = 0;
        this.enhancedSumInsured.peEsi[i].relationship = 'SELF';
        this.enhancedSumInsured.peEsi[i].discount = 0;
        this.enhancedSumInsured.peEsi[i].gST = 0;
        this.enhancedSumInsured.peEsi[i].total = 0;
        this.enhancedSumInsured.peEsi[i].pePolicyId = this.enhancedSumInsured.peExtraBenefits.pePolicyId;
        this.enhancedSumInsured.peEsi[i].extraBenefitsId = this.enhancedSumInsured.peExtraBenefits.id;
        this.enhancedSumInsured.peEsi[i].sumInsured =
          this.enhancedSumInsured.peEsi[i].stringValue !== undefined
            ? Number(this.enhancedSumInsured.peEsi[i].stringValue.toString().replace(/,/g, ''))
            : 0;
      }
      await this.preenrollmentService
        .addESIExtraBenefits(this.policy.id, this.enhancedSumInsured)
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  async addUpdateTopupSumInsured() {
    this.topupOption.peExtraBenefits.benefitType = 'TopUp';
    this.topupOption.peExtraBenefits.relationship = 'SELF';
    this.topupOption.peExtraBenefits.plan = this.policy.plan;
    for (let i = 0; i < this.topupOption.peTopUp.length; i++) {
      this.topupOption.peTopUp[i].id = 0;
    }
    if (this.topupOption.peExtraBenefits.id !== 0) {
      await this.preenrollmentService
        .updateTopupExtraBenefits(this.policy.id, this.topupOption)
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          console.log('Error occured');
        });
    } else {
      for (let i = 0; i < this.topupOption.peTopUp.length; i++) {
        this.topupOption.peTopUp[i].id = 0;
        this.topupOption.peTopUp[i].singlePremium = 0;
        this.topupOption.peTopUp[i].doublePremium = 0;
        this.topupOption.peTopUp[i].relationship = 'SELF';
        this.topupOption.peTopUp[i].discount = 0;
        this.topupOption.peTopUp[i].gST = 0;
        this.topupOption.peTopUp[i].total = 0;
        this.topupOption.peTopUp[i].pePolicyId = this.topupOption.peExtraBenefits.pePolicyId;
        this.topupOption.peTopUp[i].extraBenefitsId = this.topupOption.peExtraBenefits.id;
        this.topupOption.peTopUp[i].sumInsured =
          this.topupOption.peTopUp[i].stringValue !== undefined
            ? Number(this.topupOption.peTopUp[i].stringValue.toString().replace(/,/g, ''))
            : 0;
      }
      await this.preenrollmentService
        .addTopupExtraBenefits(this.policy.id, this.topupOption)
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          console.log('Error occured');
        });
    }
  }

  async createESISumInsured(id) {
    this.enhancedSumInsured = new EnhancedSumInsured(null);
    this.enhancedSumInsured.peExtraBenefits.benefitType = 'ESI';
    this.enhancedSumInsured.peExtraBenefits.relationship = 'SELF';
    this.enhancedSumInsured.peExtraBenefits.plan = this.policy.plan;
    this.enhancedSumInsured.peExtraBenefits.sumInsuredType = null;
    await this.preenrollmentService
      .addESIExtraBenefits(id, this.enhancedSumInsured)
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        console.log(error);
      });
  }

  async createTopupSumInsured(id) {
    this.topupOption = new TopupModel(null);
    this.topupOption.peExtraBenefits.benefitType = 'TopUp';
    this.topupOption.peExtraBenefits.relationship = 'SELF';
    this.topupOption.peExtraBenefits.plan = this.policy.plan;
    this.topupOption.peExtraBenefits.sumInsuredType = null;
    await this.preenrollmentService
      .addTopupExtraBenefits(id, this.topupOption)
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        console.log('Error occured');
      });
  }

  setGstPercentage() {
    if (this.gstDetails.isGSTExempted) {
      this.gstDetails.gSTPercentage = 0;
    } else {
      this.gstDetails.gSTPercentage = undefined;
    }
  }

  async submit() {
    this.isSaveButtonEnable = false;
    this.policy.memberFields = this.memberFieldsMaster.filter(x => x.isEnabled);
    this.policy.relationships = this.relationshipTypeMaster.filter(x => x.isEnabled);

    if (this.policy.id) {
      await this.getSI();
      this.policy.corporateId = this.corporate.id;
      this.policy.reminderEmailDate = moment(this.policy.reminderEmailDate).format('YYYY-MM-DD');
      this.preenrollmentService
        .update(this.policy)
        .then(x => {
          this.saveGSTDetails(x.id);
          this.alertService.success('PreEnrollment policy Updated successfully.');
          this.getTopupExtraBenefits();
          this.getESIExtraBenefits();
          this.router.navigate(['/biz-admin/corporate/' + this.corporate.id]);
          this.isSaveButtonEnable = true;
        })
        .catch(errorData => {
          this.errorR = errorData.error.errors;
          this.alertService.error('Error occured');
          this.policy.startDate = moment(this.policy.startDate).toDate();
          this.policy.endDate = moment(this.policy.endDate).toDate();
          this.policy.welcomeEmailDate = moment(this.policy.welcomeEmailDate).toDate();
          this.policy.reminderEmailDate = moment(this.policy.reminderEmailDate).toDate();
          this.isSaveButtonEnable = true;
        });
    } else {
      this.policy.startDate = moment(this.policy.startDate)
        .utc()
        .format();
      this.policy.endDate = moment(this.policy.endDate)
        .utc()
        .format();
      this.policy.welcomeEmailDate = moment(this.policy.welcomeEmailDate)
        .utc()
        .format();

      this.policy.reminderEmailDate = moment(this.policy.reminderEmailDate).format('YYYY-MM-DD'); // moment(this.policy.reminderEmailDate).utc().format();

      this.policy.corporateId = this.corporate.id;
      this.policy.allowedSumInsuredType = null;
      this.policy.allowedSumInsured = null;

      this.preenrollmentService
        .create(this.corporate.id, this.policy)
        .then(x => {
          this.saveGSTDetails(x.id);
          this.alertService.success('PreEnrollment policy created successfully.');
          this.createTopupSumInsured(x.id);
          this.createESISumInsured(x.id);
          this.router.navigate(['/biz-admin/corporate/' + this.corporate.id]);
          this.isSaveButtonEnable = true;
        })
        .catch(errorData => {
          this.errorR = errorData.error.errors;
          this.alertService.error('Error occured');
          this.policy.startDate = moment(this.policy.startDate).toDate();
          this.policy.endDate = moment(this.policy.endDate).toDate();
          this.policy.welcomeEmailDate = moment(this.policy.welcomeEmailDate).toDate();
          this.policy.reminderEmailDate = moment(this.policy.reminderEmailDate).toDate();
          this.isSaveButtonEnable = true;
        });
    }
  }

  deleteFile() {
    (<HTMLInputElement>document.getElementById('file')).value = '';
    this.fileName = '';
    this.isSizeValid = true;
    this.uploadImageInvalidMsg = '';
    this.gstDetails.gSTFileUpload = '';
  }

  saveGSTDetails(policyId) {
    let file;
    if (typeof this.gstDetails.gSTFileUpload === 'string') {
      file = undefined;
    } else {
      file = this.gstDetails.gSTFileUpload;
      this.gstDetails.gSTFileUpload = null;
    }
    const data = new FormData();
    data.append('File', file);
    // this.gstDetails.gSTFileUpload = '';
    const stringJson = JSON.stringify(this.gstDetails);
    data.append('Json', stringJson);
    this.preenrollmentService.updateGSTDetails(this.corporate.id, policyId, data);
  }

  completeStep(index) {
    // If user is trying to skip steps
    if (this.currentStep < index) {
      return;
    }

    // If user is trying to goto a past step
    if (this.currentStep > index) {
      this.currentStep = index;
      this.allStepsCompleted = false;
      return;
    }

    let proceed = false;
    switch (index) {
      case this.setPolicyName:
        proceed = this.completeSetPolicyNameStep();
        break;
      case this.configureMemberSettings:
        proceed = this.completeConfigureMemberSettingsStep();
        break;
      case this.configurePasswordOption:
        proceed = this.completeSelectPasswordOptionsStep();
        break;
      case this.configureRelationships:
        proceed = this.completeConfigureRelationshipsStep();
        break;
      // case this.configureEnhancedSumInsured:
      //   proceed = this.completeConfigureEnhancedSumInsuredStep();
      //   break;
      // case this.configureTopups:
      //   proceed = this.completeConfigureTopupsStep();
      //   break;
      // case this.configureEmails:
      //   proceed = this.completeConfigureEmailsStep();
      //   break;
      // case this.configurePremiumCalculator:
      //   proceed = this.completePremiumCalculatorStep();
      default:
        break;
    }

    if (proceed) {
      if (this.currentStep === 4) {
        this.allStepsCompleted = true;
      }

      this.currentStep += 1;
    }
  }
  completePremiumCalculatorStep() {
    console.log(this.populateArray);
    return true;
  }
  completeSetPolicyNameStep() {
    const today = moment();
    let startDate = moment(this.policy.startDate);
    let endDate = moment(this.policy.endDate);
    // while create
    if (!this.editMode) {
      this.policy.startDate = startDate.endOf('day').toDate();
      startDate = moment(this.policy.startDate);
      this.policy.endDate = endDate.endOf('day').toDate();
      endDate = moment(this.policy.endDate);
      // this.policyStartDateInvalid = !startDate.isValid() || today.isAfter(startDate.endOf('day'));
      this.policyStartDateInvalid = !startDate.isValid();
    } else {
      // while update
    }

    this.policyEndDateInvalid = !endDate.isValid() || endDate.isBefore(startDate);

    this.policyNameInvalid = !this.policy.name;

    // this.sumInsuredValuesMissing = this.policy.sumsInsured.length === 0;
    // let oneInvalidSumInsuredFound = false;
    // for (let index = 0; index < this.policy.sumsInsured.length; index++) {
    //   if (!this.policy.sumsInsured[index].checkValidity()) {
    //     oneInvalidSumInsuredFound = true;
    //   }
    // }
    // const sumInsuredValues = this.policy.sumsInsured.map(x => {
    //   return x.value;
    // });
    // const formattedValues = sumInsuredValues.map(el => {
    //   return el.replace(/,/g, '');
    // });
    // this.repeatedSumInsuredValues = this.checkIfDuplicateExists(formattedValues);

    this.isPlanNull = !this.policy.plan;
    this.isGSTPercentageNull = this.gstDetails.gSTPercentage === undefined ? true : false;
    return (
      !this.policyStartDateInvalid &&
      !this.sumInsuredValuesMissing &&
      !this.policyNameInvalid &&
      !this.isPlanNull &&
      !this.policyEndDateInvalid &&
      !this.repeatedSumInsuredValues &&
      !this.isGSTPercentageNull
    );
  }

  checkIfDuplicateExists(sumInsured) {
    return new Set(sumInsured).size !== sumInsured.length;
  }

  completeConfigureMemberSettingsStep() {
    const totalMembersValue = Number(
      this.policy.totalMembersInString
        .toString()
        .replace(/,/g, '')
        .trim()
    );
    const isFloat = Number(totalMembersValue) === totalMembersValue && totalMembersValue % 1 !== 0;
    if (isFloat) {
      this.totalMembersInvalid = true;
    } else {
      this.totalMembersInvalid = !totalMembersValue || isNaN(totalMembersValue) || totalMembersValue <= 0;
    }
    if (!this.totalMembersInvalid) {
      this.policy.totalMembers = Number(
        this.policy.totalMembersInString
          .toString()
          .replace(/,/g, '')
          .trim()
      );
    }

    return !this.totalMembersInvalid;
  }

  completeSelectPasswordOptionsStep() {
    return true;
  }

  completeConfigureRelationshipsStep() {
    /// ************* check for maximum members allowed per family is valid or not********************///
    const max_members = this.policy.maxNumOfMembersPerFamily;
    const isFloat = Number(max_members) === max_members && max_members % 1 !== 0;
    if (isFloat) {
      this.maxMembersPerFamilyInvalid = true;
    } else {
      this.maxMembersPerFamilyInvalid =
        !this.policy.maxNumOfMembersPerFamily ||
        isNaN(this.policy.maxNumOfMembersPerFamily) ||
        this.policy.maxNumOfMembersPerFamily <= 0;
    }
    if (!this.maxMembersPerFamilyInvalid) {
      this.policy.maxNumOfMembersPerFamily = parseInt(this.policy.maxNumOfMembersPerFamily.toString().trim(), 10);
    }

    if (this.areTwinsAllowed === false) {
      this.isMaxChildrenValid = true;
      this.policy.maxNumOfChildrenAllowed = 0;
    }

    let count = 0;
    let isChildrenEnabled = false;
    for (let index = 0; index < this.relationshipTypeMaster.length; index++) {
      const rship = this.relationshipTypeMaster[index];
      if (
        rship.type.toLocaleLowerCase().indexOf('children') > -1 &&
        rship.isEnabled &&
        rship.label.toLocaleLowerCase() === 'son'
      ) {
        count = count + rship.count;
        isChildrenEnabled = true;
      }
      if (
        rship.type.toLocaleLowerCase().indexOf('children') > -1 &&
        rship.isEnabled &&
        rship.label.toLocaleLowerCase() === 'daughter'
      ) {
        count = count + rship.count;
        isChildrenEnabled = true;
      }
    }
    if (isChildrenEnabled === false) {
      this.isMaxChildrenValid = true;
    } else {
      if (this.policy.maxNumOfChildrenAllowed <= count && this.policy.maxNumOfChildrenAllowed > 0) {
        this.isMaxChildrenValid = true;
      } else {
        this.isMaxChildrenValid = false;
        this.invalidMessage =
          this.policy.maxNumOfChildrenAllowed <= 0
            ? 'Maximum children must be greater than 0'
            : 'Maximum children should be less than or equal to children count';
      }
    }
    const max_children = this.policy.maxNumOfChildrenAllowed;
    const isFloatMaxChildren = max_children === max_children && max_children % 1 !== 0;
    if (isFloatMaxChildren) {
      this.isMaxChildrenValid = false;
      this.invalidMessage = 'Invalid maximum children count';
    }

    /// ************* assigning minAge value based on relationship type********************///
    for (let index = 0; index < this.relationshipTypeMaster.length; index++) {
      const rship = this.relationshipTypeMaster[index];
      let minAge: number;
      if (rship.type === 'Children') {
        minAge = 0;
      } else {
        minAge = 1;
      }

      /// ************* check for maxAge is valid or not********************///
      const maxAgeLimit_ = rship.maxAgeLimit;
      const isFloat_maxAge = Number(maxAgeLimit_) === maxAgeLimit_ && maxAgeLimit_ % 1 !== 0;
      if (isFloat_maxAge) {
        this.maxAgeValid[index] = false;
        this.maxAgeInvalidMsg[index] = 'Invalid Age';
      } else {
        if (rship.maxAgeLimit <= 0) {
          this.maxAgeValid[index] = false;
          this.maxAgeInvalidMsg[index] = 'Invalid Age';
        } else if (rship.minAgeLimit > rship.maxAgeLimit) {
          this.maxAgeValid[index] = false;
          this.maxAgeInvalidMsg[index] = 'Max age should be greater than min age';
        } else {
          this.maxAgeValid[index] = true;
        }
      }

      /// ************* check for minAge is valid or not********************///
      const minAgeLimit_ = rship.minAgeLimit;
      const isFloat_minAge = Number(minAgeLimit_) === minAgeLimit_ && minAgeLimit_ % 1 !== 0;
      if (isFloat_minAge) {
        this.minAgeValid[index] = false;
        this.minAgeInvalidMsg[index] = 'Invalid Age';
      } else {
        if (rship.minAgeLimit < minAge) {
          this.minAgeValid[index] = false;
          this.minAgeInvalidMsg[index] = 'Invalid Age';
        } else {
          this.minAgeValid[index] = true;
        }
      }

      /// ************* check for count is valid or not********************///
      const count_ = rship.count;
      const isFloat_count = Number(count_) === count_ && count_ % 1 !== 0;
      if (isFloat_count) {
        this.isCountValid[index] = false;
        this.isCountValidMsg[index] = 'Invalid Count';
      } else {
        if (rship.count <= 0) {
          this.isCountValid[index] = false;
          this.isCountValidMsg[index] = 'Invalid Count';
        } else {
          this.isCountValid[index] = true;
        }
      }
    }

    /// ************* check for all relationship  fields are valid or not********************///
    for (let index = 0; index < this.maxAgeValid.length; index++) {
      if (!this.maxAgeValid[index] || !this.minAgeValid[index] || !this.isCountValid[index]) {
        this.rshipFieldsValid = false;
        break;
      } else {
        this.rshipFieldsValid = true;
      }
    }
    // this.checkType();
    return !this.maxMembersPerFamilyInvalid && this.isMaxChildrenValid && this.rshipFieldsValid;
  }

  completeConfigureEmailsStep() {
    const cc = this.policy.registrationConfirmationEmailCcs;
    const bcc = this.policy.registrationConfirmationEmailBccs;

    if (this.policy.sendWelcomeEmail) {
      this.isWelcomeEmailValid = this.policy.welcomeEmailSubject ? true : false;
      if (!this.policy.welcomeEmailSubject) {
        this.emailSubjectLength = false;
        this.subjectErrorMsg = 'Subject cannot be empty!!!';
      }
    } else {
      this.isWelcomeEmailValid = true;
    }
    if (this.policy.sendRegistrationConfirmationEmail) {
      this.isConfirmationEmailValid = this.policy.registrationConfirmationEmailSubject ? true : false;
      if (!this.policy.registrationConfirmationEmailSubject) {
        this.confirmationEmailSubjectLength = false;
        this.confirmationSubjectErrorMsg = 'Subject cannot be empty!!!';
      }
    } else {
      this.isConfirmationEmailValid = true;
    }
    if (this.policy.sendReminderEmail) {
      this.isReminderEmailValid = this.policy.reminderEmailSubject ? true : false;
      if (!this.policy.reminderEmailSubject) {
        this.reminderEmailSubjectLength = false;
        this.reminderEmailSubjectErrorMsg = 'Subject cannot be empty!!!';
      }
    } else {
      this.isReminderEmailValid = true;
    }
    if (cc !== null) {
      if (cc.trim().length !== 0) {
        if (cc.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i)) {
          this.isCcEmailValid = true;
        } else {
          this.isCcEmailValid = false;
          this.invalidEmailBccMsg = 'Enter valid email';
        }
      } else {
        this.isCcEmailValid = true;
      }
    } else {
      this.isCcEmailValid = true;
    }
    if (bcc !== null) {
      if (bcc.trim().length !== 0) {
        if (bcc.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i)) {
          this.isBccEmailValid = true;
        } else {
          this.isBccEmailValid = false;
          this.invalidEmailBccMsg = 'Enter valid email';
        }
      } else {
        this.isBccEmailValid = true;
      }
    } else {
      this.isBccEmailValid = true;
    }
    return (
      this.isCcEmailValid &&
      this.isBccEmailValid &&
      this.isWelcomeEmailValid &&
      this.isConfirmationEmailValid &&
      this.isReminderEmailValid &&
      this.reminderEmailSubjectLength &&
      this.confirmationEmailSubjectLength &&
      this.emailSubjectLength
    );
  }

  private loadDataFromExistingPolicy() {
    if (!this.policy.parentalSumInsured) {
      this.policy.parentalSumInsured = new PreEnrollmentAdditionSumOptionModel();
    }
    if (!this.policy.enhancedSumInsured) {
      this.policy.enhancedSumInsured = new PreEnrollmentAdditionSumOptionModel();
    }
    if (!this.policy.topupOption) {
      this.policy.topupOption = new PreEnrollmentAdditionSumOptionModel();
    }
    for (let i = 0; i < this.memberFieldsMaster.length; i++) {
      const field = this.memberFieldsMaster[i];

      for (let j = 0; j < this.policy.memberFields.length; j++) {
        const memField = this.policy.memberFields[j];

        if (memField.id === field.id) {
          field.isEnabled = memField.isEnabled;
          field.isMandatoryForSelf = memField.isMandatoryForSelf;
          field.isVisibleForSelf = memField.isVisibleForSelf;
        }
      }
    }

    for (let i = 0; i < this.relationshipTypeMaster.length; i++) {
      const type = this.relationshipTypeMaster[i];
      for (let j = 0; j < this.policy.relationships.length; j++) {
        const rship = this.policy.relationships[j];
        if (rship.id === type.id) {
          type.isEnabled = rship.isEnabled;
          type.count = rship.count;
          type.minAgeLimit = rship.minAgeLimit;
          type.maxAgeLimit = rship.maxAgeLimit;
        }
      }
      this.relationshipSelectionChanged(type);
    }
  }

  startDateChanged() {
    this.policy.endDate = moment(this.policy.startDate)
      .add(1, 'year')
      .toDate();
    this.policy.endDate = moment(this.policy.endDate)
      .subtract(1, 'day')
      .toDate();
  }

  // addNewSumInsuredEntry() {
  //   this.policy.sumsInsured.push(new SumInsuredModel());
  // }

  // removeSumInsuredEntry(index) {
  //   this.policy.sumsInsured.splice(index, 1);
  // }

  checkMaxNoOfChildrenCount(event) {
    let count = 0;
    let isChildrenEnabled = false;
    for (let index = 0; index < this.relationshipTypeMaster.length; index++) {
      const rship = this.relationshipTypeMaster[index];
      if (
        rship.type.toLocaleLowerCase().indexOf('children') > -1 &&
        rship.isEnabled &&
        rship.label.toLocaleLowerCase() === 'son'
      ) {
        count = count + rship.count;
        isChildrenEnabled = true;
      }
      if (
        rship.type.toLocaleLowerCase().indexOf('children') > -1 &&
        rship.isEnabled &&
        rship.label.toLocaleLowerCase() === 'daughter'
      ) {
        count = count + rship.count;
        isChildrenEnabled = true;
      }
    }

    const max_children = event.target.value;
    const isFloat = max_children === max_children && max_children % 1 !== 0;
    if (isFloat) {
      this.isMaxChildrenValid = false;
      this.invalidMessage = 'Invalid maximum children count';
    } else {
      if (isChildrenEnabled === false) {
        this.isMaxChildrenValid = true;
      } else {
        if (event.target.value <= count && event.target.value > 0) {
          this.isMaxChildrenValid = true;
        } else {
          this.isMaxChildrenValid = false;
          this.invalidMessage =
            event.target.value <= 0
              ? 'Maximum children must be greater than 0'
              : 'Maximum children should be less than or equal to children count';
        }
      }
    }
  }

  sortMemberFields() {
    this.policy.memberFields.sort(memberField => {
      return memberField.isEnabled === false ||
        memberField.isVisibleForSelf === false ||
        memberField.isVisibleForDependent === false ||
        memberField.isMandatoryForSelf === false ||
        memberField.isMandatoryForDependent === false
        ? 0
        : memberField
        ? -1
        : 1;
    });
    this.policy.memberFields.sort(memberField => {
      return memberField.isEnabled === true &&
        memberField.isVisibleForSelf === true &&
        memberField.isVisibleForDependent === true &&
        memberField.isMandatoryForSelf === true &&
        memberField.isMandatoryForDependent === true
        ? 0
        : memberField
        ? -1
        : 1;
    });
    this.policy.memberFields.reverse();
  }

  sortRelationships() {
    this.policy.relationships.sort(function(a, b) {
      return a.id - b.id;
    });
    this.policy.relationships.sort(relationshipField => {
      return relationshipField.isEnabled === false ? 0 : relationshipField ? -1 : 1;
    });
    this.policy.relationships.sort(relationshipField => {
      return relationshipField.isEnabled === true ? 0 : relationshipField ? -1 : 1;
    });
    this.policy.relationships.reverse();
  }

  onFileChange(file) {
    if (file.length > 0) {
      this.gstDetails.gSTFileUpload = file[0];
      const name = file[0].name;
      this.fileName = file[0].name;
      const size = file[0].size;
      if (size > 5000000) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Max size must be 5 Mb';
      } else {
        this.isSizeValid = true;
        this.uploadImageInvalidMsg = '';
      }
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf'];
      if (!fileExtensionArr.includes(ext.toLowerCase())) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Upload valid format file';
      }
    }
  }

  async getSI() {
    await this.preenrollmentService.getSumInsured(this.corporate.id, this.policy.id).then(result => {
      const data = new AllowedSumInsuredModel(result);
      this.policy.allowedSumInsuredType = data.selfSumInsuredType;
      this.policy.allowedSumInsured = data;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
