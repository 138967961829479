import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { PreEnrollmentService } from 'src/app/business-admin/services/preEnrollment.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { PreEnrollmentPeriodModel } from 'src/app/shared/models/preEnrollment/periods/preEnrollment-period.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-configure-pre-enrollment',
  templateUrl: './configure-pre-enrollment.component.html',
  styleUrls: ['./configure-pre-enrollment.component.scss'],
})
export class ConfigurePreEnrollmentComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('closebutton', { static: true }) closebutton;
  @Input() corporate: CorporateModel;
  @Input() policy: PreEnrollmentPolicyModel;
  @Output() closePeriodModal = new EventEmitter<boolean>();

  selectedOption = 'SETTINGS';
  emailType = '';

  constructor(
    injector: Injector,
    private preEnrollmentService: PreEnrollmentService,
    private alertService: AlertService
  ) {
    super(injector);
  }

  async ngOnChanges(): Promise<void> {}

  ngOnInit() {
    super.ngOnInit();
    document.getElementById('SETTINGS').style.background = 'blue';
  }

  setCurrentScreen(selectedOption, emailType) {
    this.selectedOption = selectedOption;
    this.emailType = emailType;
    console.log(this.selectedOption);
    console.log(this.emailType);

    const dropdownArray = ['WELCOME', 'REGISTRATION', 'REMINDER'];
    dropdownArray.forEach(element => {
      document.getElementById(element).className = 'dropdown-item';
    });
    document.getElementById('SETTINGS').className = 'dropdown-btn';
    if (emailType === '') {
      document.getElementById('SETTINGS').className = 'dropdown-btn active';
    } else {
      document.getElementById(emailType).className = 'dropdown-item active';
    }

    if (selectedOption === 'SETTINGS') {
      document.getElementById('SETTINGS').style.background = 'blue';
    } else {
      document.getElementById('SETTINGS').style.background = 'none';
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
