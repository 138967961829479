import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { BehaviorSubject } from 'rxjs';
import { SelfMember } from '../models/self-member.model';
import { FamilyClaimsCount } from '../models/family-claims-count.model';
import { FamilyClaimsDetails } from '../models/family-claims-details.model';
import { CorporateSummaryModel } from 'src/app/shared/models/corporate/CorporateSummaryModel';
import { rejects } from 'assert';
import { Notification } from 'src/app/shared/models/notification/notification.model';

@Injectable({
  providedIn: 'root',
})
export class CPMemberService {
  cpmemberbaseapi = 'CorporatePortal';
  // allPolicies: BehaviorSubject<any[]>;
  // policies: BehaviorSubject<any[]>;
  // currentPolicy: BehaviorSubject<any>;

  selfMemberDetails: BehaviorSubject<any>;
  memberDetails: BehaviorSubject<any>;
  dependentDetails: BehaviorSubject<any[]>;

  hrDetails: BehaviorSubject<any>;
  isEmployeeBelongsToPolicy = false;
  isHrView: BehaviorSubject<any>;

  familyClaimsCount: FamilyClaimsCount[] = [];
  familyClaimsDetails: FamilyClaimsDetails[] = [];

  unSeenMemberNotificationCount = 0;
  unSeenHrNotificationCount = 0;

  notifications: BehaviorSubject<any>;

  corporate: CorporateSummaryModel = new CorporateSummaryModel();

  public isHr: boolean;
  public isMember: boolean;

  constructor(private readonly backendService: BackendService) {
    // this.allPolicies = new BehaviorSubject<any[]>([]);
    // this.policies = new BehaviorSubject<any[]>([]);
    // this.currentPolicy = new BehaviorSubject<any>({});
    this.memberDetails = new BehaviorSubject<any>([]);
    this.hrDetails = new BehaviorSubject<any>({});
    this.selfMemberDetails = new BehaviorSubject<any>({});
    this.dependentDetails = new BehaviorSubject<any[]>([]);
    this.notifications = new BehaviorSubject<any[]>([]);
    this.isHrView = new BehaviorSubject<any>(false);
  }

  // async getPolicies(): Promise<any> {
  //   return new Promise(async resolve => {
  //     if (this.allPolicies.value.length > 0) {
  //       resolve(this.allPolicies.value);
  //     }
  //     const result = await this.backendService.get(this.cpmemberbaseapi + '/member/policies');
  //     this.allPolicies.next(result);
  //     this.policies.next(result);
  //     if (result.length > 0) {
  //       this.currentPolicy.next(result[0]);
  //     }
  //     resolve(result);
  //   });
  // }

  async IsMemberOrHr(): Promise<any> {
    return new Promise(async resolve => {
      const isMemberOrHr = await this.backendService.get(this.cpmemberbaseapi + '/employee/roles');
      this.isHr = isMemberOrHr.isHR;
      this.isMember = isMemberOrHr.isMember;
      this.isHrView.next(this.isHr);
      resolve(isMemberOrHr);
    });
  }

  async getMemberOrHrCorporate(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/memberOrHr/corporate').then(
        result => {
          this.corporate = result;
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async getAllRelationships(): Promise<any>{
    return new Promise(async (resolve, reject) => {
      await this.backendService.get(this.cpmemberbaseapi + '/intimatorRelationList')
            .then(res => resolve(res))
            .catch(error => reject(error))
    });
  }

  async giveConsent(identityServerId): Promise<any>{
    return new Promise(async (resolve, reject) => {
      await this.backendService.put("user/consent/"+identityServerId, null)
            .then(res => {
              resolve(res)
            })
            .catch(error => {
              reject(error)
            })
    });
  }

  async getPACIBenefits(policyNumber: string): Promise<any>{
    
    return new Promise(async (resolve, reject) => {
      await this.backendService.get("CorporatePortal/GetAllBenefit/policyno/"+policyNumber).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error);
      })
    })
  }

  async viewAllUploadedDocuments(claimNo): Promise<any>{
    return new Promise(async (resolve, reject) => {
      await this.backendService.get(this.cpmemberbaseapi + '/DocumentListByClaim/'+claimNo)
            .then(res => resolve(res))
            .catch(error => reject(error))
    });
  }

  async getHRProfile(): Promise<any> {
    if (this.hrDetails.value.name !== undefined) {
      return new Promise(async resolve => {
        resolve(this.hrDetails.value);
      });
    }
    return new Promise(async resolve => {
      const result = await this.backendService.get(this.cpmemberbaseapi + '/HR/Profile');
      this.hrDetails.next(result);
      resolve(result);
    });
  }

  // Policy Coverage
  async getPolicyCoverage(policyNo): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.get(this.cpmemberbaseapi + '/PolicyCoverages/policies/' + policyNo).then(
        result => {
          return resolve(result);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async getPolicyAllowedSumInsured(policyNo): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.get(
        this.cpmemberbaseapi + '/policies/' + policyNo + '/AllowedsumInsured'
      );
      resolve(result);
    });
  }

  async getHRPolicyCoverage(policyNo, empId, sumInsured): Promise<any> {
    if (empId === undefined) {
      empId = '';
    }
    return new Promise(async (resolve, reject) => {
      const result = await this.backendService
        .get(
          this.cpmemberbaseapi +
            `/PolicyCoverages/policies/${policyNo}/Member/EmployeeId/SumInsured?employeeId=${empId}&sumInsured=${sumInsured}`
        )
        .then(
          resolv => {
            return resolve(resolv);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  // Intimate Claim
  async getPolicyInfo(policyNo): Promise<any> {
    return new Promise(async resolve => {
      const policyInfo = await this.backendService.get(this.cpmemberbaseapi + '/policies/' + policyNo + '/Information');
      resolve(policyInfo);
    });
  }

  async searchHospital(hospitalName, cityId): Promise<any> {
    return new Promise(async resolve => {
      const documents = await this.backendService.get(
        this.cpmemberbaseapi + '/hospitals/search/hospitalName/' + hospitalName + '/cityId/' + cityId
      );
      resolve(documents);
    });
  }

  async getIntimateClaimDocuments(IntimationType:string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/IntimateClaims/Documents/'+IntimationType).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async getIntimateClaimDocumentsPACI(claimNumber: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/IntimateClaims/PACIDocumentByClaimNo/claimno/'+claimNumber).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      )
    });
  }

  async getPACIIntimateClaimDocuments(productTypeId, lossTypeId: number, benefitTypeId: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/PACIDocumentBenefitList/'+productTypeId+'/'+lossTypeId+'/'+benefitTypeId).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      )
    })
  }

  async getIntimateClaimDocumentsOPD(category:string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/IntimateClaims/OPDDocuments/' + category).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async createIntimateClaim(claim): Promise<any> {
    return new Promise(async resolve => {
      const intimateClaim = await this.backendService.post(this.cpmemberbaseapi + '/IntimateClaims', claim);
      resolve(intimateClaim);
    });
  }

  public submitClaims(data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.postFormData(this.cpmemberbaseapi + '/IntimateClaims', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public submitNomineeEnroll(data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post(this.cpmemberbaseapi + '/EnrollmentData', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public submitPACIClaims(data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.postFormData(this.cpmemberbaseapi + '/IntimatePACIClaims', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      )
    })
  }

  // Profile
  async getMemberFamily(policyNumber): Promise<any> {
    //debugger;
    if (this.selfMemberDetails.value.policyNo === policyNumber && this.memberDetails.value.length > 0) {
      return new Promise(async resolve => {
        resolve(this.memberDetails.value);
      });
    }

    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/policies/' + policyNumber + '/members/family').then(
        memberFamily => {
          const dep = [];
          memberFamily = memberFamily.filter(member => {
            const dateOfSep = new Date(member.dos);
            return dateOfSep.getFullYear() == 1;
          })
          this.memberDetails.next(memberFamily);
          memberFamily.forEach(element => {
            if (element.relationShipId != null && element.relationShipId.toUpperCase() === 'SELF') {
              this.isEmployeeBelongsToPolicy = true;
              this.selfMemberDetails.next(element);
            } else {
              //add to the dependent array only if the dos is not present
              const dateOfSep = new Date(element.dos);
              if(dateOfSep.getFullYear() == 1){
                dep.push(element);
              }
            }
          });
          this.dependentDetails.next(dep);
          resolve(memberFamily);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async getMember(policyNumber): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.get(this.cpmemberbaseapi + '/policies/' + policyNumber + '/member');
      resolve(result);
    });
  }

  // Track Claims
  async getFamilyClaimsCount(policyNumber): Promise<any> {
    if (this.familyClaimsCount.length > 0 && this.familyClaimsCount[0].policyNo === policyNumber) {
      return new Promise(async resolve => {
        resolve(this.familyClaimsCount);
      });
    }
    return new Promise(async resolve => {
      const result = await this.backendService.get(
        this.cpmemberbaseapi + '/policies/' + policyNumber + '/members/familyClaimsCount'
      );

      this.familyClaimsCount = result;
      resolve(result);
    });
  }

  async getFamilyClaimsDetails(policyNumber): Promise<any> {
    if (this.familyClaimsDetails.length > 0 && this.familyClaimsDetails[0].policyNo === policyNumber) {
      return new Promise(async resolve => {
        resolve(this.familyClaimsDetails);
      });
    }
    return new Promise(async resolve => {
      const result = await this.backendService.get(
        this.cpmemberbaseapi + '/policies/' + policyNumber + '/members/familyclaimdetails'
      );
      this.familyClaimsDetails = result;
      resolve(result);
    });
  }

  async getClaimFile(inwardNo): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/downloaddetails/inwardno/' + inwardNo).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  // Search Track Claims
  async getHrFamilyClaimsCount(policyNumber, employeeId, abhaId, abhaAddress): Promise<any> {
    if(employeeId == null || employeeId == undefined || employeeId == ''){
      employeeId = 0;
    }
    if(abhaId == null || abhaId == undefined || abhaId == ''){
      abhaId = 0;
    }
    if(abhaAddress == null || abhaAddress == undefined || abhaAddress == ''){
      abhaAddress = 0;
    }
    return new Promise(async (resolve, reject) => {
      this.backendService
        .get(
          this.cpmemberbaseapi +
            `/policies/${policyNumber}/members/familyClaimsCount/employeeId?employeeId=${employeeId}&insuredabhaId=${abhaId}&insuredabhaaddress=${abhaAddress}`
        )
        .then(
          result => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  async getHrFamilyClaimsDetails(policyNumber, employeeId, abhaId, abhaAddress): Promise<any> {
    if(employeeId == null || employeeId == undefined || employeeId == ''){
      employeeId = 0;
    }
    if(abhaId == null || abhaId == undefined || abhaId == ''){
      abhaId = 0;
    }
    if(abhaAddress == null || abhaAddress == undefined || abhaAddress == ''){
      abhaAddress = 0;
    }
    return new Promise(async (resolve, reject) => {
      this.backendService
        .get(
          this.cpmemberbaseapi +
            `/policies/${policyNumber}/members/familyclaimdetails/employeeId?employeeId=${employeeId}&insuredabhaId=${abhaId}&insuredabhaaddress=${abhaAddress}`
        )
        .then(
          result => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  async getClaimsDetails(claimNumber): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const claimsDetails = await this.backendService
        .get(this.cpmemberbaseapi + '/ClaimsDetails/claimno/' + claimNumber)
        .then(
          result => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  async getBasicClaimsDetails(claimNumber): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/ClaimsDetails/basic/claimno/' + claimNumber).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async getClaimsJournery(claimNumber): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const claimjourney = await this.backendService
        .get(this.cpmemberbaseapi + '/claimjourney/claimno/' + claimNumber)
        .then(
          result => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  async getClaimsPaymentDetails(claimNumber): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/ClaimsPaymentDetails/claimno/' + claimNumber).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async getClaimsCommunicationDetails(claimNumber): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/ClaimsCommunicationDetails/claimno/' + claimNumber).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async getDisallowedDetails(claimNumber): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/disalloweddetails/claimno/' + claimNumber).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async getECards(policyNumber, uhid): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const ecard = await this.backendService
        .downloadPDF(this.cpmemberbaseapi + '/ecard/policies/' + policyNumber + '/Memberuhid/' + uhid)
        .then(result => {
              const blob = new Blob([result], {
                type: 'application/pdf',
              });
              return resolve(blob);
          },
          error => reject(error)
        );
    });
  }

  async getQueryDocument(claimNo): Promise<any>{
    return new Promise(async (resolve, reject) => {
      await this.backendService.get(this.cpmemberbaseapi + '/downloaddetails/inwardno/'+claimNo)
      .then(result => resolve(result),
            error => reject(error));
    });
  }

  async downloadUploadedDocuments(documentDetailId): Promise<any>{
    return new Promise(async (resolve, reject) => {
      await this.backendService.get(this.cpmemberbaseapi + '/DownloadDocument/'+documentDetailId)
      .then(result => {
        resolve(result);
        console.log(result);
        })
      .catch(error => reject(error));
      });
  }

  async downloadEcardUHIDWise(policyNumber, data): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const ecard = await this.backendService
        .post(`${this.cpmemberbaseapi}/Uhid/Ecards/policies/${policyNumber}`, data)
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  async downloadEcardPolicyWise(policyNumber): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const ecard = await this.backendService
        .post(`${this.cpmemberbaseapi}/PolicyEcards/policies/${policyNumber}`, {
          policyNumber: policyNumber,
        })
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  async emailECards(policyNumber, uhid, email): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const ecard = await this.backendService
        .post(this.cpmemberbaseapi + '/ecard/policies/' + policyNumber + '/Memberuhid/' + uhid + '/email', {
          email: email,
        })
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  // Notification
  public createNotification(data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.postFormData(this.cpmemberbaseapi + '/Notifications/create', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async editNotification(id, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.put('CorporatePortal/Notifications/' + id, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
  async getHrNotification(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + '/Notifications/HR').then(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async deleteNotification(notificationId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const result = await this.backendService.delete(this.cpmemberbaseapi + `/Notifications/${notificationId}`).then(
        resolv => {
          return resolve(resolv);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async getNotificationCreatedByUser(): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.get(this.cpmemberbaseapi + '/Notifications/createdBy/user');
      this.notifications.next([]);
      this.notifications.next(result);
      resolve(result);
    });
  }

  async UpdateMemberSeenNotification(policyId): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.post(
        this.cpmemberbaseapi + `/Policies/${policyId}/Notifications/Member/seen`,
        ''
      );
      resolve(result);
    });
  }

  async UpdateHrSeenNotification(): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.post(this.cpmemberbaseapi + `/Notifications/HR/seen`, '');
      resolve(result);
    });
  }

  async GetUnSeenMemberNotificationCount(): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.get(this.cpmemberbaseapi + `/Notifications/Member/Unseen/count`);
      if(result == null)
      {
        this.unSeenMemberNotificationCount = 0;
      }
      else{
      this.unSeenMemberNotificationCount = result.count??0;
      }
      resolve(result);
    });
  }

  async GetUnSeenHrNotificationCount(): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.get(this.cpmemberbaseapi + `/Notifications/HR/Unseen/count`);
      if(result == null)
      {
          this.unSeenHrNotificationCount=0;
      }
      else{
      this.unSeenHrNotificationCount = result.count??0;
      }
      resolve(result);
    });
  }

  async getMemberNotification(policyId): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.get(this.cpmemberbaseapi + `/Policies/${policyId}/Notifications/Member`);
      resolve(result);
    });
  }

  async getMemberCommonNotification(): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.get(this.cpmemberbaseapi + `/Notifications/Member/common`);
      resolve(result);
    });
  }

  async searchIntimateClaimsByEmpId(policyNo, employeeId, abhaId, abhaAddress): Promise<any> {
    if(employeeId == null || employeeId == '' || employeeId == undefined){
      employeeId = 0;
    }
    if(abhaId == null || abhaId == '' || abhaId == undefined){
      abhaId = 0;
    }
    if(abhaAddress == null || abhaAddress == '' || abhaAddress == undefined){
      abhaAddress = 0;
    }
    return new Promise(async (resolve, reject) => {
      const result = await this.backendService
        .get(this.cpmemberbaseapi + `/policies/${policyNo}/members/family/employeeId?employeeId=${employeeId}&insuredabhaId=${abhaId}&insuredabhaaddress=${abhaAddress}`)
        .then(
          resolv => {
            return resolve(resolv);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  // Reports
  async getHealthEnrollmentMISExcelReport(policyNo, status): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .downloadFile(this.cpmemberbaseapi + `/Report/HealthEnrollmentMIS/Excel/policyno/${policyNo}/status/${status}`)
        .then(
          fileBlob => {
            const blob = new Blob([fileBlob], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            return resolve(blob);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  async getUserIdMISExcelReport(policyNo): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.downloadFile(this.cpmemberbaseapi + `/Report/UserMIS/Excel/policyno/${policyNo}`).then(
        fileBlob => {
          const blob = new Blob([fileBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          return resolve(blob);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async getHealthClaimMISExcelReport(policyNo, claimtype, fromDate, toDate): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .downloadFile(
          this.cpmemberbaseapi +
            `/Report/HealthClaimMIS/Excel/policyno/${policyNo}/claimtype/${claimtype}/fromdate/${fromDate}/todate/${toDate}`
        )
        .then(
          fileBlob => {
            const blob = new Blob([fileBlob], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            return resolve(blob);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  async getHealthClaimMISView(policyNo, claimtype, fromDate, toDate): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.get(
        this.cpmemberbaseapi +
          `/Report/HealthClaimMIS/policyno/${policyNo}/claimtype/${claimtype}/fromdate/${fromDate}/todate/${toDate}`
      );
      resolve(result);
    });
  }

  // Dashboard
  async getStaticDocuments(policyId, documentType): Promise<any> {
    return new Promise(async resolve => {
      const result = await this.backendService.get(
        this.cpmemberbaseapi + `/policies/${policyId}/documents/type/${documentType}`
      );
      resolve(result);
    });
  }

  async emailContactUs(data): Promise<any> {
    const uhid = 'b14ca5898a4e4133bbce2ea2315a1916';
    return new Promise(async (resolve, reject) => {
      await this.backendService.post('/ContactUs/' + uhid + '/email', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async submitFinalDeclaration(data): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.post('Claim/submitdeclaration', data).then(
        response => {
          return resolve(response);
        },
        error => {
          console.log(error);
          return reject(error);
        }
      );
    });
  }

  async getDeclaration(claimNo): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.get('Claim/getDeclaration/claimno/' + claimNo).then(
        response => {
          return resolve(response);
        },
        error => {
          console.log(error);
          return reject(error);
        }
      );
    });
  }

  async uploadDocument(data): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.postFormData('Claim/uploaddocument', data).then(
        response => {
          return resolve(response);
        },
        error => {
          console.log(error);
          return reject(error);
        }
      );
    });
  }

  async allowedDocument(claimNo): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.get('Claim/allowdocument/claimno/' + claimNo).then(
        response => {
          return resolve(response);
        },
        error => {
          console.log(error);
          return reject(error);
        }
      );
    });
  }

  async isDocumentStatusQueried(claimNo): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.get('Claim/QueriedStatus/claimno/' + claimNo).then(
        response => {
          return resolve(response);
        },
        error => {
          console.log(error);
          return reject(error);
        }
      );
    });
  }

  async getRelationshipDetails(data):Promise<any>{
    return new Promise(async (resolve, reject) =>{
      await this.backendService.post(this.cpmemberbaseapi + '/relationshipdetails',data).then(
        response => {return resolve(response);},
        error => {
          console.log(error);
          return reject(error);
        }        
      );
    });
  }
}
