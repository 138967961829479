export class Policy {
  additionOfDependents: boolean;
  contactUs: boolean;
  downloadForms: boolean;
  faq: boolean;
  healthOPedia: boolean;
  hra: boolean;
  intimateClaims: boolean;
  intimatePACIClaims: boolean; 
  isActive: boolean;
  networkHospitalTracker: boolean;
  policyId: string;
  id: string;
  policyNumber: string;
  rHealthAssist: boolean;
  rHealthBeat: boolean;
  rHealthCircle: boolean;
  riskEndDate: string;
  riskStartDate: string;
  trackClaims: boolean;
  valueDeals: boolean;
  viewECard: boolean;
  viewPolicyCoverage: boolean;
  wellness: boolean;
  isMemberEnrolled: boolean;
  policyType: string;
  onScreenDisplayAvailable: boolean;
  onScreenDisplayPolicy: boolean;
  attendance:boolean;
  gpa:boolean;
  mobileClaim:boolean;
  nomineeEnroll: boolean;
  policyNote:boolean;
}
