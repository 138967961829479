<div class="sidebar-wrapper">
  <div class="logo">
    <div class="member-info-wrapper">
      <!-- <img src="assets/images/user-icon.png" alt="" /> -->

      <span *ngIf="!selfMember" class="shimmer primary lighter"></span>

      <span *ngIf="selfMember" class="username" matTooltip="{{ insuredName }}" matTooltipPosition="above">{{
        insuredName
      }}</span>
    </div>
    <div><a href="javascript:;" (click)="signout()" class="signout">Sign out</a></div>
    <i><img src="assets/images/user-icon.png" alt=""/></i>
  </div>
  <div *ngIf="!selfMember">
    <ul class="shimmer-wrapper">
      <li class="shimmer primary"></li>
    </ul>
    <ul class="shimmer-wrapper">
      <li class="shimmer primary"></li>
    </ul>
    <ul class="shimmer-wrapper">
      <li class="shimmer primary"></li>
    </ul>
  </div>
  <ul *ngIf="selfMember" class="list-unstyled sidebar-menu-wrapper">
    <li>
      <a (click)="closeSidebarMobile($event)" routerLink="/member/home" routerLinkActive="active"
        ><i class="icon-img">
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <title>Profile</title>
            <g>
              <path
                d="M24.576,24.839a10.489,10.489,0,0,0,7.664-3.175A10.487,10.487,0,0,0,35.415,14,10.49,10.49,0,0,0,32.24,6.337a10.493,10.493,0,0,0-7.664-3.174,10.487,10.487,0,0,0-7.663,3.175A10.487,10.487,0,0,0,13.738,14a10.487,10.487,0,0,0,3.175,7.663A10.49,10.49,0,0,0,24.576,24.839Z"
                style="fill: #09f"
              ></path>
              <path
                d="M43.541,37.765a26.806,26.806,0,0,0-.365-2.843,22.192,22.192,0,0,0-.7-2.859A14.117,14.117,0,0,0,41.3,29.4a10.1,10.1,0,0,0-1.772-2.31,7.818,7.818,0,0,0-2.546-1.6,8.786,8.786,0,0,0-3.25-.588,3.3,3.3,0,0,0-1.762.747c-.528.344-1.145.742-1.835,1.183a10.553,10.553,0,0,1-2.374,1.046,9.225,9.225,0,0,1-5.808,0,10.454,10.454,0,0,1-2.373-1.046c-.683-.436-1.3-.835-1.837-1.184a3.3,3.3,0,0,0-1.761-.747,8.777,8.777,0,0,0-3.249.589,7.8,7.8,0,0,0-2.546,1.6A10.062,10.062,0,0,0,8.417,29.4a14.181,14.181,0,0,0-1.175,2.666,22.57,22.57,0,0,0-.7,2.859,26.692,26.692,0,0,0-.365,2.844c-.059.861-.09,1.755-.09,2.657a7.474,7.474,0,0,0,2.22,5.654,7.987,7.987,0,0,0,5.718,2.086H35.694a7.987,7.987,0,0,0,5.718-2.086,7.471,7.471,0,0,0,2.22-5.654c0-.906-.031-1.8-.091-2.658Z"
                style="fill: #09f"
              ></path>
            </g>
          </svg>
        </i>
        <span>My Profile</span></a
      >
    </li>
    <li>
      <a (click)="closeSidebarMobile($event)" routerLink="/member/enrollment" routerLinkActive="active"
        ><i class="icon-img">
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <title>Member Enrollment</title>
            <g>
              <path
                d="M35.671,27.6c.009.182.02.288.02.394,0,4.623.015,9.246-.009,13.869a3.927,3.927,0,0,1-1.857,3.48,3.971,3.971,0,0,1-2.2.586q-12.052,0-24.107,0a4.047,4.047,0,0,1-3.105-1.371,4.426,4.426,0,0,1-.78-1.484,6.909,6.909,0,0,1-.225-2.612C3.4,29.981,3.378,19.5,3.424,9.02A3.914,3.914,0,0,1,5.766,5.389a3.791,3.791,0,0,1,1.689-.325q12.072,0,24.143,0a3.853,3.853,0,0,1,3.617,2.09,3.985,3.985,0,0,1,.467,1.8c.033.821.02,1.645,0,2.468a.823.823,0,0,1-.2.518c-.8.8-1.627,1.586-2.446,2.374a.918.918,0,0,1-.167.091c-.007-.169-.017-.3-.017-.423,0-1.609-.032-3.219.012-4.828a1.328,1.328,0,0,0-1.322-1.31q-12,.033-24,.015A1.146,1.146,0,0,0,6.314,8.915a4.069,4.069,0,0,0-.033.615q0,16.011,0,32.023c0,.635.081,1.229.774,1.486a1.971,1.971,0,0,0,.673.091q11.073.006,22.147,0a14.976,14.976,0,0,1,1.669.013,1.34,1.34,0,0,0,1.329-1.312c-.031-3.666-.01-7.334-.022-11a1.149,1.149,0,0,1,.4-.946c.733-.666,1.429-1.373,2.142-2.062C35.456,27.76,35.534,27.706,35.671,27.6Z"
                style="fill: #09f"
              ></path>
              <path
                d="M19.622,10.849H29.711c.551,0,.548,0,.548.534,0,1.754.007,3.509-.011,5.262a.945.945,0,0,1-.238.6q-1.374,1.368-2.8,2.684a1.14,1.14,0,0,1-.7.286c-5.722.015-11.444.009-17.166.017-.333,0-.438-.107-.436-.44q.015-4.264,0-8.528c0-.358.132-.42.444-.419Q14.487,10.856,19.622,10.849Zm-.1,6.576H26.89c.49,0,.537-.047.538-.543q0-1.361,0-2.721c0-.447-.092-.541-.526-.541q-7.329,0-14.656,0c-.455,0-.576.122-.579.589-.006.858,0,1.717,0,2.575,0,.591.05.641.642.641Z"
                style="fill: #09f"
              ></path>
              <path
                d="M43.628,8.512c.5.494,1,.969,1.482,1.458.543.552,1.072,1.115,1.607,1.674.386.4.384.4-.049.822q-3.753,3.633-7.5,7.267L28.79,29.773c-.077.074-.161.141-.276.241-.571-.577-1.129-1.142-1.69-1.7-.52-.522-1.038-1.048-1.568-1.56-.2-.189-.227-.314-.008-.525q6.66-6.417,13.307-12.848,2.425-2.344,4.855-4.687A2.766,2.766,0,0,1,43.628,8.512Z"
                style="fill: #09f"
              ></path>
              <path
                d="M19.6,37.434H29.626c.619,0,.619,0,.619.636,0,.593-.008,1.186,0,1.779.005.253-.093.359-.34.34-.072-.005-.145,0-.218,0H9.466c-.544,0-.543,0-.543-.532,0-.593.02-1.187-.008-1.779-.017-.355.1-.452.45-.451C12.778,37.438,16.191,37.434,19.6,37.434Z"
                style="fill: #09f"
              ></path>
              <path
                d="M14.189,26.96c-1.608,0-3.216-.009-4.824.006-.343,0-.467-.086-.451-.446.03-.664.015-1.33.006-2,0-.293.074-.43.409-.429q4.861.016,9.723,0c.318,0,.46.082.45.427q-.03,1.034,0,2.068c.008.294-.1.373-.379.371C17.479,26.955,15.834,26.959,14.189,26.96Z"
                style="fill: #09f"
              ></path>
              <path
                d="M14.223,31.057c1.61,0,3.219.009,4.828-.007.348,0,.467.1.452.452-.026.64-.017,1.282,0,1.923.006.276-.078.392-.369.391q-4.919-.009-9.838,0c-.283,0-.383-.1-.376-.384.015-.629.005-1.258.005-1.887,0-.486,0-.487.472-.488Z"
                style="fill: #09f"
              ></path>
              <path d="M21.979,33.108,23.8,27.9l3.421,3.405Z" style="fill: #09f"></path>
            </g>
          </svg> </i
        ><span> Member Enrollment </span></a
      >
    </li>
    <li>
      <a
        (click)="closeSidebarMobile($event)"
        routerLink="/member/generate/reports"
        [hidden]="true"
        routerLinkActive="active"
        ><i class="icon-img">
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <title>Generate Report</title>
            <g>
              <path
                d="M35.671,27.6c.009.182.02.288.02.394,0,4.623.015,9.246-.009,13.869a3.927,3.927,0,0,1-1.857,3.48,3.971,3.971,0,0,1-2.2.586q-12.052,0-24.107,0a4.047,4.047,0,0,1-3.105-1.371,4.426,4.426,0,0,1-.78-1.484,6.909,6.909,0,0,1-.225-2.612C3.4,29.981,3.378,19.5,3.424,9.02A3.914,3.914,0,0,1,5.766,5.389a3.791,3.791,0,0,1,1.689-.325q12.072,0,24.143,0a3.853,3.853,0,0,1,3.617,2.09,3.985,3.985,0,0,1,.467,1.8c.033.821.02,1.645,0,2.468a.823.823,0,0,1-.2.518c-.8.8-1.627,1.586-2.446,2.374a.918.918,0,0,1-.167.091c-.007-.169-.017-.3-.017-.423,0-1.609-.032-3.219.012-4.828a1.328,1.328,0,0,0-1.322-1.31q-12,.033-24,.015A1.146,1.146,0,0,0,6.314,8.915a4.069,4.069,0,0,0-.033.615q0,16.011,0,32.023c0,.635.081,1.229.774,1.486a1.971,1.971,0,0,0,.673.091q11.073.006,22.147,0a14.976,14.976,0,0,1,1.669.013,1.34,1.34,0,0,0,1.329-1.312c-.031-3.666-.01-7.334-.022-11a1.149,1.149,0,0,1,.4-.946c.733-.666,1.429-1.373,2.142-2.062C35.456,27.76,35.534,27.706,35.671,27.6Z"
                style="fill: #09f"
              ></path>
              <path
                d="M19.622,10.849H29.711c.551,0,.548,0,.548.534,0,1.754.007,3.509-.011,5.262a.945.945,0,0,1-.238.6q-1.374,1.368-2.8,2.684a1.14,1.14,0,0,1-.7.286c-5.722.015-11.444.009-17.166.017-.333,0-.438-.107-.436-.44q.015-4.264,0-8.528c0-.358.132-.42.444-.419Q14.487,10.856,19.622,10.849Zm-.1,6.576H26.89c.49,0,.537-.047.538-.543q0-1.361,0-2.721c0-.447-.092-.541-.526-.541q-7.329,0-14.656,0c-.455,0-.576.122-.579.589-.006.858,0,1.717,0,2.575,0,.591.05.641.642.641Z"
                style="fill: #09f"
              ></path>
              <path
                d="M43.628,8.512c.5.494,1,.969,1.482,1.458.543.552,1.072,1.115,1.607,1.674.386.4.384.4-.049.822q-3.753,3.633-7.5,7.267L28.79,29.773c-.077.074-.161.141-.276.241-.571-.577-1.129-1.142-1.69-1.7-.52-.522-1.038-1.048-1.568-1.56-.2-.189-.227-.314-.008-.525q6.66-6.417,13.307-12.848,2.425-2.344,4.855-4.687A2.766,2.766,0,0,1,43.628,8.512Z"
                style="fill: #09f"
              ></path>
              <path
                d="M19.6,37.434H29.626c.619,0,.619,0,.619.636,0,.593-.008,1.186,0,1.779.005.253-.093.359-.34.34-.072-.005-.145,0-.218,0H9.466c-.544,0-.543,0-.543-.532,0-.593.02-1.187-.008-1.779-.017-.355.1-.452.45-.451C12.778,37.438,16.191,37.434,19.6,37.434Z"
                style="fill: #09f"
              ></path>
              <path
                d="M14.189,26.96c-1.608,0-3.216-.009-4.824.006-.343,0-.467-.086-.451-.446.03-.664.015-1.33.006-2,0-.293.074-.43.409-.429q4.861.016,9.723,0c.318,0,.46.082.45.427q-.03,1.034,0,2.068c.008.294-.1.373-.379.371C17.479,26.955,15.834,26.959,14.189,26.96Z"
                style="fill: #09f"
              ></path>
              <path
                d="M14.223,31.057c1.61,0,3.219.009,4.828-.007.348,0,.467.1.452.452-.026.64-.017,1.282,0,1.923.006.276-.078.392-.369.391q-4.919-.009-9.838,0c-.283,0-.383-.1-.376-.384.015-.629.005-1.258.005-1.887,0-.486,0-.487.472-.488Z"
                style="fill: #09f"
              ></path>
              <path d="M21.979,33.108,23.8,27.9l3.421,3.405Z" style="fill: #09f"></path>
            </g>
          </svg> </i
        ><span>Generate Reports</span></a
      >
    </li>
  </ul>
</div>
