import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CorporateModel } from '../../../shared/models/corporate/corporate.model';
import { CorporateService } from '../../services/corporate.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-corporate-details',
  templateUrl: './corporate-details.component.html',
  styleUrls: ['./corporate-details.component.scss'],
})
export class CorporateDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  corporate: CorporateModel;

  constructor(
    injector: Injector,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly corporateService: CorporateService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptionsToDestroy.push(
      this.route.params.subscribe(async params => {
        const id = parseInt(params['corporateId'], 10);
        this.corporate = await this.corporateService.get(id);
        if (!this.corporate) {
          this.router.navigate(['/404']);
        }
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
