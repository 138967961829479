<div class="members-main-wrapper">
  <button class="toggle-sidebar" (click)="toggleSidebar($event)">
    <span class="arrow-left"><i class="material-icons">keyboard_arrow_left</i></span>
    <span class="arrow-right"><i class="material-icons">keyboard_arrow_right</i></span>
  </button>
  <app-cp-side-bar></app-cp-side-bar>
  <app-cp-header></app-cp-header>
  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
