<form class="w-100" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="col-12 pt-3 pb-4">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Document Type <sup>*</sup></label>
          <select formControlName="documentType" (ngModelChange)="resetValue()" class="form-control">
            <option value="Claims">Claims</option>
            <option value="Enrollment">Enrollment</option>
            <option value="Policy">Policy</option>
            <option value="Others">Others</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button [disabled]="!form.valid" class="btn btn-primary w-100">Get Document</button>
      </div>
    </div>
  </div>
</form>


<div *ngIf="isdocumentLoaded && documents.length === 0" class="no-result-wrapper">
  <h5>No Documents Found!</h5>
</div>
<div class="row" *ngIf="isdocumentLoaded && documents.length > 0">
  <div class="container-fluid">
    <div class="list-component-body">
      <table>
        <thead>
          <tr>
            <th>S No.</th>
            <th>Document Name</th>
            <th>Document type</th>
            <th>Document type name</th>
            <th class="text-center">Document link</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let document of documents; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ document.documentName }}</td>
            <td>{{ document.documentType }}</td>
            <td>{{ document.documentTypeName }}</td>
            <td class="text-center">
              <a href="{{ document.documentUrl }}" target="_blank" class="text-secondary"><span
                  class="material-icons">description</span></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>