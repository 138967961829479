import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-shared',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit {
  @Input() public date;

  constructor() {}

  ngOnInit() {
    const minDate = moment.utc('1900-01-01'); // minimum value as per UTC

    if (!moment.utc(this.date).isAfter(minDate)) {
      this.date = '';
    }
  }
}
