import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PreEnrollmentMemberService } from '../../services/preEnrollment-member.service';
import { HRService } from '../../services/hr.service';
import { RoleService } from 'src/app/shared/services/role.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  insuredName: string;
  selfMember: any;
  isRoleHR: boolean;
  userRole: string;
  renderedOn: string;

  constructor(
    private authService: AuthService,
    private roleService: RoleService,
    private preEnrollmentMemberService: PreEnrollmentMemberService,
    private hrService: HRService
  ) {}

  async ngOnInit() {
    this.displayInsuredName();
    this.userRole = await this.roleService.getMemberRole();
    this.isRoleHR = this.userRole === 'HR';

    this.preEnrollmentMemberService.memberUpdate.subscribe(isUpdated => {
      if (isUpdated) {
        this.displayInsuredName();
        this.preEnrollmentMemberService.memberUpdate.next(false);
      }
    });
    if (navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)/i)) {
      this.renderedOn = 'Mobile';
    } else {
      this.renderedOn = 'Desktop';
    }
  }

  async displayInsuredName() {
    this.selfMember = await this.preEnrollmentMemberService.getMemberProfile();
    this.selfMember.forEach(element => {
      if (element['label'] === 'Insured Name') {
        this.insuredName = element['value'];
      }
    });
  }
  async signout() {
    await this.authService.signout();
  }
  closeSidebarMobile(event) {
    if (this.renderedOn === 'Mobile') {
      this.preEnrollmentMemberService.closedSideBarEventUpdate.next(event);
      event.currentTarget.offsetParent.parentElement.offsetParent.classList.remove('close-sidebar');
      event.currentTarget.offsetParent.parentElement.offsetParent.firstChild.classList.remove('closed');
    }
  }
}
