import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { BehaviorSubject } from 'rxjs';
import { Broker } from 'src/app/shared/models/CorporatePortal/broker.model';
import { CorporatePolicyModel } from 'src/app/corporate-portal/models/corporate-policy.model';

@Injectable({
  providedIn: 'root',
})
export class BrokerService {
  brokerProfile: BehaviorSubject<any>;
  brokerPrivileges: Broker;

  constructor(private readonly backendService: BackendService) {
    this.brokerProfile = new BehaviorSubject<any>({});
  }

  async getBrokerProfile(): Promise<any> {
    // if (this.brokerProfile.value !== null) {
    //   return new Promise(async resolve => {
    //     resolve(this.brokerProfile);
    //   });
    // }
    return new Promise(async resolve => {
      const result = await this.backendService.get('Broker');
      this.brokerProfile.next(result);
      resolve(result);
    });
  }

  async getBrokerByPolicy(policyId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.get(`Broker/policy/${policyId}`).then(
        resul => {
          this.brokerPrivileges = resul;
          return resolve(resul);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
}
