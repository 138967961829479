import { Component, OnInit } from '@angular/core';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { HRService } from '../../services/hr.service';
import { PreEnrollmentMemberModel } from 'src/app/shared/models/preEnrollment/members/preEnrollment-member.model';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { PreEnrollmentMemberService } from '../../services/preEnrollment-member.service';

@Component({
  selector: 'app-generate-reports',
  templateUrl: './generate-reports.html',
  styleUrls: ['./generate-reports.scss'],
})
export class GenerateReportsComponent implements OnInit {
  policies: PreEnrollmentPolicyModel[];
  selectedPolicy: PreEnrollmentPolicyModel;
  members: PreEnrollmentMemberModel[];
  dependentModuleVisibilityTracker: {};
  memberPropertyName = 'insuredName';
  searchText: string;
  isLoading = false;
  corporate: CorporateModel;

  constructor(private hrService: HRService, private readonly preEnrollmentMemberService: PreEnrollmentMemberService) {}

  ngOnInit(): void {
    this.preEnrollmentMemberService.getMemberCorporate().then(c => {
      this.corporate = c;
    });

    this.hrService.getPolicies().then(p => {
      this.policies = p;
      if (this.policies.length > 0) {
        this.selectedPolicy = this.policies[0];
        this.hrService.getMembers(this.selectedPolicy.id).then(m => {
          this.members = m;
          this.dependentModuleVisibilityTracker = {};
          for (let index = 0; index < this.members.length; index++) {
            this.dependentModuleVisibilityTracker[this.members[index].id] = false;
          }
        });
      }
    });
  }
}
