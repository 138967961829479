<app-popup-model
  [title]="title"
  (close)="close()"
  [saveButton]="saveButtonText"
  (save)="close()"
>
<div class="form-card">
  <div class="form-box scroll_class upload-documentation-wrapper">
    <h3 class="main-heading">Uploaded Documents</h3>
    <div *ngIf="documents.length === 0">
      No documents uploaded
    </div>
    <div *ngFor="let doc of documents">
        <!-- <span class="pb-1">{{doc.DocumentType}} -- {{doc.FileName}}</span> -->
        <!-- <span>
          <a
          (click)="downloadDocument(doc)"
          matTooltip="Click to download document."
          matTooltipPosition="above"
          >
            <img src="assets/images/download.png" height="24" width="24">
          </a>
        </span> -->
        <div class="upload-documentation-row pb-1">
          <div>
            <div>{{doc.DocumentType}}</div>
            <div>{{doc.FileName}}</div>
          </div>
          <div>
            <span *ngIf="!isDocumentLoading || doc.DocumentDetailId !== downloadDocumentDetailId">
              <a
              (click)="downloadDocument(doc)"
              matTooltip="Click to download document."
              matTooltipPosition="above"
              >
                <img src="assets/images/download.png" height="24" width="24">
              </a>
            </span>
            <p class="spinner-border spinner-border-sm myspinner"
                role="status"
                aria-hidden="true"
                *ngIf="isDocumentLoading && doc.DocumentDetailId === downloadDocumentDetailId"
            ></p>
          </div>
        </div>
    </div>
  </div>
</div>
</app-popup-model>