<div class="list-corporate-component-wrapper">
  <div class="list-corporate-component-header">
    <div class="container">
      <input
        type="text"
        placeholder="Search corporate"
        class="form-control search-box"
        [(ngModel)]="searchText"
        (input)="searchingCorporate($event)"
      />
      <a routerLink="../create" class="btn btn-primary text-white"
        ><i class="material-icons">add</i> <span>Create Corporate</span></a
      >
    </div>
  </div>

  <div class="container">
    <div class="list-corporate-component-body">
      <table>
        <thead>
          <tr>
            <th>Corporate Name</th>
            <th>Code</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          <tr [hidden]="!isLoading">
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer circle"></div>
                <div class="shimmer primary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer secondary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer secondary"></div>
              </div>
            </td>
          </tr>
          <tr [hidden]="!isLoading">
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer circle"></div>
                <div class="shimmer primary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer secondary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer secondary"></div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="corporates">
          <tr *ngFor="let corporate of corporates" [hidden]="isLoading || corporates.length === 0">
            <td>
              <div class="corporate-identity-box">
                <a routerLink="../{{ corporate.id }}">
                  <figure><img src="{{ corporate.logoImage }}" alt="" /></figure>
                </a>
                <h6>
                  <a routerLink="../{{ corporate.id }}">{{ corporate.name }}</a>
                </h6>
              </div>
            </td>
            <td>
              {{ corporate.code }}
            </td>
            <td>
              {{ corporate.createdAt.split(' ')[0] | date: 'dd MMMM yyyy' }}
            </td>
          </tr>
          <tr>
            <td colspan="3" class="border-0 p-0">
              <div class="pagination-wrapper">
                <ngb-pagination
                  [collectionSize]="totalCorporateCount"
                  [pageSize]="pageSize"
                  [(page)]="page"
                  [maxSize]="5"
                  (pageChange)="loadPage($event)"
                  [boundaryLinks]="true"
                  [rotate]="true"
                  [hidden]="corporates.length === 0 || isLoading || isSearchingCorporate"
                ></ngb-pagination>
                <ngb-pagination
                  [collectionSize]="totalCorporateSearchCount"
                  [pageSize]="pageSizeCopy"
                  [(page)]="pageCopy"
                  [boundaryLinks]="true"
                  (pageChange)="loadSearchPage($event)"
                  [maxSize]="5"
                  [rotate]="true"
                  [hidden]="isLoading || corporates.length === 0 || !isSearchingCorporate"
                ></ngb-pagination>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
