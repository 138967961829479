import { NgModule, OnInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/auth.guard';
import { ProfileComponent } from './components/profile/profile.component';
import { EndorsementComponent } from '../corporate-portal/components/endorsement/endorsement.component';
import { TrackClaimsComponent } from '../corporate-portal/components/track-claims/track-claims.component';
import { IntimateClaimComponent } from '../corporate-portal/components/intimate-claim/intimate-claim.component';
import { PolicyCoverageComponent } from '../corporate-portal/components/policy-coverage/policy-coverage.component';
import { ECardsComponent } from '../corporate-portal/components/e-cards/e-cards.component';
import { DownloadFormsComponent } from '../corporate-portal/components/download-forms/download-forms.component';
import { FaqComponent } from '../corporate-portal/components/faq/faq.component';
import { ContactUsComponent } from '../corporate-portal/components/contact-us/contact-us.component';
import { CpMemberEnrollmentComponent } from '../cp-member/components/cp-member-enrollment/cp-member-enrollment.component';
import { NotificationsComponent } from '../corporate-portal/components/notifications/notifications.component';
import { ReportComponent } from '../corporate-portal/components/report/report.component';
import { DashboardComponent } from '../corporate-portal/components/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { roles: ['Broker'] },
    children: [
      { path: 'profile', component: ProfileComponent },
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'notification', component: NotificationsComponent },
      { path: 'endorsement', component: EndorsementComponent },
      { path: 'report', component: ReportComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'track-claims', component: TrackClaimsComponent },
      { path: 'intimate-claim', component: IntimateClaimComponent },
      { path: 'policy-coverage', component: PolicyCoverageComponent },
      { path: 'e-cards', component: ECardsComponent },
      { path: 'download-forms', component: DownloadFormsComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'contact', component: ContactUsComponent },
      { path: 'enrollment', component: CpMemberEnrollmentComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BrokerRoutingModule {
  constructor() {}
}
