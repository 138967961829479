import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncruptioDecryptionService {
  constructor(private readonly router: Router) {}

  encrypt(msg, pass) {
    // random salt for derivation
    const keySize = 256;
    const salt = CryptoJS.lib.WordArray.random(16);
    // well known algorithm to generate key
    const key = CryptoJS.PBKDF2(pass, salt, {
      keySize: keySize / 32,
      iterations: 100,
    });
    // random IV
    const iv = CryptoJS.lib.WordArray.random(128 / 8);
    // specify everything explicitly
    const encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    // combine everything together in base64 string
    const result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
    return result;
  }
}
