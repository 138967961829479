<div class="list-component-wrapper">
  <div class="list-component-header" [hidden]="isLoading">
    <div class="row">
      <div class="container">
        <input type="text" placeholder="Search SM" class="form-control search-box" [(ngModel)]="searchText" />
        <button class="btn btn-primary text-white" (click)="open()">
          <i class="material-icons">add</i> <span>Create SM</span>
        </button>
      </div>
    </div>
  </div>
  <div [hidden]="smList.length !== 0 || isLoading === true" class="no-result-wrapper">
    <h5>No SM found!</h5>
  </div>

  <div class="row">
    <div class="container">
      <div class="list-component-body">
        <table>
          <thead [hidden]="smList.length === 0">
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th>Last Logged In</th>
              <!-- <th>Email Sent On</th>
              <th>SMS Sent On</th> -->
              <th>Created On</th>
              <th><div class="float-right">Action</div></th>
            </tr>
          </thead>
          <tbody>
            <tr [hidden]="!isLoading">
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer primary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper float-right">
                  <div class="shimmer tertiary"></div>
                </div>
              </td>
            </tr>
            <tr [hidden]="!isLoading">
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer primary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <!-- <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td> -->
              <td>
                <div class="shimmer-wrapper float-right">
                  <div class="shimmer tertiary"></div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody
            *ngIf="smList | searchFilter: searchText:smProperty as result"
            [hidden]="isLoading || smList.length === 0"
          >
            <tr *ngFor="let sm of result | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
              <td>{{ sm.code }}</td>
              <td>{{ sm.name }}</td>
              <td>{{ sm.email }}</td>
              <td>{{ sm.mobileNo }}</td>
              <td><app-date-shared [date]="sm.lastLoggedInAt"> </app-date-shared></td>
              <!-- <td><app-date-shared [date]="sm.loginEmailSentAt"> </app-date-shared></td>
              <td><app-date-shared [date]="sm.loginSMSSentAt"> </app-date-shared></td> -->
              <td><app-date-shared [date]="sm.createdAt"> </app-date-shared></td>
              <td>
                <div class="position-relative">
                  <button
                    type="button"
                    class="btn btn-link float-right dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="material-icons">more_vert</i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <button class="dropdown-item" (click)="edit(sm)">Edit</button>
                    <button class="dropdown-item" (click)="removeSM(sm.id)">Remove</button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8" class="border-0 p-0">
                <div class="pagination-wrapper">
                  <ngb-pagination
                    [collectionSize]="result.length"
                    [pageSize]="pageSize"
                    [(page)]="page"
                    [boundaryLinks]="true"
                    [maxSize]="3"
                    [rotate]="true"
                    [hidden]="result.length === 0 || isLoading"
                  ></ngb-pagination>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
