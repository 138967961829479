import { NgModule, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrokerComponent } from './components/broker/broker.component';
import { ProfileComponent } from './components/profile/profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BrokerRoutingModule } from './broker-routing.module';
import { MaterialModule } from '../material/material.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CorporatePortalModule } from '../corporate-portal/corporate-portal.module';

@NgModule({
  declarations: [BrokerComponent, ProfileComponent],
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrokerRoutingModule,
    CorporatePortalModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    EditorModule,
    MaterialModule,
    SlickCarouselModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [BrokerComponent, ProfileComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BrokerModule {}
