<div class="confirm-modal-wrapper">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Job Details</h5>
      </div>
      <div class="modal-body">
        <div class="py-2 px-3 mb-0">
          <h6>Status: {{ jobStatus }}</h6><br />
          <h6>Url: 
          <button type="submit" (click)="downloadJobStatusFile()" class="btn btn-sm import-btn">
            <i class="material-icons">cloud_download</i>
            <span>Download</span>
          </button>
          </h6>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary px-4" (click)="onDismiss()">Close</button>
      </div>
    </div>
  </div>
</div>