import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpMemberRoutingModule } from './cp-member-routing.module';
import { CpMemberProfileComponent } from './components/cp-member-profile/cp-member-profile.component';
import { CpMemberEnrollmentComponent } from './components/cp-member-enrollment/cp-member-enrollment.component';
import { CpMemberComponent } from './components/cp-member/cp-member.component';
import { DyanamicFieldsFormModule } from '../dyanamic-fields-form/dyanamic-fields-form.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MaterialModule } from '../material/material.module';
import { BusinessAdminModule } from '../business-admin/business-admin.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MemberEndorsementComponent } from './components/endorsement/member-endorsement/member-endorsement.component';
import { DependentEndorsementComponent } from './components/endorsement/dependent-endorsement/dependent-endorsement.component';
import { CreateEmployeeEndorsementComponent } from './components/endorsement/create-employee-endorsement/create-employee-endorsement.component';
import { EndorsementByMemberComponent } from './components/endorsement/endorsement-by-member/endorsement-by-member.component';
import { CorporatePortalModule } from '../corporate-portal/corporate-portal.module';
import { EndorsementComponent } from './components/endorsement/endorsement.component';
import { ConsentComponent } from './components/cp-member/consent/consent.component';
import { CpNomineeEnrollComponent } from './components/cp-nominee-enroll/cp-nominee-enroll.component';

@NgModule({
  declarations: [
    CpMemberProfileComponent,
    CpMemberEnrollmentComponent,
    CpMemberComponent,
    EndorsementComponent,
    MemberEndorsementComponent,
    DependentEndorsementComponent,
    CreateEmployeeEndorsementComponent,
    EndorsementByMemberComponent,
    ConsentComponent,
    CpNomineeEnrollComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    MaterialModule,
    CpMemberRoutingModule,
    CorporatePortalModule,
    DyanamicFieldsFormModule,
    BusinessAdminModule,
    SlickCarouselModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [CpMemberComponent, CpMemberProfileComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CpMemberModule {}
