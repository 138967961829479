<form class="w-100" [formGroup]="healthEnrollmentForm" (ngSubmit)="onSubmit()">
  <div class="col-12 py-4">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="form-group">
          <label>Status<sup>*</sup></label>
          <select class="form-control" formControlName="status">
            <option value="Active">Active</option>
            <option value="InActive">InActive</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button [disabled]="!healthEnrollmentForm.valid" class="btn btn-primary">
          <span *ngIf="!isExcelReportLoaded" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          Download Report
        </button>
      </div>
    </div>
  </div>
</form>