<div *ngIf="error" class="row justify-content-center">
  <div class="col-md-8 text-center">
    <div class="alert alert-warning" role="alert">
      Oops, there was an error, please try to <a routerLink="/identity/login">login again</a>.
    </div>
  </div>
</div>
<!-- Spinner -->
<div *ngIf="isLoading" class="spinner-center-wrapper">
  <div class="spinner-center"></div>
  <div class="pt-2">Logging in ...</div>
</div>
