import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { getLocaleDateTimeFormat } from '@angular/common';
import { EncruptioDecryptionService } from './encryption-decryption.service';
import { stringify } from '@angular/compiler/src/util';
import { param } from 'jquery';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isLoggedIn = new BehaviorSubject<boolean>(false);
  private _userName = new BehaviorSubject<string>(null);
  private manager: UserManager;
  private user: User | null;
  private initialized = false;
  private httpOptions: any;
  isLoading = true;

  loginType = '';
  code = '';

  canPerformLogin = new BehaviorSubject<boolean>(false);
  role: string;

  isLoggedIn = this._isLoggedIn.asObservable();
  userName = this._userName.asObservable();
  key: any;

  constructor(
    private router: Router,
    private readonly httpClient: HttpClient,
    private encryptionDecryption: EncruptioDecryptionService
  ) {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === 'status' && event.newValue === 'logout') {
        // Another Browser-Tab removed the magic entry in localStorage (he logged out)
        // so we remove our tokens as well
        this.signout();
      }
    });
  }

  async init(loginType?) {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    this.manager = new UserManager(await this.getClientSettings(loginType));

    await this.manager.getUser().then(user => {
      this.user = user;
      this._isLoggedIn.next(this.isAuthenticated());
      if (user != null) {
        this._userName.next(this.user.profile['name']);
      }
    });

    this.manager.events.addUserLoaded(x => {
      console.log('user loaded');
    });

    if (!loginType) {
      this.manager = new UserManager(await this.getClientSettings(window.sessionStorage.getItem('loginType')));
    }
    this.manager.events.addAccessTokenExpiring(x => {
      console.log('Acess token expiring event');
      this.renewToken().then(u => {
        console.log('Acess token expiring event renew success');
      });
    });

    this.canPerformLogin.subscribe(value => {
      if (this.canPerformLogin.value) {
        this.canPerformLogin.next(false);
        if (window.sessionStorage.getItem('loginType') === 'SSO' && this.code === '') {
          console.log('delay');
          return;
        }
        this.login();
      }
    });
  }

  async sso(ssoParams) {
    this.generateKey();
    return await this.httpClient
      .get(environment.apiBaseUrl + 'user/sso/usercode?params=' + ssoParams, {
        headers: new HttpHeaders({
          Key: this.key,
        }),
      })
      .toPromise();
  }

  login() {
    if (this.manager === undefined) {
      console.log('manager und');
      return;
    }
    return this.manager.signinRedirect();
  }

  async completeAuthentication() {
    await this.manager
      .signinRedirectCallback()
      .then(x => {
        this.user = x;
        if (x != null) {
          this._userName.next(this.user.profile['name']);
        }
      })
      .catch(errorData => {
        const expired = errorData;
      });

    this._isLoggedIn.next(this.isAuthenticated());
    if (this.user != null) {
      console.log(this.user.profile['iat']);
      const date = new Date().toUTCString();
      const diff = moment(date).diff(moment(this.user.profile['iat']), 'minute') - 330;
      if (diff < 3) {
        await this.lastLogin();
      }
      this._userName.next(this.user.profile['name']);
    }
  }

  async lastLogin() {
    this.generateKey();
    await this.httpClient
      .put(
        environment.apiBaseUrl + 'user/loggedIn',
        {},
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.authorizationHeaderValue,
            Key: this.key,
          }),
        }
      )
      .toPromise();
  }

  async GetRole() {
    this.generateKey();
    return await this.httpClient
      .get(environment.apiBaseUrl + 'user/role/' + this.user.profile.sub, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: this.authorizationHeaderValue,
          Key: this.key,
        }),
      })
      .toPromise();
  }

  isAuthenticated(): boolean {
    if (this.user != null) {
      return this.user !== null && !this.user.expired;
    }
    return false;
  }

  getUserId() {
    return this.user.profile.sub;
  }

  public renewToken() {
    return this.manager
      .signinSilent()
      .then(u => {
        this.user = u;
      })
      .catch(er => {
        console.log(er);
        this.signout();
      });
  }

  get authorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  async signout() {
    try {
      this.generateKey();
      const url = `${environment.apiBaseUrl}User/User/Logout/${this.user.access_token}`;
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Key: this.key,
        }),
      };
      await this.httpClient.post(url, null, this.httpOptions).toPromise();
    } catch (ex) {
      console.log(ex);
    }
    window.localStorage.setItem('status', 'logout');
    await this.manager.signoutRedirect();
  }

  private async getClientSettings(loginType?): Promise<UserManagerSettings> {
    let logout = window.location.origin;
    if (
      window.sessionStorage.getItem('loginType') === 'Internal' ||
      window.sessionStorage.getItem('loginType') === 'SSO'
    ) {
      if (window.sessionStorage.getItem('role') === 'BusinessAdmin') {
        logout += '/biz-admin';
      } else if (window.sessionStorage.getItem('role') === 'SM') {
        logout += '/sm';
      } else if (window.sessionStorage.getItem('role') === 'Operation') {
        logout += '/operation';
      }
    }

    if (
      window.sessionStorage.getItem('loginType') === 'External' ||
      window.sessionStorage.getItem('loginType') === 'SSO'
    ) {
      if (window.sessionStorage.getItem('role') === 'Member') {
        logout += '/';
      } else if (window.sessionStorage.getItem('role') === 'Broker') {
        logout += '/broker';
      } else if (window.sessionStorage.getItem('role') === 'CPMember') {
        logout += '/cp-member';
      }
    }

    if (window.location.search.indexOf('sso=') > 0) {
      const userparam = window.location.search.split('sso=')[1];
      await this.sso(userparam)
        .then(x => {
          this.code = x['userCode'];
          console.log('login');
          this.canPerformLogin.next(true);
        })
        .catch(error => {
          this.router.navigate(['/user-not-valid']);
        });
    }

    return {
      authority: environment.authority,
      client_id: environment.client,
      redirect_uri: window.location.origin + '/auth-callback/',
      post_logout_redirect_uri: logout,
      response_type: 'id_token token',
      scope: environment.scopes,
      filterProtocolClaims: true,
      silentRequestTimeout: 10000,
      automaticSilentRenew: false,
      includeIdTokenInSilentRenew: true,
      silent_redirect_uri: window.location.origin + '/assets/silent-refresh.html',
      loadUserInfo: true,
      extraQueryParams: {
        loginType: loginType,
        code: this.code,
      },
    };
  }

  generateKey() {
    const key = {
      date: moment().format(),
      key: uuidv4(),
    };
    this.key = this.encryptionDecryption.encrypt(JSON.stringify(key), 'b14ca5898a4e4133bbce2ea2315a1916KPngPNGyVN');
  }
}
