
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LexedCssResult } from 'codelyzer/angular/styles/cssLexer';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { SelfMember } from 'src/app/cp-member/models/self-member.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { MemberModel } from 'src/app/member/model/member.model';
import { PreEnrollmentMemberPolicyModel } from 'src/app/member/model/preEnrollment-member-policy.model';
import { Attendance } from '../../models/attendance.model';
import { CPPolicyService } from '../../services/cp-policy.service';
import * as moment from 'moment';

@Component({
  selector: 'app-active-sessions',
  templateUrl: './active-sessions.component.html',
  styleUrls: ['./active-sessions.component.scss']
})
export class ActiveSessionsComponent implements OnInit {
  isLoading:boolean;
  attendances:Attendance[]=[];
  attendancesCopy:Attendance[]=[];
  skip = 0;
    take = 10;
    totalAttendanceCount: number;
    page = 1;
    pageCopy = 1;
    pageSize = 10;
    pageSizeCopy = 10;
    isSearchingAttendance=false;
    startDate:[''];
    endDate:[''];
    corporate:string='';
    employeeID:string='';
    corporateCode:string='';
    currentPolicy:Policy=new Policy();
    family:PreEnrollmentMemberPolicyModel[]=[];
    self:SelfMember= new SelfMember();
    EndDateInvalid:boolean=false;
    StartDateInvalid:boolean=false;
    hidePagignation=false;
    @ViewChild('closeModalButton') content;
  constructor(private cpPolicyService:CPPolicyService,private cpMemberService:CPMemberService) { }

  ngOnInit(): void {
    this.isLoading=true;
    const count = this.attendancesCopy.length;
    
    this.cpPolicyService.currentPolicy.subscribe(async (policy:any)=>
    {
      this.currentPolicy=policy;      
    });
    this.cpPolicyService.currentCorporate.subscribe(m=> {
      this.corporateCode=m.code;
  });
    this.cpMemberService.selfMemberDetails.subscribe(m=>
      {
            this.self=m;
            this.employeeID=this.self.memberIdEmpId;
            console.log('EmployeeID is :- '+this.employeeID);
            this.getPaginatedCorporates(0, 10);
            this.isLoading = false;
      });
      
    // this.cpMemberService.getMemberFamily(this.currentPolicy.policyNumber)
    //   .then(x => {
    //     console.log(x);
    //     // this.family=x.members;
    //     x.members.forEach(x=> { 
    //       if(x.relationshipId.toUpperCase() ==='SELF')
    //         this.self=x;
    //         this.self
    //       });
    //   })
    //   .catch(x => {
    //     console.log('error');
    //   });    
  }

  async getPaginatedCorporates(skip, take) {
    this.isLoading = true;
    let data={
      corporate_code : this.corporateCode,
      corporate_emp_Id : this.employeeID,
      attendance_period_start :this.startDate,
      attendance_period_end :this.endDate
    }
    let corps= await this.cpPolicyService.getAllAttendance(data);
    if(corps.length>10)
    {
    this.totalAttendanceCount = corps.length;
    this.attendancesCopy=corps;
    this.attendances=this.attendancesCopy.slice(0,10);
    this.hidePagignation=false;
    this.isLoading=false;
    }
    else{
      this.attendancesCopy=corps;
      this.attendances = corps;
      this.hidePagignation=true;
      this.isLoading=false;
    }    
    console.log(this.attendances);
    
    

  }
  async loadPage(event) {
    const pageNum = event;
    this.skip = (pageNum - 1) * this.take;
    let take=this.skip+this.take;
    if(take>this.attendancesCopy.length)
      take = this.attendancesCopy.length;
    //this.getPaginatedCorporates(this.skip, this.take);
    this.attendances = this.attendancesCopy.slice(this.skip,take);
  }
  async GetDatesSession(){
    this.isLoading = true;
    // let endDate = moment(this.endDate);
    // let startDate = moment(this.startDate);
    // const today = moment();
    // this.EndDateInvalid=!endDate.isValid() || endDate.isBefore(startDate);
    // this.StartDateInvalid = !startDate.isValid() || startDate.isAfter(today.endOf('day'));
    // if(this.EndDateInvalid || this.StartDateInvalid)
    //   return;
    // this.content.nativeElement.click();
    // document.getElementById("closeModalButton").click();

    //this.isSearchingAttendance=true;
    let data={
      corporate_code : this.corporateCode,
      corporate_emp_Id : this.employeeID,
      attendance_period_start :this.startDate,
      attendance_period_end :this.endDate
    }
    console.log(this.attendances);
    this.startDate= null;
    this.endDate = null;
    let corps = await this.cpPolicyService.getAllAttendance(data);
    this.isLoading=false;

    //let corps; //await this.cpPolicyService.getAllAttendance();
    if(corps.length>10)
    {
    this.totalAttendanceCount = corps.length;
    this.attendancesCopy=corps;
    this.attendances=this.attendancesCopy.slice(0,10);
    this.hidePagignation=false;
    }
    else{
      this.attendancesCopy=corps;
      this.attendances = corps;
      this.hidePagignation=true;
    }    
    
  }

}
