<div class="member-enrollment-component-wrapper" *ngIf="isMember">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12">
          <div class="card card-style-1">
            <div class="card-body">
              <div class="card-title row justify-content-between align-items-center">
                <h4 class="m-0 header-title">Member Enrollment</h4>
                <div class="dropdown card-header-dropdown" *ngIf="policies.length > 0">
                  <button
                    class="btn btn-sm dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <span *ngIf="currentPolicy.isActive" class="logged"></span>
                    <span *ngIf="!currentPolicy.isActive" class="notLogged"></span>
                    <span *ngIf="currentPolicy.onScreenDisplayAvailable == false">
                      {{ currentPolicy.riskStartDate.split('-')[0] }}-{{ currentPolicy.riskEndDate.split('-')[0] }} -
                      {{ currentPolicy.policyNumber }} - {{currentPolicy.policyType}}</span
                    >
                    <span *ngIf="currentPolicy.onScreenDisplayAvailable == true">{{currentPolicy.onScreenDisplayPolicy}}</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <div *ngFor="let policy of policies" (click)="onNamePolicyChanged(policy)">
                      <a class="dropdown-item">
                        <span *ngIf="policy.isActive" class="logged"></span>
                        <span *ngIf="!policy.isActive" class="notLogged"></span>
                        <span *ngIf="policy.onScreenDisplayAvailable == false">
                          {{ policy.riskStartDate.split('-')[0] }}-{{ policy.riskEndDate.split('-')[0] }} -
                          {{ policy.policyNumber }} - {{policy.policyType}}</span
                        >
                        <span *ngIf="policy.onScreenDisplayAvailable == true">{{policy.onScreenDisplayPolicy}}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row py-3">
                <div *ngIf="!isMemberSelfLoaded" class="col-12">
                  <ul class="list-unstyled shimmer-wrapper">
                    <li class="shimmer"></li>
                    <li class="shimmer"></li>
                    <li class="shimmer"></li>
                    <li class="shimmer"></li>
                    <li class="shimmer"></li>
                    <li class="shimmer"></li>
                  </ul>
                </div>
                <div *ngIf="!isEmployeeBelongsToPolicy && isMemberSelfLoaded">Member Not enrolled.</div>
                <div
                  class="col-md-6 pr-sm-1"
                  *ngIf="isEmployeeBelongsToPolicy && isMemberSelfLoaded && memberSelf !== null"
                >
                  <ul class="inner-cards">
                    <li>
                      <span class="field-name mr-2">Name:</span>
                      <span class="field-data">{{ memberSelf.insuredName }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">DOB:</span>
                      <span class="field-data"><app-date-shared [date]="memberSelf.dob"> </app-date-shared></span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Age:</span>
                      <span class="field-data">{{ memberSelf.age }} Years</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Gender:</span>
                      <span class="field-data">{{ memberSelf.sex }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Mobile No:</span>
                      <span class="field-data">{{ memberSelf.mobileNo }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Email:</span>
                      <span class="field-data">{{ memberSelf.emailID }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Address Of Policy:</span>
                      <span class="field-data">{{ memberSelf.address1 }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Date of Joining:</span>
                      <span class="field-data"><app-date-shared [date]="memberSelf.doj"> </app-date-shared></span>
                    </li>

                    <li>
                      <span class="field-name mr-2">Relationship:</span>
                      <span class="field-data">{{ memberSelf.relationShipId }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Abha Id:</span>
                      <span class="field-data">{{ memberSelf.insuredABHAId }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Abha Address:</span>
                      <span class="field-data">{{ memberSelf.insuredABHAAddress }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Sum Insured:</span>
                      <span class="field-data">{{ memberSelf.sumInsured }}</span>
                    </li>
                  </ul>
                </div>
                <div
                  class="col-md-6 pl-sm-1"
                  *ngIf="isEmployeeBelongsToPolicy && isMemberSelfLoaded && memberSelf !== null && false"
                >
                  <ul class="inner-cards">
                    <li>
                      <span class="field-name mr-2">Elite:</span>
                      <span class="field-data">{{ memberSelf.eliteStatus }}</span>
                    </li>

                    <li>
                      <span class="field-name mr-2">Sum Insured:</span>
                      <span class="field-data">{{ memberSelf.sumInsured }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Grade:</span>
                      <span class="field-data">{{ memberSelf.grade }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Branch Name:</span>
                      <span class="field-data">{{ memberSelf.bankName }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Account No:</span>
                      <span class="field-data">{{ memberSelf.accountNumber }}</span>
                    </li>

                    <li>
                      <span class="field-name mr-2">Bank Name:</span>
                      <span class="field-data">{{ memberSelf.bankName }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Bank Branch Address:</span>
                      <span class="field-data">{{ memberSelf.bankBranckAddress }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">IFSC Code Of The Bank:</span>
                      <span class="field-data">{{ memberSelf.ifscCodeoftheBank }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">Pan No Of The Insured:</span>
                      <span class="field-data">{{ memberSelf.panNoOfTheInsured }}</span>
                    </li>
                    <li>
                      <span class="field-name mr-2">MICR No:</span>
                      <span class="field-data">{{ memberSelf.micrNo }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- end row -->
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
