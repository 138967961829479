import { NgModule, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/auth.guard';
import { MemberHomeComponent } from './components/member-home/member-home.component';
import { MemberEnrollmentComponent } from './components/member-enrollment/member-enrollment.component';
import { EditMemberComponent } from './components/edit-member/edit-member.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { EditMemberFamilyComponent } from './components/edit-member-family/edit-member-family.component';
import { GenerateReportsComponent } from './components/generate-reports/generate-reports';
import { PageNotFoundComponent } from '../shared/components/page-not-found/page-not-found.component';
import { CpMemberProfileComponent } from '../cp-member/components/cp-member-profile/cp-member-profile.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { roles: ['Member', 'HR'] },
    children: [
      { path: 'home', component: MemberHomeComponent },
      { path: 'edit/member', component: EditMemberComponent },
      { path: 'edit/member/family/:dependentId', component: EditMemberFamilyComponent },
      { path: 'edit/member/family/:dependentId/:relationshipId/:sumInsured', component: EditMemberFamilyComponent },
      { path: 'edit/Profile', component: EditProfileComponent },
      { path: 'enrollment', component: MemberEnrollmentComponent },
      { path: 'generate/reports', component: GenerateReportsComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'page-not-found', component: PageNotFoundComponent },
      { path: '403Forbidden', component: PageNotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MemberRoutingModule {
  constructor() {}
}
