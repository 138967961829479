import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MasterService } from 'src/app/business-admin/services/master.service';
import { PreEnrollmentService } from 'src/app/business-admin/services/preEnrollment.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { NoteMsgDialogModel } from 'src/app/shared/components/note-msg/note-msg-dialog-model.model';
import { NoteMsgComponent } from 'src/app/shared/components/note-msg/note-msg.component';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { ReminderEmailDateTime } from 'src/app/shared/models/preEnrollment/email/reminder-email-date-time.model';
import { ReminderEmail } from 'src/app/shared/models/preEnrollment/email/reminder-email.model';
import { PreEnrollmentPeriodModel } from 'src/app/shared/models/preEnrollment/periods/preEnrollment-period.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-configure-reminder-email',
  templateUrl: './configure-reminder-email.component.html',
  styleUrls: ['./configure-reminder-email.component.scss'],
})
export class ConfigureReminderEmailComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  form: FormGroup;
  @Input() corporate: CorporateModel;
  @Input() policy: PreEnrollmentPolicyModel;
  editMode = false;

  initialized = false;

  reminderEmailMinDate = new Date();

  configureEmails = 5;
  errorR: any;
  reminderMinsOptions: number[] = [0, 30];

  isSaveButtonEnable = true;
  isReminderEmailValid: boolean;
  emailSubjectLength: boolean;
  subjectErrorMsg: string;

  reminderEmailSubjectErrorMsg = '';
  reminderEmailSubjectLength = true;
  displaySave: string;
  reminderEmailData: ReminderEmail = new ReminderEmail();

  hrsOptions: number[] = [];
  minsOptions: number[] = [];
  fileName: string;
  file: File = null;
  isSizeValid: boolean;
  uploadImageInvalidMsg = '';

  periods: PreEnrollmentPeriodModel[] = [];

  constructor(
    injector: Injector,
    private readonly masterService: MasterService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly preenrollmentService: PreEnrollmentService,
    private fb: FormBuilder
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.createForm();
    super.ngOnInit();
    this.periods = await this.preenrollmentService.getPeriods(this.policy.id);

    for (let index = 0; index < 24; index++) {
      this.hrsOptions.push(index);
    }
    for (let index = 0; index < 60; index++) {
      this.minsOptions.push(index);
    }
    if (!this.policy) {
      this.displaySave = 'Save';
      const templates = await this.masterService.getEmailTemplate();
      this.reminderEmailData.reminderEmailTemplate = templates.reminderEmailTemplate;
    } else {
      this.displaySave = 'Save Changes';
      this.reminderEmailData.sendReminderEmail = this.policy.sendReminderEmail;
      this.reminderEmailData.reminderEmailTemplate = this.policy.reminderEmailTemplate;
      this.reminderEmailData.reminderEmailSubject = this.policy.reminderEmailSubject;
      this.reminderEmailData.reminderEmailFileName = this.policy.reminderEmailFileName;
      if (this.policy.reminderEmailSubject === null) {
        this.reminderEmailData.reminderEmailSubject = '';
      }

      if (!this.policy.reminderEmailTemplate || this.policy.reminderEmailTemplate === null) {
        const templates = await this.masterService.getEmailTemplate();
        this.reminderEmailData.reminderEmailTemplate = templates.reminderEmailTemplate;
      }
      if (
        this.policy.reminderEmailDateTime === '0001-01-01T00:00:00' ||
        this.policy.reminderEmailDateTime === null ||
        this.policy.reminderEmailDateTime === ''
      ) {
        this.reminderEmailData.pEReminderDateTime.push(new ReminderEmailDateTime());
      } else {
        this.reminderEmailData.pEReminderDateTime = JSON.parse(this.policy.reminderEmailDateTime);
        this.reminderEmailData.pEReminderDateTime.forEach(
          item => (item.ReminderEmailDate = moment(item.ReminderEmailDate).toDate())
        );
      }

      this.fileName = this.policy.reminderEmailFileName;
      if (this.fileName) {
        this.reminderEmailData.reminderEmailFile = this.policy.reminderEmailFile;
        this.file = new File(['foo'], 'foo.txt');
      }
      if (this.reminderEmailData.reminderEmailSubject.length === 0) {
        this.reminderEmailSubjectLength = false;
        this.reminderEmailSubjectErrorMsg = 'Subject cannot be empty!!!';
      } else if (this.reminderEmailData.reminderEmailSubject.length > 254) {
        this.reminderEmailSubjectLength = false;
        this.reminderEmailSubjectErrorMsg = 'Max length of 254 exceeded';
      } else {
        this.reminderEmailSubjectLength = true;
        this.reminderEmailSubjectErrorMsg = '';
      }
      this.checkSaveButtonDisplay();

      this.editMode = true;
    }

    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.preenrollmentService.preenrollmentPeriods.subscribe((periods: any[]) => {
      this.periods = periods;
    });
    if (changes['policy'] !== undefined && changes['corporate'] === undefined) {
      this.reminderEmailData = new ReminderEmail();
      this.ngOnInit();
    }
  }

  createForm() {
    this.form = this.fb.group({
      reminderMessage: ['', [Validators.pattern('^(?! )[A-Za-z0-9.,{}() ]*(?<! )$')]],
    });
  }

  addNewDate() {
    this.reminderEmailData.pEReminderDateTime.push(new ReminderEmailDateTime());
  }

  removeDateTime(index) {
    this.reminderEmailData.pEReminderDateTime.splice(index, 1);
  }

  isReminderSubjectValid(event) {
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.reminderEmailSubjectLength = false;
      this.reminderEmailSubjectErrorMsg = 'Subject cannot be empty!!!';
    } else if (fieldValue.length > 254) {
      this.reminderEmailSubjectLength = false;
      this.reminderEmailSubjectErrorMsg = 'Max length of 254 exceeded';
    } else {
      this.reminderEmailSubjectLength = true;
      this.reminderEmailSubjectErrorMsg = '';
    }
    this.checkSaveButtonDisplay();
  }

  onFileChange(file) {
    if (file.length > 0) {
      this.file = file[0];
      const name = file[0].name;
      const size = file[0].size;
      this.reminderEmailData.reminderEmailFile = null;
      this.fileName = name;
      this.reminderEmailData.reminderEmailFileName = this.fileName;
      if (this.fileName.length > 30) {
        this.fileName = this.fileName.slice(0, 25) + '...';
      }
      if (size > 5000000) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Maximum size must be 5 Mb';
      } else {
        this.isSizeValid = true;
        this.uploadImageInvalidMsg = '';
      }
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf', 'rar', 'zip'];
      if (!fileExtensionArr.includes(ext.toLowerCase())) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Upload valid format file';
      }
    }
  }

  downloadFile() {
    const link = document.createElement('a');
    link.download = this.fileName;
    if (this.reminderEmailData.reminderEmailFile == null) {
      link.href = URL.createObjectURL(this.file);
    } else {
      link.href = this.reminderEmailData.reminderEmailFile;
    }

    link.click();
  }

  deleteFile() {
    (<HTMLInputElement>document.getElementById('file')).value = '';
    this.file = null;
    this.isSizeValid = true;
    this.uploadImageInvalidMsg = '';
    this.fileName = '';
    this.reminderEmailData.reminderEmailFile = null;
    this.reminderEmailData.reminderEmailFileName = null;
  }

  async submit() {
    this.checkReminderDateIfExists();
    const isDateValid = this.checkReminderDateValidity();
    if (isDateValid) {
      if (this.reminderEmailData.reminderEmailFile) {
        this.file = null;
      }
      this.reminderEmailData.pEReminderDateTime.forEach(
        item => (item.ReminderEmailDate = moment(item.ReminderEmailDate).toDate())
      );
      const stringJson = JSON.stringify(this.reminderEmailData);
      const data = new FormData();
      data.append('File', this.file);
      data.append('Json', stringJson);

      await this.preenrollmentService
        .sendReminderEmail(this.corporate.id, this.policy.id, data)
        .then(x => {
          this.alertService.success('Reminder Email Saved Successfully');
          this.router.navigate(['/biz-admin/corporate/' + this.corporate.id]);
        })
        .catch(errorData => {
          this.errorR = errorData.error.errors;
          this.alertService.error('Error occured');
        });
    }
  }

  sendReminderEmail() {
    if (!this.reminderEmailData.sendReminderEmail) {
      this.reminderEmailSubjectLength = true;
    } else {
      if (this.reminderEmailData.reminderEmailSubject.length === 0) {
        this.reminderEmailSubjectLength = false;
        this.reminderEmailSubjectErrorMsg = 'Subject cannot be empty!!!';
      } else if (this.reminderEmailData.reminderEmailSubject.length > 254) {
        this.reminderEmailSubjectLength = false;
        this.reminderEmailSubjectErrorMsg = 'Max length of 254 exceeded';
      } else {
        this.reminderEmailSubjectLength = true;
        this.reminderEmailSubjectErrorMsg = '';
      }
    }

    this.checkSaveButtonDisplay();
  }

  checkReminderDateIfExists() {
    for (let index = 0; index < this.reminderEmailData.pEReminderDateTime.length; index++) {
      const element = this.reminderEmailData.pEReminderDateTime[index];
      const isDateValid = this.isValid(element.ReminderEmailDate);
      if (!isDateValid) {
        this.openAlertBox();
        this.reminderEmailData.pEReminderDateTime[index].ReminderEmailDate = null;
      }
    }
  }

  isValid(date) {
    for (let index = 0; index < this.periods.length; index++) {
      const element = this.periods[index];
      if (element.status) {
        const startDate = moment(element.startDate)
          .endOf('day')
          .toDate();
        const endDate = moment(element.endDate)
          .endOf('day')
          .toDate();
        const valid = moment(date).isBetween(startDate, endDate, 'days', '[]');
        if (valid) {
          return true;
        }
      }
    }
    return false;
  }

  checkSaveButtonDisplay() {
    const isDateValid = this.checkReminderDateValidity();
    if (isDateValid) {
      if (this.reminderEmailData.sendReminderEmail) {
        this.isSaveButtonEnable =
          this.reminderEmailSubjectLength &&
          this.reminderEmailData.reminderEmailTemplate !== null &&
          this.reminderEmailData.reminderEmailTemplate !== undefined;
      } else {
        this.isSaveButtonEnable = true;
      }
    } else {
      this.isSaveButtonEnable = false;
    }
  }

  checkDateValidity(date, index) {
    const isDateValid = this.isValid(date);
    if (!isDateValid) {
      this.openAlertBox();
      this.reminderEmailData.pEReminderDateTime[index].ReminderEmailDate = null;
    }
    this.checkSaveButtonDisplay();
    // let isDateNull =
    //   !date || date === null || date === undefined;
    // return !isDateNull;
  }

  openAlertBox() {
    const message = `The reminder email send date is not within preenrollment period active date. Please enter date within active period.`;
    const dialogData = new NoteMsgDialogModel('Alert', message);
    this.dialog.open(NoteMsgComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  checkReminderDateValidity() {
    for (let index = 0; index < this.reminderEmailData.pEReminderDateTime.length; index++) {
      const element = this.reminderEmailData.pEReminderDateTime[index];
      if (!element.ReminderEmailDate) {
        return false;
      }
    }
    return true;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
