<div class="error-msg" *ngFor="let error of errorList">
  <p>{{ error.message }}</p>
</div>
<div class="row">
  <div class="col-12">
    <form [formGroup]="policyForm">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Policy Number<sup>*</sup></label>
            <input type="text" class="form-control" formControlName="policyNumber" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Insured Name<sup>*</sup></label>
            <input type="text" class="form-control" formControlName="insuredName" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Risk Start Date<sup>*</sup></label>
            <input
              type="text"
              class="form-control"
              bsDatepicker
              formControlName="riskStartDate"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Risk End Date<sup>*</sup></label>
            <input
              type="text"
              class="form-control"
              bsDatepicker
              formControlName="riskEndDate"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Issue Date<sup>*</sup></label>
            <input
              type="text"
              class="form-control"
              bsDatepicker
              formControlName="issueDate"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Grace Period Type</label>
            <select class="form-control" (change)="periodTypeChanged()" formControlName="gracePeriodType">
              <option *ngFor="let item of periodTypes" [value]="item">{{ item }}(s)</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Grace Period Value</label>
            <select class="form-control" formControlName="gracePeriodValue">
              <option *ngFor="let item of periodValues" [value]="item"
                >{{ item }} {{ policyForm.get('gracePeriodType').value }}(s)</option
              >
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
          <div class="col-md-3">
          <div class="form-group">
            <label>Claim SPOC</label>
            <div class="custom-checkbox">
              <label>
                <input
                  type="checkbox"
                  class="custom-control-input" formControlName="claimSpoc" #claimSPOC
                />
                <span></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-9" *ngIf="claimSPOC.checked">
          <div class="form-group">
            <label>Claim SPOC EmailID<sup>*</sup></label>
            <input type="text" class="form-control" formControlName="claimSpocMailId" 
            #claimSpocMailId />
            <span
              class="error-msg small"
              *ngIf="claimSpocMailId.value.length === 0 ||
              (policyForm.get('claimSpocMailId').dirty && policyForm.get('claimSpocMailId').status === 'INVALID')
              "
              >Invalid Email ID<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Display Policy Name</label>
            <input type="text" class="form-control" formControlName="onscreenDisplay" 
            [maxLength]="30"
            (input)="onScreenDisplayNameEntered()"/>
            <span
              class="error-msg small"
              *ngIf="(policyForm.get('onscreenDisplay').dirty && policyForm.controls['onscreenDisplay'].errors?.pattern)"
              >Special characters not allowed<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
            <span
              class="error-msg small"
              *ngIf="(policyForm.get('onscreenDisplay').dirty 
                    && policyForm.controls['onscreenDisplay'].errors?.pattern
                    && policyForm.controls['onscreenDisplay'].errors?.maxlength)"
              > and <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
            <span
              class="error-msg small"
              *ngIf="(policyForm.get('onscreenDisplay').dirty && policyForm.controls['onscreenDisplay'].errors?.maxlength)"
              >Maximum 30 characters allowed<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row mt-2">
  <div class="col">
    <div [hidden]="canEdit">
      <button (click)="edit()" class="btn btn-primary px-4">
        Edit
      </button>
    </div>

    <div [hidden]="!canEdit">
      <button (click)="save()" class="btn btn-primary px-4" [disabled]="(claimSPOC.checked && !policyForm.valid) || disableSaveButton">
        Save Changes
      </button>
      <button (click)="cancel()" class="btn btn-link ml-2 text-secondary">
        Cancel
      </button>
    </div>
  </div>

  <div class="col">
    <div class="d-flex flex-row-reverse">
      <button (click)="detachPolicy()" [disabled]="disableDetachButton" class="btn btn-danger">
        Detach Policy
      </button>
    </div>
  </div>
</div>
