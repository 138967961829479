import { Component, OnInit, Injector, Input } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NotificationService } from 'src/app/business-admin/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { CpHeaderComponent } from 'src/app/corporate-portal/components/cp-header/cp-header.component';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { BrokerService } from 'src/app/broker/service/broker.service';

@Component({
  selector: 'app-edit-notification',
  templateUrl: './edit-notification.component.html',
  styleUrls: ['./edit-notification.component.scss'],
})
export class EditNotificationComponent extends BaseComponent implements OnInit {
  @Input() notification: any;
  role: string;
  date = new Date();
  date1: Date;
  date2: Date;
  broadcast_to: string;
  isSizeValid = false;
  uploadImageInvalidMsg = '';
  errorR = [];
  corporates: any;
  isEdit = false;
  title = 'Edit Notification';
  disableSaveButton = false;
  saveButtonText = 'Edit';
  fileName = null;
  deleteUploadedFile = false;
  notificationId: string;
  showUploadFile = false;
  showDltButton = false;
  isFileChanged: boolean;
  notificationForm = this.fb.group({
    effectiveDateFrom: ['', Validators.required],
    effectiveDateTo: ['', Validators.required],
    subject: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')]],
    content: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')]],
    file: [''],
  });

  constructor(
    private fb: FormBuilder,
    injector: Injector,
    private brokerService: BrokerService,
    private cpMemberService: CPMemberService,
    private authService: AuthService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    public activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
    super(injector);
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void {
    this.role = this.authService.role;

    console.log(this.notification);
    this.setFormValueForEditingNotification();
    // this.notificationForm.controls['effectiveDateFrom'].valueChanges.subscribe(value => {
    //   value = moment(value)
    //     .add(1, 'month')
    //     .toDate();
    //   value = moment(value)
    //     .subtract(1, 'day')
    //     .toDate();
    //   this.notificationForm.controls.effectiveDateTo.setValue(value);
    // });
    console.log(this.notificationForm.value);
  }

  setFormValueForEditingNotification() {
    this.notificationId = this.notification.id;
    this.notificationForm.disable();
    this.notificationForm.patchValue({
      effectiveDateFrom: new Date(this.notification.effectiveDateFrom),
      effectiveDateTo: new Date(this.notification.effectiveDateTo),
      subject: this.notification.subject,
      content: this.notification.content,
      file: this.notification.file,
    });
  }

  async save(event) {
    if (event === 'Edit') {
      this.saveButtonText = 'Save Changes';
      this.isEdit = true;
      this.notificationForm.get('effectiveDateTo').enable();
      this.notificationForm.get('effectiveDateFrom').enable();
      this.notificationForm.get('subject').enable();
      this.notificationForm.get('content').enable();
      this.notificationForm.get('file').enable();
      this.disableSaveButton = !this.notificationForm.valid;
      if (this.notification.file === null) {
        this.showUploadFile = true;
      } else {
        this.showUploadFile = false;
      }
    } else if (event === 'Save Changes') {
      this.disableSaveButton = true;
      const isFileChanged = this.checkIsFileChangedValue();
      const effectiveFromDate = this.notificationForm.value.effectiveDateFrom;
      const effectiveToDate = this.notificationForm.value.effectiveDateTo;
      const Json = {
        effectiveDateFrom: moment(effectiveFromDate).add('330', 'minutes'),
        effectiveDateTo: moment(effectiveToDate).add('330', 'minutes'),
        subject: this.notificationForm.value.subject,
        content: this.notificationForm.value.content,
        isFileChanged: isFileChanged,
      };

      const stringJson = JSON.stringify(Json);
      const data = new FormData();
      if (
        this.notificationForm.value.file === null ||
        this.notificationForm.value.file === undefined ||
        this.notificationForm.value.file === ''
      ) {
        data.append('File', null);
      } else {
        data.append('File', this.notificationForm.value.file[0]);
      }
      data.append('Json', stringJson);
      await this.notificationService
        .editNotification(this.notificationId, data)
        .then(x => {
          this.disableSaveButton = false;
          this.alertService.success('Notification updated successfully.');
          if (this.role === Roles.BuisnessAdmin) {
            this.router.navigate(['/biz-admin/notification/list']);
          } else if (this.role === Roles.CPHR || this.role === Roles.Broker || this.role === Roles.SM) {
            this.cpMemberService.getNotificationCreatedByUser();
          }
          this.modalService.dismissAll(EditNotificationComponent);
        })
        .catch(errorData => {
          this.disableSaveButton = false;
          this.errorR = errorData.error.errors;
          // this.alertService.error('Error occured');
        });
    }
  }

  onFileChange(file) {
    if (file.length > 0) {
      this.notificationForm.controls.file.setValue(file);
      const name = file[0].name;
      this.fileName = file[0].name;
      const size = file[0].size;
      if (size > 5000000) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Max size must be 5 Mb';
        this.notificationForm.get('file').setErrors({ invalid: true });
      } else {
        this.isSizeValid = true;
        this.uploadImageInvalidMsg = '';
        this.notificationForm.get('file').setErrors(null);
      }
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf'];
      if (!fileExtensionArr.includes(ext.toLowerCase())) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Upload valid format file';
        this.notificationForm.get('file').setErrors({ invalid: true });
      }
    }
    this.disableSaveButton = !this.notificationForm.valid;
  }

  deleteFile() {
    if (this.notification.file !== null) {
      this.deleteUploadedFile = true;
    }
    if (<HTMLInputElement>document.getElementById('file') !== null) {
      (<HTMLInputElement>document.getElementById('file')).value = '';
    }
    this.notificationForm.controls.file.setValue('');
    this.isSizeValid = true;
    this.uploadImageInvalidMsg = '';
    this.fileName = null;
    this.showUploadFile = true;
    this.checkFormValidity();
  }

  close() {
    this.modalService.dismissAll(EditNotificationComponent);
  }

  checkIsFileChangedValue() {
    if (this.notification.file === null && this.fileName === null) {
      return false;
    } else if (this.notification.file === null && this.fileName !== null) {
      return true;
    } else if (this.notification.file !== null && this.deleteUploadedFile === false) {
      return false;
    } else if (this.notification.file !== null && this.deleteUploadedFile === true) {
      return true;
    }
  }

  checkFormValidity() {
    this.disableSaveButton = !this.notificationForm.valid;
  }
}
