import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PreEnrollmentService } from 'src/app/business-admin/services/preEnrollment.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { NoteMsgDialogModel } from 'src/app/shared/components/note-msg/note-msg-dialog-model.model';
import { NoteMsgComponent } from 'src/app/shared/components/note-msg/note-msg.component';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { AllowedSumInsuredModel } from 'src/app/shared/models/preEnrollment/policies/allowedSumInsuredModel';
import { EnhancedSumInsured } from 'src/app/shared/models/preEnrollment/policies/enhanced-sum-insured.model';
import { ParentalSumInsured } from 'src/app/shared/models/preEnrollment/policies/Parental-sum-insured.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { SiExtrabenefits } from 'src/app/shared/models/preEnrollment/policies/si-extrabenefits.model';
import { TopupModel } from 'src/app/shared/models/preEnrollment/policies/topup-model.model';
import { EsiPremiumDetails } from 'src/app/shared/models/preEnrollment/premium/esi-premium-details.model';
import { PremiumDetails } from 'src/app/shared/models/preEnrollment/premium/premium-details.model';
import { PsiPremiumDetails } from 'src/app/shared/models/preEnrollment/premium/psi-premium-details.model';
import { SiPremiumDetails } from 'src/app/shared/models/preEnrollment/premium/si-premium-details.model';
import { TopupPremiumDetails } from 'src/app/shared/models/preEnrollment/premium/topup-premium-details.model';
import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { AlertService } from 'src/app/shared/services/alert.service';
import { errorComparator } from 'tslint/lib/verify/lintError';
import { AddNewPremiumComponent } from './add-new-premium/add-new-premium.component';

@Component({
  selector: 'app-configure-premium',
  templateUrl: './configure-premium.component.html',
  styleUrls: ['./configure-premium.component.scss'],
})
export class ConfigurePremiumComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() relationshipType: string;
  @Input() sumInsuredType = '';
  @Input() policy: PreEnrollmentPolicyModel;
  @Input() corporate: CorporateModel;
  @Output() public cancelConfigurePremium = new EventEmitter<boolean>();

  modalOption: NgbModalOptions = {};

  constructor(
    private modalService: NgbModal,
    injector: Injector,
    private preEnrollmentService: PreEnrollmentService,
    private alertService: AlertService
  ) {
    super(injector);
  }

  isLoading: boolean;
  premiumDetails: PremiumDetails = new PremiumDetails();

  parentalSumInsured: ParentalSumInsured = null;
  enhancedSumInsured: EnhancedSumInsured = null;
  topupOption: TopupModel = null;
  selfSumInsured: SiExtrabenefits = null;

  psiPremiumDetails: PsiPremiumDetails[] = [];
  esiPremiumDetails: EsiPremiumDetails[] = [];
  topupPremiumDetails: TopupPremiumDetails[] = [];
  selfSiPremiumDetails: SiPremiumDetails[] = [];

  extraBenefitId: number;
  isEdit = true;
  deleteList: number[] = [];
  currentPremiumType = undefined;

  isExtraBenefitsApplicable = undefined;
  allowedSumInsured: AllowedSumInsuredModel;

  async ngOnInit() {
    console.log(this.relationshipType);
    console.log(this.sumInsuredType);
    console.log(this.policy);
    console.log(this.corporate);
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.isLoading = true;
    this.premiumDetails = new PremiumDetails();

    this.parentalSumInsured = null;
    this.enhancedSumInsured = null;
    this.topupOption = null;

    this.psiPremiumDetails = [];
    this.esiPremiumDetails = [];
    this.topupPremiumDetails = [];

    this.extraBenefitId = undefined;
    this.isEdit = true;
    this.deleteList = [];
    this.currentPremiumType = undefined;
    this.isExtraBenefitsApplicable = undefined;

    this.getSI().then(result => {
      if (this.relationshipType !== '' && this.sumInsuredType === 'PSI') {
        this.psiPremiumDetails.push(new PsiPremiumDetails());
        this.getPSIExtraBenefits();
      } else if (this.relationshipType !== '' && this.sumInsuredType === 'ESI') {
        this.esiPremiumDetails.push(new EsiPremiumDetails());
        this.getESIExtraBenefits();
      } else if (this.relationshipType !== '' && this.sumInsuredType === 'TopUp') {
        this.topupPremiumDetails.push(new TopupPremiumDetails());
        this.getTopupExtraBenefits();
      } else if (this.relationshipType !== '' && this.sumInsuredType === 'SI') {
        this.selfSiPremiumDetails.push(new SiPremiumDetails());
        this.getSIExtraBenefits();
      }
    });
  }

  async getSI() {
    await this.preEnrollmentService
      .getSumInsured(this.corporate.id, this.policy.id)
      .then(result => {
        this.allowedSumInsured = new AllowedSumInsuredModel(result);
      })
      .catch(error => {
        this.allowedSumInsured = new AllowedSumInsuredModel(null);
      });
  }

  addNewData(data) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(AddNewPremiumComponent, this.modalOption);
    modalRef.componentInstance.corporate = this.corporate;
    modalRef.componentInstance.policy = this.policy;
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.relationshipType = this.relationshipType;
    modalRef.componentInstance.sumInsuredType = this.sumInsuredType;
    modalRef.componentInstance.premiumType = this.premiumDetails.premiumType;
    modalRef.componentInstance.premiumAddedSucessfully.subscribe(isHrAdded => {
      if (isHrAdded === true) {
        this.isLoading = true;
        if (this.sumInsuredType === 'PSI') {
          this.getPSIPremiumData();
        } else if (this.sumInsuredType === 'ESI') {
          this.getESIPremiumData();
        } else if (this.sumInsuredType === 'TopUp') {
          this.getTopupPremiumData();
        } else if (this.sumInsuredType === 'SI') {
          this.getSIPremiumData();
        }
        this.isLoading = false;
      }
    });
  }

  getPremiumDetails() {
    let rship;
    if (this.relationshipType === 'Self') {
      rship = 'self';
    } else if (this.relationshipType === 'Parent') {
      rship = 'parent';
    } else if (this.relationshipType === 'Parent-In-Law') {
      rship = 'parentinlaw';
    }
    this.preEnrollmentService
      .getPremiumDetails(this.policy.id, rship)
      .then(x => {
        console.log(x);
        this.premiumDetails = x;
        this.currentPremiumType = this.premiumDetails.premiumType !== null ? this.premiumDetails.premiumType : null;
      })
      .catch(error => {
        console.log('Error while fetching premium details', error);
      });
  }

  async getSIExtraBenefits() {
    await this.preEnrollmentService
      .getSIextraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          if (result.peExtraBenefits.isAllowed) {
            this.selfSumInsured = result;
            this.isExtraBenefitsApplicable = true;
            this.extraBenefitId = this.selfSumInsured.peExtraBenefits.id;
            this.getPremiumDetails();
            this.getSIPremiumData();
          } else {
            this.isExtraBenefitsApplicable = false;
            this.isLoading = false;
          }
        } else {
          console.log(result);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getPSIExtraBenefits() {
    await this.preEnrollmentService
      .getPSIExtraBenefits(this.policy.id, this.relationshipType)
      .then(async result => {
        if (result && 'peExtraBenefits' in result) {
          if (result.peExtraBenefits.isAllowed) {
            this.parentalSumInsured = result;
            this.isExtraBenefitsApplicable = true;
            this.extraBenefitId = this.parentalSumInsured.peExtraBenefits.id;
            this.getPremiumDetails();
            this.getPSIPremiumData();
          } else {
            this.isExtraBenefitsApplicable = false;
            this.isLoading = false;
          }
        } else {
          console.log(result);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getSIPremiumData() {
    await this.preEnrollmentService
      .getSelfSIPremium(this.extraBenefitId)
      .then(result => {
        this.selfSiPremiumDetails = result;
        this.selfSiPremiumDetails = this.sortByKey(this.selfSiPremiumDetails);
        this.selfSiPremiumDetails.forEach(element => {
          for (const [key, value] of Object.entries(element)) {
            if (key === 'sumInsured' || key === 'premiumAmount') {
              if (element[key]) {
                const numberFormatPipe = new NumberFormatPipe();
                element[key] = numberFormatPipe.transform(element[key].toString());
              }
            }
          }
        });
        this.isLoading = false;
      })
      .catch(error => {
        this.isLoading = false;
      });
  }

  async getESIExtraBenefits() {
    await this.preEnrollmentService
      .getESIExtraBenefits(this.policy.id, this.relationshipType)
      .then(async result => {
        if (result && 'peExtraBenefits' in result) {
          if (result.peExtraBenefits.isAllowed) {
            this.enhancedSumInsured = result;
            this.isExtraBenefitsApplicable = true;
            this.extraBenefitId = this.enhancedSumInsured.peExtraBenefits.id;
            this.getPremiumDetails();
            this.getESIPremiumData();
          } else {
            this.isExtraBenefitsApplicable = false;
            this.isLoading = false;
          }
        } else {
          console.log(result);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getTopupExtraBenefits() {
    await this.preEnrollmentService
      .getTopupExtraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          if (result.peExtraBenefits.isAllowed) {
            this.topupOption = result;
            this.isExtraBenefitsApplicable = true;
            this.extraBenefitId = this.topupOption.peExtraBenefits.id;
            this.getPremiumDetails();
            this.getTopupPremiumData();
          } else {
            this.isExtraBenefitsApplicable = false;
            this.isLoading = false;
          }
        } else {
          console.log(result);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getESIPremiumData() {
    await this.preEnrollmentService
      .getESIPremium(this.extraBenefitId)
      .then(result => {
        console.log(result);
        this.esiPremiumDetails = result;
        this.esiPremiumDetails = this.sortByKey(this.esiPremiumDetails);
        this.esiPremiumDetails.forEach(element => {
          for (const [key, value] of Object.entries(element)) {
            if (key === 'sumInsured' || key === 'premiumAmount') {
              if (element[key]) {
                const numberFormatPipe = new NumberFormatPipe();
                element[key] = numberFormatPipe.transform(element[key].toString());
              }
            }
          }
        });
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
  }

  async getPSIPremiumData() {
    await this.preEnrollmentService
      .getPSIPremium(this.extraBenefitId)
      .then(result => {
        console.log(result);
        this.psiPremiumDetails = result;
        this.psiPremiumDetails = this.sortByKey(this.psiPremiumDetails);
        this.psiPremiumDetails.forEach(element => {
          for (const [key, value] of Object.entries(element)) {
            if (key === 'sumInsured' || key === 'premiumAmount') {
              if (element[key]) {
                const numberFormatPipe = new NumberFormatPipe();
                element[key] = numberFormatPipe.transform(element[key].toString());
              }
            }
          }
        });
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
  }

  async getTopupPremiumData() {
    await this.preEnrollmentService
      .getTopupPremium(this.extraBenefitId)
      .then(result => {
        console.log(result);
        this.topupPremiumDetails = result;
        this.topupPremiumDetails = this.sortByKey(this.topupPremiumDetails);
        this.topupPremiumDetails.forEach(element => {
          for (const [key, value] of Object.entries(element)) {
            if (key === 'sumInsured' || key === 'premiumAmount') {
              if (element[key]) {
                const numberFormatPipe = new NumberFormatPipe();
                element[key] = numberFormatPipe.transform(element[key].toString());
              }
            }
          }
        });
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
  }

  sortByKey(array) {
    return array.sort((x, y) => {
      return x.premiumAmount < y.premiumAmount ? -1 : x.premiumAmount > y.premiumAmount ? 1 : 0;
    });
  }

  selectAll() {
    const ele = document.getElementById('checkAll') as HTMLInputElement;
    if (ele.checked === true) {
      this.selects();
      this.deleteList = [];
      if (this.sumInsuredType === 'PSI') {
        this.psiPremiumDetails.forEach(element => {
          this.deleteList.push(element.id);
        });
      } else if (this.sumInsuredType === 'ESI') {
        this.esiPremiumDetails.forEach(element => {
          this.deleteList.push(element.id);
        });
      } else if (this.sumInsuredType === 'TopUp') {
        this.topupPremiumDetails.forEach(element => {
          this.deleteList.push(element.id);
        });
      } else if (this.sumInsuredType === 'SI') {
        this.selfSiPremiumDetails.forEach(element => {
          this.deleteList.push(element.id);
        });
      }
    } else {
      this.deleteList = [];
      this.deSelect();
    }
  }

  selects() {
    const ele = document.getElementsByName('chk');
    for (let i = 0; i < ele.length; i++) {
      const element = ele[i] as HTMLInputElement;
      if (element.type === 'checkbox') {
        element.checked = true;
      }
    }
  }

  deSelect() {
    const ele = document.getElementsByName('chk');
    for (let i = 0; i < ele.length; i++) {
      const element = ele[i] as HTMLInputElement;
      if (element.type === 'checkbox') {
        element.checked = false;
      }
    }
  }

  selectForDelete(sumInsuredType, event) {
    const ele = document.getElementById('checkAll') as HTMLInputElement;
    if (event.target.checked) {
      let flag = true;
      const ele1 = document.getElementsByName('chk');
      for (let i = 0; i < ele1.length; i++) {
        const element = ele1[i] as HTMLInputElement;
        if (element.checked === false) {
          flag = false;
          break;
        }
      }

      if (flag === true) {
        ele.checked = true;
        this.deleteList = [];
        if (this.sumInsuredType === 'PSI') {
          this.psiPremiumDetails.forEach(element => {
            this.deleteList.push(element.id);
          });
        } else if (this.sumInsuredType === 'ESI') {
          this.esiPremiumDetails.forEach(element => {
            this.deleteList.push(element.id);
          });
        } else if (this.sumInsuredType === 'TopUp') {
          this.topupPremiumDetails.forEach(element => {
            this.deleteList.push(element.id);
          });
        } else if (this.sumInsuredType === 'SI') {
          this.selfSiPremiumDetails.forEach(element => {
            this.deleteList.push(element.id);
          });
        }
      } else {
        ele.checked = false;
        this.deleteList.push(sumInsuredType.id);
      }
    } else {
      ele.checked = false;
      this.deleteList.forEach((element, index) => {
        if (element === sumInsuredType.id) {
          this.deleteList.splice(index, 1);
        }
      });
    }
  }

  deletePremium() {
    const data = [
      {
        id: this.deleteList,
        premiumType: this.sumInsuredType,
      },
    ];

    let result;
    const message = `Are you sure you want to delete selected premium(s)?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.preEnrollmentService
            .deletePremium(data)
            .then(x => {
              this.alertService.success('Premium deleted successfully');
              this.isLoading = true;
              if (this.sumInsuredType === 'PSI') {
                this.getPSIPremiumData();
                this.deleteList = [];
              } else if (this.sumInsuredType === 'ESI') {
                this.getESIPremiumData();
                this.deleteList = [];
              } else if (this.sumInsuredType === 'TopUp') {
                this.getTopupPremiumData();
                this.deleteList = [];
              } else if (this.sumInsuredType === 'SI') {
                this.getSIPremiumData();
                this.deleteList = [];
              }
            })
            .catch(error => {
              this.alertService.error('Error occured!');
            });
        } catch (error) {
          this.alertService.error('Error occured');
        }
      }
    });
  }
}
