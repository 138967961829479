<div class="create-enrollment-component-wrapper" *ngIf="initialized">
  <div class="container">
    <div class="error-msg" *ngFor="let error of errorR">
      <p>{{ error.message }}</p>
    </div>
    <div class="create-enrollment-box">
      <div class="enrollment-box-row">
        <div class="box-body">
          <!-- registration email div -->

          <h6>FINAL SUBMISSION COMPLETED EMAIL</h6>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [(ngModel)]="confirmationEmailData.sendRegistrationConfirmationEmail"
                      (ngModelChange)="sendConfirmationEmail()"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Send final submission completed email to the employees</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" [hidden]="!confirmationEmailData.sendRegistrationConfirmationEmail">
            <div class="col-md-6">
              <div class="form-group">
                <label>Final Submission Completed Email CC</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="confirmationEmailData.registrationConfirmationEmailCcs"
                />
              </div>
              <p [hidden]="isCcEmailValid" class="errorMsg">{{ invalidEmailBccMsg }}</p>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Final Submission Completed Email BCC</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="confirmationEmailData.registrationConfirmationEmailBccs"
                />
              </div>
            </div>

            <div class="col-md-12">
              <form [formGroup]="form">
                <div class="form-group">
                  <label>Final Submission Completed Email Subject<sup>*</sup></label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="message"
                    [(ngModel)]="confirmationEmailData.registrationConfirmationEmailSubject"
                    (input)="isConfirmationSubjectValid($event)"
                  />
                  <span
                    class="error-msg small"
                    *ngIf="
                      form.get('message').dirty &&
                      form.get('message').status === 'INVALID' &&
                      confirmationEmailSubjectLength
                    "
                    >Invalid Subject
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                  <span class="error-msg small" *ngIf="!confirmationEmailSubjectLength">
                    {{ confirmationSubjectErrorMsg }}
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div class="row" [hidden]="!confirmationEmailData.sendRegistrationConfirmationEmail">
            <div class="col">
              <div class="form-group">
                <label>Final Submission Completed Email template <sup>*</sup></label>
                <editor
                  apiKey="65447p1khods6dtyql8kuu3wf9xhgw1hbekv2nj8xibdxfob"
                  initialValue="{{ confirmationEmailData.registrationConfirmationEmailTemplate }}"
                  [(ngModel)]="confirmationEmailData.registrationConfirmationEmailTemplate"
                  [init]="{
                    height: 340,
                    menubar: false,
                    plugins: ['table'],
                    toolbar:
                      'undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | forecolor backcolor  permanentpen  removeformat '
                  }"
                >
                </editor>
              </div>
            </div>
          </div>

          <div class="row pl-3" [hidden]="!confirmationEmailData.sendRegistrationConfirmationEmail">
            <div class="form-group">
              <button class="btn px-4 btn-sm mb-2 btn-primary" *ngIf="true" type="button" (click)="fileInput.click()">
                <span>Upload file</span>
                <input
                  #fileInput
                  id="file"
                  type="file"
                  (change)="onFileChange($event.target.files)"
                  style="display:none;"
                  accept=".xlsx,.jpg, .png, .doc, .docx,.pdf"
                />
              </button>
              <div *ngIf="fileName !== '' && fileName !== null && fileName !== undefined" class="position-relative">
                <label>File Name/Link</label>
                <input
                  id="fileText"
                  type="text"
                  class="form-control"
                  [(ngModel)]="fileName"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder=""
                  readonly
                />
                <button
                  class="btn btn-sm remove-file-wrapper"
                  [hidden]="file === '' || file === null || file === undefined"
                >
                  <i class="material-icons" (click)="deleteFile()">delete</i>
                </button>
                <button
                  type="submit"
                  (click)="downloadFile()"
                  class="btn btn-sm import-btn"
                  [hidden]="file === '' || file === null || file === undefined"
                >
                  <i class="material-icons">cloud_download</i>
                  <span>Download</span>
                </button>
              </div>

              <p [hidden]="isSizeValid" class="error-msg mb-0">
                <small>{{ uploadImageInvalidMsg }}</small>
              </p>
            </div>
          </div>

          <div class="row pl-3">
            <button (click)="submit()" [disabled]="!isSaveButtonEnable" class="btn btn-secondary">
              {{ displaySave }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
