import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';

@Component({
  selector: 'app-premium-calculator',
  templateUrl: './premium-calculator.component.html',
  styleUrls: ['./premium-calculator.component.scss'],
})
export class PremiumCalculatorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('closebutton', { static: true }) closebutton;
  @Input() corporate: CorporateModel;
  @Input() policy: PreEnrollmentPolicyModel;
  @Output() closePeriodModal = new EventEmitter<boolean>();

  sumInsuredType = '';
  relationshipType = '';
  currentTab = 'SI';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.sumInsuredType === 'PD') {
      this.currentTab = 'PD';
    }
  }

  ngOnInit() {
    super.ngOnInit();
    const dropdown = document.getElementsByClassName('dropdown-btn');
    let i;

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener('click', function() {
        this.classList.toggle('active');
        const dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === 'block') {
          dropdownContent.style.display = 'none';
        } else {
          dropdownContent.style.display = 'block';
        }
      });
    }
    this.relationshipType = 'Self';
    this.sumInsuredType = 'PD';
    const ele = document.getElementById('SelfPD');
    ele.className = 'dropdown-item active';
  }

  setCurrentScreen(rType, sumInsType) {
    const dropdownArray = [
      'SelfTopUp',
      'SelfESI',
      'SelfSI',
      'SelfPD',
      'ParentPD',
      'ParentPSI',
      'ParentESI',
      'ParentTopUp',
      'Parent-In-LawPD',
      'Parent-In-LawPSI',
      'Parent-In-LawESI',
      'Parent-In-LawTopUp',
    ];
    dropdownArray.forEach(element => {
      document.getElementById(element).className = 'dropdown-item';
    });
    this.relationshipType = rType;
    this.sumInsuredType = sumInsType;
    console.log(this.relationshipType, this.sumInsuredType, this.currentTab);
    const ele = document.getElementById(rType + sumInsType);
    ele.className = 'dropdown-item active';
    if (this.sumInsuredType === 'PD') {
      this.currentTab = 'PD';
    }
    if (
      this.sumInsuredType !== 'PD' &&
      this.sumInsuredType !== '' &&
      (this.currentTab === 'SI' || this.currentTab === 'PD' || this.currentTab !== 'PC')
    ) {
      this.currentTab = 'SI';
    }
  }

  setTabName(tabName) {
    this.currentTab = tabName;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
