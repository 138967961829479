<div class="list-component-wrapper">
  <div class="list-component-header" [hidden]="isLoading">
    <div class="container">
      <input type="text" placeholder="Search notifications" class="form-control search-box" [(ngModel)]="searchText" />
      <a routerLink="../create" class="btn btn-primary text-white">
        <i class="material-icons">add</i> <span>Create Notification</span></a
      >
    </div>
  </div>

  <div class="container">
    <div class="header-tabs">
      <nav>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link active show"
              id="Biz-admin-notification-tab"
              data-toggle="tab"
              href="#Biz-admin-notification"
              role="tab"
              aria-controls="Biz-admin-notification"
              aria-selected="true"
            >
              Created By Biz-admin
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Hr-notification-tab"
              data-toggle="tab"
              href="#Hr-notification"
              role="tab"
              aria-controls="Hr-notification"
              aria-selected="false"
            >
              Created By HR
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Broker-notification-tab"
              data-toggle="tab"
              href="#Broker-notification"
              role="tab"
              aria-controls="Broker-notification"
              aria-selected="false"
            >
              Created By Broker
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="SM-notification-tab"
              data-toggle="tab"
              href="#SM-notification"
              role="tab"
              aria-controls="SM-notification"
              aria-selected="false"
            >
              Created By SM
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="container">
    <div class="tab-content">
      <div class="tab-pane active" id="Biz-admin-notification" role="tabpanel">
        <app-biz-admin-notification [searchText]="searchText"></app-biz-admin-notification>
      </div>
      <div class="tab-pane" id="Hr-notification" role="tabpanel">
        <app-hr-notification [searchText]="searchText" role="CPHR"></app-hr-notification>
      </div>
      <div class="tab-pane" id="Broker-notification" role="tabpanel">
        <app-hr-notification [searchText]="searchText" role="Broker"></app-hr-notification>
      </div>
      <div class="tab-pane" id="SM-notification" role="tabpanel">
        <app-hr-notification [searchText]="searchText" role="SM"></app-hr-notification>
      </div>
    </div>
  </div>
</div>
