import { Component, OnInit, Input, Injector, OnDestroy } from '@angular/core';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AttachPolicyComponent } from './attach-policy/attach-policy.component';

@Component({
  selector: 'app-corporate-portal-attach-policy',
  templateUrl: './corporate-portal-attach-policy.component.html',
  styleUrls: ['./corporate-portal-attach-policy.component.scss'],
})
export class CorporatePortalAttachPolicyComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() corporate: CorporateModel;
  @Input() policy: any;
  modalOption: NgbModalOptions = {};

  constructor(injector: Injector, private modalService: NgbModal) {
    super(injector);
  }

  open() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(AttachPolicyComponent, this.modalOption);
    modalRef.componentInstance.corporate = this.corporate;
    modalRef.componentInstance.policy = this.policy;
  }

  ngOnInit() {}
}
