<app-popup-model
  [title]="title"
  [saveButton]="saveButtonText"
  [isDisableSaveButton]="!notificationForm.valid"
  (close)="close()"
  (save)="save($event)"
  [extraClass]="extraClass"
>
  <form [formGroup]="notificationForm">
    <div class="error-msg" *ngFor="let error of errorList">
      <p>{{ error.message }}</p>
    </div>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
    <div class="form-group">
      <label>Notification Subject<sup>*</sup></label>
      <input type="text" formControlName="subject" class="form-control" />
      <div class="error-msg" *ngIf="notificationForm.controls.subject.errors !== null">
        <p
          *ngIf="
            notificationForm.controls.subject.errors.minlength && !notificationForm.controls.subject.errors.pattern
          "
        >
          Minimum length is 10 characters
        </p>
        <p
          *ngIf="notificationForm.controls.subject.errors.pattern && notificationForm.controls.subject.errors.minlength"
        >
          Subject is invalid
        </p>
        <p
          *ngIf="
            notificationForm.controls.subject.errors.pattern && !notificationForm.controls.subject.errors.minlength
          "
        >
          Subject is invalid
        </p>
      </div>
    </div>
    <div class="form-group">
      <label>Notification Effective From<sup>*</sup></label>
      <div class="input-group">
        <input
          value="{{ date1 | date: 'dd MMMM yyyy' }}"
          type="text"
          class="form-control"
          readonly
          bsDatepicker
          formControlName="effectiveDateFrom"
          (click)="StartDateSelected()"
          [minDate]="date"
          [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY', containerClass: 'theme-blue' }"
        />
      </div>
    </div>
    <div class="form-group">
      <label>Notification Effective To<sup>*</sup></label>
      <div class="input-group">
        <input
          value="{{ date2 | date: 'dd MMMM yyyy' }}"
          type="text"
          class="form-control"
          readonly
          bsDatepicker
          formControlName="effectiveDateTo"
          [minDate]="notificationForm.value.effectiveDateFrom"
          [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY', containerClass: 'theme-blue' }"
        />
      </div>
    </div>
    <div class="form-group">
      <label for="policy">Notification Details:<sup>*</sup></label>
      <textarea type="text" maxlength="256" formControlName="content" class="form-control"></textarea>
      <div class="text-remain">
        <span id="chars">{{ 256 - notificationForm.value.content.length }}</span> characters remaining
      </div>
      <div class="error-msg" *ngIf="notificationForm.controls.content.errors !== null">
        <p
          *ngIf="
            notificationForm.controls.content.errors.minlength && !notificationForm.controls.content.errors.pattern
          "
        >
          Minimum length is 10 characters
        </p>
        <p
          *ngIf="notificationForm.controls.content.errors.pattern && notificationForm.controls.content.errors.minlength"
        >
          Details is invalid
        </p>
        <p
          *ngIf="
            notificationForm.controls.content.errors.pattern && !notificationForm.controls.content.errors.minlength
          "
        >
          Details is invalid
        </p>
      </div>
    </div>

    <div class="form-group">
      <button class="btn px-4 btn-sm mb-2 btn-primary" *ngIf="true" type="button" (click)="fileInput.click()">
        <span>Upload file</span>
        <input
          #fileInput
          id="file"
          type="file"
          (change)="onFileChange($event.target.files)"
          style="display:none;"
          accept=".xlsx,.jpg, .png, .doc, .docx,.pdf"
        />
      </button>
      <div class="position-relative" *ngIf="notificationForm.value.file !== ''">
        <input
          id="fileText"
          type="text"
          class="form-control"
          [(ngModel)]="fileName"
          [ngModelOptions]="{ standalone: true }"
          placeholder=""
          readonly
        />
        <button class="btn btn-sm delete-file-wrapper">
          <i class="material-icons" (click)="deleteFile()">delete</i>
        </button>
      </div>

      <p [hidden]="isSizeValid" class="error-msg mb-0">
        <small>{{ uploadImageInvalidMsg }}</small>
      </p>
    </div>

    <div *ngIf="role === 'Broker' || role === 'SM'">
      <div class="form-group">
        <label class="col-form-label">Broadcast To<sup>*</sup> : </label>
        <div class="form-control p-0 border-0">
          <!-- Material inline 1 -->
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input"
                value="All Corporates"
                formControlName="broadcast_to"
                (click)="setAllCorporates()"
              />
              <span>All Corporates</span>
            </label>
          </div>

          <!-- Material inline 2 -->
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input"
                value="Specific Corporates"
                formControlName="broadcast_to"
                (click)="getCorporates()"
              />
              <span>Specific Corporates</span>
            </label>
          </div>
        </div>
      </div>

      <div *ngIf="notificationForm.value.broadcast_to === 'Specific Corporates'">
        <div class="form-group">
          <label class="col-form-label">Corporate Code* : </label>
          <select class="form-control" (change)="corporateChanged()" formControlName="corporateCode">
            <option *ngFor="let corporate of corporates" [value]="corporate.code">{{ corporate.code }}</option>
          </select>
        </div>
      </div>

      <div *ngIf="notificationForm.value.broadcast_to === 'Specific Corporates'">
        <div class="form-group">
          <label class="col-form-label">Corporate Name* : </label>
          <input type="text" formControlName="corporateName" disabled class="form-control" />
        </div>
      </div>
    </div>

    <div
      class="form-group"
      *ngIf="
        (notificationForm.value.broadcast_to === 'Specific Corporates' && (role === 'Broker' || role === 'SM')) ||
        role === 'CPHR'
      "
    >
      <label for="staticEmail" class="col-form-label">Notify To<sup>*</sup> : </label>
      <div class="form-control p-0 border-0">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input
              type="radio"
              class="form-check-input"
              value="All Policies"
              formControlName="notify_to"
              (click)="setAllPolicies()"
            />
            <span>All Policies</span>
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input
              type="radio"
              class="form-check-input"
              value="Specific Policy"
              formControlName="notify_to"
              (click)="getPolicies()"
            />
            <span>Specific Policy</span>
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="notificationForm.value.notify_to === 'Specific Policy'">
      <div class="form-group">
        <label for="inputPassword" class="col-form-label">Policy Id* : </label>
        <div class="dropdown">
          <button
            class="btn my-0 dropdown-toggle text-left w-100"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <span *ngIf="currentPolicyChosen === null">Select policy</span>
            <span *ngIf="currentPolicyChosen !== null && currentPolicyChosen.isActive" class="logged"></span>
            <span *ngIf="currentPolicyChosen !== null && !currentPolicyChosen.isActive" class="notLogged"></span>
            <span *ngIf="currentPolicyChosen !== null"> {{ currentPolicyChosen.policyNumber }}</span>
          </button>
          <div class="dropdown-menu dropdown-menu-right w-100">
            <div *ngFor="let policy of policies" (click)="policyChanged(policy)">
              <a class="dropdown-item" *ngIf="policy.isActive">
                <span *ngIf="policy.isActive" class="logged"></span>
                <span *ngIf="!policy.isActive" class="notLogged"></span>
                <span> {{ policy.policyNumber }}</span>
              </a>
            </div>
          </div>
        </div>
        <!-- <select class="form-control" (change)="policyChanged()" formControlName="policyNumber"> -->
        <!-- <option *ngFor="let policy of policies" [value]="policy.policyNumber">{{ policy.policyNumber }}</option> -->
        <!-- </select> -->
      </div>
    </div>
  </form>
</app-popup-model>
