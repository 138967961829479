import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { BehaviorSubject } from 'rxjs';
import { Endorsement } from '../models/endorsement/endorsement.model';

@Injectable({
  providedIn: 'root',
})
export class EndorsementService {
  cpmemberbaseapi = 'CorporatePortal';
  allPolicies: BehaviorSubject<any[]>;
  policies: BehaviorSubject<any[]>;
  currentPolicy: BehaviorSubject<any>;

  constructor(private readonly backendService: BackendService) {
    this.allPolicies = new BehaviorSubject<any[]>([]);
    this.policies = new BehaviorSubject<any[]>([]);
    this.currentPolicy = new BehaviorSubject<any>({});
  }

  public approveRejectEndorsement(endorsementId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post(this.cpmemberbaseapi + `/Endorsement/${endorsementId}/ApproveReject`, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public bulkApproveRejectEndorsement(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .post(this.cpmemberbaseapi + `/policies/${policyId}/Endorsement/Bulk/ApproveReject`, data)
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  async getBrokerEndorsement(policyId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.get(`CorporatePortal/policies/${policyId}/Endorsement/For/Broker`).then(
        resul => {
          return resolve(this.mapEndorsement(resul));
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async getSMEndorsement(policyId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.get(`CorporatePortal/policies/${policyId}/Endorsement/For/SM`).then(
        resul => {
          return resolve(this.mapEndorsement(resul));
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async exportExcel(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.downloadFile(this.cpmemberbaseapi + `/policies/${policyId}/Endorsement/Export/Excel`).then(
        fileBlob => {
          const blob = new Blob([fileBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          return resolve(blob);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async getMemberEndorsementSample(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.downloadFile(this.cpmemberbaseapi + `/Endorsement/SampleExcel/relation/self`).then(
        fileBlob => {
          const blob = new Blob([fileBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          return resolve(blob);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async getDependentEndorsementSample(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .downloadFile(this.cpmemberbaseapi + `/Endorsement/SampleExcel/relation/Dependent`)
        .then(fileBlob => {
          const blob = new Blob([fileBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          return resolve(blob);
        });
    });
  }

  public getEndorsements(policyId, createdBy, role, relation): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .get(
          this.cpmemberbaseapi +
            '/policies/' +
            policyId +
            `/Endorsement/createdby/${createdBy}/For/${role}/relation/${relation}}`
        )
        .then(
          response => {
            return resolve(this.mapEndorsement(response));
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public getDependentEndorsed(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .get(this.cpmemberbaseapi + '/policies/' + policyId + '/Endorsement/createdby/CPHr/for/CPhr/relation/dependent')
        .then(
          response => {
            return resolve(this.mapEndorsement(response));
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public getMemberEndorsed(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .get(this.cpmemberbaseapi + '/policies/' + policyId + '/Endorsement/createdby/CPHr/for/CPhr/relation/self')
        .then(
          response => {
            return resolve(this.mapEndorsement(response));
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public getEndorsementCreatedByMember(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .get(
          this.cpmemberbaseapi +
            '/policies/' +
            policyId +
            '/Endorsement/createdby/CPmember/for/CPmember/relation/dependent'
        )
        .then(
          response => {
            return resolve(this.mapEndorsement(response));
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public getEndorsementCreatedByMemberForHr(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .get(
          this.cpmemberbaseapi + '/policies/' + policyId + '/Endorsement/createdby/CPmember/For/CPHr/relation/Dependent'
        )
        .then(
          response => {
            return resolve(this.mapEndorsement(response));
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public createEmployeeEndorsement(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post(this.cpmemberbaseapi + '/policies/' + policyId + '/Endorsement/Employee', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public createDependentEndorsement(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post(this.cpmemberbaseapi + '/policies/' + policyId + '/Endorsement/Dependent', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public excelMemberEndorsement(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .postFile(this.cpmemberbaseapi + '/policies/' + policyId + '/ExcelEndorsement/Employee', data)
        .then(
          response => {
            return resolve({});
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public excelDependentEndorsement(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .postFile(this.cpmemberbaseapi + '/policies/' + policyId + '/ExcelEndorsement/Dependent', data)
        .then(
          response => {
            return resolve({});
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public dependentEndorsementsExcel(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService
        .downloadFile(this.cpmemberbaseapi + `/Endorsement/Dependent/createdBy/Member`)
        .then(fileBlob => {
          const blob = new Blob([fileBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          return resolve(blob);
        });
    });
  }

  public dependentEndorsementsCount(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + `/Endorsement/Dependent/createdBy/Member/count`).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  mapEndorsement(endorsement: Endorsement[]) {
    endorsement.forEach(e => {
      e.remark = this.getEndorsementRemark(e);
    });

    return endorsement;
  }

  getEndorsementRemark(endorsement: Endorsement) {
    if (endorsement.approveRejectFlowModel != null) {
      if (endorsement.approveRejectFlowModel.hr.isRejected) {
        endorsement.remark = endorsement.approveRejectFlowModel.hr.remark;
      } else if (endorsement.approveRejectFlowModel.broker.isRejected) {
        endorsement.remark = endorsement.approveRejectFlowModel.broker.remark;
      } else if (endorsement.approveRejectFlowModel.sm.isRejected) {
        endorsement.remark = endorsement.approveRejectFlowModel.sm.remark;
      } else if (endorsement.approveRejectFlowModel.sm.isApproved) {
        endorsement.remark = endorsement.approveRejectFlowModel.sm.remark;
      } else if (endorsement.approveRejectFlowModel.broker.isApproved) {
        endorsement.remark = endorsement.approveRejectFlowModel.broker.remark;
      } else if (endorsement.approveRejectFlowModel.hr.isApproved) {
        endorsement.remark = endorsement.approveRejectFlowModel.hr.remark;
      }
    }

    return endorsement.remark;
  }
}
