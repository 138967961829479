<div class="row p-4">
  <div class="card card-style-1 col-xs-12 col-md-6 col-lg-6 p-0">
    <div class="card-body">
      <div class="card-title row justify-content-between align-items-center">
        <h4 class="m-0 header-title">Add Nominee Details</h4>
        <div class="dropdown card-header-dropdown" *ngIf="policies.length > 0">
          <button class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <span *ngIf="currentPolicy.isActive" class="logged"></span>
            <span *ngIf="!currentPolicy.isActive" class="notLogged"></span>
            <span *ngIf="currentPolicy.onScreenDisplayAvailable == false">
              {{ currentPolicy.riskStartDate.split('-')[0] }}-{{ currentPolicy.riskEndDate.split('-')[0] }} -
              {{ currentPolicy.policyNumber }} - {{currentPolicy.policyType}}</span>
            <span *ngIf="currentPolicy.onScreenDisplayAvailable == true">{{currentPolicy.onScreenDisplayPolicy}}</span>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <div *ngFor="let policy of policies" (click)="onNamePolicyChanged(policy)">
              <a class="dropdown-item">
                <span *ngIf="policy.isActive" class="logged"></span>
                <span *ngIf="!policy.isActive" class="notLogged"></span>
                <span *ngIf="policy.onScreenDisplayAvailable == false">
                  {{ policy.riskStartDate.split('-')[0] }}-{{ policy.riskEndDate.split('-')[0] }} -
                  {{ policy.policyNumber }} - {{policy.policyType}}</span>
                <span *ngIf="policy.onScreenDisplayAvailable == true">{{policy.onScreenDisplayPolicy}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="col-12 py-4">
          <form *ngIf="currentPolicy.nomineeEnroll" [formGroup]="nomineeDetailForm" (submit)="onSubmit($event)">
            <div class="col-sm-12">
              <div class="form-group">
                <label>Name (as per Gov Id Proof)<sup>*</sup></label>
                <input type="text" class="form-control" formControlName="name" placeholder="Please enter the name" maxlength="50"/>
                <span class="error-msg small" *ngIf="
                                          nomineeDetailForm.get('name').dirty && nomineeDetailForm.get('name').status === 'INVALID'
                                          ">Max length 50 characters<i class="fa fa-exclamation-triangle"
                    aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>Email</label>
                <input type="text" class="form-control" formControlName="email" placeholder="Please enter the email id" maxlength="50"/>
                <span class="error-msg small" *ngIf="
                                        nomineeDetailForm.get('email').dirty && nomineeDetailForm.get('email').status === 'INVALID'
                                        ">Please enter valid email address<i class="fa fa-exclamation-triangle"
                    aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>Relationship<sup>*</sup></label>
                <select class="form-control" formControlName="relationship">
                  <option value="" disabled selected>--Select a Relationship--</option>
                  <option value="spouse">Spouse</option>
                  <option value="son">Son</option>
                  <option value="daughter">Daughter</option>
                  <option value="mother">Mother</option>
                  <option value="father">Father</option>
                </select>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group d-flex flex-row">
                <label>Nominee Percentage Share<sup>*</sup></label>
                <input type="text" class="form-control" value="100%" disabled/>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>Gender<sup>*</sup></label>
                <select class="form-control" formControlName="gender">
                  <option value="" disabled selected>--Select a Gender--</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                </select>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>Employee Id<sup>*</sup></label>
                <input type="text" class="form-control" formControlName="employeeId" placeholder="Please enter the employee id" maxlength="20" [(ngModel)]="employeeId"
                [attr.disabled]="!isHrView ? true : null"/>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>Date of Birth<sup>*</sup></label>
                <input class="form-control" formControlName="dob" placeholder="Please select date of birth" type="text"
                  readonly required bsDatepicker [minDate]="minDate" [maxDate]="maxDate" [bsConfig]="{
                                        isAnimated: true,
                                        dateInputFormat: 'DD MMMM YYYY',
                                        adaptivePosition: true,
                                        containerClass: 'theme-blue'
                                      }" />
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>Mobile No<sup>*</sup></label>
                <input type="text" class="form-control" formControlName="mobileno" placeholder="Please enter 10 digits mobile number" maxlength="10"/>
                <span class="error-msg small" *ngIf="
                                        nomineeDetailForm.get('mobileno').dirty && nomineeDetailForm.get('mobileno').status === 'INVALID'
                                        ">Please enter valid Mobile Number<i class="fa fa-exclamation-triangle"
                    aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>PAN</label>
                <input type="text" class="form-control" formControlName="pan" placeholder="Please enter 10 characters PAN number" maxlength="10"/>
                <span class="error-msg small" *ngIf="
                                        nomineeDetailForm.get('pan').dirty && nomineeDetailForm.get('pan').status === 'INVALID'
                                        ">Please enter valid PAN (All Capitals)<i class="fa fa-exclamation-triangle"
                    aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="col-12 text-center">
              <input class="btn btn-primary w-100"
                      [disabled]="!nomineeDetailForm.valid" type="submit" value="Submit Details">
            </div>
          </form>
          <div *ngIf="!currentPolicy.nomineeEnroll">
            This feature is not allowed for Current Policy
          </div>
        </div>
      </div>
    </div>
  </div>
</div>