import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { CorporateSummaryModel } from 'src/app/shared/models/corporate/CorporateSummaryModel';
import { RoleService } from 'src/app/shared/services/role.service';
import { BrokerService } from 'src/app/broker/service/broker.service';
import { CPPolicyService } from '../../services/cp-policy.service';
import { HRService } from 'src/app/member/services/hr.service';
import { CorporatePolicyModel } from '../../models/corporate-policy.model';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { SMService } from 'src/app/sm/service/sm.service';

@Component({
  selector: 'app-cp-header',
  templateUrl: './cp-header.component.html',
  styleUrls: ['./cp-header.component.scss'],
})
export class CpHeaderComponent implements OnInit {
  name: string;
  isHrView = false;
  userRole: string;

  currentPolicy: Policy;
  policies = [];

  isHr: boolean;
  isMember: boolean;

  corporate: CorporatePolicyModel = new CorporatePolicyModel();
  corporates: CorporatePolicyModel[] = [];

  constructor(
    private authService: AuthService,
    private roleService: RoleService,
    private cpMemberService: CPMemberService,
    private brokerService: BrokerService,
    private smService: SMService,
    private policyService: CPPolicyService,
    private hrService: HRService
  ) {}

  ngOnInit() {
    this.userRole = this.authService.role;
    this.policyService.policies.subscribe(policies => {
      this.policies = policies;
    });

    if (this.authService.role === Roles.CPMember || this.authService.role === Roles.CPHR) {
      this.cpMemberService.getMemberOrHrCorporate().then(cor => {
        this.corporate = cor;
      });
      this.cpMemberService.hrDetails.subscribe(m => {
        this.name = m.name;
      });
      if (this.name === undefined) {
        this.cpMemberService.selfMemberDetails.subscribe(m => {
          this.name = m.insuredName;
        });
      }
    } else if (this.authService.role === Roles.Broker) {
      this.policyService.getBrokerCorporatesAndPolicies().then(c => {
        this.corporates = c;
        this.corporate = c[0];
        this.policies = [];
        this.policies = this.corporate.policies;
      });
      this.brokerService.brokerProfile.subscribe(x => {
        this.name = x.name;
      });
    } else if (this.authService.role === Roles.SM) {
      this.smService.smProfile.subscribe(x => {
        this.name = x.name;
      });
      this.policyService.getSMCorporatesAndPolicies().then(c => {
        this.corporates = c;
        this.corporate = c[0];
        this.policies = [];
        this.policies = this.corporate.policies;
      });
    }

    // this.authService.userName.subscribe(name => (this.userName = name));
  }

  changeCorporate(event) {
    this.policyService.currentCorporate.next(event);
    this.corporate = event;
    this.policies = [];
    this.policies = event.policies;
    this.policyService.policies.next([]);
    this.policyService.allPolicies.next([]);
    this.policyService.policies.next(event.policies);
    this.policyService.allPolicies.next(event.policies);
    this.policyService.currentPolicy.next(event.policies[0]);
  }
}
