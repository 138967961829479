<div class="members-component-wrapper">
  <div class="members-component-header">
    <h3 class="row-title mb-0">
      <div [hidden]="isRoleHR">Members</div>
    </h3>
    <div class="header-right">
      <input
        type="text"
        placeholder="Search member"
        class="search-input"
        [(ngModel)]="searchText"
        (input)="searchingMember($event)"
        [hidden]="fileSelected"
      />
      <form [formGroup]="form">
        <button type="submit" class="btn btn-sm import-btn" [hidden]="fileSelected || isRoleHR">
          <input
            type="file"
            #fileInput
            name="avatar"
            (click)="fileInput.value = null"
            (change)="fileSelectedChanged($event)"
            accept=".xlsx"
          />
          <i class="material-icons">cloud_upload</i>
          <span>Import</span>
        </button>
      </form>
      <form [formGroup]="form">
        <button type="submit" (click)="downloadMemberFile()" class="btn btn-sm import-btn">
          <i class="material-icons">cloud_download</i>
          <span>Download</span>
        </button>
      </form>
      <form [formGroup]="form">
        <button type="submit" (click)="resetPeCredentials()" [hidden]="isRoleHR" class="btn btn-sm reset-btn ml-2 pb-1">
          <i class="material-icons">refresh</i>
          <span>Resend Credentials</span>
        </button>
        <button type="submit" (click)="showFiles()" class="btn btn-sm reset-btn ml-2 pb-1">
          <span>Show Files</span>
        </button>
      </form>
      <form [formGroup]="form">
        <button
          type="submit"
          (click)="unlockAllMembers()"
          class="btn btn-sm reset-btn ml-2 pb-1"
          [disabled]="unlockMembersProcessing"
        >
          <i class="material-icons">lock_open</i>
          <span>Unlock Members</span>
          <mat-icon *ngIf="unlockMembersProcessing"><mat-spinner color="warn" diameter="23"> </mat-spinner></mat-icon>
        </button>
      </form>
      <!-- <form [formGroup]="form">
        <button [hidden]="!showJobStatus" type="submit" class="btn btn-sm reset-btn ml-2 pb-1" (click)="getJobStatus()">
          <i class="material-icons">sms</i>
          <span>Check Status</span>
        </button>
      </form> -->
    </div>
  </div>

  <div class="members-component-body" *ngIf="userRole">
    <div class="table-grid" [hidden]="!fileSelected || isRoleHR">
      <div class="upload-file-info-wrapper">
        <div *ngIf="file" class="file-name">
          {{ fileNameInUi }}
        </div>
        <div>
          <button type="submit" class="btn btn-primary px-4" (click)="uploadFile()" [disabled]="this.isUploading">
            {{ this.isUploading ? 'Uploading...' : 'Upload' }}
          </button>
          <button type="button" class="btn btn-link" (click)="cancelFileUpload()" [hidden]="this.isUploading">
            Cancel
          </button>
        </div>
      </div>

      <table *ngIf="error.length > 0">
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Error Message</th>
          </tr>
        </thead>
        <ng-container>
          <tr *ngFor="let errorField of error">
            <td class="error-msg">{{ errorField.fieldName }}</td>
            <td class="error-msg">{{ errorField.message }}</td>
          </tr>
        </ng-container>
      </table>
    </div>
    <div class="table-grid" [hidden]="fileSelected">
      <div [hidden]="isLoading || !members || members.length > 0 || !isSearchMemberFound" class="no-result-wrapper">
        <h5>{{ msg }}</h5>
      </div>
      <div [hidden]="isSearchMemberFound" class="no-result-wrapper">
        <h5>No members found!</h5>
      </div>

      <div class="table-wrapper">
        <table>
          <thead [hidden]="isLoading || members.length === 0">
            <tr>
              <th>Emp Id</th>
              <th>Name</th>
              <th>Date of birth</th>
              <th>Family Count</th>
              <th></th>
            </tr>
          </thead>

          <!-- Shimmers Start -->
          <thead [hidden]="!isLoading">
            <tr>
              <th>Emp Id</th>
              <th>Name</th>
              <th>Date of birth</th>
              <th>Family Count</th>
              <th></th>
            </tr>
          </thead>
          <tbody [hidden]="!isLoading">
            <tr>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer primary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer primary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer primary"></div>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer primary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer primary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer primary"></div>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
          <!-- Shimmers End -->
          <ng-container *ngIf="members">
            <tbody *ngFor="let member of members" [hidden]="isLoading || members.length === 0">
              <tr
                *ngIf="member.relationshipId == '1'"
                class="action-row"
                (click)="toggleDependentsViewVisibility(member)"
              >
                <td>
                  <div>{{ member.employeeId }}</div>
                </td>
                <td>
                  <div>{{ member.insuredName }}</div>
                </td>
                <td>
                  <div>{{ member.dateOfBirth | date: 'dd MMMM yyyy' }}</div>
                </td>
                <td>
                  <div>{{ member.dependent.length + 1 }}</div>
                </td>
                <td>
                  <div class="position-relative">
                    <button
                      type="button"
                      class="btn btn-link float-right dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      (click)="isShowUnlockButton(member)"
                    >
                      <i class="material-icons">more_vert</i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                      <button (click)="deleteMember(member)" [hidden]="isRoleHR" class="dropdown-item">Remove</button>
                      <button (click)="unlockMember(member.id)" [disabled]="!isShowUnlock" class="dropdown-item">
                        Unlock
                      </button>
                      <button (click)="resendMemberCredentials(member)" [hidden]="isRoleHR" class="dropdown-item">
                        Resend Credentials
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="!!dependentModuleVisibilityTracker[member.id]">
                <td colspan="6" class="px-0 pt-0 border-0 inner-row">
                  <div class="inner-row-table-wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Relationship</th>
                          <th>Date of birth</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let dependent of member.dependent">
                          <td>
                            <div>{{ dependent.insuredName }}</div>
                          </td>
                          <td>
                            <div>{{ dependent.relationship }}</div>
                          </td>
                          <td>
                            <div>{{ dependent.dateOfBirth | date: 'dd MMMM yyyy' }}</div>
                          </td>
                          <td>
                            <div class="position-relative float-right">
                              <button
                                type="button"
                                class="btn btn-link float-right dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="material-icons">more_vert</i>
                              </button>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a (click)="deleteMember(dependent)" [hidden]="isRoleHR" class="dropdown-item"
                                  >Remove</a
                                >
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>

      <ng-container *ngIf="members | searchFilter: searchText:memberPropertyName as result">
        <div class="pagination-wrapper">
          <ngb-pagination
            [collectionSize]="totalMemberCount"
            [pageSize]="pageSize"
            [(page)]="page"
            [maxSize]="5"
            [boundaryLinks]="true"
            [rotate]="true"
            (pageChange)="loadPage($event)"
            [hidden]="isLoading || members.length === 0 || isSearchingMember"
          ></ngb-pagination>

          <ngb-pagination
            [collectionSize]="totalMemberSearchCount"
            [pageSize]="pageSizeCopy"
            [(page)]="pageCopy"
            [boundaryLinks]="true"
            (pageChange)="loadSearchPage($event)"
            [maxSize]="5"
            [rotate]="true"
            [hidden]="isLoading || members.length === 0 || !isSearchingMember"
          ></ngb-pagination>
        </div>
      </ng-container>
    </div>
  </div>
</div>
