import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  saveButtonText = "Submit";
  title = "Consent Declaration";
  disableSaveButton = true;

  constructor(private modalService: NgbModal, 
              private authService: AuthService, 
              private cpMemberService: CPMemberService) { }

  ngOnInit(): void {
  }

  close(){
    console.log(this.authService.getUserId());
    this.cpMemberService.giveConsent(this.authService.getUserId()).then(res => {});
    this.modalService.dismissAll(ConsentComponent);
  }
  checkBoxClicked(){
    const checkbox = document.querySelector("#consent1") as HTMLInputElement;
    if(checkbox.checked){
      this.disableSaveButton = false;
    }else{
      this.disableSaveButton = true;
    }
  }
  signout(){
    this.authService.signout();
  }
}
