import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ErrorMsgDialog } from './ErrorMsgDialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-msg-dialog.component.html',
  styleUrls: ['./error-msg-dialog.component.scss'],
})
export class ErrorMsgDialogComponent implements OnInit {
  errorList: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ErrorMsgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorMsgDialog
  ) {
    // Update view with given values
    this.errorList = data.errorList;
  }

  ngOnInit() {}

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
}
