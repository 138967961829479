export class Roles {
  public static readonly CPHR: string = 'CPHR';
  public static readonly Broker: string = 'Broker';
  public static readonly SM: string = 'SM';
  public static readonly HR: string = 'HR';
  public static readonly BuisnessAdmin: string = 'BusinessAdmin';
  public static readonly CPMember: string = 'CPMember';
  public static readonly Member: string = 'Member';
  public static readonly Operation: string = 'Operation';
}
