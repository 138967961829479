import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Notification } from 'src/app/shared/models/notification/notification.model';
import { NotificationService } from '../../services/notification.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Roles } from 'src/app/shared/enums/roles.enum';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends BaseComponent implements OnInit {
  // notifications: NotificationModel[] = [];
  notifications: Notification[] = [];
  searchText: string;
  isLoading: boolean;
  page = 1;
  pageSize = 5;
  notificationProperty = 'notificationProp';
  constructor(
    injector: Injector,
    private readonly notificationService: NotificationService,
    private readonly alertService: AlertService
  ) {
    super(injector);
  }
  async ngOnInit() {
    super.ngOnInit();
  }

  changePage() {
    this.page = 1;
  }
}
