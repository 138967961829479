import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { BusinessAdminComponent } from './business-admin/components/business-admin/business-admin.component';
import { AuthCallbackComponent } from './shared/components/auth-callback/auth-callback.component';
import { MemberComponent } from './member/components/member/member.component';
import { CpMemberComponent } from './cp-member/components/cp-member/cp-member.component';
import { BrokerComponent } from './broker/components/broker/broker.component';
import { SmComponent } from './sm/components/sm/sm.component';
import { OperationComponent } from './operation/components/operation/operation.component';

const routes: Routes = [
  {
    path: 'member',
    loadChildren: () => import('./member/member.module').then(m => m.MemberModule),
    component: MemberComponent,
  },
  {
    path: 'operation',
    loadChildren: () => import('./operation/operation.module').then(m => m.OperationModule),
    component: OperationComponent,
  },
  {
    path: 'biz-admin',
    loadChildren: () => import('./business-admin/business-admin.module').then(m => m.BusinessAdminModule),
    component: BusinessAdminComponent,
  },
  {
    path: 'cp-member',
    loadChildren: () => import('./cp-member/cp-member.module').then(m => m.CpMemberModule),
    component: CpMemberComponent,
  },
  {
    path: 'broker',
    loadChildren: () => import('./broker/broker.module').then(m => m.BrokerModule),
    component: BrokerComponent,
  },
  {
    path: 'sm',
    loadChildren: () => import('./sm/sm.module').then(m => m.SmModule),
    component: SmComponent,
  },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: '', redirectTo: '/member', pathMatch: 'full' },
  { path: '403Forbidden', component: PageNotFoundComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'user-not-valid', component: PageNotFoundComponent },
  { path: '404', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
