import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  messageDisplay: string;
  constructor() {}

  ngOnInit() {
    const url = window.location.href;
    const urlPath = url.split('/').pop();
    if (urlPath === '403Forbidden') {
      this.messageDisplay = '403 Forbidden';
    } else if (urlPath === 'page-not-found' || urlPath === '404') {
      this.messageDisplay = '404 Page Not Found';
    } else if (urlPath === 'user-not-valid' || urlPath === '404') {
      this.messageDisplay = 'User Not Valid';
    }
  }
}
