<div class="create-enrollment-component-wrapper">
  <div class="container px-0">
    <div class="create-enrollment-box" *ngIf="isLoading" class="spinner-center-wrapper">
      <div class="spinner-center"></div>
    </div>

    <div
      class="create-enrollment-box"
      [hidden]="isLoading || (!isSIApplicable && !isPSIApplicable && !isESIApplicable && !isTopupApplicable)"
    >
      <h6>Premium Calculator</h6>
      <div class="row" id="premiumDetails1">
        <div class="col">
          <div class="form-group">
            <div class="custom-checkbox">
              <label>
                <input
                  id="premiumApplicable"
                  [disabled]="!policy"
                  [(ngModel)]="premiumDetails.isPremiumApplicable"
                  (ngModelChange)="checkPremiumsAdded()"
                  type="checkbox"
                  class="custom-control-input"
                />
                <span>Is Premium Calculator Applicable</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col" *ngIf="premiumDetails.isPremiumApplicable">
          <div class="form-group">
            <div class="custom-checkbox">
              <label>
                <input
                  [disabled]="!policy"
                  [(ngModel)]="premiumDetails.isPremiumVisible"
                  type="checkbox"
                  class="custom-control-input"
                />
                <span>Is Premium Calculator Visible</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!premiumDetails.isPremiumApplicable">
        <div class="col">
          <div class="mt-4">
            <button (click)="savePremiumDetails()" class="btn btn-primary px-4">Save</button>
          </div>
        </div>
      </div>

      <div class="row" [hidden]="!premiumDetails.isPremiumApplicable">
        <div class="col" id="premiumDetails2">
          <div class="form-group">
            <div class="custom-checkbox">
              <label>
                <input
                  [disabled]="!policy"
                  [(ngModel)]="premiumDetails.isRefundApplicable"
                  type="checkbox"
                  class="custom-control-input"
                />
                <span>Is Refund Applicable</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col" id="premiumDetails3">
          <div class="form-group">
            <div class="custom-checkbox">
              <label>Premium Type<sup>*</sup> </label>

              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="inputFlat"
                    value="Flat"
                    (click)="displayAlertText('Flat', $event)"
                    [(ngModel)]="premiumDetails.premiumType"
                  />
                  <span>Flat</span>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="inputSelective"
                    value="Selective"
                    (click)="displayAlertText('Selective', $event)"
                    [(ngModel)]="premiumDetails.premiumType"
                  />
                  <span>Selective</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="isEdit">
          <div class="col">
            <div class="mt-4">
              <button
                [disabled]="
                  premiumDetails.isPremiumApplicable === true &&
                  (premiumDetails.premiumType === undefined || premiumDetails.premiumType === null)
                "
                (click)="savePremiumDetails()"
                class="btn btn-primary px-4"
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!isEdit">
          <div class="col">
            <div class="mt-4">
              <button (click)="enableEdit()" class="btn btn-primary px-4">Edit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="create-enrollment-box"
      *ngIf="relationshipType === 'Self'"
      [hidden]="isLoading || isESIApplicable || isTopupApplicable || isSIApplicable"
    >
      <h6>Please allow SI/ESI/Topup to enable Premium calculator</h6>
    </div>

    <div
      class="create-enrollment-box"
      *ngIf="relationshipType !== 'Self'"
      [hidden]="isLoading || isPSIApplicable || isESIApplicable || isTopupApplicable"
    >
      <h6>Please allow PSI/ESI/Topup to enable Premium calculator</h6>
    </div>
  </div>
</div>
