import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  Injector,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CorporateModel } from '../../../../shared/models/corporate/corporate.model';
import { PreEnrollmentPolicyModel } from '../../../../shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { PreEnrollmentPeriodModel } from '../../../../shared/models/preEnrollment/periods/preEnrollment-period.model';
import { PreEnrollmentService } from '../../../services/preEnrollment.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { BaseComponent } from '../../../../shared/components/base.component';
import * as moment from 'moment';

@Component({
  selector: 'app-pre-enrollment-periods',
  templateUrl: './pre-enrollment-periods.component.html',
  styleUrls: ['./pre-enrollment-periods.component.scss'],
})
export class PreEnrollmentPeriodsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('closebutton', { static: true }) closebutton;
  @ViewChild('closebuttonedit', { static: true }) closebuttonedit;

  @Input() corporate: CorporateModel;
  @Input() policy: PreEnrollmentPolicyModel;
  @Output() closePeriodModal = new EventEmitter<boolean>();

  newPeriod: PreEnrollmentPeriodModel;
  periods: PreEnrollmentPeriodModel[] = [];
  today: any;
  moment: any = moment;
  editDateModel: any;
  minDate: any;

  periodStartDateInvalid: boolean;
  periodEndDateInvalid: boolean;
  addingPeriod: boolean;
  updatedPEPeriod: PreEnrollmentPeriodModel;
  errorR: any;

  constructor(
    injector: Injector,
    private preEnrollmentService: PreEnrollmentService,
    private alertService: AlertService
  ) {
    super(injector);
  }

  async ngOnChanges(): Promise<void> {
    this.periods = await this.preEnrollmentService.getPeriods(this.policy.id);
    this.preEnrollmentService.preenrollmentPeriods.next(this.periods);
    this.periods.forEach(period => this.computeStatus(period));
    this.sortPeriods();
  }

  ngOnInit() {
    super.ngOnInit();
    this.today = new Date();
    this.newPeriod = new PreEnrollmentPeriodModel();
    this.newPeriod.startDate = new Date();
    this.newPeriod.endDate = new Date();
    this.newPeriod.policyId = this.policy.id;
  }
  editPeriod(period) {
    if (period) {
      this.editDateModel = moment(period.endDate)
        .format('DD-MMM-YYYY')
        .toString();
      this.updatedPEPeriod = period;
      const minDateFormat = moment(period.startDate)
        .format('YYYY-MM-DD')
        .toString();
      this.minDate = new Date(minDateFormat);
    }
  }
  async saveUpdatedPeriod() {
    this.updatedPEPeriod.endDate = moment(this.editDateModel).format('YYYY-MM-DD');
    const period = await this.preEnrollmentService.updatePolicyPeriod(this.policy.id, this.updatedPEPeriod);
    this.alertService.success('Updated Successfully');
    this.closebuttonedit.nativeElement.click();
    this.computeStatus(period);
    const getPeriods = await this.preEnrollmentService.getPeriods(this.policy.id);
    this.preEnrollmentService.preenrollmentPeriods.next(getPeriods);
  }

  async save() {
    if (!this.isValid()) {
      return;
    }

    try {
      this.addingPeriod = true;

      const period = await this.preEnrollmentService.createPolicyPeriod(this.policy.id, this.newPeriod);
      this.periods.push(period);
      this.preEnrollmentService.preenrollmentPeriods.next(this.periods);
      this.computeStatus(period);
      this.sortPeriods();
      this.alertService.success('Added Successfully');
    } catch (error) {
      this.errorR = error.error.errors;
      this.alertService.error('Error occured');
    }
    this.newPeriod.startDate = new Date();
    this.newPeriod.endDate = new Date();

    this.addingPeriod = false;
    this.closebutton.nativeElement.click();
  }

  private isValid() {
    let startDate = moment(this.newPeriod.startDate);
    this.newPeriod.startDate = startDate.endOf('day').toDate();
    startDate = moment(this.newPeriod.startDate);

    let endDate = moment(this.newPeriod.endDate);
    this.newPeriod.endDate = endDate.endOf('day').toDate();
    endDate = moment(this.newPeriod.endDate);

    const today = moment();
    this.periodStartDateInvalid = !startDate.isValid() || today.isAfter(startDate.endOf('day'));
    this.periodEndDateInvalid = !endDate.isValid() || endDate.isBefore(startDate);

    return !this.periodStartDateInvalid && !this.periodEndDateInvalid;
  }

  computeStatus(period) {
    const today = moment()
      .startOf('day')
      .add('1', 'second');
    const startDate = moment(period.startDate);
    const endDate = moment(period.endDate);

    if (today.isAfter(endDate.endOf('day'))) {
      period['status'] = -1;
    } else if (
      today
        .endOf('day')
        .add('-1', 'second')
        .isBefore(startDate.startOf('day'))
    ) {
      period['status'] = 0;
    } else {
      period['status'] = 1;
    }
  }

  sortPeriods() {
    this.periods.sort((a, b) => {
      if (a['status'] > b['status']) {
        return 1;
      } else if (a['status'] === b['status'] && moment(a.startDate).isBefore(b.startDate)) {
        return 1;
      }

      return -1;
    });
  }

  startDateChanged() {
    this.newPeriod.endDate = this.newPeriod.startDate;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
