import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFilterPipe implements PipeTransform {
  transform(list: any[], filterText: string, propertyName: string): any {
    if (propertyName === 'name') {
      return list ? list.filter(item => item.name.search(new RegExp(filterText, 'i')) > -1) : [];
    } else if (propertyName === 'insuredName') {
      return list
        ? list.filter(
            item =>
              item.insuredName.search(new RegExp(filterText, 'i')) > -1 ||
              item.employeeId.toLowerCase().indexOf(filterText.toLowerCase()) > -1
          )
        : [];
    } else if (propertyName === 'notificationProp') {
      return list ? list.filter(item => item.subject.search(new RegExp(filterText, 'i')) > -1) : [];
    } else if (propertyName === 'documentProp') {
      return list ? list.filter(item => item.documentName.search(new RegExp(filterText, 'i')) > -1) : [];
    } else if (propertyName === 'memberProp') {
      return list ? list.filter(item => item.employeeId.search(new RegExp(filterText, 'i')) > -1) : [];
    } else if (propertyName === 'brokerProp') {
      return list
        ? list.filter(
            item =>
              item.name.search(new RegExp(filterText, 'i')) > -1 || item.code.search(new RegExp(filterText, 'i')) > -1
          )
        : [];
    } else if (propertyName === 'smProp') {
      return list
        ? list.filter(
            item =>
              item.name.search(new RegExp(filterText, 'i')) > -1 || item.code.search(new RegExp(filterText, 'i')) > -1
          )
        : [];
    } else if (propertyName === 'hrProp') {
      return list
        ? list.filter(
            item =>
              item.name.search(new RegExp(filterText, 'i')) > -1 ||
              item.hrEmpId.search(new RegExp(filterText, 'i')) > -1
          )
        : [];
    } else if (propertyName === 'endorsementProp') {
      return list
        ? list.filter(
            item =>
              item.insuredName.search(new RegExp(filterText, 'i')) > -1 ||
              item.employeeId.search(new RegExp(filterText, 'i')) > -1
          )
        : [];
    }
  }
}
