import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { MasterService } from 'src/app/business-admin/services/master.service';
import { PreEnrollmentService } from 'src/app/business-admin/services/preEnrollment.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { NoteMsgDialogModel } from 'src/app/shared/components/note-msg/note-msg-dialog-model.model';
import { NoteMsgComponent } from 'src/app/shared/components/note-msg/note-msg.component';
import { WelcomeEmailTimeGridComponent } from 'src/app/shared/components/welcome-email-time-grid/welcome-email-time-grid.component';
import { WelcomeEmailTimeModel } from 'src/app/shared/components/welcome-email-time-grid/welcome-email-time-model.model';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { WelcomeEmail } from 'src/app/shared/models/preEnrollment/email/welcome-email.model';
import { PreEnrollmentPeriodModel } from 'src/app/shared/models/preEnrollment/periods/preEnrollment-period.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-configure-welcome-email',
  templateUrl: './configure-welcome-email.component.html',
  styleUrls: ['./configure-welcome-email.component.scss'],
})
export class ConfigureWelcomeEmailComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  form: FormGroup;
  @Input() corporate: CorporateModel;
  @Input() policy: PreEnrollmentPolicyModel;
  editMode = false;

  initialized = false;

  welcomeEmailMinDate = new Date();
  configureEmails = 5;
  errorR: any;
  reminderMinsOptions: number[] = [0, 30];

  isSaveButtonEnable = true;

  dynamicArray: any;

  isWelcomeEmailValid: boolean;
  emailSubjectLength = true;
  subjectErrorMsg = '';
  displaySave: string;

  welcomeEmailTemplate: string;
  welcomeEmailData: WelcomeEmail = new WelcomeEmail(null);

  hrsOptions: number[] = [];
  minsOptions: number[] = [];
  fileName: string;
  file: File = null;
  isSizeValid: boolean;
  uploadImageInvalidMsg = '';

  dateTimeAllocation = [];

  periods: PreEnrollmentPeriodModel[] = [];

  constructor(
    injector: Injector,
    private readonly masterService: MasterService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly preenrollmentService: PreEnrollmentService,
    private fb: FormBuilder
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.createForm();
    super.ngOnInit();
    this.periods = await this.preenrollmentService.getPeriods(this.policy.id);
    this.hrsOptions = [];
    this.dateTimeAllocation = await this.preenrollmentService.getWelcomeEmailDateTimeAlloccation(this.corporate.id);
    for (let index = 0; index < 24; index++) {
      this.hrsOptions.push(index);
    }
    this.minsOptions = [0, 30];
    if (!this.policy) {
      this.displaySave = 'Save';
      const templates = await this.masterService.getEmailTemplate();
      this.welcomeEmailData.welcomeEmailTemplate = templates.welcomeEmailTemplate;
      this.setWelcomeDate();
    } else {
      this.displaySave = 'Save Changes';
      this.welcomeEmailData.sendWelcomeEmail = this.policy.sendWelcomeEmail;
      this.welcomeEmailData.welcomeEmailTemplate = this.policy.welcomeEmailTemplate;
      this.welcomeEmailData.welcomeEmailSubject = this.policy.welcomeEmailSubject;
      this.welcomeEmailData.welcomeEmailHrs = this.policy.welcomeEmailHrs;
      this.welcomeEmailData.welcomeEmailMins = this.policy.welcomeEmailMins;
      this.welcomeEmailData.welcomeEmailDate = moment(this.policy.welcomeEmailDate).toDate();
      this.welcomeEmailData.welcomeEmailFileName = this.policy.welcomeEmailFileName;
      this.fileName = this.policy.welcomeEmailFileName;
      if (this.fileName) {
        this.welcomeEmailData.welcomeEmailFile = this.policy.welcomeEmailFile;
        this.file = new File(['foo'], 'foo.txt');
      }
      this.welcomeEmailData.createUsers = this.policy.createUsers;
      if (this.policy.welcomeEmailHrs === 100 && this.policy.welcomeEmailMins === 100) {
        this.setWelcomeDate();
      } else {
        this.changeWelcomeDate();
        this.changeWelcomeHrs();
      }
      this.editMode = true;
      this.changeWelcomeDate();
      this.changeWelcomeHrs();
    }

    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.preenrollmentService.preenrollmentPeriods.subscribe((periods: any[]) => {
      this.periods = periods;
    });
    if (changes['policy'] !== undefined && changes['corporate'] === undefined) {
      this.welcomeEmailData = new WelcomeEmail(null);
      this.ngOnInit();
    }
  }

  changeWelcomeDate() {
    this.hrsOptions = [];
    for (let index = 0; index < 24; index++) {
      this.hrsOptions.push(index);
    }
    this.minsOptions = [0, 30];
    let hrs = [];
    const hrs_mins = [];
    const date = this.welcomeEmailData.welcomeEmailDate;
    const day = date.getDate().toString().length === 2 ? date.getDate() : '0' + date.getDate().toString();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let ddate;
    if (month.toString().length === 1) {
      ddate = year + '-0' + month + '-' + day + 'T00:00:00';
    } else {
      ddate = year + '-' + month + '-' + day + 'T00:00:00';
    }

    // const ddate = year + '-' + month + '-' + day + 'T00:00:00';
    this.dateTimeAllocation.forEach(dateTime => {
      if (ddate === dateTime.welcomeEmailDate) {
        hrs.push(dateTime.welcomeEmailHrs);
        hrs_mins.push(dateTime.welcomeEmailHrs.toString() + ':' + dateTime.welcomeEmailMins.toString());
      }
    });

    hrs = hrs.filter((v, i, a) => a.indexOf(v) === i);
    hrs.forEach(hr => {
      const result = hrs_mins.filter(hr_min => hr_min.split(':')[0] === hr.toString());
      if (result.length > 1) {
        const index = this.hrsOptions.indexOf(hr);
        if (index > -1) {
          this.hrsOptions.splice(index, 1);
        }
      }

      if (this.welcomeEmailData.welcomeEmailHrs === hr && ddate !== this.policy.welcomeEmailDate) {
        this.welcomeEmailData.welcomeEmailHrs = null;
        this.welcomeEmailData.welcomeEmailMins = null;
      }
    });
  }

  welcomeDateNgModelChange() {
    this.changeWelcomeDate();
    this.checkDateValidity();
  }

  setWelcomeDate() {
    this.hrsOptions = [];
    for (let index = 0; index < 24; index++) {
      this.hrsOptions.push(index);
    }
    this.minsOptions = [0, 30];
    this.welcomeEmailData.welcomeEmailDate = new Date();
    this.welcomeEmailData.welcomeEmailHrs = null;
    this.welcomeEmailData.welcomeEmailMins = null;

    let hrs = [];
    const hrs_mins = [];
    const date = this.welcomeEmailData.welcomeEmailDate;
    const day = date.getDate().toString().length === 2 ? date.getDate() : '0' + date.getDate().toString();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let ddate;
    if (month.toString().length === 1) {
      ddate = year + '-0' + month + '-' + day + 'T00:00:00';
    } else {
      ddate = year + '-' + month + '-' + day + 'T00:00:00';
    }
    this.dateTimeAllocation.forEach(dateTime => {
      if (ddate === dateTime.welcomeEmailDate) {
        hrs.push(dateTime.welcomeEmailHrs);
        hrs_mins.push(dateTime.welcomeEmailHrs.toString() + ':' + dateTime.welcomeEmailMins.toString());
      }
    });

    hrs = hrs.filter((v, i, a) => a.indexOf(v) === i);
    hrs.forEach(hr => {
      const result = hrs_mins.filter(hr_min => hr_min.split(':')[0] === hr.toString());
      if (result.length > 1) {
        const index = this.hrsOptions.indexOf(hr);

        if (index > -1) {
          this.hrsOptions.splice(index, 1);
        }
      }
    });

    this.hrsOptions.sort(function(a, b) {
      return a - b;
    });
  }

  changeWelcomeHrs() {
    const mins = [];
    this.minsOptions = [0, 30];
    const date = this.welcomeEmailData.welcomeEmailDate;
    const day = date.getDate().toString().length === 2 ? date.getDate() : '0' + date.getDate().toString();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let ddate;
    if (month.toString().length === 1) {
      ddate = year + '-0' + month + '-' + day + 'T00:00:00';
    } else {
      ddate = year + '-' + month + '-' + day + 'T00:00:00';
    }
    this.dateTimeAllocation.forEach(dateTime => {
      if (ddate === dateTime.welcomeEmailDate && dateTime.welcomeEmailHrs === this.welcomeEmailData.welcomeEmailHrs) {
        mins.push(dateTime.welcomeEmailMins);
      }
    });

    mins.forEach(min => {
      const index = this.minsOptions.indexOf(min);

      if (index > -1) {
        this.minsOptions.splice(index, 1);
      }

      if (
        this.welcomeEmailData.welcomeEmailMins === min &&
        !(
          ddate === this.policy.welcomeEmailDate &&
          this.welcomeEmailData.welcomeEmailHrs === this.policy.welcomeEmailHrs
        )
      ) {
        this.welcomeEmailData.welcomeEmailMins = null;
      }
    });
    this.hrsOptions.sort(function(a, b) {
      return a - b;
    });
    this.showCurrentDate();
  }

  showCurrentDate() {
    const date = this.welcomeEmailData.welcomeEmailDate;
    const welcomeDay = date.getDate().toString().length === 2 ? date.getDate() : '0' + date.getDate().toString();
    const welcomeMonth = date.getMonth() + 1;
    const welcomeYear = date.getFullYear();
    let ddate;
    if (welcomeMonth.toString().length === 1) {
      ddate = welcomeYear + '-0' + welcomeMonth + '-' + welcomeDay + 'T00:00:00';
    } else {
      ddate = welcomeYear + '-' + welcomeMonth + '-' + welcomeDay + 'T00:00:00';
    }
    // const ddate = welcomeYear + '-' + welcomeMonth + '-' + welcomeDay + 'T00:00:00';

    if (
      ddate === this.policy.welcomeEmailDate &&
      this.welcomeEmailData.welcomeEmailHrs === this.policy.welcomeEmailHrs
    ) {
      this.hrsOptions.push(this.policy.welcomeEmailHrs);
      this.minsOptions.push(this.policy.welcomeEmailMins);
    }

    this.hrsOptions = this.hrsOptions.filter(this.onlyUnique);
    this.minsOptions = this.minsOptions.filter(this.onlyUnique);
    this.hrsOptions.sort(function(a, b) {
      return a - b;
    });
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  createForm() {
    this.form = this.fb.group({
      welcomeMessage: ['', [Validators.pattern('^(?! )[A-Za-z0-9.,{}() ]*(?<! )$')]],
    });
  }

  isSubjectNameValid(event) {
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.emailSubjectLength = false;
      this.subjectErrorMsg = 'Subject cannot be empty!!!';
    } else if (fieldValue.length > 254) {
      this.emailSubjectLength = false;
      this.subjectErrorMsg = 'Max length of 254 exceeded';
    } else {
      this.emailSubjectLength = true;
      this.subjectErrorMsg = '';
    }
    this.checkSaveButtonDisplay();
  }

  checkTodaysDateValidity() {
    const date = new Date();
    const day = date.getDate().toString().length === 2 ? date.getDate() : '0' + date.getDate().toString();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    let ddate;
    if (month.toString().length === 1) {
      ddate = year + '-0' + month + '-' + day + 'T00:00:00';
    } else {
      ddate = year + '-' + month + '-' + day + 'T00:00:00';
    }

    const date2 = this.welcomeEmailData.welcomeEmailDate;
    const day2 = date2.getDate().toString().length === 2 ? date2.getDate() : '0' + date2.getDate().toString();
    const month2 = date2.getMonth() + 1;
    const year2 = date2.getFullYear();
    date2.setHours(this.welcomeEmailData.welcomeEmailHrs, this.welcomeEmailData.welcomeEmailMins);
    let ddate2;
    if (month2.toString().length === 1) {
      ddate2 = year2 + '-0' + month2 + '-' + day2 + 'T00:00:00';
    } else {
      ddate2 = year2 + '-' + month2 + '-' + day2 + 'T00:00:00';
    }
    if (ddate2 === ddate && hours >= this.welcomeEmailData.welcomeEmailHrs) {
      return false;
    }

    var differenceInMs = date2.getTime() - date.getTime();
    
    // To calculate the no. of minutes between two dates
    var diffInMinutes = Math.floor(differenceInMs / 60000);
  
    if (ddate2 === ddate && diffInMinutes >= 60) {
      return true;
    } else if (ddate2 === ddate && diffInMinutes < 60) {
      return false;
    }

    if (
      (this.welcomeEmailData.welcomeEmailHrs !== this.policy.welcomeEmailHrs ||
        this.welcomeEmailData.welcomeEmailMins !== this.policy.welcomeEmailMins) &&
      ddate2 === this.policy.welcomeEmailDate
    ) {
      return false;
    }

    return true;
  }

  async submit() {
    if (this.checkTodaysDateValidity()) {
      const isWelcomeDateValid = this.isValid(this.welcomeEmailData.welcomeEmailDate);
      if (isWelcomeDateValid) {
        const isDateValid = this.checkWelcomeDateValidity();
        if (isDateValid) {
          if (this.welcomeEmailData.welcomeEmailFile) {
            this.file = null;
          }
          this.welcomeEmailData.welcomeEmailDate.setMinutes(
            this.welcomeEmailData.welcomeEmailDate.getMinutes() -
              this.welcomeEmailData.welcomeEmailDate.getTimezoneOffset()
          );

          if (this.welcomeEmailData.welcomeEmailHrs === null || !this.welcomeEmailData.welcomeEmailMins === null) {
            this.welcomeEmailData.welcomeEmailHrs = 0;
            this.welcomeEmailData.welcomeEmailMins = 0;
          }
          const stringJson = JSON.stringify(this.welcomeEmailData);
          const data = new FormData();
          data.append('File', this.file);
          data.append('Json', stringJson);

          await this.preenrollmentService
            .sendWelcomeEmail(this.corporate.id, this.policy.id, data)
            .then(x => {
              this.alertService.success('Welcome Email Saved Successfully');
              this.router.navigate(['/biz-admin/corporate/' + this.corporate.id]);
            })
            .catch(errorData => {
              this.errorR = errorData.error.errors;
              this.alertService.error('Error occured');
            });
        }
      } else {
        const message = `The welcome email send date is not within preenrollment active period. Please enter date within active period.`;
        this.openAlertBox(message);
      }
    } else {
      const message = `Please select welcome send email date of future with one hour from now`;
      this.openAlertBox(message);
    }
  }

  onFileChange(file) {
    if (file.length > 0) {
      this.file = file[0];
      this.welcomeEmailData.welcomeEmailFile = null;
      const name = file[0].name;
      const size = file[0].size;
      this.fileName = name;
      this.welcomeEmailData.welcomeEmailFileName = this.fileName;
      if (this.fileName.length > 30) {
        this.fileName = this.fileName.slice(0, 25) + '...';
      }
      if (size > 5000000) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Maximum size must be 5 Mb';
      } else {
        this.isSizeValid = true;
        this.uploadImageInvalidMsg = '';
      }
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf', 'rar', 'zip'];
      if (!fileExtensionArr.includes(ext.toLowerCase())) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Upload valid format file';
      }
    }
  }

  downloadFile() {
    const link = document.createElement('a');
    link.download = this.fileName;
    if (this.welcomeEmailData.welcomeEmailFile == null) {
      link.href = URL.createObjectURL(this.file);
    } else {
      link.href = this.welcomeEmailData.welcomeEmailFile;
    }

    link.click();
  }

  deleteFile() {
    (<HTMLInputElement>document.getElementById('file')).value = '';
    this.file = null;
    this.isSizeValid = true;
    this.uploadImageInvalidMsg = '';
    this.fileName = '';
    this.welcomeEmailData.welcomeEmailFile = null;
    this.welcomeEmailData.welcomeEmailFileName = null;
  }

  checkSaveButtonDisplay() {
    const isDateValid = this.checkWelcomeDateValidity();
    if (isDateValid) {
      if (this.welcomeEmailData.sendWelcomeEmail) {
        this.isSaveButtonEnable =
          this.emailSubjectLength &&
          this.welcomeEmailData.welcomeEmailTemplate !== null &&
          this.welcomeEmailData.welcomeEmailTemplate !== undefined;
      } else {
        this.isSaveButtonEnable = true;
      }
    } else {
      this.isSaveButtonEnable = false;
    }
  }

  sendWelcomeEmail() {
    if (!this.welcomeEmailData.sendWelcomeEmail) {
      this.emailSubjectLength = true;
    } else {
      this.welcomeEmailData.createUsers = true;
      if (this.welcomeEmailData.welcomeEmailSubject.length === 0) {
        this.emailSubjectLength = false;
        this.subjectErrorMsg = 'Subject cannot be empty!!!';
      } else if (this.welcomeEmailData.welcomeEmailSubject.length > 254) {
        this.emailSubjectLength = false;
        this.subjectErrorMsg = 'Max length of 254 exceeded';
      } else {
        this.emailSubjectLength = true;
        this.subjectErrorMsg = '';
      }
    }

    this.checkSaveButtonDisplay();
  }

  checkDateTime() {
    const dialogData = new WelcomeEmailTimeModel(this.dateTimeAllocation);
    this.dialog.open(WelcomeEmailTimeGridComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  private isValid(date) {
    for (let index = 0; index < this.periods.length; index++) {
      const element = this.periods[index];
      if (element.status) {
        const startDate = moment(element.startDate)
          .endOf('day')
          .toDate();
        const endDate = moment(element.endDate)
          .endOf('day')
          .toDate();
        const valid = moment(date).isBetween(startDate, endDate, 'days', '[]');
        if (valid) {
          return true;
        }
      }
    }
    return false;
  }

  checkDateValidity() {
    const isDateValid = this.isValid(this.welcomeEmailData.welcomeEmailDate);
    if (!isDateValid) {
      const message = `The welcome email send date is not within preenrollment active period. Please enter date within active period.`;
      this.openAlertBox(message);
      if (!this.policy.welcomeEmailDate) {
        this.welcomeEmailData.welcomeEmailDate = null;
      } else {
        this.welcomeEmailData.welcomeEmailDate = moment(this.policy.welcomeEmailDate).toDate();
      }
    }
    this.checkSaveButtonDisplay();
  }

  openAlertBox(message) {
    const dialogData = new NoteMsgDialogModel('Alert', message);
    this.dialog.open(NoteMsgComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  checkWelcomeDateValidity() {
    if (!this.welcomeEmailData.welcomeEmailDate) {
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
