<div class="member-component-wrapper">
  <!-- Spinner -->
  <div *ngIf="isLoading" class="spinner-center-wrapper">
    <div class="spinner-center"></div>
  </div>
  <div class="container-fluid" *ngIf="!isLoading">
    <div class="row">
      <div class="col-xl-12" [hidden]="isPolicyStatusActive">
        <div class="card w-100">
          <div class="alert alert-warning">You can not edit enrollment as period is not active.</div>
        </div>
      </div>

      <div class="col-xl-12">
        <div class="card w-100">
          <div class="card-body pb-0">
            <div class="card-title row justify-content-between align-items-center">
              <h4 class="m-0 header-title">
                Profile
                <a
                  class="text-white edit-btn"
                  [hidden]="!canEditProfile || !isPolicyStatusActive"
                  routerLink="../edit/Profile"
                  ><span class="material-icons">
                    create
                  </span></a
                >
              </h4>
              <span> {{ policy.year }} - {{ policy.name }} <span class="logged"></span></span>
            </div>

            <!-- Shimmers -->
            <!-- <div class="two-col-shimmer-wrapper">
              <ul class="list-unstyled" [hidden]="!isLoading">
                <li><span class="shimmer"></span></li>
                <li><span class="shimmer"></span></li>
                <li><span class="shimmer"></span></li>
                <li><span class="shimmer"></span></li>
              </ul>
            </div> -->

            <ul class="inner-cards two-col-rows">
              <ng-container *ngFor="let item of selfMemberFields">
                <ng-container *ngIf="item.section == 'Profile'">
                  <li>
                    <span class="field-name mr-4"> {{ item.name }} :</span>
                    <span class="field-data">{{ item.value }}</span>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </div>

        <div>
          <div class="card w-100">
            <div class="card-body pb-0">
              <div class="card-title row justify-content-between align-items-center">
                <h4 class="m-0 header-title">
                  Contact Details
                </h4>
              </div>

              <!-- Shimmers   -->
              <!-- <div class="two-col-shimmer-wrapper">
                <ul class="list-unstyled" [hidden]="!isLoading">
                  <li><span class="shimmer"></span></li>
                  <li><span class="shimmer"></span></li>
                  <li><span class="shimmer"></span></li>
                  <li><span class="shimmer"></span></li>
                </ul>
              </div> -->

              <ul class="inner-cards two-col-rows">
                <ng-container *ngFor="let item of selfMemberFields">
                  <ng-container *ngIf="item.section == 'Contact'">
                    <li>
                      <span class="field-name mr-4"> {{ item.name }} :</span>
                      <span class="field-data">{{ item.value }}</span>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-xl-5">
        <div class="card w-100">
          <div class="card-body card-inverted-blue">
            <div class="card-title row justify-content-between align-items-center">
              <h4 class="m-0 header-title">
                Looking for general insurance products
              </h4>
            </div>

            <ul class="general-insurance-product">
              <li>
                <span class="icon">
                  <img src="assets/images/health-home.png" alt="" />
                </span>
                <span class="icon-title">
                  Health
                </span>
              </li>
              <li>
                <span class="icon">
                  <img src="assets/images/motor.png" alt="" />
                </span>
                <span class="icon-title">
                  Motor
                </span>
              </li>
              <li>
                <span class="icon">
                  <img src="assets/images/home.png" alt="" />
                </span>
                <span class="icon-title">
                  Home
                </span>
              </li>
              <li>
                <span class="icon">
                  <img src="assets/images/travel.png" alt="" />
                </span>
                <span class="icon-title">
                  Travel
                </span>
              </li>
              <li>
                <span class="icon">
                  <img src="assets/images/other.png" alt="" />
                </span>
                <span class="icon-title">
                  Others
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="card w-100">
          <div class="card-body p-0">
            <img src="assets/images/offer-img.png" width="100%" alt="" />
          </div>
        </div>
        <div class="card w-100">
          <div class="card-body bg-blue p-1">
            <div class="row">
              <div class="col-md-12">
                <ul class="comp-logos">
                  <li>
                    <img src="assets/images/rha.jpg" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/logo-2.jpg" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/rha.jpg" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/RhealthBeat.jpg" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/offer-bags.jpg" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="card w-100">
          <div class="card-body p-1">
            <div class="row">
              <div class="col-md-12">
                <img src="assets/images/twitter.jpg" width="100%" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> -->
</div>
