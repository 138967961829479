<div class="create-corporate-component-wrapper">
  <div class="container">
    <a [hidden]="false" class="link-go-back" routerLink="../list"
      ><svg fill="none" height="11" viewBox="0 0 10 11" width="10" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M5.44212 1.56699C5.68619 1.32291 5.68619 0.927191 5.44212 0.683117C5.19804 0.439042 4.80232 0.439042 4.55824 0.683117L0.1833 5.05806C-0.0607745 5.30213 -0.0607745 5.69786 0.1833 5.94193L4.55824 10.3169C4.80232 10.561 5.19804 10.561 5.44212 10.3169C5.68619 10.0728 5.68619 9.67708 5.44212 9.433L2.1341 6.12498H9.37513C9.7203 6.12498 10.0001 5.84517 10.0001 5.49999C10.0001 5.15482 9.7203 4.875 9.37513 4.875H2.1341L5.44212 1.56699Z"
          fill-rule="evenodd"
        ></path>
      </svg>
      Back to notifications
    </a>
    <div class="error-msg" *ngFor="let error of errorR">
      <p>{{ error.fieldName }} : {{ error.message }}</p>
    </div>
    <div class="create-corporate-box">
      <form [formGroup]="notificationForm" (ngSubmit)="onSubmit()">
        <div class="container">
          <h3 class="box-title">
            Create Notification
            <hr />
          </h3>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="staticEmail" class="col-form-label"
                  >Notification effective from (DD-MM-YYYY)<sup>*</sup> :
                </label>
                <input
                  value="{{ date1 | date: 'dd MMMM yyyy' }}"
                  type="text"
                  class="form-control"
                  readonly
                  bsDatepicker
                  formControlName="effectiveDateFrom"
                  (click)="StartDateSelected()"
                  [minDate]="date"
                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="staticEmail" class="col-form-label"
                  >Notification effective to (DD-MM-YYYY)<sup>*</sup> :
                </label>
                <input
                  value="{{ date2 | date: 'dd MMMM yyyy' }}"
                  type="text"
                  class="form-control"
                  readonly
                  bsDatepicker
                  formControlName="effectiveDateTo"
                  [minDate]="notificationForm.value.effectiveDateFrom"
                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="inputPassword" class="col-form-label">Notification subject<sup>*</sup> : </label>
                <input type="text" formControlName="subject" class="form-control" />
                <div class="error-msg" *ngIf="notificationForm.controls.subject.errors !== null">
                  <p
                    *ngIf="
                      notificationForm.controls.subject.errors.minlength &&
                      !notificationForm.controls.subject.errors.pattern
                    "
                  >
                    Minimum length is 10 characters
                  </p>
                  <p
                    *ngIf="
                      notificationForm.controls.subject.errors.pattern &&
                      notificationForm.controls.subject.errors.minlength
                    "
                  >
                    Subject is invalid
                  </p>
                  <p
                    *ngIf="
                      notificationForm.controls.subject.errors.pattern &&
                      !notificationForm.controls.subject.errors.minlength
                    "
                  >
                    Subject is invalid
                  </p>
                </div>
              </div>

              <div class="form-group">
                <button class="btn px-4 btn-sm mb-2 btn-primary" *ngIf="true" type="button" (click)="fileInput.click()">
                  <span>Upload file</span>
                  <input
                    #fileInput
                    id="file"
                    type="file"
                    (change)="onFileChange($event.target.files)"
                    style="display:none;"
                    accept=".xlsx,.jpg, .png, .doc, .docx,.pdf"
                  />
                </button>
                <div *ngIf="notificationForm.value.file !== ''" class="position-relative">
                  <input
                    id="fileText"
                    type="text"
                    class="form-control"
                    [(ngModel)]="fileName"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder=""
                    readonly
                  />
                  <button class="btn btn-sm remove-file-wrapper">
                    <i class="material-icons" (click)="deleteFile()">delete</i>
                  </button>
                </div>

                <p [hidden]="isSizeValid" class="error-msg mb-0">
                  <small>{{ uploadImageInvalidMsg }}</small>
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="inputPassword" class="col-form-label">Notification details<sup>*</sup> : </label>
                <textarea type="text" maxlength="256" formControlName="content" class="form-control"></textarea>
                <div class="text-remain">
                  <span id="chars">{{ 256 - notificationForm.value.content.length }}</span> characters remaining
                </div>
                <div class="error-msg" *ngIf="notificationForm.controls.content.errors !== null">
                  <p
                    *ngIf="
                      notificationForm.controls.content.errors.minlength &&
                      !notificationForm.controls.content.errors.pattern
                    "
                  >
                    Minimum length is 10 characters
                  </p>
                  <p
                    *ngIf="
                      notificationForm.controls.content.errors.pattern &&
                      !notificationForm.controls.content.errors.minlength
                    "
                  >
                    Details is invalid
                  </p>
                  <p
                    *ngIf="
                      notificationForm.controls.content.errors.pattern &&
                      notificationForm.controls.content.errors.minlength
                    "
                  >
                    Details is invalid
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <h3 class="box-title pt-3">
            Broadcast To
            <hr />
          </h3>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-0">
                <label for="staticEmail" class="col-form-label">Broadcast To<sup>*</sup> : </label>
                <div class="form-control p-0 border-0">
                  <!-- Material inline 1 -->
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        value="All Corporates"
                        formControlName="broadcast_to"
                        (click)="setAllCorporates()"
                      />
                      <span>All Corporates</span>
                    </label>
                  </div>

                  <!-- Material inline 2 -->
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        value="Specific Corporates"
                        formControlName="broadcast_to"
                        (click)="getCorporates()"
                      />
                      <span>Specific Corporates</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="notificationForm.value.broadcast_to === 'Specific Corporates'">
              <div class="form-group">
                <label for="inputPassword" class="col-form-label">Corporate Code* : </label>
                <select class="form-control" (change)="corporateChanged()" formControlName="corporateCode">
                  <option *ngFor="let corporate of corporates" [value]="corporate.code">{{ corporate.code }}</option>
                </select>
              </div>
            </div>

            <div class="col-md-6" *ngIf="notificationForm.value.broadcast_to === 'Specific Corporates'">
              <div class="form-group">
                <label for="inputPassword" class="col-form-label">Corporate Name* : </label>
                <input type="text" formControlName="corporateName" disabled class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <button type="submit" [disabled]="!notificationForm.valid" class="btn btn-primary px-4">Create</button>
        </div>
      </form>
    </div>
  </div>
</div>
