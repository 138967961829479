<app-popup-model
  [title]="title"
  [saveButton]="saveButtonText"
  [isDisableSaveButton]="disableSaveButton"
  (close)="close()"
  (save)="save($event)"
>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
  <div class="error-msg" *ngFor="let error of errorR">
    <p>{{ error.message }}</p>
  </div>
  <div class="create-corporate-box">
    <form [formGroup]="notificationForm">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="staticEmail" class="col-form-label"
              >Notification effective from (DD-MM-YYYY)<sup>*</sup> :
            </label>
            <input
              type="text"
              class="form-control"
              readonly
              bsDatepicker
              formControlName="effectiveDateFrom"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY', containerClass: 'theme-blue' }"
            />
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label for="staticEmail" class="col-form-label"
              >Notification effective to (DD-MM-YYYY)<sup>*</sup> :
            </label>
            <input
              type="text"
              class="form-control"
              readonly
              bsDatepicker
              formControlName="effectiveDateTo"
              [minDate]="notificationForm.value.effectiveDateFrom"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY', containerClass: 'theme-blue' }"
            />
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label for="inputPassword" class="col-form-label">Notification subject<sup>*</sup> : </label>
            <input type="text" formControlName="subject" class="form-control" (input)="checkFormValidity()" />
            <div class="error-msg" *ngIf="notificationForm.controls.subject.errors !== null">
              <p *ngIf="notificationForm.controls.subject.errors.minlength">Min length is 10 characters</p>
              <p
                *ngIf="
                  notificationForm.controls.subject.errors.pattern &&
                  !notificationForm.controls.subject.errors.minlength
                "
              >
                Subject is invalid
              </p>
            </div>
          </div>

          <!-- Filleee inputt -->
          <div class="form-group">
            <button
              class="btn px-4 btn-sm mb-2 btn-primary"
              *ngIf="isEdit && showUploadFile"
              type="button"
              (click)="fileInput.click()"
            >
              <span>Upload file</span>
              <input
                #fileInput
                id="file"
                type="file"
                (change)="onFileChange($event.target.files)"
                style="display:none;"
                accept=".xlsx,.jpg, .png, .doc, .docx,.pdf"
              />
            </button>
            <div *ngIf="isEdit && showUploadFile && fileName !== null">
              <input
                id="fileText"
                type="text"
                class="form-control"
                [(ngModel)]="fileName"
                [ngModelOptions]="{ standalone: true }"
                placeholder=""
                readonly
              />
            </div>
            <label *ngIf="notification.file === null && !isEdit" class="col-form-label">No file Uploaded</label>
            <div
              class="text-center"
              *ngIf="
                notificationForm.value.file !== null &&
                notificationForm.value.file !== undefined &&
                notificationForm.value.file !== '' &&
                !showUploadFile
              "
            >
              <label class="col-form-label">View File</label>
              <a href="{{ notification.file }}" target="_blank" class="text-secondary"
                ><span class="material-icons">description</span></a
              >
            </div>

            <button class="btn btn-sm delete-file-wrapper" *ngIf="notificationForm.value.file && isEdit">
              <i class="material-icons" (click)="deleteFile()">delete</i>
            </button>
            <p [hidden]="isSizeValid" class="error-msg mb-0">
              <small>{{ uploadImageInvalidMsg }}</small>
            </p>
          </div>
          <!-- Filleee inputt -->
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label">Notification details<sup>*</sup> : </label>
            <textarea
              type="text"
              formControlName="content"
              class="form-control"
              (input)="checkFormValidity()"
            ></textarea>
            <div class="error-msg" *ngIf="notificationForm.controls.content.errors !== null">
              <p *ngIf="notificationForm.controls.content.errors.minlength">Min length is 10 characters</p>
              <p
                *ngIf="
                  notificationForm.controls.content.errors.pattern &&
                  !notificationForm.controls.content.errors.minlength
                "
              >
                Details is invalid
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</app-popup-model>
