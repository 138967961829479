import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NoteMsgDialogModel } from './note-msg-dialog-model.model';

@Component({
  selector: 'app-note-msg',
  templateUrl: './note-msg.component.html',
  styleUrls: ['./note-msg.component.scss'],
})
export class NoteMsgComponent implements OnInit {
  title: string;
  message: string;
  text: string;

  constructor(
    public dialogRef: MatDialogRef<NoteMsgDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: NoteMsgDialogModel
  ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.text = data.text;
  }

  ngOnInit() {}

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
