<div class="member-endorsement-wrapper">
  <div *ngIf="!isDetailsLoaded">
    <ul class="list-unstyled shimmer-wrapper">
      <li class="shimmer shimmer-box"></li>
      <li class="shimmer shimmer-box"></li>
      <li class="shimmer shimmer-box"></li>
    </ul>
  </div>
  <ngx-slick-carousel
    class="carousel"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    *ngIf="memberDetails.length > 0"
  >
    <div ngxSlickItem class="slide" *ngFor="let member of memberDetails">
      <div class="item-box">
        <div class="carousel-item-text text-center">
          <div class="item-box-top">
            <span class="material-icons">
              <span
                [ngClass]="{
                  red: member.isRejected,
                  green: member.isApproved,
                  yellow: !member.isRejected && !member.isApproved
                }"
                >person</span
              ></span
            >
            <h4 class="item-title">Dependent Details</h4>
          </div>
          <div class="mb-1 status-wrap">
            <div class="badge badge-green" *ngIf="member.isApproved">
              {{ member.status }}
            </div>
            <div class="badge badge-red" *ngIf="member.isRejected">
              {{ member.status }}
            </div>
            <div class="badge badge-orange" *ngIf="!member.isRejected && !member.isApproved">
              {{ member.status }}
            </div>
            <div class="remark" *ngIf="member.remark !== '' && member.remark !== null">{{ member.remark }}</div>
          </div>
          <div class="item-box-details">
            <ul class="inner-cards">
              <li>
                <span class="field-name">Policy No:</span>
                <span class="field-data">{{ member.policyNo }}</span>
              </li>
              <li>
                <span class="field-name">Insured Name:</span>
                <span class="field-data">{{ member.insuredName }}</span>
              </li>
              <li>
                <span class="field-name">Gender:</span>
                <span class="field-data">{{ member.gender }}</span>
              </li>
              <li>
                <span class="field-name">Employee Id:</span>
                <span class="field-data">{{ member.employeeId }}</span>
              </li>
              <li>
                <span class="field-name">Date Of Birth:</span>
                <span class="field-data">{{ member.dob | date: 'dd MMMM yyyy' }}</span>
              </li>
              <li>
                <span class="field-name">Mobile No:</span>
                <span class="field-data">{{ member.mobileNo }}</span>
              </li>
              <li>
                <span class="field-name">Email:</span>
                <span class="field-data">{{ member.email }}</span>
              </li>
              <li>
                <span class="field-name">Relationship:</span>
                <span class="field-data">{{ member.relationship }}</span>
              </li>
              <li>
                <span class="field-name">Sum Insured:</span>
                <span class="field-data">{{ member.sumInsured }}</span>
              </li>
              <li>
                <span class="field-name">UHID:</span>
                <span class="field-data">{{ member.uhid }}</span>
              </li>
              <li>
                <span class="field-name">Endorsement Type:</span>
                <span class="field-data">{{ member.endorsementType }}</span>
              </li>
            </ul>
          </div>
          <div class="item-box-bottom pb-3">
            <button
              class="btn btn-sm btn-primary mr-2 px-4"
              [disabled]="
                member.approveRejectFlowModel != null &&
                (member.approveRejectFlowModel.hr.isApproved || member.isRejected)
              "
              (click)="approveorreject(member)"
            >
              Approve/Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
  <div *ngIf="isDetailsLoaded && memberDetails.length === 0" class="no-result-wrapper">
    <h5>No Members Added!</h5>
  </div>
</div>
