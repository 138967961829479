import { PreEnrollmentAdditionSumOptionModel } from './PreEnrollmentAdditionSumOptionModel';
import { PreEnrollmentPolicyRelationshipModel } from './PreEnrollmentPolicyRelationshipModel';
import { PreEnrollmentPolicyMemberFieldModel } from './PreEnrollmentPolicyMemberFieldModel';
import { SumInsuredModel } from './SumInsuredModel';
import { AllowedSumInsuredModel } from './allowedSumInsuredModel';
export class PreEnrollmentPolicyModel {
  id: number;
  corporateId: number;

  name: string;
  year: number;
  startDate: any = new Date();
  endDate: any = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  plan: string;
  sumsInsured: SumInsuredModel[] = [];
  allowedSumInsured: AllowedSumInsuredModel;
  allowedSumInsuredType: string;
  totalMembers: number;
  allowHRResetEmployeeDetails = false;
  allowEmployeeViewProfile = true;
  allowEmployeeEditProfile = false;
  allowDeletingMember = true;
  memberFields: PreEnrollmentPolicyMemberFieldModel[];
  totalMembersInString: string;
  period;

  passwordPolicy = 'AutoGenerated';

  relationships: PreEnrollmentPolicyRelationshipModel[];
  maxNumOfMembersPerFamily: number;
  maxNumOfChildrenAllowed: number;
  parentInLawCondition = false;
  areTwinsAllowed = false;
  IsPremiumCalculatorAllowed = false;

  parentalSumInsured: PreEnrollmentAdditionSumOptionModel = new PreEnrollmentAdditionSumOptionModel();
  enhancedSumInsured: PreEnrollmentAdditionSumOptionModel = new PreEnrollmentAdditionSumOptionModel();
  topupOption: PreEnrollmentAdditionSumOptionModel = new PreEnrollmentAdditionSumOptionModel();

  sendWelcomeEmail = false;
  welcomeEmailDate: any = new Date();
  welcomeEmailHrs = 100;
  welcomeEmailMins = 100;
  welcomeEmailTemplate = '';
  welcomeEmailSubject = '';
  welcomeEmailFile: any;
  createUsers: boolean;
  welcomeEmailFileName: string;

  sendRegistrationConfirmationEmail = false;
  registrationConfirmationEmailCcs = '';
  registrationConfirmationEmailBccs = '';
  registrationConfirmationEmailTemplate = '';
  registrationConfirmationEmailSubject = '';
  confirmationEmailFile: any;
  confirmationEmailFileName: string;

  sendReminderEmail = false;
  reminderEmailDate: any = new Date();
  reminderEmailHrs = 10;
  reminderEmailMin: number;
  reminderEmailSubject = '';
  reminderEmailTemplate = '';
  reminderEmailDateTime = '';
  reminderEmailFile: any;
  reminderEmailFileName: string;

  gstFileUpload = null;
  gstPercentage: number;
  isGSTExempted = false;

  constructor(policy) {
    this.endDate.setDate(this.endDate.getDate() - 1);
    Object.assign(this, policy);
    const futureDate = new Date();
    futureDate.setMonth(futureDate.getMonth() + 1);
    // this.welcomeEmailDate = futureDate;
  }
}
