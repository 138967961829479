import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-privileges',
  templateUrl: './privileges.component.html',
  styleUrls: ['./privileges.component.scss'],
})
export class PrivilegesComponent implements OnInit {
  @Input() user: any;
  isReportCheckboxValid = true;
  isDashboardCheckboxValid = true;
  @Output() isFormValid = new EventEmitter<boolean>();
  @Output() saveButtonText = new EventEmitter<string>();
  @Output() currentStep = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  checkDashboardValue() {
    if (this.user.dashboard === true) {
      if (this.user.staticDashboard === true || this.user.dyanamicDashboard === true) {
        this.isDashboardCheckboxValid = true;
      } else {
        this.isDashboardCheckboxValid = false;
      }
    } else if (this.user.dashboard === false) {
      this.user.staticDashboard = false;
      this.user.dyanamicDashboard = false;
      this.isDashboardCheckboxValid = true;
    }
  }

  checkReportValue() {
    if (this.user.reportAllowed === true) {
      if (this.user.reimbersementReport === true || this.user.cashlessReport === true) {
        this.isReportCheckboxValid = true;
      } else {
        this.isReportCheckboxValid = false;
      }
    } else if (this.user.reportAllowed === false) {
      this.user.reimbersementReport = false;
      this.user.cashlessReport = false;
      this.isReportCheckboxValid = true;
    }
  }

  back() {
    this.currentStep.emit('firstStep');
    this.saveButtonText.emit('Next');
    this.isFormValid.emit(true);
  }

  checkCheckboxesValidity() {
    this.checkDashboardValue();
    this.checkReportValue();
    this.isFormValid.emit(this.isReportCheckboxValid === true && this.isDashboardCheckboxValid === true ? true : false);
  }
}
