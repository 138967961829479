export class FamilyClaimsDetails {
  policyNo: string;
  claimNo: string;
  claimType: string;
  admissionDate: string;
  insuredName: string;
  hospitalName: string;
  policyName: string;
  claimStatus: string;
  inwardNumber: string;
}
