<div class="list-component-wrapper">
  <div class="list-component-header">
    <div class="row">
      <div class="container">
        <!-- <h3 class="box-title">Members</h3> -->
        <!-- <input type="text" placeholder="Search member" class="form-control search-box" [(ngModel)]="searchText" /> -->
        <input
          type="text"
          placeholder="Search member"
          class="form-control search-box"
          [(ngModel)]="searchText"
          (input)="searchingMember($event)"
        />
        <!-- This button should only be visible to Admin and not broker or SM. -->
        <div class="btn-wrap">
          <button
            class="btn btn-sm btn-secondary"
            (click)="turnOnSync()"
            [disabled]="
              policy.isSyncOn || !policy.isActive || !(policy.userNameType !== null && policy.passwordType !== null)
            "
          >
            <span class="material-icons">sync</span>
            <span>{{ syncText }}</span>
          </button>
          <button
            class="btn btn-sm btn-primary ml-2"
            (click)="resetPasswordByPolicyId()"
            [disabled]="totalMemberCount < 1 || !(policy.userNameType !== null && policy.passwordType !== null)"
          >
            <span class="material-icons">refresh</span>
            <span>Reset and Resend Password</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- There should also be a search box to search employees by emp id or name. -->

  <div [hidden]="members.length !== 0 || isLoading === true" class="no-result-wrapper">
    <h5>No Member found!</h5>
  </div>

  <div class="row">
    <div class="container">
      <div class="list-component-body">
        <table>
          <thead [hidden]="members.length === 0">
            <tr>
              <th>Emp Id</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th>Last Logged In</th>
              <th>Email Sent On</th>
              <th>SMS Sent On</th>
              <th><div class="float-right">Actions</div></th>
            </tr>
          </thead>
          <tbody>
            <tr [hidden]="!isLoading">
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer primary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper float-right">
                  <div class="shimmer tertiary"></div>
                </div>
              </td>
            </tr>
            <tr [hidden]="!isLoading">
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer primary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper">
                  <div class="shimmer secondary"></div>
                </div>
              </td>
              <td>
                <div class="shimmer-wrapper float-right">
                  <div class="shimmer tertiary"></div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="members">
            <tr *ngFor="let member of members" [hidden]="isLoading || members.length === 0">
              <td>{{ member.employeeId }}</td>
              <td>{{ member.insuredName }}</td>
              <td>{{ member.email }}</td>
              <td>{{ member.mobileNo }}</td>
              <td><app-date-shared [date]="member.lastLoggedInAt"> </app-date-shared></td>
              <td><app-date-shared [date]="member.loginEmailSentAt"> </app-date-shared></td>
              <td><app-date-shared [date]="member.loginSMSSentAt"> </app-date-shared></td>
              <td>
                <div class="position-relative">
                  <button
                    type="button"
                    class="btn btn-link float-right dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="material-icons">more_vert</i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <button
                      class="dropdown-item"
                      [disabled]="!member.isLoginDetailsCreated"
                      (click)="resetPasswordByMemberId(member)"
                    >
                      Reset and Resend Password
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8" class="border-0">
                <ng-container *ngIf="members | searchFilter: searchText:memberProperty as result">
                  <div class="pagination-wrapper">
                    <ngb-pagination
                      [collectionSize]="totalMemberCount"
                      [pageSize]="pageSize"
                      [(page)]="page"
                      [maxSize]="5"
                      [boundaryLinks]="true"
                      [rotate]="true"
                      (pageChange)="loadPage($event)"
                      [hidden]="isLoading || members.length === 0 || isSearchingMember"
                    ></ngb-pagination>

                    <ngb-pagination
                      [collectionSize]="totalMemberSearchCount"
                      [pageSize]="pageSizeCopy"
                      [(page)]="pageCopy"
                      [boundaryLinks]="true"
                      (pageChange)="loadSearchPage($event)"
                      [maxSize]="5"
                      [rotate]="true"
                      [hidden]="isLoading || members.length === 0 || !isSearchingMember"
                    >
                    </ngb-pagination>
                  </div>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
