import { Component, OnInit, Input, Injector, SimpleChanges, OnChanges } from '@angular/core';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreateDocumentComponent } from './create-document/create-document.component';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { error } from 'protractor';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ErrorMsgDialog } from 'src/app/shared/components/error-msg-dialog/ErrorMsgDialog';
import { ErrorMsgDialogComponent } from 'src/app/shared/components/error-msg-dialog/error-msg-dialog.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() corporate: CorporateModel;
  @Input() policyNumber: any;
  policy: any;
  searchText: string;
  isLoading: boolean;
  page = 1;
  pageSize = 5;
  documents: any = [];
  documentProperty = 'documentProp';
  errorMsg: string[] = [];
  modalOption: NgbModalOptions = {};

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly corporatePortalService: CorporatePortalService,
    private readonly alertService: AlertService,
    private modalService: NgbModal
  ) {
    super(injector);
  }

  loadPage(event) {
    this.page = 1;
  }

  open() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(CreateDocumentComponent, this.modalOption);
    modalRef.componentInstance.corporate = this.corporate;
    modalRef.componentInstance.policy = this.policy;
    modalRef.componentInstance.docAddedSucessfully.subscribe(isDocAdded => {
      if (isDocAdded === true) {
        this.isLoading = true;
        this.getDocs();
        this.isLoading = false;
      }
    });
  }

  async ngOnInit() {
    if (this.policyNumber !== 'Attach Policy') {
      this.isLoading = true;
      await this.getDocs();
      this.isLoading = false;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.corporatePortalService.updatedPolicies.subscribe((policyList: any[]) => {
      this.policy = policyList.find(policy => policy.policyNumber === this.policyNumber);
    });
    if (changes['policyNumber'] !== undefined && changes['corporate'] === undefined) {
      if (this.policyNumber !== 'Attach Policy') {
        this.isLoading = true;
        this.searchText = '';
        this.page = 1;
        this.documents = [];
        await this.getDocs();
        this.isLoading = false;
      }
    }
  }
  async getDocs() {
    const documentList = await this.corporatePortalService.getDocument(this.policy.id);
    if (documentList) {
      this.documents = documentList;
    }
  }

  async removeDoc(documentId) {
    let result;
    const message = `Are you sure you want to delete?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.isLoading = true;
          await this.corporatePortalService
            .deleteDocument(documentId)
            .then(x => {
              this.getDocs();
              this.alertService.success('Document deleted successfully');
            })
            .catch(errorData => {
              const errorList = errorData.error.errors;
              this.openErrorModal(errorList);
            });
          this.isLoading = false;
        } catch (error) {
          this.alertService.error('Error occured');
          this.isLoading = false;
        }
      }
    });
  }

  openErrorModal(errorList) {
    this.errorMsg = [];
    errorList.forEach(errorDescription => {
      this.errorMsg.push(errorDescription.message);
    });

    const dialogData = new ErrorMsgDialog(this.errorMsg);

    this.dialog.open(ErrorMsgDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
  }
}
