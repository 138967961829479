import { Component, OnInit, Input, Injector, SimpleChanges, OnChanges } from '@angular/core';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Endorsement } from 'src/app/corporate-portal/models/endorsement/endorsement.model';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ApproveRejectComponent } from 'src/app/corporate-portal/components/approve-reject/approve-reject.component';
import { EndorsementService } from 'src/app/corporate-portal/services/endorsement.service';

@Component({
  selector: 'app-endorsement-by-member',
  templateUrl: './endorsement-by-member.component.html',
  styleUrls: ['./endorsement-by-member.component.scss'],
})
export class EndorsementByMemberComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() policy: Policy;
  memberDetails: Endorsement[] = [];
  isDetailsLoaded = false;

  error: any;
  isHrView = false;
  modalOption: NgbModalOptions = {};

  constructor(
    injector: Injector,
    private modalService: NgbModal,
    private policyService: CPPolicyService,
    private cpmemberService: CPMemberService,
    private endorsementService: EndorsementService
  ) {
    super(injector);
  }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  async ngOnInit() {
    this.isDetailsLoaded = false;
    this.cpmemberService.isHrView.subscribe(v => {
      this.isHrView = v;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['policy'] !== undefined) {
      if (this.policy.hasOwnProperty('policyId')) {
        this.getEndorsementCreatedByMember();
      }
    }
  }

  getEndorsementCreatedByMember() {
    this.memberDetails = [];
    this.isDetailsLoaded = false;
    this.endorsementService
      .getEndorsementCreatedByMemberForHr(this.policy.policyId)
      .then(memberDetail => {
        this.memberDetails = memberDetail;
        this.isDetailsLoaded = true;
      })
      .catch(x => {
        console.log(x);
      });
  }

  approveorreject(endorsement) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(ApproveRejectComponent, this.modalOption);
    modalRef.componentInstance.endorsement = endorsement;
    modalRef.componentInstance.type = 'endorsement';
    modalRef.componentInstance.approveRejectSuccefully.subscribe(result => {
      if (result === true) {
        this.getEndorsementCreatedByMember();
      }
    });
  }
}
