import { Component, OnInit, Injector } from '@angular/core';
import { PreEnrollmentMemberPolicyModel } from '../../model/preEnrollment-member-policy.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { FieldConfig } from 'src/app/dyanamic-fields-form/model/form-fields';
import { PreEnrollmentMemberService } from '../../services/preEnrollment-member.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MemberValidationHelper } from '../../helpers/member-validation-helper';
import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { ErrorMsgDialogComponent } from 'src/app/shared/components/error-msg-dialog/error-msg-dialog.component';
import { ErrorMsgDialog } from 'src/app/shared/components/error-msg-dialog/ErrorMsgDialog';
import { MemberModel } from '../../model/member.model';
@Component({
  selector: 'app-edit-member-family',
  templateUrl: './edit-member-family.component.html',
  styleUrls: ['./edit-member-family.component.scss'],
})
export class EditMemberFamilyComponent extends BaseComponent implements OnInit {
  selfPolicyModel: PreEnrollmentMemberPolicyModel;
  dependentPolicyModel: PreEnrollmentMemberPolicyModel;
  policy: PreEnrollmentPolicyModel;
  dependentFields: FieldConfig[];
  dependentName: string;
  pageTitle: string;
  addError: any;
  isLoading = true;
  isfinalSubmissionLocked = false;
  isPolicyStatusActive = false;
  errorMsg: string[] = [];
  relationshipIdInPath: number;
  dependentMember: PreEnrollmentMemberPolicyModel = new PreEnrollmentMemberPolicyModel();
  dependentMembersList: MemberModel[] = [];
  updatedDependent: MemberModel;
  submitButtonClicked = false;

  constructor(
    injector: Injector,
    private readonly preEnrollmentService: PreEnrollmentMemberService,
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly memberValidationHelper: MemberValidationHelper,
    private readonly router: Router
  ) {
    super(injector);
  }
  errorR: any;
  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.policy = this.preEnrollmentService.policy;
    if (this.policy === null) {
      this.policy = await this.preEnrollmentService.getPolicy();
    }
    this.isPolicyStatusActive = this.policy.period.status;
    this.subscriptionsToDestroy.push(
      this.route.params.subscribe(async params => {
        if (params['dependentId'] === 'new') {
          this.pageTitle = 'Add';
          const relationshipId = parseInt(params['relationshipId'], 10);
          this.relationshipIdInPath = relationshipId;
          const sumInsured = parseInt(params['sumInsured'], 10);
          this.preEnrollmentService.getMemberFamilyEmptyModel(this.policy.id, relationshipId, sumInsured).then(x => {
            this.dependentPolicyModel = x;
            this.pageTitle = 'Add';
            this.dependentFields = this.dependentPolicyModel.members[0].fields;
            this.getSelf();
            this.dependentFields.forEach(element => {
              if (element.name === 'Sum Insured') {
                const numberFormatPipe = new NumberFormatPipe();
                element.value = numberFormatPipe.transform(element.value);
              }
            });
            if (this.policy.corporateId === 4472 || this.policy.corporateId === 4473) {
              this.checkPSIValue();
            }

            this.isLoading = false;
          });
        } else {
          const dependentId = parseInt(params['dependentId'], 10);
          this.pageTitle = 'Edit';
          const dependentsPolicyModel = this.preEnrollmentService.dependentsPolicyModel
            ? Object.assign({}, this.preEnrollmentService.dependentsPolicyModel)
            : null;
          // const dependentsPolicyModel = JSON.parse(JSON.stringify(this.preEnrollmentService.dependentsPolicyModel));
          if (dependentsPolicyModel !== null) {
            this.dependentPolicyModel = dependentsPolicyModel;
            this.dependentPolicyModel.members = dependentsPolicyModel.members.filter(x => x.id === dependentId);
            this.relationshipIdInPath = this.dependentPolicyModel.members[0].relationshipId;
            this.isLoading = false;
            this.getDependentFields();
            // this.getSelf();
            if (!this.dependentPolicyModel) {
              this.router.navigate(['/404']);
            }
            return;
          } else {
            await this.preEnrollmentService.getMemberFamilyById(dependentId).then(x => {
              this.dependentPolicyModel = x;
              this.dependentFields = this.dependentPolicyModel.members[0].fields;
              // this.getSelf();
              this.isLoading = false;
              if (!this.dependentPolicyModel) {
                this.router.navigate(['/404']);
              }
            });
          }
        }
      })
    );
  }
  getSelf() {
    this.selfPolicyModel = this.preEnrollmentService.selfPolicyModel;
    if (this.selfPolicyModel === null) {
      this.preEnrollmentService.getMember().then(x => {
        this.selfPolicyModel = x;
      });
    }
  }
  hideShow() {
    this.isfinalSubmissionLocked = this.memberValidationHelper.isFinalSubmissionLocked(this.selfPolicyModel);
  }
  getDependentFields() {
    this.dependentFields = this.dependentPolicyModel.members[0].fields;
    this.dependentFields.forEach(element => {
      if (element.name === 'Insured Name') {
        this.dependentName = element.value;
      }
    });
  }
  async submit(value: any) {
    this.submitButtonClicked = true;
    const data = value.getRawValue();
    if ('ESI' in data) {
      data.ESI = data.ESI.toString().replace(/,/g, '');
    }
    if ('PSI' in data) {
      data.PSI = data.PSI.toString().replace(/,/g, '');
    }
    if ('Topup' in data) {
      data.Topup = data.Topup.toString().replace(/,/g, '');
    }
    if ('Sum Insured' in data) {
      data['Sum Insured'] = data['Sum Insured'].toString().replace(/,/g, '');
    }
    if (!this.dependentPolicyModel.members[0].id) {
      if (this.policy.corporateId === 4472 || this.policy.corporateId === 4473) {
        const psi = this.dependentPolicyModel.members[0].fields.find(x => x.label === 'Parental Sum Insured');
        if (psi != null) {
          psi.value;
        }
      }
      this.preEnrollmentService
        .addNewFamilyMember(this.policy.id, data)
        .then(x => {
          this.alertService.success('Member added successfully.');
          this.router.navigate(['member/enrollment']);
        })
        .catch(errorData => {
          this.addError = errorData.error.errorResponses;
          const errorList = errorData.error[0].errors;
          this.openErrorModal(errorList);
        });
    } else {
      this.updatePSIValue();
      if (this.updatedDependent !== null) {
        const data1 = {
          'Date Of Birth': this.updatedDependent.fields.find(x => x.label === 'Date Of Birth').value,
          'Insured Name': this.updatedDependent.fields.find(x => x.label === 'Insured Name').value,
          Gender: this.updatedDependent.fields.find(x => x.label === 'Gender').value,
          Grade: this.updatedDependent.fields.find(x => x.label === 'Grade').value,
          Relationship: this.updatedDependent.fields.find(x => x.label === 'Relationship').value,
          'Employee Id': this.updatedDependent.fields.find(x => x.label === 'Employee Id').value,
          PSI: data.PSI,
          'Email Id': this.updatedDependent.fields.find(x => x.label === 'Email Id').value,
        };

        await this.preEnrollmentService
          .updateMemberFamily(this.updatedDependent.id, data1)
          .then(x => {
            this.alertService.success('Member updated successfully.');
          })
          .catch(errorData => {
            this.addError = errorData.error.errorResponses;
            const errorList = errorData.error[0].errors;
            this.openErrorModal(errorList);
          });
      }

      this.preEnrollmentService
        .updateMemberFamily(this.dependentPolicyModel.members[0].id, data)
        .then(x => {
          this.alertService.success('Member updated successfully.');
          this.router.navigate(['member/enrollment']);
        })
        .catch(errorData => {
          this.addError = errorData.error.errorResponses;
          const errorList = errorData.error[0].errors;
          this.openErrorModal(errorList);
        });
    }
  }
  openErrorModal(errorList) {
    this.errorMsg = [];
    errorList.forEach(errorDescription => {
      this.errorMsg.push(errorDescription.message);
    });
    const dialogData = new ErrorMsgDialog(this.errorMsg);
    this.dialog.open(ErrorMsgDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
  }
  async getDependents() {
    this.dependentMember = this.preEnrollmentService.dependentsPolicyModel;
    if (this.dependentMember === null) {
      await this.preEnrollmentService.getMemberFamily(this.policy.id).then(family => {
        this.dependentMember = family;
      });
    }
    if (this.dependentMember == null) {
      this.dependentMembersList = [];
      return;
    }
    // this.isMaxCountReached = this.dependentMember.members.length + 1 === this.policy.maxNumOfMembersPerFamily;
    this.dependentMembersList = this.dependentMember.members;
    for (let i = 0; i < this.dependentMember.members.length; i++) {
      this.dependentMember.members[i].fields.forEach(element => {
        if (element.name === 'Insured Name') {
          this.dependentMember.members[i].insuredName = element.value;
        }
        if (element.name === 'Sum Insured') {
          const numberFormatPipe = new NumberFormatPipe();
          element.value = numberFormatPipe.transform(element.value);
        }
        if (element.type === 'extrabenefits') {
          const numberFormatPipe = new NumberFormatPipe();
          element.value = numberFormatPipe.transform(element.value);
        }
      });
    }
  }
  checkPSIValue() {
    this.getDependents();
    console.log(this.dependentMembersList);
    if (this.policy.corporateId === 4472 || this.policy.corporateId === 4473) {
      this.dependentMembersList.forEach(dependent => {
        if (
          (this.relationshipIdInPath === 5 && dependent.relationshipId === 6) ||
          (this.relationshipIdInPath === 6 && dependent.relationshipId === 5) ||
          (this.relationshipIdInPath === 7 && dependent.relationshipId === 8) ||
          (this.relationshipIdInPath === 8 && dependent.relationshipId === 7)
        ) {
          this.dependentFields.forEach(element => {
            if (element.name === 'PSI') {
              element.value = dependent.psi;
            }
          });
        }
      });
    }
  }
  updatePSIValue() {
    this.updatedDependent = null;
    this.getDependents();

    console.log(this.dependentMembersList);
    if (this.policy.corporateId === 4472 || this.policy.corporateId === 4473) {
      this.dependentMembersList.forEach(dependent => {
        if (
          (this.relationshipIdInPath === 5 && dependent.relationshipId === 6) ||
          (this.relationshipIdInPath === 6 && dependent.relationshipId === 5) ||
          (this.relationshipIdInPath === 7 && dependent.relationshipId === 8) ||
          (this.relationshipIdInPath === 8 && dependent.relationshipId === 7)
        ) {
          dependent.psi = this.dependentPolicyModel.members[0].psi;
          this.updatedDependent = dependent;
        }
      });
    }
  }
}
