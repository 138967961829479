<div class="members-component-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12">
        <div class="card w-100">
          <div class="card-body">
            <div class="card-title row justify-content-between align-items-center">
              <h4 class="m-0 header-title">Members</h4>

              <div class="policy-dropdown">
                <label class="pb-0">Select policy</label>
                <select class="form-control" [(ngModel)]="selectedPolicy">
                  <option *ngFor="let policy of policies" [ngValue]="policy">
                    {{ policy.name }}
                  </option>
                </select>

                <div hidden class="shimmer primary lighter"></div>
              </div>
            </div>

            <div class="w-100">
              <app-pre-enrollment-members [corporate]="corporate" [policy]="selectedPolicy">
              </app-pre-enrollment-members>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
