import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FieldConfig } from '../../model/form-fields';
import * as moment from 'moment';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';

export const DD_MM_YYYY_Format = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date',
  template: `
    <mat-form-field class="demo-full-width margin-top" [formGroup]="group">
      <input
        matInput
        [matDatepicker]="picker"
        [value]="field.value"
        [required]="field.isMandatory"
        readonly
        [min]="minDate"
        [max]="maxDate"
        [formControlName]="field.name"
        [placeholder]="field.label"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="multi-year" [startAt]="year"></mat-datepicker>
      <mat-hint></mat-hint>
      <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
        <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{ validation.message }}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styles: [],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: DD_MM_YYYY_Format,
    },
  ],
})
export class DateComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  minDate: Date;
  maxDate: Date;
  year: any;
  constructor() {}
  ngOnInit() {
    let minAge: number;
    let maxAge: number;
    const currentYear = new Date().getFullYear();

    this.field.validationsCustom.forEach(validation => {
      const arr: string[] = validation.split(' ');
      minAge = parseInt(arr[0], 10);
      maxAge = parseInt(arr[1], 10);

      this.minDate = new Date();
      this.minDate.setFullYear(currentYear - maxAge);

      this.maxDate = new Date();
      this.maxDate.setFullYear(currentYear - minAge);
    });

    if (this.field.name === 'Date Of Joining') {
      this.maxDate = new Date();
    }
    if (this.field.value === '') {
      this.year = this.minDate;
    } else {
      this.year = moment(this.field.value).format('DD MMM YYYY');
    }
  }
}
