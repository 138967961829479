<div class="long-scroll-modal {{ extraClass }}">
  <div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <div class="spinner-border text-primary mr-3" *ngIf="showLoader"></div>
    <button type="button" class="btn btn-primary px-3" [disabled]="isDisableSaveButton" (click)="savePopup()">
      {{ saveButton }}
    </button>
  </div>
</div>
