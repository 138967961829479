import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelfMember } from 'src/app/cp-member/models/self-member.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EncruptioDecryptionService } from 'src/app/shared/services/encryption-decryption.service';
import { CPPolicyService } from '../../services/cp-policy.service';
import { CorporatePortalBaseComponent } from '../corporate-portal-base/corporate-portal-base.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent extends CorporatePortalBaseComponent implements OnInit {
  form: FormGroup;
  errorList = [];
  isLoading = false;

  constructor(
    policyService: CPPolicyService,
    authService: AuthService,
    private encryptDecrypt: EncruptioDecryptionService,
    private cpMemberService: CPMemberService,
    private fb: FormBuilder,
    injector: Injector,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private modalService: NgbModal
  ) {
    super(injector, policyService, authService);
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[a-zA-Z._]+([a-zA-Z. ]*)s*$')]],
      message: ['', [Validators.required, Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')]],
      emailBeforeEncrypt: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i),
        ],
      ],
      email: [],
      number: ['', [Validators.required, Validators.minLength(10), Validators.pattern(/^([6-9][0-9]{9})$/)]],
    });
  }

  emailSubmit() {
    const email = this.encryptDecrypt.encrypt(
      this.form.value.emailBeforeEncrypt,
      `b14ca5898a4e4133bbce2ea2315a1916KPngPNGyVN`
    );
    this.form.controls.email.setValue(email);
    this.form.get('emailBeforeEncrypt').disable();
    this.isLoading = true;

    this.cpMemberService
      .emailContactUs(this.form.value)
      .then(x => {
        this.alertService.success('Email submitted successfully');
        this.isLoading = false;
        this.form.get('emailBeforeEncrypt').enable();
        this.form.reset();
      })
      .catch(x => {
        this.alertService.error('Error occured');
        this.isLoading = false;
        this.form.get('emailBeforeEncrypt').enable();
      });
  }
}
