<div class="corporate-portal-component-wrapper">
  <div class="corporate-portal-component-header" *ngIf="initialized && selectedPolicy && !addNewPolicyRequest">
    <div class="container position-relative">
      <nav>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="Corporate-portal-policy-details-tab"
              data-toggle="tab"
              href="#Corporate-portal-policy-details"
              role="tab"
              aria-controls="Corporate-portal-policy-details"
              aria-selected="true"
            >
              Policy Details
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Credentials-tab"
              data-toggle="tab"
              href="#Credentials"
              role="tab"
              aria-controls="Credentials"
              aria-selected="false"
            >
              Credentials
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="HR-tab"
              data-toggle="tab"
              href="#HR"
              role="tab"
              aria-controls="HR"
              aria-selected="false"
            >
              HR
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Broker-tab"
              data-toggle="tab"
              href="#Broker"
              role="tab"
              aria-controls="Broker"
              aria-selected="false"
            >
              Broker
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="SM-tab"
              data-toggle="tab"
              href="#SM"
              role="tab"
              aria-controls="SM"
              aria-selected="false"
            >
              SM
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Features-tab"
              data-toggle="tab"
              href="#Features"
              role="tab"
              aria-controls="Features"
              aria-selected="false"
            >
              Features
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Members-tab"
              data-toggle="tab"
              href="#Members"
              role="tab"
              aria-controls="Members"
              aria-selected="false"
            >
              Members
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Documents-tab"
              data-toggle="tab"
              href="#Documents"
              role="tab"
              aria-controls="Documents"
              aria-selected="false"
            >
              Static Dashboard
            </a>
          </li>
        </ul>
      </nav>
      <div class="policy-dropdown">
        <button
          class="text-left dropdown-toggle"
          *ngIf="policies.length > 0"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <span *ngIf="selectedPolicy == undefined">Select Policy</span>
          <span *ngIf="selectedPolicy != undefined && selectedPolicyDetails.isActive" class="logged"></span>
          <span *ngIf="selectedPolicy != undefined && !selectedPolicyDetails.isActive" class="not-logged"></span>
          <span *ngIf="selectedPolicy != undefined" class="policy-number">{{ selectedPolicy }}</span>
        </button>
        <div class="dropdown-menu">
          <div *ngFor="let policy of policies">
            <a class="dropdown-item" (click)="onPolicyDropdownChanged(policy)">
              <span *ngIf="policy.isActive" class="tag tag-active"></span>
              <span *ngIf="!policy.isActive" class="tag tag-inactive"></span>
              <span>{{ policy.policyNumber }}</span>
            </a>
          </div>
          <div>
            <a class="dropdown-item" (click)="onPolicyDropdownChanged('Attach Policy')">
              <span class="text-primary">Attach Policy</span>
            </a>
          </div>
        </div>
      </div>

      <!-- <div class="policy-dropdown">
        <div class="input-group">
          <select class="form-control" [(ngModel)]="selectedPolicy" (ngModelChange)="onPolicyChanged($event)">
            <option *ngFor="let policy of policies" [ngValue]="policy.policyNumber">
              {{ policy.policyNumber }}
            </option>
            <option style="color:blue;">Attach Policy</option>
          </select>
        </div>
      </div> -->
    </div>
  </div>

  <div class="corporate-portal-component-body" *ngIf="initialized && selectedPolicy && !addNewPolicyRequest">
    <div class="container">
      <div class="tab-content">
        <div class="tab-pane active" id="Corporate-portal-policy-details" role="tabpanel">
          <div class="form-box">
            <app-corporate-portal-policy-details
              [corporate]="corporate"
              [policyNumber]="selectedPolicy"
            ></app-corporate-portal-policy-details>
          </div>
        </div>
        <div class="tab-pane" id="Credentials" role="tabpanel">
          <div class="form-box">
            <app-credentials [corporate]="corporate" [policyNumber]="selectedPolicy"></app-credentials>
          </div>
        </div>
        <div class="tab-pane" id="HR" role="tabpanel">
          <div class="form-box">
            <app-list-hr [corporate]="corporate" [policyNumber]="selectedPolicy"></app-list-hr>
          </div>
        </div>
        <div class="tab-pane" id="Broker" role="tabpanel">
          <div class="form-box">
            <app-list-broker [corporate]="corporate" [policyNumber]="selectedPolicy"></app-list-broker>
          </div>
        </div>
        <div class="tab-pane" id="SM" role="tabpanel">
          <div class="form-box">
            <app-list-sm [corporate]="corporate" [policyNumber]="selectedPolicy"></app-list-sm>
          </div>
        </div>
        <div class="tab-pane" id="Features" role="tabpanel">
          <div class="form-box">
            <app-features [corporate]="corporate" [policyNumber]="selectedPolicy"></app-features>
          </div>
        </div>
        <div class="tab-pane" id="Members" role="tabpanel">
          <div class="form-box">
            <app-members [corporate]="corporate" [policyNumber]="selectedPolicy"></app-members>
          </div>
        </div>
        <div class="tab-pane" id="Documents" role="tabpanel">
          <div class="form-box">
            <app-documents [corporate]="corporate" [policyNumber]="selectedPolicy"></app-documents>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <a class="link-go-back" [hidden]="!addNewPolicyRequest" (click)="cancel()">
      <svg fill="none" height="11" viewBox="0 0 10 11" width="10" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M5.44212 1.56699C5.68619 1.32291 5.68619 0.927191 5.44212 0.683117C5.19804 0.439042 4.80232 0.439042 4.55824 0.683117L0.1833 5.05806C-0.0607745 5.30213 -0.0607745 5.69786 0.1833 5.94193L4.55824 10.3169C4.80232 10.561 5.19804 10.561 5.44212 10.3169C5.68619 10.0728 5.68619 9.67708 5.44212 9.433L2.1341 6.12498H9.37513C9.7203 6.12498 10.0001 5.84517 10.0001 5.49999C10.0001 5.15482 9.7203 4.875 9.37513 4.875H2.1341L5.44212 1.56699Z"
          fill-rule="evenodd"
        ></path>
      </svg>
      Cancel
    </a>
  </div>

  <app-corporate-portal-attach-policy [corporate]="corporate" *ngIf="initialized && (!policies || addNewPolicyRequest)">
  </app-corporate-portal-attach-policy>
</div>
