<app-popup-model
  [title]="title"
  (close)="signout()"
  [saveButton]="saveButtonText"
  (save)="close()"
  [isDisableSaveButton]="disableSaveButton"
  [removeCloseButton]="false"
>
<div>
    <div class="consent-container">  
      <p>&#8203;&#8203;&#8203;&#8203;Dear Customer,</p>
  
      <p>Purpose and Scope of the Privacy Policy</p>
  
      <p>Protecting your privacy and treating the personal information of all users of our website in accordance with the law is very important to us. We understand that by using our website you may be entrusting us with personal information and assure you that we take our duty to protect and safeguard this information very seriously.</p>
  
      <p>This Privacy Policy which is framed in accordance with the requirements of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal data or information) Rules, 2011 (&quot;Information Security Rules 2011&quot;) includes information about;</p>
  
      <p>1. What personal information we collect</p>
  
      <p>2. Purpose for which we use personal information</p>
  
      <p>3. Sharing of Personal Information</p>
  
      <p>1. What personal information we collect We collect, use and process various types of personal information about customer accounts to the extent appropriate for your relationship with us. The personal information that we collect about you may include but is not limited to name, address, email address, telephone and mobile number, date of birth, bank details, monthly income, vehicle number, personal identifiers such as passport number, permanent account number, driving license, other personally identifiable information and sensitive personal data or information as defined in the Information Security Rules, 2011.</p>
  
      <p>The collection of personal information about you is from a variety of sources, including through:</p>
  
      <p>Online and electronic interactions including via website of our company / group companies, mobile applications, text messaging programs or applications on third party social networks</p>
  
      <p>Offline interactions, including via direct marketing campaigns, hard copy registration / application forms, competition entries and contacts through Company's call centres; and</p>
  
      <p>Your interaction with online targeted content (such as advertisements) or service providers on our behalf, provided to you via third party websites or applications.</p>
  
      <p>2. Purpose for which we use personal information  We use and process customer's personal information for a range of purposes, which may include but is not limited to:</p>
  
      <p>To provide with products and services; To fulfil requests To deliver administrative notices or alert relevant to use of service To facilitate transactions; To check the identity and suitability of clients for certain products and services; To ensure the security and operation of our Information Technology environment; To use it for marketing and advertising measures (e.g. newsletters via e-mail, online advertising); To detect, prevent and investigate fraud; To comply with applicable laws, rules and regulations; To comply with the legal process to respond to requests from public, regulatory or government authorities; To perform internal business processes such as data analysis, audits, developing and improving new products and services; To third party services entity that provide services to the Company and are bound by these same privacy restrictions; As part of sale, merger or similar change of the business or its parent company, subsidiaries, affiliates and related group companies;</p>
  
      <p>We process all your personal information in accordance with the applicable laws on data protection and for as long as required.&#8203;&#8203; 3. Disclosure of Personal Information Personal information may be shared for any of the purposes described in the document.</p>
  
      <p>We may share personal information with our parent company, subsidiaries, affiliates and related group companies. Our parent company, subsidiaries, affiliates and related group companies are committed to protecting personal information and to use it only for legitimate business purposes.</p>
  
      <p>We may also share personal information with government agencies mandated under the law to obtain personal information including sensitive personal data or information for the purposes of verification of identity or for prevention, detection, investigation including cyber incidents, prosecution and punishment of offences.</p>
  
      <p>Consent We shall obtain your consent in writing (and includes electronic consent) before collecting personal information for providing Services. Personal information for purposes of this Policy includes information that identifies you, such as your name, date of birth, bank account details or credit card or debit card, phone number, fax number or email address etc., and include gender, marital status, city of residence. All Personal information so provided will be on an 'as on' basis and Company shall not be responsible for the authenticity of the personal information supplied by you.</p>
  
      <p>Services shall include all provisions of services and facilities and connected activities which are provided in connection with offering of products and services as a customer of the Company</p>
      <div class="consent-checkbox">
        <input type="checkbox" id="consent1" name="consent1" (change)="checkBoxClicked()">
        <label for="consent1">I acknowledge that I have read and understood the terms and conditions.</label>
      </div>
    </div>
  </div>
</app-popup-model>