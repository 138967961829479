export class PolicyMemberFeatures {
  policyId: string;
  viewPolicyCoverage: boolean;
  additionOfDependents: boolean;
  trackClaims: boolean;
  viewECard: boolean;
  networkHospitalTracker: boolean;
  downloadForms: boolean;
  intimateClaims: boolean;
  intimatePACIClaims: boolean; 
  contactUs: boolean;
  faq: boolean;
  wellness: boolean;
  rHealthAssist: boolean;
  rHealthCircle: boolean;
  rHealthBeat: boolean;
  healthOPedia: boolean;
  hra: boolean;
  valueDeals: boolean;
  attendance:boolean;
  gpa:boolean;
  mobileClaim:boolean;
  nomineeEnroll: boolean;
  policyNote: boolean;
}
