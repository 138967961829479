import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PreEnrollmentMemberService } from '../../services/preEnrollment-member.service';

@Component({
  selector: 'app-member-header',
  templateUrl: './member-header.component.html',
  styleUrls: ['./member-header.component.scss'],
})
export class MemberHeaderComponent implements OnInit, OnDestroy {
  memberCorporate: any;
  isAuthenticated: boolean;
  subscription: Subscription;
  userName: string;
  constructor(
    private authService: AuthService,
    private readonly preEnrollmentMemberService: PreEnrollmentMemberService
  ) {}

  async ngOnInit() {
    this.subscription = this.authService.isLoggedIn.subscribe(status => (this.isAuthenticated = status));
    this.subscription = this.authService.userName.subscribe(name => (this.userName = name));
    this.memberCorporate = await this.preEnrollmentMemberService.getMemberCorporate();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
