import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { CorporateService } from '../../services/corporate.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporateSummaryModel } from '../../../shared/models/corporate/CorporateSummaryModel';
import * as moment from 'moment';

@Component({
  selector: 'app-list-corporate',
  templateUrl: './list-corporate.component.html',
  styleUrls: ['./list-corporate.component.scss'],
})
export class ListCorporateComponent extends BaseComponent implements OnInit, OnDestroy {
  moment: any = moment;
  corporates: CorporateSummaryModel[] = [];
  corporatesCopy: CorporateSummaryModel[] = [];
  searchText: string;
  corporatePropertyName = 'name';
  isLoading: boolean;
  skip = 0;
  take = 10;
  totalCorporateCount: number;
  isSearchingCorporate = false;
  totalCorporateSearchCount: number;
  page = 1;
  pageCopy = 1;
  pageSize = 10;
  pageSizeCopy = 10;
  isSearchCorporateFound = true;
  constructor(injector: Injector, private readonly corporateService: CorporateService) {
    super(injector);
  }

  async ngOnInit() {
    super.ngOnInit();
    this.isLoading = true;
    const count = await this.corporateService.get_total_corporates();
    this.totalCorporateCount = JSON.parse(JSON.stringify(count))['totalCount'];
    this.getPaginatedCorporates(0, 10);
    // this.subscriptionsToDestroy.push(
    //   this.corporateService.existingCorporates$.subscribe(corps => {
    //     if (!corps) {
    //       return;
    //     }

    //     this.corporates = corps;
    //     console.log(this.corporates);
    //     this.isLoading = false;
    //   })
    // );
  }

  async searchingCorporate(event) {
    const searchValue = event.target.value.trim();
    if (searchValue === '' || searchValue === null || searchValue === undefined) {
      this.isLoading = true;
      this.isSearchingCorporate = false;
      this.isSearchCorporateFound = true;
      this.getPaginatedCorporates(0, this.take);
      this.page = 1;
      this.isLoading = false;
    } else {
      this.isLoading = true;
      this.isSearchingCorporate = true;
      const corpCode = event.target.value.trim();
      this.corporatesCopy = await this.corporateService.search_corporate(corpCode);
      this.isSearchCorporateFound = this.corporatesCopy.length === 0 ? false : true;
      this.totalCorporateSearchCount = this.corporatesCopy.length;
      const size = 10;
      this.corporates = [];
      this.corporatesCopy.slice(0, size).map(i => {
        this.corporates.push(i);
      });
      this.pageCopy = 1;
      this.isLoading = false;
    }
  }

  loadSearchPage(event) {
    const pageNum = event;
    const size = 10;
    this.corporates = [];
    this.corporatesCopy.slice((pageNum - 1) * size, pageNum * size).map(i => {
      this.corporates.push(i);
    });
  }

  async loadPage(event) {
    const pageNum = event;
    this.skip = (pageNum - 1) * this.take;
    this.getPaginatedCorporates(this.skip, this.take);
  }

  async getPaginatedCorporates(skip, take) {
    this.isLoading = true;
    const corps = await this.corporateService.get_corporates(skip, take);
    if (!corps) {
      return;
    }
    this.corporates = corps;
    console.log(this.corporates);
    this.isLoading = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
