<div class="create-enrollment-component-wrapper" *ngIf="initialized">
  <div class="container">
    <div class="error-msg" *ngFor="let error of errorR">
      <p>{{ error.message }}</p>
    </div>
    <div class="create-enrollment-box">
      <div class="enrollment-box-row">
        <div class="box-body">
          <h6>WELCOME EMAIL</h6>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [(ngModel)]="welcomeEmailData.sendWelcomeEmail"
                      (ngModelChange)="sendWelcomeEmail()"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Send welcome email to the employees</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" [hidden]="!welcomeEmailData.sendWelcomeEmail">
            <div class="col-md-12">
              <div style="margin-bottom: 10px;">
                <button (click)="checkDateTime()" class="btn btn-secondary">Check Allocated Time</button>
              </div>

              <div class="form-group date-time-parent">
                <div class="date-time-wrapper">
                  <label>Welcome Email Send Date<sup>*</sup></label>
                  <input
                    value="{{ welcomeEmailData.welcomeEmailDate | date: 'dd MMMM yyyy' }}"
                    [(ngModel)]="welcomeEmailData.welcomeEmailDate"
                    (ngModelChange)="welcomeDateNgModelChange()"
                    type="text"
                    [minDate]="welcomeEmailMinDate"
                    class="form-control"
                    readonly
                    bsDatepicker
                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                  />
                </div>
                <div class="timepicker-wrapper">
                  <label>Hours</label>
                  <select
                    [(ngModel)]="welcomeEmailData.welcomeEmailHrs"
                    (change)="changeWelcomeHrs()"
                    type="number"
                    class="form-control"
                  >
                    <option *ngFor="let hr of hrsOptions" [ngValue]="hr">{{ hr }}</option>
                  </select>
                </div>
                <div class="timepicker-wrapper">
                  <label>Minutes</label>
                  <select
                    [(ngModel)]="welcomeEmailData.welcomeEmailMins"
                    (change)="changeWelcomeMins()"
                    type="number"
                    class="form-control"
                  >
                    <option *ngFor="let min of minsOptions" [ngValue]="min">{{ min }}</option>
                  </select>
                </div>
              </div>

              <!-- <p [hidden]="isEmailDateValid" class="errorMsg">Enter valid date and time</p> -->
            </div>
            <div class="col-md-6"></div>
          </div>

          <div class="row" [hidden]="!welcomeEmailData.sendWelcomeEmail">
            <div class="col-md-12">
              <form [formGroup]="form">
                <div class="form-group">
                  <label>Welcome Email Subject <sup>*</sup> </label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="welcomeEmailData.welcomeEmailSubject"
                    formControlName="welcomeMessage"
                    (input)="isSubjectNameValid($event)"
                  />
                  <span
                    class="error-msg small"
                    *ngIf="
                      form.get('welcomeMessage').dirty &&
                      form.get('welcomeMessage').status === 'INVALID' &&
                      emailSubjectLength
                    "
                    >Invalid Subject
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                  <span class="error-msg small" *ngIf="!emailSubjectLength">
                    {{ subjectErrorMsg }}
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                </div>
              </form>
            </div>

            <div class="col">
              <div class="form-group">
                <label>Welcome Email Template <sup>*</sup></label>
                <editor
                  apiKey="65447p1khods6dtyql8kuu3wf9xhgw1hbekv2nj8xibdxfob"
                  initialValue="{{ welcomeEmailData.welcomeEmailTemplate }}"
                  [(ngModel)]="welcomeEmailData.welcomeEmailTemplate"
                  [init]="{
                    height: 340,
                    menubar: false,
                    plugins: ['table'],
                    toolbar:
                      'undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | forecolor backcolor  permanentpen  removeformat '
                  }"
                >
                </editor>
              </div>
            </div>
          </div>

          <div class="row pl-3" [hidden]="!welcomeEmailData.sendWelcomeEmail">
            <div class="form-group">
              <button class="btn px-4 btn-sm mb-2 btn-primary" *ngIf="true" type="button" (click)="fileInput.click()">
                <span>Upload file</span>
                <input
                  #fileInput
                  id="file"
                  type="file"
                  (change)="onFileChange($event.target.files)"
                  style="display:none;"
                  accept=".xlsx,.jpg, .png, .doc, .docx,.pdf"
                />
              </button>
              <div *ngIf="fileName !== '' && fileName !== null && fileName !== undefined" class="position-relative">
                <label>File Name/Link</label>
                <input
                  id="fileText"
                  type="text"
                  class="form-control"
                  [(ngModel)]="fileName"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder=""
                  readonly
                />
                <button
                  class="btn btn-sm remove-file-wrapper"
                  [hidden]="file === '' || file === null || file === undefined"
                >
                  <i class="material-icons" (click)="deleteFile()">delete</i>
                </button>
                <button
                  type="submit"
                  (click)="downloadFile()"
                  class="btn btn-sm import-btn"
                  [hidden]="file === '' || file === null || file === undefined"
                >
                  <i class="material-icons">cloud_download</i>
                  <span>Download</span>
                </button>
              </div>

              <p [hidden]="isSizeValid" class="error-msg mb-0">
                <small>{{ uploadImageInvalidMsg }}</small>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [(ngModel)]="welcomeEmailData.createUsers"
                      type="checkbox"
                      class="custom-control-input"
                      [disabled]="welcomeEmailData.sendWelcomeEmail"
                    />
                    <span>Create Users</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row pl-3">
            <button (click)="submit()" [disabled]="!isSaveButtonEnable" class="btn btn-secondary">
              {{ displaySave }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
