import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { ok } from 'assert';
import { PreEnrollmentMemberPolicyModel } from '../model/preEnrollment-member-policy.model';
import { FieldConfig, Validator } from 'src/app/dyanamic-fields-form/model/form-fields';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { PreEnrollmentMemberModel } from 'src/app/shared/models/preEnrollment/members/preEnrollment-member.model';

@Injectable({
  providedIn: 'root',
})
export class PreEnrollmentMemberService {
  memberUpdate: Subject<boolean>;
  closedSideBarEventUpdate: Subject<object>;
  editProfileFields: FieldConfig[] = null;
  policy: PreEnrollmentPolicyModel = null;
  self: PreEnrollmentMemberModel = null;
  selfPolicyModel: PreEnrollmentMemberPolicyModel = null;
  dependentsPolicyModel: PreEnrollmentMemberPolicyModel = null;

  constructor(private readonly backendService: BackendService) {
    this.memberUpdate = new Subject<boolean>();
    this.closedSideBarEventUpdate = new Subject<object>();
  }

  url1 = 'member/my';

  editProfileValue(fields) {
    this.editProfileFields = fields.filter(field => field.section === 'Profile' || field.section === 'Contact');
  }

  async getMemberProfile(): Promise<any> {
    return new Promise(async resolve => {
      const preEnrollmentMember = await this.backendService.get(this.url1 + '/profile');
      this.editProfileFields = preEnrollmentMember;
      resolve(preEnrollmentMember);
    });
  }

  async updateMemberProfile(policyId, data): Promise<any> {
    const d = data['Date Of Birth'];
    data['Date Of Birth'] = moment(d).add(6, 'hours');
    return new Promise<any>((resolve, reject) => {
      this.backendService.put(this.url1 + '/profile/' + policyId, data).then(
        response => {
          this.self = null;
          this.selfPolicyModel = null;
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async getMemberCorporate(): Promise<any> {
    return new Promise(async resolve => {
      const preEnrollmentMemberCorporate = await this.backendService.get(this.url1 + '/corporate');
      resolve(preEnrollmentMemberCorporate);
    });
  }
  async updateMember(policyId, data): Promise<any> {
    const d = data['Date Of Birth'];
    data['Date Of Birth'] = moment(d).format('YYYY-MM-DD') + 'T00:30:00.000Z';
    // d = moment(d).add(6, 'hours');
    // data['Date Of Birth'] = moment(d).add(30, 'minutes');
    return new Promise<any>((resolve, reject) => {
      this.backendService.put(this.url1 + '/' + policyId, data).then(
        response => {
          this.self = null;
          this.selfPolicyModel = null;
          return resolve(ok);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async updateMemberFamily(dependentId, data): Promise<any> {
    const d = data['Date Of Birth'];
    data['Date Of Birth'] = moment(d).format('YYYY-MM-DD') + 'T00:30:00.000Z';
    return new Promise<any>((resolve, reject) => {
      this.backendService.put(this.url1 + '/family/' + dependentId, data).then(
        response => {
          this.dependentsPolicyModel = null;
          return resolve(ok);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async addNewFamilyMember(policyId, data): Promise<any> {
    const d = data['Date Of Birth'];
    data['Date Of Birth'] = moment(d).add(6, 'hours');
    return new Promise<any>((resolve, reject) => {
      this.backendService.post(this.url1 + '/family/' + policyId + '/add', data).then(
        response => {
          this.dependentsPolicyModel = null;
          return resolve(ok);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async getMember(): Promise<PreEnrollmentMemberPolicyModel> {
    return new Promise(async resolve => {
      const preEnrollmentMember = await this.backendService.get(this.url1);
      this.self = preEnrollmentMember.members[0];
      this.selfPolicyModel = preEnrollmentMember;
      resolve(preEnrollmentMember);
    });
  }

  async deleteMember(policyId, memberId): Promise<any> {
    return new Promise(async resolve => {
      this.dependentsPolicyModel = null;
      const result = await this.backendService.delete(`preenrollment/${policyId}/preenrollmentmember/${memberId}`);
      this.dependentsPolicyModel = null;
      resolve(result);
    });
  }

  async getPolicy(): Promise<PreEnrollmentPolicyModel> {
    return new Promise(async resolve => {
      const preEnrollmentMember = await this.backendService.get(this.url1 + '/policy');
      this.policy = preEnrollmentMember;
      resolve(preEnrollmentMember);
    });
  }

  async getMemberFamily(policyId): Promise<PreEnrollmentMemberPolicyModel> {
    return new Promise(async resolve => {
      const dependents = await this.backendService.get(this.url1 + '/' + policyId + '/family');
      this.dependentsPolicyModel = dependents;
      resolve(dependents);
    });
  }

  async getRelationships(policyId): Promise<any> {
    return new Promise(async resolve => {
      const relationships = await this.backendService.get(this.url1 + '/family/' + policyId + '/relationships ');
      resolve(relationships);
    });
  }

  async getMemberFamilyById(dependentId): Promise<PreEnrollmentMemberPolicyModel> {
    return new Promise(async resolve => {
      const preEnrollmentMember = await this.backendService.get(this.url1 + '/family/' + dependentId);
      resolve(preEnrollmentMember);
    });
  }

  async getMemberFamilyEmptyModel(policyId, relationshipId, sumInsured): Promise<PreEnrollmentMemberPolicyModel> {
    return new Promise(async resolve => {
      const preEnrollmentMember = await this.backendService.get(
        this.url1 + '/family/' + policyId + '/add/' + relationshipId + '/' + sumInsured
      );
      resolve(preEnrollmentMember);
    });
  }

  async setFinalSubmission(policyId): Promise<PreEnrollmentMemberPolicyModel> {
    return new Promise<any>(async (resolve, reject) => {
      await this.backendService.put(this.url1 + '/' + policyId + '/finalsubmission', '').then(
        response => {
          this.selfPolicyModel = null;
          this.self = null;
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
  async getMemberRole(): Promise<any> {
    return new Promise(async resolve => {
      const role = await this.backendService.get(`user/role/${this.backendService.userId}`);
      resolve(role);
    });
  }

  public async getPremiumDetails(policyId: number, relationship): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get('getPremium/policy/' + policyId + '/' + relationship).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
}
