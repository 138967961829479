<div class="member-endorsement-wrapper">
  <ngx-slick-carousel
    class="carousel"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    *ngIf="memberDetails.length > 0"
  >
    <div ngxSlickItem class="slide" *ngFor="let member of memberDetails">
      <div class="item-box">
        <div class="carousel-item-text text-center">
          <div class="item-box-top">
            <span class="material-icons">
              <span
                [ngClass]="{
                  red: member.isRejected,
                  green: member.isApproved,
                  yellow: !member.isRejected && !member.isApproved
                }"
                >person</span
              >
            </span>
            <h4 class="item-title">Member Details</h4>
          </div>
          <div class="mb-1 status-wrap">
            <div class="badge badge-green" *ngIf="member.isApproved">
              {{ member.status }}
            </div>
            <div class="badge badge-red" *ngIf="member.isRejected">
              {{ member.status }}
            </div>
            <div class="badge badge-orange" *ngIf="!member.isRejected && !member.isApproved">
              {{ member.status }}
            </div>
            <div class="remark" *ngIf="member.remark !== '' && member.remark !== null">{{ member.remark }}</div>
          </div>
          <div class="item-box-details">
            <ul class="inner-cards">
              <li>
                <span class="field-name">Policy No:</span>
                <span class="field-data">{{ member.policyNo }}</span>
              </li>
              <li>
                <span class="field-name">Insured Name:</span>
                <span class="field-data">{{ member.insuredName }}</span>
              </li>
              <li>
                <span class="field-name">Gender:</span>
                <span class="field-data">{{ member.gender }}</span>
              </li>
              <li>
                <span class="field-name">Mobile No:</span>
                <span class="field-data">{{ member.mobileNo }}</span>
              </li>
              <li>
                <span class="field-name">Employee Id:</span>
                <span class="field-data">{{ member.employeeId }}</span>
              </li>
              <li>
                <span class="field-name">Date Of Birth:</span>
                <span class="field-data">{{ member.dob | date: 'dd MMMM yyyy' }}</span>
              </li>
              <li>
                <span class="field-name">Policy No:</span>
                <span class="field-data">{{ member.policyNo }}</span>
              </li>
              <li>
                <span class="field-name">Location:</span>
                <span class="field-data">{{ member.location }}</span>
              </li>
              <li>
                <span class="field-name">Designation:</span>
                <span class="field-data">{{ member.designation }}</span>
              </li>
              <li>
                <span class="field-name">Email:</span>
                <span class="field-data">{{ member.email }}</span>
              </li>
              <li>
                <span class="field-name">Relationship:</span>
                <span class="field-data">{{ member.relationship }}</span>
              </li>
              <li>
                <span class="field-name">Sum Insured:</span>
                <span class="field-data">{{ member.sumInsured }}</span>
              </li>
              <li>
                <span class="field-name">UHID:</span>
                <span class="field-data">{{ member.uhid }}</span>
              </li>
              <li>
                <span class="field-name">Endorsement Type:</span>
                <span class="field-data">{{ member.endorsementType }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
  <div *ngIf="isDetailsLoaded && memberDetails.length === 0" class="no-result-wrapper">
    <h5>No Members Added!</h5>
  </div>

  <div *ngIf="!isDetailsLoaded">
    <ul class="list-unstyled shimmer-wrapper">
      <li class="shimmer shimmer-box"></li>
      <li class="shimmer shimmer-box"></li>
      <li class="shimmer shimmer-box"></li>
    </ul>
  </div>

  <!-- <div *ngIf="file !== undefined">{{ file.name }}</div> -->
  <div class="btn-wrap text-center mt-4">
    <div class="errors-table-wrap" *ngIf="error.length > 0">
      <table>
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Error Message</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let row of error">
            <tr *ngFor="let errorField of row.Errors">
              <td class="error-msg">{{ errorField.FieldName }}</td>
              <td class="error-msg">{{ errorField.Message }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-center" *ngIf="policy.isActive">
      <button class="btn px-4 btn-sm mr-2 btn-primary" (click)="getSampleMemberExcelFile()">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          *ngIf="!isMemberEndorsementSampleLoaded"
        ></span>
        Sample Download
      </button>
      <div class="updload-with-file">
        <div *ngIf="file !== undefined && file !== null">
          <span class="file-name">{{ file.name }}</span>
        </div>

        <button class="btn btn-sm p-0 mr-2" *ngIf="file !== undefined && file !== null" (click)="deleteFile()">
          <span class="material-icons">delete</span>
        </button>
        <button class="btn px-4 btn-sm mr-2 btn-primary" type="button" (click)="fileInput.click()">
          <span>Upload</span>
          <input
            #fileInput
            type="file"
            (click)="fileInput.value = null"
            (change)="uploadFile($event)"
            style="display:none;"
            accept=".xlsx"
          />
        </button>
      </div>
      <button
        class="btn px-4 btn-sm btn-primary"
        [disabled]="file === undefined || file === null"
        (click)="submitFile()"
      >
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="!isFileUploaded"></span
        >Submit Details
      </button>
    </div>
  </div>
</div>
