<div class="create-corporate-component-wrapper">
  <div class="container">
    <a [hidden]="editMode" class="link-go-back" routerLink="../list"
      ><svg fill="none" height="11" viewBox="0 0 10 11" width="10" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M5.44212 1.56699C5.68619 1.32291 5.68619 0.927191 5.44212 0.683117C5.19804 0.439042 4.80232 0.439042 4.55824 0.683117L0.1833 5.05806C-0.0607745 5.30213 -0.0607745 5.69786 0.1833 5.94193L4.55824 10.3169C4.80232 10.561 5.19804 10.561 5.44212 10.3169C5.68619 10.0728 5.68619 9.67708 5.44212 9.433L2.1341 6.12498H9.37513C9.7203 6.12498 10.0001 5.84517 10.0001 5.49999C10.0001 5.15482 9.7203 4.875 9.37513 4.875H2.1341L5.44212 1.56699Z"
          fill-rule="evenodd"
        ></path>
      </svg>
      Back to all corporates
    </a>

    <div class="create-corporate-box">
      <div class="form-box">
        <div class="error-msg" *ngFor="let error of errorR">
          <p>{{ error.fieldName }} : {{ error.message }}</p>
        </div>
        <h3 class="box-title" [hidden]="editMode">Create new corporate</h3>
        <!-- <h3 class="box-title" [hidden]="!editMode">Edit corporate details</h3> -->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Name <sup>*</sup></label>
              <p [hidden]="!isRequiredNameNull || firstTimeEnterName" id="fieldInvalidError">
                {{ this.invalidNameMsg }}
              </p>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="workingCorporate.name"
                (input)="isNameNull($event)"
                [disabled]="!isEdit"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Logo</label>
              <div class="form-control p-0 border-0">
                <div class="upload-logo-wrapper">
                  <figure>
                    <img
                      src="{{
                        workingCorporate.logoImage ? workingCorporate.logoImage : 'assets/images/default_user.png'
                      }}"
                      id="img"
                      alt=""
                    />
                  </figure>
                  <div class="add-logo-btn" [hidden]="editMode && !isEdit">
                    <label for="file" [hidden]="editMode && !isEdit"><i class="material-icons">add_circle</i></label>
                    <input
                      type="file"
                      id="file"
                      accept="image/*"
                      [hidden]="editMode && !isEdit"
                      (change)="uploadFiles($event.target.files)"
                      style="display: none;"
                      [disabled]="!isEdit"
                    />
                  </div>
                  <p [hidden]="isValidFormat" class="error-msg mb-0">
                    <small>{{ uploadImageInvalidMsg }}</small>
                  </p>
                  <div class="file-path-wrapper">
                    <input
                      class="file-path-validate"
                      id="fileText"
                      type="text"
                      [hidden]="editMode && !isEdit"
                      [disabled]="!isEdit"
                      [(ngModel)]="fileName"
                      [ngModelOptions]="{ standalone: true }"
                      placeholder=""
                      readonly
                    />
                  </div>
                  <div class="delete-file-wrapper">
                    <i
                      class="material-icons"
                      [hidden]="!workingCorporate.logoImage || !isEdit"
                      (click)="removeSelectedFile()"
                      >delete</i
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Code <sup>*</sup></label>
              <p [hidden]="!isRequiredCodeNull || firstTimeEnterCode" id="fieldInvalidError">
                {{ this.invalidCodeMsg }}
              </p>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="workingCorporate.code"
                maxlength="10"
                (input)="isCodeNull($event)"
                [disabled]="editMode"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Website</label>
              <p [hidden]="isWebsiteUrlValid || firstTimeEnterWebsiteURL" id="fieldInvalidError">
                Please enter valid url!!!
              </p>
              <input
                type="url"
                class="form-control"
                [(ngModel)]="workingCorporate.website"
                (input)="validateWebsiteURL($event)"
                [disabled]="!isEdit"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Pin Code <sup>*</sup></label>
              <p [hidden]="isPincodeValid || firstTimeEnterPinCode" id="fieldInvalidError">
                {{ invalidPinCodeMsg }}
              </p>
              <input
                [(ngModel)]="workingCorporate.pinCode"
                type="number"
                class="form-control"
                minlength="6"
                maxlength="6"
                (keypress)="numberOnly($event)"
                (input)="validatePincode($event)"
                [disabled]="!isEdit"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>State <sup>*</sup></label>
              <input [(ngModel)]="workingCorporate.state" type="text" [disabled]="true" class="form-control" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>District <sup>*</sup></label>
              <input [(ngModel)]="workingCorporate.district" type="text" [disabled]="true" class="form-control" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>City <sup>*</sup></label>
              <input [(ngModel)]="workingCorporate.city" type="text" [disabled]="true" class="form-control" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Area <sup>*</sup></label>
              <select
                [(ngModel)]="workingCorporate.area"
                (ngModelChange)="onAreaChanged($event)"
                [disabled]="!isEdit"
                class="form-control"
              >
                <option *ngFor="let area of areas" [ngValue]="area">{{ area }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Address</label>
              <input type="text" class="form-control" [(ngModel)]="workingCorporate.address" [disabled]="!isEdit" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Primary Contact Name <sup>*</sup></label>
              <p [hidden]="!isRequiredContactNameNull || firstTimeEnterPrimaryName" id="fieldInvalidError">
                {{ this.invalidContactNameMsg }}
              </p>
              <input
                [(ngModel)]="workingCorporate.contactName"
                type="text"
                class="form-control"
                (input)="isContactNameNull($event)"
                [disabled]="!isEdit"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Email <sup>*</sup></label>
              <p [hidden]="!isRequiredEmailNull || firstTimeEnterEmail" id="fieldInvalidError">
                {{ this.invalidEmailMsg }}
              </p>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="workingCorporate.email"
                (input)="isEmailNull($event)"
                [disabled]="!isEdit"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Primary Contact Mobile Number</label>
              <p [hidden]="isMobileNumberValid || firstTimeEnterMobileNum" id="fieldInvalidError">
                Please enter valid 10 digit Mobile number!!!
              </p>
              <input
                [(ngModel)]="workingCorporate.mobileNumber"
                type="text"
                class="form-control"
                minlength="10"
                maxlength="10"
                (input)="validateMobileNumber($event)"
                [disabled]="!isEdit"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Primary Contact Landline Number</label>
              <p [hidden]="isLandlineNumberValid || firstTimeEnterLandlineNum" id="fieldInvalidError">
                Please enter valid Landline number!!!
              </p>
              <input
                [(ngModel)]="workingCorporate.landlineNumber"
                type="text"
                class="form-control"
                minlength="11"
                maxlength="11"
                (input)="validateLandlineNumber($event)"
                [disabled]="!isEdit"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Is Multi Lingual</label>
              <select class="form-control" [(ngModel)]="workingCorporate.isMultiLingual" [disabled]="!isEdit">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mt-4">
              <div class="spinner-border text-primary mr-3" [hidden]="editMode" *ngIf="!isSaveButtonEnable"></div>
              <button
                (click)="save()"
                class="btn btn-primary px-4"
                [hidden]="editMode"
                [disabled]="
                  !isMobileNumberValid ||
                  !isLandlineNumberValid ||
                  !isWebsiteUrlValid ||
                  !isPincodeValid ||
                  isPincodeNull ||
                  isRequiredNameNull ||
                  isRequiredCodeNull ||
                  isRequiredContactNameNull ||
                  isRequiredEmailNull ||
                  !isSaveButtonEnable ||
                  !isLocationSelected
                "
              >
                Save
              </button>
              <button [hidden]="editMode" (click)="cancel()" class="btn btn-link ml-2 text-secondary">Cancel</button>

              <div
                class="spinner-border text-primary mr-3"
                [hidden]="!editMode || !isEdit"
                *ngIf="!isSaveButtonEnable"
              ></div>
              <button
                (click)="save()"
                class="btn btn-primary px-4"
                [hidden]="!editMode || !isEdit"
                [disabled]="
                  !isMobileNumberValid ||
                  !isLandlineNumberValid ||
                  !isWebsiteUrlValid ||
                  !isPincodeValid ||
                  isPincodeNull ||
                  isRequiredNameNull ||
                  isRequiredCodeNull ||
                  isRequiredContactNameNull ||
                  isRequiredEmailNull ||
                  !isSaveButtonEnable ||
                  !isLocationSelected
                "
              >
                Save Changes
              </button>
              <button [hidden]="!editMode || !isEdit" (click)="cancel()" class="btn btn-link ml-2 text-secondary sm-2">
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="mt-4">
              <button [hidden]="!editMode || isEdit" (click)="edit()" class="btn btn-primary px-4">
                Edit details
              </button>
              <button
                [hidden]="!displayDeleteCorporateButton"
                (click)="deleteCorporate()"
                class="btn btn-danger px-4"
                [disabled]="deleteCorporateProcessing"
                style="float: right;"
              >
                Delete Corporate<i class="material-icons">delete</i>
                <mat-icon
                  ><mat-spinner color="warn" diameter="23" *ngIf="deleteCorporateProcessing"> </mat-spinner
                ></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
