import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { ok } from 'assert';
import { PreEnrollmentMemberPolicyModel } from '../model/preEnrollment-member-policy.model';
import { FieldConfig, Validator } from 'src/app/dyanamic-fields-form/model/form-fields';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { PreEnrollmentMemberModel } from 'src/app/shared/models/preEnrollment/members/preEnrollment-member.model';

@Injectable({
  providedIn: 'root',
})
export class HRService {
  constructor(private readonly backendService: BackendService) {}

  async getPolicies(): Promise<any> {
    return new Promise(async resolve => {
      const policy = await this.backendService.get(`member/my/policies`);
      resolve(policy);
    });
  }

  public async getMembers(policyId: number): Promise<PreEnrollmentMemberModel[]> {
    return await this.backendService.get('preenrollment/' + policyId + '/preenrollmentmember');
  }
}
