import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MemberUploadFilesModel } from './member-upload-files-model.model';

@Component({
  selector: 'app-show-member-upload-files',
  templateUrl: './show-member-upload-files.component.html',
  styleUrls: ['./show-member-upload-files.component.scss'],
})
export class ShowMemberUploadFilesComponent implements OnInit {
  filesListWithStatus: any;

  constructor(
    public dialogRef: MatDialogRef<MemberUploadFilesModel>,
    @Inject(MAT_DIALOG_DATA) public data: MemberUploadFilesModel
  ) {
    // Update view with given values
    this.filesListWithStatus = data.response;
  }

  ngOnInit() {
    this.filesListWithStatus.sort(function compare(a, b) {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return <any>dateB - <any>dateA;
    });

    this.filesListWithStatus.forEach(element => {
      const ddate = new Date(element.createdAt);
      element[
        'createdAt'
      ] = `${ddate.getDate()}-${ddate.getMonth()}-${ddate.getFullYear()} ${ddate.getHours()}:${ddate.getMinutes()}`;
    });
  }

  downloadURI(url) {
    const link = document.createElement('a');
    link.download = 'member.xlsx';
    link.href = url;
    link.click();
  }

  onConfirm() {
    this.dialogRef.close(true);
  }
}
