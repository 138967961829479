import { Component, OnInit, Input, Injector, SimpleChanges, OnChanges } from '@angular/core';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import * as FileSaver from 'file-saver';
import { Endorsement } from 'src/app/corporate-portal/models/endorsement/endorsement.model';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EndorsementService } from 'src/app/corporate-portal/services/endorsement.service';

@Component({
  selector: 'app-dependent-endorsement',
  templateUrl: './dependent-endorsement.component.html',
  styleUrls: ['./dependent-endorsement.component.scss'],
})
export class DependentEndorsementComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() policy: Policy;
  @Input() loadDetails;
  memberDetails: Endorsement[] = [];
  isDetailsLoaded = false;

  file: any;
  isExcelUploaded = true;

  isDependentEndorsementSampleLoaded = true;

  error = [];
  isHrView = false;

  constructor(
    injector: Injector,
    private policyService: CPPolicyService,
    private endorsementService: EndorsementService,
    private alertservise: AlertService,
    private cpmemberService: CPMemberService
  ) {
    super(injector);
  }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  async ngOnInit() {
    this.isDetailsLoaded = false;
    this.cpmemberService.isHrView.subscribe(v => {
      this.isHrView = v;
      this.callDependentAPI();
    });
    this.loadDetails.subscribe(isLoadDetails => {
      if (isLoadDetails === 'dependent') {
        this.callDependentAPI();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['policy'] !== undefined) {
      this.file = null;
      this.callDependentAPI();
      this.error = [];
    }
  }

  callDependentAPI() {
    if (this.policy.hasOwnProperty('policyId')) {
      if (this.isHrView) {
        this.getDependentEndorsement();
      } else {
        this.getDependentEndorsementByMember();
      }
    }
  }

  getDependentEndorsement() {
    this.memberDetails = [];
    this.isDetailsLoaded = false;
    this.endorsementService
      .getDependentEndorsed(this.policy.policyId)
      .then(memberDetail => {
        this.memberDetails = memberDetail;
        this.isDetailsLoaded = true;
      })
      .catch(x => {
        console.log(x);
      });
  }

  getDependentEndorsementByMember() {
    this.memberDetails = [];
    this.isDetailsLoaded = false;
    this.endorsementService
      .getEndorsementCreatedByMember(this.policy.policyId)
      .then(memberDetail => {
        this.memberDetails = memberDetail;
        this.isDetailsLoaded = true;
      })
      .catch(x => {
        console.log(x);
      });
  }

  uploadFile(event) {
    this.error = [];
    this.file = event.target.files[0];
  }

  deleteFile() {
    this.file = null;
    this.error = [];
  }

  async submitFile() {
    this.error = [];
    this.isExcelUploaded = false;
    const formData = new FormData();
    formData.append('file', this.file);
    this.endorsementService
      .excelDependentEndorsement(this.policy.policyId, formData)
      .then(x => {
        this.isExcelUploaded = true;
        this.file = null;
        this.alertservise.success('Added endorsement.');
        this.getDependentEndorsement();
      })
      .catch(e => {
        this.error = e.error;
        this.isExcelUploaded = true;
        this.alertservise.success('Error Occured.');
      });
  }

  async getSampleDependentExcelFile() {
    this.isDependentEndorsementSampleLoaded = false;
    await this.endorsementService.getDependentEndorsementSample().then(
      result => {
        this.isDependentEndorsementSampleLoaded = true;
        FileSaver.saveAs(result, `DependentEndorsementSample.xlsx`);
      },
      error => {}
    );
  }
}
