export class CorporateModel {
  id: number;
  name: string;
  code: string;
  logoUrl: string;
  logoImage: any;
  website: string;
  address: string;
  emailAddress: string;
  state: any;
  stateId: number;
  cityId: number;
  districtId: number;
  areaId: number;
  city: any;
  district: any;
  area: any;
  pinCode: number;
  contactName: string;
  mobileNumber: string;
  landlineNumber: string;
  email: string;
  isFileChanged: boolean;
  isMultiLingual: boolean = false;
}
