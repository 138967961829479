<div class="header-tabs">
  <nav>
    <ul class="nav">
      <li class="right-head">
        <div class="dropdown card-header-dropdown">
          <button class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <span *ngIf="!currentPolicy.hasOwnProperty('policyId')">
              <span class="shimmer-wrapper">
                <span class="shimmer primary"></span>
              </span>
            </span>
            <span *ngIf="currentPolicy.hasOwnProperty('policyId')">
              <span *ngIf="currentPolicy.isActive" class="logged"></span>
              <span *ngIf="!currentPolicy.isActive" class="notLogged"></span>
              <span *ngIf="currentPolicy.riskStartDate !== undefined && currentPolicy.onScreenDisplayAvailable == false">
                {{ currentPolicy.riskStartDate.split('-')[0] }}-{{ currentPolicy.riskEndDate.split('-')[0] }} -
                {{ currentPolicy.policyNumber }} - {{currentPolicy.policyType}}</span>
              <span *ngIf="currentPolicy.riskStartDate !== undefined && currentPolicy.onScreenDisplayAvailable == true">{{currentPolicy.onScreenDisplayPolicy}}</span>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <div *ngFor="let policy of policies" (click)="onNamePolicyChanged(policy)">
              <a class="dropdown-item">
                <span *ngIf="policy.isActive" class="logged"></span>
                <span *ngIf="!policy.isActive" class="notLogged"></span>
                <span *ngIf="policy.onScreenDisplayAvailable == false">
                  {{ policy.riskStartDate.split('-')[0] }}-{{ policy.riskEndDate.split('-')[0] }} -
                  {{ policy.policyNumber }} - {{policy.policyType}}</span>
                <span *ngIf="policy.onScreenDisplayAvailable == true">{{policy.onScreenDisplayPolicy}}</span>
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</div>
<div class="reports-wrapper">
  <div class="row">
    <div class="card card-style-1 col-xs-12 col-md-6 col-lg-4" *ngIf="
    (hr !== undefined && hr.reportAllowed && role === 'CPHR') ||
    (brokerPrivileges !== undefined && brokerPrivileges.reportAllowed && role === 'Broker') ||
    (smPrivileges !== undefined && smPrivileges.reportAllowed && role === 'SM')
  ">
      <div class="card-body">
        <div class="card-title row justify-content-between align-items-center">
          <h4 class="m-0 header-title">Health Claim MIS</h4>
        </div>
        <div id="Health-claim-mis">
          <app-health-claim-mis [policy]="currentPolicy" [cashless]="
              (hr !== undefined && hr.cashlessReport && role === 'CPHR') ||
              (brokerPrivileges !== undefined && brokerPrivileges.cashlessReport && role === 'Broker') ||
              (smPrivileges !== undefined && smPrivileges.cashlessReport && role === 'SM')
            " [reimbursement]="
              (hr !== undefined && hr.reimbersementReport && role === 'CPHR') ||
              (brokerPrivileges !== undefined && brokerPrivileges.reimbersementReport && role === 'Broker') ||
              (smPrivileges !== undefined && smPrivileges.reimbersementReport && role === 'SM')
            "></app-health-claim-mis>
        </div>
      </div>
    </div>
    <div class="card card-style-1 col-xs-12 col-md-6 col-lg-4" *ngIf="
    (hr !== undefined && hr.reportAllowed && role === 'CPHR') ||
    (brokerPrivileges !== undefined && brokerPrivileges.reportAllowed && role === 'Broker') ||
    (smPrivileges !== undefined && smPrivileges.reportAllowed && role === 'SM')
  ">
      <div class="card-body">
        <div class="card-title row justify-content-between align-items-center">
          <h4 class="m-0 header-title">Health Enrollment MIS</h4>
        </div>
        <div id="Health-enrollment-mis">
          <app-health-enrollment-mis [policy]="currentPolicy"></app-health-enrollment-mis>
        </div>
      </div>
    </div>
    <div class="card card-style-1 col-xs-12 col-md-6 col-lg-4" *ngIf="
    (hr !== undefined && hr.reportAllowed && role === 'CPHR') ||
    (brokerPrivileges !== undefined && brokerPrivileges.reportAllowed && role === 'Broker') ||
    (smPrivileges !== undefined && smPrivileges.reportAllowed && role === 'SM')
  ">
      <div class="card-body">
        <div class="card-title row justify-content-between align-items-center">
          <h4 class="m-0 header-title">User Id MIS</h4>
        </div>
        <div id="User-id-mis">
          <app-user-id-mis [policy]="currentPolicy"></app-user-id-mis>
        </div>
      </div>
    </div>
  </div>



  <div class="row" *ngIf="
  (hr !== undefined && !hr.reportAllowed && role === 'CPHR') ||
  (brokerPrivileges !== undefined && !brokerPrivileges.reportAllowed && role === 'Broker') ||
  (smPrivileges !== undefined && !smPrivileges.reportAllowed && role === 'SM')
">
    <div class="card card-style-1 col-xs-12">
      <div class="no-result-wrapper text-center">
        <h5>This feature is not Allowed.</h5>
      </div>
    </div>
  </div>
</div>