import { BackendService } from 'src/app/shared/services/backend.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommCodeService {
  commCodes: string[] = [];

  constructor(private readonly backendService: BackendService) {}

  public async getCommCode(policyNumbers): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const result = this.backendService.post('CorporatePortal/policies/commcode', policyNumbers).then(
        x => {
          this.commCodes = x.commCodes;
          return resolve(this.commCodes);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getCommCodeByPolicy(policyNumber): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const result = this.backendService.get(`CorporatePortal/policies/policy/${policyNumber}/commcode`).then(
        x => {
          this.commCodes = x.commCodes;
          return resolve(this.commCodes);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
}
