import { Component, OnInit } from '@angular/core';
import { PreEnrollmentMemberPolicyModel } from '../../model/preEnrollment-member-policy.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { PreEnrollmentMemberService } from '../../services/preEnrollment-member.service';
import { FieldConfig, Validator } from 'src/app/dyanamic-fields-form/model/form-fields';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-member',
  templateUrl: './edit-member.component.html',
  styleUrls: ['./edit-member.component.scss'],
})
export class EditMemberComponent implements OnInit {
  selfMember: PreEnrollmentMemberPolicyModel;
  dependentMember: PreEnrollmentMemberPolicyModel;
  policy: PreEnrollmentPolicyModel;
  selfData: FieldConfig[];
  selfName: string;
  isLoading = true;
  isPolicyStatusActive = false;
  dependents: PreEnrollmentMemberPolicyModel[] = [];

  constructor(
    private readonly preEnrollmentService: PreEnrollmentMemberService,
    private readonly alertService: AlertService,
    private readonly router: Router
  ) {}
  errorR: any;

  async ngOnInit(): Promise<void> {
    this.selfMember = this.preEnrollmentService.selfPolicyModel;
    if (this.selfMember === null) {
      this.selfMember = await this.preEnrollmentService.getMember();
    }

    this.policy = this.preEnrollmentService.policy;
    if (this.policy === null) {
      this.policy = await this.preEnrollmentService.getPolicy();
    }
    this.isPolicyStatusActive = this.policy.period.status;

    this.selfData = this.selfMember.members[0].fields;

    this.selfData.forEach(element => {
      if (element.name === 'Insured Name') {
        this.selfName = element.value;
      }
      this.isLoading = false;
    });
  }

  async submit(value: any) {
    const data = value.getRawValue();
    if ('ESI' in data) {
      data.ESI = data.ESI.toString().replace(/,/g, '');
    }
    if ('PSI' in data) {
      data.PSI = data.PSI.toString().replace(/,/g, '');
    }
    if ('Topup' in data) {
      data.Topup = data.Topup.toString().replace(/,/g, '');
    }
    if ('Sum Insured' in data) {
      data['Sum Insured'] = data['Sum Insured'].toString().replace(/,/g, '');
    }

    this.preEnrollmentService
      .updateMember(this.policy.id, data)
      .then(x => {
        this.preEnrollmentService.memberUpdate.next(true);
        this.alertService.success('Member updated successfully.');
        if (this.policy.plan === 'Floater' && ('Topup' in data || 'ESI' in data)) {
          this.preEnrollmentService
            .getMemberFamily(this.policy.id)
            .then(x => {
              x.members.forEach(member => {
                if (
                  member.relationshipId !== 5 &&
                  member.relationshipId !== 6 &&
                  member.relationshipId !== 7 &&
                  member.relationshipId !== 8
                ) {
                  const obj = JSON.parse(member.fieldsJson);
                  const topup = 'Topup' in data ? data.Topup.toString().replace(/,/g, '') : obj['Topup'];
                  const esi = 'ESI' in data ? data.ESI.toString().replace(/,/g, '') : obj['ESI'];

                  const memberdata = {
                    'Date Of Birth': obj['Date Of Birth'],
                    ESI: esi,
                    'Email Id': obj['Email Id'],
                    'Employee Id': obj['Employee Id'],
                    Gender: obj['Gender'],
                    Grade: obj['Grade'],
                    'Insured Name': obj['Insured Name'],
                    Relationship: obj['Relationship'],
                    'Sum Insured': obj['Sum Insured'],
                    Topup: topup,
                  };
                  this.preEnrollmentService.updateMemberFamily(member.id, memberdata).then(x => {
                    this.preEnrollmentService.getMemberFamily(this.policy.id);
                    this.router.navigate(['member/enrollment']);
                  });
                }
              });
              this.router.navigate(['member/enrollment']);
            })
            .catch(error => {});
        } else {
          this.router.navigate(['member/enrollment']);
        }
      })
      .catch(errorData => {
        this.errorR = errorData;
        this.alertService.error('Error occured');
      });
  }
}
