import { Component, OnInit, Injector, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Sm } from 'src/app/shared/models/CorporatePortal/sm.model';
import { Guid } from 'src/app/shared/enums/guid.enum';

@Component({
  selector: 'app-create-sm',
  templateUrl: './create-sm.component.html',
  styleUrls: ['./create-sm.component.scss'],
})
export class CreateSmComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() public corporate;
  @Input() public policy;
  @Output() public smAddedSucessfully = new EventEmitter<boolean>();
  code: string;
  isMemberDetailsFound: boolean;
  sm: Sm;
  saveButtonText = 'Next';
  currentStep = 'firstStep';
  isReportCheckboxValid = true;
  isDashboardCheckboxValid = true;
  isNameValid = false;
  isCodeValid = false;
  isEmailValid = false;
  isMobileNoValid = false;
  disableCode = false;
  disableName = false;
  disableEmail = true;
  disableMobileNo = true;
  invalidMobileMsg: string;
  invalidEmailMsg: string;
  invalidNameMsg: string;
  errorList = [];
  title = 'Create SM';
  isUpdatingSm = false;
  smDetails: any;
  invalidCodeMsg: string;
  smSearchList = [];
  searchByCodeDetailsFound = false;

  showLoader = false;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly corporatePortalService: CorporatePortalService,
    private readonly alertService: AlertService,
    private modalService: NgbModal
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.sm = new Sm();
    if (this.smDetails !== undefined) {
      this.title = 'Edit SM';
      this.getSmByCode();
      this.isUpdatingSm = true;
    } else {
      this.code = '';
      this.disableEmail = true;
      this.disableMobileNo = true;
      this.isEmailValid = true;
      this.isMobileNoValid = true;
    }
  }

  close() {
    this.modalService.dismissAll(CreateSmComponent);
  }

  back() {
    this.currentStep = 'firstStep';
    this.saveButtonText = 'Next';
    this.isMemberDetailsFound = true;
  }

  async save(event) {
    if (event === 'Next') {
      this.currentStep = 'secondStep';
      this.saveButtonText = 'Save';
      this.checkCheckboxesValidity();
    } else if (event === 'Save') {
      this.isMemberDetailsFound = false;
      this.showLoader = true;
      if (this.sm.smMaster.id === null) {
        this.sm.smMaster.id = Guid.Default;
        delete this.sm['smMaster']['loginEmailSentAt'];
        delete this.sm['smMaster']['loginSMSSentAt'];
      }
      if (this.isUpdatingSm === true) {
        await this.corporatePortalService
          .updateSm(this.policy.id, this.sm, this.smDetails.id)
          .then(x => {
            console.log(x);
            this.showLoader = false;
            this.smAddedSucessfully.emit(true);
            this.modalService.dismissAll(CreateSmComponent);
            this.alertService.success('SM updated successfully');
          })
          .catch(errorData => {
            this.showLoader = false;
            this.errorList = errorData.error.errors;
            this.isMemberDetailsFound = true;
            this.alertService.error('Error occured');
          });
      } else {
        await this.corporatePortalService
          .createSm(this.policy.id, this.sm)
          .then(x => {
            console.log(x);
            this.showLoader = false;
            this.smAddedSucessfully.emit(true);
            this.modalService.dismissAll(CreateSmComponent);
            this.alertService.success('SM added successfully');
          })
          .catch(errorData => {
            this.showLoader = false;
            this.errorList = errorData.error.errors;
            this.isMemberDetailsFound = true;
            this.alertService.error('Error occured');
          });
      }
    }
    console.log(event);
    console.log(this.sm);
  }

  async searchSmByCode(event) {
    if (event.target.value.trim() !== '') {
      const sm = await this.corporatePortalService.getSmByCode(event.target.value);
      if (sm !== null) {
        this.isCodeValid = true;
        this.invalidCodeMsg = '';
        this.searchByCodeDetailsFound = true;
        this.smSearchList = [];
        this.isMemberDetailsFound = true;
        this.sm.smMaster.code = event.target.value;
        this.sm.smMaster.name = sm.name;
        this.sm.smMaster.email = sm.email;
        this.sm.smMaster.mobileNo = sm.mobileNo;
        this.sm.smMaster.id = sm.id;
        this.sm.smMaster.loginEmailSentAt = sm.loginEmailSentAt;
        this.sm.smMaster.loginSMSSentAt = sm.loginSMSSentAt;
        this.disableName = true;
        this.isNameValid = true;
      } else {
        this.isCodeValid = false;
        this.invalidCodeMsg = 'SM not found';
        this.searchByCodeDetailsFound = false;
        this.disableName = false;
        this.isMemberDetailsFound = false;
        this.sm.smMaster.code = event.target.value;
        this.sm.smMaster.name = null;
        this.sm.smMaster.email = null;
        this.sm.smMaster.mobileNo = null;
        this.sm.smMaster.id = null;
        this.sm.smMaster.loginEmailSentAt = null;
        this.sm.smMaster.loginSMSSentAt = null;
      }
    } else {
      this.isCodeValid = false;
      this.invalidCodeMsg = 'SM not found';
      this.searchByCodeDetailsFound = false;
      this.disableName = false;
      this.isMemberDetailsFound = false;
      this.sm.smMaster.code = event.target.value;
      this.sm.smMaster.name = null;
      this.sm.smMaster.email = null;
      this.sm.smMaster.mobileNo = null;
      this.sm.smMaster.id = null;
      this.sm.smMaster.loginEmailSentAt = null;
      this.sm.smMaster.loginSMSSentAt = null;
      this.isNameValid = false;
      this.invalidNameMsg = '';
    }
  }

  checkDashboardValue() {
    if (this.sm.dashboard === true) {
      if (this.sm.staticDashboard === true || this.sm.dyanamicDashboard === true) {
        this.isDashboardCheckboxValid = true;
      } else {
        this.isDashboardCheckboxValid = false;
      }
    } else if (this.sm.dashboard === false) {
      this.sm.staticDashboard = false;
      this.sm.dyanamicDashboard = false;
      this.isDashboardCheckboxValid = true;
    }
  }

  checkReportValue() {
    if (this.sm.reportAllowed === true) {
      if (this.sm.reimbersementReport === true || this.sm.cashlessReport === true) {
        this.isReportCheckboxValid = true;
      } else {
        this.isReportCheckboxValid = false;
      }
    } else if (this.sm.reportAllowed === false) {
      this.sm.reimbersementReport = false;
      this.sm.cashlessReport = false;
      this.isReportCheckboxValid = true;
    }
  }

  checkCheckboxesValidity() {
    this.checkDashboardValue();
    this.checkReportValue();
    this.isMemberDetailsFound =
      this.isReportCheckboxValid === true && this.isDashboardCheckboxValid === true ? true : false;
  }

  checkNameValidity(event) {
    const fieldValue = event.target.value.trim();
    if (this.smSearchList.length !== 0) {
      this.smSearchList = [];
      this.code = '';
      this.sm.smMaster.email = '';
      this.sm.smMaster.code = '';
      this.sm.smMaster.mobileNo = '';
      this.disableCode = false;
      this.isEmailValid = false;
      this.isMobileNoValid = false;
      this.isMemberDetailsFound = false;
    }
    if (fieldValue.length === 0) {
      this.isNameValid = false;
      this.invalidNameMsg = 'Name cannot be null!!!';
    } else if (fieldValue.match(/^[a-zA-Z][a-zA-Z. ]*[a-zA-Z]$/) === null) {
      this.isNameValid = false;
      this.invalidNameMsg = 'Please enter valid name!!';
    } else {
      this.isNameValid = true;
    }
    this.showNextButton();
  }

  async getSmByName(event) {
    if (event.target.value.trim() !== '') {
      const fieldValue = event.target.value.trim();
      const smSearchList = await this.corporatePortalService.searchSmByName(fieldValue);
      if (smSearchList.length > 0) {
        this.smSearchList = smSearchList;
      }
    }
  }

  async onNameDropdownChanged(event) {
    this.code = this.sm.smMaster.code;
    this.disableCode = true;
    this.disableEmail = true;
    this.disableMobileNo = true;
    this.isCodeValid = true;
    this.isEmailValid = true;
    this.isMobileNoValid = true;
    this.isNameValid = true;
    this.invalidEmailMsg = '';
    this.invalidMobileMsg = '';
    this.showNextButton();
  }

  checkCodeValidity(event) {
    // if (this.searchByCodeDetailsFound) {
    //   this.disableName = false;
    //   // this.disableEmail = false;
    //   // this.disableMobileNo = false;
    //   this.sm.smMaster.email = '';
    //   this.sm.smMaster.name = '';
    //   this.sm.smMaster.mobileNo = '';
    //   this.isEmailValid = true;
    //   this.isMobileNoValid = true;
    //   this.isNameValid = true;
    // }
    this.disableName = false;
    this.sm.smMaster.email = null;
    this.sm.smMaster.name = null;
    this.sm.smMaster.mobileNo = null;
    this.isEmailValid = false;
    this.isMobileNoValid = false;
    this.isNameValid = false;

    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.isCodeValid = false;
      this.invalidCodeMsg = 'Code cannot be null!!!';
    } else if (fieldValue.match(/^[a-zA-Z0-9]+$/i) === null) {
      this.isCodeValid = false;
      this.invalidCodeMsg = 'Please enter valid code!!';
    } else {
      this.isCodeValid = true;
    }
    this.showNextButton();
  }

  showNextButton() {
    if (this.code.length === 0) {
      this.isMemberDetailsFound = false;
    } else {
      this.isMemberDetailsFound =
        this.isNameValid === true &&
        this.isCodeValid === true &&
        this.isEmailValid === true &&
        this.isMobileNoValid === true
          ? true
          : false;
    }
  }

  async getSmByCode() {
    this.isMemberDetailsFound = true;
    this.code = this.smDetails.code;
    this.sm.smMaster.code = this.smDetails.code;
    this.sm.smMaster.name = this.smDetails.name;
    this.sm.smMaster.email = this.smDetails.email;
    this.sm.smMaster.mobileNo = this.smDetails.mobileNo;
    this.sm.smMaster.id = this.smDetails.id;
    this.sm.smMaster.loginEmailSentAt = this.smDetails.loginEmailSentAt;
    this.sm.smMaster.loginSMSSentAt = this.smDetails.loginSMSSentAt;
    this.sm.reportAllowed = this.smDetails.reportAllowed;
    this.sm.endoresment = this.smDetails.endoresment;
    this.sm.dashboard = this.smDetails.dashboard;
    this.sm.staticDashboard = this.smDetails.staticDashboard;
    this.sm.dyanamicDashboard = this.smDetails.dyanamicDashboard;
    this.sm.cashlessReport = this.smDetails.cashlessReport;
    this.sm.reimbersementReport = this.smDetails.reimbersementReport;
    this.disableName = true;
    this.disableEmail = true;
    this.disableMobileNo = true;
    this.disableCode = true;
  }
}
