import { Component, OnInit, OnDestroy, Injector, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html',
  styleUrls: ['./create-document.component.scss'],
})
export class CreateDocumentComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() public corporate;
  @Input() public policy;
  @Output() public docAddedSucessfully = new EventEmitter<boolean>();
  disableCreateButton = true;
  documentType: string;
  documentTypeName: string;
  documentName: string;
  isSizeValid = false;
  isNameValid = false;
  isTypeNameValid = false;
  uploadImageInvalidMsg: string;
  invalidNameMsg: string;
  invalidTypeNameMsg: string;
  isMemberDetailsFound = true;
  file: any;
  fileName: string;
  errorList = [];
  showLoader = false;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly corporatePortalService: CorporatePortalService,
    private readonly alertService: AlertService,
    private modalService: NgbModal
  ) {
    super(injector);
  }
  ngOnInit(): void {}
  close() {
    this.modalService.dismissAll(CreateDocumentComponent);
  }
  async save() {
    this.disableCreateButton = true;
    this.showLoader = true;
    const json = {
      documentType: this.documentType,
      documentName: this.documentName,
      documentTypeName: this.documentTypeName,
    };
    const stringJson = JSON.stringify(json);
    const data = new FormData();
    data.append('Document', this.file[0], this.file[0].name);
    data.append('Json', stringJson);
    await this.corporatePortalService
      .createDocument(this.policy.id, data)
      .then(x => {
        console.log(x);
        this.showLoader = false;
        this.docAddedSucessfully.emit(true);
        this.modalService.dismissAll(CreateDocumentComponent);
        this.alertService.success('Document added successfully');
      })
      .catch(errorData => {
        this.showLoader = false;
        this.errorList = errorData.error.errors;
        this.disableCreateButton = false;
        this.alertService.error('Error occured');
      });
  }
  validateFile(file) {
    console.log(file);
    if (file.length > 0) {
      this.file = file;
      this.fileName = file[0].name;
      const name = file[0].name;
      const size = file[0].size;
      if (size > 5000000) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Max size must be 5 Mb';
      } else {
        this.isSizeValid = true;
      }
      if (this.isSizeValid === true) {
        const ext = name.substring(name.lastIndexOf('.') + 1);
        const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf'];
        if (!fileExtensionArr.includes(ext.toLowerCase())) {
          this.isSizeValid = false;
          this.uploadImageInvalidMsg = 'Upload valid format file';
        }
      }
      this.check();
    }
  }
  check() {
    if (
      this.documentName !== undefined &&
      this.documentName.trim() !== '' &&
      this.documentType !== undefined &&
      this.documentType.trim() !== '' &&
      this.file !== undefined
    ) {
      if (
        this.documentType === 'Others' &&
        this.documentTypeName !== undefined &&
        this.documentTypeName.trim() !== ''
      ) {
        this.disableCreateButton =
          this.isNameValid === true && this.isSizeValid === true && this.isTypeNameValid === true ? false : true;
      } else if (this.documentType !== 'Others' && this.documentType !== undefined && this.documentType !== '') {
        this.documentTypeName = '';
        this.disableCreateButton = this.isNameValid === true && this.isSizeValid === true ? false : true;
      } else {
        this.disableCreateButton = true;
      }
    } else {
      this.disableCreateButton = true;
    }
  }
  isDocumentTypeNameValid(event) {
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.isTypeNameValid = false;
      this.invalidTypeNameMsg = 'Name cannot be null!!!';
    } else {
      this.isTypeNameValid = true;
    }
    this.check();
  }
  isDocumentNameValid(event) {
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.isNameValid = false;
      this.invalidNameMsg = 'Name cannot be null!!!';
    } else if (fieldValue.match(/^[a-zA-Z0-9][a-zA-Z0-9., ]+$/i) === null) {
      this.isNameValid = false;
      this.invalidNameMsg = 'Document name is invalid';
    } else {
      this.isNameValid = true;
    }
    this.check();
  }
}
