import { ReminderEmailDateTime } from './reminder-email-date-time.model';

export class ReminderEmail {
  sendReminderEmail: boolean;
  reminderEmailTemplate: string;
  reminderEmailSubject: string;
  pEReminderDateTime: ReminderEmailDateTime[] = [];
  reminderEmailFile: any;
  reminderEmailFileName: string;
}
