import { NgModule, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListCorporateComponent } from './components/list-corporate/list-corporate.component';
import { CreateCorporateComponent } from './components/create-corporate/create-corporate.component';
import { CorporateDetailsComponent } from './components/corporate-details/corporate-details.component';
import { SharedModule } from '../shared/shared.module';
import { BusinessAdminRoutingModule } from './business-admin-routing.module';
import { CreatePreEnrollmentComponent } from './components/create-pre-enrollment/create-pre-enrollment.component';
import { PreEnrollmentComponent } from './components/pre-enrollment/pre-enrollment.component';
import { PreEnrollmentPeriodsComponent } from './components/pre-enrollment/pre-enrollment-periods/pre-enrollment-periods.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CorporateService } from './services/corporate.service';
import { MasterService } from './services/master.service';
import { BusinessAdminComponent } from './components/business-admin/business-admin.component';
import { PreEnrollmentMembersComponent } from './components/pre-enrollment/pre-enrollment-members/pre-enrollment-members.component';
import { BizAdminHeaderComponent } from './components/biz-admin-header/biz-admin-header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationComponent } from './components/notification/notification.component';
import { CreateNotificationComponent } from './components/notification/create-notification/create-notification.component';
import { CorporatePortalComponent } from './components/corporate-portal/corporate-portal.component';
import { CorporatePortalPolicyDetailsComponent } from './components/corporate-portal/corporate-portal-policy-details/corporate-portal-policy-details.component';
import { CorporatePortalAttachPolicyComponent } from './components/corporate-portal/corporate-portal-attach-policy/corporate-portal-attach-policy.component';
import { ListHrComponent } from './components/corporate-portal/list-hr/list-hr.component';
import { CreateHrComponent } from './components/corporate-portal/list-hr/create-hr/create-hr.component';
import { FeaturesComponent } from './components/corporate-portal/features/features.component';
import { DocumentsComponent } from './components/corporate-portal/documents/documents.component';
import { MembersComponent } from './components/corporate-portal/members/members.component';
import { CreateDocumentComponent } from './components/corporate-portal/documents/create-document/create-document.component';
import { ListBrokerComponent } from './components/corporate-portal/list-broker/list-broker.component';
import { ListSmComponent } from './components/corporate-portal/list-sm/list-sm.component';
import { CreateSmComponent } from './components/corporate-portal/list-sm/create-sm/create-sm.component';
import { CreateBrokerComponent } from './components/corporate-portal/list-broker/create-broker/create-broker.component';
import { CredentialsComponent } from './components/corporate-portal/credentials/credentials.component';
import { AttachPolicyComponent } from './components/corporate-portal/corporate-portal-attach-policy/attach-policy/attach-policy.component';
import { BizAdminNotificationComponent } from './components/notification/biz-admin-notification/biz-admin-notification.component';
import { HrNotificationComponent } from './components/notification/hr-notification/hr-notification.component';
import { CorporatePortalModule } from '../corporate-portal/corporate-portal.module';
import { PrivilegesComponent } from './components/corporate-portal/privileges/privileges.component';
import { MaterialModule } from '../material/material.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PremiumCalculatorComponent } from './components/pre-enrollment/premium-calculator/premium-calculator.component';
import { ConfigurePremiumComponent } from './components/pre-enrollment/premium-calculator/configure-premium/configure-premium.component';
import { ConfigureSumnsuredComponent } from './components/pre-enrollment/premium-calculator/configure-sumnsured/configure-sumnsured.component';
import { ConfigurePreEnrollmentComponent } from './components/pre-enrollment/configure-pre-enrollment/configure-pre-enrollment.component';
import { ConfigureEmailComponent } from './components/pre-enrollment/configure-pre-enrollment/configure-email/configure-email.component';
import { ConfigureWelcomeEmailComponent } from './components/pre-enrollment/configure-pre-enrollment/configure-welcome-email/configure-welcome-email.component';
import { ConfigureReminderEmailComponent } from './components/pre-enrollment/configure-pre-enrollment/configure-reminder-email/configure-reminder-email.component';
import { AddNewPremiumComponent } from './components/pre-enrollment/premium-calculator/configure-premium/add-new-premium/add-new-premium.component';
import { PremiumDetailsComponent } from './components/pre-enrollment/premium-calculator/premium-details/premium-details.component';

@NgModule({
  declarations: [
    ListCorporateComponent,
    CreateCorporateComponent,
    CorporateDetailsComponent,
    CreatePreEnrollmentComponent,
    PreEnrollmentComponent,
    PreEnrollmentPeriodsComponent,
    PreEnrollmentMembersComponent,
    BusinessAdminComponent,
    BizAdminHeaderComponent,
    NotificationComponent,
    CreateNotificationComponent,
    CorporatePortalComponent,
    CorporatePortalPolicyDetailsComponent,
    CorporatePortalAttachPolicyComponent,
    ListHrComponent,
    CreateHrComponent,
    FeaturesComponent,
    DocumentsComponent,
    MembersComponent,
    CreateDocumentComponent,
    ListBrokerComponent,
    CreateBrokerComponent,
    ListSmComponent,
    CreateSmComponent,
    CredentialsComponent,
    AttachPolicyComponent,
    BizAdminNotificationComponent,
    HrNotificationComponent,
    PrivilegesComponent,
    PremiumCalculatorComponent,
    ConfigurePremiumComponent,
    ConfigureSumnsuredComponent,
    ConfigurePreEnrollmentComponent,
    ConfigureEmailComponent,
    ConfigureWelcomeEmailComponent,
    ConfigureReminderEmailComponent,
    AddNewPremiumComponent,
    PremiumDetailsComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    BusinessAdminRoutingModule,
    CorporatePortalModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    EditorModule,
  ],
  exports: [
    ListCorporateComponent,
    CreateCorporateComponent,
    CorporateDetailsComponent,
    PreEnrollmentComponent,
    PreEnrollmentPeriodsComponent,
    PreEnrollmentMembersComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BusinessAdminModule {
  constructor() {}
}
