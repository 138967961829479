<div class="confirm-modal-wrapper">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Member files upload Status</h5>
            </div>
            <div class="modal-body"  *ngIf="filesListWithStatus.length > 0">
                <div class="row memberupload">
                    <div class="container">
                        <div class="list-component-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Created at</th>
                                        <th>Created by</th>
                                        <th>Status</th>
                                        <th>Download File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of filesListWithStatus">
                                        <td>
                                            {{file.createdAt}}
                                        </td>
                                        <td>{{ file.jobCreatedBy }}</td>
                                        <td>{{ file.status }}</td>

                                        <td><button type="submit" (click)="downloadURI(file.memberFileURL)"
                                                class="btn btn-sm import-btn">
                                                <i class="material-icons">cloud_download</i>
                                            </button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-result-wrapper" *ngIf="filesListWithStatus.length === 0">
                <h4>No files to show!!!</h4>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary px-4" (click)="onConfirm()">Close</button>
            </div>
        </div>
    </div>
</div>