<app-popup-model
  [title]="title"
  [saveButton]="saveButtonText"
  [isDisableSaveButton]="!form.valid"
  (close)="close()"
  (save)="save($event)"
>
  <div class="error-msg" *ngFor="let error of errorList">
    <p>{{ error.message }}</p>
  </div>

  <form [formGroup]="form">
    <div class="form-group" *ngIf="addMemberOrDependent === 'addMemberByHr'">
      <label>Policy No:<sup>*</sup></label>
      <!-- <input type="text" class="form-control" formControlName="policyNo" /> -->
      <div class="dropdown">
        <button
          class="btn my-0 dropdown-toggle text-left w-100"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <span *ngIf="currentPolicyChosen === null">Select policy</span>
          <span *ngIf="currentPolicyChosen !== null && currentPolicyChosen.isActive" class="logged"></span>
          <span *ngIf="currentPolicyChosen !== null && !currentPolicyChosen.isActive" class="notLogged"></span>
          <span *ngIf="currentPolicyChosen !== null"> {{ currentPolicyChosen.policyNumber }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right w-100">
          <div *ngFor="let policy of policies" (click)="policyChanged(policy)">
            <a class="dropdown-item" *ngIf="policy.isActive">
              <span *ngIf="policy.isActive" class="logged"></span>
              <span *ngIf="!policy.isActive" class="notLogged"></span>
              <span> {{ policy.policyNumber }}</span>
            </a>
          </div>
        </div>
      </div>
      <span class="error-msg small" *ngIf="form.get('policyNo').dirty && form.get('policyNo').status === 'INVALID'"
        >Invalid Policy Number <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group" *ngIf="addMemberOrDependent === 'addDependentByMember'">
      <label>Policy No:<sup>*</sup></label>
      <input type="text" name="" class="form-control" disabled formControlName="policyNo" />
    </div>
    <div class="form-group" *ngIf="addMemberOrDependent === 'addMemberByHr'">
      <label>Employee Id:<sup>*</sup></label>
      <input type="text" class="form-control" formControlName="employeeId" />
      <span class="error-msg small" *ngIf="form.get('employeeId').dirty && form.get('employeeId').status === 'INVALID'"
        >Invalid Employee Id <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group" *ngIf="addMemberOrDependent === 'addDependentByMember'">
      <label>Employee Id:<sup>*</sup></label>
      <input type="text" name="" class="form-control" disabled formControlName="employeeId" />
    </div>
    <div class="form-group">
      <label>Insured Name:<sup>*</sup></label>
      <input type="text" class="form-control" formControlName="insuredName" />
      <span
        class="error-msg small"
        *ngIf="form.get('insuredName').dirty && form.get('insuredName').status === 'INVALID'"
        >Invalid Insured Name <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group">
      <label>Gender:<sup>*</sup></label>
      <select name="" class="form-control" formControlName="gender">
        <option value="M">Male</option>
        <option value="F">Female</option>
      </select>
    </div>
    <div class="form-group">
      <label>Mobile No:<sup>*</sup></label>
      <input type="number" class="form-control" formControlName="mobileNo" />
      <span class="error-msg small" *ngIf="form.get('mobileNo').dirty && form.get('mobileNo').status === 'INVALID'"
        >Invalid Mobile Number<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group">
      <label>Location:</label>
      <input type="text" class="form-control" formControlName="location" />
      <span class="error-msg small" *ngIf="form.get('location').dirty && form.get('location').status === 'INVALID'"
        >Invalid Location <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group" *ngIf="addMemberOrDependent !== 'addMemberByHr'">
      <label>Relationship With Insured:<sup>*</sup></label>
      <select name="" class="form-control" formControlName="relationship" (change)="relationshipChanged()">
        <option *ngFor="let relation of relationships" [value]="relation[0]">{{ relation[0] }}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="addMemberOrDependent === 'addMemberByHr'">
      <label>Relationship With Insured:<sup>*</sup></label>
      <input type="text" name="" class="form-control" disabled formControlName="relationship" />
    </div>
    <div class="form-group">
      <label>Sum Insured:</label>
      <input type="number" (keypress)="numberOnly($event)" class="form-control" formControlName="sumInsured" />
      <span class="error-msg small" *ngIf="form.get('sumInsured').dirty && form.get('sumInsured').status === 'INVALID'"
        >Invalid Sum Insured <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group">
      <label>Email ID:</label>
      <input type="text" class="form-control" formControlName="email" />
      <span class="error-msg small" *ngIf="form.get('email').dirty && form.get('email').status === 'INVALID'"
        >Invalid Email Id <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group" *ngIf="addMemberOrDependent === 'addMemberByHr'">
      <label>Grade:</label>
      <input type="text" class="form-control" formControlName="grade" />
      <span class="error-msg small" *ngIf="form.get('grade').dirty && form.get('grade').status === 'INVALID'"
        >Invalid Grade <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group">
      <label>Date Of Birth:<sup>*</sup></label>
      <input
        value="{{ date | date: 'dd MMMM yyyy' }}"
        type="text"
        class="form-control"
        readonly
        bsDatepicker
        [maxDate]="date"
        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY', adaptivePosition: true }"
        formControlName="dob"
      />
    </div>
    <div class="form-group">
      <label>Endorsement Type:<sup>*</sup></label>
      <select name="" class="form-control" formControlName="endorsementType">
        <option value="Addition">ADDITION</option>
        <option value="Modification">MODIFICATION</option>
        <option value="Deletion">DELETION</option>
        <option value="FreshEnrollement">FRESHENROLLMENT</option>
      </select>
    </div>
    <div class="form-group" *ngIf="isTopUpSumInsured">
      <label>TopUp Sum Insured:</label>
      <input type="number" (keypress)="numberOnly($event)" class="form-control" formControlName="topupSI" />
      <span class="error-msg small" *ngIf="form.get('topupSI').dirty && form.get('topupSI').status === 'INVALID'"
        >Invalid Topup Sum Insured <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group" *ngIf="addMemberOrDependent === 'addMemberByHr'">
      <label>Designation:</label>
      <input type="text" class="form-control" formControlName="designation" />
      <span
        class="error-msg small"
        *ngIf="form.get('designation').dirty && form.get('designation').status === 'INVALID'"
        >Invalid Designation<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group">
      <label>Elite:</label>
      <input type="text" class="form-control" formControlName="elite" />
      <span class="error-msg small" *ngIf="form.get('elite').dirty && form.get('elite').status === 'INVALID'"
        >Invalid Elite <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="form-group">
      <label>UHID:</label>
      <input type="text" class="form-control" formControlName="uhid" />
      <span class="error-msg small" *ngIf="form.get('uhid').dirty && form.get('uhid').status === 'INVALID'"
        >Invalid UHID <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
  </form>
</app-popup-model>
