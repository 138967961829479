export class ClaimDetails {
  dateAdmission: string;
  dateOfDischarge: string;
  claimNo: string;
  claimType: string;
  copaymentDeduction: string;
  claimAmt: string;
  sanctionAmount: string;
  disallowedAmount: string;
  compulsoryDeduction: string;
  netSanctionAmount: string;
  serviceTax: string;
  payableAmount: string;
  tdsAmount: string;
  finalPayableAmount: string;
  queryResons: string;
  claimStatus: string;
}
