import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/business-admin/services/notification.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Guid } from 'src/app/shared/enums/guid.enum';

@Component({
  selector: 'app-create-notification',
  templateUrl: './create-notification.component.html',
  styleUrls: ['./create-notification.component.scss'],
})
export class CreateNotificationComponent extends BaseComponent implements OnInit {
  date = new Date();
  date1: Date;
  date2: Date;
  broadcast_to: string;
  isSizeValid = false;
  uploadImageInvalidMsg = '';

  fileName: string;
  errorR = [];
  corporates: any;
  isEdit = false;
  notificationForm = this.fb.group({
    effectiveDateFrom: ['', Validators.required],
    effectiveDateTo: ['', Validators.required],
    subject: [
      '',
      [
        Validators.required,
        Validators.minLength(10),
        // Validators.pattern(
        //   '^[A-Za-z0-9-<>{}"/|;:.,\'~!?@#$%^=&*\\]\\\\()\\[¿§«»ω⊙¤°℃℉€¥£¢¡®©_+]+([A-Za-z0-9-<>{}"/|;:.,\'~!?@#$%^=&*\\]\\\\()\\[¿§«»ω⊙¤°℃℉€¥£¢¡®©_+ ]*)s*$'
        // ),
        Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$'),
      ],
    ],
    content: [
      '',
      [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(256),
        Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$'),
      ],
    ],
    isAllCorporate: [''],
    isForAllPolicy: [''],
    isForHR: [''],
    isForMember: [''],
    file: [''],
    broadcast_to: ['', Validators.required],
    specificCorporate: [''],
    specificPolicy: [''],
    corporateCode: [''],
    corporateName: [''],
  });
  notification: any;

  constructor(
    private fb: FormBuilder,
    injector: Injector,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    public activatedRoute: ActivatedRoute
  ) {
    super(injector);
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }
  ngOnInit(): void {
    this.setFormValueForCreatingNotification();
    console.log(this.notificationForm.value);
  }

  setFormValueForCreatingNotification() {
    this.notificationForm.controls.isForHR.setValue(true);
    this.notificationForm.controls.isForAllPolicy.setValue(true);
    this.notificationForm.controls.isForMember.setValue(true);
    this.notificationForm.controls.specificPolicy.setValue(Guid.Default);
    this.notificationForm.get('effectiveDateTo').disable();
    this.notificationForm.controls['effectiveDateFrom'].valueChanges.subscribe(value => {
      value = moment(value)
        .add(1, 'month')
        .toDate();
      value = moment(value)
        .subtract(1, 'day')
        .toDate();
      this.notificationForm.controls.effectiveDateTo.setValue(value);
    });
  }

  StartDateSelected() {
    this.notificationForm.get('effectiveDateTo').enable();
  }

  async onSubmit() {
    this.setFormValue();
    console.log(this.notificationForm.value);
    const Json = {
      effectiveDateFrom: moment(this.notificationForm.value.effectiveDateFrom).add('330', 'minutes'),
      effectiveDateTo: moment(this.notificationForm.value.effectiveDateTo).add('330', 'minutes'),
      subject: this.notificationForm.value.subject,
      content: this.notificationForm.value.content,
      isAllCorporate: this.notificationForm.value.isAllCorporate,
      isForAllPolicy: this.notificationForm.value.isForAllPolicy,
      isForHR: this.notificationForm.value.isForHR,
      isForMember: this.notificationForm.value.isForMember,
      specificCorporate: this.notificationForm.value.specificCorporate,
    };
    const stringJson = JSON.stringify(Json);
    const data = new FormData();
    data.append('File', this.notificationForm.value.file[0]);
    data.append('Json', stringJson);
    // const data = this.notificationForm.getRawValue();
    this.notificationForm.get('file').setErrors({ invalid: true });
    await this.notificationService
      .createNotifications(data)
      .then(x => {
        this.notificationForm.get('file').setErrors(null);
        this.alertService.success('Notification created successfully');
        this.router.navigate(['/biz-admin/notification/list']);
      })
      .catch(errorData => {
        this.notificationForm.get('file').setErrors(null);
        this.errorR = errorData.error.errors;
        this.alertService.error('Error occured');
      });
  }

  onFileChange(file) {
    if (file.length > 0) {
      this.notificationForm.controls.file.setValue(file);
      const name = file[0].name;
      const size = file[0].size;
      this.fileName = name;
      if (this.fileName.length > 30) {
        this.fileName = this.fileName.slice(0, 25) + '...';
      }
      if (size > 5000000) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Maximum size must be 5 Mb';
        this.notificationForm.get('file').setErrors({ invalid: true });
      } else {
        this.isSizeValid = true;
        this.uploadImageInvalidMsg = '';
        this.notificationForm.get('file').setErrors(null);
      }
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf'];
      if (!fileExtensionArr.includes(ext.toLowerCase())) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Upload valid format file';
        this.notificationForm.get('file').setErrors({ invalid: true });
      }
    }
    console.log(this.notificationForm);
  }

  deleteFile() {
    (<HTMLInputElement>document.getElementById('file')).value = '';
    this.notificationForm.controls.file.setValue('');
    this.isSizeValid = true;
    this.uploadImageInvalidMsg = '';
    this.fileName = '';
  }

  setFormValue() {
    if (this.notificationForm.value.broadcast_to === 'All Corporates') {
      this.notificationForm.controls.isAllCorporate.setValue(true);
    } else {
      this.notificationForm.controls.isAllCorporate.setValue(false);
    }
    // this.notificationForm.controls.specificCorporate.setValue(Guid.Default);
    console.log(this.notificationForm);
  }

  setAllCorporates() {
    this.notificationForm.get('corporateCode').setValidators([]);
    this.notificationForm.get('corporateName').setValidators([]);
    this.notificationForm.get('corporateCode').updateValueAndValidity();
    this.notificationForm.get('corporateName').updateValueAndValidity();
    this.notificationForm.controls.corporateName.setValue('');
    this.notificationForm.controls.corporateCode.setValue('');
    this.notificationForm.controls.specificCorporate.setValue(0);
    this.corporates = [];
  }

  async getCorporates() {
    this.notificationForm.get('corporateCode').setValidators(Validators.required);
    this.notificationForm.get('corporateName').setValidators(Validators.required);
    this.notificationForm.get('corporateCode').updateValueAndValidity();
    this.notificationForm.get('corporateName').updateValueAndValidity();
    this.notificationService.getCorporates().then(x => {
      this.corporates = x;
    });
  }

  corporateChanged() {
    const corporateCode = this.notificationForm.value.corporateCode;
    const corporateDetail = this.corporates.find(corporate => corporate.code === corporateCode);
    this.notificationForm.controls.corporateName.setValue(corporateDetail.name);
    this.notificationForm.controls.specificCorporate.setValue(corporateDetail.id);
  }
}
