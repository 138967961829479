import { Component, OnInit, Input, Injector, EventEmitter, Output } from '@angular/core';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'src/app/shared/services/role.service';
import { BrokerService } from 'src/app/broker/service/broker.service';
import { CPPolicyService } from '../../services/cp-policy.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-policies-dropdown',
  templateUrl: './policies-dropdown.component.html',
  styleUrls: ['./policies-dropdown.component.scss'],
})
export class PoliciesDropdownComponent extends BaseComponent implements OnInit {
  @Input() heading = 'View';
  selectedPolicy: Policy = new Policy();
  allPolicies: Policy[] = [];
  policyNumber: any;
  @Input() hidePolicyDropdown = false;
  @Output() onChange:EventEmitter<any> = new EventEmitter();
  constructor(
    injector: Injector,
    private cpMemberService: CPMemberService,
    private readonly alertService: AlertService,
    private modalService: NgbModal,
    private roleService: RoleService,
    private brokerService: BrokerService,
    private policyService: CPPolicyService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.policyService.policies.subscribe(x => {
      this.allPolicies = x;
    });
    this.policyService.currentPolicy.subscribe(x => {
      if (x.policyId !== undefined) {
        this.selectedPolicy = x;
      }
    });
  }

  onChangePolicy(policy) {
    this.onChange.emit(null);
    this.selectedPolicy = policy;
    this.policyService.currentPolicy.next(this.selectedPolicy);
  }
}
