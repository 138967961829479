import { Component, OnInit, Input, Output, EventEmitter, Injector, OnDestroy } from '@angular/core';
import { CorporateModel } from '../../../shared/models/corporate/corporate.model';
import { CorporateService } from '../../services/corporate.service';
import { Router } from '@angular/router';
import { GeoService } from 'src/app/shared/services/geo.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AlertTypeEnum } from 'src/app/shared/enums/alert-type.enum';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { resolve } from 'url';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-create-corporate',
  templateUrl: './create-corporate.component.html',
  styleUrls: ['./create-corporate.component.scss'],
})
export class CreateCorporateComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() corporate: CorporateModel;
  @Output() corporateChange = new EventEmitter<CorporateModel>();

  firstTimeEnterName = false;
  firstTimeEnterCode = false;
  firstTimeEnterWebsiteURL = false;
  firstTimeEnterPrimaryName = false;
  firstTimeEnterPinCode = false;
  firstTimeEnterLandlineNum = false;
  firstTimeEnterMobileNum = false;
  firstTimeEnterEmail = false;

  invalidNameMsg = '';
  invalidCodeMsg = '';
  invalidContactNameMsg = '';
  invalidEmailMsg = '';
  editMode = false;
  workingCorporate: CorporateModel;
  searchText: string;
  areas = [];
  selectedStateIndex: number;
  errorR: any;
  isValidFormat = true;
  fileName: string;
  isEdit = false;
  isPincodeValid = true;
  isWebsiteUrlValid = true;
  isMobileNumberValid = true;
  isLandlineNumberValid = true;
  isRequiredNameNull = false;
  isRequiredCodeNull = false;
  isRequiredEmailNull = false;
  isRequiredContactNameNull = false;
  isPincodeNull = false;
  isSaveButtonEnable = true;
  isLocationSelected = false;
  invalidPinCodeMsg: string;
  uploadImageInvalidMsg: string;
  pinCodeAPIResponse: any;
  originalLogoImage: string;
  displayDeleteCorporateButton = true;
  deleteCorporateProcessing = false;

  corporateLogo: any;

  constructor(
    injector: Injector,
    private readonly corporateService: CorporateService,
    private readonly geoService: GeoService,
    private readonly alertService: AlertService,
    private readonly router: Router
  ) {
    super(injector);
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  async ngOnInit() {
    super.ngOnInit();

    if (!this.corporate) {
      this.isEdit = true;
      this.displayDeleteCorporateButton = false;
      this.firstTimeEnterName = true;
      this.firstTimeEnterCode = true;
      this.firstTimeEnterWebsiteURL = true;
      this.firstTimeEnterPinCode = true;
      this.firstTimeEnterPrimaryName = true;
      this.firstTimeEnterLandlineNum = true;
      this.firstTimeEnterMobileNum = true;
      this.firstTimeEnterEmail = true;

      this.isPincodeValid = false;
      this.isRequiredNameNull = true;
      this.isRequiredCodeNull = true;
      this.isRequiredContactNameNull = true;
      this.isPincodeNull = true;
      this.isRequiredEmailNull = true;

      this.workingCorporate = new CorporateModel();
    } else {
      console.log(this.corporate);
      this.editMode = true;
      this.isRequiredNameNull = false;
      this.isRequiredCodeNull = false;
      this.isRequiredContactNameNull = false;
      this.isPincodeNull = false;
      this.isRequiredEmailNull = false;
      this.isLocationSelected = true;
      this.workingCorporate = this.corporate;
      this.originalLogoImage = this.workingCorporate.logoImage;
      this.loadDataFromExistingCorporate();
    }
  }

  async loadDataFromExistingCorporate() {
    const pinCodeAPIResp = await this.geoService.getCompleteAddViaPincode(this.workingCorporate.pinCode);
    pinCodeAPIResp['areas'].forEach(area => {
      this.areas.push(area.areaName);
      if (area.areaName.trim() === this.workingCorporate.area.trim()) {
        this.workingCorporate.area = area['areaName'];
      }
    });
  }

  async onAreaChanged(event) {
    this.isLocationSelected = true;
    const chosenArea = this.pinCodeAPIResponse['areas'].find(
      searchedArea => searchedArea.areaName === this.workingCorporate.area
    );
    this.workingCorporate.areaId = chosenArea.id;
  }

  uploadFiles(file: any) {
    if (!this.validateFile(file)) {
      this.isValidFormat = false;
      this.workingCorporate.logoImage = '';
      this.fileName = '';
      (<HTMLInputElement>document.getElementById('file')).value = '';
      return false;
    }
    this.fileName = file[0].name;
    this.isValidFormat = true;
    this.corporateLogo = file[0];
    const fileReader = new FileReader();
    const self = this;
    fileReader.readAsDataURL(file[0]);
    fileReader.onload = function() {
      const base64EncodedFile = fileReader.result;
      self.workingCorporate.logoImage = base64EncodedFile;
    };
    fileReader.onerror = function(error) {};
  }
  validateFile(file) {
    const name = file[0].name;
    const size = file[0].size;
    let isSizeValid: boolean;
    if (size > 500000) {
      isSizeValid = false;
      this.uploadImageInvalidMsg = 'Max size must be 500 Kb';
    } else {
      isSizeValid = true;
    }
    const ext = name.substring(name.lastIndexOf('.') + 1);
    const fileExtensionArr = ['jpg', 'jpeg', 'bmp', 'gif', 'png'];
    if (!fileExtensionArr.includes(ext.toLowerCase())) {
      this.uploadImageInvalidMsg = 'Upload valid format file';
    }
    return fileExtensionArr.includes(ext.toLowerCase()) && isSizeValid;
  }

  removeSelectedFile() {
    this.workingCorporate.logoImage = '';
    this.fileName = '';
  }

  async save() {
    if (this.workingCorporate.id) {
      this.workingCorporate.isFileChanged = this.originalLogoImage === this.workingCorporate.logoImage ? false : true;
    }
    this.isSaveButtonEnable = false;
    const stringJson = JSON.stringify(this.workingCorporate);
    const data = new FormData();
    data.append('File', this.corporateLogo);
    data.append('Json', stringJson);

    if (this.workingCorporate.id) {
      this.corporateService
        .update(this.workingCorporate.id, data)
        .then(x => {
          this.corporateChange.emit(this.workingCorporate);
          this.alertService.success('Corporate details updated successfully');
          this.isSaveButtonEnable = true;
          this.router.navigate(['/biz-admin/corporate/' + x.id]);
        })
        .catch(errorData => {
          this.errorR = errorData.error.errors;
          this.alertService.error('Error occured');
          this.isSaveButtonEnable = true;
        });
    } else {
      this.corporateService
        .create(data)
        .then(x => {
          this.alertService.success('Created corporate successfully');
          this.isSaveButtonEnable = true;
          this.router.navigate(['/biz-admin/corporate/' + x.id]);
        })
        .catch(errorData => {
          this.errorR = errorData.error.errors;
          this.alertService.error('Error occured');
          this.isSaveButtonEnable = true;
        });
    }
  }

  edit() {
    this.editMode = true;
    this.isEdit = true;
  }

  goBack() {
    this.router.navigate(['/biz-admin/corporate/list']);
  }

  cancel() {
    if (this.corporate) {
      this.isEdit = false;
      this.workingCorporate = this.corporate;
      this.isPincodeValid = true;
      this.isMobileNumberValid = true;
      this.isLandlineNumberValid = true;
      this.isWebsiteUrlValid = true;
      this.isRequiredNameNull = false;
      this.isRequiredCodeNull = false;
      this.isRequiredContactNameNull = false;
      this.isPincodeNull = false;
      this.isRequiredEmailNull = false;
      this.isLocationSelected = true;
      this.originalLogoImage = this.workingCorporate.logoImage;
      this.loadDataFromExistingCorporate();
    } else {
      this.router.navigate(['/biz-admin/corporate/list']);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  async validatePincode(event) {
    this.firstTimeEnterPinCode = false;
    const pinCode = event.target.value;
    const pincodeLength = pinCode.length;
    this.workingCorporate.pinCode = pinCode;
    this.workingCorporate.state = null;
    this.workingCorporate.city = null;
    this.workingCorporate.district = null;
    this.isLocationSelected = false;
    this.areas = [];
    this.pinCodeAPIResponse = null;
    if (pincodeLength === 0) {
      this.isPincodeNull = true;
      this.invalidPinCodeMsg = 'PinCode cannot be null!!';
    } else {
      this.isPincodeNull = false;
      if (pincodeLength !== 6 || pinCode.match(/^[1-9][0-9]{5}$/) === null) {
        this.isPincodeValid = false;
        this.invalidPinCodeMsg = 'Enter Valid 6 digits Pincode';
      } else {
        this.isPincodeValid = true;
        const pinCodeAPIResp = await this.geoService.getCompleteAddViaPincode(pinCode);
        this.pinCodeAPIResponse = pinCodeAPIResp;
        if (pinCodeAPIResp['pinCode'] === null) {
          this.isPincodeValid = false;
          this.invalidPinCodeMsg = 'Pincode not found!!';
        } else {
          this.workingCorporate.city = pinCodeAPIResp['city']['cityOrVillageCode'];
          this.workingCorporate.district = pinCodeAPIResp['district']['districtName'];
          this.workingCorporate.state = pinCodeAPIResp['state']['stateName'];
          this.workingCorporate.stateId = pinCodeAPIResp['state']['id'];
          this.workingCorporate.cityId = pinCodeAPIResp['city']['id'];
          this.workingCorporate.districtId = pinCodeAPIResp['district']['id'];
          pinCodeAPIResp['areas'].forEach(area => {
            this.areas.push(area.areaName);
          });
          this.workingCorporate.area = this.areas[0];
          const chosenArea = pinCodeAPIResp['areas'].find(
            searchedArea => searchedArea.areaName === this.workingCorporate.area
          );
          this.workingCorporate.areaId = chosenArea.id;
          this.isLocationSelected = true;
        }
      }
    }
  }

  validateMobileNumber(event) {
    this.firstTimeEnterMobileNum = false;
    const mobileNumber = event.target.value;
    const mobileNumberLength = mobileNumber.length;
    this.workingCorporate.mobileNumber = mobileNumber;
    if (mobileNumberLength === 0) {
      this.isMobileNumberValid = true;
    } else {
      if (mobileNumberLength !== 10 || mobileNumber.match(/^[6-9][0-9]{9}$/) === null) {
        this.isMobileNumberValid = false;
      } else {
        this.isMobileNumberValid = true;
      }
    }
  }

  validateLandlineNumber(event) {
    this.firstTimeEnterLandlineNum = false;
    const landlineNumber = event.target.value;
    const landlineNumberLength = landlineNumber.length;
    if (landlineNumberLength === 0) {
      this.workingCorporate.landlineNumber = landlineNumber;
      this.isLandlineNumberValid = true;
    } else {
      if (
        landlineNumberLength !== 11 ||
        landlineNumber.match(/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/) === null
      ) {
        this.isLandlineNumberValid = false;
      } else {
        this.workingCorporate.landlineNumber = landlineNumber;
        this.isLandlineNumberValid = true;
      }
    }
  }

  isNameNull(event) {
    this.firstTimeEnterName = false;
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.isRequiredNameNull = true;
      this.invalidNameMsg = 'Name cannot be null!!!';
    } else {
      this.isRequiredNameNull = false;
      this.workingCorporate.name = fieldValue;
    }
  }

  isCodeNull(event) {
    this.firstTimeEnterCode = false;
    const fieldValue = event.target.value;
    if (fieldValue.length === 0 || fieldValue.length === 1) {
      this.isRequiredCodeNull = true;
      this.invalidCodeMsg = fieldValue.length === 0 ? 'Code cannot be null' : 'Minimum length must be 2 characters';
    } else if (fieldValue.match(/^[a-z0-9]+$/i) === null) {
      this.isRequiredCodeNull = true;
      this.invalidCodeMsg = 'Please enter valid code!!';
    } else {
      this.isRequiredCodeNull = false;
    }
  }

  isContactNameNull(event) {
    this.firstTimeEnterPrimaryName = false;
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.isRequiredContactNameNull = true;
      this.invalidContactNameMsg = 'Name cannot be null!!!';
    } else if (fieldValue.match(/^[a-z. ]+$/i) === null) {
      this.isRequiredContactNameNull = true;
      this.invalidContactNameMsg = 'Please enter valid name!!';
    } else {
      this.isRequiredContactNameNull = false;
      this.workingCorporate.contactName = fieldValue;
    }
  }

  validateWebsiteURL(event) {
    this.firstTimeEnterWebsiteURL = false;
    const WebsiteURL = event.target.value;
    if (WebsiteURL.length === 0) {
      this.workingCorporate.website = WebsiteURL;
      this.isWebsiteUrlValid = true;
    } else {
      if (
        WebsiteURL.match(
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        ) === null
      ) {
        this.isWebsiteUrlValid = false;
      } else {
        this.workingCorporate.website = WebsiteURL;
        this.isWebsiteUrlValid = true;
      }
    }
  }

  isEmailNull(event) {
    this.firstTimeEnterEmail = false;
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.isRequiredEmailNull = true;
      this.invalidEmailMsg = 'Email cannot be null!!!';
    } else if (fieldValue.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i) === null) {
      this.isRequiredEmailNull = true;
      this.invalidEmailMsg = 'Please enter valid email!!';
    } else {
      this.isRequiredEmailNull = false;
      this.workingCorporate.email = fieldValue;
    }
  }

  numberOnly(event): boolean {
    if (event.target.value.length > 5) {
      return false;
    }
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async deleteCorporate() {
    let result;
    const message = `Are you sure you want to delete ${this.corporate.name} corporate?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.deleteCorporateProcessing = true;
          await this.corporateService.deleteCorporate(this.corporate.id);
          this.alertService.success(`Corporate ${this.corporate.name} Deleted successfully`);
          this.router.navigate(['/biz-admin/corporate/list']);
        } catch (errorData) {
          this.errorR = errorData.error.errors;
          this.alertService.error('Error Occured');
        } finally {
          this.deleteCorporateProcessing = false;
        }
      }
    });
  }
}
