import { Component, OnInit, Injector } from '@angular/core';
import { Hr } from 'src/app/shared/models/CorporatePortal/hr.model';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { BrokerService } from 'src/app/broker/service/broker.service';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { Broker } from 'src/app/shared/models/CorporatePortal/broker.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { RoleService } from 'src/app/shared/services/role.service';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { CorporatePortalBaseComponent } from '../corporate-portal-base/corporate-portal-base.component';
import { Router } from '@angular/router';
import { Sm } from 'src/app/shared/models/CorporatePortal/sm.model';
import { SMService } from 'src/app/sm/service/sm.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent extends CorporatePortalBaseComponent implements OnInit {
  brokerPrivileges: Broker = new Broker();

  // SM
  smPrivileges: Sm = new Sm();

  isHr: boolean;
  isMember: boolean;
  hr: Hr = new Hr();

  isEmployeeBelongsToPolicy = false;

  constructor(
    injector: Injector,
    policyService: CPPolicyService,
    authService: AuthService,
    private router: Router,
    private smService: SMService,
    private brokerService: BrokerService,
    private cpMemberService: CPMemberService
  ) {
    super(injector, policyService, authService);
  }

  async ngOnInit() {
    this.cpMemberService.hrDetails.subscribe(x => {
      this.hr = x;
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      if (this.role === Roles.CPHR) {
        this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;
        this.isHr = this.cpMemberService.isHr;
        this.isMember = this.cpMemberService.isMember;
      }
      // Broker Role
      if (this.role === Roles.Broker) {
        this.brokerPrivileges = this.brokerService.brokerPrivileges;
      }
      // SM Role
      if (this.role === Roles.SM) {
        this.smService.getSMByPolicy(policy.policyId).then(xx => {
          this.smPrivileges = xx;
        });
      }
    });
  }

  onNamePolicyChanged(policy) {
    this.policyService.currentPolicy.next(policy);
  }
}
