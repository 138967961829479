<div class="endorsement-component-wrapper">
  <div class="endorsement-component">
    <div class="header-btn-wrap" *ngIf="isHrView && hr !== null && hr.endoresment">
      <div>
        <button class="btn btn-sm btn-primary ml-2" (click)="OpenCreateEmployeeComponent('addMemberByHr')">
          Add Employee
        </button>

        <button class="shimmer-button" *ngIf="!dependentCountFound">
          <span class="shimmer-wrapper">
            <span class="shimmer primary"></span>
          </span>
        </button>

        <button class="btn btn-sm btn-primary ml-2" *ngIf="dependentCountFound"
          (click)="GetDependents('addDependentByHr')">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
            *ngIf="!isDependentEndorsementsLoaded"></span>
          Total Dependent Added By Members
          <span class="badge badge-light">{{ dependentEndorsementsByMemberCount }}</span>
        </button>
      </div>
    </div>

    <!-- POLICY DROPDOWN -->
    <div class="card card-style-1" *ngIf="!isHrView && currentPolicy.hasOwnProperty('policyId')">
      <div class="card-body pb-0">
        <div class="card-title">
          <h4 class="m-0 header-title">Endorsement</h4>
        </div>
        <div class="mt-3 px-3">
          <app-policies-dropdown heading="Member Endorsement" class="w-100"> </app-policies-dropdown>
        </div>

        <div class="header-btn-wrap">
          <div *ngIf="
              currentPolicy !== undefined && currentPolicy.additionOfDependents && currentPolicy.isActive && isMember
            ">
            <button class="btn btn-sm btn-primary mr-1 mt-3"
              (click)="OpenCreateEmployeeComponent('addDependentByMember')" [disabled]="!isMemberDetailsLoaded">
              Add Dependent
            </button>
          </div>
        </div>
        <div class="table-wrapper"
          *ngIf="!isHrView && currentPolicy !== undefined && currentPolicy.additionOfDependents">
          <app-dependent-endorsement [policy]="currentPolicy" [loadDetails]="isLoadDetails"></app-dependent-endorsement>
        </div>
      </div>
    </div>

    <!-- POLICY DROPDOWN -->
    <div class="header-tabs" *ngIf="isHrView && hr !== null && hr.endoresment">
      <nav>
        <ul class="nav">
          <li class="nav-item">
            <a class="nav-link active show" id="Member-endorsement-tab" data-toggle="tab" href="#Member-endorsement"
              role="tab" aria-controls="Member-endorsement" aria-selected="true">
              Member
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="Dependent-endorsement-tab" data-toggle="tab" href="#Dependent-endorsement"
              role="tab" aria-controls="Dependent-endorsement" aria-selected="false">
              Dependent
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="Endorsement-by-member-tab" data-toggle="tab" href="#Endorsement-by-member"
              role="tab" aria-controls="Endorsement-by-member" aria-selected="false">
              Approve
            </a>
          </li>
          <li class="right-head">
            <div class="dropdown card-header-dropdown">
              <button class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="true">
                <span *ngIf="!currentPolicy.hasOwnProperty('policyId')">
                  <span class="shimmer-wrapper">
                    <span class="shimmer primary"></span>
                  </span>
                </span>
                <span *ngIf="currentPolicy.hasOwnProperty('policyId')">
                  <span *ngIf="currentPolicy.isActive" class="logged"></span>
                  <span *ngIf="!currentPolicy.isActive" class="notLogged"></span>
                  <span *ngIf="currentPolicy.riskStartDate !== undefined && currentPolicy.onScreenDisplayAvailable == false">
                    {{ currentPolicy.riskStartDate.split('-')[0] }}-{{ currentPolicy.riskEndDate.split('-')[0] }} -
                    {{ currentPolicy.policyNumber }} - {{currentPolicy.policyType}}</span>
                  <span *ngIf="currentPolicy.riskStartDate !== undefined && currentPolicy.onScreenDisplayAvailable == true">{{currentPolicy.onScreenDisplayPolicy}}</span>
                </span>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <div *ngFor="let policy of policies" (click)="onNamePolicyChanged(policy)">
                  <a class="dropdown-item">
                    <span *ngIf="policy.isActive" class="logged"></span>
                    <span *ngIf="!policy.isActive" class="notLogged"></span>
                    <span *ngIf="policy.onScreenDisplayAvailable == false">
                      {{ policy.riskStartDate.split('-')[0] }}-{{ policy.riskEndDate.split('-')[0] }} -
                      {{ policy.policyNumber }} - {{policy.policyType}}</span>
                    <span *ngIf="policy.onScreenDisplayAvailable == true">{{policy.onScreenDisplayPolicy}}</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <div class="tab-content" *ngIf="isHrView && hr !== null && hr.endoresment">
      <div class="tab-pane active" id="Member-endorsement" role="tabpanel">
        <app-member-endorsement [policy]="currentPolicy" [loadDetails]="isLoadDetails"></app-member-endorsement>
      </div>

      <div class="tab-pane" id="Dependent-endorsement" role="tabpanel">
        <app-dependent-endorsement [policy]="currentPolicy"></app-dependent-endorsement>
      </div>

      <div class="tab-pane" id="Endorsement-by-member" role="tabpanel">
        <app-endorsement-by-member [policy]="currentPolicy"></app-endorsement-by-member>
      </div>
    </div>   
  </div>
</div>

<div *ngIf="
    ((!isHrView && currentPolicy !== undefined && !currentPolicy.additionOfDependents) ||
      (isHrView && hr !== null && !hr.endoresment && hr.hasOwnProperty('hrEmpId'))) &&
    currentPolicy.hasOwnProperty('policyId')
  " class="no-result-wrapper">
  <h5>This feature not Allowed for selected policy.</h5>
</div>