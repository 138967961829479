<div class="confirm-modal-wrapper">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
      </div>
      <div class="modal-body">
        <div class="py-2 px-3 mb-0">
          <h6>{{ message }}</h6>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary px-4" (click)="onDismiss()">{{ text }}</button>
      </div>
    </div>
  </div>
</div>
