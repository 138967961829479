import { Component, OnInit, Input, Inject, Injector, SimpleChanges, OnChanges } from '@angular/core';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import '@angular/compiler';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-corporate-portal-policy-details',
  templateUrl: './corporate-portal-policy-details.component.html',
  styleUrls: ['./corporate-portal-policy-details.component.scss'],
})
export class CorporatePortalPolicyDetailsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() corporate: CorporateModel;
  @Input() policyNumber: any;
  disableDetachButton = false;
  policy: any;
  periodTypes = ['Week', 'Month', 'Year'];
  periodValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  policyData: any;
  isLoading = false;
  policyForm: FormGroup;
  canEdit = false;
  errorList = [];
  disableSaveButton = false;

  constructor(
    private fb: FormBuilder,
    injector: Injector,
    private readonly alertService: AlertService,
    private readonly corporatePortalService: CorporatePortalService,
    private readonly router: Router
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.policyNumber !== 'Attach Policy') {
      this.createpolicyForm();
      this.getPolicy();
    }
  }

  createpolicyForm() {
    this.policyForm = this.fb.group({
      policyNumber: [''],
      insuredName: [''],
      riskStartDate: [''],
      riskEndDate: [''],
      issueDate: [''],
      gracePeriodType: [''],
      gracePeriodValue: [0],
      corporate: [''],
      key: [''],
      id: [''],
      corporateId: [''],
      claimSpoc:[false],
      claimSpocMailId: ['',[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      onscreenDisplay: ['', [Validators.maxLength(30), Validators.pattern('^[a-zA-Z0-9]+[a-zA-Z0-9\-_()]*( [a-zA-Z0-9\-_()]+)*$')]]
    });
  }

  async getPolicy() {
    this.policyData = await this.corporatePortalService.getPolicyByNumber(this.policy.policyNumber);
    if (this.policyData !== null) {
      this.policyForm.patchValue({
        policyNumber: this.policyData.policyNumber,
        insuredName: this.policyData.insuredName,
        riskStartDate: new Date(this.policyData.riskStartDate),
        riskEndDate: new Date(this.policyData.riskEndDate),
        issueDate: new Date(this.policyData.issueDate),
        gracePeriodType: this.policyData.gracePeriodType,
        gracePeriodValue: this.policyData.gracePeriodValue,
        key: this.policyData.key,
        id: this.policyData.id,
        claimSpoc:this.policyData.claimSpoc,
        claimSpocMailId:this.policyData.claimSpocMailId,
        corporateId: this.corporate.id,
        corporate: null,
        onscreenDisplay: this.policyData.onScreenDisplay,
      });
    }
    this.policyForm.disable();
  }

  edit() {
    this.canEdit = true;
    this.policyForm.get('gracePeriodType').enable();
    if (this.policyForm.get('gracePeriodValue').value === 0) {
      this.policyForm.get('gracePeriodValue').disable();
    } else {
      this.policyForm.get('gracePeriodValue').enable();
    }
    this.policyForm.get('claimSpoc').enable();
    this.policyForm.get('claimSpocMailId').enable();
    this.policyForm.get('onscreenDisplay').enable();
  }

  cancel() {
    this.canEdit = false;
    this.errorList = [];
    this.policyForm.patchValue({
      gracePeriodType: this.policyData.gracePeriodType,
      gracePeriodValue: this.policyData.gracePeriodValue,
      claimSpoc:this.policyData.claimSpoc,
      claimSpocMailId:this.policyData.claimSpocMailId,
    });
    this.policyForm.disable();
  }

  save() {
    console.log(this.policyForm);
    this.disableSaveButton = true;
    const isClaimSpoc:boolean=this.policyForm.get('claimSpoc').value;
    if(!isClaimSpoc)
    {
      this.policyForm.patchValue({
        claimSpocMailId:null,
      });
  }
    const data = this.policyForm.getRawValue();
    const id = this.policyForm.get('id').value;
    this.corporatePortalService
      .updatePolicy(id, data)
      .then(x => {
        this.alertService.success('Policy updated successfully');
        this.disableSaveButton = false;
        this.canEdit = false;
        this.getPolicy();
      })
      .catch(errorData => {
        this.errorList = errorData.error.errors;
        this.disableSaveButton = false;
        this.alertService.error('Error occured');
      });
  }

  onScreenDisplayNameEntered(){
    if(this.policyForm.controls['onscreenDisplay'].errors?.pattern || this.policyForm.controls['onscreenDisplay'].errors?.maxlength){
      this.disableSaveButton = true;
    }else{
      this.disableSaveButton = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.corporatePortalService.updatedPolicies.subscribe((policyList: any[]) => {
      this.policy = policyList.find(policy => policy.policyNumber === this.policyNumber);
    });
    this.canEdit = false;
    if (changes['policyNumber'] !== undefined && changes['corporate'] === undefined) {
      if (this.policyNumber !== 'Attach Policy') {
        this.isLoading = true;
        this.getPolicy();
        this.isLoading = false;
      }
    }
  }

  periodTypeChanged() {
    if (this.policyForm.get('gracePeriodValue').value === 0) {
      this.policyForm.patchValue({
        gracePeriodValue: null,
      });
      this.policyForm.get('gracePeriodValue').enable();
      this.policyForm.get('gracePeriodValue').setValidators(Validators.required);
      this.policyForm.get('gracePeriodValue').updateValueAndValidity();
    }
  }

  detachPolicy() {
    let result;
    const message = `Are you sure you want to detach policy?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.disableDetachButton = true;
          this.corporatePortalService
            .detachPolicy(this.policy.id)
            .then(x => {
              this.alertService.success('Policy detached successfully');
              this.disableDetachButton = false;
              this.router.navigate(['/biz-admin/corporate/' + this.corporate.id]);
            })
            .catch(error => {
              this.alertService.success('Error occured');
              this.disableDetachButton = false;
            });
        } catch (error) {
          this.alertService.error('Error occured');
          this.disableDetachButton = false;
        }
      }
    });
  }
}
