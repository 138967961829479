import { Component, OnInit, Injector, Input, SimpleChanges, OnChanges } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { NotificationService } from 'src/app/business-admin/services/notification.service';
import { CreateHrComponent } from './create-hr/create-hr.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { ListHr } from 'src/app/shared/models/CorporatePortal/list-hr.model';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ErrorMsgDialog } from 'src/app/shared/components/error-msg-dialog/ErrorMsgDialog';
import { ErrorMsgDialogComponent } from 'src/app/shared/components/error-msg-dialog/error-msg-dialog.component';

@Component({
  selector: 'app-list-hr',
  templateUrl: './list-hr.component.html',
  styleUrls: ['./list-hr.component.scss'],
})
export class ListHrComponent extends BaseComponent implements OnInit {
  @Input() corporate: CorporateModel;
  @Input() policyNumber: any;
  policy: any;
  searchText: string;
  isLoading: boolean;
  page = 1;
  pageSize = 5;
  hrList: ListHr[] = [];
  hrProperty = 'hrProp';
  errorMsg: string[] = [];
  isUsernamePasswordTypeNotNull = false;
  modalOption: NgbModalOptions = {};

  constructor(
    injector: Injector,
    private readonly alertService: AlertService,
    private readonly corporatePortalService: CorporatePortalService,
    private modalService: NgbModal
  ) {
    super(injector);
  }

  changePage() {
    this.page = 1;
  }

  open() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(CreateHrComponent, this.modalOption);
    modalRef.componentInstance.corporate = this.corporate;
    modalRef.componentInstance.policy = this.policy;
    modalRef.componentInstance.hrAddedSucessfully.subscribe(isHrAdded => {
      if (isHrAdded === true) {
        this.isLoading = true;
        this.getHr();
        this.isLoading = false;
      }
    });
  }

  ngOnInit() {
    this.corporatePortalService.updatedPolicies.subscribe((policyList: any[]) => {
      this.policy = policyList.find(policy => policy.policyNumber === this.policyNumber);
    });
    this.isLoading = true;
    this.isUsernamePasswordTypeNotNull =
      this.policy.userNameType !== null && this.policy.passwordType !== null ? true : false;
    this.getHr();
    this.isLoading = false;
  }

  async getHr() {
    const response = await this.corporatePortalService.getHr(this.corporate.id);
    if (response !== null) {
      this.hrList = response;
    }
  }

  edit(hr) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(CreateHrComponent, this.modalOption);
    modalRef.componentInstance.corporate = this.corporate;
    modalRef.componentInstance.policy = this.policy;
    modalRef.componentInstance.hrAddedSucessfully.subscribe(isHrAdded => {
      if (isHrAdded === true) {
        this.isLoading = true;
        this.getHr();
        this.isLoading = false;
      }
    });
    modalRef.componentInstance.hrDetails = hr;
  }

  async shareCredentials(hr) {
    let result;
    const message = `Are you sure you want to share credentials?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        await this.corporatePortalService
          .sendCredentialsToMemberById(this.policy.id, hr.id)
          .then(x => {
            console.log(x);
            this.alertService.success('Credentials shared successfully');
            this.getHr();
          })
          .catch(errorData => {
            const errorList = errorData.error.errors;
            this.openErrorModal(errorList);
          });
      }
    });
  }

  openErrorModal(errorList) {
    this.errorMsg = [];
    errorList.forEach(errorDescription => {
      this.errorMsg.push(errorDescription.message);
    });

    const dialogData = new ErrorMsgDialog(this.errorMsg);

    this.dialog.open(ErrorMsgDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  async removeHR(hrId) {
    let result;
    const message = `Are you sure you want to delete?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.isLoading = true;
          await this.corporatePortalService
            .deleteHR(hrId)
            .then(x => {
              this.isLoading = true;
              this.getHr();
              this.isLoading = false;
              this.alertService.success('HR deleted successfully');
            })
            .catch(errorData => {
              const errorList = errorData.error.errors;
              this.openErrorModal(errorList);
            });
          this.isLoading = false;
        } catch (error) {
          this.alertService.error('Error occured');
          this.isLoading = false;
        }
      }
    });
  }
}
