<div class="pre-enrollment-component-wrapper">
  <div class="sidenav">
    <div class="sidenav-row">
      <button class="dropdownbtnsettings" id="SETTINGS" (click)="setCurrentScreen('SETTINGS', '')">
        SETTINGS
      </button>
    </div>
    <div class="sidenav-row">
      <button class="dropdown-btn">
        CONFIGURE EMAIL
      </button>
      <div class="dropdown-container">
        <a id="WELCOME" class="dropdown-item" (click)="setCurrentScreen('CONFIGURE EMAIL', 'WELCOME')">WELCOME EMAIL</a>
        <a id="REGISTRATION" class="dropdown-item" (click)="setCurrentScreen('CONFIGURE EMAIL', 'REGISTRATION')"
          >FINAL SUBMISSION EMAIL</a
        >
        <a id="REMINDER" class="dropdown-item" (click)="setCurrentScreen('CONFIGURE EMAIL', 'REMINDER')"
          >REMINDER EMAIL</a
        >
      </div>
    </div>
  </div>
  <div class="w-100">
    <div class="tab-pane active" *ngIf="selectedOption == 'SETTINGS'" id="Settings" role="tabpanel">
      <app-create-pre-enrollment [policy]="policy" [corporate]="corporate"></app-create-pre-enrollment>
    </div>
    <div
      class="tab-pane"
      *ngIf="selectedOption == 'CONFIGURE EMAIL' && emailType == 'WELCOME'"
      id="Configure-email"
      role="tabpanel"
    >
      <app-configure-welcome-email [policy]="policy" [corporate]="corporate"></app-configure-welcome-email>
    </div>
    <div
      class="tab-pane"
      *ngIf="selectedOption == 'CONFIGURE EMAIL' && emailType == 'REGISTRATION'"
      id="Configure-email"
      role="tabpanel"
    >
      <app-configure-email [policy]="policy" [corporate]="corporate"></app-configure-email>
    </div>
    <div
      class="tab-pane"
      *ngIf="selectedOption == 'CONFIGURE EMAIL' && emailType == 'REMINDER'"
      id="Configure-email"
      role="tabpanel"
    >
      <app-configure-reminder-email [policy]="policy" [corporate]="corporate"></app-configure-reminder-email>
    </div>
  </div>
</div>
