import { EsiValues } from './esi-values.model';
import { PeExtraBenefits } from './pe-extra-benefits.model';

export class EnhancedSumInsured {
  peExtraBenefits: PeExtraBenefits = new PeExtraBenefits();
  peEsi: EsiValues[] = [new EsiValues(null)];

  constructor(model) {
    Object.assign(this, model);
  }

  public isValid() {
    if (this.peExtraBenefits.sumInsuredType === 'EqualToSumInsured' || !this.peExtraBenefits.isAllowed) {
      this.peEsi = [new EsiValues(null)];
      return true;
    }

    if (this.peExtraBenefits.sumInsuredType === 'Defined') {
      const insuredValues = this.peEsi.map(el => {
        return el.stringValue;
      });
      const formattedValues = insuredValues.map(el => {
        return el.replace(/,/g, '');
      });
      const checkValues = this.checkIfDuplicateExists(formattedValues);

      if (checkValues) {
        this.peExtraBenefits.isValueRepeated = true;
        return false;
      } else {
        this.peExtraBenefits.isValueRepeated = false;
      }
    }
    if (this.peExtraBenefits.sumInsuredType === 'GradeWiseMultiples') {
      const insuredValues = this.peEsi.map(el => {
        return el.grade.toLowerCase();
      });
      const formattedValues = insuredValues.map(el => {
        return el.replace(/,/g, '');
      });
      const checkValues = this.checkIfDuplicateExists(formattedValues);

      if (checkValues) {
        this.peExtraBenefits.isGradeWiseMultipleRepeated = true;
        return false;
      } else {
        this.peExtraBenefits.isGradeWiseMultipleRepeated = false;
      }
    }
    if (this.peExtraBenefits.sumInsuredType === 'GradeWiseDefined') {
      const insuredValues = this.peEsi.map(el => {
        return el.grade.toLowerCase() + el.stringValue.toString();
      });
      const formattedValues = insuredValues.map(el => {
        return el.replace(/,/g, '');
      });
      const checkValues = this.checkIfDuplicateExists(formattedValues);

      if (checkValues) {
        this.peExtraBenefits.isGradeWiseDefinedRepeated = true;
        return false;
      } else {
        this.peExtraBenefits.isGradeWiseDefinedRepeated = false;
      }
    }
    let proceed = true;
    for (let index = 0; index < this.peEsi.length; index++) {
      const element = new EsiValues(this.peEsi[index]);
      this.peEsi[index] = element;
      this.peEsi[index].isValid(this.peExtraBenefits.sumInsuredType);
      proceed = proceed && element.isValid(this.peExtraBenefits.sumInsuredType);
    }
    return proceed;
  }

  checkIfDuplicateExists(sumInsured) {
    return new Set(sumInsured).size !== sumInsured.length;
  }
}
