<div class="endorsement-wrapper">
  <div class="card-body pb-0">
    <app-policies-dropdown [heading]="heading" class="w-100"></app-policies-dropdown>

    <div
      *ngIf="
        (brokerPrivileges !== undefined &&
          !brokerPrivileges.endoresment &&
          role === 'Broker' &&
          brokerPrivileges.hasOwnProperty('code')) ||
        (smPrivileges !== undefined &&
          !smPrivileges.endoresment &&
          role === 'SM' &&
          smPrivileges.hasOwnProperty('code') &&
          policy.hasOwnProperty('policyId'))
      "
      class="no-result-wrapper"
    >
      <h5>This feature not Allowed for selected policy.</h5>
    </div>
    <div
      class="mt-3 header-filters"
      *ngIf="
        (brokerPrivileges !== undefined && brokerPrivileges.endoresment && role === 'Broker') ||
        (smPrivileges !== undefined && smPrivileges.endoresment && role === 'SM')
      "
    >
      <div>
        <input
          type="text"
          placeholder="Search Endorsement"
          class="search-input form-control"
          [(ngModel)]="searchText"
        />
      </div>
      <div>
        <button
          class="btn btn-primary mr-3"
          [disabled]="
            ((brokerPrivileges !== undefined &&
              brokerPrivileges.endoresment &&
              role === 'Broker' &&
              brokerPrivileges.hasOwnProperty('code')) ||
              (smPrivileges !== undefined &&
                smPrivileges.endoresment &&
                role === 'SM' &&
                smPrivileges.hasOwnProperty('code'))) &&
            endorsements.length === 0 &&
            isEndorsementLoaded
          "
          (click)="bulkApproveReject()"
        >
          Bulk Approve/Reject
        </button>
        <button class="btn btn-primary" (click)="exportExcel()">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            *ngIf="isEndorsementSampleLoaded"
          ></span>
          Download Excel
        </button>
      </div>
    </div>
    <div class="table-wrapper">
      <table>
        <tbody
          *ngIf="
            (brokerPrivileges !== undefined &&
              brokerPrivileges.endoresment &&
              role === 'Broker' &&
              brokerPrivileges.hasOwnProperty('code')) ||
            (smPrivileges !== undefined &&
              smPrivileges.endoresment &&
              role === 'SM' &&
              smPrivileges.hasOwnProperty('code') &&
              policy.hasOwnProperty('policyId'))
          "
        >
          <tr>
            <th>Insured Name</th>
            <th>UHID</th>
            <th>Relationship</th>
            <th>Employee Id</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Grade</th>
            <th>Endorsement Type</th>
            <th>Status</th>
            <th>
              <div class="float-right">Action</div>
            </th>
            <!-- Action - approve/reject -->
          </tr>
        </tbody>
        <tbody [hidden]="isEndorsementLoaded">
          <tr>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper float-right">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper float-right">
                <div class="shimmer tertiary"></div>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
        <tbody
          *ngIf="endorsements | searchFilter: searchText:endorsementProperty as result"
          [hidden]="!isEndorsementLoaded"
        >
          <tr *ngFor="let endorsement of result | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
            <td>{{ endorsement.insuredName }}</td>
            <td>{{ endorsement.uhid }}</td>
            <td>{{ endorsement.relationship }}</td>
            <td>{{ endorsement.employeeId }}</td>
            <td>{{ endorsement.gender }}</td>
            <td>{{ endorsement.email }}</td>
            <td>{{ endorsement.grade }}</td>
            <td>{{ endorsement.endorsementType }}</td>
            <td>
              <div class="mb-1 status-wrap">
                <div class="badge badge-green" *ngIf="endorsement.isApproved">
                  {{ endorsement.status }}
                </div>
                <div class="badge badge-red" *ngIf="endorsement.isRejected">
                  {{ endorsement.status }}
                </div>
                <div class="badge badge-orange" *ngIf="!endorsement.isRejected && !endorsement.isApproved">
                  {{ endorsement.status }}
                </div>
                <div class="remark" *ngIf="endorsement.remark !== '' && endorsement.remark !== null">
                  {{ endorsement.remark }}
                </div>
              </div>
            </td>
            <td>
              <div class="position-relative">
                <button
                  type="button"
                  class="btn btn-link float-right dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="material-icons">more_vert</i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" *ngIf="role === 'Broker'">
                  <button
                    class="dropdown-item"
                    [disabled]="
                      endorsement.approveRejectFlowModel != null &&
                      (endorsement.approveRejectFlowModel.broker.isApproved || endorsement.isRejected)
                    "
                    (click)="approveorreject(endorsement)"
                  >
                    Approve/Reject
                  </button>
                </div>
                <div class="dropdown-menu dropdown-menu-right" *ngIf="role === 'SM'">
                  <button
                    class="dropdown-item"
                    [disabled]="
                      endorsement.approveRejectFlowModel != null &&
                      (endorsement.approveRejectFlowModel.sm.isApproved || endorsement.isRejected)
                    "
                    (click)="approveorreject(endorsement)"
                  >
                    Approve/Reject
                  </button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="10" class="border-0 p-0">
              <div class="pagination-wrapper">
                <ngb-pagination
                  [collectionSize]="result.length"
                  [pageSize]="pageSize"
                  [(page)]="page"
                  [boundaryLinks]="true"
                  [maxSize]="3"
                  [rotate]="true"
                  [hidden]="result.length === 0 || !isEndorsementLoaded"
                ></ngb-pagination>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        *ngIf="
          ((brokerPrivileges !== undefined &&
            brokerPrivileges.endoresment &&
            role === 'Broker' &&
            brokerPrivileges.hasOwnProperty('code')) ||
            (smPrivileges !== undefined &&
              smPrivileges.endoresment &&
              role === 'SM' &&
              smPrivileges.hasOwnProperty('code'))) &&
          endorsements.length === 0 &&
          isEndorsementLoaded
        "
        class="no-result-wrapper"
      >
        <h5>No Data Found!</h5>
      </div>
    </div>
  </div>
</div>
