export class HRProfile {
  id: string;
  name: string;
  mobileNo: number;
  email: string;
  hrEmpId: string;
  reportAllowed: string;
  endoresment: string;
  dashboard: string;
  cashlessReport: string;
  reimbersementReport: string;
  staticDashboard: string;
  dyanamicDashboard: string;
  corporateCode: string;
  corporateName: string;
  corporateId: number;
  dob: any;
  doj: any;
}
