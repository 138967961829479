<div class="download-form-wrapper pb-5">
  <div class="card-body pb-0">
    <div class="card-title row justify-content-between align-items-center">
      <h4 class="m-0 header-title">Download Form</h4>
    </div>

    <div class="w-100">
      <div class="row">
        <div class="col-lg-3 col-sm-4">
          <a href="assets/pdf/Health Claim Form.pdf" target="_blank" class="download-form">
            <div class=" text-center">
              <img src="assets/images/refund.png" />
              <h4>Claims</h4>
              <!-- <p>Mis</p> -->
            </div>
          </a>
        </div>

        <div class="col-lg-3 col-sm-4">
          <a href="assets/pdf/Health Pre-Authorization Claim Form.pdf" target="_blank" class="download-form">
            <div class="text-center">
              <img src="assets/images/desk.png" />
              <h4>Claims Pre-Auth</h4>
              <!-- <p>Mis</p> -->
            </div>
          </a>
        </div>

        <div class="col-lg-3 col-sm-4">
          <a href="assets/pdf/OPD_Claim Form.pdf" target="_blank" class="download-form">
            <div class="text-center">
              <img src="assets/images/refund.png" />
              <h4>OPD Claims</h4>
             <!-- <p>Basic Details</p>-->
            </div>
          </a>
        </div>
        <!-- 
        <div class="col-lg-3 col-sm-4">
          <a href="#" class="download-form">
            <div class="text-center">
              <img src="assets/images/policy.png" />
              <h4>Group Mediclaim Policy</h4>
              <p>Basic Details</p>
            </div>
          </a>
        </div>

        <div class="col-lg-3 col-sm-4">
          <a href="#" class="download-form">
            <div class="text-center">
              <img src="assets/images/m-policy.png" />
              <h4>Mpolicy Premium</h4>
              <p>(W/o ST)</p>
            </div>
          </a>
        </div> -->
      </div>
    </div>
  </div>
</div>
