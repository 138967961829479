import { Component, OnInit, Injector } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BrokerService } from '../../service/broker.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'src/app/shared/services/role.service';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { CorporatePolicyModel } from 'src/app/corporate-portal/models/corporate-policy.model';

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.scss'],
})
export class BrokerComponent extends BaseComponent implements OnInit {
  corporate: CorporatePolicyModel = new CorporatePolicyModel();
  corporates: CorporatePolicyModel[] = [];

  constructor(
    injector: Injector,
    private cpMemberService: CPMemberService,
    private readonly alertService: AlertService,
    private modalService: NgbModal,
    private roleService: RoleService,
    private brokerService: BrokerService,
    private policyService: CPPolicyService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.policyService.currentPolicy.subscribe(async p => {
      if (p.id !== undefined) {
        await this.brokerService.getBrokerByPolicy(p.id).then(x => {});
      }
    });

    this.brokerService.getBrokerProfile().then(x => {});
  }

  toggleSidebar(event) {
    event.currentTarget.offsetParent.classList.toggle('close-sidebar');
    event.currentTarget.classList.toggle('closed');
  }
}
