<app-popup-model
  [title]="title"
  [saveButton]="saveButtonText"
  [isDisableSaveButton]="!isSaveButtonVisible"
  (close)="close()"
  (save)="save($event)"
  [showLoader]="showLoader"
>
  <div class="row">
    <div class="col-12" *ngIf="premiumType == 'Flat'">
      <div class="form-group">
        <label>Relationship<sup>*</sup></label>
        <input type="text" [disabled]="true" [(ngModel)]="relationship" class="form-control" />
      </div>
    </div>

    <div class="col-12" *ngIf="premiumType == 'Flat'">
      <div class="form-group">
        <label>Number of Members<sup>*</sup></label>
        <input type="text" [disabled]="true" [(ngModel)]="noOfMembers" class="form-control" />
      </div>
    </div>
    <div class="col-12" *ngIf="premiumType == 'Flat'">
      <div class="form-group">
        <label>Age<sup>*</sup></label>
        <input type="text" [disabled]="true" [(ngModel)]="age" class="form-control" />
      </div>
    </div>

    <div class="col-12" *ngIf="premiumType == 'Selective'">
      <div class="form-group">
        <label>Relationship<sup>*</sup></label>
        <div class="dropdown select-hr-dropdown">
          <button
            class="form-control text-left mt-2 dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <span *ngIf="relationship == undefined">Select Relationship</span>
            <span *ngIf="relationship != undefined">{{ relationship }}</span>
          </button>
          <div class="dropdown-menu">
            <div *ngFor="let relationship of relationShipDropdownEle">
              <a class="dropdown-item" (click)="onRelationshipDropdownChanged(relationship)">
                <span>{{ relationship }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" *ngIf="premiumType == 'Selective' && relationship !== undefined">
      <div class="form-group">
        <label>Number of Members<sup>*</sup></label>
        <div class="dropdown select-hr-dropdown">
          <button
            class="form-control text-left mt-2 dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <span *ngIf="noOfMembers == undefined">Select No of Members</span>
            <span *ngIf="noOfMembers != undefined">{{ noOfMembers }}</span>
          </button>
          <div class="dropdown-menu">
            <div *ngFor="let noOfMembers of noOfMembersDropdownEle">
              <a class="dropdown-item" (click)="onNoOfMembersDropdownChanged(noOfMembers)">
                <span>{{ noOfMembers }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" *ngIf="premiumType == 'Selective' && relationship !== undefined">
      <div class="form-group">
        <label>Min Age<sup>*</sup></label>
        <input type="number" [(ngModel)]="minage" class="form-control" (input)="validateMinAge()" />
      </div>
      <p [hidden]="isMinAgeValid" class="error-msg mb-0">
        <small>{{ invalidMinAgeMsg }}</small>
      </p>
    </div>

    <div class="col-12" *ngIf="premiumType == 'Selective' && relationship !== undefined">
      <div class="form-group">
        <label>Max Age<sup>*</sup></label>
        <input type="number" [(ngModel)]="maxage" class="form-control" (input)="validateMaxAge()" />
      </div>
      <p [hidden]="isMaxAgeValid" class="error-msg mb-0">
        <small>{{ invalidMaxAgeMsg }}</small>
      </p>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Sum Insured<sup>*</sup></label>
        <div class="dropdown select-hr-dropdown">
          <button
            [disabled]="relationship === undefined"
            class="form-control text-left mt-2 dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <span *ngIf="sumInsured == undefined">Select SumInsured</span>
            <span *ngIf="sumInsured != undefined">{{ sumInsured }}</span>
          </button>
          <div class="dropdown-menu">
            <div *ngFor="let sumInsured of sumInsuredList">
              <a class="dropdown-item" (click)="onSumInsuredDropdownChanged(sumInsured)">
                <span>{{ sumInsured }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Loading<sup>*</sup></label>
        <input
          [disabled]="relationship === undefined"
          type="number"
          [(ngModel)]="loading"
          class="form-control"
          (input)="validateLoading()"
        />
      </div>
      <p [hidden]="isLoadingValid" class="error-msg mb-0">
        <small>{{ invalidLoadingMsg }}</small>
      </p>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Premium Amount<sup>*</sup></label>
        <input
          [disabled]="relationship === undefined"
          type="text"
          [(ngModel)]="premiumAmount"
          class="form-control"
          (input)="validatePremiumAmount()"
        />
      </div>
      <p [hidden]="isPremiumAmountValid" class="error-msg mb-0">
        <small>{{ invalidPremiumAmountMsg }}</small>
      </p>
    </div>
    <div class="error-msg" *ngFor="let error of errorList">
      <p>{{ error.message }}</p>
    </div>
  </div>
</app-popup-model>
