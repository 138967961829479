<div class="policy-coverage-wrapper">
  <div class="container-fluid">
    <div class="row" *ngIf="(isHr && isHrView && role === 'CPHR') || role === 'Broker' || role === 'SM'">
      <div class="col-md-5">
        <div class="card card-style-1">
          <div class="card-body pb-0">
            <div class="card-title mb-3">
              <h4 class="m-0 header-title">Coverage</h4>
            </div>

            <app-policies-dropdown class="w-100"></app-policies-dropdown>

            <div class="mobile-card-padding w-100">              
              <div
                class="claim-search-box pt-0"
                *ngIf="currentPolicy.viewPolicyCoverage !== undefined && currentPolicy.viewPolicyCoverage"
              >
                <div class="form-group w-100">
                  <label>Employee ID</label>
                  <input
                    class="form-control"
                    [(ngModel)]="empId"
                    (input)="setSearchByEmpId($event)"
                    placeholder="Employee ID"
                  />
                </div>
                <p *ngIf="invalidEmpId" class="error-msg">
                  <small>{{ invalidEmpIdMsg }}</small>
                </p>
                <div class="form-action-wrapper">
                  <button
                    type="submit"
                    data-toggle="modal"
                    [disabled]="invalidEmpId || empId === '' || empId === null"
                    (click)="getHRPolicyCoverage()"
                    data-target="#policy-benefits"
                    class="btn w-100 rounded-0 btn-primary"
                  >
                    Search Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5">
          <div class="card-body pb-0">
            <div class="card-title mb-3">
              <h4 class="m-0 header-title">Sum Assured</h4>
            </div>

            <div
                class="w-100 form-group mb-0"
                *ngIf="currentPolicy.viewPolicyCoverage !== undefined && currentPolicy.viewPolicyCoverage"
              >               

                <div class="row">
                  <div class="col-12" *ngIf="!ispolicyAllowedSumInsuredLoaded">
                    <ul class="shimmer-wrapper justify-content-start list-unstyled">
                      <li class="shimmer shimmer-button"></li>
                      <li class="shimmer shimmer-button"></li>
                      <li class="shimmer shimmer-button"></li>
                    </ul>
                  </div>
                  <div class="col" *ngIf="ispolicyAllowedSumInsuredLoaded">
                    <button
                      *ngFor="let si of policyAllowedSumInsureds"
                      type="submit"
                      class="btn btn-primary btn-sum-insured"
                      data-toggle="modal"
                      (click)="getHRPolicyCoverageBySumInsured(si)"
                      data-target="#policy-benefits"
                      matTooltip="Click here to see policy coverages for this Sum Insured."
                    >
                      <img src="assets/images/rupee-symbol.svg" alt="" /> <span>{{ si.sumInsured }}</span>
                    </button>
                  </div>
                </div>
              </div>

          </div>

      </div>

    </div>

    <div class="row" *ngIf="isMember && isEmployeeBelongsToPolicy && !isHrView">
      <div class="col-12">
          <div class="card-body mb-0">
            <div class="card-title mb-3">
              <h4 class="m-0 header-title">Policy Benefits</h4>
            </div>
            <app-policies-dropdown class="w-100"></app-policies-dropdown>

            <div class="w-100" *ngIf="!isHrView">
              <div class="w-100 mt-2" *ngIf="!ispolicyCoverageLoaded">
                <ul class="shimmer-wrapper list-unstyled">
                  <li class="shimmer mb-3"></li>
                  <li class="shimmer"></li>
                </ul>
              </div>
              <div class="w-100 no-result-wrapper" *ngIf="ispolicyCoverageLoaded && policyCoverage.length === 0">
                <h5>No Data Found!</h5>
                <div class="error-msg mt-0" *ngIf="error !== null || error.length > 0">
                  <div *ngFor="let error of error">
                    <p>{{ error.message }}</p>
                  </div>
                </div>
              </div>
              <div id="accordion" class="accordian-section">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div *ngIf="currentPolicy.viewPolicyCoverage !== undefined && currentPolicy.viewPolicyCoverage">
                        <div *ngIf="ispolicyCoverageLoaded">
                          <mat-accordion>
                            <div *ngFor="let coverage of policyCoverage" class="panel-wrapper">
                              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <div>
                                      <h5>{{ coverage.displayTextField }}</h5>
                                      <p>
                                        {{ coverage.displayDescrptionField }}
                                      </p>
                                    </div>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-panel-description>
                                  <div class="card-pad">
                                    <div class="Normal-delivery" *ngFor="let subtype of coverage.coveragelistField">
                                      <div class="mb-2">
                                        {{ subtype.displayDescrptionField }}
                                        <div>{{ subtype.displayTextField }}</div>
                                      </div>
                                      <div class="row" *ngFor="let benifit of subtype.subtypelistField">
                                        <div class="col-12">
                                          <h6>{{ benifit.headerTextField }}</h6>
                                        </div>
                                        <div
                                          class="col-md-6"
                                          *ngFor="let colvalue of benifit.columnField; let i = index"
                                        >
                                          <h5>{{ colvalue.valueField }}</h5>
                                          <p>{{ benifit.rowField[i].valueField }}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </mat-panel-description>
                              </mat-expansion-panel>
                            </div>
                          </mat-accordion>
                        </div>
                      </div>
                      <!-- Shimmers -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- end row -->
          </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="currentPolicy.viewPolicyCoverage !== undefined && !currentPolicy.viewPolicyCoverage"
  class="no-result-wrapper"
>
  <h5>This feature not Allowed for selected policy.</h5>
</div>
<!-- <div *ngIf="ispolicyCoverageLoaded && error !== null">{{ error }}</div> -->

<!-- Modal -->
<div
  class="modal fade"
  id="policy-benefits"
  tabindex="-1"
  role="dialog"
  data-backdrop="static"
  data-keyboard="false"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Policy Benefits</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="accordion" class="accordian-section">
          <div class="row">
            <div class="col-md-12">
              <div class="card" *ngIf="ispolicyCoverageLoaded">
                <mat-accordion>
                  <div class="w-100 no-result-wrapper" *ngIf="ispolicyCoverageLoaded && policyCoverage.length === 0">
                    <h5>No Data Found!</h5>
                    <p class="error-msg mt-0" *ngIf="error !== null">
                      <small>{{ error }}</small>
                    </p>
                  </div>
                  <div *ngFor="let coverage of policyCoverage" class="panel-wrapper">
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div>
                            <h5>{{ coverage.displayTextField }}</h5>
                            <p>
                              {{ coverage.displayDescrptionField }}
                            </p>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-panel-description>
                        <div class="card-pad">
                          <div class="Normal-delivery" *ngFor="let subtype of coverage.coveragelistField">
                            <div class="mb-2">
                              {{ subtype.displayDescrptionField }}
                              <div>{{ subtype.displayTextField }}</div>
                            </div>
                            <div class="row" *ngFor="let benifit of subtype.subtypelistField">
                              <h6>{{ benifit.headerTextField }}</h6>
                              <div class="col-md-6" *ngFor="let colvalue of benifit.columnField; let i = index">
                                <h5>{{ colvalue.valueField }}</h5>
                                <p>{{ benifit.rowField[i].valueField }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-panel-description>
                    </mat-expansion-panel>
                  </div>
                </mat-accordion>
              </div>

              <!-- Shimmers -->
              <div>
                <ul class="shimmer-wrapper list-unstyled" *ngIf="!ispolicyCoverageLoaded">
                  <li class="shimmer mb-2"></li>
                  <li class="shimmer mb-2"></li>
                  <li class="shimmer mb-2"></li>
                  <li class="shimmer mb-2"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
