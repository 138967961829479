import { Component, OnInit, Input, Injector, OnChanges, SimpleChanges } from '@angular/core';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';

@Component({
  selector: 'app-static-dashboard',
  templateUrl: './static-dashboard.component.html',
  styleUrls: ['./static-dashboard.component.scss'],
})
export class StaticDashboardComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() policy: Policy;
  documentType: string;
  documents: Document[] = [];
  isdocumentLoaded = false;
  form = this.fb.group({
    documentType: ['', Validators.required],
  });

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    private policyService: CPPolicyService,
    private readonly cpmemberservice: CPMemberService
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetValue();
  }

  ngOnInit(): void {}

  async onSubmit() {
    this.isdocumentLoaded = false;
    await this.cpmemberservice
      .getStaticDocuments(this.policy.policyId, this.form.value.documentType)
      .then(x => {
        this.documents = x;
        this.isdocumentLoaded = true;
      })
      .catch(e => {
        console.log(e);
      });
  }

  resetValue() {
    this.documents = [];
    this.isdocumentLoaded = false;
  }
}
