import { Component, OnInit } from '@angular/core';
import { OperationService } from '../../services/operation.service';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss'],
})
export class OperationComponent implements OnInit {
  constructor(private readonly operationService: OperationService) {}

  ngOnInit(): void {
    // this.operationService.getOperationProfile();
  }

  toggleSidebar(event) {
    event.currentTarget.offsetParent.classList.toggle('close-sidebar');
    event.currentTarget.classList.toggle('closed');
  }
}
