import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { map } from 'jquery';
import { Key } from 'protractor';
import { IntimateClaimUploadDocuments } from 'src/app/cp-member/models/Intimate-claim/intimate-claim-upload-documents.model';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { SelfMember } from 'src/app/cp-member/models/self-member.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { PreEnrollmentMemberPolicyModel } from 'src/app/member/model/preEnrollment-member-policy.model';
import { documentList, GPAMail } from '../../models/gpa-mail.model';
import { CPPolicyService } from '../../services/cp-policy.service'

@Component({
  selector: 'app-gpa',
  templateUrl: './gpa.component.html',
  styleUrls: ['./gpa.component.scss']
})
export class GpaComponent implements OnInit {
isLoading:boolean=true;
intimationType:string;
MailForm:FormGroup;
gpaMail =new GPAMail();
isSizeValid = false;
uploadImageInvalidMsg = '';
fileListDocs = [];
imageInvalid = true;
disableSubmitButton=true;
currentPolicy:Policy=new Policy();
  family:PreEnrollmentMemberPolicyModel[]=[];
  self:SelfMember= new SelfMember();
  hidEmailError=true;
  hidMessageError=true;
  hidNumError=true;
  hidSubjectError=true;
  corporateName:string;
  policyNumber:string;
  showModalBox:boolean=false;
  isValid:boolean=true;
  files= new Map<string,string>();//{ key: string; value: any; }[] =[];
  totalFilesSize=0;
  constructor(private cpPolicyService:CPPolicyService,private fb: FormBuilder,private cpMemberService:CPMemberService
    ,private router:Router) {
      
    // this.gpaMail.emailId='Sheetal.mahadik@traceart.co.in';
    // this.gpaMail.mobileNumber=919773184910;
    // this.gpaMail.message='';
    // this.gpaMail.subject='';
   }

  ngOnInit(): void {
    console.log('Current URL is:-'+this.router.url);
      let url=this.router.url;
      if(url.indexOf('gpa')>0)
        this.intimationType= 'GPA';
      else if(url.indexOf('gpm')>0)
        this.intimationType = 'GPM';
      this.MailForm = this.fb.group({
        emailId: ['', [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        subject: ['',Validators.required],
        message: ['', [Validators.required,Validators.minLength(100),Validators.maxLength(4000)]],
        mobileNumber: [0,[Validators.minLength(10),Validators.required,Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
        DocumentList: ['']
      });
    // this.hidEmailError=true;
    // this.hidMessageError=true;
    // this.hidNumError=true;
    // this.hidSubjectError=true;
    this.cpPolicyService.currentCorporate.subscribe(m=> {
        this.corporateName=m.name;
    });
    this.cpMemberService.selfMemberDetails.subscribe(m=>
      {
        this.self=m;
        this.policyNumber=this.self.policyNo;
        // if(this.self.emailID!= '')
        //   this.gpaMail.emailId=this.self.emailID;
        // if(this.self.mobileNo != '')
        //   this.gpaMail.mobileNumber = this.self.mobileNo;
        // this.gpaMail.subject=this.intimationType +' Claim Intimation for '+this.self.insuredName+' / '+this.self.memberIdEmpId+' / '+this.corporateCode;
        // this.gpaMail.message='';
        let subs=this.intimationType == 'GPA'? '/LOP' : '';
        this.MailForm.patchValue({
          emailId: this.self.emailID,
          subject: this.intimationType + subs +' Claim Intimation for '+this.self.insuredName+' ('+this.self.memberIdEmpId+') '+this.corporateName,
          mobileNumber: this.self.mobileNo,
          message:''
        });
      });
      this.isLoading=false;
    
  }
emailSubmit(){
  console.log(this.MailForm);
  this.gpaMail.intimationType=this.intimationType;
  let data = this.MailForm.getRawValue();
  this.gpaMail.emailId=data.emailId;
  this.gpaMail.subject=data.subject;
  this.gpaMail.message=data.message;
  this.gpaMail.mobileNumber=data.mobileNumber
  // let error=true;
  // // this.gpaMail.mobileNumber=this.MailTemplate.mobileNumber;
  // if(!(this.gpaMail.emailId.length>0))
  //   this.hidEmailError=false;
  // else
  //   this.hidEmailError=true;
  // if(!(this.gpaMail.message.length>=10 && this.gpaMail.message.length<=4000))
  //   this.hidMessageError = false;
  // else
  //   this.hidMessageError =true;
  // if(!(this.gpaMail.mobileNumber.toString().length>=10 && this.gpaMail.mobileNumber.toString().length<=12))
  //   this.hidNumError = false;
  // else
  //   this.hidNumError = true;
  // if(!(this.gpaMail.subject.length>0))
  //   this.hidSubjectError = false;
  // else
  //   this.hidSubjectError = true;
  // if(!this.hidEmailError || !this.hidMessageError || !this.hidNumError || !this.hidSubjectError)
  //   return;
  this.cpPolicyService
      .sendGPAMail(this.gpaMail,this.policyNumber)
      .then(cj => {
        console.log('success');
      })
      .catch(cj => {
        console.log('error');
      });
  this.showModalBox=true;
  }

  onFileChange(files) {
    let size=0;
    let ext='';
    this.isValid=true;
    if(this.gpaMail.DocumentList.length>=8)
    {
    //   const fileName=this.gpaMail.DocumentList[0].FileName
    // this.gpaMail.DocumentList = this.gpaMail.DocumentList.filter(el => el.FileName != fileName);
    this.isValid=false;
    this.uploadImageInvalidMsg = "You can upload maximum of 8 documents!!";
    return;
    }
    
    let File:documentList;
      for (const file of files) {
        File = {
          FileName: file.name,
          FileBytes: file,
          DocTypeId: 0
        };
        size=file.size;
        if((this.totalFilesSize+size)>=20000000)
        {
          this.isValid=false;
          this.uploadImageInvalidMsg = "You can upload maximum of 20MB files size!!";
          return;
        }
        ext = file.name.substring(file.name.lastIndexOf('.') + 1);
        if (file.name.length > 30) {
          File.FileName = file.name.slice(0, 40) + '...';
        }
       //this.gpaMail.DocumentList.push(File);  
           
        
        // (<HTMLInputElement>document.getElementById('doc_para')).value = File.FileName;
        // this.MailForm.patchValue({DocumentList:File.FileName});
        // document.getElementById('doc_para').style.display = 'block';
        // document.getElementById('doc_delete').style.display = 'block';
      }

        const fileExtensionArr = ['jpg', 'jpeg', 'png', 'pdf'];
        if (!fileExtensionArr.includes(ext.toLowerCase())) {
          this.isSizeValid = false;
          this.uploadImageInvalidMsg = File.FileName + ' is invalid. Upload Valid Format File(Maximum 5 Mb)';
          // (<HTMLInputElement>(
          //   document.getElementById('doc_invalid')
          // )).innerText = this.uploadImageInvalidMsg;
          //document.getElementById('doc_invalid').style.display = 'block';
          
        } else {
          this.isSizeValid = true;
          this.uploadImageInvalidMsg = 'Please upload .jpg,.jpeg,.png.pdf files only';
          // (<HTMLInputElement>(
          //   document.getElementById('doc_invalid')
          // )).innerText = this.uploadImageInvalidMsg;
         // document.getElementById('doc_invalid').style.display = 'none';
          this.disableSubmitButton=false;
        }
        if (this.isSizeValid) {
          if (size > 5000000) {
            this.isSizeValid = false;
            this.uploadImageInvalidMsg = File.FileName + ' is invalid. Maximum file size must be 5 Mb.';
            // (<HTMLInputElement>(
            //   document.getElementById('doc_invalid')
            // )).innerText = this.uploadImageInvalidMsg;
            //document.getElementById('doc_invalid').style.display = 'block';
          } else {
            this.isSizeValid = true;
            this.uploadImageInvalidMsg = '';
            // (<HTMLInputElement>(
            //   document.getElementById('doc_invalid')
            // )).innerText = this.uploadImageInvalidMsg;
            // document.getElementById('doc_invalid').style.display = 'none';
            let file=this.gpaMail.DocumentList.filter(el =>el.FileName == File.FileName);
            if(file == null || file==undefined || file.length ==0 )
            {
            this.gpaMail.DocumentList.push(File);  
            this.totalFilesSize+=size;
            }
          }
        }
        this.files.set(File.FileName,this.uploadImageInvalidMsg);   

        // document.getElementById('doc_delete').style.display = 'block';
        // document.getElementById('doc_para').style.display = 'block';
        this.convertFilesArrayToBase64Array();
  }
  convertFilesArrayToBase64Array() {
    this.gpaMail.DocumentList.forEach(element => {
      if (element.FileBytes instanceof File) {
        this.convertFiletoBase64(element.FileBytes).then(FileByte => {
          const filebyteInBase64 = String(FileByte).split('base64,')[1];
          const index1 = this.gpaMail.DocumentList.findIndex(document => document === element);
          if (index1 !== -1) {
            this.gpaMail.DocumentList[index1].FileBytes = filebyteInBase64;
          }
        });
      }
    });
  }
  async convertFiletoBase64(file) {
    const result_base64 = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = e => resolve(fileReader.result);
      fileReader.onerror = e => reject(e);
    });
    return result_base64;
  }

  deleteFile(fileName) {
    this.isValid=true;
    // document.getElementById('doc_invalid').style.display = 'none';
    // document.getElementById('doc_delete').style.display = 'none';
    // document.getElementById('doc_para').style.display = 'none';
    // (<HTMLInputElement>document.getElementById('doc_para')).innerText = '';
    // (<HTMLInputElement>document.getElementById('doc_invalid')).innerText = '';
    //const fileName=this.gpaMail.DocumentList[0].FileName
    if(fileName=='')
    {
      this.gpaMail.DocumentList = [];
      this.files.clear();
      this.isValid=true;
      return;
    }
    let file=this.gpaMail.DocumentList.filter(el =>el.FileName == fileName);
    this.totalFilesSize-=file[0].FileBytes.length;
    this.gpaMail.DocumentList = this.gpaMail.DocumentList.filter(el => el.FileName != fileName);
    this.files.delete(fileName);
    if(this.gpaMail.DocumentList.length>0)
      this.disableSubmitButton=false;
    else
      this.disableSubmitButton=true;
    console.log(this.gpaMail.DocumentList);

  }

  OK()
  {
    this.showModalBox=false;
    this.deleteFile('');
    this.ngOnInit();
    // this.MailForm.patchValue({
    //   message:''});
      // this.MailForm.patchValue({message:""})
    this.isValid=true;
  }

}

