import { Component, OnInit, Injector, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { EndorsementService } from 'src/app/corporate-portal/services/endorsement.service';

@Component({
  selector: 'app-create-employee-endorsement',
  templateUrl: './create-employee-endorsement.component.html',
  styleUrls: ['./create-employee-endorsement.component.scss'],
})
export class CreateEmployeeEndorsementComponent implements OnInit {
  @Input() policy: Policy;
  @Input() addMemberOrDependent: string;
  @Input() policies;
  @Output() public dependentAddedSucessfully = new EventEmitter<boolean>();

  title = 'Add New Employee Detail';
  saveButtonText = 'Submit';
  isDisableSaveButton = false;
  form: FormGroup;
  errorList = [];
  date = new Date();
  currentPolicyChosen: Policy = null;

  isLoading = false;

  relationships:Array<[string,boolean]>=[];
  isTopUpSumInsured = true;

  constructor(
    private cpMemberService: CPMemberService,
    private endorsementService: EndorsementService,
    private policyService: CPPolicyService,
    private fb: FormBuilder,
    injector: Injector,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.createForm();
    if (this.addMemberOrDependent === 'addDependentByMember') {
      this.form.controls.employeeId.setValue(this.cpMemberService.selfMemberDetails.value.memberIdEmpId);
      this.form.controls.grade.setValue(this.cpMemberService.selfMemberDetails.value.grade);
      this.form.controls.sumInsured.setValue(this.cpMemberService.selfMemberDetails.value.sumInsured);
      this.form.controls.policyNo.setValue(this.policy.policyNumber);
      this.currentPolicyChosen = this.policy;
      this.form.controls.email.setValue(this.cpMemberService.selfMemberDetails.value.emailID);
      let data = { "policyNo" : this.currentPolicyChosen.policyNumber};
      this.cpMemberService.getRelationshipDetails(data).then(val=>
        {
          val.forEach(x=>{
            if(x.Description.toLowerCase() != 'self')
              this.relationships.push([x.Description,x.TopupApplicable]);
          })
        });
    }
    if (this.addMemberOrDependent === 'addDependentByMember' || this.addMemberOrDependent === 'addDependentByHr') {
      this.title = 'Add New Dependent';
    } else {
      this.form.controls.relationship.setValue('Self');
    }
  }

  relationshipChanged(){
    let relation = this.form.get('relationship').value;
    let x = this.relationships.filter(x=> x[0] === relation)[0][1];
    this.isTopUpSumInsured=x;
  }

  createForm() {
    this.form = this.fb.group({
      policyNo: ['', Validators.required],
      insuredName: [
        '',
        [Validators.required, Validators.minLength(2), Validators.pattern('^[a-zA-Z._]+([a-zA-Z. ]*)s*$')],
      ],
      location: ['', [Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')]],
      grade: ['', [Validators.minLength(1), Validators.pattern('^s*([0-9a-zA-Z+]*)s*$')]],
      gender: ['', Validators.required],
      employeeId: ['', [Validators.required, Validators.minLength(1), Validators.pattern('^s*([0-9a-zA-Z]*)s*$')]],
      designation: ['', [Validators.minLength(2), Validators.pattern('^[a-zA-Z0-9_]+([0-9a-zA-Z ]*)s*$')]],
      dob: ['', Validators.required],
      email: [
        '',
        [
          Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i)
        ],
      ],
      elite: ['', [Validators.minLength(1), Validators.pattern('^[a-zA-Z0-9_]+([0-9a-zA-Z]*)s*$')]],
      uhid: ['', [Validators.minLength(1), Validators.pattern('^[a-zA-Z0-9_]+([0-9a-zA-Z]*)s*$')]],
      relationship: ['', Validators.required],
      mobileNo: ['', [Validators.required, Validators.minLength(10), Validators.pattern(/^([6-9][0-9]{9})$/)]],
      sumInsured: ['', Validators.pattern(/^[0-9]+$/)],
      topupSI: ['', Validators.pattern(/^[0-9]+$/)],
      endorsementType: ['', Validators.required],
    });
  }

  close() {
    this.modalService.dismissAll(CreateEmployeeEndorsementComponent);
  }

  save(event) {
    if (
      this.form.value.sumInsured === null ||
      this.form.value.sumInsured === undefined ||
      this.form.value.sumInsured === ''
    ) {
      this.form.controls.sumInsured.setValue(0);
    }
    if (this.form.value.topupSI === null || this.form.value.topupSI === '' || this.form.value.topupSI === undefined) {
      this.form.controls.topupSI.setValue(0);
    }
    this.form.get('designation').setErrors({ invalid: true });
    if (this.addMemberOrDependent === 'addDependentByMember') {
      this.isLoading = true;
      this.endorsementService
        .createDependentEndorsement(this.currentPolicyChosen.policyId, this.form.value)
        .then(x => {
          this.form.get('designation').setErrors(null);
          this.dependentAddedSucessfully.emit(true);
          this.isLoading = false;
          this.alertService.success('Endorsement added successfully');
          this.modalService.dismissAll(CreateEmployeeEndorsementComponent);
        })
        .catch(errorData => {
          this.form.get('designation').setErrors(null);
          this.errorList = errorData.error.errors;
          this.isLoading = false;
        });
    }
    if (this.addMemberOrDependent === 'addMemberByHr') {
      this.isLoading = true;
      this.endorsementService
        .createEmployeeEndorsement(this.currentPolicyChosen.policyId, this.form.value)
        .then(x => {
          this.form.get('designation').setErrors(null);
          this.dependentAddedSucessfully.emit(false);
          this.isLoading = false;
          this.alertService.success('Endorsement added successfully');
          this.modalService.dismissAll(CreateEmployeeEndorsementComponent);
        })
        .catch(errorData => {
          this.form.get('designation').setErrors(null);
          this.errorList = errorData.error.errors;
          this.isLoading = false;
        });
    }
  }

  policyChanged(policy) {
    this.currentPolicyChosen = policy;
    this.form.controls.policyNo.setValue(policy.policyNumber);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
