import { ApproveRejectFlow } from '../approve-reject-flow.model';

export class Endorsement {
  id: string;
  insuredName: string;
  dob: string;
  endorsementType: string;
  gender: string;
  uhid: string;
  mobileNo: string;
  email: string;
  address1: string;
  employeeId: string;
  relationShip: string;
  topUpSI: number;
  elite: string;
  sumInsured: number;
  grade: string;
  remark: string;
  policyNo: string;
  isApproved: boolean;
  isRejected: boolean;
  approveRejectFlowModel: ApproveRejectFlow;
}
