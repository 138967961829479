export class WelcomeEmail {
    sendWelcomeEmail: boolean;
    welcomeEmailTemplate: string;
    welcomeEmailSubject: string;
    welcomeEmailHrs: number;
    welcomeEmailMins: number;
    welcomeEmailDate: Date;
    welcomeEmailFile: any;
    createUsers: boolean;
    welcomeEmailFileName: string;
    constructor(model) {
        Object.assign(this, model);
      }
}
