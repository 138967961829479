import { Injectable } from '@angular/core';
import { PreEnrollmentPolicyRelationshipModel } from '../../shared/models/preEnrollment/policies/PreEnrollmentPolicyRelationshipModel';
import { PreEnrollmentPolicyMemberFieldModel } from '../../shared/models/preEnrollment/policies/PreEnrollmentPolicyMemberFieldModel';
import { BackendService } from '../../shared/services/backend.service';
import { EmailTemplateModel } from '../../shared/models/preEnrollment/policies/EmailTemplateModel';

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  constructor(private backendService: BackendService) {}

  public getMemberFieldsMaster(): Promise<PreEnrollmentPolicyMemberFieldModel[]> {
    const promise = new Promise<PreEnrollmentPolicyMemberFieldModel[]>(async resolve => {
      let memberFields: any;
      memberFields = await this.backendService.get('FieldMaster');
      resolve(memberFields);
    });

    return promise;
  }

  public getEmailTemplate(): Promise<EmailTemplateModel> {
    const promise = new Promise<EmailTemplateModel>(async resolve => {
      let emailtemplate: any;
      emailtemplate = await this.backendService.get('preenrollment/emailtemplate');
      resolve(emailtemplate);
    });

    return promise;
  }

  public getPolicyMemberFields(policyId): Promise<PreEnrollmentPolicyMemberFieldModel[]> {
    const promise = new Promise<PreEnrollmentPolicyMemberFieldModel[]>(async resolve => {
      let memberFields: any;
      memberFields = await this.backendService.get('FieldMaster/' + policyId);
      resolve(memberFields);
    });

    return promise;
  }

  public getPolicyRelationshipFields(policyId): Promise<PreEnrollmentPolicyRelationshipModel[]> {
    const promise = new Promise<PreEnrollmentPolicyRelationshipModel[]>(async resolve => {
      let relationshipFields: any;

      relationshipFields = await this.backendService.get('RelationshipMaster/' + policyId);
      resolve(relationshipFields);
    });

    return promise;
  }

  public getRelationshipMaster(): Promise<PreEnrollmentPolicyRelationshipModel[]> {
    const promise = new Promise<PreEnrollmentPolicyRelationshipModel[]>(async resolve => {
      const relationshipFields = await this.backendService.get('RelationshipMaster');

      resolve(relationshipFields);
    });

    return promise;
  }
}
