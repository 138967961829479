<div class="create-enrollment-component-wrapper">
  <div class="container px-0">
    <div class="create-enrollment-box" *ngIf="sumInsuredType != '' && !displayUi" class="spinner-center-wrapper">
      <div class="spinner-center"></div>
    </div>
    <div class="create-enrollment-box" *ngIf="sumInsuredType != '' && displayUi && !sumInsuredNotApplicable">
      <div class="error-msg" *ngFor="let error of errorList">
        <p>{{ error.message }}</p>
      </div>
      <div class="enrollment-box-row" id="si_div" *ngIf="sumInsuredType == 'SI'">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: configureRelationships,
            title: 'Configure relationship',
            btnTitle: 'Set Relationships'
          }"
        >
        </ng-container>

        <div class="box-body">
          <h6>SUM INSURED</h6>
          <ng-container
            [ngTemplateOutlet]="SITemplate"
            [ngTemplateOutletContext]="{ sumInsured: allowedSumInsured.sumInsuredList }"
          >
          </ng-container>
        </div>
      </div>

      <div class="enrollment-box-row" id="psi_div" *ngIf="sumInsuredType == 'PSI'">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: configureRelationships,
            title: 'Configure relationship',
            btnTitle: 'Set Relationships'
          }"
        >
        </ng-container>

        <div class="box-body">
          <h6 *ngIf="relationshipType === 'Parent'">PARENTAL SUM INSURED</h6>
          <h6 *ngIf="relationshipType === 'Parent-In-Law'">PARENT IN LAW SUM INSURED</h6>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [disabled]="!policy"
                      [(ngModel)]="parentalSumInsured.peExtraBenefits.isAllowed"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Allow parental sum insured</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="PSITemplate" [ngTemplateOutletContext]="{ sumInsured: parentalSumInsured }">
          </ng-container>
        </div>
      </div>

      <div class="enrollment-box-row" *ngIf="sumInsuredType == 'ESI' && !sumInsuredNotApplicable">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: configureEnhancedSumInsured,
            title: 'Configure enhanced sum insured',
            btnTitle: 'Set Enhanced Sum Insured'
          }"
        >
        </ng-container>

        <div class="box-body">
          <h6>ENHANCED SUM INSURED</h6>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [disabled]="!policy"
                      [(ngModel)]="enhancedSumInsured.peExtraBenefits.isAllowed"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Allow enhanced sum insured</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="ESITemplate" [ngTemplateOutletContext]="{ sumInsured: enhancedSumInsured }">
          </ng-container>
        </div>
      </div>

      <div class="enrollment-box-row" *ngIf="sumInsuredType == 'TopUp' && !sumInsuredNotApplicable">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: configureTopups,
            title: 'Configure top-ups',
            btnTitle: 'Configure Top-ups'
          }"
        >
        </ng-container>

        <div class="box-body">
          <h6>TOPUP SUM INSURED</h6>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [(ngModel)]="topupOption.peExtraBenefits.isAllowed"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Allow top-up option</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="TopupTemplate" [ngTemplateOutletContext]="{ sumInsured: topupOption }">
          </ng-container>
        </div>
      </div>
    </div>
    <div class="create-enrollment-box no-results" *ngIf="sumInsuredType == ''">
      <h6>Please select Sum Insured to save/edit details</h6>
    </div>

    <div class="create-enrollment-box no-results" *ngIf="sumInsuredNotApplicable && displayUi">
      <div *ngIf="sumInsuredType === 'ESI' && relationshipType === 'Self'">
        <h6>Please add Sum Insured to add ESI details</h6>
      </div>
      <div *ngIf="sumInsuredType === 'ESI' && relationshipType === 'Parent'">
        <h6>Please add Parental Sum Insured to add ESI details</h6>
      </div>
      <div *ngIf="sumInsuredType === 'ESI' && relationshipType === 'Parent-In-Law'">
        <h6>Please add Parent In Law Sum Insured to add ESI details</h6>
      </div>
      <div *ngIf="sumInsuredType === 'PSI' && relationshipType === 'Parent'">
        <h6>Please add Sum Insured to add PSI details</h6>
      </div>
      <div *ngIf="sumInsuredType === 'PSI' && relationshipType === 'Parent-In-Law'">
        <h6>Please add Sum Insured to add PSI details</h6>
      </div>
      <div *ngIf="sumInsuredType === 'TopUp' && relationshipType === 'Self'">
        <h6>Please add Sum Insured to add Topup details</h6>
      </div>
      <div *ngIf="sumInsuredType === 'TopUp' && relationshipType === 'Parent'">
        <h6>Please add Parental Sum Insured to add Topup details</h6>
      </div>
      <div *ngIf="sumInsuredType === 'TopUp' && relationshipType === 'Parent-In-Law'">
        <h6>Please add Parent In Law Sum Insured to add Topup details</h6>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="sumInsuredType != '' && displayUi && !sumInsuredNotApplicable">
    <div class="col" *ngIf="showEditOption === false">
      <div class="mt-4">
        <button (click)="saveSumInsured()" class="btn btn-primary px-4">Save</button>
      </div>
    </div>
    <div class="col" *ngIf="(sumInsuredType === 'SI' || sumInsuredType === 'PSI') && showEditOption">
      <div class="mt-4">
        <button (click)="editSumInsured()" class="btn btn-primary px-4">Edit</button>
      </div>
    </div>
  </div>

  <ng-template #SITemplate let-sumInsured="sumInsured">
    <div class="table-grid">
      <table>
        <thead>
          <tr>
            <th [hidden]="hideGradewise">
              Grades
            </th>
            <th>
              Sums
            </th>
          </tr>
          <span class="errorMsg" [hidden]="!allowedSumInsured.isValueRepeated">&nbsp;Sums cannot be Repeated</span>
          <span class="errorMsg" [hidden]="!allowedSumInsured.isGradeWiseDefinedRepeated"
            >&nbsp;Grades cannot be Repeated</span
          >
        </thead>
        <tbody>
          <tr *ngFor="let item of sumInsured; let i = index">
            <td [hidden]="hideGradewise">
              <input [(ngModel)]="item.grade" type="text" class="form-parcontrol small-input w-50" />
              <span class="errorMsg" [hidden]="!item.isGradeInvalid">&nbsp;Invalid Grade</span>
            </td>
            <td>
              <input [(ngModel)]="item.sumInsured" type="text" class="form-control small-input w-50" />
              <span class="errorMsg" [hidden]="!item.isValueInvalid">&nbsp;Invalid Sum</span>
            </td>
            <td>
              <a [hidden]="sumInsured.length === 1" (click)="removeEntry(i)" class="float-right text-secondary"
                ><i class="material-icons">delete</i></a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <button (click)="addNewEntry()" class="add-new-row-btn">
        <i class="material-icons">add</i> <span>Add new</span>
      </button>
    </div>
  </ng-template>

  <ng-template #PSITemplate let-sumInsured="sumInsured">
    <div class="row" *ngIf="sumInsured.peExtraBenefits.isAllowed">
      <div class="col-md-6">
        <div class="form-group">
          <label>Plan</label>
          <select [(ngModel)]="sumInsured.peExtraBenefits.plan" class="form-control">
            <option value="Floater">Floater</option>
            <option value="Individual">Individual</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Type</label>
          <select
            (ngModelChange)="sumInsuredTypeOptionChanged()"
            [(ngModel)]="sumInsured.peExtraBenefits.sumInsuredType"
            class="form-control"
          >
            <option value="Defined">Defined</option>
            <option value="GradeWiseDefined" [hidden]="hideGradewise">Grade Wise Defined</option>
          </select>
        </div>
      </div>
    </div>
    <div
      class="table-grid"
      [hidden]="
        sumInsured.peExtraBenefits.sumInsuredType === 'EqualToSumInsured' || !sumInsured.peExtraBenefits.isAllowed
      "
    >
      <table>
        <thead>
          <tr>
            <th [hidden]="sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseDefined'">
              Grades
            </th>
            <th
              [hidden]="
                sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseDefined' &&
                sumInsured.peExtraBenefits.sumInsuredType != 'Defined'
              "
            >
              Sums
            </th>
          </tr>
          <span class="errorMsg" [hidden]="!sumInsured.peExtraBenefits.isValueRepeated"
            >&nbsp;Sums cannot be Repeated</span
          >
          <span class="errorMsg" [hidden]="!sumInsured.peExtraBenefits.isGradeWiseDefinedRepeated"
            >&nbsp;Grades cannot be Repeated</span
          >
        </thead>
        <tbody>
          <tr *ngFor="let insuredValue of sumInsured.pePsi; let i = index">
            <!-- <td *ngIf="sumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined'">
              <input [(ngModel)]="insuredValue.grade" type="text" class="form-parcontrol small-input w-50" />
              <span class="errorMsg" [hidden]="!insuredValue.isGradeInvalid">&nbsp;Invalid Grade</span>
            </td> -->

            <td
              *ngIf="
                sumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseMultiples' ||
                sumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined'
              "
            >
              <select class="form-control" [(ngModel)]="insuredValue.grade">
                <option *ngFor="let si of allowedSumInsured.sumInsuredList" [ngValue]="si.grade">
                  {{ si.grade }}
                </option>
              </select>
              <span class="errorMsg" [hidden]="!insuredValue.isGradeInvalid">&nbsp;Invalid Grade</span>
            </td>
            <td [hidden]="sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseMultiples'">
              <input [(ngModel)]="insuredValue.multiples" type="number" class="form-control small-input w-50" />
              <span class="errorMsg" [hidden]="!insuredValue.isMultipleInvalid">&nbsp;Invalid Multiple</span>
            </td>
            <td
              [hidden]="
                sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseDefined' &&
                sumInsured.peExtraBenefits.sumInsuredType != 'Defined'
              "
            >
              <input
                [ngModel]="insuredValue.stringValue | numberFormat"
                (ngModelChange)="insuredValue.stringValue = $event"
                type="text"
                class="form-control small-input w-50"
              />
              <span class="errorMsg" [hidden]="!insuredValue.isValueInvalid">&nbsp;Invalid Sum</span>
            </td>
            <td>
              <a [hidden]="sumInsured.pePsi.length === 1" (click)="removeEntry(i)" class="float-right text-secondary"
                ><i class="material-icons">delete</i></a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <button (click)="addNewEntry()" class="add-new-row-btn">
        <i class="material-icons">add</i> <span>Add new</span>
      </button>
    </div>
    <div class="row" [hidden]="!sumInsured.peExtraBenefits.isAllowed">
      <div class="col-md-12">
        <div class="form-group">
          <label>Instructions</label>
          <input [(ngModel)]="sumInsured.peExtraBenefits.instructions" type="text" class="form-control" />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #ESITemplate let-sumInsured="sumInsured">
    <div class="row" *ngIf="sumInsured.peExtraBenefits.isAllowed">
      <div class="col-md-6">
        <div *ngIf="relationshipType === 'Self'" class="form-group">
          <label>Plan</label>
          <select [(ngModel)]="sumInsured.peExtraBenefits.plan" class="form-control" disabled>
            <option value="Floater">Floater</option>
            <option value="Individual">Individual</option>
          </select>
        </div>
        <div *ngIf="relationshipType !== 'Self'" class="form-group">
          <label>Plan</label>
          <select [(ngModel)]="parentalSumInsured.peExtraBenefits.plan" class="form-control" disabled>
            <option value="Floater">Floater</option>
            <option value="Individual">Individual</option>
          </select>
        </div>
      </div>
      <div class="col-md-6" *ngIf="relationshipType === 'Self'">
        <div class="form-group" *ngIf="allowedSumInsured.selfSumInsuredType === 'GradeWiseDefined'">
          <label>Type</label>
          <select
            (ngModelChange)="sumInsuredTypeOptionChanged()"
            [(ngModel)]="sumInsured.peExtraBenefits.sumInsuredType"
            class="form-control"
          >
            <option value="EqualToSumInsured" [hidden]="hideSumInsuredFields">Equal to Sum Insured</option>
            <option value="Defined">Defined</option>
            <option value="GradeWiseMultiples" [hidden]="hideGradewise || hideSumInsuredFields"
              >Grade Wise Multiples
            </option>
            <option value="GradeWiseDefined" [hidden]="hideGradewise">Grade Wise Defined</option>
          </select>
        </div>
        <div class="form-group" *ngIf="allowedSumInsured.selfSumInsuredType === 'Defined'">
          <label>Type</label>
          <select
            (ngModelChange)="sumInsuredTypeOptionChanged()"
            [(ngModel)]="sumInsured.peExtraBenefits.sumInsuredType"
            class="form-control"
          >
            <option value="EqualToSumInsured" [hidden]="hideSumInsuredFields">Equal to Sum Insured</option>
            <option value="Defined">Defined</option>
          </select>
        </div>
      </div>

      <div class="col-md-6" *ngIf="relationshipType !== 'Self'">
        <div class="form-group" *ngIf="parentalSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined'">
          <label>Type</label>
          <select
            (ngModelChange)="sumInsuredTypeOptionChanged()"
            [(ngModel)]="sumInsured.peExtraBenefits.sumInsuredType"
            class="form-control"
          >
            <option value="EqualToSumInsured" [hidden]="hideSumInsuredFields">Equal to Sum Insured</option>
            <option value="Defined">Defined</option>
            <option value="GradeWiseMultiples" [hidden]="hideGradewise || hideSumInsuredFields"
              >Grade Wise Multiples
            </option>
            <option value="GradeWiseDefined" [hidden]="hideGradewise">Grade Wise Defined</option>
          </select>
        </div>
        <div class="form-group" *ngIf="parentalSumInsured.peExtraBenefits.sumInsuredType === 'Defined'">
          <label>Type</label>
          <select
            (ngModelChange)="sumInsuredTypeOptionChanged()"
            [(ngModel)]="sumInsured.peExtraBenefits.sumInsuredType"
            class="form-control"
          >
            <option value="EqualToSumInsured" [hidden]="hideSumInsuredFields">Equal to Sum Insured</option>
            <option value="Defined">Defined</option>
          </select>
        </div>
      </div>
    </div>
    <div
      class="table-grid"
      [hidden]="
        sumInsured.peExtraBenefits.sumInsuredType === 'EqualToSumInsured' || !sumInsured.peExtraBenefits.isAllowed
      "
    >
      <table>
        <thead>
          <tr>
            <th
              [hidden]="
                sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseDefined' &&
                sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseMultiples'
              "
            >
              Grades
            </th>
            <th [hidden]="sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseMultiples'">Multiples</th>
            <th
              [hidden]="
                sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseDefined' &&
                sumInsured.peExtraBenefits.sumInsuredType != 'Defined'
              "
            >
              Sums
            </th>
          </tr>
          <span class="errorMsg" [hidden]="!sumInsured.peExtraBenefits.isValueRepeated"
            >&nbsp;Sums cannot be Repeated</span
          >
          <span class="errorMsg" [hidden]="!sumInsured.peExtraBenefits.isGradeWiseMultipleRepeated"
            >&nbsp;Grades cannot be Repeated</span
          >
          <span class="errorMsg" [hidden]="!sumInsured.peExtraBenefits.isGradeWiseDefinedRepeated"
            >&nbsp;Grades cannot be Repeated</span
          >
        </thead>
        <tbody>
          <tr *ngFor="let insuredValue of sumInsured.peEsi; let i = index">
            <!-- <td *ngIf="sumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined'">
              <input [(ngModel)]="insuredValue.grade" type="text" class="form-control small-input w-50" />
              <span class="errorMsg" [hidden]="!insuredValue.isGradeInvalid">&nbsp;Invalid Grade</span>
            </td> -->
            <td
              *ngIf="
                sumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseMultiples' ||
                sumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined'
              "
            >
              <select class="form-control" [(ngModel)]="insuredValue.grade">
                <option *ngFor="let si of allowedSumInsured.sumInsuredList" [ngValue]="si.grade">
                  {{ si.grade }}
                </option>
              </select>
              <!-- <input [(ngModel)]="insuredValue.grade" type="text" class="form-control small-input w-50" /> -->
              <span class="errorMsg" [hidden]="!insuredValue.isGradeInvalid">&nbsp;Invalid Grade</span>
            </td>
            <td [hidden]="sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseMultiples'">
              <input [(ngModel)]="insuredValue.multiples" type="number" class="form-control small-input w-50" />
              <span class="errorMsg" [hidden]="!insuredValue.isMultipleInvalid">&nbsp;Invalid Multiple</span>
            </td>
            <td
              [hidden]="
                sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseDefined' &&
                sumInsured.peExtraBenefits.sumInsuredType != 'Defined'
              "
            >
              <input
                [ngModel]="insuredValue.stringValue | numberFormat"
                (ngModelChange)="insuredValue.stringValue = $event"
                type="text"
                class="form-control small-input w-50"
              />
              <span class="errorMsg" [hidden]="!insuredValue.isValueInvalid">&nbsp;Invalid Sum</span>
            </td>
            <td>
              <a [hidden]="sumInsured.peEsi.length === 1" (click)="removeEntry(i)" class="float-right text-secondary"
                ><i class="material-icons">delete</i></a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <button (click)="addNewEntry()" class="add-new-row-btn">
        <i class="material-icons">add</i> <span>Add new</span>
      </button>
    </div>
    <div class="row" [hidden]="!sumInsured.peExtraBenefits.isAllowed">
      <div class="col-md-12">
        <div class="form-group">
          <label>Instructions</label>
          <input [(ngModel)]="sumInsured.peExtraBenefits.instructions" type="text" class="form-control" />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #TopupTemplate let-sumInsured="sumInsured">
    <div class="row" *ngIf="sumInsured.peExtraBenefits.isAllowed">
      <div class="col-md-6">
        <div *ngIf="relationshipType === 'Self'" class="form-group">
          <label>Plan</label>
          <select [(ngModel)]="sumInsured.peExtraBenefits.plan" class="form-control" disabled>
            <option value="Floater">Floater</option>
            <option value="Individual">Individual</option>
          </select>
        </div>
        <div *ngIf="relationshipType !== 'Self'" class="form-group">
          <label>Plan</label>
          <select [(ngModel)]="parentalSumInsured.peExtraBenefits.plan" class="form-control" disabled>
            <option value="Floater">Floater</option>
            <option value="Individual">Individual</option>
          </select>
        </div>
      </div>
      <div class="col-md-6" *ngIf="relationshipType === 'Self'">
        <div class="form-group" *ngIf="allowedSumInsured.selfSumInsuredType === 'GradeWiseDefined'">
          <label>Type</label>
          <select
            (ngModelChange)="sumInsuredTypeOptionChanged()"
            [(ngModel)]="sumInsured.peExtraBenefits.sumInsuredType"
            class="form-control"
          >
            <option value="EqualToSumInsured" [hidden]="hideSumInsuredFields">Equal to Sum Insured</option>
            <option value="Defined">Defined</option>
            <option value="GradeWiseMultiples" [hidden]="hideGradewise || hideSumInsuredFields"
              >Grade Wise Multiples
            </option>
            <option value="GradeWiseDefined" [hidden]="hideGradewise">Grade Wise Defined</option>
          </select>
        </div>
        <div class="form-group" *ngIf="allowedSumInsured.selfSumInsuredType === 'Defined'">
          <label>Type</label>
          <select
            (ngModelChange)="sumInsuredTypeOptionChanged()"
            [(ngModel)]="sumInsured.peExtraBenefits.sumInsuredType"
            class="form-control"
          >
            <option value="EqualToSumInsured" [hidden]="hideSumInsuredFields">Equal to Sum Insured</option>
            <option value="Defined">Defined</option>
          </select>
        </div>
      </div>
      <div class="col-md-6" *ngIf="relationshipType !== 'Self'">
        <div class="form-group" *ngIf="parentalSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined'">
          <label>Type</label>
          <select
            (ngModelChange)="sumInsuredTypeOptionChanged()"
            [(ngModel)]="sumInsured.peExtraBenefits.sumInsuredType"
            class="form-control"
          >
            <option value="EqualToSumInsured" [hidden]="hideSumInsuredFields">Equal to Sum Insured</option>
            <option value="Defined">Defined</option>
            <option value="GradeWiseMultiples" [hidden]="hideGradewise || hideSumInsuredFields"
              >Grade Wise Multiples
            </option>
            <option value="GradeWiseDefined" [hidden]="hideGradewise">Grade Wise Defined</option>
          </select>
        </div>
        <div class="form-group" *ngIf="parentalSumInsured.peExtraBenefits.sumInsuredType === 'Defined'">
          <label>Type</label>
          <select
            (ngModelChange)="sumInsuredTypeOptionChanged()"
            [(ngModel)]="sumInsured.peExtraBenefits.sumInsuredType"
            class="form-control"
          >
            <option value="EqualToSumInsured" [hidden]="hideSumInsuredFields">Equal to Sum Insured</option>
            <option value="Defined">Defined</option>
          </select>
        </div>
      </div>
    </div>
    <div
      class="table-grid"
      [hidden]="
        sumInsured.peExtraBenefits.sumInsuredType === 'EqualToSumInsured' || !sumInsured.peExtraBenefits.isAllowed
      "
    >
      <table>
        <thead>
          <tr>
            <th
              [hidden]="
                sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseDefined' &&
                sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseMultiples'
              "
            >
              Grades
            </th>
            <th [hidden]="sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseMultiples'">Multiples</th>
            <th
              [hidden]="
                sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseDefined' &&
                sumInsured.peExtraBenefits.sumInsuredType != 'Defined'
              "
            >
              Sums
            </th>
          </tr>
          <span class="errorMsg" [hidden]="!sumInsured.peExtraBenefits.isValueRepeated"
            >&nbsp;Sums cannot be Repeated</span
          >
          <span class="errorMsg" [hidden]="!sumInsured.peExtraBenefits.isGradeWiseMultipleRepeated"
            >&nbsp;Grades cannot be Repeated</span
          >
          <span class="errorMsg" [hidden]="!sumInsured.peExtraBenefits.isGradeWiseDefinedRepeated"
            >&nbsp;Grades cannot be Repeated</span
          >
        </thead>
        <tbody>
          <tr *ngFor="let insuredValue of sumInsured.peTopUp; let i = index">
            <!-- <td *ngIf="sumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined'">
              <input [(ngModel)]="insuredValue.grade" type="text" class="form-control small-input w-50" />
              <span class="errorMsg" [hidden]="!insuredValue.isGradeInvalid">&nbsp;Invalid Grade</span>
            </td> -->
            <td
              *ngIf="
                sumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseMultiples' ||
                sumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined'
              "
            >
              <select class="form-control" [(ngModel)]="insuredValue.grade">
                <option *ngFor="let si of allowedSumInsured.sumInsuredList" [ngValue]="si.grade">
                  {{ si.grade }}
                </option>
              </select>
              <!-- <input [(ngModel)]="insuredValue.grade" type="text" class="form-control small-input w-50" /> -->
              <span class="errorMsg" [hidden]="!insuredValue.isGradeInvalid">&nbsp;Invalid Grade</span>
            </td>
            <td [hidden]="sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseMultiples'">
              <input [(ngModel)]="insuredValue.multiples" type="number" class="form-control small-input w-50" />
              <span class="errorMsg" [hidden]="!insuredValue.isMultipleInvalid">&nbsp;Invalid Multiple</span>
            </td>
            <td
              [hidden]="
                sumInsured.peExtraBenefits.sumInsuredType != 'GradeWiseDefined' &&
                sumInsured.peExtraBenefits.sumInsuredType != 'Defined'
              "
            >
              <input
                [ngModel]="insuredValue.stringValue | numberFormat"
                (ngModelChange)="insuredValue.stringValue = $event"
                type="text"
                class="form-control small-input w-50"
              />
              <span class="errorMsg" [hidden]="!insuredValue.isValueInvalid">&nbsp;Invalid Sum</span>
            </td>
            <td>
              <a [hidden]="sumInsured.peTopUp.length === 1" (click)="removeEntry(i)" class="float-right text-secondary"
                ><i class="material-icons">delete</i></a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <button (click)="addNewEntry()" class="add-new-row-btn">
        <i class="material-icons">add</i> <span>Add new</span>
      </button>
    </div>
    <div class="row" [hidden]="!sumInsured.peExtraBenefits.isAllowed">
      <div class="col-md-12">
        <div class="form-group">
          <label>Instructions</label>
          <input [(ngModel)]="sumInsured.peExtraBenefits.instructions" type="text" class="form-control" />
        </div>
      </div>
    </div>
  </ng-template>
</div>
