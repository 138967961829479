import { Component, OnInit, Input, Injector } from '@angular/core';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-user-id-mis',
  templateUrl: './user-id-mis.component.html',
  styleUrls: ['./user-id-mis.component.scss'],
})
export class UserIdMisComponent extends BaseComponent implements OnInit {
  @Input() policy: Policy;
  errorR = [];
  isExcelReportLoaded = true;

  constructor(
    private fb: FormBuilder,
    injector: Injector,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private cpMemberService: CPMemberService
  ) {
    super(injector);
  }

  ngOnInit(): void {}

  async onSubmit() {
    this.isExcelReportLoaded = false;
    await this.cpMemberService.getUserIdMISExcelReport(this.policy.policyNumber).then(
      result => {
        this.isExcelReportLoaded = true;
        FileSaver.saveAs(result, `User${this.policy.policyNumber}.xlsx`);
      },
      error => {}
    );
  }
}
