import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  error: boolean;
  isLoading = true;
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

  async ngOnInit() {
    // check for error
    if (this.route.snapshot.fragment.indexOf('error') >= 0) {
      this.error = true;
      return;
    }

    try {
      await this.authService
        .completeAuthentication()
        .then(x => {
          this.isLoading = false;
        })
        .catch(e => {
          this.error = e;
        });

      if (
        window.sessionStorage.getItem('loginType') === 'Internal' ||
        window.sessionStorage.getItem('loginType') === 'SSO'
      ) {
        if (window.sessionStorage.getItem('role') === 'BusinessAdmin') {
          this.router.navigate(['/biz-admin']);
        } else if (window.sessionStorage.getItem('role') === 'SM') {
          this.router.navigate(['/sm']);
        } else if (window.sessionStorage.getItem('role') === 'Operation') {
          this.router.navigate(['/operation']);
        }
      }
      if (
        window.sessionStorage.getItem('loginType') === 'External' ||
        window.sessionStorage.getItem('loginType') === 'SSO'
      ) {
        if (window.sessionStorage.getItem('role') === 'Member') {
          this.router.navigate(['/member']);
        } else if (window.sessionStorage.getItem('role') === 'Broker') {
          this.router.navigate(['/broker']);
        } else if (window.sessionStorage.getItem('role') === 'CPMember') {
          this.router.navigate(['/cp-member']);
        } else if (window.sessionStorage.getItem('role') === 'Operation') {
          this.router.navigate(['/operation']);
        }
      }
    } catch (error) {
      this.error = true;
    }
  }
}
