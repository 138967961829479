<header class="site-header">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand mr-5" routerLink="/member/home"><img src="assets/images/logo.png" alt=""/></a>
    <!-- <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button> -->
    <div *ngIf="!memberCorporate" class="shimmer wrapper">
      <span class="shimmer circle"></span>
      <span class="shimmer circle"></span>
    </div>
    <div class="user-menu" *ngIf="isAuthenticated && memberCorporate">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link">
            <span>{{ userName }}</span>
            <figure *ngIf="memberCorporate.logoImage; else demoLogoImage">
              <img src="{{ memberCorporate.logoImage }}" alt="" height="35" width="35" />
            </figure>
            <ng-template #demoLogoImage>
              <figure>
                <img src="assets/images/default_company.png" alt="" height="35" width="35" />
              </figure>
            </ng-template>

            <div hidden class="shimmer lighter circle ml-2"></div>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>
