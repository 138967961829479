import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporateService } from '../../services/corporate.service';

@Component({
  selector: 'app-business-admin',
  templateUrl: './business-admin.component.html',
  styleUrls: ['./business-admin.component.scss'],
})
export class BusinessAdminComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean;
  subscription: Subscription;
  corporates: any;
  constructor(
    injector: Injector,
    private readonly authService: AuthService,
    private readonly corporateService: CorporateService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscriptionsToDestroy.push(
      this.authService.isLoggedIn.subscribe(status => {
        this.isLoggedIn = status;
        // this.corporateService.existingCorporates$.subscribe(corps => {
        //   this.corporates = corps;
        // });
        // if (this.isLoggedIn && this.corporates === null) {
        // this.corporateService.init();
        // }
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
