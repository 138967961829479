import { Component, OnInit, Input, Injector } from '@angular/core';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { Validators, FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import * as moment from 'moment';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import * as FileSaver from 'file-saver';
import { HealthClaimReport } from 'src/app/cp-member/models/report/health-claim-report.model';
import { Broker } from 'src/app/shared/models/CorporatePortal/broker.model';
import { Sm } from 'src/app/shared/models/CorporatePortal/sm.model';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { SMService } from 'src/app/sm/service/sm.service';
import { BrokerService } from 'src/app/broker/service/broker.service';

@Component({
  selector: 'app-health-claim-mis',
  templateUrl: './health-claim-mis.component.html',
  styleUrls: ['./health-claim-mis.component.scss'],
})
export class HealthClaimMisComponent extends BaseComponent implements OnInit {
  @Input() policy: Policy;
  @Input() cashless: boolean;
  @Input() reimbursement: boolean;
  date = new Date();
  date1: Date;
  date2: Date;
  errorR = [];
  healthClaimForm = this.fb.group({
    toDate: ['', Validators.required],
    fromDate: ['', Validators.required],
    claim: ['', Validators.required],
    type: ['Report'],
  });

  claimMis = new HealthClaimReport();
  isClaimMisLoaded = null;
  isDashboardView = false;
  isExcelReportLoaded = true;

  constructor(
    private fb: FormBuilder,
    injector: Injector,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private cpMemberService: CPMemberService
  ) {
    super(injector);
    this.healthClaimForm.get('toDate');
    this.healthClaimForm.controls['fromDate'].valueChanges.subscribe(value => {
      value = moment(value)
        .add(1, 'month')
        .toDate();
      this.healthClaimForm.controls.toDate.setValue(value);
    });
  }

  ngOnInit(): void {
    const c = this.policy;
    this.isDashboardView = false;
  }

  StartDateSelected() {
    this.healthClaimForm.get('toDate').enable();
  }

  viewType() {
    this.isDashboardView = false;
  }

  async onSubmit() {
    const fromDate = moment(this.healthClaimForm.value.fromDate).format('DMMMYYYY');
    const toDate = moment(this.healthClaimForm.value.toDate).format('DMMMYYYY');

    if (this.healthClaimForm.value.type === 'Report') {
      this.isClaimMisLoaded = null;
      this.isExcelReportLoaded = false;

      await this.cpMemberService
        .getHealthClaimMISExcelReport(this.policy.policyNumber, this.healthClaimForm.value.claim, fromDate, toDate)
        .then(
          result => {
            this.isExcelReportLoaded = true;
            FileSaver.saveAs(result, `Claim${this.policy.policyNumber}.xlsx`);
          },
          error => {}
        );
    } else if (this.healthClaimForm.value.type === 'Dashboard') {
      this.isClaimMisLoaded = false;
      await this.cpMemberService
        .getHealthClaimMISView(this.policy.policyNumber, this.healthClaimForm.value.claim, fromDate, toDate)
        .then(x => {
          this.claimMis = x;
          this.isClaimMisLoaded = true;
          this.isDashboardView = true;
        })
        .catch(x => {
          console.log('error');
        });
    }
  }
}
