import { PeGradeSiModel } from './peGradeSiModel';

export class AllowedSumInsuredModel {
  selfSumInsuredType: string;
  sumInsuredList: PeGradeSiModel[] = [];
  isValueRepeated: boolean;
  isGradeWiseDefinedRepeated: boolean;

  constructor(model) {
    Object.assign(this, model);
  }

  public isValid() {
    if (this.selfSumInsuredType === 'Defined') {
      const insuredValues = this.sumInsuredList.map(el => {
        return el.sumInsured;
      });

      const checkValues = this.checkIfDuplicateExists(insuredValues);

      if (checkValues) {
        this.isValueRepeated = true;
        return false;
      } else {
        this.isValueRepeated = false;
      }
    }

    if (this.selfSumInsuredType === 'GradeWiseDefined') {
      const insuredValues = this.sumInsuredList.map(el => {
        return el.grade.toLowerCase() + el.sumInsured.toString();
      });

      const checkValues = this.checkIfDuplicateExists(insuredValues);

      if (checkValues) {
        this.isGradeWiseDefinedRepeated = true;
        return false;
      } else {
        this.isGradeWiseDefinedRepeated = false;
      }
    }
    let proceed = true;
    for (let index = 0; index < this.sumInsuredList.length; index++) {
      const element = new PeGradeSiModel(this.sumInsuredList[index]);
      this.sumInsuredList[index] = element;
      this.sumInsuredList[index].isValid(this.selfSumInsuredType);
      proceed = proceed && element.isValid(this.selfSumInsuredType);
    }
    return proceed;
  }

  checkIfDuplicateExists(sumInsured) {
    return new Set(sumInsured).size !== sumInsured.length;
  }
}
