<div class="form-box">
  <div class="form-box scroll_class">
  <h3 class="main-heading">DECLARATION BY THE INSURED - SUBMISSION OF CLAIM DOCUMENTS ON SOFT COPIES FOR MEDICLAIM</h3>
  <div class="form-box-body">
    <p>
      I, {{ name }} aged {{ age }} years, enrolled under the Group Mediclaim policy / Retail Health Insurance policy of
      Reliance General Insurance Co limited bearing Policy # {{ id }} / Health Card ID # {{ uhid }} hereby accept the
      following
    </p>
    <ol>
      <li>
        I/we herewith understand that Reliance General Insurance Co limited (RGICL) reserves the right to process the
        claim as per the policy terms and conditions of the policy.
      </li>
      <li>
        RGICL reserves its right to settle the claim on soft copies and reserves it right to call for original documents
        if deemed necessary at any given point of time
      </li>
      <li>
        I/we hereby declare that the information furnished is true and correct to the best of my knowledge and belief,
        In Addition to the above I/we also consent RGICL, to seek necessary medical information / documents from the
        hospital / Medical Practitioner who has attended on the person against whom this claim is made. I/we will not be
        making any claim with any other insurance company for any undue benefit.
      </li>
    </ol>
    <p>
      In addition to the above I also declare that RGICL will reserve the rights to seek all the original documents in
      physical form for a period of one year from the date of claim submission and also will be refunding the entire
      claim amount to RGICL if any misrepresentation/concealment of facts are been noted in my respective
      hospitalization related to claim/ hospitalization.
    </p>

    <div class="mb-3">
      <mat-radio-group [(ngModel)]="finalDeclarationDone">
        <mat-radio-button [value]="'yes'" (change)="isFinalDeclarationDone('yes')" class="mr-5">Yes</mat-radio-button>
        <mat-radio-button [value]="'no'" (change)="isFinalDeclarationDone('no')">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <h6>Thanks for Intimation</h6><!--, please dispatch original documents to Health Claim Team</h6>
      <div>
        <strong
          >Reliance General Insurance<br />
          1-89/3/B/40 to 42/ks/301 3rd floor, Krishe Block, Krishe Sapphire, South Wing,<br />
          Survery NO-88 Hi Tech City Main Road, Madhapur, Hyderabad Pincode: 500081</strong
        >
      </div>-->
    </div>
  </div>
</div>
</div>
