import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BackendService } from 'src/app/shared/services/backend.service';
import { rejects } from 'assert';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root',
})
export class OperationService {
  operationProfile: BehaviorSubject<any>;

  constructor(private readonly backendService: BackendService) {
    this.operationProfile = new BehaviorSubject<any>({});
  }

  async getOperationProfile(): Promise<any> {
    return new Promise(async resolve => {
      const result = this.backendService.get('Operation');
      this.operationProfile.next(result);
      resolve(result);
    });
  }

  async getAllCorporates(): Promise<Array<any>> {
    return new Promise(async (resolve, reject) => {
      const result = this.backendService.get('Corporate');
      resolve(result);
    });
  }

  async getPolicyForCorporate(corporateId): Promise<Array<any>> {
    return new Promise(async (resolve, reject) => {
      const result = this.backendService.get('CorporatePortal/policies/corporate/' + corporateId);
      resolve(result);
    });
  }
  async downloadEndorsement(corporateId, policyNumber, fromDate, toDate): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService
        .downloadFile(
          'CorporatePortal/DownloadEndorsement/'+
            'PolicyNumber/' +
            policyNumber +
            '/fromDate/' +
            fromDate +
            '/toDate/' +
            toDate
        )
        .then(fileBlob => {
          const blob = new Blob([fileBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          return resolve(blob);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
