<div class="contact-us-wrapper">
  <!-- end row -->
  <div class="row">
    <div class="card w-100">
      <div class="row mx-2">
        <div class="col-md-7">
          <div class="contact-info-wrapper">
            <div>
              <h3>Write To Us At (Correspondence Only)</h3>
              <address>
                Reliance General Insurance, RCare Health, <br />
                No.1-89/3/B/40 to 42/KS/301, 3rd floor, <br />
                Krishe Block, Krishe Sapphire, Madhapur, <br />
                Hyderabad-Telangana-500081
              </address>
            </div>
            <div>
              <h3>Email Us</h3>
              <a href="mailto:rgicl.rcarehealth@relianceada.com">rgicl.rcarehealth@relianceada.com</a>
            </div><br />
            <div>
              <h3>Call Center Number</h3>
              <p>Reliance General Insurance (RGI) : +91 22 48903009</p>
            </div>
          </div>
        </div>
        <div class="col-md-5 p-0">
          <form [formGroup]="form">
            <div class="form-group contact-form-wrapper">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Name<sup>*</sup></label>
                  <input class="form-control" type="text" formControlName="name" />
                  <span class="error-msg small" *ngIf="form.get('name').dirty && form.get('name').status === 'INVALID'"
                    >Invalid Name
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Number<sup>*</sup></label>
                  <input class="form-control" type="text" formControlName="number" />
                  <span
                    class="error-msg small"
                    *ngIf="form.get('number').dirty && form.get('number').status === 'INVALID'"
                    >Invalid Mobile Number
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Email<sup>*</sup></label>
                  <input class="form-control" type="text" formControlName="emailBeforeEncrypt" />
                  <span
                    class="error-msg small"
                    *ngIf="form.get('emailBeforeEncrypt').dirty && form.get('emailBeforeEncrypt').status === 'INVALID'"
                    >Invalid Email
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Message<sup>*</sup></label>
                  <textarea required="" class="form-control" formControlName="message" rows="3"></textarea>
                  <span
                    class="error-msg small"
                    *ngIf="form.get('message').dirty && form.get('message').status === 'INVALID'"
                    >Invalid Message
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="spinner-border text-primary mr-3" [hidden]="!isLoading" *ngIf="isLoading"></div>
                <button
                  class="btn btn-primary mt-1 w-100"
                  [disabled]="!form.valid || isLoading"
                  [hidden]="isLoading"
                  (click)="emailSubmit()"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>
