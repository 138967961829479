import { OnInit, OnDestroy, Injector } from '@angular/core';
import { LoggerService } from '../services/logger.service';
import { AlertService } from '../services/alert.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';

export class BaseComponent implements OnInit, OnDestroy {
  public pageName: string;
  public renderedOn: string;
  public pluralWordMapping: {};
  protected logger: LoggerService;
  protected alert: AlertService;
  protected subscriptionsToDestroy: Subscription[] = [];
  protected container: any;
  public titleService: Title;
  public dialog: MatDialog;

  constructor(private _injector: Injector) {
    this.titleService = _injector.get(Title);
    (this.dialog = _injector.get(MatDialog)), (this.logger = _injector.get(LoggerService));
    this.alert = _injector.get(AlertService);
  }

  ngOnInit(): void {
    this.detectFormFactor();
  }

  ngOnDestroy(): void {
    this.subscriptionsToDestroy.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  enableScrolling() {
    document.body.style.overflow = 'visible';
  }

  disableScrolling() {
    document.body.style.overflow = 'hidden';
  }

  protected setPageTitle(title: string, name: string, data = {}) {
    this.titleService.setTitle(title);
    this.logger.info(`Page Name Changed to ${name}`, data, 'BaseComponent', 'setPageTitle');
  }

  private detectFormFactor() {
    if (navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)/i)) {
      this.renderedOn = 'Mobile';
    } else {
      this.renderedOn = 'Desktop';
    }
  }
}
