export class PeExtraBenefits {
  id = 0;
  pePolicyId: number;
  relationship: string;
  benefitType: string;
  isAllowed = false;
  sumInsuredType = 'EqualToSumInsured';
  plan = 'Floater';
  errorMsg = '';
  instructions = '';
  isValueRepeated = false;
  isGradeWiseMultipleRepeated = false;
  isGradeWiseDefinedRepeated = false;
  isPremiumApplicable: boolean;
  isPremiumVisible: boolean;
  isRefundApplicable: boolean;
  premiumType: string;
  constructor() {}
}
