import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './components/alert/alert.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SearchFilterPipe } from './pipes/search.pipe';
import { RouterModule } from '@angular/router';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ErrorMsgDialogComponent } from './components/error-msg-dialog/error-msg-dialog.component';
import { PopupModelComponent } from './components/popup-model/popup-model.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MaterialModule } from '../material/material.module';
import { CpMemberRoutingModule } from '../cp-member/cp-member-routing.module';
import { DyanamicFieldsFormModule } from '../dyanamic-fields-form/dyanamic-fields-form.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrokerRoutingModule } from '../broker/broker-routing.module';
import { DateComponent } from './components/date/date.component';
import { NoteMsgComponent } from './components/note-msg/note-msg.component';
import { PremiumTableComponent } from './components/premium-table/premium-table.component';
import { ShowJobStatusComponent } from './components/show-job-status/show-job-status.component';
import { WelcomeEmailTimeGridComponent } from './components/welcome-email-time-grid/welcome-email-time-grid.component';
import { ShowMemberUploadFilesComponent } from './components/show-member-upload-files/show-member-upload-files.component';

@NgModule({
  declarations: [
    AlertComponent,
    PageNotFoundComponent,
    AuthCallbackComponent,
    SearchFilterPipe,
    NumberFormatPipe,
    ConfirmDialogComponent,
    ErrorMsgDialogComponent,
    PopupModelComponent,
    DateComponent,
    NoteMsgComponent,
    PremiumTableComponent,
    ShowJobStatusComponent,
    WelcomeEmailTimeGridComponent,
    ShowMemberUploadFilesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    MaterialModule,
    DyanamicFieldsFormModule,
    SlickCarouselModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    AlertComponent,
    PageNotFoundComponent,
    SearchFilterPipe,
    NumberFormatPipe,
    MatButtonModule,
    MatDialogModule,
    PopupModelComponent,
    DateComponent,
  ],
  providers: [],
})
export class SharedModule {}
