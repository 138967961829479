import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FieldConfig, Validator } from '../../model/form-fields';
import * as moment from 'moment';
import * as loadash from 'lodash';
import { Location } from '@angular/common';
import { Relationship } from 'src/app/shared/enums/Relationship.enum';
import { Router } from '@angular/router';

@Component({
  exportAs: 'dynamicForm',
  // tslint:disable-next-line: component-selector
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges {
  @Input() fields: FieldConfig[] = [];
  @Input() dependent = [];
  @Input() submitButtonName: string;
  @Input() submitButtonClicked = false;
  sectionWise: any;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  validation = true;
  displayFloaterNote = false;
  form: FormGroup = new FormGroup({});

  get value() {
    return this.form.value;
  }
  constructor(private fb: FormBuilder, private location: Location, private readonly router: Router) {}
  ngOnChanges() {
    this.initialise();
  }

  cancel() {
    this.location.back();
    // this.router.navigate(['member/enrollment']);
  }

  orderBYSection() {
    if (this.fields === null) {
      return;
    }
    this.fields.forEach(x => {
      if (x.section === 'Profile') {
        x.order = 2;
      } else if (x.section === 'Contact') {
        x.order = 3;
      } else if (x.section === 'Policy') {
        x.order = 4;
      } else if (x.section === 'Employee') {
        x.order = 1;
      }
    });

    return this.fields;
  }

  ngOnInit() {
    this.initialise();
  }

  initialise() {
    this.displayFloaterNotification(this.fields);
    this.fields = this.orderBYSection();
    if (this.fields.length !== 0) {
      this.form = this.createControl();
      this.sectionWise = loadash.groupBy(this.fields, 'order');
    }
  }

  displayFloaterNotification(fields) {
    const relationshipIndex = loadash.findIndex(fields, function(o) { return o.label === 'Relationship'; });
    if (relationshipIndex > -1 && Object.values(Relationship).includes(fields[relationshipIndex].value)) {
      this.displayFloaterNote = true;
    }
  }
  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      this.submit.emit(this.form);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  validations() {
    this.fields.forEach(x => {
      if (x.type === 'button') {
        return;
      }

      // add pattern validation
      // add this before required
      x.validations.forEach(validation => {
        if (validation.name === 'pattern' && validation.regex !== null && x.name !== 'Date Of Birth' && x.name !== 'Date Of Joining') {
          validation.validator = Validators.pattern(validation.regex);
        } else if (validation.name === 'date') {
          x.validationsCustom = [];
          x.validationsCustom.push(validation.regex);
          x.validations.splice(x.validations.indexOf(validation), 1);
        }
      });

      // add validation as required
      if (x.isMandatory) {
        const val: Validator = {
          name: 'required',
          validator: Validators.required,
          message: 'Required ' + x.label,
        };

        const present = x.validations.find(v => v.name === 'required');
        if (!present) {
          x.validations.push(val);
        }
      }
    });

    return this.fields;
  }

  createControl() {
    const group = this.fb.group({});

    if (this.validation) {
      this.fields = this.validations();
      this.validation = false;
    }
    let control;

    this.fields.forEach(field => {
      if (field.type === 'button') {
        return;
      }
      if (field.type === 'date') {
        control = this.fb.control(
          { value: moment(field.value), disabled: !field.isEditable },
          this.bindValidations(field.validations || [])
        );
      } else if (field.type === 'input' && field.name === 'Grade') {
        control = this.fb.control(
          { value: field.value, disabled: true },
          this.bindValidations(field.validations || [])
        );
      } else {
        control = this.fb.control(
          { value: field.value, disabled: !field.isEditable },
          this.bindValidations(field.validations || [])
        );
      }
      group.addControl(field.name, control);
    });
    return group;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
