export class HealthClaimReport {
  fresh_Settled_ApprovedCount: number;
  fresh_Settled_ApprovedAmount: number;
  fresh_Settled_RejectedCount: number;
  fresh_Settled_RejectedAmount: number;
  fresh_Settled_Closed_CancelledCount: 1;
  fresh_Settled_Closed_CancelledAmount: number;
  fresh_UnderPro_OpenIntimationsCount: number;
  fresh_UnderPro_OpenIntimationsAmount: number;
  fresh_UnderPro_QueriedCount: number;
  fresh_UnderPro_QueriedAmount: number;
  fresh_UnderPro_WIPCount: number;
  fresh_UnderPro_WIPAmount: number;
  fresh_SubTotalCount: number;
  fresh_SubTotalAmount: number;
  enhancement_Settled_ApprovedCount: number;
  enhancement_Settled_ApprovedAmount: number;
  enhancement_Settled_RejectedCount: number;
  enhancement_Settled_RejectedAmount: number;
  enhancement_Settled_Closed_CancelledCount: number;
  enhancement_Settled_Closed_CancelledAmount: number;
  enhancement_UnderPro_QueriedCount: number;
  enhancement_UnderPro_QueriedAmount: number;
  enhancement_UnderPro_WIPCount: number;
  enhancement_UnderPro_WIPAmount: number;
  enhancement_SubTotalCount: number;
  enhancement_SubTotalAmount: number;
  prePost_Settled_PaidCount: number;
  prePost_Settled_PaidAmount: number;
  prePost_Settled_RejectedCount: number;
  prePost_Settled_RejectedAmount: number;
  prePost_Settled_Closed_CancelledCount: number;
  prePost_Settled_Closed_CancelledAmount: number;
  prePost_UnderPro_QueriedCount: number;
  prePost_UnderPro_QueriedAmount: number;
  prePost_UnderPro_WIPCount: number;
  prePost_UnderPro_WIPAmount: number;
  prePost_SubTotalCount: number;
  prePost_SubTotalAmount: number;
}
