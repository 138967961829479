<div class="corporate-details-component" *ngIf="corporate">
  <div class="corporate-details-header">
    <div class="corporate-info-wrapper">
      <div class="container">
        <a class="link-go-back" routerLink="../list">
          <svg fill="none" height="11" viewBox="0 0 10 11" width="10" xmlns="http://www.w3.org/2000/svg">
            <path
              clip-rule="evenodd"
              d="M5.44212 1.56699C5.68619 1.32291 5.68619 0.927191 5.44212 0.683117C5.19804 0.439042 4.80232 0.439042 4.55824 0.683117L0.1833 5.05806C-0.0607745 5.30213 -0.0607745 5.69786 0.1833 5.94193L4.55824 10.3169C4.80232 10.561 5.19804 10.561 5.44212 10.3169C5.68619 10.0728 5.68619 9.67708 5.44212 9.433L2.1341 6.12498H9.37513C9.7203 6.12498 10.0001 5.84517 10.0001 5.49999C10.0001 5.15482 9.7203 4.875 9.37513 4.875H2.1341L5.44212 1.56699Z"
              fill-rule="evenodd"
            ></path>
          </svg>
          Go Back
        </a>
        <div class="corporate-details-wrapper">
          <div class="img-wrapper">
            <img class="corporate-img" src="{{ corporate.logoUrl }}" />
          </div>
          <div class="name-wrapper">
            <h3 class="corporate-name">{{ corporate.name }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-tabs">
    <div class="container">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="Corporate-tab"
            data-toggle="tab"
            href="#Corporate"
            role="tab"
            aria-controls="Corporate"
            aria-selected="true"
            >Profile</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="Pre-enrollment-tab"
            data-toggle="tab"
            href="#Pre-enrollment"
            role="tab"
            aria-controls="Pre-enrollment"
            aria-selected="false"
          >
            Pre-enrollment</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="Corporate-portal-tab"
            data-toggle="tab"
            href="#Corporate-portal"
            role="tab"
            aria-controls="Corporate-portal"
            aria-selected="false"
          >
            Corporate Portal</a
          >
        </li>
      </ul>
    </div>
  </div>

  <div class="tab-content">
    <div class="tab-pane active" id="Corporate" role="tabpanel">
      <app-create-corporate [corporate]="corporate" (corporateChange)="corporate = $event"></app-create-corporate>
    </div>
    <div class="tab-pane" id="Pre-enrollment" role="tabpanel">
      <app-pre-enrollment [corporate]="corporate"></app-pre-enrollment>
    </div>
    <div class="tab-pane" id="Corporate-portal" role="tabpanel">
      <app-corporate-portal [corporate]="corporate"></app-corporate-portal>
    </div>
  </div>
</div>
