<div class="member-component-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-4">
        <div class="card card-style-1">
          <div class="card-body">
            <div class="card-title row justify-content-between align-items-center">
              <h4 class="m-0 header-title">My Profile</h4>
            </div>
            <div class="row">
              <div class="col-12">
                <ul class="inner-cards profile-details mt-2 ml-2 mr-2">
                  <li>
                    <div class="form-group w-100 m-0">
                      <div class="dropdown card-header-dropdown">
                        <button
                          class="btn btn-sm dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          <span *ngIf="currentPolicy.hasOwnProperty('policyId')" class="policy-content">
                            <span *ngIf="currentPolicy.isActive" class="logged"></span>
                            <span *ngIf="!currentPolicy.isActive" class="notLogged"></span>
                            <span *ngIf="currentPolicy.riskStartDate !== undefined && currentPolicy.onScreenDisplayAvailable == false">
                              {{ currentPolicy.riskStartDate.split('-')[0] }}-{{
                                currentPolicy.riskEndDate.split('-')[0]
                              }}
                              - {{ currentPolicy.policyNumber }} - {{currentPolicy.policyType}}</span
                            >
                            <span *ngIf="currentPolicy.riskStartDate !== undefined && currentPolicy.onScreenDisplayAvailable == true">{{currentPolicy.onScreenDisplayPolicy}}</span>
                          </span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <div *ngFor="let policy of policies" (click)="onNamePolicyChanged(policy)">
                            <a class="dropdown-item">
                              <span *ngIf="policy.isActive" class="logged"></span>
                              <span *ngIf="!policy.isActive" class="notLogged"></span>
                              <span *ngIf="policy.onScreenDisplayAvailable == false">
                                {{ policy.riskStartDate.split('-')[0] }}-{{ policy.riskEndDate.split('-')[0] }} -
                                {{ policy.policyNumber }} - {{policy.policyType}}</span
                              >
                              <span *ngIf="policy.onScreenDisplayAvailable == true">{{policy.onScreenDisplayPolicy}}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul class="list-unstyled shimmer-wrapper" *ngIf="!ismemberLoaded && !isHrView">
                  <li class="shimmer"></li>
                  <li class="shimmer"></li>
                  <li class="shimmer"></li>
                  <li class="shimmer"></li>
                  <li class="shimmer"></li>
                  <li class="shimmer"></li>
                </ul>
                <ul class="inner-cards profile-details" *ngIf="isEmployeeBelongsToPolicy && ismemberLoaded && !isHrView">
                  <li>
                    <span class="field-name mr-2">Name:</span>
                    <span class="field-data">{{ memberSelf.insuredName }}</span>
                  </li>
                  <li>
                    <span class="field-name mr-2">Mobile:</span>
                    <span class="field-data">{{ memberSelf.mobileNo }}</span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Email:</span>
                    <span class="field-data">{{ memberSelf.emailID }}</span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Address:</span>
                    <span class="field-data">{{ memberSelf.address1 }} </span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Date of Joining:</span>
                    <span class="field-data">{{ formatDoj(memberSelf.doj) }} </span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Employee ID:</span>
                    <span class="field-data">{{ memberSelf.memberIdEmpId }} </span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Abha ID:</span>
                    <span class="field-data">{{ memberSelf.insuredABHAId }}</span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Abha Address:</span>
                    <span class="field-data">{{ memberSelf.insuredABHAAddress }}</span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Relationship:</span>
                    <span class="field-data">Self </span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Age:</span>
                    <span class="field-data">{{ memberSelf.age }} Years </span>
                  </li>
                </ul>
                <ul class="list-unstyled shimmer-wrapper" *ngIf="!ishrProfileLoaded && isHrView">
                  <li class="shimmer"></li>
                  <li class="shimmer"></li>
                  <li class="shimmer"></li>
                </ul>
                <ul class="inner-cards profile-details" *ngIf="ishrProfileLoaded && isHrView">
                  <li>
                    <span class="field-name mr-2">Name:</span>
                    <span class="field-data">{{ hrProfile.name }}</span>
                  </li>
                  <li>
                    <span class="field-name mr-2">Mobile:</span>
                    <span class="field-data">{{ hrProfile.mobileNo }}</span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Email:</span>
                    <span class="field-data">{{ hrProfile.email }}</span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Date of Joining:</span>
                    <span class="field-data">{{ memberDoj }} </span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Employee ID:</span>
                    <span class="field-data">{{ hrProfile.hrEmpId }} </span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Address:</span>
                    <span class="field-data">{{ memberSelf.address1 }} </span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Abha ID:</span>
                    <span class="field-data">{{ memberSelf.insuredABHAId }}</span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Abha Address:</span>
                    <span class="field-data">{{ memberSelf.insuredABHAAddress }}</span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Relationship:</span>
                    <span class="field-data">Self </span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Age:</span>
                    <span class="field-data">{{ memberAge }} Years </span>
                  </li>
                  <li class="w-100">
                    <span class="field-name mr-2">Covered Up-to Sum Insured:</span>
                    <span class="field-data">{{ coveredUptoSumInsured }} </span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>


      <div class="col-xs-12 col-md-6 col-lg-4">
        <div class="card card-style-1">
          <div class="card-body depedent-details" *ngIf="isHr && isHrView">
            <div class="card-title row justify-content-between align-items-center">
              <h4 class="m-0 header-title">
                Policy Details
              </h4>
            </div>
            <div class="row">
              <div class="col-md-12">
                <ul class="list-unstyled shimmer-wrapper" *ngIf="!isPolicyInfoLoaded">
                  <li class="shimmer"></li>
                  <li class="shimmer"></li>
                  <li class="shimmer"></li>
                  <li class="shimmer"></li>
                </ul>
                <ul class="policy-infocards" *ngIf="isPolicyInfoLoaded">
                  <li>
                    <span class="field-data">
                      Active Insured
                      <strong>{{ policyInfo.activeInsured }} </strong>
                    </span>
                  </li>
                  <li>
                    <span class="field-data">
                      Total Endorsements
                      <strong>{{ policyInfo.totalEndorsements }} </strong>
                    </span>
                  </li>
                  <li>
                    <span class="field-data">
                      Cashless Claims
                      <strong>{{ policyInfo.cashlessClaims }} </strong>
                    </span>
                  </li>
                  <li>
                    <span class="field-data">
                      Reimbursement Claims
                      <strong>{{ policyInfo.reimbursementClaims }} </strong>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- end row -->
          </div>
  
          <!-- end card -->
          <div class="card-body depedent-details" *ngIf="isEmployeeBelongsToPolicy && !isHrView">
            <div class="card-title row justify-content-between align-items-center">
              <h4 class="my-0 header-title">
                Policy Details
              </h4>
            </div>
            <div *ngIf="isDependentLoaded && dependent.length === 0">
              <h6 class="my-4 ml-4">No Dependent Found!</h6>
            </div>
            <ul class="list-unstyled shimmer-wrapper" *ngIf="!isDependentLoaded">
              <li class="shimmer-row">
                <div class="image-shimmer"></div>
                <div class="shimmer"></div>
              </li>
              <li class="shimmer-row">
                <div class="image-shimmer"></div>
                <div class="shimmer"></div>
              </li>
              <li class="shimmer-row">
                <div class="image-shimmer"></div>
                <div class="shimmer"></div>
              </li>
              <li class="shimmer-row">
                <div class="image-shimmer"></div>
                <div class="shimmer"></div>
              </li>
            </ul>
  
            <div class="row">
              <div class="col-md-12">
                <ul class="inner-cards">
                  <li *ngFor="let dep of dependent">
                    <img
                      *ngIf="dep.relationShipId === 'SELF'  && dep.sex==='M'"
                      class="d-flex align-self-start rounded mr-3"
                      src="assets/images/husband.png"
                      alt="Generic placeholder image"
                      height="64"
                    />
                    <img
                    *ngIf="dep.relationShipId === 'SELF'  && dep.sex==='F'"
                    class="d-flex align-self-start rounded mr-3"
                    src="assets/images/wife.png"
                    alt="Generic placeholder image"
                    height="64"
                  />
                    <img
                      *ngIf="dep.relationShipId === 'SPOUSE'  && dep.sex==='M'"
                      class="d-flex align-self-start rounded mr-3"
                      src="assets/images/husband.png"
                      alt="Generic placeholder image"
                      height="64"
                    />
                    <img
                    *ngIf="dep.relationShipId === 'SPOUSE'  && dep.sex==='F'"
                    class="d-flex align-self-start rounded mr-3"
                    src="assets/images/wife.png"
                    alt="Generic placeholder image"
                    height="64"
                  />
                    <img
                      *ngIf="dep.relationShipId === 'SON'"
                      class="d-flex align-self-start rounded mr-3"
                      src="assets/images/son.png"
                      alt="Generic placeholder image"
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationShipId === 'DAUGHTER'"
                      class="d-flex align-self-start rounded mr-3"
                      src="assets/images/daughter.png"
                      alt="Generic placeholder image"
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationShipId === 'FATHER'"
                      class="d-flex align-self-start rounded mr-3"
                      src="assets/images/father.png"
                      alt="Generic placeholder image"
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationShipId === 'MOTHER'"
                      class="d-flex align-self-start rounded mr-3"
                      src="assets/images/mother.png"
                      alt="Generic placeholder image"
                      height="64"
                    />
                    <span class="field-data"
                      >{{ dep.insuredName
                      }}<small>
                        <strong>{{ dep.relationShipId }} - </strong> {{ dep.age }} Years</small
                      ></span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>

        <div class="card card-style-1" *ngIf="currentPolicy.policyNote">
          <div class="card-body p-4 text-danger">
            Please reach out to HR for any changes to your name, date of birth, or mobile number
          </div>
        </div>
      </div>


      <div class="col-xs-12 col-lg-4">
        <div class="card w-100">
          <div class="card-body p-0">
            <img src="/assets/images/offerblock.png" width="100%" alt="" />
          </div>
        </div>
        <div class="card w-100">
          <div class="card-body p-0">
            <ul class="comp-logos">
              <li>
                <img src="/assets/images/rha.jpg" alt="" />
              </li>
              <li>
                <img src="/assets/images/logo-2.jpg" alt="" />
              </li>
              <li>
                <img src="/assets/images/rha.jpg" alt="" />
              </li>
              <li>
                <img src="/assets/images/RhealthBeat.jpg" alt="" />
              </li>
              <li>
                <img src="/assets/images/offer-bags.jpg" alt="" />
              </li>
            </ul>
          </div>
        </div>
        
        <div class="card w-100">
          <div class="card-body card-inverted-blue">
            <div class="card-title row justify-content-between align-items-center">
              <h4 class="m-0 header-title">
                Looking for general insurance products
                <hr />
              </h4>
            </div>
            <div class="row">
              <div class="col-md-12">
                <ul class="general-insurance-product">
                  <li>
                    <span class="icon">
                      <img src="/assets/images/health-home.png" alt="" />
                    </span>
                    <span class="icon-title">
                      Health
                    </span>
                  </li>
                  <li>
                    <span class="icon">
                      <img src="/assets/images/home.png" alt="" />
                    </span>
                    <span class="icon-title">
                      Home
                    </span>
                  </li>
                  <li>
                    <span class="icon">
                      <img src="/assets/images/motor.png" alt="" />
                    </span>
                    <span class="icon-title">
                      Motor
                    </span>
                  </li>
                  <li>
                    <span class="icon">
                      <img src="/assets/images/travel.png" alt="" />
                    </span>
                    <span class="icon-title">
                      Travel
                    </span>
                  </li>
                  <li>
                    <span class="icon">
                      <img src="/assets/images/sme.png" alt="" />
                    </span>
                    <span class="icon-title">
                      SME
                    </span>
                  </li>
                  <li>
                    <span class="icon">
                      <img src="/assets/images/other.png" alt="" />
                    </span>
                    <span class="icon-title">
                      Others
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>

        <!-- <div class="card w-100" *ngIf="!isHrView">
          <div class="card-body bg-blue p-1">
            <div class="row">
              <div class="col-md-12">
                <ul class="comp-logos">
                  <li *ngIf="currentPolicy.rHealthAssist">
                    <a [href]="rhealthAssist" target="_blank"> <img src="/assets/images/rha.jpg" alt=""/></a>
                  </li>
                  <li *ngIf="currentPolicy.rHealthCircle">
                    <a [href]="rhealthCircle" target="_blank"> <img src="/assets/images/logo-2.jpg" alt=""/></a>
                  </li>
                  <li *ngIf="currentPolicy.healthOPedia">
                    <a href="https://www.reliancegeneral.co.in/Insurance/Self-Help/Health-O-Pedia.aspx" target="_blank">
                      <img src="/assets/images/Health-o-pedias.png" alt=""
                    /></a>
                  </li>
                  <li *ngIf="currentPolicy.rHealthBeat">
                    <a
                      href="https://www.reliancegeneral.co.in/Insurance/Insurance-Information/Wellness/R-HealthBeat.aspx"
                      target="_blank"
                    >
                      <img src="/assets/images/RhealthBeat.jpg" alt=""
                    /></a>
                  </li>
                  <li *ngIf="currentPolicy.valueDeals">
                    <a
                      href="https://www.reliancegeneral.co.in/Insurance/Insurance-Information/Wellness/Value-Deals.aspx"
                      target="_blank"
                    >
                      <img src="/assets/images/offer-bags.jpg" alt=""
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
