import { IntimateClaimUploadDocuments } from "src/app/cp-member/models/Intimate-claim/intimate-claim-upload-documents.model";

export class GPAMail{
    emailId:string;
    subject:string;
    message:string;
    mobileNumber:string;
    intimationType:string;
    DocumentList: documentList[] = [];
}

export class documentList{
    FileName: string;
    FileBytes: any;
    DocTypeId: any;
  }