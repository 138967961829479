import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WelcomeEmailTimeModel } from './welcome-email-time-model.model';

@Component({
  selector: 'app-welcome-email-time-grid',
  templateUrl: './welcome-email-time-grid.component.html',
  styleUrls: ['./welcome-email-time-grid.component.scss']
})
export class WelcomeEmailTimeGridComponent implements OnInit {
  dateTimeAllocation: any;

  constructor(
    public dialogRef: MatDialogRef<WelcomeEmailTimeModel>,
    @Inject(MAT_DIALOG_DATA) public data: WelcomeEmailTimeModel
  ) {
    // Update view with given values
    this.dateTimeAllocation = data.dateTimeAllocation;
  }

  ngOnInit() {}

  onConfirm() {
    this.dialogRef.close(true);
  }
}
