export const environment = {
  production: false,
  apiBaseUrl: 'https://ghipuatapi.reliancegeneral.co.in/api/v1/',
  envName: 'UAT',
  cookieDomain: '',
  sentryIOUrl: '',
  mixpanelToken: '',
  siteVersion: '%VERSION%',
  authority: 'https://cpuatlogin.reliancegeneral.co.in/',
  loginToken: 'sieWMsDZ1BOamVf9BAdhkVlzoh4qGWQe',
  scopes: 'openid profile GhipApi',
  redirectUri: 'https://preenrolluat.reliancegeneral.co.in/auth-callback/',
  post_logout_redirect_uri: 'https://preenrolluat.reliancegeneral.co.in/',
  client: 'GhipUAT',
};
