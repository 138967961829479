import { Component, OnInit, Input, Output, OnDestroy, Injector } from '@angular/core';
import { CorporateModel } from '../../../shared/models/corporate/corporate.model';
import { BaseComponent } from '../../../shared/components/base.component';
import { PreEnrollmentService } from '../../services/preEnrollment.service';
import { PreEnrollmentPolicyModel } from '../../../shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { Router } from '@angular/router';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { resolve } from 'url';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AlertService } from 'src/app/shared/services/alert.service';
@Component({
  selector: 'app-pre-enrollment',
  templateUrl: './pre-enrollment.component.html',
  styleUrls: ['./pre-enrollment.component.scss'],
})
export class PreEnrollmentComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() corporate: CorporateModel;
  selectedPolicy: PreEnrollmentPolicyModel;
  policies: PreEnrollmentPolicyModel[];
  initialized = false;
  addNewPolicyRequest = false;
  deletePolicyProcessing = false;

  constructor(
    injector: Injector,
    private readonly preEnrollmentService: PreEnrollmentService,
    private readonly router: Router,
    private readonly alertService: AlertService
  ) {
    super(injector);
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  async ngOnInit() {
    super.ngOnInit();
    const policy = await this.preEnrollmentService.getPolicies(this.corporate.id);

    if (policy.length > 0) {
      this.policies = policy;
      this.selectedPolicy = this.policies[0];
    }

    this.initialized = true;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  async detachPreEnrollmentPolicy() {
    let result;
    const message = `Are you sure you want to delete ${this.selectedPolicy.name} policy?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.deletePolicyProcessing = true;
          await this.preEnrollmentService.detachPreEnrollmentPolicy(
            this.selectedPolicy.id,
            this.selectedPolicy.corporateId
          );
          this.alertService.success(`Policy ${this.selectedPolicy.name} deleted Successfully`);
          this.router.navigate(['/biz-admin/corporate/list']);
        } catch (error) {
          this.alertService.error('Error occured');
        } finally {
          this.deletePolicyProcessing = false;
        }
      }
    });
  }
}
