import { Component, OnInit, OnDestroy, Injector, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Router } from '@angular/router';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Hr } from 'src/app/shared/models/CorporatePortal/hr.model';
import { SearchHr } from 'src/app/shared/models/CorporatePortal/search-hr.model';
import { Guid } from 'src/app/shared/enums/guid.enum';
import { CommCodeService } from 'src/app/business-admin/services/comm-code.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PolicyCommunicationCodes } from 'src/app/shared/models/CorporatePortal/policy-communication-codes.model';

@Component({
  selector: 'app-create-hr',
  templateUrl: './create-hr.component.html',
  styleUrls: ['./create-hr.component.scss'],
})
export class CreateHrComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() public corporate;
  @Input() public policy;
  @Output() public hrAddedSucessfully = new EventEmitter<boolean>();

  empId: string;
  isMemberDetailsFound: boolean;

  title = 'Create HR';

  commCodes: string[];
  isCommCodesLoaded = false;
  exisitngcommCodes: string[] = [];
  selectedCommCode: string[] = [];

  hr: Hr;
  searchHrDetails: SearchHr;

  saveButtonText = 'Next';
  currentStep = 'firstStep';

  isReportCheckboxValid = true;
  isDashboardCheckboxValid = true;

  errorList = [];
  // isMemberFound = false;
  // memberNotFoundMsg = '';
  disableEmpId = false;
  isUpdatingHr = false;
  hrDetails: any;
  isNameValid = false;
  isCodeValid = false;
  isEmailValid = false;
  isMobileNoValid = false;
  disableName = false;
  disableEmail = false;
  disableMobileNo = false;
  invalidMobileMsg: string;
  invalidEmailMsg: string;
  invalidNameMsg: string;
  invalidCodeMsg: string;
  hrSearchList = [];
  empIdSearchList = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  showLoader = false;
  policies = [];
  selectedPolicy;
  selectedCommCodeForCurrentPolicy: string[] = [];
  selectedCommCodes: PolicyCommunicationCodes;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly commCodeService: CommCodeService,
    private readonly corporatePortalService: CorporatePortalService,
    private readonly alertService: AlertService,
    private modalService: NgbModal
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.hr = new Hr();
    this.selectedCommCodes = new PolicyCommunicationCodes();

    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.corporatePortalService.updatedPolicies.subscribe((policyList: any[]) => {
      this.policies = policyList;
      this.policies.sort(policy => {
        return policy.isActive === false ? 0 : policy ? -1 : 1;
      });
    });

    this.searchHrDetails = new SearchHr();
    if (this.hrDetails !== undefined) {
      this.isUpdatingHr = true;
      this.title = 'Edit HR';
      this.getHrByEmpId();
    } else {
      this.empId = '';
    }
  }

  onItemSelect(item: any) {
    this.hr.policyCommunicationCodes.push({
      policyId: this.selectedPolicy.id,
      communicationCode: item,
    });

    const commCodeFound = this.hr.policyCommunicationCodes.filter(
      commCode => commCode.policyId === this.selectedPolicy.id
    );

    commCodeFound.forEach(element => {
      this.selectedCommCodeForCurrentPolicy.push(element.communicationCode);
    });

    if (this.isUpdatingHr) {
      this.isMemberDetailsFound = this.hr.policyCommunicationCodes.length > 0 ? true : false;
    } else {
      this.showNextButton();
    }
  }

  onDeSelect(item: any) {
    const index = this.hr.policyCommunicationCodes.findIndex(
      policyCommCode => policyCommCode.policyId === this.selectedPolicy.id && policyCommCode.communicationCode === item
    );
    this.hr.policyCommunicationCodes.splice(index, 1);

    if (this.isUpdatingHr) {
      this.isMemberDetailsFound = this.hr.policyCommunicationCodes.length > 0 ? true : false;
    } else {
      this.showNextButton();
    }
  }

  onSelectAll(items: any) {
    this.selectedCommCodeForCurrentPolicy = items;
    items.forEach(item => {
      const index = this.hr.policyCommunicationCodes.findIndex(
        policyCommCode =>
          policyCommCode.communicationCode === item && policyCommCode.policyId === this.selectedPolicy.id
      );
      if (index === -1) {
        this.hr.policyCommunicationCodes.push({
          policyId: this.selectedPolicy.id,
          communicationCode: item,
        });
      }
    });

    this.selectedCommCode = items;
    if (this.isUpdatingHr) {
      this.isMemberDetailsFound = this.hr.policyCommunicationCodes.length > 0 ? true : false;
    } else {
      this.showNextButton();
    }
  }

  onDeSelectAll(items: any) {
    this.selectedCommCodeForCurrentPolicy = [];
    this.hr.policyCommunicationCodes = this.hr.policyCommunicationCodes.filter(
      item => item.policyId !== this.selectedPolicy.id
    );

    this.selectedCommCode = [];
    if (this.isUpdatingHr) {
      this.isMemberDetailsFound = this.hr.policyCommunicationCodes.length > 0 ? true : false;
    } else {
      this.showNextButton();
    }
  }

  close() {
    this.modalService.dismissAll(CreateHrComponent);
  }

  selected(event) {
    if (this.exisitngcommCodes !== undefined) {
      return this.exisitngcommCodes.findIndex(event) !== -1;
    }
    return false;
  }

  back() {
    this.currentStep = 'firstStep';
    this.saveButtonText = 'Next';
    this.isMemberDetailsFound = true;
  }

  async save(event) {
    if (event === 'Next') {
      this.currentStep = 'secondStep';
      this.saveButtonText = 'Save';
      this.checkCheckboxesValidity();
    } else if (event === 'Save') {
      this.showLoader = true;
      this.isMemberDetailsFound = false;
      if (this.isUpdatingHr) {
        await this.corporatePortalService
          .updateHr(this.corporate.id, this.hr, this.hrDetails.id)
          .then(x => {
            console.log(x);
            this.showLoader = false;
            this.hrAddedSucessfully.emit(true);
            this.modalService.dismissAll(CreateHrComponent);
            this.alertService.success('HR updated successfully');
          })
          .catch(errorData => {
            this.showLoader = false;
            this.errorList = errorData.error.errors;
            this.isMemberDetailsFound = true;
            this.alertService.error('Error occured');
          });
      } else {
        await this.corporatePortalService
          .createHr(this.corporate.id, this.hr)
          .then(x => {
            console.log(x);
            this.showLoader = false;
            this.hrAddedSucessfully.emit(true);
            this.modalService.dismissAll(CreateHrComponent);
            this.alertService.success('HR added successfully');
          })
          .catch(errorData => {
            this.showLoader = false;
            this.errorList = errorData.error.errors;
            this.isMemberDetailsFound = true;
            this.alertService.error('Error occured');
          });
      }
    }
  }

  async searchMemberWithEmpId(event) {
    if (event.target.value.trim() !== '') {
      const fieldValue = event.target.value.trim();
      const empIdSearchList = await this.corporatePortalService.searchMemberByEmpIdWithCorporateId(
        event.target.value,
        this.corporate.id
      );
      if (empIdSearchList.length > 0) {
        this.empIdSearchList = empIdSearchList;
      }
    }
  }

  async onEmpidDropdownChanged(event) {
    this.empId = event.employeeId;
    this.hr.name = event.insuredName;
    this.searchHrDetails.name = event.insuredName;
    this.searchHrDetails.policyNo = event.policyNo;
    this.searchHrDetails.isActive = event.isActive;
    this.hr.email = event.email;
    this.hr.mobileNo = event.mobileNo;
    this.hr.hrEmpId = event.employeeId;
    this.hr.memberId = event.id;
    this.disableName = true;
    this.disableEmail = true;
    this.disableMobileNo = true;
    this.isCodeValid = true;
    this.isEmailValid = true;
    this.isMobileNoValid = true;
    this.isNameValid = true;
    this.invalidEmailMsg = '';
    this.invalidMobileMsg = '';
    this.showNextButton();
  }

  async getHrByName(event) {
    if (event.target.value.trim() !== '') {
      const fieldValue = event.target.value.trim();
      const hrSearchList = await this.corporatePortalService.searchHrByName(this.corporate.id, fieldValue);
      if (hrSearchList.length > 0) {
        this.hrSearchList = hrSearchList;
      }
    }
  }

  async onNameDropdownChanged(event) {
    this.empId = event.employeeId;
    this.searchHrDetails.name = event.insuredName;
    this.searchHrDetails.policyNo = event.policyNo;
    this.searchHrDetails.isActive = event.isActive;
    this.hr.name = event.insuredName;
    this.hr.email = event.email;
    this.hr.mobileNo = event.mobileNo;
    this.hr.hrEmpId = event.employeeId;
    this.hr.memberId = event.id;
    this.disableEmpId = true;
    this.disableEmail = true;
    this.disableMobileNo = true;
    this.isCodeValid = true;
    this.isEmailValid = true;
    this.isMobileNoValid = true;
    this.isNameValid = true;
    this.invalidEmailMsg = '';
    this.invalidMobileMsg = '';
    this.showNextButton();
  }

  showNextButton() {
    if (this.empId.length === 0) {
      this.isMemberDetailsFound = false;
    } else {
      this.isMemberDetailsFound =
        this.isNameValid === true &&
        this.isCodeValid === true &&
        this.isEmailValid === true &&
        this.isMobileNoValid === true &&
        this.hr.policyCommunicationCodes.length > 0
          ? true
          : false;
    }
  }

  checkDashboardValue() {
    if (this.hr.dashboard === true) {
      if (this.hr.staticDashboard === true || this.hr.dyanamicDashboard === true) {
        this.isDashboardCheckboxValid = true;
      } else {
        this.isDashboardCheckboxValid = false;
      }
    } else if (this.hr.dashboard === false) {
      this.hr.staticDashboard = false;
      this.hr.dyanamicDashboard = false;
      this.isDashboardCheckboxValid = true;
    }
  }

  checkReportValue() {
    if (this.hr.reportAllowed === true) {
      if (this.hr.reimbersementReport === true || this.hr.cashlessReport === true) {
        this.isReportCheckboxValid = true;
      } else {
        this.isReportCheckboxValid = false;
      }
    } else if (this.hr.reportAllowed === false) {
      this.hr.reimbersementReport = false;
      this.hr.cashlessReport = false;
      this.isReportCheckboxValid = true;
    }
  }

  checkCheckboxesValidity() {
    this.checkDashboardValue();
    this.checkReportValue();
    this.isMemberDetailsFound =
      this.isReportCheckboxValid === true && this.isDashboardCheckboxValid === true ? true : false;
  }

  checkEmpIdValidity(event) {
    this.searchHrDetails = new SearchHr();
    const fieldValue = event.target.value.trim();
    if (this.empIdSearchList.length !== 0) {
      this.empIdSearchList = [];
      this.hr.name = '';
      this.hr.email = '';
      this.hr.hrEmpId = '';
      this.hr.mobileNo = null;
      this.disableName = false;
      this.disableEmail = false;
      this.disableMobileNo = false;
      this.isEmailValid = false;
      this.isMobileNoValid = false;
      this.isNameValid = false;
      this.isMemberDetailsFound = false;
    }
    if (fieldValue.length === 0) {
      this.isCodeValid = false;
      this.invalidCodeMsg = 'Employee Id cannot be null!!!';
    } else if (fieldValue.match(/^[a-zA-Z0-9]+$/i) === null) {
      this.isCodeValid = false;
      this.invalidCodeMsg = 'Please enter valid Employee Id!!';
    } else {
      this.isCodeValid = true;
      this.hr.hrEmpId = fieldValue;
    }
    this.showNextButton();
  }

  checkNameValidity(event) {
    this.searchHrDetails = new SearchHr();
    const fieldValue = event.target.value.trim();
    if (this.hrSearchList.length !== 0) {
      this.hrSearchList = [];
      this.empId = '';
      this.hr.email = '';
      this.hr.hrEmpId = '';
      this.hr.mobileNo = null;
      this.disableEmpId = false;
      this.disableEmail = false;
      this.disableMobileNo = false;
      this.isEmailValid = false;
      this.isMobileNoValid = false;
      this.isMemberDetailsFound = false;
    }
    if (fieldValue.length === 0) {
      this.isNameValid = false;
      this.invalidNameMsg = 'Name cannot be null!!!';
    } else if (fieldValue.match(/^[a-zA-Z][a-zA-Z. ]*[a-zA-Z]$/) === null) {
      this.isNameValid = false;
      this.invalidNameMsg = 'Please enter valid name(Min 2 characters)';
    } else {
      this.isNameValid = true;
    }
    this.showNextButton();
  }

  checkEmailValidity(event) {
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.isEmailValid = false;
      this.invalidEmailMsg = 'Email cannot be null!!!';
    } else if (fieldValue.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i) === null) {
      this.isEmailValid = false;
      this.invalidEmailMsg = 'Please enter valid email!!';
    } else {
      this.isEmailValid = true;
    }
    this.showNextButton();
  }

  checkMobileNoValidity(event) {
    const mobileNumber = event.target.value;
    const mobileNumberLength = mobileNumber.trim().length;
    if (mobileNumberLength === 0) {
      this.isMobileNoValid = false;
      this.invalidMobileMsg = 'Mobile number cannot be null!!';
    } else {
      if (mobileNumberLength !== 10 || mobileNumber.match(/^[6-9][0-9]{9}$/) === null) {
        this.isMobileNoValid = false;
        this.invalidMobileMsg = 'Enter valid mobile number';
      } else {
        this.isMobileNoValid = true;
      }
    }
    this.showNextButton();
  }

  async getHrByEmpId() {
    this.disableEmpId = true;
    if (this.hrDetails.isHr === true && this.hrDetails.isMember === false) {
      this.disableEmail = false;
      this.disableMobileNo = false;
      this.disableName = false;
    } else {
      this.disableEmail = true;
      this.disableMobileNo = true;
      this.disableName = true;
    }
    this.isMemberDetailsFound = true;
    // this.isMemberFound = true;
    this.empId = this.hrDetails.hrEmpId;
    this.hr.hrEmpId = this.hrDetails.hrEmpId;
    this.hr.name = this.hrDetails.name;
    this.hr.email = this.hrDetails.email;
    this.hr.mobileNo = this.hrDetails.mobileNo;
    this.hr.memberId = this.hrDetails.memberId;
    this.hr.endoresment = this.hrDetails.endoresment;
    this.hr.reportAllowed = this.hrDetails.reportAllowed;
    this.hr.dashboard = this.hrDetails.dashboard;
    this.hr.staticDashboard = this.hrDetails.staticDashboard;
    this.hr.dyanamicDashboard = this.hrDetails.dyanamicDashboard;
    this.hr.reimbersementReport = this.hrDetails.reimbersementReport;
    this.hr.cashlessReport = this.hrDetails.cashlessReport;

    this.isNameValid = true;
    this.isCodeValid = true;
    this.isEmailValid = true;
    this.isMobileNoValid = true;

    this.hr.policyCommunicationCodes = this.hrDetails.policyCommunicationCodes;

    if (this.hr.policyCommunicationCodes.length > 0) {
      const policyId = this.hr.policyCommunicationCodes[0].policyId;
      this.policies.forEach(policy => {
        if (policy.id === policyId) {
          this.selectedPolicy = policy;
          return;
        }
      });
      this.onPolicyDropdownChanged(this.selectedPolicy);
    }
  }

  onPolicyDropdownChanged(policy) {
    this.isCommCodesLoaded = false;
    this.selectedPolicy = policy;
    this.commCodeService.getCommCodeByPolicy(this.selectedPolicy.policyNumber).then(x => {
      this.commCodes = x;
      this.isCommCodesLoaded = true;
    });
    this.selectedCommCodeForCurrentPolicy = [];
    const commCodeFound = this.hr.policyCommunicationCodes.filter(
      commCode => commCode.policyId === this.selectedPolicy.id
    );

    commCodeFound.forEach(element => {
      this.selectedCommCodeForCurrentPolicy.push(element.communicationCode);
    });
  }
}
