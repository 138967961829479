<header class="site-header">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand mr-5" routerLink="/"><img src="assets/images/logo.png" alt=""/></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/biz-admin/corporate/list">Corporates</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/biz-admin/notification/list">Notification</a>
        </li>
      </ul>
    </div>
    <div class="user-menu" *ngIf="isAuthenticated">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownMenuLink"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >{{ userName }}
            <i class="material-icons">
              account_circle
            </i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" (click)="signout()" href="javascript:void(0)">Logout </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
