import { Component, OnInit } from '@angular/core';
import { Hr } from 'src/app/shared/models/CorporatePortal/hr.model';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { BrokerService } from 'src/app/broker/service/broker.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { RoleService } from 'src/app/shared/services/role.service';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { CPPolicyService } from '../../services/cp-policy.service';
import { Broker } from 'src/app/shared/models/CorporatePortal/broker.model';
import { Sm } from 'src/app/shared/models/CorporatePortal/sm.model';
import { SMService } from 'src/app/sm/service/sm.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  currentPolicy: Policy = new Policy();
  policies = [];
  isHr: boolean;
  isMember: boolean;
  hr: Hr = new Hr();

  role: string;

  isEmployeeBelongsToPolicy = false;

  // Broker
  brokerPrivileges: Broker = new Broker();
  // SM
  smPrivileges: Sm = new Sm();

  constructor(
    private cpMemberService: CPMemberService,
    private authService: AuthService,
    private roleService: RoleService,
    private smService: SMService,
    private policyService: CPPolicyService,
    private brokerService: BrokerService
  ) {}

  async ngOnInit() {
    this.policyService.policies.subscribe(policies => {
      this.policies = policies;
    });
    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.currentPolicy = policy;

      if (this.role === Roles.CPHR) {
        this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;
        this.isHr = this.cpMemberService.isHr;
        this.isMember = this.cpMemberService.isMember;
      }
      // Broker Role
      if (this.role === Roles.Broker) {
        this.brokerPrivileges = this.brokerService.brokerPrivileges;
      }
      // SM Role
      if (this.role === Roles.SM) {
        this.smService.getSMByPolicy(policy.policyId).then(x => {
          this.smPrivileges = x;
        });
      }
    });

    this.cpMemberService.hrDetails.subscribe(x => {
      this.hr = x;
    });
  }

  onNamePolicyChanged(policy) {
    this.policyService.currentPolicy.next(policy);
  }
}
