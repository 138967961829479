import { Component, OnInit, Input, Injector } from '@angular/core';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HealthClaimReport } from 'src/app/cp-member/models/report/health-claim-report.model';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-health-enrollment-mis',
  templateUrl: './health-enrollment-mis.component.html',
  styleUrls: ['./health-enrollment-mis.component.scss'],
})
export class HealthEnrollmentMisComponent extends BaseComponent implements OnInit {
  @Input() policy: Policy;
  errorR = [];
  healthEnrollmentForm = this.fb.group({
    status: ['', Validators.required],
  });

  isExcelReportLoaded = true;

  constructor(
    private fb: FormBuilder,
    injector: Injector,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private cpMemberService: CPMemberService
  ) {
    super(injector);
  }

  ngOnInit(): void {}

  async onSubmit() {
    this.isExcelReportLoaded = false;
    await this.cpMemberService
      .getHealthEnrollmentMISExcelReport(this.policy.policyNumber, this.healthEnrollmentForm.value.status)
      .then(
        result => {
          this.isExcelReportLoaded = true;
          FileSaver.saveAs(result, `Enrollment${this.policy.policyNumber}.xlsx`);
        },
        error => {}
      );
  }
}
