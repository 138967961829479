import { Component, OnInit, Injector, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-attach-policy',
  templateUrl: './attach-policy.component.html',
  styleUrls: ['./attach-policy.component.scss'],
})
export class AttachPolicyComponent extends BaseComponent implements OnInit {
  @Input() corporate: CorporateModel;
  @Input() policy: any;

  date2: Date = new Date();
  periodTypes = ['Week', 'Month', 'Year'];
  periodValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  id: number;
  attachPolicyForm: FormGroup;
  errorList = [];
  showLoader = false;

  constructor(
    private fb: FormBuilder,
    injector: Injector,
    private readonly router: Router,
    private readonly corporatePortalService: CorporatePortalService,
    private readonly alertService: AlertService,
    private modalService: NgbModal
  ) {
    super(injector);
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void {
    this.createAttachPolicyForm();
    this.attachPolicyForm.get('gracePeriodValue').disable();
  }

  createAttachPolicyForm() {
    this.attachPolicyForm = this.fb.group({
      policyNumber: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^s*([0-9a-zA-Z]*)s*$')]],
      insuredName: ['', Validators.required],
      riskStartDate: ['', Validators.required],
      riskEndDate: ['', Validators.required],
      issueDate: ['', Validators.required],
      gracePeriodType: [''],
      gracePeriodValue: [0],
      corporate: [''],
      key: [''],
      id: [''],
      corporateId: [''],
      claimSpoc:[false],
      claimSpocMailId: ['',Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      onscreenDisplay: ['', [Validators.maxLength(30), Validators.pattern('^[a-zA-Z0-9]+[a-zA-Z0-9\-_()]*( [a-zA-Z0-9\-_()]+)*$')]]
    });
  }

  save(event) {
    this.showLoader = true;
    const isClaimSpoc:boolean=this.attachPolicyForm.get('claimSpoc').value;
    if(!isClaimSpoc)
    {
      this.attachPolicyForm.patchValue({
        claimSpocMailId:null,
      });
    }
    else
    {
      let reg=new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
      const spocMail=this.attachPolicyForm.get('claimSpocMailId').value;
      if(spocMail.length ===0 || !reg.test(spocMail))
      {
        this.showLoader = false;
        let erer={
          message:"Please add valid SPOC Mail ID."
        }
        this.errorList=[erer];
        return;
      }
    }
    const data = this.attachPolicyForm.getRawValue();
    const id = this.attachPolicyForm.get('id').value;
    this.corporatePortalService
      .attachPolicy(id, data)
      .then(x => {
        this.showLoader = false;
        this.modalService.dismissAll(AttachPolicyComponent);
        this.alertService.success('Policy attached succesfully');
        this.router.navigate(['/biz-admin/corporate/' + this.corporate.id]);
      })
      .catch(errorData => {
        this.showLoader = false;
        this.errorList = errorData.error.errors;
        // this.alertService.error('Error occured');
      });
  }

  close() {
    this.modalService.dismissAll(AttachPolicyComponent);
  }

  async searchPolicyNumber(event) {
    const policyNumber = event.target.value;
    const policy = await this.corporatePortalService.getPolicyByNumber(policyNumber);
    if (policy) {
      this.attachPolicyForm.get('policyNumber').setErrors(null);
      this.attachPolicyForm.patchValue({
        insuredName: policy.insuredName,
        riskStartDate: new Date(policy.riskStartDate),
        riskEndDate: new Date(policy.riskEndDate),
        issueDate: new Date(policy.issueDate),
        key: policy.key,
        id: policy.id,
        corporateId: this.corporate.id,
        corporate: null,
      });
    } else {
      this.attachPolicyForm.get('policyNumber').setErrors({ invalid: true });
      this.attachPolicyForm.patchValue({
        insuredName: null,
        riskStartDate: null,
        riskEndDate: null,
        issueDate: null,
      });
    }
  }

  periodTypeChanged() {
    if (this.attachPolicyForm.get('gracePeriodValue').value === 0) {
      this.attachPolicyForm.patchValue({
        gracePeriodValue: null,
      });
      this.attachPolicyForm.get('gracePeriodValue').enable();
      this.attachPolicyForm.get('gracePeriodValue').setValidators(Validators.required);
      this.attachPolicyForm.get('gracePeriodValue').updateValueAndValidity();
    }
  }

  setPolicyDetailsNull() {
    this.errorList = [];
    this.attachPolicyForm.get('insuredName').setErrors({ invalid: true });
  }
}
