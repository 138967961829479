import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MemberRoutingModule } from './member-routing.module';
import { MemberHomeComponent } from './components/member-home/member-home.component';
import { MemberComponent } from './components/member/member.component';
import { MemberHeaderComponent } from './components/member-header/member-header.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { MemberEnrollmentComponent } from './components/member-enrollment/member-enrollment.component';
import { EditMemberComponent } from './components/edit-member/edit-member.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DyanamicFieldsFormModule } from '../dyanamic-fields-form/dyanamic-fields-form.module';
import { MaterialModule } from '../material/material.module';
import { EditMemberFamilyComponent } from './components/edit-member-family/edit-member-family.component';
import { GenerateReportsComponent } from './components/generate-reports/generate-reports';
import { BusinessAdminModule } from '../business-admin/business-admin.module';

@NgModule({
  declarations: [
    MemberComponent,
    MemberHomeComponent,
    MemberHeaderComponent,
    SideBarComponent,
    MemberEnrollmentComponent,
    EditMemberComponent,
    EditProfileComponent,
    EditMemberFamilyComponent,
    GenerateReportsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    MaterialModule,
    MemberRoutingModule,
    DyanamicFieldsFormModule,
    BusinessAdminModule,
  ],
  exports: [MemberHomeComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MemberModule {
  constructor() {}
}
