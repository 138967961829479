import { Privileges } from 'src/app/corporate-portal/models/privileges.model';
import { PolicyCommunicationCodes } from './policy-communication-codes.model';

export class Hr extends Privileges {
  memberId: string = null;
  name: string = null;
  email: string = null;
  mobileNo: number = null;
  hrEmpId: string = null;
  policyCommunicationCodes: PolicyCommunicationCodes[] = [];
}
