<div class="notifications-wrapper">
  <div class="card-body mb-3" *ngIf="role === 'CPMember' || role === 'CPHR'">
    <div class="card-title row justify-content-between align-items-center">
      <div *ngIf="!isHrView">
        <h4 class="m-0 header-title">Notifications For Member</h4>
      </div>
      <div *ngIf="isHrView">
        <h4 class="m-0 header-title">Notifications For HR</h4>
      </div>
      <div>
        <button
          *ngIf="role === 'Broker' || role === 'SM' || (role === 'CPHR' && isHrView)"
          class="btn btn-primary"
          (click)="open()"
        >
          Create New Notification
        </button>
        <div class="dropdown card-header-dropdown" *ngIf="!isHrView">
          <button class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <span *ngIf="!currentPolicy.hasOwnProperty('policyId')">
              <span class="shimmer-wrapper">
                <span class="shimmer primary"></span>
              </span>
            </span>
            <span *ngIf="!isCommonNoificationSelected && currentPolicy.hasOwnProperty('policyId')">
              <span *ngIf="currentPolicy.isActive" class="logged"></span>
              <span *ngIf="!currentPolicy.isActive" class="notLogged"></span>
              <span *ngIf="currentPolicy.onScreenDisplayAvailable == false">
                {{ currentPolicy.riskStartDate.split('-')[0] }}-{{ currentPolicy.riskEndDate.split('-')[0] }} -
                {{ currentPolicy.policyNumber }} - {{currentPolicy.policyType}}</span
              >
              <span *ngIf="currentPolicy.onScreenDisplayAvailable == true">{{currentPolicy.onScreenDisplayPolicy}}</span>
            </span>
            <div *ngIf="isCommonNoificationSelected && currentPolicy.hasOwnProperty('policyId')">
              <span>Common Notifications</span>
            </div>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <div *ngFor="let policy of policies" (click)="onNamePolicyChanged(policy)">
              <a class="dropdown-item">
                <span *ngIf="policy.isActive" class="logged"></span>
                <span *ngIf="!policy.isActive" class="notLogged"></span>
                <span *ngIf="policy.onScreenDisplayAvailable == false">
                  {{ policy.riskStartDate.split('-')[0] }}-{{ policy.riskEndDate.split('-')[0] }} -
                  {{ policy.policyNumber }} - {{policy.policyType}}</span
                >
                <span *ngIf="policy.onScreenDisplayAvailable == true">{{policy.onScreenDisplayPolicy}}</span>
              </a>
            </div>
            <div>
              <a class="dropdown-item" (click)="getCommonNotification()"><span>Common Notifications</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="notifications-list">
      <div *ngIf="!isNotificationLoaded">
        <ul class="list-unstyled shimmer-wrapper">
          <li class="shimmer shimmer-box"></li>
          <li class="shimmer shimmer-box"></li>
          <li class="shimmer shimmer-box"></li>
        </ul>
      </div>

      <div *ngIf="isNotificationLoaded && notifications.length == 0" class="no-result-wrapper">
        <h5>No Notifications Present!</h5>
      </div>
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        *ngIf="isNotificationLoaded && notifications.length > 0"
      >
        <div ngxSlickItem class="slide" *ngFor="let notification of notifications">
          <div class="item-box">
            <div class="carousel-item-text text-center">
              <div class="item-box-top">
                <div *ngIf="notification.isSeen" class="green">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"
                    />
                  </svg>
                </div>
                <div *ngIf="!notification.isSeen" class="yellow">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"
                    />
                  </svg>
                </div>
                <h4 class="item-title">{{ notification.subject }}</h4>
              </div>

              <div class="item-box-middle">
                <p>Effective From</p>
                <p>
                  <span>{{ notification.effectiveDateFrom | date: 'dd MMMM yyyy' }}</span>
                </p>
                <div class="bd-right"></div>
              </div>

              <div class="item-box-middle">
                <p>Effective To</p>
                <p>
                  <span>{{ notification.effectiveDateTo | date: 'dd MMMM yyyy' }}</span>
                </p>
              </div>

              <div class="item-box-bottom">
                <div *ngIf="notification.file !== null">
                  <label class="col-form-label">View File</label>
                  <a href="{{ notification.file }}" target="_blank" class="text-secondary"
                    ><span class="material-icons">description</span></a
                  >
                </div>
                <p class="text">
                  {{ notification.content }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
  <!-- end row -->

  <div class="card-body" *ngIf="role === 'Broker' || role === 'SM' || (role === 'CPHR' && isHrView)">
    <div class="card-title row justify-content-between align-items-center">
      <h4 class="m-0 header-title">Notifications Created By You</h4>
      <div class="dropdown card-header-dropdown"></div>
    </div>
    <div class="notifications-list">
      <div *ngIf="!isNotificationCreatedByUserLoaded">
        <ul class="list-unstyled shimmer-wrapper">
          <li class="shimmer shimmer-box"></li>
          <li class="shimmer shimmer-box"></li>
          <li class="shimmer shimmer-box"></li>
        </ul>
      </div>

      <div
        *ngIf="isNotificationCreatedByUserLoaded && notificationsCreatedByUser.length == 0"
        class="no-result-wrapper"
      >
        <h5>No Notifications Present!</h5>
      </div>
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        *ngIf="isNotificationCreatedByUserLoaded && notificationsCreatedByUser.length > 0"
      >
        <div ngxSlickItem class="slide" *ngFor="let notification of notificationsCreatedByUser">
          <div class="item-box">
            <div class="carousel-item-text text-center">
              <div class="item-box-top">
                <div *ngIf="notification.isApproved" class="green">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"
                    />
                  </svg>
                </div>
                <div *ngIf="notification.isRejected" class="red">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"
                    />
                  </svg>
                </div>
                <div *ngIf="!notification.isApproved && !notification.isRejected" class="yellow">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"
                    />
                  </svg>
                </div>
                <!-- <p *ngIf="!notification.isRejected">red</p> -->
                <h4 class="item-title">{{ notification.subject }}</h4>
              </div>
              <div class="item-box-middle">
                <p>Effective From</p>
                <p>
                  <span>{{ notification.effectiveDateFrom | date: 'dd MMMM yyyy' }}</span>
                </p>
                <div class="bd-right"></div>
              </div>

              <div class="item-box-middle">
                <p>Effective To</p>
                <p>
                  <span>{{ notification.effectiveDateTo | date: 'dd MMMM yyyy' }}</span>
                </p>
              </div>

              <div class="item-box-bottom">
                <div *ngIf="notification.file !== null">
                  <label class="col-form-label">View File</label>
                  <a href="{{ notification.file }}" target="_blank" class="text-secondary"
                    ><span class="material-icons">description</span></a
                  >
                </div>
                <p class="text" style="word-wrap: break-word;">
                  {{ notification.content }}
                </p>
              </div>
              <div
                class="item-box-bottom notification-remark"
                *ngIf="notification.isApproved || notification.isRejected"
              >
                Remark:
                <p class="text">
                  {{ notification.remark }}
                </p>
              </div>
              <div class="item-box-bottom">
                <button
                  class="btn btn-sm btn-primary mr-2 px-4"
                  [disabled]="notification.isApproved || notification.isRejected"
                  (click)="editNotification(notification)"
                >
                  Edit
                </button>
                <button class="btn btn-sm btn-primary px-4" (click)="deleteNotfication(notification)">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
