import { Component, OnInit, Injector, Input } from '@angular/core';
import { NotificationService } from 'src/app/business-admin/services/notification.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ApproveRejectComponent } from 'src/app/corporate-portal/components/approve-reject/approve-reject.component';
import { EditNotificationComponent } from 'src/app/corporate-portal/components/notifications/edit-notification/edit-notification.component';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { Notification } from 'src/app/shared/models/notification/notification.model';

@Component({
  selector: 'app-hr-notification',
  templateUrl: './hr-notification.component.html',
  styleUrls: ['./hr-notification.component.scss'],
})
export class HrNotificationComponent extends BaseComponent implements OnInit {
  @Input() searchText: string;
  @Input() role: string;

  notifications: Notification[] = [];
  isNotificationsLoaded = false;
  isLoading: boolean;
  page = 1;
  pageSize = 5;
  notificationProperty = 'notificationProp';
  modalOption: NgbModalOptions = {};
  constructor(
    injector: Injector,
    private readonly notificationService: NotificationService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private modalService: NgbModal
  ) {
    super(injector);
  }
  async ngOnInit() {
    super.ngOnInit();
    this.getNotification();
  }

  changePage() {
    this.page = 1;
  }

  editNotification(notification) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(EditNotificationComponent, this.modalOption);
    modalRef.componentInstance.notification = notification;
  }

  getNotification() {
    this.isNotificationsLoaded = false;
    if (this.role === Roles.CPHR) {
      this.notificationService.getNotificationsCreatedByAllByRole(this.role).then(x => {
        this.notifications = x;
        this.isNotificationsLoaded = true;
      });
    } else if (this.role === Roles.Broker || this.role === Roles.SM) {
      this.notificationService.getNotificationsCreatedByAllByRole(this.role).then(x => {
        this.notifications = x;
        //         isMultiplePolicy: true
        // isRejected: false
        // multiplePolicy: "{"Corporates":[{"Id":23,"Policies":["ee7f7690-cba4-4cce-fc23-08d83ed023fe"]}]}"
        // notificationBy: "Broker"
        // policyNo: null
        // rejectedBy: null
        this.notifications.forEach(notification => {
          if (notification.isMultiplePolicy === true) {
            const multiplePolicy = JSON.parse(notification.multiplePolicy);
            notification.corporateCode = multiplePolicy.Corporates[0].Id;
            notification.policyNo = '';
            multiplePolicy.Corporates[0].Policies.forEach(policyNum => {
              notification.policyNo += policyNum + '\n';
            });
          }
        });

        this.isNotificationsLoaded = true;
      });
    }
  }

  async deleteNotification(id) {
    let result;
    const message = `Are you sure you want to delete?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.isLoading = true;
          await this.notificationService
            .deleteNotification(id)
            .then(async x => {
              this.alertService.success('Notification deleted successfully');
              this.getNotification();
            })
            .catch(error => {
              this.alertService.success('Error Occured');
            });
          this.isLoading = false;
        } catch (error) {
          this.alertService.error('Error occured');
          this.isLoading = false;
        }
      }
    });
  }

  async approveNotification(notification) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(ApproveRejectComponent, this.modalOption);
    modalRef.componentInstance.notification = notification;
    modalRef.componentInstance.type = 'notification';
    modalRef.componentInstance.approveRejectSuccefully.subscribe(result => {
      if (result === true) {
        this.getNotification();
      }
    });
  }
}
