import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { count, min } from 'rxjs/operators';
import { PreEnrollmentService } from 'src/app/business-admin/services/preEnrollment.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AllowedSumInsuredModel } from 'src/app/shared/models/preEnrollment/policies/allowedSumInsuredModel';
import { EnhancedSumInsured } from 'src/app/shared/models/preEnrollment/policies/enhanced-sum-insured.model';
import { ParentalSumInsured } from 'src/app/shared/models/preEnrollment/policies/Parental-sum-insured.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { SiExtrabenefits } from 'src/app/shared/models/preEnrollment/policies/si-extrabenefits.model';
import { TopupModel } from 'src/app/shared/models/preEnrollment/policies/topup-model.model';
import { EsiPremiumDetails } from 'src/app/shared/models/preEnrollment/premium/esi-premium-details.model';
import { PsiPremiumDetails } from 'src/app/shared/models/preEnrollment/premium/psi-premium-details.model';
import { SiPremiumDetails } from 'src/app/shared/models/preEnrollment/premium/si-premium-details.model';
import { TopupPremiumDetails } from 'src/app/shared/models/preEnrollment/premium/topup-premium-details.model';
import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { AlertService } from 'src/app/shared/services/alert.service';
import { isJSDocThisTag } from 'typescript';

@Component({
  selector: 'app-add-new-premium',
  templateUrl: './add-new-premium.component.html',
  styleUrls: ['./add-new-premium.component.scss'],
})
export class AddNewPremiumComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public corporate;
  @Input() public policy: PreEnrollmentPolicyModel;
  @Input() public data;
  @Input() public relationshipType;
  @Input() public sumInsuredType;
  @Input() public premiumType;
  @Output() public premiumAddedSucessfully = new EventEmitter<boolean>();
  title = 'Add New Premium';
  saveButtonText = 'Save';
  isDetailsFilled = true;
  showLoader = false;

  currentStep = 'secondStep';

  parentalSumInsured: ParentalSumInsured = null;
  enhancedSumInsured: EnhancedSumInsured = null;
  topupOption: TopupModel = null;
  selfSumInsured: SiExtrabenefits = null;

  relationShipDropdownEle = [];
  sumInsuredDropdownEle = [];
  noOfMembersDropdownEle = [];
  sumInsuredList = [];
  age: any;
  minage: number;
  maxage: number;
  relationship: string;
  loading: number;
  sumInsured: number;
  premiumAmount: any;
  noOfMembers: any;
  sumInsuredTypeExtraBenefit: string;

  psiPremiumDetails: PsiPremiumDetails[] = [];
  esiPremiumDetails: EsiPremiumDetails[] = [];
  topupPremiumDetails: TopupPremiumDetails[] = [];
  selfSiPremiumDetails: SiPremiumDetails[] = [];

  currentPremiumDetailList: any[];
  esiAllPremiumDetails: EsiPremiumDetails[];
  topupAllPremiumDetails: TopupPremiumDetails[];
  selfAllSiPremiumDetails: SiPremiumDetails[];

  extraBenefitsId: number;

  errorList = [];

  isMinAgeValid: boolean;
  invalidMinAgeMsg = '';
  isMaxAgeValid: boolean;
  invalidMaxAgeMsg = '';

  isLoadingValid = undefined;
  isPremiumAmountValid = undefined;

  isSaveButtonVisible = false;
  invalidLoadingMsg = '';
  invalidPremiumAmountMsg = '';
  allowedSumInsured: AllowedSumInsuredModel;
  grade: any;
  multiples: any;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private modalService: NgbModal,
    private preEnrollmentService: PreEnrollmentService
  ) {
    super(injector);
  }

  async ngOnInit() {
    await this.getSI();
    if (this.relationshipType !== 'Self') {
      this.psiPremiumDetails.push(new PsiPremiumDetails());
      await this.getPSIExtraBenefits();
    }
    console.log(this.data);
    if (this.data !== '') {
      this.loading = this.data.loading;
      this.maxage = this.data.maxAge;
      this.minage = this.data.minAge;
      this.premiumAmount = this.data.premiumAmount;
      this.relationship = this.data.relationship;
      this.sumInsured = this.data.sumInsured;
      this.grade = this.data.grade;
      this.noOfMembers = this.data.numberOfMembers;
      this.title = 'Update Premium';
      this.isSaveButtonVisible = true;
      this.saveButtonText = 'Update';
      this.isMaxAgeValid = true;
      this.isMinAgeValid = true;
      this.isLoadingValid = true;
      this.isPremiumAmountValid = true;
      this.checkNumberOfMembersDropdown();
    } else {
      this.title = 'Add New Premium';
      this.isSaveButtonVisible = false;
      this.saveButtonText = 'Save';
    }

    if (this.sumInsuredType === 'ESI') {
      this.esiPremiumDetails.push(new EsiPremiumDetails());
      this.getESIExtraBenefits();
    } else if (this.sumInsuredType === 'TopUp') {
      this.topupPremiumDetails.push(new TopupPremiumDetails());
      this.getTopupExtraBenefits();
    } else if (this.relationshipType !== '' && this.sumInsuredType === 'SI') {
      this.selfSiPremiumDetails.push(new SiPremiumDetails());
      this.getselfSIExtraBenefits();
    }

    if (this.premiumType === 'Flat') {
      this.noOfMembers = 'All';
    }

    if (this.premiumType === 'Flat') {
      this.relationship = 'All';
    } else if (this.premiumType === 'Selective') {
      this.relationShipDropdownEle = this.fetchRelationshipFromPolicy();
    }

    if (this.premiumType === 'Flat') {
      this.age = 'All';
    }
  }

  fetchRelationshipFromPolicy() {
    const selfRship: string[] = [];
    const parentRship: string[] = [];
    const pilRship: string[] = [];
    this.policy.relationships.forEach(element => {
      if (element.type === 'Parent-In-Law') {
        pilRship.push(element.label);
        pilRship.push(element.type);
      }
      if (element.type === 'Parent') {
        parentRship.push(element.label);
        parentRship.push(element.type);
      }
      if (element.type !== 'Parent' && element.type !== 'Parent-In-Law') {
        selfRship.push(element.label);
        selfRship.push(element.type);
      }
    });
    if (this.relationshipType === 'Self') {
      return selfRship.filter(function(item, index, inputArray) {
        return inputArray.indexOf(item) == index;
      });
    }
    if (this.relationshipType === 'Parent') {
      return parentRship.filter(function(item, index, inputArray) {
        return inputArray.indexOf(item) == index;
      });
    }
    if (this.relationshipType === 'Parent-In-Law') {
      return pilRship.filter(function(item, index, inputArray) {
        return inputArray.indexOf(item) == index;
      });
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.getSI();
    if (this.relationshipType !== '' && this.sumInsuredType === 'PSI') {
      this.getPSIExtraBenefits();
    } else if (this.relationshipType !== '' && this.sumInsuredType === 'ESI') {
      this.getESIExtraBenefits();
    } else if (this.relationshipType !== '' && this.sumInsuredType === 'TopUp') {
      this.getTopupExtraBenefits();
    } else if (this.relationshipType !== '' && this.sumInsuredType === 'SI') {
      this.getselfSIExtraBenefits();
    }
  }

  async getSI() {
    await this.preEnrollmentService
      .getSumInsured(this.corporate.id, this.policy.id)
      .then(result => {
        this.allowedSumInsured = new AllowedSumInsuredModel(result);
      })
      .catch(error => {
        this.allowedSumInsured = new AllowedSumInsuredModel(null);
      });
  }

  onSumInsuredDropdownChanged(sumInsured) {
    if (this.relationshipType === 'Self') {
      if (
        this.sumInsuredTypeExtraBenefit === 'Defined' ||
        (this.sumInsuredTypeExtraBenefit === 'EqualToSumInsured' &&
          this.allowedSumInsured.selfSumInsuredType === 'Defined')
      ) {
        this.sumInsured = sumInsured;
      }

      if (
        this.sumInsuredTypeExtraBenefit === 'GradeWiseDefined' ||
        this.sumInsuredTypeExtraBenefit === 'GradeWiseMultiples' ||
        (this.sumInsuredTypeExtraBenefit === 'EqualToSumInsured' &&
          this.allowedSumInsured.selfSumInsuredType === 'GradeWiseDefined')
      ) {
        this.sumInsured = sumInsured.split('-')[1];
        this.grade = sumInsured.split('-')[0];
      }
    } else {
      if (
        this.sumInsuredTypeExtraBenefit === 'Defined' ||
        (this.sumInsuredTypeExtraBenefit === 'EqualToSumInsured' &&
          this.parentalSumInsured.peExtraBenefits.sumInsuredType === 'Defined')
      ) {
        this.sumInsured = sumInsured;
      }

      if (
        this.sumInsuredTypeExtraBenefit === 'GradeWiseDefined' ||
        this.sumInsuredTypeExtraBenefit === 'GradeWiseMultiples' ||
        (this.sumInsuredTypeExtraBenefit === 'EqualToSumInsured' &&
          this.parentalSumInsured.peExtraBenefits.sumInsuredType === 'GradeWiseDefined')
      ) {
        this.sumInsured = sumInsured.split('-')[1];
        this.grade = sumInsured.split('-')[0];
      }
    }

    this.checkSaveButtonVisibility();
  }

  async getselfSIExtraBenefits() {
    await this.preEnrollmentService
      .getSIextraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.selfSumInsured = result;
          if (this.sumInsuredType === 'SI') {
            this.extraBenefitsId = this.selfSumInsured.peExtraBenefits.id;
            this.sumInsuredTypeExtraBenefit = this.selfSumInsured.peExtraBenefits.sumInsuredType;
            if (this.sumInsuredTypeExtraBenefit === 'Defined') {
              this.selfSumInsured.peSelfModels.forEach(element => {
                this.sumInsuredList.push(element.sumInsured);
              });
            }

            if (this.sumInsuredTypeExtraBenefit === 'GradeWiseDefined') {
              this.selfSumInsured.peSelfModels.forEach(element => {
                this.sumInsuredList.push(element.grade + '-' + element.sumInsured.toString());
              });
            }
          }
        } else {
          console.log(result);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getPSIExtraBenefits() {
    await this.preEnrollmentService
      .getPSIExtraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.parentalSumInsured = result;
          if (this.sumInsuredType === 'PSI') {
            this.extraBenefitsId = this.parentalSumInsured.peExtraBenefits.id;
            this.sumInsuredTypeExtraBenefit = this.parentalSumInsured.peExtraBenefits.sumInsuredType;
            if (this.sumInsuredTypeExtraBenefit === 'Defined') {
              this.parentalSumInsured.pePsi.forEach(element => {
                this.sumInsuredList.push(element.sumInsured);
              });
            }

            if (this.sumInsuredTypeExtraBenefit === 'GradeWiseDefined') {
              this.parentalSumInsured.pePsi.forEach(element => {
                this.sumInsuredList.push(element.grade + '-' + element.sumInsured.toString());
              });
            }
          }
        } else {
          console.log(result);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getESIExtraBenefits() {
    await this.preEnrollmentService
      .getESIExtraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.enhancedSumInsured = result;
          this.extraBenefitsId = this.enhancedSumInsured.peExtraBenefits.id;
          this.sumInsuredTypeExtraBenefit = this.enhancedSumInsured.peExtraBenefits.sumInsuredType;
          if (this.sumInsuredTypeExtraBenefit === 'Defined') {
            this.enhancedSumInsured.peEsi.forEach(element => {
              this.sumInsuredList.push(element.sumInsured);
            });
          }

          if (this.sumInsuredTypeExtraBenefit === 'EqualToSumInsured') {
            if (this.relationshipType === 'Self') {
              if (this.allowedSumInsured.selfSumInsuredType === 'Defined') {
                this.allowedSumInsured.sumInsuredList.forEach(element => {
                  this.sumInsuredList.push(element.sumInsured);
                });
              } else {
                this.allowedSumInsured.sumInsuredList.forEach(element => {
                  this.sumInsuredList.push(element.grade + '-' + element.sumInsured.toString());
                });
              }
            } else if (this.relationshipType !== 'Self') {
              if (this.parentalSumInsured.peExtraBenefits.sumInsuredType === 'Defined') {
                this.parentalSumInsured.pePsi.forEach(element => {
                  this.sumInsuredList.push(element.sumInsured);
                });
              } else {
                this.parentalSumInsured.pePsi.forEach(element => {
                  this.sumInsuredList.push(element.grade + '-' + element.sumInsured.toString());
                });
              }
            }
          }

          if (this.sumInsuredTypeExtraBenefit === 'GradeWiseDefined') {
            this.enhancedSumInsured.peEsi.forEach(element => {
              this.sumInsuredList.push(element.grade + '-' + element.sumInsured.toString());
            });
          }

          if (this.sumInsuredTypeExtraBenefit === 'GradeWiseMultiples') {
            this.enhancedSumInsured.peEsi.forEach(element => {
              this.allowedSumInsured.sumInsuredList.forEach(si => {
                if (si.grade === element.grade) {
                  const suminsured = si.sumInsured;
                  if (suminsured > element.multiples) {
                    for (let sum = element.multiples; sum < si.sumInsured; sum = sum + element.multiples) {
                      this.sumInsuredList.push(element.grade + '-' + sum);
                    }
                    this.sumInsuredList.push(element.grade + '-' + suminsured);
                  }
                }
              });
            });
          }
        } else {
          console.log(result);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getTopupExtraBenefits() {
    await this.preEnrollmentService
      .getTopupExtraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.topupOption = result;
          this.extraBenefitsId = this.topupOption.peExtraBenefits.id;
          this.sumInsuredTypeExtraBenefit = this.topupOption.peExtraBenefits.sumInsuredType;
          if (this.sumInsuredTypeExtraBenefit === 'Defined') {
            this.topupOption.peTopUp.forEach(element => {
              this.sumInsuredList.push(element.sumInsured);
            });
          }

          if (this.sumInsuredTypeExtraBenefit === 'EqualToSumInsured') {
            if (this.relationshipType === 'Self') {
              if (this.allowedSumInsured.selfSumInsuredType === 'Defined') {
                this.allowedSumInsured.sumInsuredList.forEach(element => {
                  this.sumInsuredList.push(element.sumInsured);
                });
              } else {
                this.allowedSumInsured.sumInsuredList.forEach(element => {
                  this.sumInsuredList.push(element.grade + '-' + element.sumInsured.toString());
                });
              }
            } else if (this.relationshipType !== 'Self') {
              if (this.parentalSumInsured.peExtraBenefits.sumInsuredType === 'Defined') {
                this.parentalSumInsured.pePsi.forEach(element => {
                  this.sumInsuredList.push(element.sumInsured);
                });
              } else {
                this.parentalSumInsured.pePsi.forEach(element => {
                  this.sumInsuredList.push(element.grade + '-' + element.sumInsured.toString());
                });
              }
            }
          }

          if (this.sumInsuredTypeExtraBenefit === 'GradeWiseDefined') {
            this.topupOption.peTopUp.forEach(element => {
              this.sumInsuredList.push(element.grade + '-' + element.sumInsured.toString());
            });
          }

          if (this.sumInsuredTypeExtraBenefit === 'GradeWiseMultiples') {
            this.topupOption.peTopUp.forEach(element => {
              this.allowedSumInsured.sumInsuredList.forEach(si => {
                if (si.grade === element.grade) {
                  const suminsured = si.sumInsured;
                  if (suminsured > element.multiples) {
                    for (let sum = element.multiples; sum < si.sumInsured; sum = sum + element.multiples) {
                      this.sumInsuredList.push(element.grade + '-' + sum);
                    }
                    this.sumInsuredList.push(element.grade + '-' + suminsured);
                  }
                }
              });
            });
          }
        } else {
          console.log(result);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  async save(event) {
    this.errorList = [];
    const isMinMaxAgeValid = await this.checkIfMaxMinDateInRange();
    if (isMinMaxAgeValid) {
      this.premiumAmount = Number(this.premiumAmount.toString().replace(/,/g, ''));
      this.sumInsured = Number(this.sumInsured.toString().replace(/,/g, ''));
      this.showLoader = true;
      this.formatData();
      if (this.data !== '') {
        if (this.sumInsuredType === 'PSI') {
          this.updatePSIPremium();
        } else if (this.sumInsuredType === 'ESI') {
          this.updateESIPremium();
        } else if (this.sumInsuredType === 'TopUp') {
          this.updateTopupPremium();
        } else if (this.sumInsuredType === 'SI') {
          this.updateSIPremium();
        }
      } else {
        if (this.sumInsuredType === 'PSI') {
          this.createPSIPremium();
        } else if (this.sumInsuredType === 'ESI') {
          this.createESIPremium();
        } else if (this.sumInsuredType === 'TopUp') {
          this.createTopupPremium();
        } else if (this.sumInsuredType === 'SI') {
          this.createSIPremium();
        }
      }
    } else {
      this.errorList = [];
      this.errorList.push({ message: 'You have already created premium for this age. Please enter valid age.' });
    }
  }

  formatData() {
    console.log('Inside format data');
    if (this.noOfMembers === 'All' && this.premiumType === 'Flat') {
      this.noOfMembers = -1;
    }

    if (this.age === 'All' && this.premiumType === 'Flat') {
      this.minage = -1;
      this.maxage = -1;
    }

    if (this.sumInsuredType === 'PSI') {
      this.psiPremiumDetails[0].loading = this.loading;
      this.psiPremiumDetails[0].maxAge = this.maxage;
      this.psiPremiumDetails[0].minAge = this.minage;
      this.psiPremiumDetails[0].premiumAmount = this.premiumAmount;
      this.psiPremiumDetails[0].relationship = this.relationship;
      this.psiPremiumDetails[0].numberOfMembers = this.noOfMembers;
      this.psiPremiumDetails[0].pEExtraBenefitsId = this.extraBenefitsId;
      this.psiPremiumDetails[0].pEPolicyId = this.policy.id;

      if (this.sumInsuredTypeExtraBenefit === 'Defined') {
        this.psiPremiumDetails[0].sumInsured = this.sumInsured;
        this.parentalSumInsured.pePsi.forEach(element => {
          if (element.sumInsured === this.sumInsured) {
            this.psiPremiumDetails[0].parentInsuranceId = element.id;
          }
        });
      }

      if (this.sumInsuredTypeExtraBenefit === 'GradeWiseDefined') {
        this.psiPremiumDetails[0].sumInsured = this.sumInsured;
        this.psiPremiumDetails[0].grade = this.grade;
        this.parentalSumInsured.pePsi.forEach(element => {
          if (element.grade === this.grade) {
            this.psiPremiumDetails[0].parentInsuranceId = element.id;
          }
        });
      }
    } else if (this.sumInsuredType === 'ESI') {
      this.esiPremiumDetails[0].loading = this.loading;
      this.esiPremiumDetails[0].maxAge = this.maxage;
      this.esiPremiumDetails[0].minAge = this.minage;
      this.esiPremiumDetails[0].premiumAmount = this.premiumAmount;
      this.esiPremiumDetails[0].relationship = this.relationship;
      this.esiPremiumDetails[0].numberOfMembers = this.noOfMembers;
      this.esiPremiumDetails[0].pEExtraBenefitsId = this.extraBenefitsId;
      this.esiPremiumDetails[0].pEPolicyId = this.policy.id;

      if (this.sumInsuredTypeExtraBenefit === 'Defined') {
        this.esiPremiumDetails[0].sumInsured = this.sumInsured;
        this.enhancedSumInsured.peEsi.forEach(element => {
          if (element.sumInsured === this.sumInsured) {
            this.esiPremiumDetails[0].enhanceInsuranceId = element.id;
          }
        });
      }

      if (
        this.sumInsuredTypeExtraBenefit === 'GradeWiseDefined' ||
        this.sumInsuredTypeExtraBenefit === 'GradeWiseMultiples'
      ) {
        this.esiPremiumDetails[0].sumInsured = this.sumInsured;
        this.esiPremiumDetails[0].grade = this.grade;
        this.enhancedSumInsured.peEsi.forEach(element => {
          if (element.grade === this.grade) {
            this.esiPremiumDetails[0].enhanceInsuranceId = element.id;
          }
        });
      }

      if (this.sumInsuredTypeExtraBenefit === 'EqualToSumInsured') {
        this.esiPremiumDetails[0].sumInsured = this.sumInsured;
        if (this.relationshipType === 'Self') {
          if (this.allowedSumInsured.selfSumInsuredType === 'Defined') {
            this.enhancedSumInsured.peEsi.forEach(element => {
              if (element.sumInsured === this.sumInsured) {
                this.esiPremiumDetails[0].enhanceInsuranceId = element.id;
              }
            });
          } else {
            this.esiPremiumDetails[0].grade = this.grade;
            this.enhancedSumInsured.peEsi.forEach(element => {
              if (element.grade === this.grade) {
                this.esiPremiumDetails[0].enhanceInsuranceId = element.id;
              }
            });
          }
        } else if (this.relationshipType !== 'Self') {
          if (this.parentalSumInsured.peExtraBenefits.sumInsuredType === 'Defined') {
            this.enhancedSumInsured.peEsi.forEach(element => {
              if (element.sumInsured === this.sumInsured) {
                this.esiPremiumDetails[0].enhanceInsuranceId = element.id;
              }
            });
          } else {
            this.esiPremiumDetails[0].grade = this.grade;
            this.enhancedSumInsured.peEsi.forEach(element => {
              if (element.grade === this.grade) {
                this.esiPremiumDetails[0].enhanceInsuranceId = element.id;
              }
            });
          }
        }
      }
    } else if (this.sumInsuredType === 'TopUp') {
      this.topupPremiumDetails[0].loading = this.loading;
      this.topupPremiumDetails[0].maxAge = this.maxage;
      this.topupPremiumDetails[0].minAge = this.minage;
      this.topupPremiumDetails[0].premiumAmount = this.premiumAmount;
      this.topupPremiumDetails[0].relationship = this.relationship;
      this.topupPremiumDetails[0].numberOfMembers = this.noOfMembers;
      this.topupPremiumDetails[0].pEExtraBenefitsId = this.extraBenefitsId;
      this.topupPremiumDetails[0].pEPolicyId = this.policy.id;

      if (this.sumInsuredTypeExtraBenefit === 'Defined') {
        this.topupPremiumDetails[0].sumInsured = this.sumInsured;
        this.topupOption.peTopUp.forEach(element => {
          if (element.sumInsured === this.sumInsured) {
            this.topupPremiumDetails[0].topupInsuranceId = element.id;
          }
        });
      }

      if (
        this.sumInsuredTypeExtraBenefit === 'GradeWiseDefined' ||
        this.sumInsuredTypeExtraBenefit === 'GradeWiseMultiples'
      ) {
        this.topupPremiumDetails[0].sumInsured = this.sumInsured;
        this.topupPremiumDetails[0].grade = this.grade;
        this.topupOption.peTopUp.forEach(element => {
          if (element.grade === this.grade) {
            this.topupPremiumDetails[0].topupInsuranceId = element.id;
          }
        });
      }

      if (this.sumInsuredTypeExtraBenefit === 'EqualToSumInsured') {
        this.topupPremiumDetails[0].sumInsured = this.sumInsured;
        if (this.relationshipType === 'Self') {
          if (this.allowedSumInsured.selfSumInsuredType === 'Defined') {
            this.topupOption.peTopUp.forEach(element => {
              if (element.sumInsured === this.sumInsured) {
                this.topupPremiumDetails[0].topupInsuranceId = element.id;
              }
            });
          } else {
            this.topupPremiumDetails[0].grade = this.grade;
            this.topupOption.peTopUp.forEach(element => {
              if (element.grade === this.grade) {
                this.topupPremiumDetails[0].topupInsuranceId = element.id;
              }
            });
          }
        } else if (this.relationshipType !== 'Self') {
          if (this.parentalSumInsured.peExtraBenefits.sumInsuredType === 'Defined') {
            this.topupOption.peTopUp.forEach(element => {
              if (element.sumInsured === this.sumInsured) {
                this.topupPremiumDetails[0].topupInsuranceId = element.id;
              }
            });
          } else {
            this.topupPremiumDetails[0].grade = this.grade;
            this.topupOption.peTopUp.forEach(element => {
              if (element.grade === this.grade) {
                this.topupPremiumDetails[0].topupInsuranceId = element.id;
              }
            });
          }
        }
      }
    } else if (this.sumInsuredType === 'SI') {
      this.selfSiPremiumDetails[0].loading = this.loading;
      this.selfSiPremiumDetails[0].maxAge = this.maxage;
      this.selfSiPremiumDetails[0].minAge = this.minage;
      this.selfSiPremiumDetails[0].premiumAmount = this.premiumAmount;
      this.selfSiPremiumDetails[0].relationship = this.relationship;
      this.selfSiPremiumDetails[0].numberOfMembers = this.noOfMembers;
      this.selfSiPremiumDetails[0].pEExtraBenefitsId = this.extraBenefitsId;
      this.selfSiPremiumDetails[0].pEPolicyId = this.policy.id;

      if (this.sumInsuredTypeExtraBenefit === 'Defined') {
        this.selfSiPremiumDetails[0].sumInsured = this.sumInsured;
        this.selfSumInsured.peSelfModels.forEach(element => {
          if (element.sumInsured === this.sumInsured) {
            this.selfSiPremiumDetails[0].selfInsuranceId = element.id;
          }
        });
      }

      if (this.sumInsuredTypeExtraBenefit === 'GradeWiseDefined') {
        this.selfSiPremiumDetails[0].sumInsured = this.sumInsured;
        this.selfSiPremiumDetails[0].grade = this.grade;
        this.selfSumInsured.peSelfModels.forEach(element => {
          if (element.grade === this.grade) {
            this.selfSiPremiumDetails[0].selfInsuranceId = element.id;
          }
        });
      }
    }
  }

  async createSIPremium() {
    await this.preEnrollmentService
      .createSelfSIPremium(this.policy.id, this.selfSiPremiumDetails)
      .then(result => {
        this.showLoader = false;
        this.premiumAddedSucessfully.emit(true);
        this.modalService.dismissAll(AddNewPremiumComponent);
        this.alertService.success('Premium added successfully');
      })
      .catch(errorData => {
        this.showLoader = false;
        this.errorList = errorData.error.errors;
        this.alertService.error('Error occured');
      });
  }

  async createPSIPremium() {
    await this.preEnrollmentService
      .createPSIPremium(this.policy.id, this.psiPremiumDetails)
      .then(result => {
        this.showLoader = false;
        this.premiumAddedSucessfully.emit(true);
        this.modalService.dismissAll(AddNewPremiumComponent);
        this.alertService.success('Premium added successfully');
      })
      .catch(errorData => {
        this.showLoader = false;
        this.errorList = errorData.error.errors;
        this.alertService.error('Error occured');
      });
  }

  async createESIPremium() {
    await this.preEnrollmentService
      .createESIPremium(this.policy.id, this.esiPremiumDetails)
      .then(result => {
        this.showLoader = false;
        this.premiumAddedSucessfully.emit(true);
        this.modalService.dismissAll(AddNewPremiumComponent);
        this.alertService.success('Premium added successfully');
      })
      .catch(errorData => {
        this.showLoader = false;
        this.errorList = errorData.error.errors;
        this.alertService.error('Error occured');
      });
  }

  async createTopupPremium() {
    await this.preEnrollmentService
      .createTopupPremium(this.policy.id, this.topupPremiumDetails)
      .then(result => {
        this.showLoader = false;
        this.premiumAddedSucessfully.emit(true);
        this.modalService.dismissAll(AddNewPremiumComponent);
        this.alertService.success('Premium added successfully');
      })
      .catch(errorData => {
        this.showLoader = false;
        this.errorList = errorData.error.errors;
        this.alertService.error('Error occured');
      });
  }

  async updateSIPremium() {
    await this.preEnrollmentService
      .updateSelfSIPremium(this.extraBenefitsId, this.policy.id, this.data.id, this.selfSiPremiumDetails)
      .then(result => {
        this.showLoader = false;
        this.premiumAddedSucessfully.emit(true);
        this.modalService.dismissAll(AddNewPremiumComponent);
        this.alertService.success('Premium updated successfully');
      })
      .catch(errorData => {
        this.showLoader = false;
        this.errorList = errorData.error.errors;
        this.alertService.error('Error occured');
      });
  }

  async updatePSIPremium() {
    await this.preEnrollmentService
      .updatePSIPremium(this.extraBenefitsId, this.policy.id, this.data.id, this.psiPremiumDetails)
      .then(result => {
        this.showLoader = false;
        this.premiumAddedSucessfully.emit(true);
        this.modalService.dismissAll(AddNewPremiumComponent);
        this.alertService.success('Premium updated successfully');
      })
      .catch(errorData => {
        this.showLoader = false;
        this.errorList = errorData.error.errors;
        this.alertService.error('Error occured');
      });
  }

  async updateESIPremium() {
    await this.preEnrollmentService
      .updateESIPremium(this.extraBenefitsId, this.policy.id, this.data.id, this.esiPremiumDetails)
      .then(result => {
        this.showLoader = false;
        this.premiumAddedSucessfully.emit(true);
        this.modalService.dismissAll(AddNewPremiumComponent);
        this.alertService.success('Premium updated successfully');
      })
      .catch(errorData => {
        this.showLoader = false;
        this.errorList = errorData.error.errors;
        this.alertService.error('Error occured');
      });
  }

  async updateTopupPremium() {
    await this.preEnrollmentService
      .updateTopupPremium(this.extraBenefitsId, this.policy.id, this.data.id, this.topupPremiumDetails)
      .then(result => {
        this.showLoader = false;
        this.premiumAddedSucessfully.emit(true);
        this.modalService.dismissAll(AddNewPremiumComponent);
        this.alertService.success('Premium updated successfully');
      })
      .catch(errorData => {
        this.showLoader = false;
        this.errorList = errorData.error.errors;
        this.alertService.error('Error occured');
      });
  }

  close() {
    this.modalService.dismissAll(AddNewPremiumComponent);
  }

  validateMaxAge() {
    let maxage;
    this.policy.relationships.forEach(element => {
      if (element.label === this.relationship) {
        maxage = element.maxAgeLimit;
        return;
      }

      if (element.type === 'Children' && this.relationship === 'Children') {
        if (maxage !== undefined && element.maxAgeLimit > maxage) {
          maxage = element.maxAgeLimit;
        } else if (maxage === undefined) {
          maxage = element.maxAgeLimit;
        }
      }

      if (element.type === 'Self-Parent' && this.relationship === 'Self-Parent') {
        if (maxage !== undefined && element.maxAgeLimit > maxage) {
          maxage = element.maxAgeLimit;
        } else if (maxage === undefined) {
          maxage = element.maxAgeLimit;
        }
      }

      if (element.type === 'Parent' && this.relationship === 'Parent') {
        if (maxage !== undefined && element.maxAgeLimit > maxage) {
          maxage = element.maxAgeLimit;
        } else if (maxage === undefined) {
          maxage = element.maxAgeLimit;
        }
      }

      if (element.type === 'Parent-In-Law' && this.relationship === 'Parent-In-Law') {
        if (maxage !== undefined && element.maxAgeLimit > maxage) {
          maxage = element.maxAgeLimit;
        } else if (maxage === undefined) {
          maxage = element.maxAgeLimit;
        }
      }
    });

    if (this.maxage <= maxage) {
      this.isMaxAgeValid = true;
    } else {
      this.isMaxAgeValid = false;
      this.invalidMaxAgeMsg = 'Maximum age is invalid';
    }

    if (this.minage !== undefined && this.minage > this.maxage) {
      this.isMaxAgeValid = false;
      this.invalidMaxAgeMsg = 'Maximum age is invalid';
    }
    this.checkSaveButtonVisibility();
  }

  validateMinAge() {
    let minage;
    this.policy.relationships.forEach(element => {
      if (element.label === this.relationship) {
        minage = element.minAgeLimit;
        return;
      }

      if (element.type === 'Children' && this.relationship === 'Children') {
        if (minage !== undefined && element.minAgeLimit < minage) {
          minage = element.minAgeLimit;
        } else if (minage === undefined) {
          minage = element.minAgeLimit;
        }
      }

      if (element.type === 'Self-Parent' && this.relationship === 'Self-Parent') {
        if (minage !== undefined && element.minAgeLimit < minage) {
          minage = element.minAgeLimit;
        } else if (minage === undefined) {
          minage = element.minAgeLimit;
        }
      }

      if (element.type === 'Parent' && this.relationship === 'Parent') {
        if (minage !== undefined && element.minAgeLimit < minage) {
          minage = element.minAgeLimit;
        } else if (minage === undefined) {
          minage = element.minAgeLimit;
        }
      }

      if (element.type === 'Parent-In-Law' && this.relationship === 'Parent-In-Law') {
        if (minage !== undefined && element.minAgeLimit < minage) {
          minage = element.minAgeLimit;
        } else if (minage === undefined) {
          minage = element.minAgeLimit;
        }
      }
    });

    if (this.minage >= minage) {
      this.isMinAgeValid = true;
      this.invalidMinAgeMsg = '';
    } else {
      this.isMinAgeValid = false;
      this.invalidMinAgeMsg = 'Minimum age is invalid';
    }

    if (this.maxage !== undefined && this.minage > this.maxage) {
      this.isMinAgeValid = false;
      this.invalidMinAgeMsg = 'Minimum age is invalid';
    }
    this.checkSaveButtonVisibility();
  }

  setMaxAge() {
    let maxage;
    this.policy.relationships.forEach(element => {
      if (element.label === this.relationship) {
        maxage = element.maxAgeLimit;
        return;
      }

      if (element.type === 'Children' && this.relationship === 'Children') {
        if (maxage !== undefined && element.maxAgeLimit > maxage) {
          maxage = element.maxAgeLimit;
        } else if (maxage === undefined) {
          maxage = element.maxAgeLimit;
        }
      }

      if (element.type === 'Self-Parent' && this.relationship === 'Self-Parent') {
        if (maxage !== undefined && element.maxAgeLimit > maxage) {
          maxage = element.maxAgeLimit;
        } else if (maxage === undefined) {
          maxage = element.maxAgeLimit;
        }
      }

      if (element.type === 'Parent' && this.relationship === 'Parent') {
        if (maxage !== undefined && element.maxAgeLimit > maxage) {
          maxage = element.maxAgeLimit;
        } else if (maxage === undefined) {
          maxage = element.maxAgeLimit;
        }
      }

      if (element.type === 'Parent-In-Law' && this.relationship === 'Parent-In-Law') {
        if (maxage !== undefined && element.maxAgeLimit > maxage) {
          maxage = element.maxAgeLimit;
        } else if (maxage === undefined) {
          maxage = element.maxAgeLimit;
        }
      }
    });

    this.maxage = maxage;
    this.isMaxAgeValid = true;
  }

  setMinAge() {
    let minage;
    this.policy.relationships.forEach(element => {
      if (element.label === this.relationship) {
        minage = element.minAgeLimit;
        return;
      }

      if (element.type === 'Children' && this.relationship === 'Children') {
        if (minage !== undefined && element.minAgeLimit < minage) {
          minage = element.minAgeLimit;
        } else if (minage === undefined) {
          minage = element.minAgeLimit;
        }
      }

      if (element.type === 'Self-Parent' && this.relationship === 'Self-Parent') {
        if (minage !== undefined && element.minAgeLimit < minage) {
          minage = element.minAgeLimit;
        } else if (minage === undefined) {
          minage = element.minAgeLimit;
        }
      }

      if (element.type === 'Parent' && this.relationship === 'Parent') {
        if (minage !== undefined && element.minAgeLimit < minage) {
          minage = element.minAgeLimit;
        } else if (minage === undefined) {
          minage = element.minAgeLimit;
        }
      }

      if (element.type === 'Parent-In-Law' && this.relationship === 'Parent-In-Law') {
        if (minage !== undefined && element.minAgeLimit < minage) {
          minage = element.minAgeLimit;
        } else if (minage === undefined) {
          minage = element.minAgeLimit;
        }
      }
    });

    this.minage = minage;
    this.isMinAgeValid = true;
  }

  onNoOfMembersDropdownChanged(noOfMembers) {
    this.noOfMembers = noOfMembers;
    this.checkSaveButtonVisibility();
  }

  onRelationshipDropdownChanged(relationship) {
    this.relationship = relationship;
    this.noOfMembersDropdownEle = [];
    this.minage = undefined;
    this.maxage = undefined;
    this.noOfMembers = undefined;
    this.setMaxAge();
    this.setMinAge();
    this.checkNumberOfMembersDropdown();
    this.checkSaveButtonVisibility();
  }

  checkNumberOfMembersDropdown() {
    let countLimit;
    this.policy.relationships.forEach(element => {
      if (element.label === this.relationship) {
        countLimit = element.count;
      }

      if (element.type === 'Children' && this.relationship === 'Children') {
        if (countLimit !== undefined && element.count > countLimit) {
          countLimit = element.count;
        } else if (countLimit === undefined) {
          countLimit = element.count;
        }
      }

      if (element.type === 'Self-Parent' && this.relationship === 'Self-Parent') {
        if (countLimit !== undefined && element.count > countLimit) {
          countLimit = element.count;
        } else if (countLimit === undefined) {
          countLimit = element.count;
        }
      }

      if (element.type === 'Parent' && this.relationship === 'Parent') {
        if (countLimit !== undefined && element.count > countLimit) {
          countLimit = element.count;
        } else if (countLimit === undefined) {
          countLimit = element.count;
        }
      }

      if (element.type === 'Parent-In-Law' && this.relationship === 'Parent-In-Law') {
        if (countLimit !== undefined && element.count > countLimit) {
          countLimit = element.count;
        } else if (countLimit === undefined) {
          countLimit = element.count;
        }
      }
    });

    for (let index = 1; index <= countLimit; index++) {
      this.noOfMembersDropdownEle.push(index);
    }
  }

  validateLoading() {
    if (this.isNumeric(this.loading) && this.loading > 0 && this.loading <= 5) {
      this.isLoadingValid = true;
      this.invalidLoadingMsg = '';
    } else {
      this.isLoadingValid = false;
      this.invalidLoadingMsg = 'Invalid Loading';
    }
    this.checkSaveButtonVisibility();
  }

  validatePremiumAmount() {
    this.premiumAmount = Number(this.premiumAmount.toString().replace(/,/g, ''));
    if (this.isNumeric(this.premiumAmount) && this.premiumAmount >= 0) {
      this.isPremiumAmountValid = true;
      this.invalidPremiumAmountMsg = '';
    } else {
      this.isPremiumAmountValid = false;
      this.invalidPremiumAmountMsg = 'Invalid Premium Amount';
    }
    const numberFormatPipe = new NumberFormatPipe();
    this.premiumAmount = numberFormatPipe.transform(this.premiumAmount.toString());
    this.checkSaveButtonVisibility();
  }

  isNumeric(n) {
    return !isNaN(n);
  }

  checkSaveButtonVisibility() {
    if (this.premiumType === 'Selective') {
      if (
        this.relationship !== undefined &&
        this.noOfMembers !== undefined &&
        this.minage !== undefined &&
        this.maxage !== undefined &&
        this.sumInsured !== undefined &&
        this.loading !== undefined &&
        this.premiumAmount !== undefined &&
        this.isMaxAgeValid !== false &&
        this.isMinAgeValid !== false &&
        this.isMaxAgeValid !== undefined &&
        this.isMinAgeValid !== undefined &&
        this.isLoadingValid !== undefined &&
        this.isLoadingValid !== false &&
        this.isPremiumAmountValid !== undefined &&
        this.isPremiumAmountValid !== false
      ) {
        this.isSaveButtonVisible = true;
      } else {
        this.isSaveButtonVisible = false;
      }
    } else {
      if (
        this.sumInsured !== undefined &&
        this.loading !== undefined &&
        this.premiumAmount !== undefined &&
        this.isLoadingValid !== undefined &&
        this.isLoadingValid !== false &&
        this.isPremiumAmountValid !== undefined &&
        this.isPremiumAmountValid !== false
      ) {
        this.isSaveButtonVisible = true;
      } else {
        this.isSaveButtonVisible = false;
      }
    }
  }

  async checkIfMaxMinDateInRange() {
    if (this.premiumType === 'Flat') {
      return true;
    }

    await this.getPremiumData();
    if (this.currentPremiumDetailList.length === 0) {
      return true;
    } else if (this.currentPremiumDetailList.length === 1 && this.data !== '') {
      return true;
    } else if (this.currentPremiumDetailList.length >= 1 && this.data === '') {
      for (let index = 0; index < this.currentPremiumDetailList.length; index++) {
        const element = this.currentPremiumDetailList[index];
        if (
          element.relationship === this.relationship &&
          Number(this.sumInsured.toString().replace(/,/g, '')) === element.sumInsured &&
          element.numberOfMembers === this.noOfMembers
        ) {
          const isDateValid = this.checkMaxMinDateValidity(element.maxAge, element.minAge);
          if (!isDateValid) {
            return false;
          }
        }
      }
      return true;
    } else if (this.currentPremiumDetailList.length > 1 && this.data !== '') {
      for (let index = 0; index < this.currentPremiumDetailList.length; index++) {
        const element = this.currentPremiumDetailList[index];
        if (
          element.relationship === this.relationship &&
          Number(this.sumInsured.toString().replace(/,/g, '')) === element.sumInsured &&
          this.data.id !== element.id &&
          element.numberOfMembers === this.noOfMembers
        ) {
          const isDateValid = this.checkMaxMinDateValidity(element.maxAge, element.minAge);
          if (!isDateValid) {
            return false;
          }
        }
      }
      return true;
    }
  }

  checkMaxMinDateValidity(maxage, minage) {
    if (this.minage < minage && this.maxage <= minage) {
      return true;
      // console.log("both values are valid and below min age");
    } else if (this.minage >= maxage && this.maxage > maxage) {
      return true;
      // console.log("both values are valid and above max age");
    } else {
      return false;
      //  console.log("Invalid");
    }
  }

  async getPremiumData() {
    if (this.sumInsuredType === 'PSI') {
      await this.getPSIPremiumData();
    } else if (this.sumInsuredType === 'ESI') {
      await this.getESIPremiumData();
    } else if (this.sumInsuredType === 'TopUp') {
      await this.getTopupPremiumData();
    } else if (this.sumInsuredType === 'SI') {
      await this.getSIPremiumData();
    }
  }

  async getSIPremiumData() {
    const resp = await this.preEnrollmentService.getSelfSIPremium(this.extraBenefitsId);
    this.currentPremiumDetailList = resp;
  }

  async getESIPremiumData() {
    const resp = await this.preEnrollmentService.getESIPremium(this.extraBenefitsId);
    this.currentPremiumDetailList = resp;
  }

  async getPSIPremiumData() {
    const resp = await this.preEnrollmentService.getPSIPremium(this.extraBenefitsId);
    this.currentPremiumDetailList = resp;
  }

  async getTopupPremiumData() {
    const resp = await this.preEnrollmentService.getTopupPremium(this.extraBenefitsId);
    this.currentPremiumDetailList = resp;
  }
}
