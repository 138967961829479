import { PreEnrollmentAdditionSumOptionValuesModel } from './PreEnrollmentAdditionSumOptionValuesModel';

export class PreEnrollmentAdditionSumOptionModel {
  isAllowed = false;
  type = 'EqualToSumInsured';
  plan = 'Floater';
  values: PreEnrollmentAdditionSumOptionValuesModel[] = [new PreEnrollmentAdditionSumOptionValuesModel(null)];
  errorMsg = '';
  instructions = '';
  isValueRepeated = false;
  PeModel = new PreEnrollmentAdditionSumOptionValuesModel(null);
  isGradeWiseMultipleRepeated = false;
  isGradeWiseDefinedRepeated = false;
  constructor() {}

  removeEntry(index) {
    this.values.splice(index, 1);
  }

  addNewEntry() {
    this.values.push(new PreEnrollmentAdditionSumOptionValuesModel(null));
  }

  public isValid() {
    this.errorMsg = '';
    if (this.type === 'EqualToSumInsured' || !this.isAllowed) {
      this.values = [new PreEnrollmentAdditionSumOptionValuesModel(null)];
      return true;
    }

    if (this.type === 'Defined') {
      const insuredValues = this.values.map(el => {
        return el.stringValue;
      });
      const formattedValues = insuredValues.map(el => {
        return el.replace(/,/g, '');
      });
      const checkValues = this.checkIfDuplicateExists(formattedValues);

      if (checkValues) {
        this.isValueRepeated = true;
        return false;
      } else {
        this.isValueRepeated = false;
      }
    }
    if (this.type === 'GradeWiseMultiples') {
      const insuredValues = this.values.map(el => {
        return el.grade.toLowerCase() + el.multiple.toString();
      });
      const formattedValues = insuredValues.map(el => {
        return el.replace(/,/g, '');
      });
      const checkValues = this.checkIfDuplicateExists(formattedValues);

      if (checkValues) {
        this.isGradeWiseMultipleRepeated = true;
        return false;
      } else {
        this.isGradeWiseMultipleRepeated = false;
      }
    }
    if (this.type === 'GradeWiseDefined') {
      const insuredValues = this.values.map(el => {
        return el.grade.toLowerCase() + el.stringValue.toString();
      });
      const formattedValues = insuredValues.map(el => {
        return el.replace(/,/g, '');
      });
      const checkValues = this.checkIfDuplicateExists(formattedValues);

      if (checkValues) {
        this.isGradeWiseDefinedRepeated = true;
        return false;
      } else {
        this.isGradeWiseDefinedRepeated = false;
      }
    }
    let proceed = true;
    for (let index = 0; index < this.values.length; index++) {
      const element = new PreEnrollmentAdditionSumOptionValuesModel(this.values[index]);
      this.values[index] = element;
      this.values[index].isValid(this.type);
      proceed = proceed && element.isValid(this.type);
    }
    return proceed;
  }

  checkIfDuplicateExists(sumInsured) {
    return new Set(sumInsured).size !== sumInsured.length;
  }
}
