export class TopupValues {
  pePolicyId: number;
  extraBenefitsId: number;
  sumInsured: number;
  multiples: number;
  grade: string;
  singlePremium: number;
  doublePremium: number;
  relationship: string;
  discount: number;
  gST: number;
  total: number;
  type = '';
  id = 0;

  stringValue: string;

  isValueInvalid: boolean;
  isGradeInvalid: boolean;
  isMultipleInvalid: boolean;

  // value: number;
  // multiple: number;
  // isValueRepeated: boolean;
  // errorMsg: string;

  constructor(model) {
    Object.assign(this, model);
  }

  isValid(type) {
    if (type === 'Defined' || type === 'GradeWiseDefined') {
      if (this.stringValue !== undefined) {
        const insuredIntValue = Number(this.stringValue.toString().replace(/,/g, ''));
        const isFloat = Number(insuredIntValue) === insuredIntValue && insuredIntValue % 1 !== 0;
        if (isFloat) {
          this.isValueInvalid = true;
        } else {
          this.isValueInvalid =
            !insuredIntValue || isNaN(insuredIntValue) || Number(insuredIntValue.toString().trim()) <= 0;
        }

        if (!this.isValueInvalid) {
          this.sumInsured = Number(this.stringValue.toString().replace(/,/g, ''));
        }
      } else {
        this.isValueInvalid = true;
      }
    }

    if (type === 'GradeWiseMultiples' || type === 'GradeWiseDefined') {
      this.isGradeInvalid = !this.grade;
    }

    if (type === 'GradeWiseMultiples') {
      this.isMultipleInvalid =
        !this.multiples || isNaN(this.multiples) || parseInt(this.multiples.toString().trim(), 10) <= 0;
      if (!this.isMultipleInvalid) {
        this.multiples = parseInt(this.multiples.toString().trim(), 10);
      }
    }

    return !(this.isValueInvalid || this.isGradeInvalid || this.isMultipleInvalid);
  }
}
