<div class="pre-enrollment-component-wrapper">
  <div class="sidenav">
    <div class="sidenav-row">
      <button class="dropdown-btn">
        SELF
      </button>
      <div class="dropdown-container">
        <a id="SelfPD" class="dropdown-item" (click)="setCurrentScreen('Self', 'PD')">Premium Details</a>
        <a id="SelfSI" class="dropdown-item" (click)="setCurrentScreen('Self', 'SI')">SI</a>
        <a id="SelfESI" class="dropdown-item" (click)="setCurrentScreen('Self', 'ESI')">ESI</a>
        <a id="SelfTopUp" class="dropdown-item" (click)="setCurrentScreen('Self', 'TopUp')">TOPUP</a>
      </div>
    </div>
    <div class="sidenav-row">
      <button class="dropdown-btn">
        PARENT
      </button>
      <div class="dropdown-container">
        <a id="ParentPD" class="dropdown-item" (click)="setCurrentScreen('Parent', 'PD')">Premium Details</a>
        <a id="ParentPSI" class="dropdown-item" (click)="setCurrentScreen('Parent', 'PSI')">PSI</a>
        <a id="ParentESI" class="dropdown-item" (click)="setCurrentScreen('Parent', 'ESI')">ESI</a>
        <a id="ParentTopUp" class="dropdown-item" (click)="setCurrentScreen('Parent', 'TopUp')">TOPUP</a>
      </div>
    </div>
    <div class="sidenav-row">
      <button class="dropdown-btn">
        PARENT IN LAW
      </button>
      <div class="dropdown-container">
        <a id="Parent-In-LawPD" class="dropdown-item" (click)="setCurrentScreen('Parent-In-Law', 'PD')"
          >Premium Details</a
        >
        <a id="Parent-In-LawPSI" class="dropdown-item" (click)="setCurrentScreen('Parent-In-Law', 'PSI')">PILSI</a>
        <a id="Parent-In-LawESI" class="dropdown-item" (click)="setCurrentScreen('Parent-In-Law', 'ESI')">ESI</a>
        <a id="Parent-In-LawTopUp" class="dropdown-item" (click)="setCurrentScreen('Parent-In-Law', 'TopUp')">TOPUP</a>
      </div>
    </div>
  </div>
  <div class="w-100">
    <div class="pre-enrollment-component-header">
      <div class="position-relative">
        <nav>
          <ul class="nav nav-tabs">
            <li class="nav-item" *ngIf="sumInsuredType === 'PD'">
              <a
                class="nav-link active"
                id="Premium-details-tab"
                data-toggle="tab"
                href="#Premium-details"
                role="tab"
                aria-controls="Premium-details"
                aria-selected="true"
                (click)="setTabName('PD')"
              >
                Premium Details
              </a>
            </li>
            <li class="nav-item" *ngIf="sumInsuredType !== '' && sumInsuredType !== 'PD'">
              <a
                class="nav-link active"
                id="Sum-insured-tab"
                data-toggle="tab"
                href="#Sum-insured"
                role="tab"
                aria-controls="Sum-insured"
                aria-selected="true"
                (click)="setTabName('SI')"
              >
                Sum Insured
              </a>
            </li>
            <li class="nav-item" *ngIf="sumInsuredType !== '' && sumInsuredType !== 'PD'">
              <a
                class="nav-link"
                id="Premium-calculator-tab"
                data-toggle="tab"
                href="#Premium-calculator"
                role="tab"
                aria-controls="Premium-calculator"
                aria-selected="false"
                (click)="setTabName('PC')"
              >
                Premium Calculator
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div
      class="tab-pane active"
      *ngIf="currentTab === 'PD' || sumInsuredType === 'PD'"
      id="Premium-details"
      role="tabpanel"
    >
      <app-premium-details
        [relationshipType]="relationshipType"
        [sumInsuredType]="sumInsuredType"
        [policy]="policy"
        [corporate]="corporate"
      ></app-premium-details>
    </div>
    <div
      class="tab-pane active"
      *ngIf="currentTab === 'SI' && sumInsuredType !== '' && sumInsuredType !== 'PD'"
      id="Sum-insured"
      role="tabpanel"
    >
      <app-configure-sumnsured
        [relationshipType]="relationshipType"
        [sumInsuredType]="sumInsuredType"
        [policy]="policy"
        [corporate]="corporate"
      ></app-configure-sumnsured>
    </div>
    <div
      class="tab-pane"
      *ngIf="currentTab === 'PC' && sumInsuredType !== '' && sumInsuredType !== 'PD'"
      id="Premium-calculator"
      role="tabpanel"
    >
      <app-configure-premium
        [relationshipType]="relationshipType"
        [sumInsuredType]="sumInsuredType"
        [policy]="policy"
        [corporate]="corporate"
      ></app-configure-premium>
    </div>
  </div>
</div>
