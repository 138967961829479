<header class="site-header">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="d-flex align-items-center">
      <a class="navbar-brand mr-4" routerLink="/cp-member/home"><img src="assets/images/logo-1.png" alt=""/></a>
      <div *ngIf="userRole === 'CPHR' || userRole === 'CPMember'">
        <img class="corporate-img" src="{{ corporate.logoImage }}" />
      </div>
      <div *ngIf="userRole === 'CPHR' || userRole === 'CPMember'" class="corporate-name">
        {{ corporate.name }}
      </div>

      <div class="dropdown card-header-dropdown" *ngIf="userRole === 'Broker' || userRole === 'SM'">
        <button class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          {{ corporate.name }} - {{ corporate.code }}
        </button>
        <div class="dropdown-menu dropdown-sm">
          <div *ngFor="let cor of corporates" (click)="changeCorporate(cor)">
            <a class="dropdown-item">
              <span> {{ cor.name }} - {{ cor.code }} </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="tech-logo">
      <img src="./assets/images/tech.png" alt="" />
    </div>
    <!-- <div class="shimmer wrapper" *ngIf="name === undefined">
      <span class="shimmer circle"></span>
      <span class="shimmer circle"></span>
    </div>
    <div *ngIf="name === undefined || name === ''" class="shimmer lighter circle ml-2"></div>

    <div class="user-menu" *ngIf="name !== undefined && name !== ''">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link">
            <span>{{ name }}</span>
            <figure *ngIf="corporate.logoImage; else demoLogoImage">
              <img src="{{ corporate.logoImage }}" alt="" height="35" width="35" />
            </figure>
            <ng-template #demoLogoImage>
              <figure>
                <img src="../../../../assets/images/default_company.png" alt="" height="35" width="35" />
              </figure>
            </ng-template>

            <div hidden class="shimmer lighter circle ml-2"></div>
          </a>
        </li>
      </ul>
    </div> -->
  </nav>
</header>
