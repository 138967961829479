export class PeSelfModel {
  pePolicyId: number;
  extraBenefitsId: number;
  sumInsured: number;
  multiples: number;
  grade: string;
  singlePremium: number;
  doublePremium: number;
  relationship: string;
  discount: number;
  gST: number;
  total: number;
  type = '';
  id = 0;

  stringValue: string;

  isValueInvalid: boolean;
  isGradeInvalid: boolean;

  constructor(model) {
    Object.assign(this, model);
  }
}
