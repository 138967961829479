export class Notification {
  id: string;
  effectiveDateFrom: string;
  effectiveDateTo: string;
  subject: string;
  content: string;
  isAllCorporate: boolean;
  isForAllPolicy: boolean;
  isForHR: boolean;
  isForMember: boolean;
  isMultiplePolicy: boolean;
  multiplePolicy: any;
  specificCorporate: number;
  corporateCode: string;
  policyNo: string;
  specificPolicy: string;
  notificationBy: string;
  file: string;
  approveRejectFlow: string;
  isApproved: string;
  isRejected: string;
}
