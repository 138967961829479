import { Component, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { Guid } from 'src/app/shared/enums/guid.enum';
import { RoleService } from 'src/app/shared/services/role.service';
import { BrokerService } from 'src/app/broker/service/broker.service';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { CorporatePolicyModel } from 'src/app/corporate-portal/models/corporate-policy.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Roles } from 'src/app/shared/enums/roles.enum';

@Component({
  selector: 'app-create-notifications',
  templateUrl: './create-notifications.component.html',
  styleUrls: ['./create-notifications.component.scss'],
})
export class CreateNotificationsComponent implements OnInit {
  @Input() role: string;
  @Output() public createdSucessfully = new EventEmitter<boolean>();

  date = new Date();
  date1: Date;
  date2: Date;
  broadcast_to: string;
  isSizeValid = false;
  uploadImageInvalidMsg = '';
  currentPolicyChosen = null;

  fileName: string;

  errorList = [];

  title = 'Create Notification';
  saveButtonText = 'Save';
  extraClass = 'from-member';

  policies = [];
  corporates: CorporatePolicyModel[] = [];

  notificationForm = this.fb.group({
    id: [''],
    effectiveDateFrom: ['', Validators.required],
    effectiveDateTo: ['', Validators.required],
    subject: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')]],
    content: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')]],
    isForAllPolicy: [''],
    isAllCorporate: [''],
    specificCorporate: [''],
    file: [''],
    notify_to: ['', Validators.required],
    broadcast_to: ['', Validators.required],
    specificPolicy: [''],
    policyId: [''],
    policyName: [''],
    corporateCode: [''],
    corporateName: [''],
  });

  constructor(
    private cpMemberService: CPMemberService,
    private authService: AuthService,
    private brokerService: BrokerService,
    private policyService: CPPolicyService,
    private fb: FormBuilder,
    injector: Injector,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.role = this.authService.role;

    this.createAttachPolicyForm();
    console.log(this.notificationForm.value);
    this.notificationForm.controls.specificPolicy.setValue(Guid.Default);
    this.notificationForm.controls.id.setValue(Guid.Default);
    this.notificationForm.get('effectiveDateTo').disable();
    this.notificationForm.controls['effectiveDateFrom'].valueChanges.subscribe(value => {
      value = moment(value)
        .add(1, 'month')
        .toDate();
      value = moment(value)
        .subtract(1, 'day')
        .toDate();
      this.notificationForm.controls.effectiveDateTo.setValue(value);
    });
    this.cpMemberService.isHrView.subscribe(v => {
      this.policyService.allPolicies.subscribe(async (policies: any) => {
        this.policies = policies;
      });
    });
  }

  createAttachPolicyForm() {
    if (this.role === Roles.Broker || this.role === Roles.SM) {
      this.notificationForm = this.fb.group({
        id: [''],
        effectiveDateFrom: ['', Validators.required],
        effectiveDateTo: ['', Validators.required],
        subject: [
          '',
          [Validators.required, Validators.minLength(10), Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')],
        ],
        content: [
          '',
          [Validators.required, Validators.minLength(10), Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')],
        ],
        isAllCorporate: [''],
        specificCorporate: [''],
        isForAllPolicy: [''],
        file: [''],
        broadcast_to: ['', Validators.required],
        specificPolicy: [''],
        notify_to: [''],
        policyNumber: [''],
        corporateCode: [''],
        corporateName: [''],
      });
    } else if (this.role === Roles.CPHR) {
      this.notificationForm = this.fb.group({
        id: [''],
        effectiveDateFrom: ['', Validators.required],
        effectiveDateTo: ['', Validators.required],
        subject: [
          '',
          [Validators.required, Validators.minLength(10), Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')],
        ],
        content: [
          '',
          [Validators.required, Validators.minLength(10), Validators.pattern('^(?! )[A-Za-z0-9., ]*(?<! )$')],
        ],
        isForAllPolicy: [''],
        file: [''],
        notify_to: ['', Validators.required],
        specificPolicy: [''],
        policyNumber: [''],
      });
    }
  }

  save(event) {
    this.setFormValue();
    let json = {};
    if (this.role === Roles.CPHR) {
      json = {
        Id: this.notificationForm.value.id,
        EffectiveDateFrom: moment(this.notificationForm.value.effectiveDateFrom).add('6', 'hours'),
        EffectiveDateTo: moment(this.notificationForm.value.effectiveDateTo).add('6', 'hours'),
        Subject: this.notificationForm.value.subject,
        Content: this.notificationForm.value.content,
        IsForAllPolicy: this.notificationForm.value.isForAllPolicy,
        SpecificPolicy: this.notificationForm.value.specificPolicy,
      };
    } else if (this.role === Roles.Broker || this.role === Roles.SM) {
      json = {
        Id: this.notificationForm.value.id,
        EffectiveDateFrom: moment(this.notificationForm.value.effectiveDateFrom).add('330', 'minutes'),
        EffectiveDateTo: moment(this.notificationForm.value.effectiveDateTo).add('330', 'minutes'),
        Subject: this.notificationForm.value.subject,
        Content: this.notificationForm.value.content,
        IsForAllPolicy: this.notificationForm.value.isForAllPolicy,
        SpecificPolicy: this.notificationForm.value.specificPolicy,
        isAllCorporate: this.notificationForm.value.isAllCorporate,
        specificCorporate: this.notificationForm.value.specificCorporate,
      };
    }
    const stringJson = JSON.stringify(json);
    const data = new FormData();
    data.append('File', this.notificationForm.value.file[0]);
    data.append('Json', stringJson);
    this.notificationForm.get('file').setErrors({ invalid: true });
    this.cpMemberService
      .createNotification(data)
      .then(x => {
        this.notificationForm.get('file').setErrors(null);
        this.createdSucessfully.emit(true);
        this.close();
        this.alertService.success('Notification created successfully');
      })
      .catch(errorData => {
        this.notificationForm.get('file').setErrors(null);
        this.errorList = errorData.error.errors;
      });
  }

  setFormValue() {
    if (this.role === Roles.Broker || this.role === Roles.SM) {
      if (this.notificationForm.value.broadcast_to === 'All Corporates') {
        this.notificationForm.controls.isAllCorporate.setValue(true);
      } else {
        this.notificationForm.controls.isAllCorporate.setValue(false);
      }
    }

    if (this.notificationForm.value.notify_to === 'All Policies') {
      this.notificationForm.controls.isForAllPolicy.setValue(true);
    } else {
      this.notificationForm.controls.isForAllPolicy.setValue(false);
    }
  }

  StartDateSelected() {
    this.notificationForm.get('effectiveDateTo').enable();
  }

  setAllPolicies() {
    this.currentPolicyChosen = null;
    this.notificationForm.get('policyNumber').setValidators([]);
    this.notificationForm.get('policyNumber').updateValueAndValidity();
    this.notificationForm.controls.policyNumber.setValue('');
  }

  async getPolicies() {
    this.notificationForm.get('policyNumber').setValidators(Validators.required);
    this.notificationForm.get('policyNumber').updateValueAndValidity();
  }

  onFileChange(file) {
    this.notificationForm.controls.file.setValue(file);
    const name = file[0].name;
    const size = file[0].size;
    this.fileName = name;
    if (this.fileName.length > 30) {
      this.fileName = this.fileName.slice(0, 25) + '...';
    }
    if (size > 5000000) {
      this.isSizeValid = false;
      this.uploadImageInvalidMsg = 'Max size must be 5 Mb';
      this.notificationForm.get('file').setErrors({ invalid: true });
    } else {
      this.isSizeValid = true;
      this.uploadImageInvalidMsg = '';
      this.notificationForm.get('file').setErrors(null);
    }
    const ext = name.substring(name.lastIndexOf('.') + 1);
    const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf'];
    if (!fileExtensionArr.includes(ext.toLowerCase())) {
      this.isSizeValid = false;
      this.uploadImageInvalidMsg = 'Upload valid format file';
      this.notificationForm.get('file').setErrors({ invalid: true });
    }
  }

  deleteFile() {
    (<HTMLInputElement>document.getElementById('file')).value = '';
    this.notificationForm.controls.file.setValue('');
    this.isSizeValid = true;
    this.uploadImageInvalidMsg = '';
    this.fileName = '';
  }

  close() {
    this.modalService.dismissAll(CreateNotificationsComponent);
  }

  policyChanged(policy) {
    this.currentPolicyChosen = policy;
    const policyNumber = policy.policyNumber;
    this.notificationForm.controls.policyNumber.setValue(policyNumber);
    const policyDetail = this.policies.find(policy1 => policy1.policyNumber === policyNumber);
    this.notificationForm.controls.specificPolicy.setValue(policyDetail.policyId);
  }

  setAllCorporates() {
    this.notificationForm.get('corporateCode').setValidators([]);
    this.notificationForm.get('corporateName').setValidators([]);
    this.notificationForm.get('corporateCode').updateValueAndValidity();
    this.notificationForm.get('corporateName').updateValueAndValidity();
    this.notificationForm.controls.corporateName.setValue('');
    this.notificationForm.controls.corporateCode.setValue('');
    this.notificationForm.controls.specificCorporate.setValue(0);
    this.corporates = [];
  }

  async getCorporates() {
    if (this.role === Roles.Broker || this.role === Roles.SM) {
      this.notificationForm.get('corporateCode').setValidators(Validators.required);
      this.notificationForm.get('corporateName').setValidators(Validators.required);
      this.notificationForm.get('corporateCode').updateValueAndValidity();
      this.notificationForm.get('corporateName').updateValueAndValidity();
      this.policyService.getBrokerCorporatesAndPolicies().then(x => {
        this.corporates = x;
      });
    }
  }

  corporateChanged() {
    if (this.role === Roles.Broker || this.role === Roles.SM) {
      const corporateCode = this.notificationForm.value.corporateCode;
      const corporateDetail = this.corporates.find(corporate => corporate.code === corporateCode);
      this.notificationForm.controls.corporateName.setValue(corporateDetail.name);
      this.notificationForm.controls.specificCorporate.setValue(corporateDetail.id);
      this.policies = corporateDetail.policies;
    }
  }
}
