import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';

@Injectable({
  providedIn: 'root',
})
export class CPWellnessService {
  cpmemberbaseapi = 'CorporatePortal';

  constructor(private readonly backendService: BackendService) {}

  async getRhealthCircle(policyNo): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + `/policies/${policyNo}/Wellness/RhealthCircle`).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async getRhealthAssist(policyNo): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.backendService.get(this.cpmemberbaseapi + `/policies/${policyNo}/Wellness/RhealthAssist`).then(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
