import * as moment from 'moment';

export class ReminderEmailDateTime {
  ReminderEmailHrs: number;
  ReminderEmailMin: number;
  ReminderEmailDate: Date = moment(new Date()).toDate();
  isHrsInvalid = false;
  isMinInvalid = false;
  isDateInvalid = false;
  constructor() {}

  public checkHrsValidity() {
    const reminderEmailHrs = Number(this.ReminderEmailHrs);
    const isFloat = Number(reminderEmailHrs) === reminderEmailHrs && reminderEmailHrs % 1 !== 0;
    if (isFloat) {
      this.isHrsInvalid = true;
    } else {
      this.isHrsInvalid =
        !reminderEmailHrs || isNaN(reminderEmailHrs) || Number(reminderEmailHrs.toString().trim()) <= 0;
    }
    return !this.isHrsInvalid;
  }

  public checkMinsValidity() {
    const reminderEmailMins = Number(this.ReminderEmailMin);
    const isFloat = Number(reminderEmailMins) === reminderEmailMins && reminderEmailMins % 1 !== 0;
    if (isFloat) {
      this.isMinInvalid = true;
    } else {
      this.isMinInvalid =
        !reminderEmailMins || isNaN(reminderEmailMins) || Number(reminderEmailMins.toString().trim()) <= 0;
    }
    return !this.isMinInvalid;
  }

  public checkDateValidity() {
    this.isDateInvalid =
      !this.ReminderEmailDate || this.ReminderEmailDate === null || this.ReminderEmailDate === undefined;
    return !this.isDateInvalid;
  }
}
