import { Component, OnInit } from '@angular/core';
import { CPMemberService } from '../../services/CPMember.service';
import { SelfMember } from '../../models/self-member.model';
import { Policy } from '../../models/policy.model';
import { Router } from '@angular/router';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';

@Component({
  selector: 'app-cp-member-enrollment',
  templateUrl: './cp-member-enrollment.component.html',
  styleUrls: ['./cp-member-enrollment.component.scss'],
})
export class CpMemberEnrollmentComponent implements OnInit {
  currentPolicy: Policy;
  policies = [];
  isHr: boolean;
  isMember: boolean;
  isHrView: boolean;
  memberSelf: SelfMember;
  isMemberSelfLoaded = false;

  isEmployeeBelongsToPolicy = false;

  constructor(
    private readonly router: Router,
    private policyService: CPPolicyService,
    private cpMemberService: CPMemberService
  ) {}

  async ngOnInit() {
    this.memberSelf = new SelfMember();
    this.policyService.policies.subscribe(policies => {
      this.policies = policies;
    });

    this.cpMemberService.isHrView.subscribe(x => {
      this.isHrView = x;
      if (this.isHrView) {
        this.router.navigate(['/cp-member/profile']);
      }
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.currentPolicy = policy;
      this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;
      this.isHr = this.cpMemberService.isHr;
      this.isMember = this.cpMemberService.isMember;

      if (policy.policyNumber !== undefined && this.isMember === true && this.currentPolicy.isMemberEnrolled) {
        this.cpMemberService.selfMemberDetails.subscribe(m => {
          this.memberSelf = m;
          this.isMemberSelfLoaded = true;
        });
      }
    });
  }

  onNamePolicyChanged(policy) {
    this.policyService.currentPolicy.next(policy);
  }
}
