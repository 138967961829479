import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import * as moment from 'moment';
import { FamilyClaimsCount } from 'src/app/cp-member/models/family-claims-count.model';
import { FamilyClaimsDetails } from 'src/app/cp-member/models/family-claims-details.model';
import { ClaimDetails } from 'src/app/cp-member/models/claim-details.model';
import { DisallowedAmount } from 'src/app/cp-member/models/track-claim/disallowed-detail.model';
import { ClaimJourney } from 'src/app/cp-member/models/track-claim/claim-journey.model';
import { ClaimPaymentDetails } from 'src/app/cp-member/models/claim-payment-details.model';
import { ClaimCommunicationDetails } from 'src/app/cp-member/models/claim-communication-details.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CPPolicyService } from '../../services/cp-policy.service';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { CorporatePortalBaseComponent } from '../corporate-portal-base/corporate-portal-base.component';
import { SelfMember } from 'src/app/cp-member/models/self-member.model';
import { IntimateClaimUploadDocuments } from 'src/app/cp-member/models/Intimate-claim/intimate-claim-upload-documents.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { IntimateClaimDocument } from 'src/app/cp-member/models/Intimate-claim/intimate-claim-document.model';
import { SubmitFinalDeclaration } from '../../models/submit-final-declaration.model';
import { element } from 'protractor';
import { BrokerService } from 'src/app/broker/service/broker.service';
import { SMService } from 'src/app/sm/service/sm.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Prepost } from 'src/app/shared/models/CorporatePortal/prepost.model';
import { IntimateClaim } from 'src/app/cp-member/models/Intimate-claim/intimate-claim.model';
import * as FileSaver from 'file-saver';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ViewUploadedDocumentsComponent } from './view-uploaded-documents/view-uploaded-documents.component';
import { AbhaAddressId } from 'src/app/shared/enums/abha-address-id.enum';

@Component({
  selector: 'app-track-claims',
  templateUrl: './track-claims.component.html',
  styleUrls: ['./track-claims.component.scss'],
})
export class TrackClaimsComponent extends CorporatePortalBaseComponent implements OnInit {
  isHr: boolean;
  isMember: boolean;
  isEmployeeBelongsToPolicy = false;
  isHrView = false;

  isClaimSubmitted = false;
  isPrepost = false;
  isAddendum = false;
  isCashless = false;
  claimType;
  newClaimNo;
  isFileLoaded = null;

  familyClaimsCount: FamilyClaimsCount[] = [];
  isFamilyClaimsCountLoaded = false;
  apiErrorForFamilyClaimsCount = null;

  fullFamilyClaimsDetails: FamilyClaimsDetails[] = [];
  familyClaimsDetails: FamilyClaimsDetails[] = [];
  isFamilyClaimsDetailsLoaded = false;
  apiErrorForFamilyClaimsDetails = null;

  claimDetails = new ClaimDetails();
  isClaimDetailsLoaded = false;

  disallowedDetails: DisallowedAmount[] = [];
  isDisallowedDetailsLoaded = false;

  claimJourney = new ClaimJourney();
  isClaimJourneyLoaded = false;

  claimPaymentDetails: Array<ClaimPaymentDetails> = [];
  isClaimPaymentDetailsLoaded = false;

  claimCommunicationDetails: ClaimCommunicationDetails[] = [];
  isClaimCommunicationDetailsLoaded = false;

  empId = null;
  invalidEmpId = false;
  invalidEmpIdMsg: string;

  abhaId: string = null;
  abhaAddress: string = null;

  claimNo = null;
  selectedClaim = new FamilyClaimsDetails();

  invalidClaimNo = false;
  invalidClaimNoMsg: string;

  invalidAbhaId = false;
  invalidAbhaIdMsg: string;

  invalidAbhaAddress = false;
  invalidAbhaAddressMsg: string;

  isSearchByEmpId = false;
  isSearchByClaimNo = false;
  searchClaimNo = null;
  isSearchFamilyClaimsCountLoaded = false;
  searchFamilyClaimsCount: FamilyClaimsCount[] = [];

  searchClaimDetails: FamilyClaimsDetails[] = [];
  isSearchClaimDetailsLoaded = false;

  selfMemberDetails: SelfMember;
  currentStep = 1;
  preCurrentStep = 1;

  errorMsg = null;

  isUploadDocumentAllowed: boolean;
  file: any;
  isSizeValid: boolean;
  uploadImageInvalidMsg: string;
  isFileUploaded = true;

  claimDocuments: IntimateClaimDocument[] = [];
  isClaimDocumentsLoaded = false;
  documentList = [];
  fileListDocs = [];
  imageInvalid: boolean;
  intimateClaimDisableNextButton: boolean;
  isFinalDeclared: string;
  isDeclarationSubmitted: boolean;
  finalDeclarationData = new SubmitFinalDeclaration();
  members: any = [];
  multipleDocumentsUploading: boolean;
  errorOccuredWhileUploadingFile: boolean;

  documentListForQueried = [];
  fileNameArrayInUI = [];

  isDocStatusQueried: boolean;

  isQueryLetterLoading = false;

  errorList = [];
  submittingDeclaration: boolean;
  fileArray: any = [];
  claimForm: FormGroup;

  modalOption: NgbModalOptions = {};
  constructor(
    injector: Injector,
    policyService: CPPolicyService,
    authService: AuthService,
    private readonly alertService: AlertService,
    private cpMemberService: CPMemberService,
    private brokerService: BrokerService,
    private smService: SMService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) {
    super(injector, policyService, authService);
  }
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  enableBtn = true;
  pre_post = new Prepost();
  intimateClaim = new IntimateClaim();

  prepostBtnEnable(claimNo) {
    this.pre_post.claimNo = claimNo;
    this.pre_post.inwardType = 'PrePost';
    this.policyService.getPrepostAndAddendum(this.pre_post).then(x => {
      if (x.status) {
        this.isPrepost = true;
      } else {
        this.isPrepost = false;
      }
    });
  }
  adendumBtnEnable(claimNo) {
    this.pre_post.claimNo = claimNo;
    this.pre_post.inwardType = 'Addendum';
    if(claimNo.toString()[0] === '4' || claimNo.toString()[0] === '3')
      this.isAddendum = false;
    else{
    this.policyService.getPrepostAndAddendum(this.pre_post).then(x => {
      if (x.status) {
        this.isAddendum = true;
      } else {
        this.isAddendum = false;
      }
    });
    }
  }

  loadDefaultDocs() {
    let intimationType = 'CL'
    this.cpMemberService
      .getIntimateClaimDocuments(intimationType)
      .then(x => {
        this.claimDocuments = x;
        this.isClaimDocumentsLoaded = true;
      })
      .catch(errorData => {
        console.log('error');
      });
  }

  filterClaimDetails(member){
    this.familyClaimsDetails = this.fullFamilyClaimsDetails.filter(element => element.insuredName == member.insuredName);
  }

  isPrePostApplicable(claimNo) {
    this.prepostBtnEnable(claimNo);
    this.adendumBtnEnable(claimNo);

    this.pre_post.claimNo = claimNo;
    this.policyService.getClaimDetails(this.pre_post).then(x => {
      this.intimateClaim = x;
      this.enableBtn =
        this.intimateClaim.IntimatorMobileNo == undefined || this.intimateClaim.IntimatorMobileNo == '' ? false : true;

      this.enableBtn =
        this.intimateClaim.IntimatorEmailId == undefined || this.intimateClaim.IntimatorEmailId == '' ? false : true;

      if(this.intimateClaim.IntimationType != null && this.intimateClaim.IntimationType == 'CL')
        this.isCashless=true;

      console.log(x);
    });
  }

  async ngOnInit() {
    // console.losg('Get all current policy.');
    this.cpMemberService.isHrView.subscribe(v => {
      this.isHrView = v;
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.isSearchByEmpId = false;
      this.isClaimJourneyLoaded = false;
      this.isClaimDetailsLoaded = false;
      this.isClaimCommunicationDetailsLoaded = false;
      this.isClaimPaymentDetailsLoaded = false;
      this.apiErrorForFamilyClaimsCount = null;
      this.apiErrorForFamilyClaimsDetails = null;

      // console.log('Get employee allowed type/role.');
      this.isHr = this.cpMemberService.isHr;
      this.isMember = this.cpMemberService.isMember;

      // console.log('Does employee belongs to current policy.');
      this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;

      if (this.role === Roles.CPHR || this.role === Roles.CPMember) {
        if (this.policyNumber !== undefined && this.isMember && !this.isHrView && this.isEmployeeBelongsToPolicy) {
          this.getMemberDetails();
        }
      }
    });
  }

  onSubmit(type: any) {
    this.errorList = new Array();
    if (this.documentList.length <= 0) {
      this.errorList.push({ message: 'Please upload document.' });
    }
    if (this.intimateClaim.ClaimedAmount == null || this.intimateClaim.ClaimedAmount == undefined) {
      this.errorList.push({ message: 'Please Enter claim amount in Intimator Details.' });
    } else if (this.intimateClaim.ClaimedAmount <= 0) {
      this.errorList.push({ message: 'Please Enter claim amount greater then 0.' });
    } else if(type === 'Addendum' && this.intimateClaim.ClaimedAmount>this.intimateClaim.DisallowedAmount){
      this.errorList.push({ message: ('Addendum can only be raised for the disallowed amount i.e., Rs. ' + this.intimateClaim.DisallowedAmount.toString() +'. For the rest of the amount (if any) please raise a pre-post with pending bills.')});
    }
    if (
      this.intimateClaim.IntimatorEmailId == null ||
      this.intimateClaim.IntimatorEmailId == undefined ||
      this.intimateClaim.IntimatorMobileNo == null ||
      this.intimateClaim.IntimatorMobileNo == undefined
    ) {
      this.errorList.push({ message: 'Please enter Intimator Details.' });
      return;
    }
    if (this.errorList.length > 0) {
      return;
    }
    let IntimatorRelationship='Self';
    if(this.isHrView) 
      IntimatorRelationship = 'HR';
    const param = {
      IntimatorMobileNo: this.intimateClaim.IntimatorMobileNo,
      IntimatorEmailId: this.intimateClaim.IntimatorEmailId,
      InwardType: type,
      CLDetailID: this.intimateClaim.ClDetailId,
      ClaimedAmount: this.intimateClaim.ClaimedAmount,
      DocumentList: this.documentList,
      ReceivedMode: 'CorporatePortal',
      IntimatorRelationship: IntimatorRelationship,
    };
    this.submittingDeclaration = true;
    this.policyService
      .submitClaimDetails(param)
      .then(x => {
        console.log(x);
        if (x) {
          this.preCurrentStep = 2;
          this.newClaimNo = x.ClaimNumber;
          this.errorOccuredWhileUploadingFile = false;
          this.submittingDeclaration = false;
          this.isClaimSubmitted = true;
        }
      })
      .catch(errorData => {
        console.log('error');
        this.errorOccuredWhileUploadingFile = true;
        this.isFileUploaded = true;
        this.submittingDeclaration = false;
        this.errorList = errorData.error.errors;
        this.isClaimSubmitted = false;
      });
  }

  async getMemberDetails() {
    // console.log('If role is member than get member family claims.');

    await this.cpMemberService
      .getFamilyClaimsCount(this.currentPolicy.policyNumber)
      .then(x => {
        this.familyClaimsCount = x;
        this.familyClaimsCount.forEach(element => {
          if (element.relationship === 'SELF' && element.isSelfCovered === false) {
            const index = this.familyClaimsCount.findIndex(member => member === element);
            this.familyClaimsCount.splice(index, 1);
          }
        });
        this.isFamilyClaimsCountLoaded = true;
      })
      .catch(x => {
        this.apiErrorForFamilyClaimsCount = x;
        console.log('error');
      });

    await this.cpMemberService
      .getFamilyClaimsDetails(this.currentPolicy.policyNumber)
      .then(x => {
        this.fullFamilyClaimsDetails = x;
        this.familyClaimsDetails = x;
        this.isFamilyClaimsDetailsLoaded = true;
      })
      .catch(x => {
        this.apiErrorForFamilyClaimsDetails = x;
        console.log('error');
      });
  }

  async callClaimJournery(claim) {
    this.closeUploadDocuments();
    this.selectedClaim = claim;
    this.claimNo = claim.claimNo;
    this.isClaimJourneyLoaded = false;
    this.errorOccuredWhileUploadingFile = undefined;

    await this.isDocumentStatusQueried();

    await this.getDeclarationFunction();
    this.currentStep = this.isDeclarationSubmitted === false ? 1 : 2;
    if (this.currentStep === 1) {
      const role = this.authService.role;
      if (this.isHrView || role === 'Broker' || role === 'SM') {
        if (this.isSearchByClaimNo) {
          this.policyNumber = claim.policyNo;
          this.empId = claim.employeeID;
          await this.getHrFamilyClaimsCount();
        }

        this.familyClaimsCount.forEach(element => {
          if (element.insuredName === this.selectedClaim.insuredName) {
            this.finalDeclarationData.uhid = element.uhid;
            this.finalDeclarationData.patientName = element.insuredName;
            this.finalDeclarationData.age = element.age;
            return;
          }
        });
        if (role === 'Broker') {
          this.brokerService.brokerProfile.subscribe(broker => {
            this.finalDeclarationData.insuredName = broker.name;
            this.finalDeclarationData.claimantName = broker.name;
            this.finalDeclarationData.mobileNumber = broker.mobileNo;
            this.finalDeclarationData.emailID = broker.email;
          });
        } else if (role === 'CPHR') {
          this.cpMemberService.hrDetails.subscribe(m => {
            this.finalDeclarationData.insuredName = m.name;
            this.finalDeclarationData.claimantName = m.name;
            this.finalDeclarationData.mobileNumber = m.mobileNo;
            this.finalDeclarationData.emailID = m.email;
          });
        } else if (role === 'SM') {
          this.smService.smProfile.subscribe(m => {
            this.finalDeclarationData.insuredName = m.name;
            this.finalDeclarationData.claimantName = m.name;
            this.finalDeclarationData.mobileNumber = m.mobileNo;
            this.finalDeclarationData.emailID = m.email;
          });
        }
      } else {
        this.cpMemberService.memberDetails.subscribe(m => {
          if (m.length > 0) {
            this.members = m;
            this.members.forEach(element => {
              if (element.relationShipId === 'SELF') {
                this.finalDeclarationData.insuredName = element.insuredName;
                this.finalDeclarationData.claimantName = element.insuredName;
              }
              if (element.insuredName === this.selectedClaim.insuredName) {
                this.finalDeclarationData.uhid = element.uhid;
                this.finalDeclarationData.mobileNumber = element.mobileNo;
                this.finalDeclarationData.emailID = element.emailID;
                this.finalDeclarationData.patientName = element.insuredName;
                this.finalDeclarationData.age = element.age;
                return;
              }
            });
          }
        });
      }
    }

    await this.cpMemberService
      .getClaimsJournery(this.claimNo)
      .then(x => {
        this.claimJourney = x;
        this.isClaimJourneyLoaded = true;
        let lastStage = 11;
        for (let i = 1; i < 11; i++) {
          const v = this.claimJourney['s' + i]['isCurrentState'];
          if (i > lastStage) {
            this.claimJourney['s' + i] = null;
          }

          if (v === true) {
            lastStage = i;
          }
        }
      })
      .catch(x => {
        console.log('error');
      });
    this.isDocumentUploadAllowed();
  }

  downloadFile() {
    this.isFileLoaded = false;
    this.cpMemberService
      .getClaimFile(this.selectedClaim.inwardNumber)
      .then(x => {
        const pdfWindow = window.open('');
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(x.file) + "'></iframe>"
        );
        this.isFileLoaded = true;
      })
      .catch(x => {
        console.log('error');
      });
  }

  async callClaimDetails() {
    this.claimDetails = new ClaimDetails();
    this.isClaimDetailsLoaded = false;

    await this.cpMemberService
      .getClaimsDetails(this.claimNo)
      .then(x => {
        this.claimDetails = x;
        this.isClaimDetailsLoaded = true;
      })
      .catch(x => {
        console.log('error');
      });

    this.callDisallowedDetails();
  }

  async callBasicClaimDetails() {
    this.isFamilyClaimsDetailsLoaded = false;

    await this.cpMemberService
      .getBasicClaimsDetails(this.claimNo)
      .then(x => {
        this.selectedClaim = new FamilyClaimsDetails();
        this.familyClaimsDetails = [];
        if (x !== null) {
          this.selectedClaim = x;
          this.familyClaimsDetails.push(x);
          this.callDisallowedDetails();
        }
        this.isFamilyClaimsDetailsLoaded = true;
      })
      .catch(x => {
        this.familyClaimsDetails = [];
        this.isFamilyClaimsDetailsLoaded = true;
        this.apiErrorForFamilyClaimsDetails = x.error.errors[0].message;
        console.log('error');
      });
  }

  async callDisallowedDetails() {
    this.disallowedDetails = [];
    this.isDisallowedDetailsLoaded = false;

    await this.cpMemberService
      .getDisallowedDetails(this.claimNo)
      .then(x => {
        this.disallowedDetails = x;
        this.isDisallowedDetailsLoaded = true;
      })
      .catch(x => {
        console.log('error');
      });
  }

  async callClaimsPaymentDetails() {
    this.isClaimPaymentDetailsLoaded = false;

    await this.cpMemberService
      .getClaimsPaymentDetails(this.claimNo)
      .then(x => {
        this.claimPaymentDetails = x;
        this.isClaimPaymentDetailsLoaded = true;
      })
      .catch(x => {
        this.isClaimPaymentDetailsLoaded = true;
        console.log('error');
      });
  }

  async callClaimsCommunicationDetails() {
    this.claimCommunicationDetails = [];
    this.isClaimCommunicationDetailsLoaded = false;

    await this.cpMemberService
      .getClaimsCommunicationDetails(this.claimNo)
      .then(x => {
        this.claimCommunicationDetails = x;
        this.isClaimCommunicationDetailsLoaded = true;
      })
      .catch(x => {
        console.log('error');
      });
  }

  onNamePolicyChanged(policy) {
    this.isClaimJourneyLoaded = false;
    this.isClaimDetailsLoaded = false;
    this.isClaimCommunicationDetailsLoaded = false;
    this.isClaimPaymentDetailsLoaded = false;
    this.isFamilyClaimsCountLoaded = false;
    this.isFamilyClaimsDetailsLoaded = false;
  }

  searchTrackClaimDetails() {
    if (this.searchClaimNo !== null) {
      this.empId = null;
      this.isSearchByEmpId = false;
      this.searchByClaimNoTrackClaimDetails();
      return;
    }
    this.isSearchByEmpId = true;
    this.searchClaimNo = null;
    this.isClaimJourneyLoaded = false;
    this.isClaimDetailsLoaded = false;
    this.isClaimCommunicationDetailsLoaded = false;
    this.isClaimPaymentDetailsLoaded = false;
    // this.isFamilyClaimsCountLoaded = false;
    // this.isFamilyClaimsDetailsLoaded = false;
    this.getHrFamilyClaimsCount();
    this.getHrFamilyClaimsDetails();
  }

  async searchByClaimNoTrackClaimDetails() {
    this.isSearchByClaimNo = true;
    this.isClaimJourneyLoaded = false;
    this.isClaimDetailsLoaded = false;
    this.isClaimCommunicationDetailsLoaded = false;
    this.isClaimPaymentDetailsLoaded = false;
    this.isFamilyClaimsCountLoaded = false;
    this.isFamilyClaimsDetailsLoaded = false;
    this.claimNo = this.searchClaimNo;
    await this.callBasicClaimDetails();
  }

  async getHrFamilyClaimsCount() {
    await this.cpMemberService
      .getHrFamilyClaimsCount(this.policyNumber, this.empId, this.abhaId, this.abhaAddress)
      .then(x => {
        this.familyClaimsCount = x;
        this.familyClaimsCount.forEach(element => {
          if (element.relationship === 'SELF' && element.isSelfCovered === false) {
            const index = this.familyClaimsCount.findIndex(member => member === element);
            this.familyClaimsCount.splice(index, 1);
          }
        });
        this.isFamilyClaimsCountLoaded = true;
      })
      .catch(x => {
        this.familyClaimsCount = [];
        this.apiErrorForFamilyClaimsCount = x.error.errors[0].message;
        this.isFamilyClaimsCountLoaded = true;
        console.log('error');
      });
  }

  async getHrFamilyClaimsDetails() {
    this.isFamilyClaimsDetailsLoaded = false;
    await this.cpMemberService
      .getHrFamilyClaimsDetails(this.policyNumber, this.empId, this.abhaId, this.abhaAddress)
      .then(x => {
        this.fullFamilyClaimsDetails = x;
        this.familyClaimsDetails = x;
        this.isFamilyClaimsDetailsLoaded = true;
      })
      .catch(x => {
        this.familyClaimsDetails = [];
        this.apiErrorForFamilyClaimsDetails = x.error.errors[0].message;
        this.isFamilyClaimsDetailsLoaded = true;
        console.log('error');
      });
  }

  setSearchByEmpId(event) {
    const empId = event.target.value;
    this.searchClaimNo = null;
    this.abhaId = null;
    this.invalidAbhaId = false;
    this.invalidAbhaIdMsg = '';
    this.abhaAddress = null;
    this.invalidAbhaAddress = false;
    this.invalidAbhaAddressMsg = '';
    if (empId === '') {
      this.invalidEmpId = true;
      this.invalidEmpIdMsg = '';
    } else {
      if (/^\S+$/g.test(empId)) {
        this.invalidEmpId = false;
        this.invalidEmpIdMsg = '';
      } else {
        this.invalidEmpId = true;
        this.invalidEmpIdMsg = 'Please enter valid Employee Id';
      }
    }
    this.isSearchByEmpId = false;
    this.isSearchByClaimNo = false;
    this.searchClaimNo = null;
    this.invalidClaimNo = false;
    this.invalidClaimNoMsg = '';
  }

  setSearchByClaimNo(event) {
    this.invalidEmpId = false;
    this.invalidEmpIdMsg = '';
    this.isSearchByClaimNo = false;
    this.isSearchByEmpId = false;
    this.empId = null;
    this.abhaId = null;
    this.invalidAbhaId = false;
    this.invalidAbhaIdMsg = '';
    this.abhaAddress = null;
    this.invalidAbhaAddress = false;
    this.invalidAbhaAddressMsg = '';
    const claimNo = event.target.value;
    if (claimNo === '') {
      this.invalidClaimNo = true;
      this.invalidClaimNoMsg = '';
    } else {
      if (/^\S+$/g.test(claimNo) === false) {
        this.invalidClaimNo = true;
        this.invalidClaimNoMsg = 'Please enter valid Claim No';
      } else {
        this.invalidClaimNo = false;
        this.invalidClaimNoMsg = '';
      }
    }
  }

  setSearchByAbhaId(event){
    this.members = [];
    this.empId = null;
    this.invalidEmpId = false;
    this.invalidEmpIdMsg = '';
    this.abhaAddress = null;
    this.invalidAbhaAddress = false;
    this.invalidAbhaAddressMsg = '';
    const abhaId = event.target.value;
    if (abhaId === '') {
      this.invalidAbhaId = true;
      this.invalidAbhaIdMsg = '';
    } else {
      if (/^[0-9]{14}$/g.test(abhaId)) {
        this.invalidAbhaId = false;
        this.invalidAbhaIdMsg = '';
      } else {
        this.invalidAbhaId = true;
        this.invalidAbhaIdMsg = 'Please enter valid Abha Id';
      }
    }
    this.isSearchByEmpId = false;
    this.isSearchByClaimNo = false;
    this.searchClaimNo = null;
    this.invalidClaimNo = false;
    this.invalidClaimNoMsg = '';
  }

  setSearchByAbhaAddress(event){
    this.members = [];
    this.empId = null;
    this.invalidEmpId = false;
    this.invalidEmpIdMsg = '';
    this.abhaId = null;
    this.invalidAbhaId = false;
    this.invalidAbhaIdMsg = '';
    const abhaAddress = event.target.value;
    if (abhaAddress === '') {
      this.invalidAbhaAddress = true;
      this.invalidAbhaAddressMsg = '';
    } else {
      if (/^[a-zA-Z]{1}[a-zA-Z0-9\.]*[a-zA-Z0-9]+@abdm$/g.test(abhaAddress)) {
        this.invalidAbhaAddress = false;
        this.invalidAbhaAddressMsg = '';
      } else {
        this.invalidAbhaAddress = true;
        this.invalidAbhaAddressMsg = 'Please enter valid Abha Address';
      }
    }
    this.isSearchByEmpId = false;
    this.isSearchByClaimNo = false;
    this.searchClaimNo = null;
    this.invalidClaimNo = false;
    this.invalidClaimNoMsg = '';
  }

  getUploadDocuments() {
    console.log(this.isUploadDocumentAllowed);
    console.log(this.selectedClaim);
    let intimationType = this.selectedClaim.claimType.toLocaleLowerCase() == 'network reimbursement' ? 'AL':'CL'
    if(this.selectedClaim.claimNo[0] == '7'){
      this.cpMemberService
      .getIntimateClaimDocumentsPACI(this.selectedClaim.claimNo)
      .then( x => {
        this.claimDocuments = x;
        this.claimDocuments.sort(claimDocument => {
          return claimDocument.isMandatory === false ? 0 : claimDocument ? -1 : 1;
        });
        this.isClaimDocumentsLoaded = true;
      })
      .catch(errorData => {
        console.log('error');
      })
    }
    else{
      this.cpMemberService
      .getIntimateClaimDocuments(intimationType)
      .then(x => {
        this.claimDocuments = x;
        this.claimDocuments.sort(claimDocument => {
          return claimDocument.isMandatory === false ? 0 : claimDocument ? -1 : 1;
        });
        this.isClaimDocumentsLoaded = true;
      })
      .catch(errorData => {
        console.log('error');
      });
    }
    
  }

  validateFile(files) {
    console.log(files);
    this.errorOccuredWhileUploadingFile = undefined;
    if (files.length > 0) {
      this.documentListForQueried = [];
      this.fileNameArrayInUI = [];
      this.file = files;
      let firstDigitClaimNo = this.selectedClaim.claimNo[0];
      let DocTypeId='148'; //For CL=reimbursement
      if(firstDigitClaimNo =='1')
        DocTypeId='147' //For AL=Cashless
      if(firstDigitClaimNo == '7')
        DocTypeId='303' //For PACI in UAT
      for (const file of files) {
        const File: IntimateClaimUploadDocuments = {
          FileName: file.name,
          FileBytes: file,
          DocTypeId: DocTypeId,
          OriginalFile: file,
        };
        this.documentListForQueried.push(File);

        let fileNameInUi = file.name;
        if (fileNameInUi.length > 30) {
          fileNameInUi = fileNameInUi.slice(0, 40) + '...';
        }

        this.fileNameArrayInUI.push(fileNameInUi);
        let multipleFilesNameInUi = '';
        multipleFilesNameInUi = this.fileNameArrayInUI.join(', ');
        (<HTMLInputElement>document.getElementById('fileName')).innerText = multipleFilesNameInUi;
        document.getElementById('fileName').style.display = 'block';
        document.getElementById('deleteFile').style.display = 'block';
      }

      for (const file of files) {
        const size = file.size;
        const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
        const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf'];
        if (!fileExtensionArr.includes(ext.toLowerCase())) {
          this.isSizeValid = false;
          this.uploadImageInvalidMsg = file.name + ' is invalid. Upload Valid Format File(Maximum 5 Mb)';
          (<HTMLInputElement>document.getElementById('invalidFileMsg')).innerText = this.uploadImageInvalidMsg;
          document.getElementById('invalidFileMsg').style.display = 'block';
          break;
        } else {
          this.isSizeValid = true;
          this.uploadImageInvalidMsg = '';
          (<HTMLInputElement>document.getElementById('invalidFileMsg')).innerText = this.uploadImageInvalidMsg;
          document.getElementById('invalidFileMsg').style.display = 'none';
        }
        if (this.isSizeValid) {
          if (size > 5000000) {
            this.isSizeValid = false;
            this.uploadImageInvalidMsg = file.name + ' is invalid. Maximum file size must be 5 Mb.';
            (<HTMLInputElement>document.getElementById('invalidFileMsg')).innerText = this.uploadImageInvalidMsg;
            document.getElementById('invalidFileMsg').style.display = 'block';
            break;
          } else {
            this.isSizeValid = true;
            this.uploadImageInvalidMsg = '';
            (<HTMLInputElement>document.getElementById('invalidFileMsg')).innerText = this.uploadImageInvalidMsg;
            document.getElementById('invalidFileMsg').style.display = 'none';
          }
        }

        document.getElementById('deleteFile').style.display = 'block';
        document.getElementById('fileName').style.display = 'block';
      }
      console.log(this.documentListForQueried);
      // this.file = file;
      // document.getElementById('fileName').style.display = 'block';
      // document.getElementById('deleteFile').style.display = 'block';

      // if (file[0].name.length > 30) {
      //   const fileNameInUi = file[0].name.slice(0, 40) + '...';
      //   (<HTMLInputElement>document.getElementById('fileName')).innerText = fileNameInUi;
      // } else {
      //   (<HTMLInputElement>document.getElementById('fileName')).innerText = file[0].name;
      // }

      // const name = file[0].name;
      // const size = file[0].size;
      // if (size > 1000000) {
      //   this.isSizeValid = false;
      //   this.uploadImageInvalidMsg = 'Max size must be 1 Mb';
      //   document.getElementById('invalidFileMsg').style.display = 'block';
      //   (<HTMLInputElement>document.getElementById('invalidFileMsg')).innerText = this.uploadImageInvalidMsg;
      // } else {
      //   this.isSizeValid = true;
      //   this.uploadImageInvalidMsg = '';
      //   document.getElementById('invalidFileMsg').style.display = 'none';
      //   (<HTMLInputElement>document.getElementById('invalidFileMsg')).innerText = this.uploadImageInvalidMsg;
      // }
      // if (this.isSizeValid === true) {
      //   const ext = name.substring(name.lastIndexOf('.') + 1);
      //   const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf'];
      //   if (!fileExtensionArr.includes(ext.toLowerCase())) {
      //     this.isSizeValid = false;
      //     this.uploadImageInvalidMsg = 'Upload valid format file';
      //     document.getElementById('invalidFileMsg').style.display = 'block';
      //     (<HTMLInputElement>document.getElementById('invalidFileMsg')).innerText = this.uploadImageInvalidMsg;
      //   } else {
      //     this.isSizeValid = true;
      //     this.uploadImageInvalidMsg = '';
      //     document.getElementById('invalidFileMsg').style.display = 'none';
      //     (<HTMLInputElement>document.getElementById('invalidFileMsg')).innerText = this.uploadImageInvalidMsg;
      //   }
      // }
      this.documentListForQueried.forEach(element => {
        if (element.FileBytes instanceof File) {
          this.convertFiletoBase64(element.FileBytes).then(FileByte => {
            const filebyteInBase64 = String(FileByte).split('base64,')[1];
            const index1 = this.documentListForQueried.findIndex(document => document === element);
            if (index1 !== -1) {
              this.documentListForQueried[index1].FileBytes = filebyteInBase64;
            }
          });
        }
      });
    }
  }

  submitUploadDocument() {
    const data = {
      claimNo: this.claimNo,
      documentList: this.documentListForQueried,
      ReceivedMode: 'CorporatePortal'
    };
    this.fileArray = [];
    for (let index = 0; index < this.documentListForQueried.length; index++) {
      this.fileArray.push(this.documentListForQueried[index].OriginalFile);
    }
    const dataToSend = new FormData();
    this.fileArray.forEach(file => {
      dataToSend.append('File', file);
    });
    dataToSend.append('Json', JSON.stringify(data));
    this.isFileUploaded = false;
    this.cpMemberService
      .uploadDocument(dataToSend)
      .then(x => {
        console.log(x);
        this.errorOccuredWhileUploadingFile = false;
        document.getElementById('fileName').style.display = 'none';
        document.getElementById('deleteFile').style.display = 'none';
        this.closeUploadDocuments();
        this.isDocumentUploadAllowed();
      })
      .catch(errorData => {
        console.log('error');
        this.errorOccuredWhileUploadingFile = true;
        this.isFileUploaded = true;
      });
  }

  async convertFiletoBase64(file) {
    const result_base64 = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = e => resolve(fileReader.result);
      fileReader.onerror = e => reject(e);
    });
    return result_base64;
  }

  deleteFile() {
    this.documentListForQueried = [];
    this.file = undefined;
    this.fileNameArrayInUI = [];
    this.errorOccuredWhileUploadingFile = undefined;
    document.getElementById('fileName').style.display = 'none';
    document.getElementById('deleteFile').style.display = 'none';
    document.getElementById('invalidFileMsg').style.display = 'none';
  }

  fileSelectionEvent(event, doc, type) {
    console.log(doc);
    const files = event.target.files;
    console.log(typeof files[0]);
    const fileNameArrayInUI = [];
    if (files.length > 0) {
      if (this.documentList.length !== 0) {
        this.documentList = this.removeFileFromIntimateDocuments(this.documentList, doc.documentId);
        this.fileListDocs = this.removeFromFileListDocs(this.fileListDocs, doc.documentId);
      }

      for (const file of files) {
        const File: IntimateClaimUploadDocuments = {
          FileName: file.name,
          FileBytes: file,
          DocTypeId: doc.documentId,
          OriginalFile: file,
        };
        this.documentList.push(File);

        let fileNameInUi = file.name;
        if (fileNameInUi.length > 30) {
          fileNameInUi = fileNameInUi.slice(0, 40) + '...';
        }
        fileNameArrayInUI.push(fileNameInUi);
        let multipleFilesNameInUi = '';
        multipleFilesNameInUi = fileNameArrayInUI.join(', ');

        if (type === 'PrePost' || type === 'Addendum') {
          (<HTMLInputElement>document.getElementById(doc.documentId + 'Ppara')).innerText = multipleFilesNameInUi;
          document.getElementById(doc.documentId + 'Ppara').style.display = 'block';
          document.getElementById(doc.documentId + 'Pdelete').style.display = 'block';
        } else {
          (<HTMLInputElement>document.getElementById(doc.documentId + 'para')).innerText = multipleFilesNameInUi;
          document.getElementById(doc.documentId + 'para').style.display = 'block';
          document.getElementById(doc.documentId + 'delete').style.display = 'block';
        }
      }

      for (const file of files) {
        const size = file.size;
        const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
        const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf'];
        if (!fileExtensionArr.includes(ext.toLowerCase())) {
          this.isSizeValid = false;
          this.uploadImageInvalidMsg = file.name + ' is invalid. Upload Valid Format File(Maximum 5 Mb)';
          if (type === 'PrePost' || type === 'Addendum') {
            (<HTMLInputElement>(
              document.getElementById(doc.documentId + 'Pinvalid')
            )).innerText = this.uploadImageInvalidMsg;
            document.getElementById(doc.documentId + 'Pinvalid').style.display = 'block';
          } else {
            (<HTMLInputElement>(
              document.getElementById(doc.documentId + 'invalid')
            )).innerText = this.uploadImageInvalidMsg;
            document.getElementById(doc.documentId + 'invalid').style.display = 'block';
          }

          break;
        } else {
          this.isSizeValid = true;
          this.uploadImageInvalidMsg = '';
          if (type === 'PrePost' || type === 'Addendum') {
            (<HTMLInputElement>(
              document.getElementById(doc.documentId + 'Pinvalid')
            )).innerText = this.uploadImageInvalidMsg;
            document.getElementById(doc.documentId + 'Pinvalid').style.display = 'none';
          } else {
            (<HTMLInputElement>(
              document.getElementById(doc.documentId + 'invalid')
            )).innerText = this.uploadImageInvalidMsg;
            document.getElementById(doc.documentId + 'invalid').style.display = 'none';
          }
        }
        if (this.isSizeValid) {
          if (size > 5000000) {
            this.isSizeValid = false;
            this.uploadImageInvalidMsg = file.name + ' is invalid. Maximum file size must be 5 Mb.';
            if (type === 'PrePost' || type === 'Addendum') {
              (<HTMLInputElement>(
                document.getElementById(doc.documentId + 'Pinvalid')
              )).innerText = this.uploadImageInvalidMsg;
              document.getElementById(doc.documentId + 'Pinvalid').style.display = 'block';
            } else {
              (<HTMLInputElement>(
                document.getElementById(doc.documentId + 'invalid')
              )).innerText = this.uploadImageInvalidMsg;
              document.getElementById(doc.documentId + 'invalid').style.display = 'block';
            }
            break;
          } else {
            this.isSizeValid = true;
            this.uploadImageInvalidMsg = '';
            if (type === 'PrePost' || type === 'Addendum') {
              (<HTMLInputElement>(
                document.getElementById(doc.documentId + 'Pinvalid')
              )).innerText = this.uploadImageInvalidMsg;
              document.getElementById(doc.documentId + 'Pinvalid').style.display = 'none';
            } else {
              (<HTMLInputElement>(
                document.getElementById(doc.documentId + 'invalid')
              )).innerText = this.uploadImageInvalidMsg;
              document.getElementById(doc.documentId + 'invalid').style.display = 'none';
            }
          }
        }
        if (type === 'PrePost' || type === 'Addendum') {
          document.getElementById(doc.documentId + 'Pdelete').style.display = 'block';
          document.getElementById(doc.documentId + 'Ppara').style.display = 'block';
        } else {
          document.getElementById(doc.documentId + 'delete').style.display = 'block';
          document.getElementById(doc.documentId + 'para').style.display = 'block';
        }
      }

      const FileCopyForValidation = {
        documentID: doc.documentId,
        isImageValid: this.isSizeValid,
      };

      this.fileListDocs.push(FileCopyForValidation);
      for (let i = 0; i < this.fileListDocs.length; i++) {
        if (this.fileListDocs[i].isImageValid === false) {
          this.imageInvalid = true;
          break;
        } else {
          this.imageInvalid = false;
        }
      }

      this.claimDocuments.forEach(element => {
        if (element.isMandatory === true) {
          const index = this.documentList.findIndex(document => document.DocTypeId === element.documentId);
          if (index === -1) {
            this.intimateClaimDisableNextButton = true;
            return;
          } else {
            this.intimateClaimDisableNextButton = false;
          }
        }
      });

      this.convertFilesArrayToBase64Array();
    }
    console.log(this.intimateClaimDisableNextButton, 'intimateclaimnextbutton');
    console.log(this.imageInvalid, 'Image invaliddd');
    console.log(this.fileListDocs, 'filelistdocs');
    console.log(this.documentList, 'documentlist');
  }

  removeFileFromIntimateDocuments(documentArray, documentId) {
    return documentArray.filter(el => el.DocTypeId !== documentId);
  }
  removeFromFileListDocs(documentArray, documentId) {
    return documentArray.filter(el => el.documentID !== documentId);
  }

  deleteMultipleFile(doc, type) {
    if (type === 'Addendum' || type === 'PrePost') {
      document.getElementById(doc.documentId + 'Ainvalid').style.display = 'none';
      document.getElementById(doc.documentId + 'Adelete').style.display = 'none';
      document.getElementById(doc.documentId + 'Apara').style.display = 'none';
      (<HTMLInputElement>document.getElementById(doc.documentId + 'Apara')).innerText = '';
      (<HTMLInputElement>document.getElementById(doc.documentId + 'Ainvalid')).innerText = '';
    } else {
      document.getElementById(doc.documentId + 'invalid').style.display = 'none';
      document.getElementById(doc.documentId + 'delete').style.display = 'none';
      document.getElementById(doc.documentId + 'para').style.display = 'none';
      (<HTMLInputElement>document.getElementById(doc.documentId + 'para')).innerText = '';
      (<HTMLInputElement>document.getElementById(doc.documentId + 'invalid')).innerText = '';
    }

    this.documentList = this.removeFileFromIntimateDocuments(this.documentList, doc.documentId);
    this.fileListDocs = this.removeFromFileListDocs(this.fileListDocs, doc.documentId);
    console.log(this.documentList);
    console.log(this.fileListDocs);

    this.claimDocuments.forEach(element => {
      if (element.isMandatory === true) {
        const index = this.documentList.findIndex(document => document.DocTypeId === element.documentId);
        if (index === -1) {
          this.intimateClaimDisableNextButton = true;
          return;
        } else {
          this.intimateClaimDisableNextButton = false;
        }
      }
    });
    console.log(this.intimateClaimDisableNextButton, 'Intimate Claim Disable Button');

    for (let i = 0; i < this.fileListDocs.length; i++) {
      if (this.fileListDocs[i].isImageValid === false) {
        this.imageInvalid = true;
        break;
      } else {
        this.imageInvalid = false;
      }
    }
    console.log(this.intimateClaimDisableNextButton, 'intimateclaimnextbutton');
    console.log(this.imageInvalid, 'Image invaliddd');
    console.log(this.fileListDocs, 'filelistdocs');
    console.log(this.documentList, 'documentlist');
  }

  convertFilesArrayToBase64Array() {
    this.documentList.forEach(element => {
      if (element.FileBytes instanceof File) {
        this.convertFiletoBase64(element.FileBytes).then(FileByte => {
          const filebyteInBase64 = String(FileByte).split('base64,')[1];
          const index1 = this.documentList.findIndex(document => document === element);
          if (index1 !== -1) {
            this.documentList[index1].FileBytes = filebyteInBase64;
          }
        });
      }
    });
  }

  previousStep() {
    this.currentStep = this.currentStep - 1;
  }

  step2() {
    const data = {
      claimNo: this.claimNo,
      documentList: this.documentList,
      ReceivedMode: 'CorporatePortal'
    };
    this.fileArray = [];
    this.errorList = [];
    for (let index = 0; index < this.documentList.length; index++) {
      this.fileArray.push(this.documentList[index].OriginalFile);
    }
    const dataToSend = new FormData();
    this.fileArray.forEach(file => {
      dataToSend.append('File', file);
    });
    dataToSend.append('Json', JSON.stringify(data));
    this.multipleDocumentsUploading = true;
    this.cpMemberService
      .uploadDocument(dataToSend)
      .then(x => {
        console.log(x);
        this.multipleDocumentsUploading = false;
        this.currentStep = 3;
      })
      .catch(errorData => {
        console.log('error');
        this.errorList = errorData.error.errors;
        this.multipleDocumentsUploading = false;
      });
  }

  openViewDocumentModal(){
    console.log("You will open the view document modal");
    this.modalOption.backdrop = "static";
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(ViewUploadedDocumentsComponent, this.modalOption);
    modalRef.componentInstance.trial = "Hello World";
    this.cpMemberService.viewAllUploadedDocuments(this.selectedClaim.claimNo)
          .then(res => modalRef.componentInstance.documents = res);
  }

  viewQueriedDocument(){
    this.isQueryLetterLoading = true;
    this.cpMemberService.getQueryDocument(this.selectedClaim.claimNo)
      .then(response => {
        const blob = this.dataURItoBlob(response); //converting the base64 to blob
        FileSaver.saveAs(blob, "QueryDocuments.pdf"); 
        this.isQueryLetterLoading = false;
      })
      .catch(error => {
        this.alertService.error("Error occured!");
        this.isQueryLetterLoading = false;
      });
  }
  dataURItoBlob(base64str) {
    const dataURI = 'data:application/pdf;base64, ' + encodeURI(base64str.file);
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  getFinalDeclaration(value) {
    this.isFinalDeclared = value;
  }

  async step1() {
    await this.submitDeclaration(this.selectedClaim.claimNo);
  }
  async prepostStep1(event) {
    this.claimType = event;
    await this.loadDefaultDocs();
  }

  async submitDeclaration(claimNumber) {
    this.finalDeclarationData.claimNo = claimNumber;
    this.finalDeclarationData.createdDate = moment().format();
    this.finalDeclarationData.isDeclarationAccepted = this.isFinalDeclared === 'yes' ? true : false;

    this.submittingDeclaration = true;
    await this.cpMemberService
      .submitFinalDeclaration(this.finalDeclarationData)
      .then(cj => {
        this.currentStep = 2;
        this.submittingDeclaration = false;
        console.log('success');
      })
      .catch(cj => {
        this.currentStep = 2;
        this.submittingDeclaration = false;
        console.log('error');
      });

    await this.getDeclarationFunction();
    this.currentStep = 2;
  }

  closeUploadDocuments() {
    this.preCurrentStep = 1;
    this.isClaimSubmitted = false;
    this.intimateClaim = new IntimateClaim();
    this.isUploadDocumentAllowed = undefined;
    this.file = undefined;
    this.isSizeValid = undefined;
    this.uploadImageInvalidMsg = undefined;
    this.isFileUploaded = true;
    this.claimDocuments = [];
    this.isClaimDocumentsLoaded = false;
    this.documentList = [];
    this.fileListDocs = [];
    this.imageInvalid = undefined;
    this.intimateClaimDisableNextButton = undefined;
    this.isFinalDeclared = undefined;
    this.isDeclarationSubmitted = undefined;
    this.finalDeclarationData = new SubmitFinalDeclaration();
    this.members = [];
    this.isDocStatusQueried = undefined;
    this.submittingDeclaration = undefined;
    this.multipleDocumentsUploading = undefined;
    this.errorList = [];
  }

  async getDeclarationFunction() {
    await this.cpMemberService
      .getDeclaration(this.claimNo)
      .then(x => {
        this.isDeclarationSubmitted = x;
      })
      .catch(errorData => {
        console.log('error');
      });
  }

  async isDocumentUploadAllowed() {
    await this.cpMemberService
      .allowedDocument(this.claimNo)
      .then(x => {
        this.isUploadDocumentAllowed = x;
      })
      .catch(x => {
        console.log('error');
      });
  }

  async isDocumentStatusQueried() {
    await this.cpMemberService
      .isDocumentStatusQueried(this.claimNo)
      .then(x => {
        this.isDocStatusQueried = x.isQueried;
      })
      .catch(x => {
        console.log('error');
      });
  }

  closeButtonClicked() {
    this.closeUploadDocuments();
    this.errorOccuredWhileUploadingFile = undefined;
  }
}
