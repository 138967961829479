import { Component, OnInit, Input, Injector, SimpleChanges, OnChanges } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreateBrokerComponent } from './create-broker/create-broker.component';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { ListBroker } from 'src/app/shared/models/CorporatePortal/list-broker.model';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ErrorMsgDialog } from 'src/app/shared/components/error-msg-dialog/ErrorMsgDialog';
import { ErrorMsgDialogComponent } from 'src/app/shared/components/error-msg-dialog/error-msg-dialog.component';

@Component({
  selector: 'app-list-broker',
  templateUrl: './list-broker.component.html',
  styleUrls: ['./list-broker.component.scss'],
})
export class ListBrokerComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() corporate: CorporateModel;
  @Input() policyNumber: any;
  policy: any;
  searchText: string;
  isLoading: boolean;
  page = 1;
  pageSize = 5;
  brokerList: ListBroker[] = [];
  brokerProperty = 'brokerProp';
  errorMsg: string[] = [];
  modalOption: NgbModalOptions = {};

  constructor(
    injector: Injector,
    private readonly alertService: AlertService,
    private readonly corporatePortalService: CorporatePortalService,
    private modalService: NgbModal
  ) {
    super(injector);
  }

  open() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(CreateBrokerComponent, this.modalOption);
    modalRef.componentInstance.corporate = this.corporate;
    modalRef.componentInstance.policy = this.policy;
    modalRef.componentInstance.brokerAddedSucessfully.subscribe(isBrokerAdded => {
      if (isBrokerAdded === true) {
        this.isLoading = true;
        this.getBroker();
        this.isLoading = false;
      }
    });
  }
  async ngOnInit() {
    if (this.policyNumber !== 'Attach Policy') {
      this.isLoading = true;
      await this.getBroker();
      this.isLoading = false;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.corporatePortalService.updatedPolicies.subscribe((policyList: any[]) => {
      this.policy = policyList.find(policy => policy.policyNumber === this.policyNumber);
    });
    if (changes['policyNumber'] !== undefined && changes['corporate'] === undefined) {
      if (this.policyNumber !== 'Attach Policy') {
        this.isLoading = true;
        this.searchText = '';
        this.page = 1;
        this.brokerList = [];
        await this.getBroker();
        this.isLoading = false;
      }
    }
  }

  async getBroker() {
    // this.isLoading = true;
    await this.corporatePortalService.getBrokerMappedByPolicy(this.policy.id).then(result => {
      if (result) {
        this.brokerList = result;
      }
      // this.isLoading = false;
    });
  }

  edit(broker) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(CreateBrokerComponent, this.modalOption);
    modalRef.componentInstance.corporate = this.corporate;
    modalRef.componentInstance.policy = this.policy;
    modalRef.componentInstance.brokerAddedSucessfully.subscribe(isBrokerAdded => {
      if (isBrokerAdded === true) {
        this.isLoading = true;
        this.getBroker();
        this.isLoading = false;
      }
    });
    modalRef.componentInstance.brokerDetails = broker;
  }

  async shareCredentials(broker) {
    let result;
    const message = `Are you sure you want to share credentials?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        await this.corporatePortalService
          .sendCredentialsToBroker(this.policy.id, broker.id)
          .then(x => {
            console.log(x);
            this.alertService.success('Credentials shared successfully');
            this.getBroker();
          })
          .catch(errorData => {
            const errorList = errorData.error.errors;
            this.openErrorModal(errorList);
          });
      }
    });
  }

  openErrorModal(errorList) {
    this.errorMsg = [];
    errorList.forEach(errorDescription => {
      this.errorMsg.push(errorDescription.message);
    });

    const dialogData = new ErrorMsgDialog(this.errorMsg);

    this.dialog.open(ErrorMsgDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  async removeBroker(brokerId) {
    let result;
    const message = `Are you sure you want to delete?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.isLoading = true;
          await this.corporatePortalService
            .deleteBroker(brokerId)
            .then(x => {
              this.isLoading = true;
              this.getBroker();
              this.isLoading = false;
              this.alertService.success('Broker deleted successfully');
            })
            .catch(errorData => {
              const errorList = errorData.error.errors;
              this.openErrorModal(errorList);
            });
          this.isLoading = false;
        } catch (error) {
          this.alertService.error('Error occured');
          this.isLoading = false;
        }
      }
    });
  }
}
