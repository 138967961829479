import { ThrowStmt } from '@angular/compiler';

export class PreEnrollmentAdditionSumOptionValuesModel {
  value: number;
  stringValue: string;
  isValueInvalid: boolean;
  grade: string;
  isGradeInvalid: boolean;
  multiple: number;
  isMultipleInvalid: boolean;
  isValueRepeated: boolean;
  errorMsg: string;
  constructor(model) {
    Object.assign(this, model);
  }

  isValid(type) {
    if (type === 'Defined' || type === 'GradeWiseDefined') {
      if (this.stringValue !== undefined) {
        const insuredIntValue = Number(this.stringValue.toString().replace(/,/g, ''));
        const isFloat = Number(insuredIntValue) === insuredIntValue && insuredIntValue % 1 !== 0;
        if (isFloat) {
          this.isValueInvalid = true;
        } else {
          this.isValueInvalid =
            !insuredIntValue || isNaN(insuredIntValue) || Number(insuredIntValue.toString().trim()) <= 0;
        }

        if (!this.isValueInvalid) {
          this.value = Number(this.stringValue.toString().replace(/,/g, ''));
        }
      } else {
        this.isValueInvalid = true;
      }
    }

    if (type === 'GradeWiseMultiples' || type === 'GradeWiseDefined') {
      this.isGradeInvalid = !this.grade;
    }

    if (type === 'GradeWiseMultiples') {
      this.isMultipleInvalid =
        !this.multiple || isNaN(this.multiple) || parseInt(this.multiple.toString().trim(), 10) <= 0;
      if (!this.isMultipleInvalid) {
        this.multiple = parseInt(this.multiple.toString().trim(), 10);
      }
    }

    return !(this.isValueInvalid || this.isGradeInvalid || this.isMultipleInvalid);
  }
}
