import { Component, OnInit, Injector } from '@angular/core';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'src/app/shared/services/role.service';
import { BrokerService } from 'src/app/broker/service/broker.service';
import { CPPolicyService } from '../../services/cp-policy.service';
import { read } from 'fs';
import { Endorsement } from '../../models/endorsement/endorsement.model';
import { ApproveRejectComponent } from '../approve-reject/approve-reject.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CorporatePortalBaseComponent } from '../corporate-portal-base/corporate-portal-base.component';
import { Broker } from 'src/app/shared/models/CorporatePortal/broker.model';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { Sm } from 'src/app/shared/models/CorporatePortal/sm.model';
import { SMService } from 'src/app/sm/service/sm.service';
import { BrokerMaster } from 'src/app/shared/models/CorporatePortal/broker-master.model';
import { EndorsementService } from '../../services/endorsement.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-endorsement',
  templateUrl: './endorsement.component.html',
  styleUrls: ['./endorsement.component.scss'],
})
export class EndorsementComponent extends CorporatePortalBaseComponent implements OnInit {
  endorsements: Endorsement[] = [];
  isEndorsementLoaded = false;
  policy: Policy;
  pageSize = 5;
  endorsementProperty = 'endorsementProp';
  searchText: string;
  page = 1;

  modalOption: NgbModalOptions = {};

  isEndorsementSampleLoaded = false;

  heading = 'View Endorsement';

  // Broker
  brokerPrivileges: Broker = new Broker();

  // SM
  smPrivileges: Sm = new Sm();

  constructor(
    injector: Injector,
    policyService: CPPolicyService,
    authService: AuthService,
    private modalService: NgbModal,
    private smService: SMService,
    private brokerService: BrokerService,
    private endorsementService: EndorsementService,
    private alertService: AlertService,
    private cpMemberService: CPMemberService
  ) {
    super(injector, policyService, authService);
  }

  async ngOnInit(): Promise<void> {
    this.policyService.currentPolicy.subscribe(x => {
      this.policy = x;
      this.endorsements = [];
      this.isEndorsementLoaded = false;
      if (x.policyId !== undefined) {
        if (this.role === Roles.Broker) {
          this.brokerEndorsement(x);
          this.brokerPrivileges = undefined;
          this.brokerService.getBrokerByPolicy(x.policyId).then(b => {
            this.brokerPrivileges = b;
          });
        } else if (this.role === Roles.SM) {
          this.smEndorsement(x);
          this.smService.getSMByPolicy(x.policyId).then(xx => {
            this.smPrivileges = xx;
          });
        }
      }
    });
  }

  brokerEndorsement(x) {
    this.endorsementService
      .getBrokerEndorsement(x.policyId)
      .then(result => {
        this.endorsements = result;
        this.isEndorsementLoaded = true;
      })
      .catch(e => {
        console.log(e);
        this.isEndorsementLoaded = true;
      });
  }

  smEndorsement(x) {
    this.endorsementService
      .getSMEndorsement(x.policyId)
      .then(result => {
        this.endorsements = result;
        this.isEndorsementLoaded = true;
      })
      .catch(e => {
        console.log(e);
        this.isEndorsementLoaded = true;
      });
  }

  approveorreject(endorsement) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(ApproveRejectComponent, this.modalOption);
    modalRef.componentInstance.endorsement = endorsement;
    modalRef.componentInstance.type = 'endorsement';
    modalRef.componentInstance.approveRejectSuccefully.subscribe(isAPISuccess => {
      if (isAPISuccess) {
        this.callBrokerOrSmAPI();
      }
    });
  }

  bulkApproveReject() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(ApproveRejectComponent, this.modalOption);
    modalRef.componentInstance.policyId = this.policy.policyId;
    modalRef.componentInstance.type = 'BulkEndorsement';
    modalRef.componentInstance.approveRejectSuccefully.subscribe(isAPISuccess => {
      if (isAPISuccess) {
        this.callBrokerOrSmAPI();
      }
    });
  }

  callBrokerOrSmAPI() {
    this.policyService.currentPolicy.subscribe(x => {
      this.policy = x;
      this.endorsements = [];
      this.isEndorsementLoaded = false;
      if (x.policyId !== undefined) {
        if (this.role === Roles.Broker) {
          this.brokerEndorsement(x);
          this.brokerPrivileges = this.brokerService.brokerPrivileges;
        } else if (this.role === Roles.SM) {
          this.smEndorsement(x);
          this.smService.getSMByPolicy(x.policyId).then(xx => {
            this.smPrivileges = xx;
          });
        }
      }
    });
  }

  async exportExcel() {
    this.isEndorsementSampleLoaded = true;
    await this.endorsementService.exportExcel(this.policy.policyId).then(
      result => {
        FileSaver.saveAs(result, `Excel.xlsx`);
        this.isEndorsementSampleLoaded = false;
      },
      error => {
        this.alertService.error('Error occured');
        this.isEndorsementSampleLoaded = false;
      }
    );
  }
}
