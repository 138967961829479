import { Component, OnInit } from '@angular/core';
import { BrokerMaster } from 'src/app/shared/models/CorporatePortal/broker-master.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BrokerService } from '../../service/broker.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  broker: BrokerMaster;
  isProfileLoaded = false;

  constructor(private authService: AuthService, private brokerService: BrokerService) {}

  ngOnInit(): void {
    this.isProfileLoaded = false;
    this.brokerService.brokerProfile.subscribe(x => {
      this.broker = x;
      this.isProfileLoaded = true;
    });
  }
}
