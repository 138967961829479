export class EsiPremiumDetails {
  enhanceInsuranceId: number;
  sumInsured: number;
  minAge: number;
  maxAge: number;
  relationship: string;
  loading: number;
  premiumAmount: any;
  numberOfMembers: number;
  pEExtraBenefitsId: number;
  pEPolicyId: number;
  id: number;
  grade: string;
  multiples: number;
}
