import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { PreEnrollmentMemberService } from '../../services/preEnrollment-member.service';
import { PreEnrollmentMemberModel } from 'src/app/shared/models/preEnrollment/members/preEnrollment-member.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { Router } from '@angular/router';
import { MemberValidationHelper } from '../../helpers/member-validation-helper';

@Component({
  selector: 'app-member-hom',
  templateUrl: './member-home.component.html',
  styleUrls: ['./member-home.component.scss'],
})
export class MemberHomeComponent extends BaseComponent implements OnInit, OnDestroy {
  selfMemberFields: any;
  policy: PreEnrollmentPolicyModel;
  isLoading: boolean;
  canEditProfile = false;
  isPolicyStatusActive = false;

  constructor(
    injector: Injector,
    private readonly preEnrollmentMemberService: PreEnrollmentMemberService,
    private readonly memberValidationHelper: MemberValidationHelper,
    private readonly router: Router
  ) {
    super(injector);
  }

  async ngOnInit() {
    super.ngOnInit();
    this.isLoading = true;

    // get policy
    this.policy = this.preEnrollmentMemberService.policy;
    if (this.policy == null) {
      this.preEnrollmentMemberService
        .getPolicy()
        .then(p => {
          this.policy = p;
          this.setPolicyStatus();
        })
        .then(y => {
          // get self member
          this.getSelfMember();
        });
    } else {
      this.setPolicyStatus();
      if (this.preEnrollmentMemberService.selfPolicyModel == null) {
        this.getSelfMember();
      } else {
        this.selfMemberValidation(this.preEnrollmentMemberService.selfPolicyModel);
      }
    }
  }

  setPolicyStatus() {
    this.isPolicyStatusActive = this.policy.period.status;
  }

  getSelfMember() {
    this.preEnrollmentMemberService.getMember().then(x => {
      this.selfMemberValidation(x);
    });
  }

  selfMemberValidation(x) {
    this.selfMemberFields = x.members[0].fields;
    this.canEditProfile =
      this.policy.allowEmployeeEditProfile && !this.memberValidationHelper.isFinalSubmissionLocked(x);

    if (
      !x.members[0].isSelfProfileEdited &&
      this.policy.allowEmployeeEditProfile &&
      !x.members[0].isFinalSubmissionLocked
    ) {
      this.router.navigate(['/member/edit/Profile']);
    }

    this.isLoading = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
