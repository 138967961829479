<div class="track-claims-wrapper pt-3 pb-5">
  <!-- Only for HR Broker SM-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5" *ngIf="(isHr && isHrView && role === 'CPHR') || role === 'Broker' || role === 'SM'">
        <div class="card card-style-1">
          <div class="card-body pb-0">
            <div class="card-title">
              <h4 class="m-0 header-title">View Claim Details Under Policy</h4>
            </div>
            <div class="claim-search-box">
              <div
                *ngIf="currentPolicy.viewPolicyCoverage !== undefined && !currentPolicy.trackClaims"
                class="no-result-wrapper"
              >
                <h5>This feature not Allowed for selected policy.</h5>
              </div>
              <div *ngIf="currentPolicy.trackClaims">
                <app-policies-dropdown class="w-100"></app-policies-dropdown>

                <div class="form-group w-100">
                  <label>Claim Reference Number</label>
                  <input
                    class="form-control"
                    [(ngModel)]="searchClaimNo"
                    (input)="setSearchByClaimNo($event)"
                    placeholder="Claim Reference Number"
                  />
                </div>
                <p *ngIf="invalidClaimNo" class="error-msg">
                  <small>{{ invalidClaimNoMsg }}</small>
                </p>
                <div class="form-group w-100">
                  <label>Employee ID</label>
                  <input
                    class="form-control"
                    [(ngModel)]="empId"
                    (input)="setSearchByEmpId($event)"
                    placeholder="Employee ID"
                  />
                </div>
                <p *ngIf="invalidEmpId" class="error-msg">
                  <small>{{ invalidEmpIdMsg }}</small>
                </p>
                <div class="form-group w-100">
                  <label>Abha ID</label>
                  <input
                    class="form-control"
                    [(ngModel)]="abhaId"
                    (input)="setSearchByAbhaId($event)"
                    placeholder="Abha ID"
                  />
                </div>
                <p *ngIf="invalidAbhaId" class="error-msg">
                  <small>{{ invalidAbhaIdMsg }}</small>
                </p>
                <div class="form-group w-100">
                  <label>Abha Address</label>
                  <input
                    class="form-control"
                    [(ngModel)]="abhaAddress"
                    (input)="setSearchByAbhaAddress($event)"
                    placeholder="Abha Address"
                  />
                </div>
                <p *ngIf="invalidAbhaAddress" class="error-msg">
                  <small>{{ invalidAbhaAddressMsg }}</small>
                </p>
                <div class="form-action-wrapper">
                  <button
                    type="submit"
                    [disabled]="invalidEmpId || invalidClaimNo || invalidAbhaId || invalidAbhaAddress || (searchClaimNo == null && empId == null && abhaId == null && abhaAddress == null)"
                    class="btn w-100 btn-primary rounded-0"
                    (click)="searchTrackClaimDetails()"
                  >
                    Search Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-5"
        *ngIf="
          (isMember && isEmployeeBelongsToPolicy && !isHrView) ||
          ((isHrView || role === 'Broker' || role === 'SM') && isSearchByEmpId)
        "
      >
        <div class="card card-style-1">
          <div class="card-body pb-0">
            <div class="card-title">
              <h4 class="m-0 header-title">Insured Details</h4>
            </div>
            <div class="mt-3">
              <app-policies-dropdown [hidePolicyDropdown]="!(!isHrView && isMember)" class="w-100">
              </app-policies-dropdown>
            </div>

            <div class="claims-cont" *ngIf="currentPolicy.trackClaims">
              <div *ngIf="isFamilyClaimsCountLoaded && familyClaimsCount.length === 0" class="no-result-wrapper">
                <h5>No Data Found!</h5>
                <p *ngIf="apiErrorForFamilyClaimsCount !== null" class="error-msg">
                  <small>{{ apiErrorForFamilyClaimsCount }}</small>
                </p>
              </div>

              <ul class="list-unstyled shimmer-wrapper" *ngIf="!isFamilyClaimsCountLoaded">
                <li class="shimmer-row">
                  <div class="image-shimmer"></div>
                  <div class="shimmer"></div>
                </li>
                <li class="shimmer-row">
                  <div class="image-shimmer"></div>
                  <div class="shimmer"></div>
                </li>
              </ul>

              <ul class="inner-cards" *ngIf="isFamilyClaimsCountLoaded">
                <li *ngFor="let dep of familyClaimsCount"
                      (click)="filterClaimDetails(dep)">
                  <span class="infocard">
                    <img
                      *ngIf="dep.relationship === 'SELF' && dep.sex === 'M'"
                      class="d-flex align-self-start rounded mr-3"
                      src="assets/images/husband.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationship === 'SELF' && dep.sex === 'F'"
                      class="d-flex align-self-start rounded mr-3"
                      src="assets/images/wife.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationship === 'DAUGHTER'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/daughter.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationship === 'SON'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/son.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationship === 'FATHER'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/father.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationship === 'MOTHER'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/mother.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationship === 'SPOUSE' && dep.sex === 'M'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/husband.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationship === 'SPOUSE' && dep.sex === 'F'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/wife.png"
                      alt=""
                      height="64"
                    />
                    <span class="field-data"
                      >{{ dep.insuredName
                      }}<small>
                        <span>{{ dep.relationship }} | </span> {{ dep.age }} Years</small
                      ></span
                    >
                  </span>
                  <span class="claimsbox">
                    {{ dep.claimCount }}
                  </span>
                </li>
              </ul>
            </div>

            <div *ngIf="currentPolicy !== null && !currentPolicy.trackClaims" class="no-result-wrapper">
              <h5>This feature not Allowed for selected policy.</h5>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-5"
        *ngIf="
          (isMember && isEmployeeBelongsToPolicy && !isHrView) ||
          ((isHrView || role === 'Broker' || role === 'SM') && (isSearchByEmpId || isSearchByClaimNo))
        "
      >
        <div class="card card-style-1">
          <div class="card-body pb-0">
            <div class="card-title">
              <h4 class="m-0 header-title">Claim Details</h4>
            </div>
            <div class="mt-3">
              <app-policies-dropdown [hidePolicyDropdown]="!(!isHrView && isMember)" class="w-100">
              </app-policies-dropdown>
            </div>

            <div class="w-100" *ngIf="currentPolicy.trackClaims">
              <ul class="list-unstyled shimmer-wrapper" *ngIf="!isFamilyClaimsDetailsLoaded">
                <li class="mb-2 px-3 shimmer-row">
                  <div class="shimmer w-100"></div>
                </li>
                <li class="mb-2 px-3 shimmer-row">
                  <div class="shimmer w-100"></div>
                </li>
                <li class="mb-2 px-3 shimmer-row">
                  <div class="shimmer w-100"></div>
                </li>
              </ul>

              <div *ngIf="isFamilyClaimsDetailsLoaded && familyClaimsDetails.length === 0" class="no-result-wrapper">
                <h5>No claims found!</h5>
                <p *ngIf="apiErrorForFamilyClaimsDetails !== null" class="error-msg">
                  <small>{{ apiErrorForFamilyClaimsDetails }}</small>
                </p>
              </div>
              <div class="row claims-boxes" *ngIf="isFamilyClaimsDetailsLoaded">
                <div class="col text-center" *ngFor="let fcd of familyClaimsDetails">
                  <a (click)="callClaimJournery(fcd)" matTooltip="Click here to Track claim.">
                    <div
                      class="claim-popup-box"
                      data-toggle="modal"
                      data-target="#claimModal"
                      (click)="isPrePostApplicable(fcd.claimNo)"
                    >
                      <span class="claim-number">
                        {{ fcd.claimNo }}
                      </span>
                      <span class="claim-type">
                        {{ fcd.claimType }}
                      </span>
                      <span *ngIf="fcd.claimNo.charAt(0) != '7'" class="claim-date"> Admission Date {{ fcd.admissionDate | date: 'dd MMMM yyyy' }} </span>
                      <span *ngIf="fcd.claimNo.charAt(0) == '7'" class="claim-date"> Date of Loss {{ fcd.admissionDate | date: 'dd MMMM yyyy' }} </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div *ngIf="currentPolicy !== null && !currentPolicy.trackClaims" class="no-result-wrapper">
              <h5>This feature not Allowed for selected policy.</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Claim Modal -->
<div
  class="modal claim-modal-wrapper fade"
  id="claimModal"
  tabindex="-1"
  data-backdrop="static"
  data-keyboard="false"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content border-0">
      <div class="modal-header">
        <h5 class="modal-title">
          Claim Status <small>{{ selectedClaim.claimNo }}</small>
        </h5>
        <button type="button" class="close" data-toggle="modal" data-target="#claimModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="isClaimJourneyLoaded">
          <ul class="registration-details">
            <li *ngIf="claimJourney.s1 !== null">
              <strong> {{ claimJourney.s1.headerText }}</strong>
              <span class="material-icons completed-state" *ngIf="claimJourney.s1.isStageCompleted">check</span>
              <span>Intimation Number: {{ claimJourney.s1.intimationNumber }}</span>
              <span class="date">Completion Date: {{ claimJourney.s1.completionDate }}</span>
            </li>
            <li *ngIf="claimJourney.s2 !== null">
              <strong> {{ claimJourney.s2.headerText }}</strong>
              <span class="material-icons completed-state" *ngIf="claimJourney.s2.isStageCompleted">check</span>
              <span>{{ claimJourney.s2.documentStatus }}</span>
              <span>Inward Number: {{ claimJourney.s2.inwardNumber }}</span>
              <span class="date">Completion Date: {{ claimJourney.s2.completionDate }}</span>
              <span>
                <button class="btn btn-primary btn-sm mybutton" (click)="openViewDocumentModal()">
                  View Documents
                </button>
              </span>
            </li>
            <li *ngIf="claimJourney.s3 !== null">
              <strong> {{ claimJourney.s3.headerText }}</strong>
              <span class="material-icons completed-state" *ngIf="claimJourney.s3.isStageCompleted">check</span>
              <!-- <span>registrationStatus {{ claimJourney.s3.registrationStatus }}</span> -->
              <span>Claim Number: {{ claimJourney.s3.registrationNo }}</span>
              <span class="date">Completion Date: {{ claimJourney.s3.completionDate }}</span>
            </li>
            <li *ngIf="claimJourney.s4 !== null">
              <strong> {{ claimJourney.s4.headerText }}</strong>
              <span class="material-icons completed-state" *ngIf="claimJourney.s4.isStageCompleted">check</span>
              <!-- <span>policyverificationStatus {{ claimJourney.s4.policyverificationStatus }}</span> -->
              <span class="date">Completion Date: {{ claimJourney.s4.completionDate }}</span>
            </li>
            <li *ngIf="claimJourney.s5 !== null">
              <strong> {{ claimJourney.s5.headerText }}</strong>
              <span class="material-icons completed-state" *ngIf="claimJourney.s5.isStageCompleted">check</span>
              <!-- <span>medicalScrutinyStatus {{ claimJourney.s5.medicalScrutinyStatus }}</span> -->
              <span class="date">Completion Date: {{ claimJourney.s5.completionDate }}</span>
            </li>
            <li *ngIf="claimJourney.s6 !== null">
              <strong> {{ claimJourney.s6.headerText }}</strong>
              <span class="material-icons completed-state" *ngIf="claimJourney.s6.isStageCompleted">check</span>
              <span>Queried Details : {{ claimJourney.s6.queriedDetails }}</span>
              <span>Queried Date : {{ claimJourney.s6.queriedDate }}</span>
              <span *ngIf="claimJourney.s6.isCurrentState">Query Letter : 
                  <button class="btn btn-primary btn-sm mybutton" (click)="viewQueriedDocument()">
                    <p
                      class="spinner-border spinner-border-sm myspinner"
                      role="status"
                      aria-hidden="true"
                      *ngIf="isQueryLetterLoading"
                    ></p>
                    Download</button>
              </span>
              <span class="date">Completion Date: {{ claimJourney.s6.completionDate }}</span>
            </li>
            <li *ngIf="claimJourney.s7 !== null">
              <strong> {{ claimJourney.s7.headerText }}</strong>
              <span class="material-icons completed-state" *ngIf="claimJourney.s7.isStageCompleted">check</span>
              <!-- <span>{{ claimJourney.s7.approvalStatus }}</span> -->
              <span class="date">Completion Date: {{ claimJourney.s7.completionDate }}</span>
            </li>
            <li *ngIf="claimJourney.s8 !== null">
              <strong> {{ claimJourney.s8.headerText }}</strong>
              <span class="material-icons completed-state" *ngIf="claimJourney.s8.isStageCompleted">check</span>
              <span class="date">Completion Date: {{ claimJourney.s8.completionDate }}</span>
            </li>
            <li *ngIf="claimJourney.s9 !== null">
              <strong> {{ claimJourney.s9.headerText }}</strong>
              <span class="material-icons completed-state" *ngIf="claimJourney.s9.isStageCompleted">check</span>
              <!-- <span>RejectedStatus {{ claimJourney.s9.rejectedStatus }}</span> -->
              <span class="date">Completion Date: {{ claimJourney.s9.completionDate }}</span>
            </li>
            <li *ngIf="claimJourney.s10 !== null">
              <strong> {{ claimJourney.s10.headerText }}</strong>
              <span class="material-icons completed-state" *ngIf="claimJourney.s10.isStageCompleted">check</span>
              <span class="date">Completion Date: {{ claimJourney.s10.completionDate }}</span>
            </li>
            <li class="detail-approved-box">
              <strong> {{ claimJourney.claimType }}</strong>
              <ul class="reimbursement-list">
                <li>{{ claimJourney.claimStatus }}</li>
              </ul>
              <!-- <span class="date">Date: 10-Nov-2014</span> -->
              <div class="spinner-border text-primary" *ngIf="isFileLoaded === false">
                <span class="sr-only">Loading...</span>
              </div>
              <div
                *ngIf="
                  (claimJourney.s7 !== null && claimJourney.s7.isStageCompleted) ||
                  (claimJourney.s8 !== null && claimJourney.s8.isStageCompleted) ||
                  (claimJourney.s9 !== null && claimJourney.s9.isStageCompleted) ||
                  (claimJourney.s10 !== null && claimJourney.s10.isStageCompleted)
                "
              >
                <a (click)="downloadFile()">
                  <span class="download-link">
                    <i><img src="assets/images/claim-details1.png" width="100%" alt=""/></i>
                    Download Details</span
                  ></a
                >
              </div>
              <div *ngIf="!isDocStatusQueried">
                <button
                  class="btn btn-primary next action-button btn-margin"
                  [disabled]="!isUploadDocumentAllowed"
                  (click)="getUploadDocuments()"
                  data-toggle="modal"
                  data-target="#intimateClaim_Popup"
                  data-dismiss="modal"
                >
                  Upload Documents
                </button>
                <button
                  *ngIf="isPrepost"
                  class="btn btn-primary next action-button btn-margin"
                  data-toggle="modal"
                  data-target="#prepostPopUp"
                  (click)="prepostStep1('PrePost')"
                  data-dismiss="modal"
                >
                  Pre Post
                </button>

                <button
                  *ngIf="isAddendum && !isCashless"
                  class="btn btn-primary next action-button"
                  data-toggle="modal"
                  data-target="#prepostPopUp"
                  (click)="prepostStep1('Addendum')"
                  data-dismiss="modal"
                >
                  Addendum
                </button>
              </div>
              <div class="form-group" *ngIf="isDocStatusQueried">
                <div class="form-group-row">
                  <button
                    class="btn px-4 btn-sm mb-2 btn-primary"
                    [disabled]="!isUploadDocumentAllowed"
                    (click)="fileInput.click()"
                  >
                    <span>Upload Document</span>
                    <input
                      #fileInput
                      id="file"
                      type="file"
                      (click)="fileInput.file = null"
                      (change)="validateFile($event.target.files)"
                      style="display:none;"
                      multiple
                      accept=".xlsx,.jpg, .png, .doc, .docx,.pdf"
                    />
                  </button>

                  <div [attr.id]="'deleteFile'" style="display: block; width: 100%;">
                    <div class="uploaded-file-wrap">
                      <small [attr.id]="'fileName'" style="display: none;"></small>
                      <button class="btn btn-sm p-0" (click)="fileInput.file = null; deleteFile()">
                        <span class="material-icons">delete</span>
                      </button>
                    </div>
                  </div>
                  <div class="delete-file-wrapper mb-4">
                    <div class="errorMsg" [attr.id]="'invalidFileMsg'" style="display: none;">
                      {{ uploadImageInvalidMsg }}
                    </div>
                  </div>
                </div>

                <div class="spinner-border text-primary mr-3" *ngIf="!isFileUploaded"></div>
                <button
                  class="btn btn-primary next action-button"
                  [hidden]="file === '' || file === undefined"
                  [disabled]="!isSizeValid || (file !== undefined && !isFileUploaded)"
                  (click)="submitUploadDocument()"
                >
                  Submit Documents
                </button>
                <div class="errorMsg" *ngIf="errorOccuredWhileUploadingFile">
                  Error occurred while uploading document
                </div>
                <div class="successfulUploadMessage" *ngIf="errorOccuredWhileUploadingFile === false">
                  Document uploaded successfully
                </div>
              </div>
            </li>
          </ul>
          <ul class="links-clicker">
            <div
              *ngIf="
                (claimJourney.s7 !== null && claimJourney.s7.isStageCompleted) ||
                (claimJourney.s8 !== null && claimJourney.s8.isStageCompleted)
              "
            >
              <a (click)="callClaimDetails()">
                <li data-toggle="modal" data-dismiss="modal" data-target="#claim-details">
                  <span class="icon-cliker">
                    <img src="assets/images/claim-details.svg" />
                  </span>
                  <span class="clicker-text">Claim Details </span>
                </li>
              </a>
            </div>
            <a (click)="callClaimsCommunicationDetails()">
              <li data-toggle="modal" data-target="#communication-details" data-dismiss="modal">
                <span class="icon-cliker">
                  <img src="assets/images/communication-details-01.svg" />
                </span>
                <span class="clicker-text">Communication Details </span>
              </li>
            </a>
            <div
              *ngIf="
              (claimJourney.s7 !== null && claimJourney.s7.isStageCompleted) ||
              (claimJourney.s8 !== null && claimJourney.s8.isStageCompleted)
            "
            >
              <a (click)="callClaimsPaymentDetails()">
                <li data-dismiss="modal" data-toggle="modal" data-target="#payment-details">
                  <span class="icon-cliker">
                    <img src="assets/images/payment-details-01.svg" />
                  </span>
                  <span class="clicker-text">Payments Details </span>
                </li>
              </a>
            </div>
          </ul>
        </div>
        <div *ngIf="!isClaimJourneyLoaded">
          <ul class="shimmer-wrapper list-unstyled">
            <li class="shimmer primary"></li>
            <li class="shimmer secondary"></li>
            <li class="shimmer primary mb-4"></li>
            <li class="shimmer primary"></li>
            <li class="shimmer secondary"></li>
            <li class="shimmer primary mb"></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Disallowed Amount Modal -->
<div
  class="modal fade"
  id="disallowd-details"
  tabindex="-1"
  role="dialog"
  data-backdrop="static"
  data-keyboard="false"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <button class="back-icon" data-toggle="modal" data-target="#claim-details" data-dismiss="modal">
          <span class="material-icons">keyboard_arrow_left</span>
        </button>
        <h5 class="modal-title">Disallowed Amount</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="disallowed-details">
          <span class="amt-details">
            Total Claimed Amount
            <span class="amt"> <i class="fas fa-rupee-sign"></i> {{ claimDetails.claimAmt }} </span>
          </span>
          <span class="amt-details">
            Total Disallowed Amount
            <span class="amt"> <i class="fas fa-rupee-sign"></i> {{ claimDetails.disallowedAmount }} </span>
          </span>
        </div>
        <div class="bill-descrips" *ngFor="let d of disallowedDetails">
          <span class="bill-details">
            <strong><small>Bill Description</small>{{ d.billDescription }}</strong>
            <strong><small>Claim Amount</small> <i class="fas fa-rupee-sign"></i> {{ d.claimedAmount }} </strong>
          </span>
          <span class="bill-details">
            <strong><small>Sub Bill Type</small> {{ d.subBillTypeDescription }}</strong>
            <strong
              ><small> Disallowed Amount </small> <i class="fas fa-rupee-sign"></i> {{ d.disallowedAmount }}</strong
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  -->
<!-- Claim Details Modal -->
<div
  class="modal fade"
  id="claim-details"
  tabindex="-1"
  role="dialog"
  data-backdrop="static"
  data-keyboard="false"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <button class="back-icon" data-toggle="modal" data-target="#claimModal" data-dismiss="modal">
          <span class="material-icons">keyboard_arrow_left</span>
        </button>
        <h5 class="modal-title">Claim Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul *ngIf="!isClaimDetailsLoaded" class="shimmer-wrapper list-unstyled">
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
        </ul>
        <div *ngIf="isClaimDetailsLoaded && claimDetails === null" class="no-result-wrapper">
          <h5>No Data Found!</h5>
        </div>
        <ul class="claim-details" *ngIf="isClaimDetailsLoaded && claimDetails !== null">
          <li>
            <span class="fieldName">
              A. Claimed Amount
            </span>
            <span class="fieldDataFetch">
              <svg
                id="Layer_1"
                style="enable-background:new 0 0 169.8 250;"
                version="1.1"
                viewBox="0 0 169.8 250"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  class="st0"
                  d="M111.7,27.8h39.1l15-22.5H18.9l-15,22.5h25.5c25.8,0,49.7,2,59.2,23.6H18.9l-15,22.5h87.5c0,0.1,0,0.1,0,0.1  c0,16.2-13.5,41-57.9,41l-21.5,0l0,21l86.1,107.6h38.3L47.4,132.4c36.7-2,71.1-22.5,76-58.5h27.4l15-22.5h-42.8  C121.3,42.7,117.6,34.3,111.7,27.8z"
                />
              </svg>
              {{ claimDetails.claimAmt }}
            </span>
          </li>
          <li>
            <span class="fieldName">
              B. Disallowed Amount
            </span>
            <!-- <a (click)="callDisallowedDetails()"> -->
            <span
              class="fieldDataFetch"
              role="button"
              data-toggle="modal"
              data-target="#disallowd-details"
              data-dismiss="modal"
            >
              <svg
                id="Layer_1"
                style="enable-background:new 0 0 169.8 250;"
                version="1.1"
                viewBox="0 0 169.8 250"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  class="st0"
                  d="M111.7,27.8h39.1l15-22.5H18.9l-15,22.5h25.5c25.8,0,49.7,2,59.2,23.6H18.9l-15,22.5h87.5c0,0.1,0,0.1,0,0.1  c0,16.2-13.5,41-57.9,41l-21.5,0l0,21l86.1,107.6h38.3L47.4,132.4c36.7-2,71.1-22.5,76-58.5h27.4l15-22.5h-42.8  C121.3,42.7,117.6,34.3,111.7,27.8z"
                />
              </svg>
              {{ claimDetails.disallowedAmount }}
              <sup class="info">!</sup>
            </span>
            <!-- </a> -->
          </li>
          <li class="total-box">
            <span class="fieldName">
              C. Sanctioned Amount
            </span>
            <span class="fieldDataFetch">
              <svg
                id="Layer_1"
                style="enable-background:new 0 0 169.8 250;"
                version="1.1"
                viewBox="0 0 169.8 250"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  class="st0"
                  d="M111.7,27.8h39.1l15-22.5H18.9l-15,22.5h25.5c25.8,0,49.7,2,59.2,23.6H18.9l-15,22.5h87.5c0,0.1,0,0.1,0,0.1  c0,16.2-13.5,41-57.9,41l-21.5,0l0,21l86.1,107.6h38.3L47.4,132.4c36.7-2,71.1-22.5,76-58.5h27.4l15-22.5h-42.8  C121.3,42.7,117.6,34.3,111.7,27.8z"
                />
              </svg>
              {{ claimDetails.sanctionAmount }}
            </span>
          </li>
        </ul>
        <ul class="claim-details" *ngIf="isClaimDetailsLoaded && claimDetails !== null">
          <li>
            <span class="fieldName">
              D. Compulsory Deduction
              <small>({{ claimDetails.compulsoryDeduction }} % Percentage/Amount)</small>
            </span>
            <span class="fieldDataFetch">
              <svg
                id="Layer_1"
                style="enable-background:new 0 0 169.8 250;"
                version="1.1"
                viewBox="0 0 169.8 250"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  class="st0"
                  d="M111.7,27.8h39.1l15-22.5H18.9l-15,22.5h25.5c25.8,0,49.7,2,59.2,23.6H18.9l-15,22.5h87.5c0,0.1,0,0.1,0,0.1  c0,16.2-13.5,41-57.9,41l-21.5,0l0,21l86.1,107.6h38.3L47.4,132.4c36.7-2,71.1-22.5,76-58.5h27.4l15-22.5h-42.8  C121.3,42.7,117.6,34.3,111.7,27.8z"
                />
              </svg>
              0
            </span>
          </li>
          <li>
            <span class="fieldName">
              E. Co-payment Deduction
              <small>( {{ claimDetails.copaymentDeduction }} % Percentage/Amount)</small>
            </span>
            <span class="fieldDataFetch">
              <svg
                id="Layer_1"
                style="enable-background:new 0 0 169.8 250;"
                version="1.1"
                viewBox="0 0 169.8 250"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  class="st0"
                  d="M111.7,27.8h39.1l15-22.5H18.9l-15,22.5h25.5c25.8,0,49.7,2,59.2,23.6H18.9l-15,22.5h87.5c0,0.1,0,0.1,0,0.1  c0,16.2-13.5,41-57.9,41l-21.5,0l0,21l86.1,107.6h38.3L47.4,132.4c36.7-2,71.1-22.5,76-58.5h27.4l15-22.5h-42.8  C121.3,42.7,117.6,34.3,111.7,27.8z"
                />
              </svg>
              0
            </span>
          </li>
          <li class="total-box">
            <span class="fieldName">
              F. Net Sanction Amount (C-(D+E))
            </span>
            <span class="fieldDataFetch">
              <svg
                id="Layer_1"
                style="enable-background:new 0 0 169.8 250;"
                version="1.1"
                viewBox="0 0 169.8 250"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  class="st0"
                  d="M111.7,27.8h39.1l15-22.5H18.9l-15,22.5h25.5c25.8,0,49.7,2,59.2,23.6H18.9l-15,22.5h87.5c0,0.1,0,0.1,0,0.1  c0,16.2-13.5,41-57.9,41l-21.5,0l0,21l86.1,107.6h38.3L47.4,132.4c36.7-2,71.1-22.5,76-58.5h27.4l15-22.5h-42.8  C121.3,42.7,117.6,34.3,111.7,27.8z"
                />
              </svg>
              {{ claimDetails.netSanctionAmount }}
            </span>
          </li>
        </ul>
        <ul class="claim-details" *ngIf="isClaimDetailsLoaded && claimDetails !== null">
          <li>
            <span class="fieldName">
              G. Service Tax
            </span>
            <span class="fieldDataFetch">
              <svg
                id="Layer_1"
                style="enable-background:new 0 0 169.8 250;"
                version="1.1"
                viewBox="0 0 169.8 250"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  class="st0"
                  d="M111.7,27.8h39.1l15-22.5H18.9l-15,22.5h25.5c25.8,0,49.7,2,59.2,23.6H18.9l-15,22.5h87.5c0,0.1,0,0.1,0,0.1  c0,16.2-13.5,41-57.9,41l-21.5,0l0,21l86.1,107.6h38.3L47.4,132.4c36.7-2,71.1-22.5,76-58.5h27.4l15-22.5h-42.8  C121.3,42.7,117.6,34.3,111.7,27.8z"
                />
              </svg>
              {{ claimDetails.serviceTax }}
            </span>
          </li>
          <li class="total-box">
            <span class="fieldName">
              H. Payable Amount (F+G)
            </span>
            <span class="fieldDataFetch">
              <svg
                id="Layer_1"
                style="enable-background:new 0 0 169.8 250;"
                version="1.1"
                viewBox="0 0 169.8 250"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  class="st0"
                  d="M111.7,27.8h39.1l15-22.5H18.9l-15,22.5h25.5c25.8,0,49.7,2,59.2,23.6H18.9l-15,22.5h87.5c0,0.1,0,0.1,0,0.1  c0,16.2-13.5,41-57.9,41l-21.5,0l0,21l86.1,107.6h38.3L47.4,132.4c36.7-2,71.1-22.5,76-58.5h27.4l15-22.5h-42.8  C121.3,42.7,117.6,34.3,111.7,27.8z"
                />
              </svg>
              {{ claimDetails.payableAmount }}
            </span>
          </li>
        </ul>
        <ul class="claim-details" *ngIf="isClaimDetailsLoaded && claimDetails !== null">
          <li>
            <span class="fieldName">
              I. TDS Amount
            </span>
            <span class="fieldDataFetch">
              <svg
                id="Layer_1"
                style="enable-background:new 0 0 169.8 250;"
                version="1.1"
                viewBox="0 0 169.8 250"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  class="st0"
                  d="M111.7,27.8h39.1l15-22.5H18.9l-15,22.5h25.5c25.8,0,49.7,2,59.2,23.6H18.9l-15,22.5h87.5c0,0.1,0,0.1,0,0.1  c0,16.2-13.5,41-57.9,41l-21.5,0l0,21l86.1,107.6h38.3L47.4,132.4c36.7-2,71.1-22.5,76-58.5h27.4l15-22.5h-42.8  C121.3,42.7,117.6,34.3,111.7,27.8z"
                />
              </svg>
              {{ claimDetails.tdsAmount }}
            </span>
          </li>
          <li class="final-box">
            <span class="fieldName">
              J. Final Payable Amount (H-I)
            </span>
            <span class="fieldDataFetch">
              <svg
                id="Layer_1"
                style="enable-background:new 0 0 169.8 250;"
                version="1.1"
                viewBox="0 0 169.8 250"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  class="st0"
                  d="M111.7,27.8h39.1l15-22.5H18.9l-15,22.5h25.5c25.8,0,49.7,2,59.2,23.6H18.9l-15,22.5h87.5c0,0.1,0,0.1,0,0.1  c0,16.2-13.5,41-57.9,41l-21.5,0l0,21l86.1,107.6h38.3L47.4,132.4c36.7-2,71.1-22.5,76-58.5h27.4l15-22.5h-42.8  C121.3,42.7,117.6,34.3,111.7,27.8z"
                />
              </svg>
              {{ claimDetails.finalPayableAmount }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Communication Details Modal -->
<div
  class="modal fade"
  id="communication-details"
  tabindex="-1"
  role="dialog"
  data-backdrop="static"
  data-keyboard="false"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <button class="back-icon" data-toggle="modal" data-target="#claimModal" data-dismiss="modal">
          <span class="material-icons">keyboard_arrow_left</span>
        </button>
        <h5 class="modal-title">Communication History</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul *ngIf="!isClaimCommunicationDetailsLoaded" class="shimmer-wrapper list-unstyled">
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
        </ul>
        <div
          *ngIf="isClaimCommunicationDetailsLoaded && claimCommunicationDetails.length === 0"
          class="no-result-wrapper"
        >
          <h5>No Data Found!</h5>
        </div>
        <ul class="payment-details" *ngFor="let claimCommunicationDetail of claimCommunicationDetails">
          <li>
            <span class="fieldName">
              <small>Recipient Name</small>
              {{ claimCommunicationDetail.recipientName }}
            </span>
            <span class="fieldDataFetch">
              {{ claimCommunicationDetail.events }}
            </span>
            <ul>
              <li *ngIf="claimCommunicationDetail.smsSent == 'Yes'">
                <span class="communication-method sent">
                  <span class="material-icons">check</span>
                  SMS
                </span>
              </li>
              <li *ngIf="claimCommunicationDetail.emailSent == 'Yes'">
                <span class="communication-method sent">
                  <span class="material-icons">check</span>
                  Email
                </span>
              </li>
              <li *ngIf="claimCommunicationDetail.smsSent == 'No'">
                <span class="communication-method notsent">
                  <span class="material-icons">close</span>
                  SMS
                </span>
              </li>
              <li *ngIf="claimCommunicationDetail.emailSent == 'No'">
                <span class="communication-method notsent">
                  <span class="material-icons">close</span>
                  Email
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Payment Details Modal -->
<div
  class="modal fade"
  id="payment-details"
  tabindex="-1"
  role="dialog"
  data-backdrop="static"
  data-keyboard="false"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <button class="back-icon" data-toggle="modal" data-target="#claimModal" data-dismiss="modal">
          <span class="material-icons">keyboard_arrow_left</span>
        </button>
        <h5 class="modal-title">Payment Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul *ngIf="!isClaimPaymentDetailsLoaded" class="shimmer-wrapper list-unstyled">
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
        </ul>
        <div *ngIf="isClaimPaymentDetailsLoaded && claimPaymentDetails.length == 0 " class="no-result-wrapper">
          <h6>For Payment details please contact your Relationship Manager</h6>
        </div>
        <div *ngIf="isClaimPaymentDetailsLoaded && claimPaymentDetails.length != 0">
          <ul class="payment-details" *ngFor="let claimPaymentDetail of claimPaymentDetails">
            <li>
              <span class="fieldName">
                <small>Payee Name</small>
                {{ claimPaymentDetail.payeename }}
              </span>
              <span class="fieldDataFetch">
                <strong
                  ><i class="fas fa-rupee-sign"></i>
                  {{ claimPaymentDetail.paidAmounts }}
                </strong>
              </span>
            </li>
            <li>
              <span class="fieldName">
                Txn. ID/ Cheque Number
              </span>
              <span class="fieldDataFetch">
                {{ claimPaymentDetail.transactionNumber }}
              </span>
            </li>
            <li>
              <span class="fieldName">
                Transaction Date
              </span>
              <span class="fieldDataFetch">
                {{ claimPaymentDetail.transactionDate }}
              </span>
            </li>
            <li>
              <span class="fieldName">
                Account Number
              </span>
              <span class="fieldDataFetch">
                {{ claimPaymentDetail.accountNumber }}
              </span>
            </li>
            <li>
              <span class="fieldName">
                Bank Name
              </span>
              <span class="fieldDataFetch">
                {{ claimPaymentDetail.bankName }}
              </span>
            </li>
            <li>
              <span class="fieldName">
                Mode of Payment
              </span>
              <span class="fieldDataFetch">
                {{ claimPaymentDetail.modeofPayment }}
              </span>
            </li>
            <hr class="my-line">
          </ul>
        </div>
        <!-- <ul class="payment-details" *ngIf="isClaimPaymentDetailsLoaded && claimPaymentDetails !== null">
          <li>
            <span class="fieldName">
              <small>Payee Name</small>
              {{ claimPaymentDetails.payeename }}
            </span>
            <span class="fieldDataFetch">
              <strong
                ><i class="fas fa-rupee-sign"></i>
                {{ claimPaymentDetails.paidAmounts }}
              </strong>
            </span>
          </li>
          <li>
            <span class="fieldName">
              Txn. ID/ Cheque Number
            </span>
            <span class="fieldDataFetch">
              {{ claimPaymentDetails.transactionNumber }}
            </span>
          </li>
          <li>
            <span class="fieldName">
              Transaction Date
            </span>
            <span class="fieldDataFetch">
              {{ claimPaymentDetails.transactionDate }}
            </span>
          </li>
          <li>
            <span class="fieldName">
              Account Number
            </span>
            <span class="fieldDataFetch">
              {{ claimPaymentDetails.accountNumber }}
            </span>
          </li>
          <li>
            <span class="fieldName">
              Bank Name
            </span>
            <span class="fieldDataFetch">
              {{ claimPaymentDetails.bankName }}
            </span>
          </li>
          <li>
            <span class="fieldName">
              Mode of Payment
            </span>
            <span class="fieldDataFetch">
              {{ claimPaymentDetails.modeofPayment }}
            </span>
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</div>

<!-- upload document model -->
<div
  class="modal fade"
  id="intimateClaim_Popup"
  tabindex="-1"
  role="dialog"
  aria-labelledby="intimateClaim_Popup"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeButtonClicked()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center p-0">
              <!-- fieldsets -->
              <div class="card border-0">
                <div class="msform">
                  <div *ngIf="currentStep === 1">
                    <app-final-declaration
                      [name]="selectedClaim.insuredName"
                      [id]="selectedClaim.policyNo"
                      [finalDeclarationDone]="isFinalDeclared"
                      [uhid]="finalDeclarationData.uhid"
                      [age]="finalDeclarationData.age"
                      (isFinalDeclared)="getFinalDeclaration($event)"
                    >
                    </app-final-declaration>
                    <div class="spinner-border text-primary mr-3" *ngIf="submittingDeclaration === true">
                      <span class="sr-only">Submitting...</span>
                    </div>
                    <button
                      class="btn btn-primary next action-button"
                      [disabled]="isFinalDeclared === undefined || submittingDeclaration === true"
                      (click)="step1()"
                    >
                      Submit Declaration
                    </button>
                  </div>
                  <div *ngIf="currentStep === 2">
                    <div class="error-msg" *ngFor="let error of errorList">
                      <p *ngIf="error.fieldName !== ''">{{ error.fieldName }}</p>
                      <p *ngIf="error.fieldName === ''">{{ error.message }}</p>
                    </div>
                    <fieldset>
                      <div class="form-card">
                        <div class="rows">
                          <div class="col-12 text-center custom-heading">
                            <h5 class="fs-title text-center">{{ selectedClaim.claimType }} Claim</h5>
                            <!-- <p>We will reimburse the medical expenses.</p> -->
                          </div>
                        </div>
                        <div class="form-box" style="max-height: 400px;overflow-y: scroll;">
                          <h5 class="main-heading text-left">Upload the below documentation</h5>
                          <small class="text-left"
                            >Upload all available set of documents, individual file size should be less than 5mb,
                            overall file size should be less then 30mb</small
                          ><br />
                          <small class="text-left">Supported files: Jpg, PDF, Jpg, jpeg, PDF, png, doc, docx, xlsx</small>
                          <div name="auto-uploader" class="uplaoder-form">
                            <div class="spinner-border text-primary" *ngIf="!isClaimDocumentsLoaded">
                              <span class="sr-only">Loading...</span>
                            </div>

                            <div
                              *ngIf="isClaimDocumentsLoaded && claimDocuments.length === 0"
                              class="no-result-wrapper"
                            >
                              <h5>No Document to Upload</h5>
                            </div>

                            <div [hidden]="!isClaimDocumentsLoaded">
                              <div *ngFor="let doc of claimDocuments">
                                <div class="upload-documentation-wrapper mb-3 text-left">
                                  <div  class="pb-1">{{ doc.description }}</div>
                                  <!-- <div *ngIf="doc.isMandatory === true" class="pb-1">
                                    {{ doc.description }}<sup>*</sup>
                                  </div> -->

                                  <div class="upload-documentation-row">
                                    <div class="documentation-row-left">
                                      <button
                                        class="btn px-3 btn-sm mr-2 btn-primary"
                                        type="button"
                                        (click)="fileInput.click()"
                                      >
                                        <span>Upload</span>
                                        <input
                                          #fileInput
                                          type="file"
                                          (change)="fileSelectionEvent($event, doc, 'Upload')"
                                          style="display:none;"
                                          multiple
                                          accept=".xlsx,.jpg, .png, .doc, .docx,.pdf, .jpeg"
                                        />
                                      </button>
                                      <div [attr.id]="doc.documentId + 'delete'" style="display: none; width: 100%;">
                                        <div class="uploaded-file-wrap">
                                          <small [attr.id]="doc.documentId + 'para'" style="display: none;"></small>
                                          <button class="btn btn-sm p-0" (click)="deleteMultipleFile(doc, 'upload')">
                                            <span class="material-icons">delete</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="delete-file-wrapper">
                                      <div
                                        class="errorMsg"
                                        [attr.id]="doc.documentId + 'invalid'"
                                        style="display: none;"
                                      >
                                        {{ uploadImageInvalidMsg }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <div class="spinner-border text-primary mr-3" *ngIf="multipleDocumentsUploading === true">
                          <span class="sr-only">Submitting...</span>
                        </div>
                        <button
                          class="btn btn-secondary previous action-button-previous mr-3"
                          [hidden]="isDeclarationSubmitted || multipleDocumentsUploading === false"
                          [disabled]="multipleDocumentsUploading === true"
                          (click)="previousStep()"
                        >
                          Previous
                        </button>
                        <button
                          class="btn btn-primary next action-button"
                          [disabled]="
                            
                            imageInvalid ||
                            imageInvalid === undefined ||
                            multipleDocumentsUploading === true
                          "
                          (click)="step2()"
                        >
                          Upload Documents
                        </button>
                      </div>
                    </fieldset>
                  </div>
                  <div *ngIf="currentStep === 3">
                    <div class="form-card">
                      <div class="row">
                        <div class="col-12 text-center custom-heading">
                          <div class="form-group modal-body">
                            Documents uploaded successfully
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <button class="btn btn-primary next action-button" data-dismiss="modal">
                      Close
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- prepsot model-->
<div
  class="modal fade"
  id="prepostPopUp"
  tabindex="-1"
  role="dialog"
  aria-labelledby="prepostPopUp"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Claim Status <small>{{ claimType }}</small>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeButtonClicked()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="progress">
          <div
            class="progress-bar progress-bar-animated"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 20%;"
            [class.per_60]="preCurrentStep === 1"
            [class.per_100]="preCurrentStep === 2"
          ></div>
        </div>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center p-0">
              <!-- fieldsets -->
              <div class="card border-0">
                <div class="msform">
                  <div *ngIf="preCurrentStep === 1">
                    <div class="error-msg" *ngFor="let error of errorList">
                      <p *ngIf="error.fieldName !== ''">{{ error.fieldName }}</p>
                      <p *ngIf="error.fieldName === ''">{{ error.message }}</p>
                      <div class="errorMsg" *ngIf="errorOccuredWhileUploadingFile">
                        Error occurred while submitting {{ claimType }}.
                      </div>
                      <p *ngIf="error.message !== ''">{{ error.message }}</p>
                    </div>
                    <div class="accordion" id="accordionExample">
                      <div class="card">
                        <div class="card-header" id="headingOne">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Insured Details
                            </button>
                            <span class="btn btn-link text-end">
                              <i class="material-icons" data-toggle="collapse" data-target="#collapseOne">add</i>
                            </span>
                          </h2>
                        </div>

                        <div
                          id="collapseOne"
                          class="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.InsuredName"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.InsuredUHID"
                                  />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.InsuredEmailId"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" id="headingTwo">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Patient Details
                            </button>
                            <span class="btn btn-link text-end">
                              <i class="material-icons" data-toggle="collapse" data-target="#collapseTwo">add</i>
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          class="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.PatientName"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.PatientUHID"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.PatientMobileNo"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" id="headingThree">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Intimator Details
                            </button>
                            <span class="btn btn-link text-end">
                              <i class="material-icons" data-toggle="collapse" data-target="#collapseThree">add</i>
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          class="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.IntimatorName"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    [disabled]="enableBtn"
                                    name="mobileNumber"
                                    [(ngModel)]="intimateClaim.IntimatorMobileNo"
                                    [pattern]="mobNumberPattern"
                                    required
                                    #mobNumber="ngModel"
                                  />
                                  <div class="error-msg" *ngIf="mobNumber.errors" [ngClass]="'error'">
                                    <div class="error-msg" *ngIf="mobNumber.errors.required">
                                      Mobile number is required.
                                    </div>
                                    <div class="error-msg" *ngIf="mobNumber.errors.pattern">
                                      Mobile number not valid.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    [disabled]="enableBtn"
                                    name="primaryEmail"
                                    [(ngModel)]="intimateClaim.IntimatorEmailId"
                                    email
                                    #prmEmail="ngModel"
                                  />
                                </div>
                                <div class="error-msg" *ngIf="prmEmail.errors?.email">
                                  Email not valid.
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Claim Amount"
                                    [(ngModel)]="intimateClaim.ClaimedAmount"
                                    #prmAmount ="ngModel"
                                  />
                                </div>
                                <!-- <div *ngIf="claimType =='Addendum'">
                                  <div class="error-msg" *ngIf="prmAmount>intimateClaim.DisallowedAmount">
                                    Addendum can only be raised for the disallowed amount i.e., Rs. {{ intimateClaim.DisallowedAmount }}. For the rest of the amount if any please raise a pre-post with pending bills.
                                  </div>
                                </div> -->
                                
                              </div>
                            </div>
                            <div class="row">
                              <!-- <div class="col-md-12" *ngIf="intimateClaim.IntimationType === 'CL'"> -->
                                <div class="form-group d-flex">
                                  <label for="intimatorType"> Intimator Type </label>
                                  <div class="form-check" style="padding-left: 50px;">
                                    <label class="form-check-label" for="radio1">
                                      <input
                                        type="radio"
                                        class="form-check-input"
                                        id="radio1"
                                        name="intimator_type"
                                        value="intimateClaim.IntimationType"
                                        disabled="true"
                                      />Cashless
                                    </label>
                                  </div>
                                  <div class="form-check" style="padding-left: 50px;">
                                    <label class="form-check-label" for="radio2">
                                      <input
                                        type="radio"
                                        class="form-check-input"
                                        id="radio2"
                                        name="intimator_type"
                                        value="intimateClaim.IntimationType"
                                        disabled="true"
                                        checked
                                      />Reimbursement
                                    </label>
                                  </div>
                                </div>
                              <!-- </div> -->
                              <!-- <div class="col-md-12" *ngIf="intimateClaim.IntimationType === 'AL'">
                                <div class="form-group d-flex">
                                  <label for="intimatorType"> Intimator Type </label>
                                  <div class="form-check" style="padding-left: 50px;">
                                    <label class="form-check-label" for="radio1">
                                      <input
                                        type="radio"
                                        class="form-check-input"
                                        id="radio1"
                                        name="intimator_type"
                                        value="intimateClaim.IntimationType"
                                        checked
                                      />Cashless
                                    </label>
                                  </div>
                                  <div class="form-check" style="padding-left: 50px;">
                                    <label class="form-check-label" for="radio2">
                                      <input
                                        type="radio"
                                        class="form-check-input"
                                        id="radio2"
                                        name="intimator_type"
                                        value="intimateClaim.IntimationType"
                                      />Reimbursement
                                    </label>
                                  </div>
                                </div>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" id="headingFour">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Case Details
                            </button>
                            <span class="btn btn-link text-end">
                              <i class="material-icons" data-toggle="collapse" data-target="#collapseFour">add</i>
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseFour"
                          class="collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.DateOfAdmission"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.DoctorName"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.DiagnosisDetails"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" id="headingFive">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              Hospital Details
                            </button>
                            <span class="btn btn-link text-end">
                              <i class="material-icons" data-toggle="collapse" data-target="#collapseFive">add</i>
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseFive"
                          class="collapse"
                          aria-labelledby="headingFive"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.HospitalName"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.AddressLine1"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.AddressLine2"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.State"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.City"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled="true"
                                    [(ngModel)]="intimateClaim.Pincode"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <fieldset>
                      <div class="form-card">
                        <!-- <div class="rows">
                            <div class="col-12 text-center custom-heading">
                              <h5 class="fs-title text-center">{{ selectedClaim.claimType }} Claim</h5>
                            </div>
                          </div> -->
                        <div class="form-box">
                          <h5 class="main-heading text-left">Upload the below documentation</h5>
                          <small class="text-left"
                            >Upload all available set of documents, individual file size should be less than 5mb,
                            overall file size should be less then 30mb</small
                          ><br />
                          <small class="text-left">Supported files: Jpg, jpeg, PDF, png, doc, docx, xlsx</small>
                          <div name="auto-uploader" class="uplaoder-form">
                            <div class="spinner-border text-primary" *ngIf="!isClaimDocumentsLoaded">
                              <span class="sr-only">Loading...</span>
                            </div>

                            <div
                              *ngIf="isClaimDocumentsLoaded && claimDocuments.length === 0"
                              class="no-result-wrapper"
                            >
                              <h5>No Document to Upload</h5>
                            </div>

                            <div [hidden]="!isClaimDocumentsLoaded">
                              <div *ngFor="let doc of claimDocuments">
                                <div class="upload-documentation-wrapper mb-3 text-left">
                                  <div *ngIf="doc.isMandatory === false" class="pb-1">{{ doc.description }}</div>
                                  <div *ngIf="doc.isMandatory === true" class="pb-1">
                                    {{ doc.description }}<sup>*</sup>
                                  </div>

                                  <div class="upload-documentation-row">
                                    <div class="documentation-row-left">
                                      <button
                                        class="btn px-3 btn-sm mr-2 btn-primary"
                                        type="button"
                                        (click)="fileInput.click()"
                                      >
                                        <span>Upload</span>
                                        <input
                                          #fileInput
                                          type="file"
                                          (change)="fileSelectionEvent($event, doc, claimType)"
                                          style="display:none;"
                                          multiple
                                          accept=".xlsx,.jpg, .png, .doc, .docx,.pdf, .jpeg"
                                        />
                                      </button>
                                      <div [attr.id]="doc.documentId + 'Pdelete'" style="display: none; width: 100%;">
                                        <div class="uploaded-file-wrap">
                                          <small [attr.id]="doc.documentId + 'Ppara'" style="display: none;"></small>
                                          <button class="btn btn-sm p-0" (click)="deleteMultipleFile(doc, claimType)">
                                            <span class="material-icons">delete</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="delete-file-wrapper">
                                      <div
                                        class="errorMsg"
                                        [attr.id]="doc.documentId + 'Pinvalid'"
                                        style="display: none;"
                                      >
                                        {{ uploadImageInvalidMsg }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <div class="spinner-border text-primary mr-3" *ngIf="submittingDeclaration === true">
                          <span class="sr-only">Submitting...</span>
                        </div>
                        <button
                          class="btn btn-primary next action-button"
                          [disabled]="isClaimSubmitted"
                          (click)="onSubmit(claimType)"
                        >
                          Submit
                        </button>
                      </div>
                      <div class="successfulUploadMessage" *ngIf="errorOccuredWhileUploadingFile === false">
                        Your claim has been successfully intimated. claim No. {{ newClaimNo }} .Please use the same for
                        further reference.
                      </div>
                    </fieldset>
                  </div>

                  <div *ngIf="preCurrentStep === 2">
                    <fieldset>
                      <div class="form-card">
                        <div class="row">
                          <div class="col-12 text-center custom-heading">
                            <h2 class="fs-title text-center">
                              Hi <span>{{ intimateClaim.InsuredName }}</span>
                            </h2>
                            <p>The {{ claimType }} Claim has been initiated</p>
                          </div>
                        </div>
                        <div class="submit-box">
                          <h5>
                            Your claim has been successfully intimated. claim No. <span>{{ newClaimNo }}</span> .Please
                            use the same for further reference.
                          </h5>
                        </div>

                        <div class="health-plan">
                          <div class="row">
                            <div class="col-md-9">
                              <h5>Family Health Plan <i class="fa fa-heart"></i></h5>
                            </div>

                            <div class="col-md-3"></div>
                          </div>

                          <div class="row">
                            <div class="col-md-6 health-data">
                              <h5>Policy Number</h5>
                              <p>{{ currentPolicy.policyNumber }}</p>
                            </div>

                            <div class="col-md-6 health-data">
                              <h5>Patient Name</h5>
                              <p>{{ intimateClaim.PatientName }}</p>
                            </div>

                            <div class="col-md-6 health-data">
                              <h5>UHID</h5>
                              <p>{{ intimateClaim.PatientUHID }}</p>
                            </div>

                            <div class="col-md-6 health-data">
                              <h5>Hospital Name</h5>
                              <p>{{ intimateClaim.HospitalName }}</p>
                            </div>
                          </div>
                        </div>

                        <div class="reimbursement-track text-center">
                          <img src="assets/images/money.png" class="img-responsive" />
                          <h5>{{ claimType }} Claim Initiated</h5>
                          <p>Well keep you updated while you rest and recover</p>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
