import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  userRole: string;

  constructor(private backendService: BackendService, private authService: AuthService) {
    this.getMemberRole();
  }

  async getMemberRole(): Promise<any> {
    const id = this.authService.getUserId();
    return new Promise(async resolve => {
      const role = await this.backendService.get(`user/role/${id}`);
      this.userRole = role.roleType;
      resolve(role.roleType);
    });
  }

  async getMemberConsent(): Promise<any> {
    const id = this.authService.getUserId();
    return new Promise(async resolve => {
      const role = await this.backendService.get(`user/role/${id}`);
      resolve(role);
    });
  }
}
