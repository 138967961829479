import { Injectable } from '@angular/core';
import { PreEnrollmentPolicyModel } from '../../shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { BackendService } from '../../shared/services/backend.service';
import { PreEnrollmentPeriodModel } from '../../shared/models/preEnrollment/periods/preEnrollment-period.model';
import { PreEnrollmentMemberModel } from '../../shared/models/preEnrollment/members/preEnrollment-member.model';
import { BehaviorSubject } from 'rxjs';
import { SumInsuredModel } from '../../shared/models/preEnrollment/policies/SumInsuredModel';
import { PreEnrollmentAdditionSumOptionModel } from '../../shared/models/preEnrollment/policies/PreEnrollmentAdditionSumOptionModel';
import * as moment from 'moment';
import { ParentalSumInsured } from 'src/app/shared/models/preEnrollment/policies/Parental-sum-insured.model';
import { AllowedSumInsuredModel } from '../../shared/models/preEnrollment/policies/allowedSumInsuredModel';
import { PeGradeSiModel } from '../../shared/models/preEnrollment/policies/peGradeSiModel';

@Injectable({
  providedIn: 'root',
})
export class PreEnrollmentService {
  preenrollmentPeriods: BehaviorSubject<any[]>;
  constructor(private readonly backendService: BackendService) {
    this.preenrollmentPeriods = new BehaviorSubject<any[]>([]);
  }

  private url1 = 'corporate/';
  private url2 = '/preenrollment/policies/';

  // convertToPolicyApiData(policy: PreEnrollmentPolicyModel): PreEnrollmentPolicyModel {
  //   //const allowedSumInsured: number[] = [];
  //   // const allowedSumInsured = new allowedSumInsuredModel(null);
  //   // policy.sumsInsured.forEach(model => {
  //     //const sumInsuredValue =new  parseInt(model.value.toString().replace(/,/g, ''), 10);
  //   //   var sumInsuredValue = new peGradeSiModel();
  //   //   sumInsuredValue.grade = null;
  //   //   sumInsuredValue.sumInsured = parseInt(model.value.toString().replace(/,/g, ''), 10);
  //   //   allowedSumInsured.sumInsuredList.push(sumInsuredValue);
  //   // });
  //   // policy.allowedSumInsured = allowedSumInsured;
  //   // console.log('allowe si ', policy.allowedSumInsured);
  //   // return policy;
  // }

  mapToSumOptionModel(model: PreEnrollmentAdditionSumOptionModel) {
    const newModel = new PreEnrollmentAdditionSumOptionModel();
    newModel.isAllowed = model.isAllowed;
    newModel.instructions = model.instructions;
    newModel.plan = model.plan;
    newModel.type = model.type;
    newModel.values = model.values;

    return newModel;
  }

  convertToPolicyUIData(policy: PreEnrollmentPolicyModel): PreEnrollmentPolicyModel {
    policy.totalMembersInString = policy.totalMembers.toString();
    return policy;
  }

  async getPolicies(corporateId): Promise<PreEnrollmentPolicyModel[]> {
    return new Promise(async resolve => {
      const preEnrollmentResponse = await this.backendService.get(this.url1 + corporateId + this.url2);
      const preEnrollment: PreEnrollmentPolicyModel[] = [];
      preEnrollmentResponse.forEach(element => {
        preEnrollment.push(this.convertToPolicyUIData(element));
      });
      resolve(preEnrollment);
    });
  }

  public create(corporateId: number, policyData: PreEnrollmentPolicyModel): Promise<PreEnrollmentPolicyModel> {
    // const policy = this.convertToPolicyApiData(policyData);
    return new Promise<any>((resolve, reject) => {
      this.backendService.post(this.url1 + corporateId + this.url2, policyData).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async detachPreEnrollmentPolicy(policyId, corporateId): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      await this.backendService.delete(this.url1 + corporateId + this.url2 + policyId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public update(policyData: PreEnrollmentPolicyModel): Promise<PreEnrollmentPolicyModel> {
    const policy = policyData;
    const startdate = policy.startDate;
    const endDate = policy.endDate;
    const welcomeDate = policy.welcomeEmailDate;
    policy.startDate = moment(startdate).add(6, 'hours');
    policy.endDate = moment(endDate).add(6, 'hours');
    policy.welcomeEmailDate = moment(welcomeDate).add(6, 'hours');

    return new Promise<any>((resolve, reject) => {
      this.backendService.put(this.url1 + policy.corporateId + this.url2 + policy.id, policy).then(
        response => {
          return resolve(this.convertToPolicyUIData(response));
          // return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  // public createPolicyPeriod(policyId: number, period: PreEnrollmentPeriodModel): Promise<PreEnrollmentPeriodModel> {
  //     return new Promise(async resolve => {
  //         period.policyId = policyId;
  //         const preEnrollmentPeriod = this.backendService.post('preenrollment/' + policyId + '/preenrollmentperiod', period );
  //         resolve(preEnrollmentPeriod);
  //     });
  // }

  public async createPolicyPeriod(
    policyId: number,
    period: PreEnrollmentPeriodModel
  ): Promise<PreEnrollmentPeriodModel> {
    return await this.backendService.post('preenrollment/' + policyId + '/preenrollmentperiod', period);
  }

  public async getPeriods(policyId: number): Promise<PreEnrollmentPeriodModel[]> {
    return await this.backendService.get('preenrollment/' + policyId + '/preenrollmentperiod');
  }

  public async getMembers(policyId: number, skip: number, take: number): Promise<PreEnrollmentMemberModel[]> {
    return await this.backendService.get('preenrollment/' + policyId + '/preenrollmentmember/' + skip + '/' + take);
  }

  public async deleteMember(policyId: number, memberId: number): Promise<PreEnrollmentMemberModel[]> {
    return await this.backendService.delete('preenrollment/' + policyId + '/preenrollmentmember/' + memberId);
  }
  public async getTotalMembersCount(policyId: number): Promise<PreEnrollmentMemberModel[]> {
    return await this.backendService.get('preenrollment/' + policyId + '/totalMemberCount/');
  }
  public async searchMembers(policyId: number, empId: string): Promise<PreEnrollmentMemberModel[]> {
    return await this.backendService.get('preenrollment/' + policyId + '/preenrollmentmember/' + empId);
  }

  public async createMembers(policyId: number, formData): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.postFile('preenrollment/' + policyId + '/preenrollmentmember', formData).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async downloadMemberFile(corporateCode: string, policyId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.downloadFile('excel/' + corporateCode + '/' + policyId).then(
        fileBlob => {
          const blob = new Blob([fileBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          return resolve(blob);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async unlockMember(policyId: number, memberId): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.put('preenrollment/' + policyId + '/preenrollmentmember/' + memberId, {}).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }

  public async unlockAllMembers(policyId: number): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.put('preenrollment/' + policyId + '/preenrollmentmember/unlock/all', {}).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }

  public async updatePolicyPeriod(
    policyId: number,
    period: PreEnrollmentPeriodModel
  ): Promise<PreEnrollmentPeriodModel> {
    return await new Promise<PreEnrollmentPeriodModel>((resolve, reject) => {
      this.backendService.put('preenrollment/' + policyId + '/preenrollmentperiod/', period).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }
  public async addTopupExtraBenefits(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.post('policy/' + policyId + '/topup/extraBenefits', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }
  public async addESIExtraBenefits(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.post('policy/' + policyId + '/esi/extraBenefits', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }

  public async getESIExtraBenefits(policyId: number, relationship): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get('policy/' + policyId + '/' + relationship + '/esi/extraBenefits').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }
  public async getTopupExtraBenefits(policyId: number, relationship): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get('policy/' + policyId + '/' + relationship + '/topup/extraBenefits').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }
  public async updatePSIExtraBenefits(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.put('policy/' + policyId + '/psi/extraBenefits', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }
  public async addPSIExtraBenefits(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.post('policy/' + policyId + '/psi/extraBenefits', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }
  public async updateESIExtraBenefits(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.put('policy/' + policyId + '/esi/extraBenefits', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }

  public async getPSIExtraBenefits(policyId: number, relationship): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get('policy/' + policyId + '/' + relationship + '/psi/extraBenefits').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }
  public resetCredentialsByPolicyId(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('ResetAndSendPECredentials/policies/' + policyId + '/members').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
  public resetCredentialsByMemberId(policyId, memberId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('ResetAndSendPECredentials/policies/' + policyId + '/members/' + memberId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public getJobStatus(policyId, corporateid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('GetJobStatus/policies/' + policyId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async updateTopupExtraBenefits(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.put('policy/' + policyId + '/topup/extraBenefits', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error.error.errorResponses);
        }
      );
    });
  }

  public async sendWelcomeEmail(corporateid: number, policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService
        .putFormData('corporate/' + corporateid + '/preenrollment/policies/PeWelcomeEmail/' + policyId, data)
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public async sendReminderEmail(corporateid: number, policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService
        .putFormData('corporate/' + corporateid + '/preenrollment/policies/PeReminderEmail/' + policyId, data)
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error.error.errorResponses);
          }
        );
    });
  }

  public async sendConfirmationEmail(corporateid: number, policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService
        .putFormData('corporate/' + corporateid + '/preenrollment/policies/PeConfirmationEmail/' + policyId, data)
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error.error.errorResponses);
          }
        );
    });
  }

  public async updateGSTDetails(corporateId, policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService
        .putFormData(
          'corporate/' + corporateId + '/preenrollment/policies/PeGstPremiumDetails/policy/' + policyId,
          data
        )
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public async createPSIPremium(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.post('createPSIPremium/policy/' + policyId, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async createESIPremium(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.post('createESIPremium/policy/' + policyId, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async createTopupPremium(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.post('createTopupPremium/policy/' + policyId, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getPSIPremium(id: number): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get('getPsiPremium/' + id).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getESIPremium(id: number): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get('getEsiPremium/' + id).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getTopupPremium(id: number): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get('getTopupPremium/' + id).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async updatePSIPremium(extrabenefitId: number, policyid: number, id: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.put('updatePsiPremium/' + extrabenefitId + '/policy/' + policyid + '/' + id, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async updateESIPremium(extrabenefitId: number, policyid: number, id: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.put('updateEsiPremium/' + extrabenefitId + '/policy/' + policyid + '/' + id, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async updateTopupPremium(extrabenefitId: number, policyid: number, id: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.put('updateTopupPremium/' + extrabenefitId + '/policy/' + policyid + '/' + id, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async deletePremium(data): Promise<any> {
    return await new Promise<any>(async (resolve, reject) => {
      this.backendService.deleteData('delete/Premium', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getSumInsured(corporateId: number, id: number): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService
        .get('corporate/' + corporateId + '/preenrollment/policies/Pe/GetSumInsured/Self/policy/' + id)
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public async updateSumInsured(corporateId: number, policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService
        .put(
          'corporate/' + corporateId + '/preenrollment/policies/Pe/SaveAllowedSumInsured/Self/policy/' + policyId,
          data
        )
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public async getPremiumDetails(policyId: number, relationship): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get('getPremium/policy/' + policyId + '/' + relationship).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async updatePremiumDetails(policyId: number, relationship: string, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService
        .put('updateExtraBenefits/premium/policy/' + policyId + '/relationship/' + relationship, data)
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public async getWelcomeEmailDateTimeAlloccation(corporateId: number): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService
        .get('corporate/' + corporateId + '/preenrollment/policies/Pe/GetWelcomeEmail/DateTime/Allocation')
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public async deleteJobStatus(policyId, statusId): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      await this.backendService.delete('FileDownloaded/policies/' + policyId + '/status/' + statusId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async deleteBulkPremium(policyId, extraBenefitsId, extraBenefitType): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      await this.backendService
        .delete('bulkDelete/' + policyId + '/' + extraBenefitsId + '/' + extraBenefitType + '/Premium')
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public async deleteBulkExtrabenefit(policyId, extraBenefitsId, extraBenefitType): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      await this.backendService
        .delete('bulkDelete/' + policyId + '/' + extraBenefitsId + '/' + extraBenefitType + '/extraBenefits')
        .then(
          response => {
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  public async GetJobs(policyId: number): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get('GetJobs/by/Username/' + policyId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getSIextraBenefits(policyId: number, relationship): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get(`policy/${policyId}/${relationship}/SI/extraBenefits`).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async createSIextraBenefits(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.post(`policy/${policyId}/SI/extraBenefits`, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async updateSIextraBenefits(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.put(`policy/${policyId}/SI/extraBenefits`, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getSelfSIPremium(extraBenefitsId: number): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.get(`getSelfSIPremium/${extraBenefitsId}`).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async createSelfSIPremium(policyId: number, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.post(`createSelfSIPremium/policy/${policyId}`, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async updateSelfSIPremium(extraBenefitsId, policyId: number, id, data): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.backendService.put(`updateSelfSIPremium/${extraBenefitsId}/policy/${policyId}/${id}`, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
}
