import { Injectable } from '@angular/core';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { BackendService } from 'src/app/shared/services/backend.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationsCreatedByHr: Notification[] = [];
  notificationsCreatedByBroker: Notification[] = [];
  notificationsCreatedBySM: Notification[] = [];
  notificationsCreatedByBizadmin: Notification[] = [];

  constructor(private readonly backendService: BackendService) {}

  public async getNotificationsCreatedByAllByRole(role): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get(`CorporatePortal/Notifications/createdBy/all/${role}`).then(
        response => {
          this.notificationsCreatedByBizadmin = response;
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async createNotifications(data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.postFormData('CorporatePortal/Notifications/create', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getCorporates(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('Corporate/basicFields').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async deleteNotification(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.delete('CorporatePortal/Notifications/' + id).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async editNotification(id, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.putFormData('CorporatePortal/Notifications/' + id, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async approveNotification(id, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post('CorporatePortal/Notifications/Approve/' + id, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async rejectNotification(id, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post('CorporatePortal/Notifications/Reject/' + id, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
}
