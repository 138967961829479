import { Injectable } from '@angular/core';
import { CorporateModel } from '../../shared/models/corporate/corporate.model';
import { CorporateSummaryModel } from '../../shared/models/corporate/CorporateSummaryModel';
import { BackendService } from 'src/app/shared/services/backend.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CorporateService {
  // private existingCorporates = new BehaviorSubject<CorporateSummaryModel[]>(null);
  // existingCorporates$ = this.existingCorporates.asObservable();

  constructor(private readonly backendService: BackendService) {}

  // public async init() {
  //   const corporates = await this.backendService.get('corporate');
  //   this.existingCorporates.next(corporates);
  // }

  public async get_total_corporates(): Promise<any> {
    return new Promise(async resolve => {
      const count = await this.backendService.get('corporate/totalCount');
      resolve(count);
    });
  }

  public async get_corporates(skip, take): Promise<any> {
    return new Promise(async resolve => {
      const corporates = await this.backendService.get('corporate/' + skip + '/' + take);
      resolve(corporates);
    });
  }

  public async search_corporate(code): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.get('corporate/search/' + code).then(res => {
        resolve(res);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public get(id): Promise<CorporateModel> {
    return new Promise(async resolve => {
      const corporate = this.backendService.get('corporate/' + id);
      resolve(corporate);
    });
  }

  public create(corporate): Promise<CorporateModel> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.postFormData('corporate', corporate).then(
        response => {
          // this.existingCorporates.next(null);
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public update(id, bodyData: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.putFormData('corporate/' + id, bodyData).then(
        response => {
          // this.existingCorporates.next(null);
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async deleteCorporate(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
     await this.backendService.delete('corporate/' + id).then(
        response => {
          // this.existingCorporates.next(null);
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
}
