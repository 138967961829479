<div class="confirm-modal-wrapper">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Premium Breakup</h5>
      </div>
      <div class="modal-body">
        <ng-container class="py-2 px-3 mb-0">
          <div class="list-component-wrapper">
            <div class="container">
              <div class="list-component-body">
                <table>
                  <thead>
                    <tr>
                      <th>Insured Name</th>
                      <th>Relationship</th>
                      <th *ngIf="rship === 'Self'">SI Premium</th>
                      <th *ngIf="rship !== 'Self'">PSI Premium</th>
                      <th>ESI Premium</th>
                      <th>Topup Premium</th>
                      <th>Total Premium</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of selfDependentDetailsList">
                      <td>{{ item['Insured Name'] }}</td>
                      <td>{{ item.Relationship }}</td>

                      <td
                        *ngIf="
                          rship === 'Self' &&
                          (!(siPremiumType === 'Flat' && sumInsuredPlan === 'Floater') || memberCount === 1)
                        "
                      >
                        <p *ngIf="item.siPremiumModel && item.siPremiumModel !== 0 && !item.isSiPremiumVisible">
                          {{ item.siPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.siPremiumModel &&
                            item.siPremiumModel !== 0 &&
                            item.isSiPremiumVisible &&
                            premiumAmountFormula === 1
                          "
                          matTooltip="Net Premium = Premium Amount * Loading&#13;Net Premium = {{
                            item.siPremiumAmount
                          }} * {{
                            item.siLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.siGst
                          }} / 100) * {{
                            item.siPremiumAmount * item.siLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.siPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.siPremiumModel &&
                            item.siPremiumModel !== 0 &&
                            item.isSiPremiumVisible &&
                            premiumAmountFormula === 2
                          "
                          matTooltip="Date Difference = End Date - Date of Joining&#13;Net Premium = Premium Amt. * (Date Diff/365) * Loading&#13;Net Premium = {{
                            item.siPremiumAmount
                          }} * ({{ dateDiff }} / 365) * {{
                            item.siLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.siGst
                          }} / 100) * {{
                            item.siPremiumAmount * (dateDiff / 365) * item.siLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.siPremiumModel }}
                        </p>
                      </td>

                      <td
                        *ngIf="
                          rship === 'Self' &&
                          siPremiumType === 'Flat' &&
                          sumInsuredPlan === 'Floater' &&
                          item.Relationship === 'Self'
                        "
                        [attr.rowspan]="memberCount"
                      >
                        <p *ngIf="item.siPremiumModel && item.siPremiumModel !== 0 && !item.isSiPremiumVisible">
                          {{ item.siPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.siPremiumModel &&
                            item.siPremiumModel !== 0 &&
                            item.isSiPremiumVisible &&
                            premiumAmountFormula === 1
                          "
                          matTooltip="Net Premium = Premium Amount * Loading&#13;Net Premium = {{
                            item.siPremiumAmount
                          }} * {{
                            item.siLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.siGst
                          }} / 100) * {{
                            item.siPremiumAmount * item.siLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.siPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.siPremiumModel &&
                            item.siPremiumModel !== 0 &&
                            item.isSiPremiumVisible &&
                            premiumAmountFormula === 2
                          "
                          matTooltip="Date Difference = End Date - Date of Joining&#13;Net Premium = Premium Amt. * (Date Diff/365) * Loading&#13;Net Premium = {{
                            item.siPremiumAmount
                          }} * ({{ dateDiff }} / 365) * {{
                            item.psiLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.psiGst
                          }} / 100) * {{
                            item.psiPremiumAmount * (dateDiff / 365) * item.psiLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.siPremiumModel }}
                        </p>
                      </td>

                      <td
                        *ngIf="
                          rship !== 'Self' &&
                          (!(psiPremiumType === 'Flat' && sumInsuredPlan === 'Floater') || memberCount === 1)
                        "
                      >
                        <p *ngIf="item.psiPremiumModel && item.psiPremiumModel !== 0 && !item.isPsiPremiumVisible">
                          {{ item.psiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.psiPremiumModel &&
                            item.psiPremiumModel !== 0 &&
                            item.isPsiPremiumVisible &&
                            premiumAmountFormula === 1
                          "
                          matTooltip="Net Premium = Premium Amount * Loading&#13;Net Premium = {{
                            item.psiPremiumAmount
                          }} * {{
                            item.psiLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.psiGst
                          }} / 100) * {{
                            item.psiPremiumAmount * item.psiLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.psiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.psiPremiumModel &&
                            item.psiPremiumModel !== 0 &&
                            item.isPsiPremiumVisible &&
                            premiumAmountFormula === 2
                          "
                          matTooltip="Date Difference = End Date - Date of Joining&#13;Net Premium = Premium Amt. * (Date Diff/365) * Loading&#13;Net Premium = {{
                            item.psiPremiumAmount
                          }} * ({{ dateDiff }} / 365) * {{
                            item.psiLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.psiGst
                          }} / 100) * {{
                            item.psiPremiumAmount * (dateDiff / 365) * item.psiLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.psiPremiumModel }}
                        </p>
                      </td>

                      <td
                        *ngIf="
                          rship !== 'Self' &&
                          psiPremiumType === 'Flat' &&
                          sumInsuredPlan === 'Floater' &&
                          (item.relationshipId === 5 || item.relationshipId === 7) &&
                          memberCount !== 1
                        "
                        [attr.rowspan]="memberCount"
                      >
                        <p *ngIf="item.psiPremiumModel && item.psiPremiumModel !== 0 && !item.isPsiPremiumVisible">
                          {{ item.psiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.psiPremiumModel &&
                            item.psiPremiumModel !== 0 &&
                            item.isPsiPremiumVisible &&
                            premiumAmountFormula === 1
                          "
                          matTooltip="Net Premium = Premium Amount * Loading&#13;Net Premium = {{
                            item.psiPremiumAmount
                          }} * {{
                            item.psiLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.psiGst
                          }} / 100) * {{
                            item.psiPremiumAmount * item.psiLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.psiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.psiPremiumModel &&
                            item.psiPremiumModel !== 0 &&
                            item.isPsiPremiumVisible &&
                            premiumAmountFormula === 2
                          "
                          matTooltip="Date Difference = End Date - Date of Joining&#13;Net Premium = Premium Amt. * (Date Diff/365) * Loading&#13;Net Premium = {{
                            item.psiPremiumAmount
                          }} * ({{ dateDiff }} / 365) * {{
                            item.psiLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.psiGst
                          }} / 100) * {{
                            item.psiPremiumAmount * (dateDiff / 365) * item.psiLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.psiPremiumModel }}
                        </p>
                      </td>

                      <td *ngIf="!(esiPremiumType === 'Flat' && sumInsuredPlan === 'Floater') || memberCount === 1">
                        <p *ngIf="item.esiPremiumModel && item.esiPremiumModel !== 0 && !item.isEsiPremiumVisible">
                          {{ item.esiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.esiPremiumModel &&
                            item.esiPremiumModel !== 0 &&
                            item.isEsiPremiumVisible &&
                            premiumAmountFormula === 1
                          "
                          matTooltip="Net Premium = Premium Amount * Loading&#13;Net Premium = {{
                            item.esiPremiumAmount
                          }} * {{
                            item.esiLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.esiGst
                          }} / 100) * {{
                            item.esiPremiumAmount * item.esiLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.esiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.esiPremiumModel &&
                            item.esiPremiumModel !== 0 &&
                            item.isEsiPremiumVisible &&
                            premiumAmountFormula === 2
                          "
                          matTooltip="Date Difference = End Date - Date of Joining&#13;Net Premium = Premium Amt. * (Date Diff/365) * Loading&#13;Net Premium = {{
                            item.esiPremiumAmount
                          }} * ({{ dateDiff }} / 365) * {{
                            item.esiLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.esiGst
                          }} / 100) * {{
                            item.esiPremiumAmount * (dateDiff / 365) * item.esiLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.esiPremiumModel }}
                        </p>
                      </td>

                      <td
                        *ngIf="
                          esiPremiumType === 'Flat' &&
                          sumInsuredPlan === 'Floater' &&
                          (item.relationshipId === 5 || item.relationshipId === 7 || item.relationshipId === 1) &&
                          memberCount !== 1
                        "
                        [attr.rowspan]="memberCount"
                      >
                        <p *ngIf="item.esiPremiumModel && item.esiPremiumModel !== 0 && !item.isEsiPremiumVisible">
                          {{ item.esiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.esiPremiumModel &&
                            item.esiPremiumModel !== 0 &&
                            item.isEsiPremiumVisible &&
                            premiumAmountFormula === 1
                          "
                          matTooltip="Net Premium = Premium Amount * Loading&#13;Net Premium = {{
                            item.esiPremiumAmount
                          }} * {{
                            item.esiLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.esiGst
                          }} / 100) * {{
                            item.esiPremiumAmount * item.esiLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.esiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.esiPremiumModel &&
                            item.esiPremiumModel !== 0 &&
                            item.isEsiPremiumVisible &&
                            premiumAmountFormula === 2
                          "
                          matTooltip="Date Difference = End Date - Date of Joining&#13;Net Premium = Premium Amt. * (Date Diff/365) * Loading&#13;Net Premium = {{
                            item.esiPremiumAmount
                          }} * ({{ dateDiff }} / 365) * {{
                            item.esiLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.esiGst
                          }} / 100) * {{
                            item.esiPremiumAmount * (dateDiff / 365) * item.esiLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.esiPremiumModel }}
                        </p>
                      </td>

                      <td *ngIf="!(topupPremiumType === 'Flat' && sumInsuredPlan === 'Floater') || memberCount === 1">
                        <p
                          *ngIf="item.topupPremiumModel && item.topupPremiumModel !== 0 && !item.isTopupPremiumVisible"
                        >
                          {{ item.topupPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.topupPremiumModel &&
                            item.topupPremiumModel !== 0 &&
                            item.isTopupPremiumVisible &&
                            premiumAmountFormula === 1
                          "
                          matTooltip="Net Premium = Premium Amount * Loading&#13;Net Premium = {{
                            item.topupPremiumAmount
                          }} * {{
                            item.topupLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.topupGst
                          }} / 100) * {{
                            item.topupPremiumAmount * item.topupLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.topupPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.topupPremiumModel &&
                            item.topupPremiumModel !== 0 &&
                            item.isTopupPremiumVisible &&
                            premiumAmountFormula === 2
                          "
                          matTooltip="Date Difference = End Date - Date of Joining&#13;Net Premium = Premium Amt. * (Date Diff/365) * Loading&#13;Net Premium = {{
                            item.topupPremiumAmount
                          }} * ({{ dateDiff }} / 365) * {{
                            item.topupLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.topupGst
                          }} / 100) * {{
                            item.topupPremiumAmount * (dateDiff / 365) * item.topupLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.topupPremiumModel }}
                        </p>
                      </td>

                      <td
                        *ngIf="
                          topupPremiumType === 'Flat' &&
                          sumInsuredPlan === 'Floater' &&
                          (item.relationshipId === 5 || item.relationshipId === 7 || item.relationshipId === 1) &&
                          memberCount !== 1
                        "
                        [attr.rowspan]="memberCount"
                      >
                        <p
                          *ngIf="item.topupPremiumModel && item.topupPremiumModel !== 0 && !item.isTopupPremiumVisible"
                        >
                          {{ item.topupPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.topupPremiumModel &&
                            item.topupPremiumModel !== 0 &&
                            item.isTopupPremiumVisible &&
                            premiumAmountFormula === 1
                          "
                          matTooltip="Net Premium = Premium Amount * Loading&#13;Net Premium = {{
                            item.topupPremiumAmount
                          }} * {{
                            item.topupLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.topupGst
                          }} / 100) * {{
                            item.topupPremiumAmount * item.topupLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.topupPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            item.topupPremiumModel &&
                            item.topupPremiumModel !== 0 &&
                            item.isTopupPremiumVisible &&
                            premiumAmountFormula === 2
                          "
                          matTooltip="Date Difference = End Date - Date of Joining&#13;Net Premium = Premium Amt. * (Date Diff/365) * Loading&#13;Net Premium = {{
                            item.topupPremiumAmount
                          }} * ({{ dateDiff }} / 365) * {{
                            item.topupLoading
                          }}&#13;Premium with GST = (GST Percentage / 100) * Net Premium&#13;Premium with GST = ({{
                            item.topupGst
                          }} / 100) * {{
                            item.topupPremiumAmount * (dateDiff / 365) * item.topupLoading
                          }}&#13;Total Premium = Net Premium + Premium with GST"
                        >
                          {{ item.topupPremiumModel }}
                        </p>
                      </td>

                      <td>
                        <p
                          *ngIf="
                            rship === 'Self' && item.topupPremiumModel && item.esiPremiumModel && item.siPremiumModel
                          "
                        >
                          {{ item.topupPremiumModel + item.esiPremiumModel + item.siPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship === 'Self' && item.topupPremiumModel && item.esiPremiumModel && !item.siPremiumModel
                          "
                        >
                          {{ item.topupPremiumModel + item.esiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship === 'Self' && item.topupPremiumModel && !item.esiPremiumModel && item.siPremiumModel
                          "
                        >
                          {{ item.topupPremiumModel + item.siPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship === 'Self' && !item.topupPremiumModel && item.esiPremiumModel && item.siPremiumModel
                          "
                        >
                          {{ item.esiPremiumModel + item.siPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship === 'Self' && !item.topupPremiumModel && !item.esiPremiumModel && item.siPremiumModel
                          "
                        >
                          {{ item.siPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship === 'Self' && item.topupPremiumModel && !item.esiPremiumModel && !item.siPremiumModel
                          "
                        >
                          {{ item.topupPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship === 'Self' && !item.topupPremiumModel && item.esiPremiumModel && !item.siPremiumModel
                          "
                        >
                          {{ item.esiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship !== 'Self' && item.topupPremiumModel && item.esiPremiumModel && item.psiPremiumModel
                          "
                        >
                          {{ item.topupPremiumModel + item.esiPremiumModel + item.psiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship !== 'Self' && item.topupPremiumModel && item.esiPremiumModel && !item.psiPremiumModel
                          "
                        >
                          {{ item.topupPremiumModel + item.esiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship !== 'Self' && item.topupPremiumModel && !item.esiPremiumModel && item.psiPremiumModel
                          "
                        >
                          {{ item.topupPremiumModel + item.psiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship !== 'Self' && !item.topupPremiumModel && item.esiPremiumModel && item.psiPremiumModel
                          "
                        >
                          {{ item.esiPremiumModel + item.psiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship !== 'Self' && !item.topupPremiumModel && !item.esiPremiumModel && item.psiPremiumModel
                          "
                        >
                          {{ item.psiPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship !== 'Self' && item.topupPremiumModel && !item.esiPremiumModel && !item.psiPremiumModel
                          "
                        >
                          {{ item.topupPremiumModel }}
                        </p>
                        <p
                          *ngIf="
                            rship !== 'Self' && !item.topupPremiumModel && item.esiPremiumModel && !item.psiPremiumModel
                          "
                        >
                          {{ item.esiPremiumModel }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td><b>Total</b></td>
                      <td colspan="4">
                        <b>{{ amountInWords }}</b>
                      </td>
                      <td>₹ {{ totalPremium }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary px-4" (click)="onDismiss()">Close</button>
      </div>
    </div>
  </div>
</div>
