<div class="intimate-claim-wrapper pt-3 pb-5">
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-md-5 col-xs-12"
        *ngIf="(isHr && isHrView && role === 'CPHR') || role === 'Broker' || role === 'SM'"
      >
          <div class="card-body pb-0">
            <div class="card-title">
              <h4 class="m-0 header-title">View claim details under policy</h4>
            </div>
            <div class="claim-search-box">
              <div *ngIf="currentPolicy.intimateClaims">
                <app-policies-dropdown class="w-100" (onChange)="inValidateMembers($event)"></app-policies-dropdown>
                <div class="form-group w-100">
                  <label>Employee ID</label>
                  <input
                    class="form-control"
                    placeholder="Employee ID"
                    [(ngModel)]="empId"
                    (input)="setSearchByEmpId($event)"
                  />
                </div>
                <p *ngIf="invalidEmpId" class="error-msg">
                  <small>{{ invalidEmpIdMsg }}</small>
                </p>
                <div class="form-group w-100">
                  <label>Abha ID</label>
                  <input
                    class="form-control"
                    placeholder="Abha ID"
                    [(ngModel)]="abhaId"
                    (input)="setSearchByAbhaId($event)"
                  />
                </div>
                <p *ngIf="invalidAbhaId" class="error-msg">
                  <small>{{ invalidAbhaIdMsg }}</small>
                </p>
                <div class="form-group w-100">
                  <label>Abha Address</label>
                  <input
                    class="form-control"
                    placeholder="Abha Address"
                    [(ngModel)]="abhaAddress"
                    (input)="setSearchByAbhaAddress($event)"
                  />
                </div>
                <p *ngIf="invalidAbhaAddress" class="error-msg">
                  <small>{{ invalidAbhaAddressMsg }}</small>
                </p>

                <div class="form-action-wrapper">
                  <button
                    type="submit"
                    [disabled]="invalidEmpId || invalidAbhaId || invalidAbhaAddress || (empId == null && abhaId == null && abhaAddress == null)"
                    class="btn w-100 btn-primary rounded-0"
                    (click)="searchIntimateClaimsByEmpId()"
                  >
                    Search Details
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="col-md-5">
          <div
            *ngIf="currentPolicy.viewPolicyCoverage !== undefined && !currentPolicy.intimateClaims"
            class="no-result-wrapper"
          >
            <h5>This feature not Allowed for selected policy.</h5>
          </div>
          <!-- This div will be for member  -->
          <div
            class="card-body pb-0"
            *ngIf="
              (isMember && isEmployeeBelongsToPolicy && !isHrView) ||
              ((isHrView || role === 'Broker' || role === 'SM') && searchingMember)
            "
          >
            <div class="card-title">
              <h4 class="m-0 header-title">Insured Details</h4>
            </div>
            <div class="mt-3">
              <app-policies-dropdown
                [hidePolicyDropdown]="!(!isHrView && isMember)"
                heading="Insured Details"
                class="w-100"
                (onChange)="inValidateMembers($event)"
              >
              </app-policies-dropdown>
            </div>
            <div class="claims-cont">
              <ul class="list-unstyled shimmer-wrapper" *ngIf="!ismemberLoaded">
                <li class="shimmer-row">
                  <div class="image-shimmer"></div>
                  <div class="shimmer"></div>
                </li>
                <li class="shimmer-row">
                  <div class="image-shimmer"></div>
                  <div class="shimmer"></div>
                </li>
              </ul>

              <div *ngIf="ismemberLoaded && currentPolicy.intimateClaims">
                <div *ngIf="members.length == 0" class="no-result-wrapper">
                  <h5>No Data Found!</h5>
                  <div class="error-msg" *ngIf="error !== null">
                    <small>{{ error }}</small>
                  </div>
                </div>
                <ul class="inner-cards">
                  <li
                    data-toggle="modal"
                    data-target="#intimateClaim_Popup"
                    *ngFor="let dep of members"
                    (click)="callIntimateClaim(dep)"
                    matTooltip="Click here to Intimate claim."
                  >
                    <img
                      *ngIf="dep.relationShipId === 'SELF' && dep.sex === 'M'"
                      class="d-flex align-self-start rounded mr-3"
                      src="assets/images/husband.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationShipId === 'SELF' && dep.sex === 'F'"
                      class="d-flex align-self-start rounded mr-3"
                      src="assets/images/wife.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationShipId === 'DAUGHTER'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/daughter.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationShipId === 'SON'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/son.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationShipId === 'FATHER'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/father.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationShipId === 'MOTHER'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/mother.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationShipId === 'SPOUSE' && dep.sex === 'F'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/wife.png"
                      alt=""
                      height="64"
                    />
                    <img
                      *ngIf="dep.relationShipId === 'SPOUSE' && dep.sex === 'M'"
                      class="d-flex align-self-start mr-3"
                      src="assets/images/husband.png"
                      alt=""
                      height="64"
                    />

                    <span class="field-data"
                      >{{ dep.insuredName }}
                      <small
                        ><span>{{ dep.relationShipId }} | </span> {{ dep.age }} Years</small
                      ></span
                    >
                  </li>
                </ul>
                <!-- </div> -->
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- Intimate Claim Modal -->
  <div
    class="modal fade"
    id="intimateClaim_Popup"
    tabindex="-1"
    role="dialog"
    aria-labelledby="intimateClaim_Popup"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Intimate/Submit Claims<small></small></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-md-12 text-center p-0">
                <div class="card border-0">
                  <div class="msform">
                    <fieldset>
                      <div class="form-card">
                        <div class="row">
                          <div class="col-12 text-center custom-heading">
                            <h2 class="fs-title text-center">Intimate/Submit Claims</h2>
                            <p>Claiming is now quick &amp; effortless with us.</p>
                          </div>
                        </div>
                        <div class="form-box">
                          <h3 class="main-heading">What type of claim do you want to raise ?</h3>
                          <h4 class="text-center">Self-made claim</h4>

                          <div class="claim-type">
                            <div
                              class="custom-control custom-radio claim-box claim-bo-or"
                              data-toggle="modal"
                              data-target="#cashless_Popup"
                              data-dismiss="modal"
                              (click)="step1('Cashless IPD')"
                              *ngIf="policyType === 'IPD' || policyType === 'IPD/OPD'"
                            >
                              <div class="media media-boxer">
                                <img src="assets/images/credit-card.png" class="mr-3" alt="Sample Image" />
                                <div class="media-body">
                                  <h5 class="mt-0">Cashless (IPD)</h5>
                                  <p>
                                    Just seek admission in the nearest network hospital and we'll directly settle the
                                    bills with the hospital.
                                  </p>
                                </div>
                              </div>
                              <div class="or-div">
                                <p>OR</p>
                              </div>
                            </div>

                            <div
                              class="custom-control custom-radio claim-box"
                              data-toggle="modal"
                              data-target="#cashless_Popup"
                              data-dismiss="modal"
                              (click)="step1('Reimbursement IPD')"
                              *ngIf="policyType === 'IPD' || policyType === 'IPD/OPD'"
                            >
                              <div class="media media-boxer">
                                <img src="assets/images/money.png" class="mr-3" alt="Sample Image" />
                                <div class="media-body">
                                  <h5 class="mt-0">Reimbursement (IPD)</h5>
                                  <p>
                                    Get treated at a non-network hospital and we'll reimburse the medical expenses.
                                  </p>
                                </div>
                              </div>
                              <div class="or-div" *ngIf="policyType === 'IPD/OPD'">
                                <p>OR</p>
                              </div>
                            </div>

                            <div
                              class="custom-control custom-radio claim-box"
                              data-toggle="modal"
                              data-target="#cashless_Popup"
                              data-dismiss="modal"
                              (click)="step1('Reimbursement OPD')"
                              *ngIf="policyType === 'OPD' || policyType === 'IPD/OPD'"
                            >
                              <div class="media media-boxer">
                                <img src="assets/images/money.png" class="mr-3" alt="Sample Image" />
                                <div class="media-body">
                                  <h5 class="mt-0">Reimbursement OPD</h5>
                                  <p>
                                    Get treated and we'll reimburse the medical expenses.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="false" class="hospital-claim text-center">
                          <div class="row">
                            <div class="col-12 text-center custom-heading">
                              <img src="assets/images/health.png" alt="" />
                              <h2 class="py-2 text-center">Or you can also claim through hospital</h2>
                              <p>Get instant claim thorugh hospital in just 5 minutes</p>
                              <!-- <a href="#" class="text-primary">Learn how you can</a> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Cashless Modal -->
  <div
    class="modal fade"
    id="cashless_Popup"
    tabindex="-1"
    role="dialog"
    aria-labelledby="cashless_Popup"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <button
            [hidden]="currentStep === 6"
            class="back-icon"
            (click)="previousStep()"
            data-toggle="modal"
            data-target="#intimateClaim_Popup"
            data-dismiss="modal"
          >
            <span class="material-icons">keyboard_arrow_left</span>
          </button>
          <h5 class="modal-title">Intimate/Submit Claim</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="progress">
            <div
              class="progress-bar progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: 20%;"
              [class.per_20]="currentStep === 2"
              [class.per_40]="currentStep === 3"
              [class.per_60]="currentStep === 4 || currentStep === 4.5"
              [class.per_80]="currentStep === 5"
              [class.per_90]="currentStep === 6"
              [class.per_100]="currentStep === 7"
            ></div>
          </div>
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-md-12 text-center p-0">
                <!-- fieldsets -->
                <div class="card border-0">
                  <div class="msform">
                    <div *ngIf="currentStep === 2">
                      <fieldset>
                        <div class="form-card">
                          <div class="rows">
                            <div class="col-12 text-center custom-heading">
                              <h2 class="fs-title text-center">{{ claimType }} Claim</h2>
                            </div>
                          </div>

                          <div class="form-box">
                            <h3 class="main-heading text-left">What is the reason for claim*<sup>*</sup></h3>

                            <div class="form-group">
                              <label>Describe the reason for claim</label>
                              <textarea
                                class="form-control"
                                maxlength="250"
                                [(ngModel)]="intimateClaim.DiagnosisDetails"
                                (input)="causeOfHospitalization($event)"
                              ></textarea>
                              <div class="text-remain">
                                <span id="chars">{{ 250 - intimateClaim.DiagnosisDetails.length }}</span> characters
                                remaining
                              </div>
                            </div>
                            <div *ngIf="claimType === 'Reimbursement OPD'" class="form-group">
                              <label>Select claim category<sup>*</sup></label>
                              <select
                                ng-model="claimCategory"
                                class="form-control"
                                (change)="loadDefaultDocs($event.target.value)"
                                [(ngModel)]="claimCategory"
                              >
                                <option *ngFor="let category of claimCategoryList" value="{{ category.value }}">{{
                                  category.text
                                }}</option>
                              </select>
                            </div>
                            <div class="errorMsg" [hidden]="causeOfHospitalizationValid">
                              {{ invalidCauseMsg }}
                            </div>
                            <!-- <div class="errorMsg" [hidden]="claimCategoryValid">
                              {{ invalidCategoryMsg }}
                            </div> -->
                          </div>
                        </div>
                        <button
                          type="button"
                          class="next btn btn-primary action-button"
                          [disabled]="
                            !causeOfHospitalizationValid || (claimType === 'Reimbursement OPD' && claimCategory === '')
                          "
                          (click)="step2()"
                        >
                          Next
                        </button>
                      </fieldset>
                    </div>
                    <div *ngIf="currentStep === 3">
                      <fieldset class="scroll_class">
                        <div class="form-card ">
                          <div class="rows">
                            <div class="col-12 text-center custom-heading">
                              <h2 class="fs-title text-center">{{ claimType }} Claim</h2>
                            </div>
                          </div>
                          <div class="form-box">
                            <h3 class="main-heading text-left">Share details of the hospitalization</h3>

                            <div *ngIf="claimType === 'Reimbursement OPD'" class="form-group has-search">
                              <span class="fa fa-hospital form-control-feedback"></span>
                              <input
                                (blur)="searchByHospitalName()"
                                type="text"
                                class="form-control inputText"
                                placeholder="Search By hospital Name / Clinic / Diagnostic Center*"
                                (input)="HospitalNameEntered()"
                                [(ngModel)]="intimateClaim.HospitalName" style="padding-right: 30px;"
                              />
                              <button type="button" class="btn btn-sm remove-file-wrapper" (click)="searchByHospitalName()">
                                <i class="icon-img">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                </svg></i>
                              </button>
                            </div>
                            <div *ngIf="claimType != 'Reimbursement OPD'" class="form-group has-search">
                              <span class="fa fa-hospital form-control-feedback"></span>
                              <input
                                (blur)="searchByHospitalName()"
                                type="text"
                                class="form-control inputText"
                                placeholder="Search By hospital Name*"
                                (input)="HospitalNameEntered()"
                                [(ngModel)]="intimateClaim.HospitalName" style="padding-right: 30px;"
                              />
                              <button type="button" class="btn btn-sm remove-file-wrapper" (click)="searchByHospitalName()">
                                <i class="icon-img">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                </svg></i>
                              </button>
                            </div>
                            <div class="errorMsg" [hidden]="hospitalNameValid">
                              {{ invalidHospitalNameMsg }}
                            </div>

                            <div class="form-group has-search">
                              <span class="fa fa-user form-control-feedback"></span>
                              <input
                                type="text"
                                class="form-control inputText"
                                [(ngModel)]="intimateClaim.DoctorName"
                                placeholder="Doctor's Name*"
                                (input)="DoctorNameEntered()"
                              />
                            </div>
                            <div class="errorMsg" [hidden]="doctorNameValid">
                              {{ invalidDoctorNameMsg }}
                            </div>

                            <div *ngIf="claimType === 'Reimbursement OPD'" class="form-group has-search">
                              <span class="fa fa-hospital form-control-feedback"></span>
                              <input
                                value="{{ intimateClaim.AdmissionDate | date: 'dd MMMM yyyy' }}"
                                [(ngModel)]="intimateClaim.AdmissionDate"
                                (ngModelChange)="admissionDateChanged()"
                                placeholder="Date of Admission / Consultation / Test*"
                                type="text"
                                class="form-control"
                                readonly
                                bsDatepicker
                                required
                                [minDate]="startDate"
                                [maxDate]="endDate"
                                [bsConfig]="{
                                  isAnimated: true,
                                  dateInputFormat: 'DD MMMM YYYY',
                                  adaptivePosition: true,
                                  containerClass: 'theme-blue'
                                }"
                              />
                              <button
                                class="btn btn-sm p-0 delete-btn"
                                *ngIf="intimateClaim.AdmissionDate !== ''"
                                (click)="clearAdmissionDate()"
                              >
                                <span class="material-icons">delete</span>
                              </button>
                            </div>
                            <div *ngIf="claimType != 'Reimbursement OPD'" class="form-group has-search">
                              <span class="fa fa-hospital form-control-feedback"></span>
                              <input
                                value="{{ intimateClaim.AdmissionDate | date: 'dd MMMM yyyy' }}"
                                [(ngModel)]="intimateClaim.AdmissionDate"
                                (ngModelChange)="admissionDateChanged()"
                                placeholder="Date of Admission*"
                                type="text"
                                class="form-control"
                                readonly
                                bsDatepicker
                                required
                                [minDate]="startDate"
                                [maxDate]="endDate"
                                [bsConfig]="{
                                  isAnimated: true,
                                  dateInputFormat: 'DD MMMM YYYY',
                                  adaptivePosition: true,
                                  containerClass: 'theme-blue'
                                }"
                              />
                              <button
                                class="btn btn-sm p-0 delete-btn"
                                *ngIf="intimateClaim.AdmissionDate !== ''"
                                (click)="clearAdmissionDate()"
                              >
                                <span class="material-icons">delete</span>
                              </button>
                            </div>

                            <div class="form-group has-search" [hidden]="claimType === 'Reimbursement OPD'">
                              <span class="fa fa-hospital form-control-feedback"></span>
                              <input
                                value="{{ intimateClaim.DischargeDate | date: 'dd MMMM yyyy' }}"
                                [(ngModel)]="intimateClaim.DischargeDate"
                                placeholder="Date of Discharge"
                                type="text"
                                class="form-control"
                                readonly
                                required
                                bsDatepicker
                                [minDate]="intimateClaim.AdmissionDate"
                                [maxDate]="endDate"
                                [disabled]="intimateClaim.AdmissionDate === ''"
                                [bsConfig]="{
                                  isAnimated: true,
                                  dateInputFormat: 'DD MMMM YYYY',
                                  adaptivePosition: true,
                                  containerClass: 'theme-blue'
                                }"
                              />
                              <button
                                class="btn btn-sm p-0 delete-btn"
                                *ngIf="intimateClaim.DischargeDate !== ''"
                                (click)="clearDischargeDate()"
                              >
                                <span class="material-icons">delete</span>
                              </button>
                            </div>
                            <div class="form-group has-search">
                              <span class="fa fa-user form-control-feedback"></span>
                              <input
                                type="number"
                                class="form-control inputText"
                                [(ngModel)]="intimateClaim.ClaimAmount"
                                placeholder="Claim Amount*"
                                 #ClaimAmount
                              />
                            </div>
                            <div class="errorMsg" [hidden]="ClaimamountValid" 
                            *ngIf="ClaimamountValid || ClaimAmount.value <= 0">
                              Claim Amount should be greater than zero.
                            </div>

                            <div class="form-group has-search">
                              <span class="fa fa-user form-control-feedback"></span>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Abha ID"
                                [(ngModel)]="intimateClaim.AbhaId"
                                (input)="abhaIdEnteredForIntimation()"
                              />
                            </div>
                            <div class="errorMsg" *ngIf="abhaIdAvailable && !abhaIdValid">
                              {{ abhaIdInvalidMsg }}
                            </div>
                            <div class="form-group has-search">
                              <span class="fa fa-user form-control-feedback"></span>
                              <input
                                class="form-control"
                                placeholder="Abha Address"
                                [(ngModel)]="intimateClaim.AbhaAddress"
                                (input)="abhaAddressEnteredForIntimation()"
                              />
                            </div>
                            <div class="errorMsg" *ngIf="abhaAddressAvailable && !abhaAddressValid">
                              {{ abhaAddressInvalidMsg }}
                            </div>
                            <div class="form-group" *ngIf="!abhaIdAvailable">
                              <a href="https://www.reliancegeneral.co.in/insurance/abha/abhaccount.aspx" target="_blank"><u>Create your ABHA ID & ABHA Address</u></a>
                            </div>
                            <div class="network-shopitals-box">
                              <p *ngIf="hospitalSearchResults.length > 0 && isSearchDone">Network Hospitals</p>
                              <p class="text-warning" *ngIf="hospitalSearchResults.length === 0 && isSearchDone">
                                Hospital name is not found in our network hospitals
                              </p>
                              <div class="searchresult" *ngIf="hospitalSearchResults.length > 0 && isSearchDone">
                                <div
                                  class="col-md-12"
                                  *ngFor="let hospital of hospitalSearchResults"
                                  (click)="HospitalChosen(hospital)"
                                >
                                  <div
                                    class="claim-box"
                                    *ngIf="hospital.hospitalId === intimateClaim.HospitalId"
                                    style="color: black; background-color: lightblue;"
                                  >
                                    <div class="media">
                                      <img src="assets/images/health.png" class="mr-3" alt="Sample Image" />
                                      <div class="media-body">
                                        <h5 class="mt-0">{{ hospital.hospitalName }}</h5>
                                        <p>
                                          {{ hospital.address1 }}
                                          {{ hospital.address2 }}
                                          {{ hospital.city }}
                                          {{ hospital.district }}
                                          {{ hospital.state }}
                                          {{ hospital.pincode }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="claim-box" *ngIf="hospital.hospitalId !== intimateClaim.HospitalId">
                                    <div class="media">
                                      <img src="assets/images/health.png" class="mr-3" alt="Sample Image" />
                                      <div class="media-body">
                                        <h5 class="mt-0">{{ hospital.hospitalName }}</h5>
                                        <p>
                                          {{ hospital.address1 }}
                                          {{ hospital.address2 }}
                                          {{ hospital.city }}
                                          {{ hospital.district }}
                                          {{ hospital.state }}
                                          {{ hospital.pincode }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button class="btn btn-secondary previous action-button-previous mr-3" (click)="previousStep()">
                          Previous
                        </button>
                        <button
                          class="next btn btn-primary action-button"
                          [disabled]="
                            !hospitalNameValid ||
                            !doctorNameValid ||
                            intimateClaim.AdmissionDate === '' ||
                            !isHospitalChosen ||
                            (abhaIdAvailable && !abhaIdValid) ||
                            (abhaAddressAvailable && !abhaAddressValid)
                          "
                          (click)="step3()"
                        >
                          Next
                        </button>
                      </fieldset>
                    </div>

                    <div [hidden]="currentStep !== 4">
                      <fieldset>
                        <div class="form-card">
                          <div class="rows">
                            <div class="col-12 text-center custom-heading">
                              <h2 class="fs-title text-center">{{ claimType }} Claim</h2>
                            </div>
                          </div>
                          <div class="form-box scroll_class">
                            <h3 class="main-heading text-left">Upload the below documentation</h3>
                            <small class="text-left"
                              >Upload all available set of documents, individual file size should be less than
                              5mb, overall file size should be less then 30mb
                              </small
                            ><br />

                            <small class="text-left">Supported files: Jpg, jpeg, PDF, png, doc, docx, xlsx</small>
                            <div name="auto-uploader" class="uplaoder-form">
                              <div class="spinner-border text-primary" *ngIf="!isClaimDocumentsLoaded">
                                <span class="sr-only">Loading...</span>
                              </div>

                              <div
                                *ngIf="isClaimDocumentsLoaded && claimDocuments.length === 0"
                                class="no-result-wrapper"
                              >
                                <h5>No Document to Upload</h5>
                              </div>

                              <div [hidden]="!isClaimDocumentsLoaded">
                                <div *ngFor="let doc of claimDocuments">
                                  <div class="upload-documentation-wrapper">
                                    <div *ngIf="doc.isMandatory === false" class="pb-1">{{ doc.description }}</div>
                                    <div *ngIf="doc.isMandatory === true" class="pb-1">
                                      {{ doc.description }}<sup>*</sup>
                                    </div>
                                    <!-- <input
                                      type="file"
                                      (change)="fileSelectionEvent($event, doc)"
                                      class="form-control p-2"
                                      accept="image/*"
                                    /> -->
                                    <div class="upload-documentation-row">
                                      <div class="documentation-row-left">
                                        <button
                                          class="btn px-3 btn-sm mr-2 btn-primary"
                                          type="button"
                                          (click)="fileInput.click()"
                                        >
                                          <span>Upload</span>
                                          <input
                                            #fileInput
                                            type="file"
                                            (change)="fileSelectionEvent($event, doc)"
                                            style="display:none;"
                                            multiple
                                            accept=".xlsx,.jpg, .png, .doc, .docx,.pdf, .jpeg"
                                          />
                                        </button>
                                      </div>

                                      <div [attr.id]="doc.documentId + 'delete'" style="display: none; width: 100%;">
                                        <div class="uploaded-file-wrap">
                                          <small
                                            [attr.id]="doc.documentId + 'para'"
                                            style="display: none;"
                                            class="uploaded-file"
                                          ></small>
                                          <button class="btn btn-sm p-0" (click)="deleteFile(doc)">
                                            <span class="material-icons">delete</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="delete-file-wrapper">
                                      <div
                                        class="errorMsg"
                                        [attr.id]="doc.documentId + 'invalid'"
                                        style="display: none;"
                                      >
                                        {{ uploadImageInvalidMsg }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button class="btn btn-secondary previous action-button-previous mr-3" (click)="previousStep()">
                          Previous
                        </button>
                        <button
                          class="btn btn-primary next action-button"
                          [disabled]="intimateClaimDisableNextButton || imageInvalid"
                          (click)="step4()"
                        >
                          Initiate Claim
                        </button>
                      </fieldset>
                    </div>

                    <div *ngIf="currentStep === 4.5">
                      <fieldset>
                        <div class="form-card">
                          <div class="rows">
                            <div class="col-12 text-center custom-heading">
                              <h2 class="fs-title text-center">{{ claimType }} Claim</h2>
                            </div>
                          </div>
                          <div class="form-box scroll_class">
                            <h3 class="main-heading text-left">Uploaded Documents</h3>
                            <div *ngIf="intimateClaim.DocumentList.length ===0">
                              No documents uploaded
                            </div>
                            <div *ngFor="let doc of intimateClaim.DocumentList">
                              <div class="upload-documentation-wrapper">
                                <div class="pb-1">{{doc.Description}}</div>
                                <small>{{doc.FileName}}</small>
                              </div>
                               
                            </div>
                          </div>
                        </div>
                        <button class="btn btn-secondary previous action-button-previous mr-3" (click)="previousStep()">
                          Previous
                        </button>
                        <button
                          class="btn btn-primary next action-button"
                          (click)="step45()"
                        >
                          Next
                        </button>
                      </fieldset>
                    </div>

                    <div *ngIf="currentStep === 5">
                      <app-final-declaration
                        [name]="intimateClaim.InsuredName"
                        [age]="intimateClaim.Age"
                        [id]="intimateClaim.PolicyNumber"
                        [uhid]="intimateClaim.UHIDNumber"
                        [finalDeclarationDone]="isFinalDeclared"
                        (isFinalDeclared)="getFinalDeclaration($event)"
                      >
                      </app-final-declaration>
                      <button class="btn btn-secondary previous action-button-previous mr-3" (click)="previousStep()">
                        Previous
                      </button>
                      <button
                        class="btn btn-primary next action-button"
                        [disabled]="isFinalDeclared === undefined || isFinalDeclared === 'no'"
                        (click)="step5()"
                      >
                        Submit Declaration
                      </button>
                    </div>

                    <div *ngIf="currentStep === 6">
                      <div class="error-msg" *ngFor="let error of errorList">
                        <p *ngIf="error.fieldName !== ''">{{ error.fieldName }}</p>
                        <p *ngIf="error.fieldName === ''">{{ error.message }}</p>
                      </div>
                      <fieldset>
                        <div class="form-card">
                          <div class="rows">
                            <div class="col-12 text-center custom-heading">
                              <h2 class="fs-title text-center">{{ claimType }} Claim</h2>
                            </div>
                          </div>
                          <div class="contact-box">
                            <ul class="nav nav-tabs">
                              <li
                                class="nav-item text-center"
                                
                              >
                                <a
                                  href="#tab1"
                                  class="nav-link"
                                  [ngClass]="{ active: isIntimatorSelf }"
                                  data-toggle="tab"
                                  (click)="toggleIntimator(true)"
                                >
                                  <p><i class="fa fa-user-alt"></i></p>
                                  <h4>{{ intimatorName }} <small>(Self)</small></h4>
                                </a>
                              </li>
                              <li
                                class="nav-item text-center"
                                
                              >
                                <a
                                  href="#tab2"
                                  class="nav-link"
                                  [ngClass]="{ active: !isIntimatorSelf}"
                                  data-toggle="tab"
                                  (click)="toggleIntimator(false)"
                                >
                                  <p><i class="fa fa-user-alt"></i></p>
                                  <h4>Other</h4>
                                  <h4></h4>
                                </a>
                              </li>
                            </ul>
                            <div class="tab-content">
                              <div *ngIf="isIntimatorSelf">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Mobile Number*"
                                    (input)="mobileNumberEntered()"
                                    [(ngModel)]="intimateClaim.MobileNumber"
                                  />
                                </div>
                                <div class="errorMsg" [hidden]="mobileNumberValid">
                                  {{ invalidMobileNumberMsg }}
                                </div>

                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Email Address*"
                                    (input)="emailEntered()"
                                    [(ngModel)]="intimateClaim.EmailID"
                                  />
                                </div>
                                <div class="errorMsg" [hidden]="emailValid">
                                  {{ invalidEmailMsg }}
                                </div>
                              </div>
                              <div *ngIf="!isIntimatorSelf">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Full Name"
                                    (input)="intimatorNameEntered()"
                                    [(ngModel)]="intimateClaim.IntimatorName"
                                  />
                                </div>
                                <div class="errorMsg" [hidden]="intimatorNameValid">
                                  {{ invalidIntimatorNameMsg }}
                                </div>

                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Mobile Number*"
                                    (input)="intimatorMobileNumberEntered()"
                                    [(ngModel)]="intimateClaim.IntimatorMobileNo"
                                  />
                                </div>
                                <div class="errorMsg" [hidden]="intimatorMobileNumberValid">
                                  {{ invalidIntimatorMobileNumberMsg }}
                                </div>
                                <div class="form-group">
                                  <select 
                                    [(ngModel)] = "selectedIntimator"
                                    class="form-control" 
                                    (change)="checkIsSubmitClaimTrue()"
                                    >
                                    <option *ngFor="let member of allRelationships" [value]="member.relation">{{member.relation}}</option>
                                  </select>
                                </div>
                                <div class="errorMsg" [hidden]="!selectedIntimator">
                                  {{ invalidIntimatorRelationshipMessage }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="d-flex justify-content-center align-items-center">
                          <div class="spinner-border text-primary mr-3" *ngIf="isSubmitClaim === true">
                            <span class="sr-only">Submitting...</span>
                          </div>
                          <button
                            class="btn btn-secondary previous action-button-previous mr-3"
                            [disabled]="isSubmitClaim === true"
                            (click)="previousStepBeforeSubmit()"
                          >
                            Previous
                          </button>
                          <button
                            class="btn btn-primary next action-button"
                            *ngIf="isSubmitClaim === false"
                            [disabled]="submitClaimDetails === false"
                            (click)="step6()"
                          >
                            Submit Claim
                          </button>                    
                        </div>
                      </fieldset>
                    </div>

                    <div *ngIf="currentStep === 7">
                      <fieldset>
                        <div class="form-box scroll_class">
                        <div class="form-card">
                          <div class="row">
                            <div class="col-12 text-center custom-heading">
                              <h2 class="fs-title text-center">
                                Hi <span>{{ intimateClaim.InsuredName }}</span>
                              </h2>
                              <p *ngIf="!isClaimNumberOPD && !isClaimNumberBlank">The Claim has been initiated</p>
                              <p *ngIf="isClaimNumberOPD">Thanks for submitting your documents, an acknowledgement would be generated within 24 hours.</p>
                              <p *ngIf="isClaimNumberBlank">Claim already register on claim UHID and date of loss.</p>
                            </div>
                          </div>
                          <div class="submit-box" *ngIf="!isClaimNumberOPD && !isClaimNumberBlank">
                            <h5>
                              Your claim initiation no is <span>{{ submitClaimData.claimNumber }}</span>
                            </h5>
                          </div>

                          <div class="health-plan">
                            <div class="row">
                              <div class="col-md-9">
                                <h5>Family Health Plan <i class="fa fa-heart"></i></h5>
                              </div>

                              <div class="col-md-3"></div>
                            </div>

                            <div class="row">
                              <div class="col-md-6 health-data">
                                <h5>Policy Number</h5>
                                <p>{{ currentPolicy.policyNumber }}</p>
                              </div>

                              <div class="col-md-6 health-data">
                                <h5>Patient Name</h5>
                                <p>{{ intimateClaim.PatientName }}</p>
                              </div>

                              <div class="col-md-6 health-data">
                                <h5>UHID</h5>
                                <p>{{ intimateClaim.PatientUHID }}</p>
                              </div>

                              <div class="col-md-6 health-data">
                                <h5>Age</h5>
                                <p>{{ intimateClaim.Age }}</p>
                              </div>

                              <div class="col-md-6 health-data">
                                <h5>Date of Admission</h5>
                                <p>{{ intimateClaim.AdmissionDate | date: 'dd MMMM yyyy' }}</p>
                              </div>

                              <div class="col-md-6 health-data">
                                <h5>Hospital Name</h5>
                                <p>{{ intimateClaim.HospitalName }}</p>
                              </div>
                            </div>
                          </div>

                          <div class="reimbursement-track text-center">
                            <img src="assets/images/money.png" class="img-responsive" />
                            <h5>{{ claimType }} Claim Initiated</h5>
                            <p>Well keep you updated while you rest and recover</p>

                            <div *ngIf="!isClaimJourneyLoaded">
                              Loading...
                            </div>
                            <div class="step-bars" *ngIf="isClaimJourneyLoaded">
                              <ol class="steps">
                                <li
                                  *ngIf="claimJourney.s1 !== null && claimJourney.s1.isStageCompleted"
                                  class="step is-complete"
                                  data-step="1"
                                >
                                  {{ claimJourney.s1.headerText }}
                                </li>
                                <li
                                  *ngIf="claimJourney.s2 !== null && claimJourney.s2.isStageCompleted"
                                  class="step is-complete"
                                  data-step="1"
                                >
                                  {{ claimJourney.s2.headerText }}
                                </li>
                                <li
                                  *ngIf="claimJourney.s3 !== null && claimJourney.s3.isStageCompleted"
                                  class="step is-complete"
                                  data-step="1"
                                >
                                  {{ claimJourney.s3.headerText }}
                                </li>
                                <li
                                  *ngIf="claimJourney.s4 !== null && claimJourney.s4.isStageCompleted"
                                  class="step is-complete"
                                  data-step="1"
                                >
                                  {{ claimJourney.s4.headerText }}
                                </li>
                                <li
                                  *ngIf="claimJourney.s5 !== null && claimJourney.s5.isStageCompleted"
                                  class="step is-complete"
                                  data-step="1"
                                >
                                  {{ claimJourney.s5.headerText }}
                                </li>
                                <li
                                  *ngIf="claimJourney.s6 !== null && claimJourney.s6.isStageCompleted"
                                  class="step is-complete"
                                  data-step="1"
                                >
                                  {{ claimJourney.s6.headerText }}
                                </li>
                                <li
                                  *ngIf="claimJourney.s7 !== null && claimJourney.s7.isStageCompleted"
                                  class="step is-complete"
                                  data-step="1"
                                >
                                  {{ claimJourney.s7.headerText }}
                                </li>
                                <li
                                  *ngIf="claimJourney.s8 !== null && claimJourney.s8.isStageCompleted"
                                  class="step is-complete"
                                  data-step="1"
                                >
                                  {{ claimJourney.s8.headerText }}
                                </li>
                                <li
                                  *ngIf="claimJourney.s9 !== null && claimJourney.s9.isStageCompleted"
                                  class="step is-complete"
                                  data-step="1"
                                >
                                  {{ claimJourney.s9.headerText }}
                                </li>
                                <li
                                  *ngIf="claimJourney.s10 !== null && claimJourney.s10.isStageCompleted"
                                  class="step is-complete"
                                  data-step="2"
                                >
                                  {{ claimJourney.s10.headerText }}
                                </li>
                                <!-- <li class="step is-active" data-step="3">
                                  Approved
                                </li> -->
                              </ol>
                            </div>
                          </div>
                          <div class="btn-div text-center" *ngIf="currentPolicy.trackClaims">
                            <a [routerLink]="'/cp-member/track-claims'"
                              ><button class="btn btn-primary text-center" data-dismiss="modal" aria-label="Close">
                                Track My Claim
                              </button></a
                            >
                          </div>
                        </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
