import { Component, OnInit, Injector, Input } from '@angular/core';
import { NotificationService } from 'src/app/business-admin/services/notification.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EditNotificationComponent } from 'src/app/corporate-portal/components/notifications/edit-notification/edit-notification.component';
import { Roles } from 'src/app/shared/enums/roles.enum';

@Component({
  selector: 'app-biz-admin-notification',
  templateUrl: './biz-admin-notification.component.html',
  styleUrls: ['./biz-admin-notification.component.scss'],
})
export class BizAdminNotificationComponent extends BaseComponent implements OnInit {
  @Input() searchText: string;
  notifications: Notification[] = [];
  isNotificationsLoaded = false;
  isLoading: boolean;
  page = 1;
  pageSize = 5;
  notificationProperty = 'notificationProp';
  modalOption: NgbModalOptions = {};
  constructor(
    injector: Injector,
    private readonly notificationService: NotificationService,
    private readonly alertService: AlertService,
    private modalService: NgbModal
  ) {
    super(injector);
  }
  async ngOnInit() {
    super.ngOnInit();
    this.getNotification();
  }

  changePage() {
    this.page = 1;
  }

  getNotification() {
    this.isNotificationsLoaded = false;
    this.notificationService.getNotificationsCreatedByAllByRole(Roles.BuisnessAdmin).then(x => {
      this.notifications = x;
      this.isNotificationsLoaded = true;
    });
  }

  editNotification(notification) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(EditNotificationComponent, this.modalOption);
    modalRef.componentInstance.notification = notification;
  }

  async deleteNotification(id) {
    let result;
    const message = `Are you sure you want to delete?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.isLoading = true;
          await this.notificationService
            .deleteNotification(id)
            .then(async x => {
              this.alertService.success('Notification deleted successfully');
              this.getNotification();
            })
            .catch(error => {
              this.alertService.success('Error Occured');
            });
          this.isLoading = false;
        } catch (error) {
          this.alertService.error('Error occured');
          this.isLoading = false;
        }
      }
    });
  }
}
