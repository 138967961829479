import { Component, OnInit, Input, OnDestroy, Injector, SimpleChanges, OnChanges } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { MasterService } from 'src/app/business-admin/services/master.service';
import { PreEnrollmentService } from 'src/app/business-admin/services/preEnrollment.service';
import { ConfirmationEmail } from 'src/app/shared/models/preEnrollment/email/confirmation-email.model';

@Component({
  selector: 'app-configure-email',
  templateUrl: './configure-email.component.html',
  styleUrls: ['./configure-email.component.scss'],
})
export class ConfigureEmailComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  form: FormGroup;
  @Input() corporate: CorporateModel;
  @Input() policy: PreEnrollmentPolicyModel;
  editMode = false;

  initialized = false;

  errorR: any;

  isSaveButtonEnable = true;

  isCcEmailValid: boolean;
  isBccEmailValid: boolean;
  invalidEmailBccMsg: string;
  dynamicArray: any;

  isConfirmationEmailValid: boolean;
  emailSubjectLength: boolean;
  subjectErrorMsg: string;
  confirmationEmailSubjectLength = true;
  confirmationSubjectErrorMsg = '';

  displaySave: string;
  confirmationEmailData: ConfirmationEmail = new ConfirmationEmail();

  hrsOptions: number[] = [];
  minsOptions: number[] = [];
  fileName: string;
  file: File = null;
  isSizeValid: boolean;
  uploadImageInvalidMsg = '';

  constructor(
    injector: Injector,
    private readonly masterService: MasterService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly preenrollmentService: PreEnrollmentService,
    private fb: FormBuilder
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.createForm();
    super.ngOnInit();

    if (!this.policy) {
      this.displaySave = 'Save';
      const templates = await this.masterService.getEmailTemplate();
      this.confirmationEmailData.registrationConfirmationEmailTemplate = templates.confirmationEmailTemplate;
    } else {
      this.displaySave = 'Save Changes';
      this.confirmationEmailData.sendRegistrationConfirmationEmail = this.policy.sendRegistrationConfirmationEmail;
      this.confirmationEmailData.registrationConfirmationEmailBccs = this.policy.registrationConfirmationEmailBccs;
      this.confirmationEmailData.registrationConfirmationEmailCcs = this.policy.registrationConfirmationEmailCcs;
      this.confirmationEmailData.registrationConfirmationEmailSubject = this.policy.registrationConfirmationEmailSubject;
      this.confirmationEmailData.registrationConfirmationEmailTemplate = this.policy.registrationConfirmationEmailTemplate;
      this.fileName = this.policy.confirmationEmailFileName;
      this.confirmationEmailData.confirmationEmailFileName = this.policy.confirmationEmailFileName;
      if (this.fileName) {
        this.confirmationEmailData.registrationConfirmationEmailFile = this.policy.confirmationEmailFile;
        this.file = new File(['foo'], 'foo.txt');
      }
      this.editMode = true;
    }

    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['policy'] !== undefined && changes['corporate'] === undefined) {
      this.isBccEmailValid = true;
      this.isCcEmailValid = true;
      this.confirmationEmailData = new ConfirmationEmail();

      this.ngOnInit();
    }
  }
  createForm() {
    this.form = this.fb.group({
      message: ['', [Validators.pattern('^(?! )[A-Za-z0-9.,{}() ]*(?<! )$')]],
    });
  }

  isConfirmationSubjectValid(event) {
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.confirmationEmailSubjectLength = false;
      this.confirmationSubjectErrorMsg = 'Subject cannot be empty!!!';
    } else if (fieldValue.length > 254) {
      this.confirmationEmailSubjectLength = false;
      this.confirmationSubjectErrorMsg = 'Max length of 254 exceeded';
    } else {
      this.confirmationEmailSubjectLength = true;
      this.confirmationSubjectErrorMsg = '';
    }
    this.checkSaveButtonDisplay();
  }

  onFileChange(file) {
    if (file.length > 0) {
      this.file = file[0];
      const name = file[0].name;
      const size = file[0].size;
      this.confirmationEmailData.registrationConfirmationEmailFile = null;
      this.fileName = name;
      this.confirmationEmailData.confirmationEmailFileName = this.fileName;
      if (this.fileName.length > 30) {
        this.fileName = this.fileName.slice(0, 25) + '...';
      }
      if (size > 5000000) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Maximum size must be 5 Mb';
      } else {
        this.isSizeValid = true;
        this.uploadImageInvalidMsg = '';
      }
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const fileExtensionArr = ['jpg', 'jpeg', 'png', 'xlsx', 'doc', 'docx', 'pdf', 'rar', 'zip'];
      if (!fileExtensionArr.includes(ext.toLowerCase())) {
        this.isSizeValid = false;
        this.uploadImageInvalidMsg = 'Upload valid format file';
      }
    }
  }

  downloadFile() {
    const link = document.createElement('a');
    link.download = this.fileName;
    if (this.confirmationEmailData.registrationConfirmationEmailFile == null) {
      link.href = URL.createObjectURL(this.file);
    } else {
      link.href = this.confirmationEmailData.registrationConfirmationEmailFile;
    }

    link.click();
  }

  deleteFile() {
    (<HTMLInputElement>document.getElementById('file')).value = '';
    this.file = null;
    this.isSizeValid = true;
    this.uploadImageInvalidMsg = '';
    this.fileName = '';
    this.confirmationEmailData.registrationConfirmationEmailFile = null;
    this.confirmationEmailData.confirmationEmailFileName = null;
  }

  async submit() {
    if (this.confirmationEmailData.registrationConfirmationEmailFile) {
      this.file = null;
    }
    const stringJson = JSON.stringify(this.confirmationEmailData);
    const data = new FormData();
    data.append('File', this.file);
    data.append('Json', stringJson);

    await this.preenrollmentService
      .sendConfirmationEmail(this.corporate.id, this.policy.id, data)
      .then(x => {
        this.alertService.success('Confirmation Email Saved Successfully');
        this.router.navigate(['/biz-admin/corporate/' + this.corporate.id]);
      })
      .catch(errorData => {
        this.errorR = errorData.error.errors;
        this.alertService.error('Error occured');
      });
  }

  completeConfigureEmailsStep() {
    const cc = this.policy.registrationConfirmationEmailCcs;
    const bcc = this.policy.registrationConfirmationEmailBccs;

    if (this.policy.sendRegistrationConfirmationEmail) {
      this.isConfirmationEmailValid = this.policy.registrationConfirmationEmailSubject ? true : false;
    } else {
      this.isConfirmationEmailValid = true;
    }

    if (cc !== null) {
      if (cc.trim().length !== 0) {
        if (cc.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i)) {
          this.isCcEmailValid = true;
        } else {
          this.isCcEmailValid = false;
          this.invalidEmailBccMsg = 'Enter valid email';
        }
      } else {
        this.isCcEmailValid = true;
      }
    } else {
      this.isCcEmailValid = true;
    }
    if (bcc !== null) {
      if (bcc.trim().length !== 0) {
        if (bcc.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i)) {
          this.isBccEmailValid = true;
        } else {
          this.isBccEmailValid = false;
          this.invalidEmailBccMsg = 'Enter valid email';
        }
      } else {
        this.isBccEmailValid = true;
      }
    } else {
      this.isBccEmailValid = true;
    }
    return this.isCcEmailValid && this.isBccEmailValid && this.isConfirmationEmailValid;
  }

  checkSaveButtonDisplay() {
    if (this.confirmationEmailData.sendRegistrationConfirmationEmail) {
      this.isSaveButtonEnable =
        this.confirmationEmailSubjectLength &&
        this.confirmationEmailData.registrationConfirmationEmailTemplate !== null &&
        this.confirmationEmailData.registrationConfirmationEmailTemplate !== undefined;
    } else {
      this.isSaveButtonEnable = true;
    }
  }

  sendConfirmationEmail() {
    if (!this.confirmationEmailData.sendRegistrationConfirmationEmail) {
      this.confirmationEmailSubjectLength = true;
    } else {
      if (this.confirmationEmailData.registrationConfirmationEmailSubject.length === 0) {
        this.confirmationEmailSubjectLength = false;
        this.confirmationSubjectErrorMsg = 'Subject cannot be empty!!!';
      } else if (this.confirmationEmailData.registrationConfirmationEmailSubject.length > 254) {
        this.confirmationEmailSubjectLength = false;
        this.confirmationSubjectErrorMsg = 'Max length of 254 exceeded';
      } else {
        this.confirmationEmailSubjectLength = true;
        this.confirmationSubjectErrorMsg = '';
      }
    }

    this.checkSaveButtonDisplay();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
