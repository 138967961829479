import { PeExtraBenefits } from './pe-extra-benefits.model';
import { PeSelfModel } from './pe-self-model.model';

export class SiExtrabenefits {
  peExtraBenefits: PeExtraBenefits = new PeExtraBenefits();
  peSelfModels: PeSelfModel[] = [];
  constructor(model) {
    Object.assign(this, model);
  }
}
