<div class="periods-component">
  <div class="period-component-header">
    <h3 class="row-title mb-0">Pre-Enrollment Periods</h3>
  </div>

  <div class="table-grid">
    <table>
      <thead>
        <tr>
          <th>Enrollment Start Date</th>
          <th>Enrollment End Date</th>
          <th>Status</th>
          <th>Edit</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let period of periods">
          <td>
            <div>{{ moment(period.startDate).format('DD MMMM YYYY') }}</div>
          </td>
          <td>
            <div>{{ moment(period.endDate).format('DD MMMM YYYY') }}</div>
          </td>
          <td>
            <div *ngIf="period.status === 1"><span class="badge badge-active">Active</span></div>
            <div *ngIf="period.status !== 1"><span class="badge badge-expired">Not Active</span></div>
          </td>
          <td>
            <button class="edit-new-row-btn" data-toggle="modal" data-target="#editPeriod" (click)="editPeriod(period)">
              <i class=" material-icons">edit</i>
            </button>
          </td>
          <td>
            <div class="position-relative" [hidden]="true">
              <button
                type="button"
                class="btn btn-link float-right dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="material-icons">more_vert</i>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item">Edit</a>
                <a class="dropdown-item" [hidden]="period.isActive">Delete</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <button class="add-new-row-btn" data-toggle="modal" data-target="#addPeriod">
    <i class="material-icons">add</i><span>Add new</span>
  </button>
</div>

<!---Edit Period Modal -->
<div
  class="modal fade"
  id="editPeriod"
  tabindex="-1"
  role="dialog"
  aria-labelledby="editPeriod"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Edit Pre-Enrollment Period</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-4">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Edit End Date</label>
              <input
                type="text"
                [minDate]="today"
                [(ngModel)]="editDateModel"
                class="form-control"
                readonly
                bsDatepicker
                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
              />
              <!-- <div class="errorMsg" [hidden]="!periodStartDateInvalid">
                The start date should be set to a future date
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link text-secondary" #closebuttonedit data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="addingPeriod" (click)="saveUpdatedPeriod()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add Period Modal -->
<div
  class="modal fade"
  id="addPeriod"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addPeriod"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add New Period</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-4">
        <div class="row">
          <div class="error-msg" *ngFor="let error of errorR">
            <p>{{ error.fieldName }} : {{ error.message }}</p>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Start Date</label>
              <input
                value="{{ newPeriod.startDate | date: 'dd MMMM yyyy' }}"
                [(ngModel)]="newPeriod.startDate"
                (ngModelChange)="startDateChanged()"
                type="text"
                [minDate]="today"
                class="form-control"
                readonly
                bsDatepicker
                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
              />
              <div class="errorMsg" [hidden]="!periodStartDateInvalid">
                The start date should be set to a future date
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>End Date</label>
              <input
                type="text"
                value="{{ newPeriod.endDate | date: 'dd MMMM yyyy' }}"
                [(ngModel)]="newPeriod.endDate"
                [minDate]="newPeriod.startDate"
                class="form-control"
                readonly
                bsDatepicker
                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
              />
              <div class="errorMsg" [hidden]="!periodEndDateInvalid">
                The end date should be set to a date equal or greater than start date
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link text-secondary" #closebutton data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="addingPeriod" (click)="save()">
          {{ addingPeriod ? 'Adding' : 'Add' }}
        </button>
      </div>
    </div>
  </div>
</div>
