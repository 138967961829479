<div class="faq-wrapper">
  <!-- end row -->
  <div class="row">
    <div class="col-lg-6">
      <div class="card p-0 border-0">
        <div class="card-body p-0">
          <div class="accordion" id="accordionExample2">
            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseFive"
                class="faq"
                aria-expanded="true"
                aria-controls="collapseFive"
              >
                <div class="card-header" id="headingFive">
                  <h6 class="m-0 faq-question">Whom does the policy cover?</h6>
                </div>
              </a>
              <div
                id="collapseFive"
                class="collapse show"
                aria-labelledby="headingFive"
                data-parent="#accordionExample2"
                style=""
              >
                <div class="card-body">
                  <p class="text-muted mb-0 faq-ans">Individual Cover for a group Employee/Worker</p>
                </div>
              </div>
            </div>
            <!-- collapse one end -->
            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseSix"
                class="faq collapsed"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <div class="card-header" id="headingSix">
                  <h6 class="m-0 faq-question">Are pre-existing diseases covered under GMC# policy?</h6>
                </div>
              </a>
              <div
                id="collapseSix"
                class="collapse"
                aria-labelledby="headingSix"
                data-parent="#accordionExample2"
                style=""
              >
                <div class="card-body">
                  <p class="text-muted mb-0 faq-ans">Yes**</p>
                </div>
              </div>
            </div>
            <!-- collapse two end -->
            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseSeven"
                class="faq collapsed"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                <div class="card-header" id="headingSeven">
                  <h6 class="m-0 faq-question">Is maternity benefit covered under the policy?</h6>
                </div>
              </a>
              <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample2">
                <div class="card-body">
                  <p class="text-muted mb-0 faq-ans">Yes**</p>
                </div>
              </div>
            </div>
            <!-- collapse three end -->
            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseEight"
                class="faq collapsed"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                <div class="card-header" id="headingEight">
                  <h6 class="m-0 faq-question">What are the limits for room rent charges?</h6>
                </div>
              </a>
              <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample2">
                <div class="card-body">
                  <p class="text-muted mb-0 faq-ans">
                    Submits are applicable depending on the selection kindly refer policy terms and conditions for more
                    details.
                  </p>
                </div>
              </div>
            </div>
            <!-- collapse four end -->
            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseone"
                class="faq collapsed"
                aria-expanded="true"
                aria-controls="collapseone"
              >
                <div class="card-header" id="headingOne">
                  <h6 class="m-0 faq-question">Are day care expenses covered under the policy?</h6>
                </div>
              </a>
              <div id="collapseone" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample2">
                <div class="card-body">
                  <p class="text-muted mb-0 faq-ans">Yes, day care hospitalization is covered under the policy</p>
                </div>
              </div>
            </div>
            <!-- collapse one end -->
            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseTwo"
                class="faq collapsed"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <div class="card-header" id="headingTwo">
                  <h6 class="m-0 faq-question">
                    How many No. of days does pre and post hospitalization covered under GMC#?
                  </h6>
                </div>
              </a>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample2">
                <div class="card-body">
                  <p class="text-muted mb-0 faq-ans">
                    Pre-30 days and Post 60 days of hospitalization, however this will differ as per the policy T & C.
                  </p>
                </div>
              </div>
            </div>
            <!-- collapse two end -->
            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseThree"
                class="faq collapsed"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                <div class="card-header" id="headingThree">
                  <h6 class="m-0 faq-question">Is domiciliary hospitalization covered under this policy?</h6>
                </div>
              </a>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample2">
                <div class="card-body">
                  <p class="text-muted mb-0 faq-ans">Yes**</p>
                </div>
              </div>
            </div>
            <!-- collapse three end -->
            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseFour"
                class="faq collapsed"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                <div class="card-header" id="headingFour">
                  <h6 class="m-0 faq-question">Is cashless Facility available for GMC# policy</h6>
                </div>
              </a>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample2">
                <div class="card-body">
                  <p class="text-muted mb-0 faq-ans">
                    Yes cashless facility is available in all our 7300+ network hospitals list, for detailed list please
                    refer our Network Hospital Locator
                    <a
                      href="https://rgi-locator.appspot.com/?Search_by=hospital&sourcesystem=website&phonenumber=&emailid=#/"
                      >https://rgi-locator.appspot.com/?Search_by=hospital&sourcesystem=website&phonenumber=&emailid=#/</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <!-- collapse four end -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card p-0 border-0">
        <div class="card-body p-0">
          <div class="accordion" id="accordionExample1">
            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseone_1"
                class="faq collapsed"
                aria-expanded="true"
                aria-controls="collapseone_1"
              >
                <div class="card-header" id="headingOne">
                  <h6 class="m-0 faq-question">What’s are the TATs for Claim Intimation in case of Cashless?</h6>
                </div>
              </a>
              <div id="collapseone_1" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample1">
                <div class="card-body">
                  <div class="text-muted mb-0 faq-ans">
                    <ol>
                      <li>48 hours prior for Planned admission</li>
                      <li>24 hours prior for emergency hospitalization</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseone_2"
                class="faq collapsed"
                aria-expanded="true"
                aria-controls="collapseone_2"
              >
                <div class="card-header">
                  <h6 class="m-0 faq-question">Details to be carry before for the initiating the intimation?</h6>
                </div>
              </a>
              <div id="collapseone_2" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample1">
                <div class="card-body">
                  <div class="text-muted mb-0 faq-ans">
                    <ol>
                      <li>Policy No :</li>
                      <li>Insured Name :</li>
                      <li>Insured mobile number :</li>
                      <li>Patient Name :</li>
                      <li>Intimator Name :</li>
                      <li>Intimator Relationship :</li>
                      <li>Intimator mobile no. :</li>
                      <li>Date of Admission :</li>
                      <li>Doctor Name :</li>
                      <li>Diagnosis Details :</li>
                      <li>Hospital Name :</li>
                      <li>Hospital Full Address :</li>
                      <li>Pin code :</li>
                      <li>insured Email id :</li>
                      <li>Employee Id :</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseone_3"
                class="faq collapsed"
                aria-expanded="true"
                aria-controls="collapseone_3"
              >
                <div class="card-header">
                  <h6 class="m-0 faq-question">What is the TAT to submit the documents in case of Reimbursement?</h6>
                </div>
              </a>
              <div id="collapseone_3" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample1">
                <div class="card-body">
                  <div class="text-muted mb-0 faq-ans">
                    30 days from the date of discharge
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseone_4"
                class="faq collapsed"
                aria-expanded="true"
                aria-controls="collapseone_4"
              >
                <div class="card-header">
                  <h6 class="m-0 faq-question">
                    What is the document checklist to be submitted for Reimbursement claim?
                  </h6>
                </div>
              </a>
              <div id="collapseone_4" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample1">
                <div class="card-body">
                  <div class="text-muted mb-0 faq-ans">
                    <ol>
                      <li>Duly filled Claim form (Mandatory)</li>
                      <li>Final Hospital bill with detail breakup (Mandatory)</li>
                      <li>Payment Receipts (Mandatory)</li>
                      <li>Discharge summary (Mandatory)</li>
                      <li>Doctor’s consultation papers (Mandatory)</li>
                      <li>All investigation reports (Mandatory)</li>
                      <li>All pharmacy bills supporting with Doctor prescriptions (Mandatory)</li>
                      <li>Implant Sticker / invoice if used (as applicable)</li>
                      <li>MLC / FIR for all RTA cases (as applicable)</li>
                      <li>
                        For all miscellaneous charges-detail bills with supporting prescription of the Treating doctor
                        (Mandatory)
                      </li>
                      <li>Copy of Health card</li>
                      <li>
                        Clear copy of cancel cheque with insured printed name, account number and IFSC code along with
                        photo copy of 1st page passbook copies of same account. (Mandatory)
                      </li>
                      <li>Pan Card copy (Mandatory if claimed amount is over and above 1 lac)</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseone_5"
                class="faq collapsed"
                aria-expanded="true"
                aria-controls="collapseone_5"
              >
                <div class="card-header">
                  <h6 class="m-0 faq-question">What is the process of cashless and reimbursement claim</h6>
                </div>
              </a>
              <div id="collapseone_5" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample1">
                <div class="card-body">
                  <div class="text-muted mb-0 faq-ans">
                    <img src="assets/images/process.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseone_6"
                class="faq collapsed"
                aria-expanded="true"
                aria-controls="collapseone_6"
              >
                <div class="card-header">
                  <h6 class="m-0 faq-question">Where do a customer intimate claim apart from Employee portal?</h6>
                </div>
              </a>
              <div id="collapseone_6" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample1">
                <div class="card-body">
                  <div class="text-muted mb-0 faq-ans">
                    <h6>1. Self i:</h6>
                    <p>
                      Customer can download Self I app in App store /Play store from any mobile. <br />
                      Self I app is very much in ease of use with one stop solution for all your policy and claim
                      related needs.<br />
                      You can register your policy with minimum details like policy number and DOB. Once the
                      registration is done its ease of self-navigation for claim intimation and claim status, health
                      card download etc. can be done in no time
                    </p>

                    <h6>2. Website:</h6>
                    <p>
                      Link for claim intimation through website is
                      <a
                        href="https://www.reliancegeneral.co.in/Insurance/Self-Help/Intimate-Claim-Health.aspx"
                        target="_blank"
                        >https://www.reliancegeneral.co.in/Insurance/Self-Help/Intimate-Claim-Health.aspx
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <a
                data-toggle="collapse"
                href="#collapseone_7"
                class="faq collapsed"
                aria-expanded="true"
                aria-controls="collapseone_7"
              >
                <div class="card-header">
                  <h6 class="m-0 faq-question">
                    So I also need to submit the original documents, if I have uploaded the complete soft copy of
                    documents?
                  </h6>
                </div>
              </a>
              <div id="collapseone_7" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample1">
                <div class="card-body">
                  <div class="text-muted mb-0 faq-ans">
                    No, you don’t need to submit originals documents, if clear copy is received, until something
                    specifically insurance company require
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

  <div class="row mt-3">
    <div class="col-12">
      <div class="note">
        <div class="alert alert-info">
          <small
            >** Maternity, PED, Domiciliary are add on benefits and not in the base cover of GMC, please refer your
            policy and terms and conditions for additional details<br />
            # GMC termed as Group Mediclaim</small
          >
        </div>
      </div>
    </div>
  </div>
</div>
