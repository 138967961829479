import { Component, OnInit, Injector, Injectable } from '@angular/core';
import { CPPolicyService } from '../../services/cp-policy.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Injectable()
export class CorporatePortalBaseComponent extends BaseComponent {
  public currentPolicy: any;
  public policies = [];
  public policyNumber: any;
  public role: string;

  // public isHr: boolean;
  // public isMember: boolean;
  // public isEmployeeBelongsToPolicy = false;
  // public isHrView = false;

  constructor(injector: Injector, public policyService: CPPolicyService, public authService: AuthService) {
    super(injector);
    this.initilise();
  }

  initilise() {
    // Get the role of the user
    this.role = this.authService.role;

    // Get all policies
    this.policyService.policies.subscribe(policies => {
      this.policies = policies;
    });

    // Get current policy
    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.currentPolicy = policy;
      this.policyNumber = this.currentPolicy.policyNumber;
    });
  }
}
