import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { PreEnrollmentMemberService } from '../../services/preEnrollment-member.service';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
})
export class MemberComponent extends BaseComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  subscription: Subscription;

  constructor(
    injector: Injector,
    private readonly authService: AuthService,
    private preEnrollmentMemberService: PreEnrollmentMemberService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscriptionsToDestroy.push(this.authService.isLoggedIn.subscribe(status => (this.isAuthenticated = status)));
    this.preEnrollmentMemberService.closedSideBarEventUpdate.subscribe(event => {});
  }
  toggleSidebar(event) {
    event.currentTarget.offsetParent.classList.toggle('close-sidebar');
    event.currentTarget.classList.toggle('closed');
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
