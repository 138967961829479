import { IntimateClaim } from './intimate-claim.model';

export class PACIIntimateClaim extends IntimateClaim {
    // DateOfLoss: string;
    // TimeOfLoss: string;
    // DateOfDeath: string;
    // BriefDesc: string;
    // DateOfIncident: string;
    // DateOfAdmission: string;
    // TimeOfAdmission: string;
    // DateOfFirstConsultation: string;
    ProductTypeId: number;
    ProductType: string;
    TypeofLossID: number;
    TypeofLoss: string;
    BenefitTypeID: number;
    BenefitType: string;
    LossDate: string;
    LossTime: string;
    IncidentDate: string;
    LossDescription: string;
    BenefitInsuredOccupation: string;
    
}