import { Component, OnInit, Injector } from '@angular/core';
import { CorporatePolicyModel } from 'src/app/corporate-portal/models/corporate-policy.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'src/app/shared/services/role.service';
import { BrokerService } from 'src/app/broker/service/broker.service';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { SMService } from '../../service/sm.service';

@Component({
  selector: 'app-sm',
  templateUrl: './sm.component.html',
  styleUrls: ['./sm.component.scss'],
})
export class SmComponent extends BaseComponent implements OnInit {
  corporate: CorporatePolicyModel = new CorporatePolicyModel();
  corporates: CorporatePolicyModel[] = [];

  constructor(
    injector: Injector,
    private cpMemberService: CPMemberService,
    private readonly alertService: AlertService,
    private modalService: NgbModal,
    private roleService: RoleService,
    private smService: SMService,
    private policyService: CPPolicyService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.policyService.currentPolicy.subscribe(async p => {
      if (p.id !== undefined) {
        await this.smService.getSMByPolicy(p.id).then(x => {});
      }
    });

    this.smService.getSMProfile().then(x => {});
  }

  toggleSidebar(event) {
    event.currentTarget.offsetParent.classList.toggle('close-sidebar');
    event.currentTarget.classList.toggle('closed');
  }
}
