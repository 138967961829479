import { Component, OnInit, Injector } from '@angular/core';
import { SelfMember } from 'src/app/cp-member/models/self-member.model';
import { PolicyAllowedSumInsured } from 'src/app/cp-member/models/policy-suminsured.model';
import { HealthCoverage } from 'src/app/cp-member/models/policy-coverage/health-coverage.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { CPPolicyService } from '../../services/cp-policy.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CorporatePortalBaseComponent } from '../corporate-portal-base/corporate-portal-base.component';
import { Roles } from 'src/app/shared/enums/roles.enum';

@Component({
  selector: 'app-policy-coverage',
  templateUrl: './policy-coverage.component.html',
  styleUrls: ['./policy-coverage.component.scss'],
})
export class PolicyCoverageComponent extends CorporatePortalBaseComponent implements OnInit {
  isHr: boolean;
  isMember: boolean;
  isHrView = false;

  empId = null;
  invalidEmpId = false;
  invalidEmpIdMsg: string;

  sumInsured = 0;
  error: string;

  isSearchByEmpId = false;
  isSearchBySumInsured = false;
  members: SelfMember[] = [];

  policyAllowedSumInsureds: PolicyAllowedSumInsured[] = [];
  ispolicyAllowedSumInsuredLoaded = false;

  policyCoverage: HealthCoverage[] = [];
  ispolicyCoverageLoaded = false;

  isEmployeeBelongsToPolicy = false;
  panelOpenState = false;

  constructor(
    injector: Injector,
    policyService: CPPolicyService,
    authService: AuthService,
    private cpMemberService: CPMemberService
  ) {
    super(injector, policyService, authService);
  }

  async ngOnInit(): Promise<void> {
    // console.log('Get all current policy.');
    this.cpMemberService.isHrView.subscribe(v => {
      this.isHrView = v;
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.ispolicyCoverageLoaded = false;
      this.isSearchBySumInsured = false;
      this.isSearchBySumInsured = false;
      this.error = '';
      // console.log('Get employee allowed type/role.');
      this.isHr = this.cpMemberService.isHr;
      this.isMember = this.cpMemberService.isMember;

      // console.log('Does employee belongs to current policy.');
      this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;

      this.cpMemberService.memberDetails.subscribe(m => {
        this.members = m;
      });

      if (this.policyNumber !== undefined && this.isMember && this.currentPolicy.isMemberEnrolled && !this.isHrView) {
        this.error = null;
        await this.cpMemberService
          .getPolicyCoverage(this.currentPolicy.policyNumber)
          .then(x => {
            this.policyCoverage = x;
            this.ispolicyCoverageLoaded = true;
          })
          .catch(x => {
            this.ispolicyCoverageLoaded = true;
            this.error = x.error.errors;
            console.log('error');
          });
      }

      if (
        this.policyNumber !== undefined &&
        ((this.isHr && this.isHrView) || this.role === Roles.Broker || this.role === Roles.SM)
      ) {
        this.ispolicyAllowedSumInsuredLoaded = false;
        await this.cpMemberService
          .getPolicyAllowedSumInsured(this.currentPolicy.policyNumber)
          .then(x => {
            this.policyAllowedSumInsureds = x;
            this.ispolicyAllowedSumInsuredLoaded = true;
          })
          .catch(x => {
            this.error = x.errors;
            console.log('error');
          });
      }
    });
  }

  async getHRPolicyCoverage() {
    this.isSearchByEmpId = true;
    this.ispolicyCoverageLoaded = false;
    this.error = null;
    await this.cpMemberService
      .getHRPolicyCoverage(this.currentPolicy.policyNumber, this.empId, this.sumInsured)
      .then(x => {
        this.policyCoverage = x;
        this.ispolicyCoverageLoaded = true;
      })
      .catch(x => {
        this.error = x.error.errors[0].message;
        this.policyCoverage = [];
        this.ispolicyCoverageLoaded = true;
        console.log('error');
      });
  }

  async getHRPolicyCoverageBySumInsured(event) {
    this.isSearchByEmpId = true;
    this.error = null;
    this.sumInsured = event.sumInsured;
    this.ispolicyCoverageLoaded = false;
    this.isSearchBySumInsured = true;
    await this.cpMemberService
      .getHRPolicyCoverage(this.currentPolicy.policyNumber, 0, this.sumInsured)
      .then(x => {
        this.policyCoverage = x;
        this.ispolicyCoverageLoaded = true;
      })
      .catch(x => {
        this.error = x.error.errors[0].message;
        this.policyCoverage = [];
        this.ispolicyCoverageLoaded = true;
      });
  }

  setSearchByEmpId(event) {
    this.policyCoverage = [];
    this.ispolicyCoverageLoaded = false;
    this.isSearchBySumInsured = false;
    this.sumInsured = 0;
    this.isSearchByEmpId = false;
    const empId = event.target.value;
    if (empId === '') {
      this.invalidEmpId = true;
      this.invalidEmpIdMsg = '';
    } else {
      if (/^\S+$/g.test(empId)) {
        this.invalidEmpId = false;
        this.invalidEmpIdMsg = '';
      } else {
        this.invalidEmpId = true;
        this.invalidEmpIdMsg = 'Please enter valid Employee Id';
      }
    }
  }
}
