import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CorporatePortalService {
  updatedPolicies: BehaviorSubject<any[]>;

  constructor(private readonly backendService: BackendService) {
    this.updatedPolicies = new BehaviorSubject<any[]>([]);
  }

  public async getPolicyByNumber(policyNumber): Promise<any> {
    return new Promise(async resolve => {
      const policy = this.backendService.get('CorporatePortal/policies/' + policyNumber);
      resolve(policy);
    });
  }

  public async getPolicyByCorporate(corporateId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('CorporatePortal/policies/corporate/' + corporateId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async attachPolicy(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.put('CorporatePortal/policies/' + policyId + '/attachCorporate', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async updatePolicy(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.put('CorporatePortal/policies/' + policyId, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async addCredentialFormat(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.put('CorporatePortal/policies/' + policyId + '/addCredentialFormat', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getPolicyMemberFeatures(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('CorporatePortal/policies/' + policyId + '/memberFeature/').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async addPolicyMemberFeature(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post('CorporatePortal/policies/' + policyId + '/memberFeature/', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async updatePolicyMemberFeature(policyId, memberFeatureId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.put('CorporatePortal/policies/' + policyId + '/memberFeature/' + memberFeatureId, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getHr(corporateId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('CorporatePortal/' + corporateId + '/HR/').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async createHr(corporateId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post('CorporatePortal/' + corporateId + '/HR', data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async updateHr(corporateId, data, hrId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.put('CorporatePortal/' + corporateId + '/HR/' + hrId, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async searchHrByName(corporateId, name): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('CorporatePortal/' + corporateId + '/members/search/name/' + name).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getMemberByPolicy(policyId, skip, take): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('CorporatePortal/policies/' + policyId + '/members/' + skip + '/' + take).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getMemberByEmpId(empId, policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get(`CorporatePortal/policies/${policyId}/members/employeeId?employeeId=${empId}`).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async searchMemberByEmpIdWithCorporateId(empId, corporateId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get(`CorporatePortal/ ${corporateId}/members/search/employeeId?employeeId=${empId}`).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getPolicyCount(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('CorporatePortal/policies/' + policyId + '/members/count').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async searchMemberByEmpId(policyId, empId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('CorporatePortal/policies/' + policyId + '/members/search/' + empId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getSmMappedCount(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('SM/Mapped/policy/' + policyId + '/count').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async getSmMappedPolicy(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('SM/Mapped/policy/' + policyId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public getSmByCode(code): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('SM/' + code).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public createSm(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post('SM/map/policy/' + policyId, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public updateSm(policyId, data, smId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.put('SM/map/policy/' + policyId + '/' + smId, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async searchSmByName(name): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('SM/Master/' + name).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public getBrokerMappedCount(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('Broker/Mapped/policy/' + policyId + '/count').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public getBrokerMappedByPolicy(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('Broker/Mapped/policy/' + policyId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
  public getBrokerByCode(code): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('Broker/' + code).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public createBroker(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post('Broker/map/policy/' + policyId, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public updateBroker(policyId, data, brokerId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.put('Broker/map/policy/' + policyId + '/' + brokerId, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
  public searchBrokerByName(name): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('Broker/Master/' + name).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public createDocument(policyId, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.postFormData(`CorporatePortal/policies/${policyId}/documents`, data).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public getDocument(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.get('CorporatePortal/policies/' + policyId + '/documents').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public deleteDocument(documentId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.delete('CorporatePortal/documents/' + documentId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public deleteSM(smId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.delete('SM/' + smId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public deleteBroker(brokerId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.delete('Broker/' + brokerId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public resetCredentialsByPolicyId(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post('ResetAndSendCredentials/policies/' + policyId + '/members', '').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public resetCredentialsByMemberId(policyId, memberId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post('ResetAndSendCredentials/policies/' + policyId + '/members/' + memberId, '').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public turnSyncOn(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post(policyId + '/TurnOnSync', '').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public sendCredentialsToMemberById(policyId, hrId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post('SendCredentials/HR/' + hrId, '').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public sendCredentialsToBroker(policyId, brokerId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.post('SendCredentials/policies/' + policyId + '/Brokers/' + brokerId, '').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public deleteHR(hrId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.delete('CorporatePortal/HR/' + hrId).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public addEmailTemplateAPI(policyId, body): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.putFormData('CorporatePortal/policies/' + policyId + '/template', body).then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  public async detachPolicy(policyId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.backendService.put('CorporatePortal/policies/' + policyId + '/detachCorporate', '').then(
        response => {
          return resolve(response);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
}
