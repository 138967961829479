import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Broker } from 'src/app/shared/models/CorporatePortal/broker.model';
import { Guid } from 'src/app/shared/enums/guid.enum';

@Component({
  selector: 'app-create-broker',
  templateUrl: './create-broker.component.html',
  styleUrls: ['./create-broker.component.scss'],
})
export class CreateBrokerComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public corporate;
  @Input() public policy;
  @Output() public brokerAddedSucessfully = new EventEmitter<boolean>();
  code: string;
  isMemberDetailsFound: boolean;
  broker: Broker;
  saveButtonText = 'Next';
  currentStep = 'firstStep';
  isReportCheckboxValid = true;
  isDashboardCheckboxValid = true;

  isNameValid = false;
  isCodeValid = false;
  isEmailValid = false;
  isMobileNoValid = false;

  disableCode = false;
  disableName = false;
  disableEmail = false;
  disableMobileNo = false;

  isNameSelectedFromDropdown = false;

  invalidMobileMsg: string;
  invalidEmailMsg: string;
  invalidNameMsg: string;

  errorList = [];

  title = 'Create Broker';

  isUpdatingBroker = false;
  brokerDetails: any;
  invalidCodeMsg: string;
  brokerSearchList = [];
  searchByCodeDetailsFound = false;
  showLoader = false;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly corporatePortalService: CorporatePortalService,
    private readonly alertService: AlertService,
    private modalService: NgbModal
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.broker = new Broker();
    if (this.brokerDetails !== undefined) {
      this.title = 'Edit Broker';
      this.getBrokerViaCode();
      this.isUpdatingBroker = true;
    } else {
      this.code = '';
    }
  }

  close() {
    this.modalService.dismissAll(CreateBrokerComponent);
  }

  back() {
    this.currentStep = 'firstStep';
    this.saveButtonText = 'Next';
    this.isMemberDetailsFound = true;
  }

  async save(event) {
    if (event === 'Next') {
      this.currentStep = 'secondStep';
      this.saveButtonText = 'Save';
      this.checkCheckboxesValidity();
    } else if (event === 'Save') {
      this.isMemberDetailsFound = false;
      this.showLoader = true;
      if (this.broker.brokerMaster.id === null) {
        this.broker.brokerMaster.id = Guid.Default;
        delete this.broker['brokerMaster']['loginEmailSentAt'];
        delete this.broker['brokerMaster']['loginSMSSentAt'];
      }
      if (this.isUpdatingBroker === true) {
        await this.corporatePortalService
          .updateBroker(this.policy.id, this.broker, this.brokerDetails.id)
          .then(x => {
            console.log(x);
            this.showLoader = false;
            this.brokerAddedSucessfully.emit(true);
            this.modalService.dismissAll(CreateBrokerComponent);
            this.alertService.success('Broker updated successfully');
          })
          .catch(errorData => {
            console.log(errorData);
            this.showLoader = false;
            this.isMemberDetailsFound = true;
            this.errorList = errorData.error.errors;
          });
      } else {
        await this.corporatePortalService
          .createBroker(this.policy.id, this.broker)
          .then(x => {
            console.log(x);
            this.showLoader = false;
            this.brokerAddedSucessfully.emit(true);
            this.modalService.dismissAll(CreateBrokerComponent);
            this.alertService.success('Broker added successfully');
          })
          .catch(errorData => {
            this.showLoader = false;
            this.errorList = errorData.error.errors;
            this.isMemberDetailsFound = true;
            this.alertService.error('Error occured');
          });
      }
    }
  }

  async getBrokerByCode(event) {
    if (event.target.value.trim() !== '') {
      const broker = await this.corporatePortalService.getBrokerByCode(event.target.value);
      if (broker !== null) {
        this.searchByCodeDetailsFound = true;
        this.brokerSearchList = [];
        this.isMemberDetailsFound = true;
        this.broker.brokerMaster.code = event.target.value;
        this.broker.brokerMaster.email = broker.email;
        this.broker.brokerMaster.id = broker.id;
        this.broker.brokerMaster.loginEmailSentAt = broker.loginEmailSentAt;
        this.broker.brokerMaster.loginSMSSentAt = broker.loginSMSSentAt;
        this.broker.brokerMaster.mobileNo = broker.mobileNo;
        this.broker.brokerMaster.name = broker.name;
        this.disableName = true;
        this.disableEmail = true;
        this.disableMobileNo = true;
        this.isNameValid = true;
      } else {
        this.searchByCodeDetailsFound = false;
        this.disableName = false;
        this.disableEmail = false;
        this.disableMobileNo = false;
        this.isMemberDetailsFound = false;
        this.broker.brokerMaster.code = event.target.value;
        // this.broker.brokerMaster.email = null;
        // this.broker.brokerMaster.id = null;
        // this.broker.brokerMaster.loginEmailSentAt = null;
        // this.broker.brokerMaster.loginSMSSentAt = null;
        // this.broker.brokerMaster.mobileNo = null;
        // this.broker.brokerMaster.name = null;
      }
    } else {
      this.searchByCodeDetailsFound = false;
      this.disableName = false;
      this.disableEmail = false;
      this.disableMobileNo = false;
      this.isMemberDetailsFound = false;
      this.broker.brokerMaster.code = event.target.value;
      // this.broker.brokerMaster.email = null;
      // this.broker.brokerMaster.id = null;
      // this.broker.brokerMaster.loginEmailSentAt = null;
      // this.broker.brokerMaster.loginSMSSentAt = null;
      // this.broker.brokerMaster.mobileNo = null;
      // this.broker.brokerMaster.name = null;
      this.isNameValid = false;
      this.invalidNameMsg = '';
    }
  }

  checkDashboardValue() {
    if (this.broker.dashboard === true) {
      if (this.broker.staticDashboard === true || this.broker.dyanamicDashboard === true) {
        this.isDashboardCheckboxValid = true;
      } else {
        this.isDashboardCheckboxValid = false;
      }
    } else if (this.broker.dashboard === false) {
      this.broker.staticDashboard = false;
      this.broker.dyanamicDashboard = false;
      this.isDashboardCheckboxValid = true;
    }
  }

  checkReportValue() {
    if (this.broker.reportAllowed === true) {
      if (this.broker.reimbersementReport === true || this.broker.cashlessReport === true) {
        this.isReportCheckboxValid = true;
      } else {
        this.isReportCheckboxValid = false;
      }
    } else if (this.broker.reportAllowed === false) {
      this.broker.reimbersementReport = false;
      this.broker.cashlessReport = false;
      this.isReportCheckboxValid = true;
    }
  }

  checkCheckboxesValidity() {
    this.checkDashboardValue();
    this.checkReportValue();
    this.isMemberDetailsFound =
      this.isReportCheckboxValid === true && this.isDashboardCheckboxValid === true ? true : false;
  }

  checkNameValidity(event) {
    const fieldValue = event.target.value.trim();
    if (this.brokerSearchList.length !== 0 && this.isNameSelectedFromDropdown) {
      this.brokerSearchList = [];
      this.code = '';
      this.broker.brokerMaster.email = '';
      this.broker.brokerMaster.code = '';
      this.broker.brokerMaster.mobileNo = '';
      this.disableCode = false;
      this.disableEmail = false;
      this.disableMobileNo = false;
      this.isEmailValid = false;
      this.isMobileNoValid = false;
      this.isMemberDetailsFound = false;
      this.isNameSelectedFromDropdown = false;
    }
    if (fieldValue.length === 0) {
      this.isNameValid = false;
      this.invalidNameMsg = 'Name cannot be null!!!';
    } else if (fieldValue.match(/^[a-zA-Z][a-zA-Z. ]*[a-zA-Z]$/) === null) {
      this.isNameValid = false;
      this.invalidNameMsg = 'Please enter valid name!!';
    } else {
      this.isNameValid = true;
    }
    this.showNextButton();
  }

  async getBrokerByName(event) {
    if (event.target.value.trim() !== '') {
      const fieldValue = event.target.value.trim();
      const brokerSearchList = await this.corporatePortalService.searchBrokerByName(fieldValue);
      if (brokerSearchList.length > 0) {
        this.brokerSearchList = brokerSearchList;
      }
    }
  }

  async onNameDropdownChanged(event) {
    console.log(event);
    console.log(this.broker.brokerMaster);
    this.code = this.broker.brokerMaster.code;
    this.disableCode = true;
    this.disableEmail = true;
    this.disableMobileNo = true;
    this.isCodeValid = true;
    this.isEmailValid = true;
    this.isMobileNoValid = true;
    this.isNameValid = true;
    this.invalidEmailMsg = '';
    this.invalidMobileMsg = '';
    this.isNameSelectedFromDropdown = true;
    this.showNextButton();
  }

  checkCodeValidity(event) {
    if (this.searchByCodeDetailsFound) {
      this.disableName = false;
      this.disableEmail = false;
      this.disableMobileNo = false;
      this.broker.brokerMaster.email = '';
      this.broker.brokerMaster.name = '';
      this.broker.brokerMaster.mobileNo = '';
      this.isEmailValid = true;
      this.isMobileNoValid = true;
      this.isNameValid = true;
    }
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.isCodeValid = false;
      this.invalidCodeMsg = 'Code cannot be null!!!';
    } else if (fieldValue.match(/^[a-zA-Z0-9]+$/i) === null) {
      this.isCodeValid = false;
      this.invalidCodeMsg = 'Please enter valid code!!';
    } else {
      this.isCodeValid = true;
    }
    this.showNextButton();
  }

  checkEmailValidity(event) {
    const fieldValue = event.target.value.trim();
    if (fieldValue.length === 0) {
      this.isEmailValid = false;
      this.invalidEmailMsg = 'Email cannot be null!!!';
    } else if (fieldValue.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i) === null) {
      this.isEmailValid = false;
      this.invalidEmailMsg = 'Please enter valid email!!';
    } else {
      this.isEmailValid = true;
    }
    this.showNextButton();
  }

  checkMobileNoValidity(event) {
    const mobileNumber = event.target.value;
    const mobileNumberLength = mobileNumber.trim().length;
    if (mobileNumberLength === 0) {
      this.isMobileNoValid = false;
      this.invalidMobileMsg = 'Mobile number cannot be null!!';
    } else {
      if (mobileNumberLength !== 10 || mobileNumber.match(/^[6-9][0-9]{9}$/) === null) {
        this.isMobileNoValid = false;
        this.invalidMobileMsg = 'Enter valid mobile number';
      } else {
        this.isMobileNoValid = true;
      }
    }
    this.showNextButton();
  }

  showNextButton() {
    if (this.code.length === 0) {
      this.isMemberDetailsFound = false;
    } else {
      this.isMemberDetailsFound =
        this.isNameValid === true && this.isEmailValid === true && this.isMobileNoValid === true ? true : false;
    }
  }

  async getBrokerViaCode() {
    this.disableName = true;
    this.disableEmail = true;
    this.disableMobileNo = true;
    this.disableCode = true;
    this.isMemberDetailsFound = true;
    this.code = this.brokerDetails.code;
    this.broker.brokerMaster.code = this.brokerDetails.code;
    this.broker.brokerMaster.email = this.brokerDetails.email;
    this.broker.brokerMaster.id = this.brokerDetails.id;
    this.broker.brokerMaster.loginEmailSentAt = this.brokerDetails.loginEmailSentAt;
    this.broker.brokerMaster.loginSMSSentAt = this.brokerDetails.loginSMSSentAt;
    this.broker.brokerMaster.mobileNo = this.brokerDetails.mobileNo;
    this.broker.brokerMaster.name = this.brokerDetails.name;
    this.broker.endoresment = this.brokerDetails.endoresment;
    this.broker.reportAllowed = this.brokerDetails.reportAllowed;
    this.broker.dashboard = this.brokerDetails.dashboard;
    this.broker.staticDashboard = this.brokerDetails.staticDashboard;
    this.broker.dyanamicDashboard = this.brokerDetails.dyanamicDashboard;
    this.broker.reimbersementReport = this.brokerDetails.reimbersementReport;
    this.broker.cashlessReport = this.brokerDetails.cashlessReport;
  }

  ngOnChanges(changes: SimpleChanges) {}

  MemberDetailsFound(event) {
    this.isMemberDetailsFound = event;
  }
}
