import { Component, OnInit, Input, Injector, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ErrorMsgDialog } from 'src/app/shared/components/error-msg-dialog/ErrorMsgDialog';
import { ErrorMsgDialogComponent } from 'src/app/shared/components/error-msg-dialog/error-msg-dialog.component';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class MembersComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() corporate: CorporateModel;
  @Input() policyNumber: any;
  policy: any;
  searchText: string;
  isLoading: boolean;
  members: any = [];
  membersCopy: any = [];
  memberProperty = 'memberProp';
  skip = 0;
  take = 5;
  totalMemberCount: number;
  isSearchingMember = false;
  totalMemberSearchCount: number;
  page = 1;
  pageCopy = 1;
  pageSize = 5;
  pageSizeCopy = 5;
  isSearchMemberFound = true;
  syncText = 'Turn Sync On';
  errorMsg: string[] = [];
  isUsernamePasswordTypeNotNull = false;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly corporatePortalService: CorporatePortalService,
    private readonly alertService: AlertService,
    private modalService: NgbModal
  ) {
    super(injector);
  }

  async ngOnInit() {
    super.ngOnInit();
    if (this.policyNumber !== 'Attach Policy') {
      this.isUsernamePasswordTypeNotNull =
        this.policy.userNameType !== null && this.policy.passwordType !== null ? true : false;
      this.isLoading = true;
      await this.getMembers(this.skip, this.take);
      this.syncText = this.policy.isSyncOn === true ? 'Sync on' : 'Turn Sync On';
      this.isLoading = false;
    }
  }
  async ngOnChanges(changes: SimpleChanges) {
    this.corporatePortalService.updatedPolicies.subscribe((policyList: any[]) => {
      this.policy = policyList.find(policy => policy.policyNumber === this.policyNumber);
    });
    if (changes['policyNumber'] !== undefined && changes['corporate'] === undefined) {
      if (this.policyNumber !== 'Attach Policy') {
        this.isLoading = true;
        this.searchText = '';
        this.page = 1;
        this.members = [];
        this.isSearchingMember = false;
        this.syncText = this.policy.isSyncOn === true ? 'Sync on' : 'Turn Sync On';
        this.isUsernamePasswordTypeNotNull =
          this.policy.userNameType !== null && this.policy.passwordType !== null ? true : false;
        await this.getMembers(this.skip, this.take);
        this.isLoading = false;
      }
    }
  }
  async getMembers(skip, take) {
    this.totalMemberCount = await this.corporatePortalService.getPolicyCount(this.policy.id);
    const members = await this.corporatePortalService.getMemberByPolicy(this.policy.id, this.skip, this.take);
    if (members || members.length !== 0) {
      this.members = members;
    }
  }

  async searchingMember(event) {
    const searchValue = event.target.value.trim();
    if (searchValue === '' || searchValue === null || searchValue === undefined) {
      this.isLoading = true;
      this.isSearchingMember = false;
      this.isSearchMemberFound = true;
      this.getMembers(0, this.take);
      this.page = 1;
      this.isLoading = false;
    } else {
      this.isLoading = true;
      this.isSearchingMember = true;
      const empId = event.target.value.trim();
      this.membersCopy = await this.corporatePortalService.searchMemberByEmpId(this.policy.id, empId);
      this.isSearchMemberFound = this.membersCopy.length === 0 ? false : true;
      this.totalMemberSearchCount = this.membersCopy.length;
      const size = 5;
      this.members = [];
      this.membersCopy.slice(0, size).map(i => {
        this.members.push(i);
      });
      this.pageCopy = 1;
      this.isLoading = false;
    }
  }

  loadSearchPage(event) {
    const pageNum = event;
    const size = 5;
    this.members = [];
    this.membersCopy.slice((pageNum - 1) * size, pageNum * size).map(i => {
      this.members.push(i);
    });
  }

  async loadPage(event) {
    const pageNum = event;
    this.isLoading = true;
    this.skip = (pageNum - 1) * this.take;
    await this.getMembers(this.skip, this.take);
    this.isLoading = false;
  }

  async resetPasswordByPolicyId() {
    let result;
    const message = `Are you sure you want to reset and send password?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        await this.corporatePortalService
          .resetCredentialsByPolicyId(this.policy.id)
          .then(x => {
            console.log(x);
            this.alertService.success('Password reset successfully');
          })
          .catch(errorData => {
            const errorList = errorData.error.errors;
            this.openErrorModal(errorList);
          });
      }
    });
  }

  async resetPasswordByMemberId(member) {
    const memberId = member.id;
    let result;
    const message = `Are you sure you want to reset and send password?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        await this.corporatePortalService
          .resetCredentialsByMemberId(this.policy.id, memberId)
          .then(x => {
            console.log(x);
            this.alertService.success('Password reset successfully');
          })
          .catch(errorData => {
            const errorList = errorData.error.errors;
            this.openErrorModal(errorList);
          });
      }
    });
  }

  async turnOnSync() {
    let result;
    const message = `Are you sure you want to turn on sync?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        await this.corporatePortalService
          .turnSyncOn(this.policy.id)
          .then(x => {
            console.log(x);
            this.alertService.success('Sync turned On');
            this.syncText = 'Sync on';
            this.policy.isSyncOn = true;
          })
          .catch(errorData => {
            const errorList = errorData.error.errors;
            this.openErrorModal(errorList);
          });
      }
    });
  }

  openErrorModal(errorList) {
    this.errorMsg = [];
    errorList.forEach(errorDescription => {
      this.errorMsg.push(errorDescription.message);
    });

    const dialogData = new ErrorMsgDialog(this.errorMsg);

    this.dialog.open(ErrorMsgDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
