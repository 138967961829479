import { HttpClient, HttpHeaders, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { mapTo, retryWhen, tap, map } from 'rxjs/operators';
import { fromEvent, Observable } from 'rxjs';
import { ErrorsHandler } from './error-handler.service';
import { AuthService } from './auth.service';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { EncruptioDecryptionService } from './encryption-decryption.service';

@Injectable()
export class BackendService {
  baseUrl: string;
  userId: string;
  private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  private httpOptions: any;
  private downloadFileHttpOptions: any;
  key: any;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorsHandler: ErrorsHandler,
    private authService: AuthService,
    private encryptionDecryptionService: EncruptioDecryptionService
  ) {
    this.userId = authService.getUserId();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authService.authorizationHeaderValue,
      }),
    };
    this.generateKey();
    this.downloadFileHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authService.authorizationHeaderValue,
        Key: this.key,
      }),
      responseType: 'blob',
    };
    this.baseUrl = environment.apiBaseUrl;
  }

  get isOnline() {
    return navigator.onLine;
  }

  generateKey() {
    const key = {
      date: moment().format(),
      key: uuidv4(),
    };
    this.key =this.encryptionDecryptionService.encrypt( JSON.stringify(key),  'b14ca5898a4e4133bbce2ea2315a1916KPngPNGyVN');
  }

  downloadFile(url: string): Promise<any> {
    this.generateKey();
    this.downloadFileHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authService.authorizationHeaderValue,
        Key: this.key,
      }),
      responseType: 'blob',
    };
    return this.httpClient.get(this.baseUrl + url, this.downloadFileHttpOptions).toPromise();
  }

  downloadPDF(url: string): Promise<any> {
    this.generateKey();
    this.downloadFileHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        Authorization: this.authService.authorizationHeaderValue,
        Key: this.key,
      }),
      responseType: 'blob',
    };
    return this.httpClient.get(this.baseUrl + url, this.downloadFileHttpOptions).toPromise();
  }

  async get(url: string, retryIfOffline: boolean = false): Promise<any> {
    this.generateKey();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authService.authorizationHeaderValue,
        Key: this.key,
      }),
    };
    return this.httpClient.get(this.baseUrl + url, this.httpOptions).toPromise();
  }

  async post(url: string, body: any, retryIfOffline: boolean = false): Promise<any> {
    this.generateKey();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authService.authorizationHeaderValue,
        Key: this.key,
      }),
    };
    return this.httpClient.post(this.baseUrl + url, body, this.httpOptions).toPromise();
  }

  async postFormData(url: string, body: any, retryIfOffline: boolean = false): Promise<any> {
    this.generateKey();
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.authService.authorizationHeaderValue,
        Key: this.key,
      }),
    };
    return this.httpClient.post(this.baseUrl + url, body, this.httpOptions).toPromise();
  }

  async putFormData(url: string, body: any, retryIfOffline: boolean = false): Promise<any> {
    this.generateKey();
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.authService.authorizationHeaderValue,
        Key: this.key,
      }),
    };
    return this.httpClient.put(this.baseUrl + url, body, this.httpOptions).toPromise();
  }

  async postFile(url: string, body: any, retryIfOffline: boolean = false): Promise<any> {
    this.generateKey();
    return this.httpClient
      .post(this.baseUrl + url, body, {
        headers: new HttpHeaders({
          Authorization: this.authService.authorizationHeaderValue,
          Key: this.key,
        }),
      })
      .toPromise();
  }

  async put(url: string, body: any): Promise<any> {
    this.generateKey();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authService.authorizationHeaderValue,
        Key: this.key,
      }),
    };
    return this.httpClient.put(this.baseUrl + url, body, this.httpOptions).toPromise();
  }

  async delete(url: string): Promise<any> {
    this.generateKey();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authService.authorizationHeaderValue,
        Key: this.key,
      }),
    };
    return this.httpClient.delete(this.baseUrl + url, this.httpOptions).toPromise();
  }

  async deleteData(url: string, body: any): Promise<any> {
    this.generateKey();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authService.authorizationHeaderValue,
        Key: this.key,
      }),
      body: body,
    };
    return this.httpClient.delete(this.baseUrl + url, this.httpOptions).toPromise();
  }

  public upload(url: string, data: any) {
    return this.httpClient
      .post<any>(this.baseUrl + url, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };

            case HttpEventType.Response:
              console.log(event);
              return event.body;
            default:
              console.log(event);
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }
}
