import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { Policy } from '../../models/policy.model';
import { CPMemberService } from '../../services/CPMember.service';
import { CreateEmployeeEndorsementComponent } from './create-employee-endorsement/create-employee-endorsement.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Hr } from 'src/app/shared/models/CorporatePortal/hr.model';
import * as FileSaver from 'file-saver';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporatePortalBaseComponent } from 'src/app/corporate-portal/components/corporate-portal-base/corporate-portal-base.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EndorsementService } from 'src/app/corporate-portal/services/endorsement.service';

@Component({
  selector: 'app-endorsement',
  templateUrl: './endorsement.component.html',
  styleUrls: ['./endorsement.component.scss'],
})
export class EndorsementComponent extends CorporatePortalBaseComponent implements OnInit {
  @Output() public isLoadDetails = new EventEmitter<string>();
  currentPolicy: Policy;
  policies = [];
  isHr: boolean;
  isMember: boolean;
  isMemberDetailsLoaded = false;

  hr: Hr;
  isHrView = false;

  isEmployeeBelongsToPolicy = false;

  isDependentEndorsementsLoaded = true;
  dependentEndorsementsByMemberCount = 0;
  modalOption: NgbModalOptions = {};
  dependentCountFound = false;

  constructor(
    injector: Injector,
    policyService: CPPolicyService,
    authService: AuthService,
    private modalService: NgbModal,
    private endorsementService: EndorsementService,
    private cpMemberService: CPMemberService
  ) {
    super(injector, policyService, authService);
  }

  async ngOnInit() {
    this.policyService.policies.subscribe(policies => {
      this.policies = policies;
    });
    this.cpMemberService.isHrView.subscribe(v => {
      this.isHrView = v;
      if (this.isHrView) {
        this.isMemberDetailsLoaded = false;
        this.GetDependentsCount();
      }
    });

    this.cpMemberService.selfMemberDetails.subscribe(self => {
      this.isMemberDetailsLoaded = false;
      if (self !== null || self !== undefined) {
        this.isMemberDetailsLoaded = true;
      }
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.currentPolicy = policy;
      this.isEmployeeBelongsToPolicy = this.currentPolicy.isMemberEnrolled;
      this.isHr = this.cpMemberService.isHr;
      this.isMember = this.cpMemberService.isMember;
      this.isMemberDetailsLoaded = this.isHrView === false ? true : false;
    });

    this.cpMemberService.hrDetails.subscribe(x => {
      this.hr = x;
    });
  }

  onNamePolicyChanged(policy) {
    this.policyService.currentPolicy.next(policy);
  }

  OpenCreateEmployeeComponent(string) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(CreateEmployeeEndorsementComponent, this.modalOption);
    modalRef.componentInstance.policy = this.currentPolicy;
    modalRef.componentInstance.addMemberOrDependent = string;
    modalRef.componentInstance.policies = this.policies;
    modalRef.componentInstance.dependentAddedSucessfully.subscribe(isdependentAdded => {
      isdependentAdded === true ? this.isLoadDetails.emit('dependent') : this.isLoadDetails.emit('member');
    });
  }

  async GetDependents() {
    this.isDependentEndorsementsLoaded = false;
    await this.endorsementService.dependentEndorsementsExcel().then(
      result => {
        this.isDependentEndorsementsLoaded = true;
        FileSaver.saveAs(result, `DependentEndorsements.xlsx`);
      },
      error => {}
    );
  }

  async GetDependentsCount() {
    this.dependentCountFound = false;
    this.endorsementService.dependentEndorsementsCount().then(
      result => {
        this.dependentCountFound = true;
        this.dependentEndorsementsByMemberCount = result.count;
      },
      error => {
        this.dependentCountFound = false;
      }
    );
  }
}
