<div class="create-enrollment-component-wrapper" *ngIf="initialized">
  <div class="container">
    <div class="error-msg" *ngFor="let error of errorR">
      <p>{{ error.message }}</p>
    </div>
    <div class="create-enrollment-box">
      <div class="enrollment-box-row" [class.complete]="currentStep > setPolicyName">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: setPolicyName,
            title: editMode ? 'General Settings' : 'Set general settings',
            btnTitle: editMode ? 'General Settings' : 'Set general settings'
          }"
        >
        </ng-container>
        <div class="box-body" [hidden]="currentStep !== setPolicyName">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Policy Year</label>
                <select class="form-control" [(ngModel)]="policy.year">
                  <option *ngFor="let year of yearOptions" [value]="year">{{ year }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Description <sup>*</sup></label>
                <input [(ngModel)]="policy.name" type="text" class="form-control" />
                <div class="errorMsg" [hidden]="!policyNameInvalid">
                  Policy name cannot be blank
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Start Date <sup>*</sup></label>
                <input
                  value="{{ policy.startDate | date: 'dd MMMM yyyy' }}"
                  [(ngModel)]="policy.startDate"
                  (ngModelChange)="startDateChanged()"
                  type="text"
                  class="form-control"
                  readonly
                  bsDatepicker
                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                />
                <div class="errorMsg" [hidden]="!policyStartDateInvalid">
                  Policy start date should be set to a future date
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>End Date <sup>*</sup></label>
                <input
                  value="{{ policy.endDate | date: 'dd MMMM yyyy' }}"
                  [(ngModel)]="policy.endDate"
                  type="text"
                  class="form-control"
                  readonly
                  bsDatepicker
                  [minDate]="policy.startDate"
                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                />
                <div class="errorMsg" [hidden]="!policyEndDateInvalid">
                  Policy end date should be set to a date equal or greater than policy start date
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Plan<sup>*</sup></label>
                <select [(ngModel)]="policy.plan" class="form-control">
                  <option value="Floater">Floater</option>
                  <option value="Individual">Individual</option>
                </select>
                <div class="errorMsg" [hidden]="!isPlanNull">
                  Plan cannot be empty
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>GST Exempted</label>
                <div class="custom-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      [(ngModel)]="gstDetails.isGSTExempted"
                      (change)="setGstPercentage()"
                    />
                    <span></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" *ngIf="gstDetails.isGSTExempted">
                <label>GST Percentage<sup>*</sup></label>
                <input [(ngModel)]="gstDetails.gSTPercentage" type="number" class="form-control" disabled />
              </div>
              <div class="form-group" *ngIf="!gstDetails.isGSTExempted">
                <label>GST Percentage<sup>*</sup></label>
                <input [(ngModel)]="gstDetails.gSTPercentage" type="number" min="0" class="form-control" />
                <div class="errorMsg" [hidden]="!isGSTPercentageNull">
                  GST percentage cannot be blank
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="gstDetails.isGSTExempted">
            <div class="col-md-6">
              <div class="form-group">
                <label>GST File Upload Browse</label>
                <!--  -->
                <div>
                  <button class="btn px-4 btn-sm mb-2 btn-primary" type="button" (click)="fileInput.click()">
                    <span>Upload file</span>
                    <input
                      #fileInput
                      id="file"
                      type="file"
                      (change)="onFileChange($event.target.files)"
                      style="display:none;"
                      accept=".xlsx,.jpg, .png, .doc, .docx,.pdf"
                    />
                  </button>
                </div>

                <div *ngIf="true">
                  <label>File Name/Link</label>
                  <input
                    id="fileText"
                    type="text"
                    class="form-control"
                    [(ngModel)]="fileName"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder=""
                    readonly
                  />
                  <button
                    class="btn btn-sm remove-file-wrapper"
                    [hidden]="fileName === '' || fileName === null || fileName === undefined"
                  >
                    <i class="material-icons" (click)="deleteFile()">delete</i>
                  </button>
                </div>
                <!--  -->
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Allowed sum insured <sup>*</sup></label>
                <div class="errorMsg" [hidden]="!sumInsuredValuesMissing">
                  There has to be atleast one sum insured value
                </div>
                <div class="errorMsg" [hidden]="!repeatedSumInsuredValues">
                  Sum Insured values should not be repeated
                </div>
                <div class="table-grid">
                  <table>
                    <tbody>
                      <tr *ngFor="let sumInsuredValue of policy.sumsInsured; let i = index">
                        <td>
                          <input
                            [ngModel]="sumInsuredValue.value | numberFormat"
                            (ngModelChange)="sumInsuredValue.value = $event"
                            type="text"
                            class="form-control small-input w-75 pl-3"
                            (input)="sumInsuredValue.checkValidity()"
                          />
                          <span class="errorMsg" [hidden]="!sumInsuredValue.isInvalid">&nbsp;Invalid Sum</span>
                        </td>
                        <td class="text-center">
                          <a
                            [hidden]="policy.sumsInsured.length === 1"
                            (click)="removeSumInsuredEntry(i)"
                            class="text-secondary"
                            ><i class="material-icons">delete</i></a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button (click)="addNewSumInsuredEntry()" class="add-new-row-btn">
                    <i class="material-icons">add</i> <span>Add new</span>
                  </button>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="enrollment-box-row" [class.complete]="currentStep > configureMemberSettings">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: configureMemberSettings,
            title: 'Configure member settings',
            btnTitle: 'Set Member Settings'
          }"
        >
        </ng-container>
        <div class="box-body" [hidden]="currentStep !== configureMemberSettings">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Total members <sup>*</sup></label>
                <input
                  [ngModel]="policy.totalMembersInString | numberFormat"
                  (ngModelChange)="policy.totalMembersInString = $event"
                  type="text"
                  class="form-control"
                />
                <div class="errorMsg" [hidden]="!totalMembersInvalid">
                  Total members must be a number greater than zero
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input [(ngModel)]="policy.allowEmployeeViewProfile" type="checkbox" class="custom-control-input" />
                    <span>Allow the employee to view his profile</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-6" [hidden]="!policy.allowEmployeeViewProfile">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input [(ngModel)]="policy.allowEmployeeEditProfile" type="checkbox" class="custom-control-input" />
                    <span>Allow the employee to edit his profile</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input [(ngModel)]="policy.allowDeletingMember" type="checkbox" class="custom-control-input" />
                    <span>Allow deleting members</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [(ngModel)]="policy.allowHRResetEmployeeDetails"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Allow HR to reset employee details</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="notification-msg">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="8" fill="white" />
              <rect x="5.5" y="3.25" width="4.5" height="9" fill="#FF7D37" />
              <path
                d="M12.979 5.11371C12.4648 4.2328 11.7674 3.53533 10.8864 3.02116C10.0053 2.50702 9.0435 2.25 8.00018 2.25C6.95697 2.25 5.99484 2.50702 5.11391 3.02116C4.23292 3.53525 3.53544 4.23272 3.02124 5.11371C2.50702 5.99475 2.25 6.95687 2.25 8C2.25 9.04318 2.5071 10.0051 3.02116 10.8862C3.53534 11.7671 4.23281 12.4646 5.1138 12.9788C5.99485 13.493 6.95689 13.75 8.00008 13.75C9.04326 13.75 10.0054 13.493 10.8864 12.9788C11.7673 12.4648 12.4648 11.7671 12.9789 10.8862C13.493 10.0052 13.75 9.04313 13.75 8C13.75 6.95677 13.493 5.99462 12.979 5.11371ZM7.04181 3.6874C7.04181 3.61749 7.06423 3.56014 7.1092 3.51524C7.1541 3.47037 7.21155 3.44785 7.28141 3.44785H8.71901C8.78881 3.44785 8.84627 3.47037 8.89106 3.51524C8.93593 3.56014 8.95853 3.61749 8.95853 3.6874V4.88535C8.95853 4.95521 8.93593 5.01264 8.89106 5.05749C8.84627 5.10238 8.78884 5.12483 8.71901 5.12483H7.28141C7.21155 5.12483 7.15412 5.10238 7.1092 5.05749C7.06423 5.01267 7.04181 4.95521 7.04181 4.88535V3.6874ZM9.91678 11.5938C9.91678 11.6636 9.89415 11.721 9.84938 11.7658C9.8047 11.8108 9.74724 11.8332 9.67728 11.8332H6.32301C6.25307 11.8332 6.19572 11.8108 6.1508 11.7658C6.1059 11.721 6.08346 11.6636 6.08346 11.5937V10.3957C6.08346 10.3257 6.1059 10.2685 6.1508 10.2235C6.19572 10.1787 6.25315 10.1561 6.32301 10.1561H7.04181V7.76037H6.32301C6.25307 7.76037 6.19575 7.73795 6.1508 7.69303C6.1059 7.64816 6.08346 7.59073 6.08346 7.52081V6.32294C6.08346 6.25303 6.1059 6.19568 6.1508 6.15073C6.19577 6.10581 6.2532 6.08339 6.32301 6.08339H8.71901C8.78881 6.08339 8.84627 6.10581 8.89106 6.15073C8.93593 6.19568 8.95853 6.25303 8.95853 6.32294V10.1563H9.67709C9.74708 10.1563 9.80452 10.1787 9.84938 10.2236C9.89407 10.2685 9.91668 10.3259 9.91668 10.3958V11.5938H9.91678V11.5938Z"
                fill="white"
              />
            </svg>
            <span
              >Above select fields Sum Insured is mandatory for self and dependent, always enable it. Sum Insured is
              only configurable for floater policies not individual.</span
            >
          </div>

          <h6>SELECT FIELDS</h6>
          <div class="row">
            <div class="col">
              <div class="table-grid">
                <table>
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Is Enabled</th>
                      <th>Is Visible For Self</th>
                      <th>Is Visible For Dependent</th>
                      <th>Is Mandatory For Self</th>
                      <th>Is Mandatory For Dependent</th>
                      <th>Is Editable For Self</th>
                      <th>Is Editable For Dependent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let field of memberFieldsMaster; let i = index">
                      <td>
                        <strong>{{ field.label }}</strong>
                      </td>
                      <td>
                        <div class="custom-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="field.isEnabled"
                              [disabled]="!field.isConfigurable"
                            />
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="custom-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="field.isVisibleForSelf"
                              [disabled]="!field.isConfigurable || !field.isEnabled"
                              [hidden]="!field.isEnabled"
                            />
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="custom-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="field.isVisibleForDependent"
                              [disabled]="!field.isConfigurable || !field.isEnabled"
                              [hidden]="!field.isEnabled"
                            />
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="custom-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="field.isMandatoryForSelf"
                              [disabled]="!field.isConfigurable || !field.isEnabled"
                              [hidden]="!field.isEnabled"
                            />
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="custom-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="field.isMandatoryForDependent"
                              [disabled]="!field.isConfigurable || !field.isEnabled"
                              [hidden]="!field.isEnabled"
                            />
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="custom-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="field.isEditableForSelf"
                              [disabled]="!field.isConfigurable || !field.isEnabled"
                              [hidden]="!field.isEnabled"
                            />
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="custom-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="field.isEditableForDependent"
                              [disabled]="!field.isConfigurable || !field.isEnabled"
                              [hidden]="!field.isEnabled"
                            />
                            <span></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="notification-msg">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="8" fill="white" />
              <rect x="5.5" y="3.25" width="4.5" height="9" fill="#FF7D37" />
              <path
                d="M12.979 5.11371C12.4648 4.2328 11.7674 3.53533 10.8864 3.02116C10.0053 2.50702 9.0435 2.25 8.00018 2.25C6.95697 2.25 5.99484 2.50702 5.11391 3.02116C4.23292 3.53525 3.53544 4.23272 3.02124 5.11371C2.50702 5.99475 2.25 6.95687 2.25 8C2.25 9.04318 2.5071 10.0051 3.02116 10.8862C3.53534 11.7671 4.23281 12.4646 5.1138 12.9788C5.99485 13.493 6.95689 13.75 8.00008 13.75C9.04326 13.75 10.0054 13.493 10.8864 12.9788C11.7673 12.4648 12.4648 11.7671 12.9789 10.8862C13.493 10.0052 13.75 9.04313 13.75 8C13.75 6.95677 13.493 5.99462 12.979 5.11371ZM7.04181 3.6874C7.04181 3.61749 7.06423 3.56014 7.1092 3.51524C7.1541 3.47037 7.21155 3.44785 7.28141 3.44785H8.71901C8.78881 3.44785 8.84627 3.47037 8.89106 3.51524C8.93593 3.56014 8.95853 3.61749 8.95853 3.6874V4.88535C8.95853 4.95521 8.93593 5.01264 8.89106 5.05749C8.84627 5.10238 8.78884 5.12483 8.71901 5.12483H7.28141C7.21155 5.12483 7.15412 5.10238 7.1092 5.05749C7.06423 5.01267 7.04181 4.95521 7.04181 4.88535V3.6874ZM9.91678 11.5938C9.91678 11.6636 9.89415 11.721 9.84938 11.7658C9.8047 11.8108 9.74724 11.8332 9.67728 11.8332H6.32301C6.25307 11.8332 6.19572 11.8108 6.1508 11.7658C6.1059 11.721 6.08346 11.6636 6.08346 11.5937V10.3957C6.08346 10.3257 6.1059 10.2685 6.1508 10.2235C6.19572 10.1787 6.25315 10.1561 6.32301 10.1561H7.04181V7.76037H6.32301C6.25307 7.76037 6.19575 7.73795 6.1508 7.69303C6.1059 7.64816 6.08346 7.59073 6.08346 7.52081V6.32294C6.08346 6.25303 6.1059 6.19568 6.1508 6.15073C6.19577 6.10581 6.2532 6.08339 6.32301 6.08339H8.71901C8.78881 6.08339 8.84627 6.10581 8.89106 6.15073C8.93593 6.19568 8.95853 6.25303 8.95853 6.32294V10.1563H9.67709C9.74708 10.1563 9.80452 10.1787 9.84938 10.2236C9.89407 10.2685 9.91668 10.3259 9.91668 10.3958V11.5938H9.91678V11.5938Z"
                fill="white"
              />
            </svg>
            <span
              >If Sum Insured is made Non-mandatory for Self and Dependents, always make IsEditable Enabled for Self and
              Dependents.</span
            >
          </div>
        </div>
      </div>

      <div class="enrollment-box-row" [class.complete]="currentStep > configurePasswordOption">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: configurePasswordOption,
            title: 'Select password option',
            btnTitle: 'Set Password Option'
          }"
        >
        </ng-container>

        <div class="box-body" [hidden]="currentStep !== configurePasswordOption">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Password option</label>
                <select [(ngModel)]="policy.passwordPolicy" class="form-control">
                  <option value="AutoGenerated">Auto-generated</option>
                  <option value="DateOfBirth">Date of Birth</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="enrollment-box-row" [class.complete]="currentStep > configureRelationships">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: configureRelationships,
            title: 'Configure relationship',
            btnTitle: 'Set Relationships'
          }"
        >
        </ng-container>

        <div class="box-body" [hidden]="currentStep !== configureRelationships">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Maximum members (self + dependents) allowed per family <sup>*</sup></label>
                <input [(ngModel)]="policy.maxNumOfMembersPerFamily" type="number" min="0" class="form-control" />
                <div class="errorMsg" [hidden]="!maxMembersPerFamilyInvalid">
                  Maximum members per family must be a number greater than zero
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h6>SELECT RELATIONSHIPS INCLUDED</h6>
              <div class="table-grid">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>is Enabled</th>
                      <th>Count</th>
                      <th>Min age limit</th>
                      <th>Max age limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let type of relationshipTypeMaster; let i = index">
                      <td>
                        <strong>{{ type.label }}</strong>
                      </td>
                      <td>
                        <strong>{{ type.type }}</strong>
                      </td>
                      <td>
                        <div class="custom-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              [(ngModel)]="type.isEnabled"
                              (ngModelChange)="relationshipSelectionChanged(type)"
                              [disabled]="!type.isConfigurable"
                            />
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <input
                          [(ngModel)]="type.count"
                          [disabled]="!type.isEnabled || !type.isConfigurable"
                          type="number"
                          min="1"
                          class="form-control small-input"
                        />
                        <span class="errorMsg" [hidden]="isCountValid[i]">
                          {{ isCountValidMsg[i] }}
                        </span>
                      </td>
                      <td>
                        <input
                          [(ngModel)]="type.minAgeLimit"
                          [disabled]="!type.isEnabled"
                          type="number"
                          min="{{ type.type === 'Children' ? 0 : 1 }}"
                          class="form-control small-input"
                        />
                        <span class="errorMsg" [hidden]="minAgeValid[i]">
                          {{ minAgeInvalidMsg[i] }}
                        </span>
                      </td>
                      <td>
                        <input
                          [(ngModel)]="type.maxAgeLimit"
                          [disabled]="!type.isEnabled"
                          type="number"
                          min="{{ type.minAgeLimit + 1 }}"
                          class="form-control small-input"
                        />
                        <span class="errorMsg" [hidden]="maxAgeValid[i]">
                          {{ maxAgeInvalidMsg[i] }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Maximum children allowed per family</label>
                <input
                  [(ngModel)]="policy.maxNumOfChildrenAllowed"
                  type="number"
                  min="0"
                  [disabled]="!areTwinsAllowed"
                  (input)="checkMaxNoOfChildrenCount($event)"
                  class="form-control"
                />
                <div class="errorMsg" [hidden]="isMaxChildrenValid">
                  {{ invalidMessage }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <br /><br />
                <div class="custom-checkbox">
                  <label>
                    <input
                      [(ngModel)]="policy.areTwinsAllowed"
                      type="checkbox"
                      [disabled]="!areTwinsAllowed"
                      class="custom-control-input"
                    />
                    <span>Are Twins Allowed</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [(ngModel)]="policy.parentInLawCondition"
                      type="checkbox"
                      [disabled]="!parentInLawConditionAllowed"
                      class="custom-control-input"
                    />
                    <span>Parent In Law Condition</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- <h6>PARENTAL SUM INSURED</h6>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [disabled]="!parentalSumInsuredOptionAvailable"
                      [(ngModel)]="policy.parentalSumInsured.isAllowed"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Allow parental sum insured</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <ng-container
            [ngTemplateOutlet]="sumOptionsTemplate"
            [ngTemplateOutletContext]="{ sumOption: policy.parentalSumInsured }"
          >
          </ng-container> -->
        </div>
      </div>

      <!-- <div class="enrollment-box-row" [class.complete]="currentStep > configureEnhancedSumInsured">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: configureEnhancedSumInsured,
            title: 'Configure enhanced sum insured',
            btnTitle: 'Set Enhanced Sum Insured'
          }"
        >
        </ng-container>

        <div class="box-body" [hidden]="currentStep !== configureEnhancedSumInsured">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [(ngModel)]="policy.enhancedSumInsured.isAllowed"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Allow enhanced sum insured</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <ng-container
            [ngTemplateOutlet]="sumOptionsTemplate"
            [ngTemplateOutletContext]="{ sumOption: policy.enhancedSumInsured }"
          >
          </ng-container>
        </div>
      </div>

      <div class="enrollment-box-row" [class.complete]="currentStep > configureTopups">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: configureTopups,
            title: 'Configure top-ups',
            btnTitle: 'Configure Top-ups'
          }"
        >
        </ng-container>

        <div class="box-body" [hidden]="currentStep !== configureTopups">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input [(ngModel)]="policy.topupOption.isAllowed" type="checkbox" class="custom-control-input" />
                    <span>Allow top-up option</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <ng-container
            [ngTemplateOutlet]="sumOptionsTemplate"
            [ngTemplateOutletContext]="{ sumOption: policy.topupOption }"
          >
          </ng-container>
        </div>
      </div> -->

      <!-- <div class="enrollment-box-row" [class.complete]="currentStep > configureEmails">
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{ step: configureEmails, title: 'Configure emails', btnTitle: 'Configure Emails' }"
        >
        </ng-container>

        <div class="box-body" [hidden]="currentStep !== configureEmails">
          <h6>WELCOME EMAIL</h6>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input [(ngModel)]="policy.sendWelcomeEmail" type="checkbox" class="custom-control-input" />
                    <span>Send welcome email to the employees</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" [hidden]="!policy.sendWelcomeEmail">
            <div class="col-md-6">
              <div class="form-group">
                <label>Welcome Email Send Date</label>

                <div class="date-time-wrapper">
                  <input
                    value="{{ policy.welcomeEmailDate | date: 'dd MMMM yyyy' }}"
                    [(ngModel)]="policy.welcomeEmailDate"
                    type="text"
                    [minDate]="welcomeEmailMinDate"
                    class="form-control"
                    readonly
                    bsDatepicker
                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                  />
                  <div class="timepicker-wrapper">
                    <select [(ngModel)]="policy.welcomeEmailHrs" type="number" class="form-control">
                      <option *ngFor="let hr of hrsOptions" [ngValue]="hr">{{ hr }}</option>
                    </select>
                    :
                    <select [(ngModel)]="policy.welcomeEmailMins" type="number" class="form-control">
                      <option *ngFor="let min of minsOptions" [ngValue]="min">{{ min }}</option>
                    </select>
                  </div>
                </div>
               // <p [hidden]="isEmailDateValid" class="errorMsg">Enter valid date and time</p> 
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>

          <div class="row" [hidden]="!policy.sendWelcomeEmail">
            <div class="col-md-12">
              <form [formGroup]="form">
                <div class="form-group">
                  <label>Welcome Email Subject <sup>*</sup> </label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="policy.welcomeEmailSubject"
                    formControlName="welcomeMessage"
                    (input)="isSubjectNameValid($event)"
                  />
                  <span
                    class="error-msg small"
                    *ngIf="
                      form.get('welcomeMessage').dirty &&
                      form.get('welcomeMessage').status === 'INVALID' &&
                      emailSubjectLength
                    "
                    >Invalid Subject
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                  <span class="error-msg small" *ngIf="!emailSubjectLength">
                    {{ subjectErrorMsg }}
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                </div>
              </form>
            </div>

            <div class="editorRightMargin">
              <div class="form-group">
                <label>Welcome Email Template <sup>*</sup></label>
                <editor
                  apiKey="65447p1khods6dtyql8kuu3wf9xhgw1hbekv2nj8xibdxfob"
                  initialValue="{{ policy.welcomeEmailTemplate }}"
                  [(ngModel)]="policy.welcomeEmailTemplate"
                  [init]="{
                    height: 340,
                    menubar: 'file table',
                    plugins: ' template  table advlist lists',
                    toolbar:
                      'undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist  | forecolor backcolor  permanentpen  removeformat '
                  }"
                >
                </editor>
              </div>
            </div>
          </div>

          // registration email div 

          <h6>REGISTRATION COMPLETED EMAIL</h6>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [(ngModel)]="policy.sendRegistrationConfirmationEmail"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Send registration completed email to the employees</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" [hidden]="!policy.sendRegistrationConfirmationEmail">
            <div class="col-md-6">
              <div class="form-group">
                <label>Registration Completed Email CC</label>
                <input type="text" class="form-control" [(ngModel)]="policy.registrationConfirmationEmailCcs" />
              </div>
              <p [hidden]="isCcEmailValid" class="errorMsg">{{ invalidEmailBccMsg }}</p>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Registration Completed Email BCC</label>
                <input type="text" class="form-control" [(ngModel)]="policy.registrationConfirmationEmailBccs" />
              </div>
            </div>

            <div class="col-md-12">
              <form [formGroup]="form">
                <div class="form-group">
                  <label>Registration Completed Email Subject<sup>*</sup></label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="message"
                    [(ngModel)]="policy.registrationConfirmationEmailSubject"
                    (input)="isConfirmationSubjectValid($event)"
                  />
                  <span
                    class="error-msg small"
                    *ngIf="
                      form.get('message').dirty &&
                      form.get('message').status === 'INVALID' &&
                      confirmationEmailSubjectLength
                    "
                    >Invalid Subject
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                  <span class="error-msg small" *ngIf="!confirmationEmailSubjectLength">
                    {{ confirmationSubjectErrorMsg }}
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div class="row" [hidden]="!policy.sendRegistrationConfirmationEmail">
            <div class="editorRightMargin">
              <div class="form-group">
                <label>Registration Completed Email template <sup>*</sup></label>
                <editor
                  apiKey="65447p1khods6dtyql8kuu3wf9xhgw1hbekv2nj8xibdxfob"
                  initialValue="{{ policy.registrationConfirmationEmailTemplate }}"
                  [(ngModel)]="policy.registrationConfirmationEmailTemplate"
                  [init]="{
                    height: 340,
                    menubar: 'file table',
                    plugins: ' template  table advlist lists',
                    toolbar:
                      'undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist  | forecolor backcolor  permanentpen  removeformat '
                  }"
                >
                </editor>
              </div>
            </div>
          </div>

          <h6>REMINDER EMAIL</h6>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input [(ngModel)]="policy.sendReminderEmail" type="checkbox" class="custom-control-input" />
                    <span>Send reminder email to the employees</span>
                  </label>
                </div>
                <div class="date-time-wrapper" [hidden]="!policy.sendReminderEmail">
                  <input
                    value="{{ policy.reminderEmailDate | date: 'dd MMMM yyyy' }}"
                    [(ngModel)]="policy.reminderEmailDate"
                    type="text"
                    [minDate]="reminderEmailMinDate"
                    class="form-control"
                    readonly
                    bsDatepicker
                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                  />
                  <div class="timepicker-wrapper">
                    <select [(ngModel)]="policy.reminderEmailHrs" type="number" class="form-control">
                      <option *ngFor="let hr of hrsOptions" [ngValue]="hr">{{ hr }}</option>
                    </select>
                    :
                    <select [(ngModel)]="policy.reminderEmailMin" type="number" class="form-control">
                      <option *ngFor="let min of reminderMinsOptions" [ngValue]="min">{{ min }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" [hidden]="!policy.sendReminderEmail">
            <div class="col-md-12">
              <form [formGroup]="form">
                <div class="form-group">
                  <label>Reminder Email Subject <sup>*</sup> </label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="policy.reminderEmailSubject"
                    formControlName="reminderMessage"
                    (input)="isReminderSubjectValid($event)"
                  />
                  <span
                    class="error-msg small"
                    *ngIf="
                      form.get('reminderMessage').dirty &&
                      form.get('reminderMessage').status === 'INVALID' &&
                      reminderEmailSubjectLength
                    "
                    >Invalid Subject
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                  <span class="error-msg small" *ngIf="!reminderEmailSubjectLength">
                    {{ reminderEmailSubjectErrorMsg }}
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </span>
                </div>
              </form>
            </div>
            <div class="editorRightMargin">
              <div class="form-group">
                <label>Reminder Email template <sup>*</sup></label>
                <editor
                  apiKey="65447p1khods6dtyql8kuu3wf9xhgw1hbekv2nj8xibdxfob"
                  initialValue="{{ policy.reminderEmailTemplate }}"
                  [(ngModel)]="policy.reminderEmailTemplate"
                  [init]="{
                    height: 340,
                    menubar: 'file table',
                    plugins: ' template  table advlist lists',
                    toolbar:
                      'undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist  | forecolor backcolor  permanentpen  removeformat '
                  }"
                >
                </editor>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="enrollment-box-row"
        [class.complete]="currentStep > configurePremiumCalculator"
        [hidden]="!policy.parentalSumInsured.isAllowed"
      >
        <ng-container
          [ngTemplateOutlet]="enrollmentBoxHeaderTemplate"
          [ngTemplateOutletContext]="{
            step: configurePremiumCalculator,
            title: 'Configure Premium',
            btnTitle: 'Set Premium'
          }"
        >
        </ng-container>

        <div class="box-body" [hidden]="currentStep !== configurePremiumCalculator">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [disabled]="!policy.parentalSumInsured.isAllowed"
                      [(ngModel)]="policy.premiumCalculator.isAllowed"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Allow Premium Calculator</span>
                  </label>
                </div>
              </div>
            </div>

            <ng-container
              [ngTemplateOutlet]="pmcTemplate"
              [ngTemplateOutletContext]="{ premiumOptions: policy.premiumCalculator }"
            >
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="pmcTemplateForInLaws"
              [ngTemplateOutletContext]="{ premiumOptions: policy.premiumCalculator }"
            >
            </ng-container>
          </div>
          <div class="row">
            <div class="col-12 ">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [disabled]="!policy.enhancedSumInsured.isAllowed"
                      [(ngModel)]="policy.esiPremiumCalculator.isAllowed"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Allow esi Premium Calculator</span>
                  </label>
                </div>
              </div>
            </div>
            <ng-container
              [ngTemplateOutlet]="pmcTemplate"
              [ngTemplateOutletContext]="{ premiumOptions: policy.esiPremiumCalculator }"
            >
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="pmcTemplateForInLaws"
              [ngTemplateOutletContext]="{ premiumOptions: policy.esiPremiumCalculator }"
            >
            </ng-container>
          </div>
          <div class="row">
            <div class="col-12 ">
              <div class="form-group">
                <div class="custom-checkbox">
                  <label>
                    <input
                      [disabled]="!policy.topupOption.isAllowed"
                      [(ngModel)]="policy.topUpPremiumCalculator.isAllowed"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <span>Allow Topup Premium Calculator</span>
                  </label>
                </div>
              </div>
            </div>
            <ng-container
              [ngTemplateOutlet]="pmcTemplate"
              [ngTemplateOutletContext]="{ premiumOptions: policy.topUpPremiumCalculator }"
            >
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="pmcTemplateForInLaws"
              [ngTemplateOutletContext]="{ premiumOptions: policy.topUpPremiumCalculator }"
            >
            </ng-container>
          </div>
        </div> 
      </div> -->
    </div>
  </div>
  <div class="save-container" [hidden]="!allStepsCompleted">
    <div class="container">
      <button (click)="submit()" [disabled]="!isSaveButtonEnable" class="btn btn-secondary">{{ displaySave }}</button>
    </div>
  </div>

  <ng-template #enrollmentBoxHeaderTemplate let-step="step" let-title="title" let-btnTitle="btnTitle">
    <div class="box-header">
      <div class="step-count">
        <span class="number">{{ step }}</span>
        <span class="check-icon"
          ><svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.27 2.31726L5.11415 10.4732L0.730469 6.09L2.05156 4.76876L5.11407 7.8309L11.9489 0.996094L13.27 2.31726Z"
              fill="#00C389"
            />
          </svg>
        </span>
      </div>

      <div class="row-header-description">
        <p>
          <b>{{ title }}</b>
        </p>
      </div>
      <div class="row-header-actions">
        <button
          (click)="completeStep(step)"
          [ngClass]="{
            'btn-active': currentStep === step,
            'btn-selected': currentStep > step,
            'btn-inactive': currentStep < step
          }"
          class="btn"
        >
          {{ btnTitle }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #sumOptionsTemplate let-sumOption="sumOption">
    <div class="row" [hidden]="!sumOption.isAllowed">
      <div class="col-md-6">
        <div class="form-group">
          <label>Plan</label>
          <select [(ngModel)]="sumOption.plan" class="form-control">
            <option value="Floater">Floater</option>
            <option value="Individual">Individual</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Type</label>
          <select [(ngModel)]="sumOption.type" class="form-control">
            <option value="EqualToSumInsured">Equal to Sum Insured</option>
            <option value="Defined">Defined</option>
            <option value="GradeWiseMultiples">Grade Wise Multiples</option>
            <option value="GradeWiseDefined">Grade Wise Defined</option>
          </select>
        </div>
      </div>
    </div>
    <div class="table-grid" [hidden]="sumOption.type === 'EqualToSumInsured' || !sumOption.isAllowed">
      <table>
        <thead>
          <tr>
            <th [hidden]="sumOption.type != 'GradeWiseDefined' && sumOption.type != 'GradeWiseMultiples'">Grades</th>
            <th [hidden]="sumOption.type != 'GradeWiseMultiples'">Multiples</th>
            <th [hidden]="sumOption.type != 'GradeWiseDefined' && sumOption.type != 'Defined'">
              Sums
            </th>
          </tr>
          <span class="errorMsg" [hidden]="!sumOption.isValueRepeated">&nbsp;Sums cannot be Repeated</span>
          <span class="errorMsg" [hidden]="!sumOption.isGradeWiseMultipleRepeated"
            >&nbsp;Same grades cannot have similar Multiples</span
          >
          <span class="errorMsg" [hidden]="!sumOption.isGradeWiseDefinedRepeated"
            >&nbsp;Same grades cannot have similar values</span
          >
        </thead>
        <tbody>
          <tr *ngFor="let insuredValue of sumOption.values; let i = index">
            <td [hidden]="sumOption.type != 'GradeWiseDefined' && sumOption.type != 'GradeWiseMultiples'">
              <input [(ngModel)]="insuredValue.grade" type="text" class="form-control small-input w-50" />
              <span class="errorMsg" [hidden]="!insuredValue.isGradeInvalid">&nbsp;Invalid Grade</span>
            </td>
            <td [hidden]="sumOption.type != 'GradeWiseMultiples'">
              <input [(ngModel)]="insuredValue.multiple" type="number" class="form-control small-input w-50" />
              <span class="errorMsg" [hidden]="!insuredValue.isMultipleInvalid">&nbsp;Invalid Multiple</span>
            </td>
            <td [hidden]="sumOption.type != 'GradeWiseDefined' && sumOption.type != 'Defined'">
              <input
                [ngModel]="insuredValue.stringValue | numberFormat"
                (ngModelChange)="insuredValue.stringValue = $event"
                type="text"
                class="form-control small-input w-50"
              />
              <span class="errorMsg" [hidden]="!insuredValue.isValueInvalid">&nbsp;Invalid Sum</span>
            </td>
            <td>
              <a
                [hidden]="sumOption.values.length === 1"
                (click)="sumOption.removeEntry(i)"
                class="float-right text-secondary"
                ><i class="material-icons">delete</i></a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <button (click)="sumOption.addNewEntry()" class="add-new-row-btn">
        <i class="material-icons">add</i> <span>Add new</span>
      </button>
    </div>
    <div class="row" [hidden]="!sumOption.isAllowed">
      <div class="col-md-12">
        <div class="form-group">
          <label>Instructions</label>
          <input [(ngModel)]="sumOption.instructions" type="text" class="form-control" />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #pmcTemplate let-premiumOptions="premiumOptions">
    <div class="row ml-3" [hidden]="!premiumOptions.isAllowed">
      <h5>{{ premiumOptions.pSIPremiumname }}</h5>
    </div>
    <div class="row" [hidden]="!premiumOptions.isAllowed">
      <div class="col">
        <div class="table-grid">
          <table>
            <thead>
              <tr>
                <th>Parental SI</th>
                <th>Allow Premium</th>
                <th>Single Valid</th>
                <th>Single Parent</th>
                <th>Double Valid</th>
                <th>Double Parent</th>
                <th>Discount</th>
                <th>GST</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let field of premiumOptions; let i = index">
                <td>
                  <strong>{{ field.value }}</strong>
                </td>
                <td>
                  <div class="custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="field.isPremiumAllowedForParents"
                        id="inlineCheckbox{{ i }}"
                        (change)="checkWellnessValue(field)"
                      />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="inlineCheckbox{{ i }}"
                        [(ngModel)]="field.isSingleValidForParents"
                        [disabled]="!field.isPremiumAllowedForParents"
                      />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      type="number"
                      class="form-control small-input"
                      [(ngModel)]="field.singleParentPremium"
                      [disabled]="!field.isPremiumAllowedForParents"
                      (ngModelChange)="checkWellnessValue(field.singleParentPremium)"
                    />
                  </div>
                </td>
                <td>
                  <div class="custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="field.isDoubleValidForParents"
                        [disabled]="!field.isPremiumAllowedForParents"
                      />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      type="number"
                      min="1"
                      class="form-control small-input"
                      [(ngModel)]="field.doubleParentPremium"
                      [disabled]="!field.isPremiumAllowedForParents"
                    />
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      type="number"
                      min="1"
                      class="form-control small-input"
                      [(ngModel)]="field.discount"
                      [disabled]="!field.isPremiumAllowedForParents"
                    />
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      type="number"
                      min="1"
                      class="form-control small-input"
                      [(ngModel)]="field.gst"
                      [disabled]="!field.isPremiumAllowedForParents"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #pmcTemplateForInLaws let-premiumOptions="premiumOptions">
    <div class="row ml-3" [hidden]="!premiumOptions.isAllowed">
      <h5>{{ premiumOptions.pSILawPremiumname }}</h5>
    </div>
    <div class="row" [hidden]="!premiumOptions.isAllowed">
      <div class="col">
        <div class="table-grid">
          <table>
            <thead>
              <tr>
                <th>Parental SI</th>
                <th>Allow Premium</th>
                <th>Single Valid</th>
                <th>Single Parent Premium</th>
                <th>Double Valid</th>
                <th>Double Parent Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let field of premiumOptions; let i = index">
                <td>
                  <strong>{{ field.value }}</strong>
                </td>
                <td>
                  <div class="custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="field.isPremiumAllowedForParentsInLaw"
                        id="inlineCheckbox{{ i }}"
                        (change)="checkWellnessValue(field)"
                      />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="inlineCheckbox{{ i }}"
                        [(ngModel)]="field.isSingleValidForParentsInLaw"
                        [disabled]="!field.isPremiumAllowedForParentsInLaw"
                      />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      type="number"
                      class="form-control small-input"
                      [(ngModel)]="field.singleParentInLawPremium"
                      [disabled]="!field.isPremiumAllowedForParentsInLaw"
                    />
                  </div>
                </td>
                <td>
                  <div class="custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="field.isDoubleValidForParentsInLaw"
                        [disabled]="!field.isPremiumAllowedForParentsInLaw"
                      />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div>
                    <input
                      type="number"
                      min="1"
                      class="form-control small-input"
                      [(ngModel)]="field.doubleParentInLawPremium"
                      [disabled]="!field.isPremiumAllowedForParentsInLaw"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-template>
</div>
