export class SubmitFinalDeclaration {
  claimantName: string;
  claimNo: number;
  uhid: string;
  insuredName: string;
  mobileNumber: string;
  emailID: string;
  patientName: string;
  createdDate: string;
  age: number;
  isDeclarationAccepted: boolean;
}
