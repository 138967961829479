import { Component, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { NotificationService } from 'src/app/business-admin/services/notification.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMsgDialog } from 'src/app/shared/components/error-msg-dialog/ErrorMsgDialog';
import { ErrorMsgDialogComponent } from 'src/app/shared/components/error-msg-dialog/error-msg-dialog.component';
import { Router } from '@angular/router';
import { Endorsement } from '../../models/endorsement/endorsement.model';
import { Notification } from 'src/app/shared/models/notification/notification.model';
import { EndorsementService } from '../../services/endorsement.service';
import { ApproveReject } from '../../models/approve-reject.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-approve-reject',
  templateUrl: './approve-reject.component.html',
  styleUrls: ['./approve-reject.component.scss'],
})
export class ApproveRejectComponent extends BaseComponent implements OnInit {
  @Input() notification: Notification = new Notification();
  @Input() endorsement: Endorsement = new Endorsement();
  @Input() type = 'notification';
  @Input() policyId;
  @Output() approveRejectSuccefully = new EventEmitter<boolean>();
  isLoading = false;
  remark = '';
  approveOrReject: string;
  errorMsg = [];
  invalidRemark = false;
  invalidRemarkMsg = '';

  constructor(
    injector: Injector,
    private readonly notificationService: NotificationService,
    private readonly endorsementService: EndorsementService,
    private readonly alertService: AlertService,
    private modalService: NgbModal,
    private readonly router: Router
  ) {
    super(injector);
  }

  ngOnInit(): void {}

  async approveOrRejectFunc(id) {
    this.modalService.dismissAll(ApproveRejectComponent);
    let result;
    const message = `Are you sure you want to ` + this.approveOrReject + `?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      const data = new ApproveReject();
      data.remark = this.remark;
      if (result) {
        if (this.type === 'notification') {
          this.notificationFunc(result, data);
        }
        if (this.type === 'endorsement') {
          this.endorsementFunc(result, data);
        }
        if (this.type === 'BulkEndorsement') {
          this.bukEndorsementFunc(result, data);
        }
      }
    });
  }

  async notificationFunc(result, data) {
    if (result) {
      if (this.approveOrReject === 'approve') {
        try {
          this.isLoading = true;
          await this.notificationService
            .approveNotification(this.notification.id, data)
            .then(async x => {
              this.approveRejectSuccefully.emit(true);
              this.alertService.success('Notification approved');
              this.router.navigate(['/biz-admin/notification/list']);
            })
            .catch(errorData => {
              const errorList = errorData.error.errors;
              this.openErrorModal(errorList);
              // this.alertService.success('Error Occured');
            });
          this.isLoading = false;
        } catch (error) {
          this.alertService.error('Error occured');
          this.isLoading = false;
        }
      } else if (this.approveOrReject === 'reject') {
        try {
          this.isLoading = true;
          await this.notificationService
            .rejectNotification(this.notification.id, data)
            .then(async x => {
              this.alertService.success('Notification rejected');
              this.approveRejectSuccefully.emit(true);
              this.router.navigate(['/biz-admin/notification/list']);
            })
            .catch(errorData => {
              const errorList = errorData.error.errors;
              this.openErrorModal(errorList);
              // this.alertService.success('Error Occured');
            });
          this.isLoading = false;
        } catch (error) {
          this.alertService.error('Error occured');
          this.isLoading = false;
        }
      }
    }
  }

  async endorsementFunc(result, data) {
    if (result) {
      if (this.approveOrReject === 'approve') {
        data.isApproved = true;
        data.isRejected = false;
      } else if (this.approveOrReject === 'reject') {
        data.isRejected = true;
        data.isApproved = false;
      }
      this.isLoading = true;
      await this.endorsementService
        .approveRejectEndorsement(this.endorsement.id, data)
        .then(async x => {
          this.approveRejectSuccefully.emit(true);
          this.alertService.success('Endorsement status updated.');
        })
        .catch(errorData => {
          const errorList = errorData.error.errors;
          this.openErrorModal(errorList);
          // this.alertService.success('Error Occured');
        });
      this.isLoading = false;
    }
  }

  async bukEndorsementFunc(result, data) {
    if (result) {
      if (this.approveOrReject === 'approve') {
        data.isApproved = true;
        data.isRejected = false;
      } else if (this.approveOrReject === 'reject') {
        data.isRejected = true;
        data.isApproved = false;
      }
      this.isLoading = true;
      await this.endorsementService
        .bulkApproveRejectEndorsement(this.policyId, data)
        .then(async x => {
          this.approveRejectSuccefully.emit(true);
          this.alertService.success('Endorsement status updated.');
        })
        .catch(errorData => {
          const errorList = errorData.error.errors;
          this.openErrorModal(errorList);
        });
      this.isLoading = false;
    }
  }

  close() {
    this.modalService.dismissAll(ApproveRejectComponent);
  }

  openErrorModal(errorList) {
    this.errorMsg = [];
    errorList.forEach(errorDescription => {
      this.errorMsg.push(errorDescription.message);
    });

    const dialogData = new ErrorMsgDialog(this.errorMsg);

    this.dialog.open(ErrorMsgDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  validateRemark() {
    if (this.remark === '') {
      this.invalidRemark = false;
      this.invalidRemarkMsg = '';
    } else {
      if (/^[^\s]+(\s+[^\s]+)*$/.test(this.remark)) {
        this.invalidRemark = false;
        this.invalidRemarkMsg = '';
      } else {
        this.invalidRemark = true;
        this.invalidRemarkMsg = 'Enter valid remark';
      }
    }
  }
}
