export class SelfMember {
  insuredName: string;
  dob: string;
  age: number;
  sex: string;
  uhid: string;
  mobileNo: string;
  emailID: string;
  address1: string;
  doj: string;
  relationShipId: string;
  topUpSI: number;
  eliteStatus: string;
  balanceSI: number;
  grade: string;
  bankName: string;
  accountNumber: string;
  bankBranckAddress: string;
  ifscCodeoftheBank: string;
  panNoOfTheInsured: string;
  micrNo: string;
  policyNo: string;
  isSelfCovered: string;
  memberIdEmpId: string;
  memberSelected: boolean;
  insuredABHAId: string = '';
  insuredABHAAddress: string =  '';
}
