import { Component, OnInit, Input, OnDestroy, Injector, SimpleChanges, OnChanges } from '@angular/core';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Router } from '@angular/router';
import { CorporatePortalService } from 'src/app/business-admin/services/corporate-portal.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PolicyMemberFeatures } from 'src/app/shared/models/CorporatePortal/policy-member-features.model';
import { Guid } from 'src/app/shared/enums/guid.enum';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() corporate: CorporateModel;
  @Input() policyNumber: any;
  policy: any;
  features: PolicyMemberFeatures;
  isFeaturesCreated: boolean;
  featureId: number;
  noFeatureAdded: boolean;
  isEditFeature = false;
  errorList = [];
  disableSaveButton: boolean;

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly corporatePortalService: CorporatePortalService,
    private readonly alertService: AlertService
  ) {
    super(injector);
  }

  async ngOnInit() {
    if (this.policyNumber !== 'Attach Policy') {
      this.getMemberFeatures();
    }
  }

  assignValueToFeatures() {
    this.features.hra = false;
    this.features.intimateClaims = false;
    this.features.networkHospitalTracker = false;
    this.features.wellness = false;
    this.features.rHealthAssist = false;
    this.features.rHealthBeat = false;
    this.features.rHealthCircle = false;
    this.features.trackClaims = false;
    this.features.valueDeals = false;
    this.features.viewECard = false;
    this.features.viewPolicyCoverage = false;
    this.features.faq = false;
    this.features.contactUs = false;
    this.features.healthOPedia = false;
    this.features.downloadForms = false;
    this.features.policyId = Guid.Default;
  }

  async save() {
    this.disableSaveButton = true;
    this.features.policyId = this.policy.id;
    console.log(this.features);
    if (this.isFeaturesCreated) {
      this.corporatePortalService
        .updatePolicyMemberFeature(this.policy.id, this.featureId, this.features)
        .then(x => {
          this.alertService.success('Features updated successfully');
          this.disableSaveButton = false;
          this.getMemberFeatures();
        })
        .catch(errorData => {
          this.errorList = errorData.error.errors;
          this.disableSaveButton = false;
          this.alertService.error('Error occured');
        });
    } else {
      this.features['id'] = Guid.Default;
      this.corporatePortalService
        .addPolicyMemberFeature(this.policy.id, this.features)
        .then(x => {
          this.alertService.success('Features added successfully');
          this.disableSaveButton = false;
          this.getMemberFeatures();
          this.isFeaturesCreated = true;
        })
        .catch(errorData => {
          this.errorList = errorData.error.errors;
          this.disableSaveButton = false;
          this.alertService.error('Error occured');
        });
    }
  }

  checkWellnessValue() {
    if (this.features.wellness === false) {
      this.disableSaveButton = false;
      this.features.rHealthAssist = false;
      this.features.rHealthBeat = false;
      this.features.rHealthCircle = false;
      this.features.valueDeals = false;
      this.features.healthOPedia = false;
      this.features.hra = false;
    } else if (
      this.features.wellness === true &&
      this.features.rHealthAssist === false &&
      this.features.rHealthBeat === false &&
      this.features.rHealthCircle === false &&
      this.features.valueDeals === false &&
      this.features.healthOPedia === false &&
      this.features.hra === false
    ) {
      this.disableSaveButton = true;
    } else {
      this.disableSaveButton = false;
    }
  }
  async getMemberFeatures() {
    this.errorList = [];
    this.disableSaveButton = false;
    const featuresList = await this.corporatePortalService.getPolicyMemberFeatures(this.policy.id);
    if (featuresList) {
      this.noFeatureAdded = false;
      this.isEditFeature = false;
      this.isFeaturesCreated = true;
      this.features = featuresList;
      this.featureId = featuresList.id;
    } else {
      this.noFeatureAdded = true;
      this.isEditFeature = true;
      this.features = new PolicyMemberFeatures();
      this.isFeaturesCreated = false;
      this.assignValueToFeatures();
    }
  }

  cancel() {
    this.getMemberFeatures();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.corporatePortalService.updatedPolicies.subscribe((policyList: any[]) => {
      this.policy = policyList.find(policy => policy.policyNumber === this.policyNumber);
    });
    if (changes['policyNumber'] !== undefined && changes['corporate'] === undefined) {
      if (this.policyNumber !== 'Attach Policy') {
        this.getMemberFeatures();
      }
    }
  }
}
