import {
  Component,
  OnInit,
  OnDestroy,
  Injector,
  Input,
  OnChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { BaseComponent } from '../../../../shared/components/base.component';
import { CorporateModel } from '../../../../shared/models/corporate/corporate.model';
import { PreEnrollmentPolicyModel } from '../../../../shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PreEnrollmentMemberModel } from '../../../../shared/models/preEnrollment/members/preEnrollment-member.model';
import { PreEnrollmentService } from 'src/app/business-admin/services/preEnrollment.service';
import { AlertService } from '../../../../shared/services/alert.service';
import * as FileSaver from 'file-saver';
import { RoleService } from 'src/app/shared/services/role.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ErrorMsgDialog } from 'src/app/shared/components/error-msg-dialog/ErrorMsgDialog';
import { ErrorMsgDialogComponent } from 'src/app/shared/components/error-msg-dialog/error-msg-dialog.component';
import { ShowJobStatusComponent } from 'src/app/shared/components/show-job-status/show-job-status.component';
import { JobStatusDialog } from 'src/app/shared/components/show-job-status/job-status-dialog.model';
import { MemberUploadFilesModel } from 'src/app/shared/components/show-member-upload-files/member-upload-files-model.model';
import { ShowMemberUploadFilesComponent } from 'src/app/shared/components/show-member-upload-files/show-member-upload-files.component';
@Component({
  selector: 'app-pre-enrollment-members',
  templateUrl: './pre-enrollment-members.component.html',
  styleUrls: ['./pre-enrollment-members.component.scss'],
})
export class PreEnrollmentMembersComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() corporate: CorporateModel;
  @Input() policy: PreEnrollmentPolicyModel;
  isLoading = false;
  isUploading = false;
  searchText: string;
  memberPropertyName = 'insuredName';
  members: PreEnrollmentMemberModel[] = [];
  membersCopy: PreEnrollmentMemberModel[] = [];
  userRole: string;
  isRoleHR = true;

  file: any;
  form: FormGroup;
  error = [];
  errorMsg: string[] = [];

  dependentModuleVisibilityTracker: {};

  fileSelected = false;
  skip = 0;
  take = 50;
  totalMemberCount: any;
  isSearchingMember = false;
  totalMemberSearchCount: number;
  page = 1;
  pageCopy = 1;
  pageSize = 50;
  pageSizeCopy = 50;
  isSearchMemberFound = true;
  isShowUnlock = false;
  msg = '';
  fileNameInUi = '';
  unlockMembersProcessing = false;
  showJobStatus = false;

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private readonly preenrollmentService: PreEnrollmentService,
    private readonly roleService: RoleService,
    private readonly alertService: AlertService
  ) {
    super(injector);
  }

  async ngOnChanges(): Promise<void> {
    this.isLoading = true;
    this.showJobStatus = false;
    if (this.policy !== undefined) {
      this.members = [];
      const totalMemberCountObject = await this.preenrollmentService.getTotalMembersCount(this.policy.id);
      this.totalMemberCount = JSON.parse(JSON.stringify(totalMemberCountObject)).totalSelfCount;
      this.page = 1;
      this.getPaginatedMembers(0, this.take);
    }
    this.isLoading = false;
  }

  async ngOnInit() {
    super.ngOnInit();
    this.isLoading = true;
    this.userRole = await this.roleService.getMemberRole();
    this.isRoleHR = this.userRole === 'HR';
    this.form = this.formBuilder.group({
      avatar: [''],
    });
    this.isLoading = false;
  }

  toggleDependentsViewVisibility(member) {
    if (member.dependentCount === 0) {
      return;
    }

    this.dependentModuleVisibilityTracker[member.id] = !this.dependentModuleVisibilityTracker[member.id];
  }

  async getJobStatus() {
    await this.preenrollmentService.getJobStatus(this.policy.id, this.corporate.id).then(res => {
      const jobStatus = res.status;
      const url = res.url;
      const policyId = this.policy.id;
      const statusId = res.id;
      const dialogData = new JobStatusDialog(jobStatus, url, policyId, statusId);
      const dialogRef = this.dialog.open(ShowJobStatusComponent, {
        data: dialogData,
        disableClose: true,
      });
    });
  }

  showFiles() {
    this.preenrollmentService.GetJobs(this.policy.id).then(res => {
      const dialogData = new MemberUploadFilesModel(res);
      this.dialog.open(ShowMemberUploadFilesComponent, {
        data: dialogData,
        disableClose: true,
      });
    });
  }

  async deleteMember(member) {
    const id = member.id;
    const name = member.insuredName;
    const empId = member.employeeId;
    let result;
    const message = `Are you sure you want to delete ` + name + ' (Employee ID - ' + empId + ')?';

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          this.isLoading = true;
          await this.preenrollmentService.deleteMember(this.policy.id, id);
          this.ngOnChanges();
          this.alertService.success(name + ' (Employee ID - ' + empId + ') deleted successfully');
        } catch (error) {
          this.alertService.error('Error occured');
        }
      }
    });
  }

  fileSelectedChanged(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.fileNameInUi = this.file.name;
      if (this.fileNameInUi.length > 30) {
        this.fileNameInUi = this.fileNameInUi.slice(0, 40) + '...';
      }
      this.form.get('avatar').setValue(this.file);
      this.fileSelected = true;
    }
  }

  cancelFileUpload() {
    this.file = null;
    // this.form.get('avatar').setValue(this.file);
    this.fileSelected = false;
    this.error = [];
    this.fileNameInUi = '';
  }

  async uploadFile() {
    const formData = new FormData();
    formData.append('file', this.form.get('avatar').value);

    this.isUploading = true;
    await this.preenrollmentService
      .createMembers(this.policy.id, formData)
      .then(async () => {
        this.alertService.success('You will receive a mail with uploaded members details shortly');
        this.isUploading = false;
        this.showJobStatus = true;
        this.cancelFileUpload();
      })
      .catch(error => {
        this.error = error.error.errors;
        this.isUploading = false;
      });
  }

  async downloadMemberFile() {
    this.preenrollmentService.downloadMemberFile(this.corporate.code, this.policy.id).then(
      result => {
        FileSaver.saveAs(result, `member` + this.policy.id + `.xlsx`);
      },
      error => {}
    );
  }

  async resetPeCredentials() {
    let result;
    const message = `Do you want to resend credentials to all the employees?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        await this.preenrollmentService
          .resetCredentialsByPolicyId(this.policy.id)
          .then(x => {
            console.log(x);
            this.alertService.success('Password reset successfully');
          })
          .catch(errorData => {
            const errorList = errorData.error.errors;
            this.openErrorModal(errorList);
          });
      }
    });
  }

  async resendMemberCredentials(member) {
    const memberId = member.id;
    let result;
    const message = `Are you sure you want to reset and send password?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        try {
          const emailSent = await this.preenrollmentService.resetCredentialsByMemberId(this.policy.id, memberId);
          this.alertService.success('Password reset successfully');
        } catch (errorData) {
          const errorList = errorData.error.errors;
          this.openErrorModal(errorList);
        }
      }
    });
  }

  openErrorModal(errorList) {
    this.errorMsg = [];
    errorList.forEach(errorDescription => {
      this.errorMsg.push(errorDescription.message);
    });

    const dialogData = new ErrorMsgDialog(this.errorMsg);

    this.dialog.open(ErrorMsgDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  async unlockAllMembers() {
    let result;
    const message = `Are you sure you want to unlock all members`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message, 'Cancel', 'Proceed');

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        this.unlockMembersProcessing = true;
        await this.preenrollmentService
          .unlockAllMembers(this.policy.id)
          .then(async () => {
            this.members = await this.preenrollmentService.getMembers(this.policy.id, this.skip, this.take);
            this.alertService.success('Members unlocked');
          })
          .catch(error => {
            this.alertService.error('Error occured');
          })
          .finally(() => {
            this.unlockMembersProcessing = false;
          });
      }
    });
  }

  async unlockMember(memberId) {
    let result;
    const message = `The employee will be able to edit his details provided there is active prenrollment period`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message, 'Cancel', 'Proceed');

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      result = dialogResult;
      if (result) {
        await this.preenrollmentService
          .unlockMember(this.policy.id, memberId)
          .then(async () => {
            this.members = await this.preenrollmentService.getMembers(this.policy.id, this.skip, this.take);
            this.alertService.success('Member unlocked');
          })
          .catch(error => {
            this.alertService.error('Error occured');
          });
      }
    });
  }
  async searchingMember(event) {
    const searchValue = event.target.value;
    if (searchValue === '' || searchValue === null || searchValue === undefined) {
      this.isLoading = true;
      this.isSearchingMember = false;
      this.isSearchMemberFound = true;
      this.getPaginatedMembers(0, this.take);
      this.page = 1;
      this.isLoading = false;
    } else {
      this.isLoading = true;
      this.isSearchingMember = true;
      const empId = event.target.value;
      this.membersCopy = await this.preenrollmentService.searchMembers(this.policy.id, empId);
      if (this.membersCopy.length === 0) {
        this.isSearchMemberFound = false;
      } else {
        this.isSearchMemberFound = true;
      }
      this.totalMemberSearchCount = this.membersCopy.length;
      const size = 50;
      this.members = [];
      this.membersCopy.slice(0, size).map(i => {
        this.members.push(i);
      });
      this.pageCopy = 1;
      this.isLoading = false;
    }
  }

  loadSearchPage(event) {
    const pageNum = event;
    const size = 50;
    this.members = [];
    this.membersCopy.slice((pageNum - 1) * size, pageNum * size).map(i => {
      this.members.push(i);
    });
  }
  async loadPage(event) {
    const pageNum = event;
    this.skip = (pageNum - 1) * this.take;
    this.getPaginatedMembers(this.skip, this.take);
  }
  async getPaginatedMembers(skip, take) {
    this.cancelFileUpload();
    this.isLoading = true;
    this.members = await this.preenrollmentService.getMembers(this.policy.id, skip, take);
    if (!this.members || this.members.length <= 0) {
      this.msg = 'No members uploaded yet';
    } else {
      this.msg = '';
    }
    this.dependentModuleVisibilityTracker = {};
    for (let index = 0; index < this.members.length; index++) {
      this.dependentModuleVisibilityTracker[this.members[index].id] = false;
    }
    this.isLoading = false;
  }

  isShowUnlockButton(member) {
    if (this.isRoleHR) {
      if (this.policy.allowHRResetEmployeeDetails) {
        if (member.isFinalSubmissionLocked) {
          this.isShowUnlock = true;
        } else {
          this.isShowUnlock = false;
        }
      } else {
        this.isShowUnlock = false;
      }
    } else {
      if (member.isFinalSubmissionLocked) {
        this.isShowUnlock = true;
      } else {
        this.isShowUnlock = false;
      }
    }
  }
}
