import { Component, OnInit } from '@angular/core';
import { FieldConfig } from '../../model/form-fields';
import { FormGroup } from '@angular/forms';
import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { PreEnrollmentMemberService } from 'src/app/member/services/preEnrollment-member.service';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import * as moment from 'moment';

@Component({
  selector: 'app-extrabenefits',
  templateUrl: './extrabenefits.component.html',
  styleUrls: ['./extrabenefits.component.scss'],
})
export class ExtrabenefitsComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  dependent;
  isChecked: boolean;
  isEditable: boolean;
  extraBenefitSelectedValue: string;
  isVisible: boolean;
  isDisable = false;
  policy: PreEnrollmentPolicyModel;

  disableFloaterField = false;
  totalPremium: any;
  gst: any;
  loading: any;
  premiumAmount: any;

  premiumAmountFormula: Number; // 1 if doj <= startdate; else 2
  dateDiff: any; // difference between policy start date and doj
  isPremiumVisible: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly preEnrollmentService: PreEnrollmentMemberService
  ) {}

  ngOnInit() {
    this.policy = this.preEnrollmentService.policy;
    if (this.policy.corporateId === 4472 || this.policy.corporateId === 4473) {
      this.route.params.subscribe(async params => {
        if (
          params['dependentId'] === 'new' &&
          this.field.name === 'PSI' &&
          this.field.value !== '' &&
          this.field.value !== '0'
        ) {
          this.isDisable = true;
        } else {
          this.isDisable = false;
        }
      });
    }

    this.isEditable = this.field.isEditable;
    this.isVisible = this.field.isVisible;
    this.isChecked = !(this.field.value === '') && !(this.field.value === '0') && !(this.field.value === null);
    if (this.isVisible === true) {
      if (typeof this.field.options === 'string') {
        const sortedbyKeyJSONArray = this.sortByKey(this.field.options);
        this.field.options = this.toJson(sortedbyKeyJSONArray);
      }
      for (const key of Object.keys(this.field.options)) {
        const numberFormatPipe = new NumberFormatPipe();
        this.field.options[key] = numberFormatPipe.transform(this.field.options[key]);
      }
    }

    if (this.isChecked) {
      const numberFormatPipe = new NumberFormatPipe();
      this.extraBenefitSelectedValue = numberFormatPipe.transform(this.field.value.toString());
    } else {
      this.extraBenefitSelectedValue = '0';
    }

    if (
      this.field.name === 'ESI' &&
      this.dependent.relationshipId !== 5 &&
        this.dependent.relationshipId !== 6 &&
        this.dependent.relationshipId !== 7 &&
        this.dependent.relationshipId !== 8 &&
        this.dependent.relationshipId !== 1
    ) {
      if (this.policy.enhancedSumInsured.plan === 'Floater') {
        this.disableFloaterField = true;
      } else {
        this.disableFloaterField = false;
      }
    }

    if (
      this.field.name === 'PSI' &&
      this.dependent.relationshipId !== 5 &&
      this.dependent.relationshipId !== 6 &&
      this.dependent.relationshipId !== 7 &&
      this.dependent.relationshipId !== 8 &&
      this.dependent.relationshipId !== 1
      ) {
      if (this.policy.parentalSumInsured.plan === 'Floater') {
        this.disableFloaterField = true;
      } else {
        this.disableFloaterField = false;
      }
    }

    if (
      this.field.name === 'Topup' &&
      this.dependent.relationshipId !== 5 &&
      this.dependent.relationshipId !== 6 &&
      this.dependent.relationshipId !== 7 &&
      this.dependent.relationshipId !== 8 &&
      this.dependent.relationshipId !== 1
    ) {
      if (this.policy.topupOption.plan === 'Floater') {
        this.disableFloaterField = true;
      } else {
        this.disableFloaterField = false;
      }
    }

    this.checkPremiumAmountFormula();
  }

  sortByKey(jsObj) {
    let ordered = {};
    jsObj = JSON.parse(jsObj);
    Object.keys(jsObj)
      .sort()
      .forEach(function(key) {
        ordered[key] = jsObj[key];
      });
    ordered = JSON.stringify(ordered);
    return ordered;
  }

  toJson(str) {
    return JSON.parse(str);
  }
  OnChange(event) {
    this.isChecked = !this.isChecked;
    if (this.isChecked === false) {
      this.extraBenefitSelectedValue = '0';
      if (this.field.name === 'ESI') {
        this.group.controls.ESI.setValue(this.extraBenefitSelectedValue);
      } else if (this.field.name === 'PSI') {
        this.group.controls.PSI.setValue(this.extraBenefitSelectedValue);
      } else if (this.field.name === 'Topup') {
        this.group.controls.Topup.setValue(this.extraBenefitSelectedValue);
      }
    }
  }

  typeof(value) {
    return typeof value;
  }

  changeExtraBenefit() {
    this.totalPremium = undefined;
    const extrabenefit = Number(this.extraBenefitSelectedValue.split(',').join(''));
    if (this.field.name === 'ESI') {
      for (let index = 0; index < this.dependent.esiPremiumModel.length; index++) {
        if (this.dependent.esiPremiumModel[index].sumInsured === extrabenefit) {
          this.totalPremium = this.dependent.esiPremiumModel[index].totalPremium;
          if (this.totalPremium) {
            const numberFormatPipe = new NumberFormatPipe();
            this.totalPremium = numberFormatPipe.transform(this.totalPremium.toString());
          }
          this.gst = this.dependent.esiPremiumModel[index].gst;
          this.loading = this.dependent.esiPremiumModel[index].loading;
          this.premiumAmount = this.dependent.esiPremiumModel[index].premiumAmount;
          if (
            this.dependent.relationshipId !== 5 &&
            this.dependent.relationshipId !== 6 &&
            this.dependent.relationshipId !== 7 &&
            this.dependent.relationshipId !== 8
          ) {
            this.isPremiumVisible = this.dependent.preEnrollmentPolicy.enhancedSumInsured.isPremiumVisible;
          } else if (this.dependent.relationshipId === 5 || this.dependent.relationshipId === 6) {
            this.isPremiumVisible = this.dependent.preEnrollmentPolicy.enhancedSumInsuredParent.isPremiumVisible;
          } else if (this.dependent.relationshipId === 7 || this.dependent.relationshipId === 8) {
            this.isPremiumVisible = this.dependent.preEnrollmentPolicy.enhancedSumInsuredParentInLaw.isPremiumVisible;
          }
          break;
        } else {
          this.totalPremium = undefined;
        }
      }
    }

    if (this.field.name === 'PSI') {
      for (let index = 0; index < this.dependent.psiPremiumModel.length; index++) {
        if (this.dependent.psiPremiumModel[index].sumInsured === extrabenefit) {
          this.totalPremium = this.dependent.psiPremiumModel[index].totalPremium;
          if (this.totalPremium) {
            const numberFormatPipe = new NumberFormatPipe();
            this.totalPremium = numberFormatPipe.transform(this.totalPremium.toString());
          }

          this.gst = this.dependent.psiPremiumModel[index].gst;
          this.loading = this.dependent.psiPremiumModel[index].loading;
          this.premiumAmount = this.dependent.psiPremiumModel[index].premiumAmount;
          if (this.dependent.relationshipId === 5 || this.dependent.relationshipId === 6) {
            this.isPremiumVisible = this.dependent.preEnrollmentPolicy.parentSumInsured.isPremiumVisible;
          } else if (this.dependent.relationshipId === 7 || this.dependent.relationshipId === 8) {
            this.isPremiumVisible = this.dependent.preEnrollmentPolicy.parentInLawSumInsured.isPremiumVisible;
          }
          break;
        } else {
          this.totalPremium = undefined;
        }
      }
    }

    if (this.field.name === 'Topup') {
      for (let index = 0; index < this.dependent.topupPremiumModel.length; index++) {
        if (this.dependent.topupPremiumModel[index].sumInsured === extrabenefit) {
          this.totalPremium = this.dependent.topupPremiumModel[index].totalPremium;
          if (this.totalPremium) {
            const numberFormatPipe = new NumberFormatPipe();
            this.totalPremium = numberFormatPipe.transform(this.totalPremium.toString());
          }

          this.gst = this.dependent.topupPremiumModel[index].gst;
          this.loading = this.dependent.topupPremiumModel[index].loading;
          this.premiumAmount = this.dependent.topupPremiumModel[index].premiumAmount;
          if (
            this.dependent.relationshipId !== 5 &&
            this.dependent.relationshipId !== 6 &&
            this.dependent.relationshipId !== 7 &&
            this.dependent.relationshipId !== 8
          ) {
            this.isPremiumVisible = this.dependent.preEnrollmentPolicy.topupOption.isPremiumVisible;
          } else if (this.dependent.relationshipId === 5 || this.dependent.relationshipId === 6) {
            this.isPremiumVisible = this.dependent.preEnrollmentPolicy.topupOptionParent.isPremiumVisible;
          } else if (this.dependent.relationshipId === 7 || this.dependent.relationshipId === 8) {
            this.isPremiumVisible = this.dependent.preEnrollmentPolicy.topupOptionParentInLaw.isPremiumVisible;
          }
          break;
        } else {
          this.totalPremium = undefined;
        }
      }
    }
  }

  checkPremiumAmountFormula() {
    if (this.policy) {
      const doj = this.dependent.dateOfJoining;
      const startdate = this.policy.startDate;
      const enddate = this.policy.endDate;
      const dojj = moment(doj).toDate();
      const startdatee = moment(startdate).toDate();
      const enddatee = moment(enddate).toDate();
      const datediff = dojj;
      if (dojj <= startdatee) {
        this.premiumAmountFormula = 1;
      } else {
        this.premiumAmountFormula = 2;
        const time = enddatee.getTime() - dojj.getTime();
        this.dateDiff = time / (1000 * 3600 * 24); // Diference in Days.
        this.dateDiff = Math.floor(this.dateDiff);
      }
    }
  }
}
