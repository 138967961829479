import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      let role = '';
      await this.authService.GetRole().then(x => {
        role = x['roleType'];
        this.authService.role = role;
      });
      if (route.data.roles.indexOf(role) === -1) {
        window.sessionStorage.clear();
        this.router.navigate(['/403Forbidden']);
        return false;
      }
      return true;
    } else {

      this.authService.canPerformLogin.next(true);
      return false;
    }
  }
}
