<div class="confirm-modal-wrapper">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Allocated Time slots for Welcome Email</h5>
            </div>
            <div class="modal-body">
                <div class="row" style="overflow-y: scroll ;height:500px;">
                    <div class="container">
                        <div class="list-component-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Corporate Name</th>
                                        <th>Policy Name</th>
                                        <th>Date</th>
                                        <th>Hours</th>
                                        <th>Minutes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let welcomeEmail of dateTimeAllocation">
                                        <td>{{ welcomeEmail.corporateName }}</td>
                                        <td>{{ welcomeEmail.policyName }}</td>
                                        <td>
                                            <app-date-shared [date]="welcomeEmail.welcomeEmailDate"> </app-date-shared>
                                        </td>
                                        <td>{{ welcomeEmail.welcomeEmailHrs }}</td>
                                        <td>{{ welcomeEmail.welcomeEmailMins }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary px-4" (click)="onConfirm()">Close</button>
            </div>
        </div>
    </div>
</div>