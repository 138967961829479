import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from './logger.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  handleError(error: Error | HttpErrorResponse): void {
    const router = this.injector.get<Router>(Router);
    const loggerService = this.injector.get<LoggerService>(LoggerService);

    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
      } else {
        loggerService.error(error, 'Unhandled HttpErrorResponse error', {}, 'handleError', 'ErrorsHandler');
        router.navigate(['/']);
      }
    } else {
      console.log(error.message);
      loggerService.error(error, 'Unhandled Error Caught In ErrorHandler', {}, 'handleError', 'ErrorsHandler');
    }
  }
}
