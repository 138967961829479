<form class="dynamic-form" [formGroup]="form" (submit)="onSubmit($event)">
  <ng-container *ngFor="let section of sectionWise | keyvalue">
    <div class="card-section">
      <h4 class="row-title">{{section.value[0].section}}</h4>
      <ng-container *ngFor="let field of section.value" dynamicField [field]="field" [dependent]="dependent" [group]="form"></ng-container>
    </div>
  </ng-container>
  
  <div>
    <div  class="note-wrapper" *ngIf="displayFloaterNote">*Note: For Floater Policy the sum Insured selected for father will be applicable for mother
      and sum Insured selected for Father-In-Law will be applicabe for Mother-In-Law and Vice Versa.
    </div>
  <div class="form-action-wrapper mb-4" [formGroup]="form">
    <button type="button" (click)="cancel()" class="btn-cancel">Cancel</button>
        <button type="submit" [disabled]="!form.valid || submitButtonClicked" class="btn px-4 btn-primary">
      {{submitButtonName}}
    </button>
  </div>
</div>
</form>
