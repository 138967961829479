<div class="header-tabs">
  <nav>
    <ul class="nav">
      <li class="right-head">
        <div class="dropdown card-header-dropdown">
          <button class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <span *ngIf="!currentPolicy.hasOwnProperty('policyId')">
              <span class="shimmer-wrapper">
                <span class="shimmer primary"></span>
              </span>
            </span>
            <span *ngIf="currentPolicy.hasOwnProperty('policyId')">
              <span *ngIf="currentPolicy.isActive" class="logged"></span>
              <span *ngIf="!currentPolicy.isActive" class="notLogged"></span>
              <span *ngIf="currentPolicy.riskStartDate !== undefined && currentPolicy.onScreenDisplayAvailable == false">
                {{ currentPolicy.riskStartDate.split('-')[0] }}-{{ currentPolicy.riskEndDate.split('-')[0] }} -
                {{ currentPolicy.policyNumber }} - {{currentPolicy.policyType}}</span
              >
              <span *ngIf="currentPolicy.riskStartDate !== undefined && currentPolicy.onScreenDisplayAvailable == true">{{currentPolicy.onScreenDisplayPolicy}}</span>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <div *ngFor="let policy of policies" (click)="onNamePolicyChanged(policy)">
              <a class="dropdown-item">
                <span *ngIf="policy.isActive" class="logged"></span>
                <span *ngIf="!policy.isActive" class="notLogged"></span>
                <span *ngIf="policy.onScreenDisplayAvailable == false">
                  {{ policy.riskStartDate.split('-')[0] }}-{{ policy.riskEndDate.split('-')[0] }} -
                  {{ policy.policyNumber }} - {{policy.policyType}}</span
                >
                <span *ngIf="policy.onScreenDisplayAvailable == true">{{policy.onScreenDisplayPolicy}}</span>
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</div>

<div class="dashboard-wrapper">
  <div class="row">
    <div class="card card-style-1 col-xs-12 col-md-12 col-lg-12">
      <div class="card-body">
        <div class="card-title row justify-content-between align-items-center">
          <h4 class="m-0 header-title">Static Dashboard</h4>
        </div>
        <app-static-dashboard [policy]="currentPolicy"></app-static-dashboard>
      </div>
    </div>
  </div>
</div>

<div *ngIf="hr !== undefined || brokerPrivileges !== undefined">
  <div
    *ngIf="
      (hr !== undefined && !hr.dashboard && role === 'CPHR') ||
      (brokerPrivileges !== undefined && !brokerPrivileges.dashboard && role === 'Broker')
    "
    class="no-result-wrapper"
  >
    <h5>This feature not Allowed for selected policy.</h5>
  </div>
</div>
