import { Component, OnInit, Injector } from '@angular/core';
import { PreEnrollmentMemberModel } from 'src/app/shared/models/preEnrollment/members/preEnrollment-member.model';
import { PreEnrollmentMemberService } from '../../services/preEnrollment-member.service';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { PreEnrollmentMemberPolicyModel } from '../../model/preEnrollment-member-policy.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MatDialog } from '@angular/material';
import { MemberModel } from '../../model/member.model';
import { MemberValidationHelper } from '../../helpers/member-validation-helper';
import { Router } from '@angular/router';
import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { ErrorMsgDialog } from 'src/app/shared/components/error-msg-dialog/ErrorMsgDialog';
import { ErrorMsgDialogComponent } from 'src/app/shared/components/error-msg-dialog/error-msg-dialog.component';
import * as moment from 'moment';
import { PremiumTableDialogModal } from 'src/app/shared/components/premium-table/premium-table-dialog-modal.model';
import { PremiumTableComponent } from 'src/app/shared/components/premium-table/premium-table.component';

@Component({
  selector: 'app-member-enrollment',
  templateUrl: './member-enrollment.component.html',
  styleUrls: ['./member-enrollment.component.scss'],
})
export class MemberEnrollmentComponent extends BaseComponent implements OnInit {
  selfMember: PreEnrollmentMemberPolicyModel = null;
  dependentMember: PreEnrollmentMemberPolicyModel = new PreEnrollmentMemberPolicyModel();
  dependentMembersList: MemberModel[] = [];
  policy: PreEnrollmentPolicyModel = null;
  relationships: any;
  relationshipSeleted: any;
  selfFields: any;
  selfName: string;
  isLoading = true;
  isLoadingDependent = true;
  isMaxCountReached = true;
  isfinalSubmissionLocked = true;
  finalSubmissionProcessing = false;
  loading = false;
  isPolicyStatusActive = false;
  sumInsured: any;
  allowedSumInsuredString: string[] = [];
  sumInsuredIntValue: number;
  errorMsg: string[] = [];
  hideSIColumns = false;
  //Enhanced Sum Insured
  hideESIColumns = false;
  //Topup Sum Insured
  hideTSIColumns = false;
  //Total Premium
  hideTPColumns = false;
  //Parental Sum Insured
  hidePSI = false;

  selfDependentDetailsList: any[] = [];
  selfCount = 0;
  parentCount = 0;
  parentInLawCount = 0;

  totalSelfPremium: any;
  totalParentPremium: any;
  totalParentInLawPremium: any;

  psiPremiumType: string;
  siPremiumType: string;
  esiPremiumType: string;
  topupPremiumType: string;

  selfPremiumVisible: string;
  parentPremiumVisible: string;
  parentInLawPremiumVisible: string;

  premiumAmountFormula: number; // 1 if doj <= startdate; else 2
  dateDiff: any; // difference between policy start date and doj

  constructor(
    injector: Injector,
    private readonly preEnrollmentService: PreEnrollmentMemberService,
    private router: Router,
    private readonly memberValidationHelper: MemberValidationHelper,
    private readonly alertService: AlertService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.isLoading = true;

    this.policy = this.preEnrollmentService.policy;
    
    this.selfMember = this.preEnrollmentService.selfPolicyModel;
   
    if (this.policy !== null && this.selfMember !== null) {
      // if(this.policy.corporateId==5854 || this.policy.corporateId ==6904 || this.policy.corporateId ==6946 || this.policy.corporateId ==6947 || this.policy.corporateId ==7287)
      //   this.hideSIColumns=true;
      if(this.policy.corporateId ==7287 || this.policy.corporateId == 7859){
        this.hideESIColumns = true;
      }
      if(this.policy.corporateId ==7859){
        this.hideTSIColumns = true;
      }
      if(this.policy.corporateId ==7287 || this.policy.corporateId == 7782 || this.policy.corporateId == 7781 || this.policy.corporateId == 7859){
        this.hideTPColumns = true;
      }
      if(this.policy.corporateId == 7859){
        this.hidePSI = true;
      }
      this.isPolicyStatusActive = this.policy.period.status;
      if (this.policy.plan === 'Floater') {
        this.sumInsured = 0;
        this.sumInsuredIntValue = parseInt(
          this.sumInsured
            .toString()
            .replace(/,/g, '')
            .trim(),
          10
        );
      }
      this.policy.allowedSumInsured.sumInsuredList.sort((a, b) => a.sumInsured - b.sumInsured);
      for (let index = 0; index < this.policy.allowedSumInsured.sumInsuredList.length; index++) {
        this.allowedSumInsuredString[index] = this.policy.allowedSumInsured.sumInsuredList[index].sumInsured.toString();
        const numberFormatPipe = new NumberFormatPipe();
        this.allowedSumInsuredString[index] = numberFormatPipe.transform(this.allowedSumInsuredString[index]);
      }
      this.isLoading = false;
      this.hideShow();
      this.getSelfFields();
      this.getRelationships();
      this.getDependents();
      return;
    }

    this.preEnrollmentService.getMember().then(self => {
      this.selfMember = self;
      this.hideShow();
      this.getSelfFields();
      this.isLoading = false;
    });
    this.preEnrollmentService.getPolicy().then(policy => {
      this.policy = policy;
      // if(policy.corporateId==5854 || this.policy.corporateId ==6904 || this.policy.corporateId ==6946 || this.policy.corporateId ==6947 || this.policy.corporateId ==7287)
      //       this.hideSIColumns=true;
      if(this.policy.corporateId ==7287 || this.policy.corporateId == 7859){
        this.hideESIColumns = true;
      }
      if(this.policy.corporateId ==7859){
        this.hideTSIColumns = true;
      }
      if(this.policy.corporateId ==7287 || this.policy.corporateId == 7782 || this.policy.corporateId == 7781 || this.policy.corporateId == 7859){
        this.hideTPColumns = true;
      }
      if(this.policy.corporateId == 7859){
        this.hidePSI = true;
      }

      this.policy.allowedSumInsured.sumInsuredList.sort((a, b) => a.sumInsured - b.sumInsured);
      for (let index = 0; index < this.policy.allowedSumInsured.sumInsuredList.length; index++) {
        this.allowedSumInsuredString[index] = this.policy.allowedSumInsured.sumInsuredList[index].sumInsured.toString();
        const numberFormatPipe = new NumberFormatPipe();
        this.allowedSumInsuredString[index] = numberFormatPipe.transform(this.allowedSumInsuredString[index]);
      }

      if (this.policy.plan === 'Floater') {
        this.sumInsured = 0;
        this.sumInsuredIntValue = parseInt(
          this.sumInsured
            .toString()
            .replace(/,/g, '')
            .trim(),
          10
        );
      }
      this.isPolicyStatusActive = this.policy.period.status;
      this.getRelationships();
      this.getDependents();
    });
  }

  hideShow() {
    this.isfinalSubmissionLocked = this.memberValidationHelper.isFinalSubmissionLocked(this.selfMember);
  }

  getSelfFields() {
    this.selfFields = this.selfMember.members[0].fields;
    this.createSelfDependentDetailsArray();
    this.selfFields.forEach(element => {
      if (element.name === 'Insured Name') {
        this.selfName = element.value;
      }
      if (element.name === 'Sum Insured') {
        if (element.value) {
          const numberFormatPipe = new NumberFormatPipe();
          element.value = numberFormatPipe.transform(element.value);
        }
      }
      if (element.type === 'extrabenefits') {
        if (element.value) {
          const numberFormatPipe = new NumberFormatPipe();
          element.value = numberFormatPipe.transform(element.value);
        }
      }
    });
  }

  async getDependents() {
    this.dependentMember = this.preEnrollmentService.dependentsPolicyModel;
    if (this.dependentMember === null) {
      await this.preEnrollmentService.getMemberFamily(this.policy.id).then(family => {
        this.dependentMember = family;
        if (this.dependentMember !== null) {
          this.dependentMember.members.forEach(x => {
            if (x.fieldsJson.includes('DisableDependentDeletion')) {
              const obj = JSON.parse(x.fieldsJson);
              x.disableDependentDeletion = obj['DisableDependentDeletion'] === 'Yes' ? true : false;
            }
          });
        }
      });
    }

    this.isLoadingDependent = false;

    if (this.dependentMember == null) {
      this.isMaxCountReached = false;
      this.dependentMembersList = [];
      this.createSelfDependentDetailsArray();
      return;
    }

    this.isMaxCountReached = this.dependentMember.members.length + 1 === this.policy.maxNumOfMembersPerFamily;

    this.dependentMembersList = this.dependentMember.members;
    this.createSelfDependentDetailsArray();

    for (let i = 0; i < this.dependentMember.members.length; i++) {
      this.dependentMember.members[i].fields.forEach(element => {
        if (element.name === 'Insured Name') {
          this.dependentMember.members[i].insuredName = element.value;
        }
        if (element.name === 'Sum Insured') {
          const numberFormatPipe = new NumberFormatPipe();
          element.value = numberFormatPipe.transform(element.value);
        }
        if (element.type === 'extrabenefits') {
          const numberFormatPipe = new NumberFormatPipe();
          element.value = numberFormatPipe.transform(element.value);
        }
      });
    }
  }

  getPremiumDetails() {
    this.preEnrollmentService
      .getPremiumDetails(this.policy.id, 'self')
      .then(x => {
     //   if(x.premiumType=="Flat")
      //  this.hideSIColumns=true;
        this.selfPremiumVisible = x.isPremiumVisible;
      })
      .catch(error => {
        console.log('Error while fetching premium details', error);
      });

    this.preEnrollmentService
      .getPremiumDetails(this.policy.id, 'parent')
      .then(x => {
       // if(x.premiumType=="Flat")
       //     this.hideSIColumns=true;
        this.parentPremiumVisible = x.isPremiumVisible;
      })
      .catch(error => {
        console.log('Error while fetching premium details', error);
      });

    this.preEnrollmentService
      .getPremiumDetails(this.policy.id, 'parentinlaw')
      .then(x => {
      //  if(x.premiumType=="Flat")
      //      this.hideSIColumns=true;
        this.parentInLawPremiumVisible = x.isPremiumVisible;
      })
      .catch(error => {
        console.log('Error while fetching premium details', error);
      });
  }

  async createSelfDependentDetailsArray() {
    await this.getPremiumDetails();
    this.checkPremiumAmountFormula();
    this.selfDependentDetailsList = [];
    const selfObject = {};
    let isSelfAdded = false;
    if (this.selfFields !== undefined) {
      this.selfDependentDetailsList.forEach(element => {
        if (element.Relationship === 'Self') {
          isSelfAdded = true;
          return;
        } else {
          isSelfAdded = false;
        }
      });

      if (!isSelfAdded) {
        this.selfFields.forEach(element => {
          if (element.label === 'Enhanced Sum Insured' || element.label === 'Enhanced Parental Sum Insured') {
            selfObject['Enhanced Sum Insured'] = element.value;
          } else if (element.label === 'Toup Sum Insured' || element.label === 'Topup Parental Sum Insured') {
            selfObject['Topup Sum Insured'] = element.value;
          } else if (element.label === 'Sum Insured' || element.label === 'Sum Insured') {
            selfObject['Sum Insured'] = element.value;
          } else {
            selfObject[element.label] = element.value;
          }
        });
        if (selfObject['Sum Insured']) {
          this.selfMember.members[0].siPremiumModel.forEach(premium => {
            if (premium.sumInsured === Number(selfObject['Sum Insured'].split(',').join(''))) {
              selfObject['siPremiumModel'] = premium.totalPremium;
              selfObject['siGst'] = premium.gst;
              selfObject['siLoading'] = premium.loading;
              selfObject['siPremiumAmount'] = premium.premiumAmount;
              selfObject[
                'isSiPremiumVisible'
              ] = this.selfMember.members[0].preEnrollmentPolicy.enhancedSumInsured.isPremiumVisible;
            }
          });
        }

        if (selfObject['Parental Sum Insured']) {
          this.selfMember.members[0].psiPremiumModel.forEach(premium => {
            if (premium.sumInsured === Number(selfObject['Parental Sum Insured'].split(',').join(''))) {
              selfObject['psiPremiumModel'] = premium.totalPremium;
              selfObject['psiGst'] = premium.gst;
              selfObject['psiLoading'] = premium.loading;
              selfObject['psiPremiumAmount'] = premium.premiumAmount;
              selfObject[
                'isPsiPremiumVisible'
              ] = this.selfMember.members[0].preEnrollmentPolicy.parentSumInsured.isPremiumVisible;
            }
          });
        }

        if (selfObject['Enhanced Sum Insured']) {
          this.selfMember.members[0].esiPremiumModel.forEach(premium => {
            if (premium.sumInsured === Number(selfObject['Enhanced Sum Insured'].split(',').join(''))) {
              selfObject['esiPremiumModel'] = premium.totalPremium;
              selfObject['esiGst'] = premium.gst;
              selfObject['esiLoading'] = premium.loading;
              selfObject['esiPremiumAmount'] = premium.premiumAmount;
              selfObject[
                'isEsiPremiumVisible'
              ] = this.selfMember.members[0].preEnrollmentPolicy.enhancedSumInsured.isPremiumVisible;
            }
          });
        }

        if (selfObject['Topup Sum Insured']) {
          this.selfMember.members[0].topupPremiumModel.forEach(premium => {
            if (premium.sumInsured === Number(selfObject['Topup Sum Insured'].split(',').join(''))) {
              selfObject['topupPremiumModel'] = premium.totalPremium;
              selfObject['topupGst'] = premium.gst;
              selfObject['topupLoading'] = premium.loading;
              selfObject['topupPremiumAmount'] = premium.premiumAmount;
              selfObject[
                'isTopupPremiumVisible'
              ] = this.selfMember.members[0].preEnrollmentPolicy.topupOption.isPremiumVisible;
            }
          });
        }
        selfObject['relationshipId'] = this.selfMember.members[0].relationshipId;
        this.selfDependentDetailsList.push(selfObject);
      }
    }
    if (this.dependentMembersList !== undefined) {
      this.dependentMembersList.forEach(element => {
        const dependentObject = {};

        if (element.relationshipId === 5 || element.relationshipId === 6) {
          dependentObject['type'] = 2;
        }
        if (element.relationshipId === 7 || element.relationshipId === 8) {
          dependentObject['type'] = 3;
        }
        if (
          element.relationshipId !== 5 &&
          element.relationshipId !== 6 &&
          element.relationshipId !== 7 &&
          element.relationshipId !== 8
        ) {
          dependentObject['type'] = 1;
        }

        if (element.fieldsJson.includes('DisableDependentDeletion')) {
          const obj = JSON.parse(element.fieldsJson);
          dependentObject['disableDependentDeletion'] = obj['DisableDependentDeletion'] === 'Yes' ? true : false;
        }

        element.fields.forEach(field => {
          if (
            field.label === 'Enhanced Sum Insured' ||
            field.label === 'Enhanced Parental Sum Insured' ||
            field.label === 'Enhanced Parent-in-Law Sum Insured'
          ) {
            dependentObject['Enhanced Sum Insured'] = field.value;
          } else if (
            field.label === 'Topup Sum Insured' ||
            field.label === 'Topup Parental Sum Insured' ||
            field.label === 'Topup Parent-In-Law Sum Insured'
          ) {
            dependentObject['Topup Sum Insured'] = field.value;
          } else if (field.label === 'Parental Sum Insured' || field.label === 'Parent-in-Law Sum Insured') {
            dependentObject['Parental Sum Insured'] = field.value;
          } else if (field.label === 'Sum Insured') {
            dependentObject['Sum Insured'] = field.value;
          } else {
            dependentObject[field.label] = field.value;
          }
        });

        if (dependentObject['Sum Insured'] && element['siPremiumModel'].length > 0) {
          element['siPremiumModel'].forEach(premium => {
            if (premium.sumInsured === Number(dependentObject['Sum Insured'].split(',').join(''))) {
              dependentObject['siPremiumModel'] = premium.totalPremium;
              dependentObject['siGst'] = premium.gst;
              dependentObject['siLoading'] = premium.loading;
              dependentObject['siPremiumAmount'] = premium.premiumAmount;
            }
          });
          if (
            element.relationshipId !== 5 &&
            element.relationshipId !== 6 &&
            element.relationshipId !== 7 &&
            element.relationshipId !== 8
          ) {
            dependentObject['isSiPremiumVisible'] = element.preEnrollmentPolicy.enhancedSumInsured.isPremiumVisible;
          }
        }

        if (dependentObject['Enhanced Sum Insured'] && element['esiPremiumModel'].length > 0) {
          element['esiPremiumModel'].forEach(premium => {
            if (premium.sumInsured === Number(dependentObject['Enhanced Sum Insured'].split(',').join(''))) {
              dependentObject['esiPremiumModel'] = premium.totalPremium;
              dependentObject['esiGst'] = premium.gst;
              dependentObject['esiLoading'] = premium.loading;
              dependentObject['esiPremiumAmount'] = premium.premiumAmount;
            }
          });
          if (
            element.relationshipId !== 5 &&
            element.relationshipId !== 6 &&
            element.relationshipId !== 7 &&
            element.relationshipId !== 8
          ) {
            dependentObject['isEsiPremiumVisible'] = element.preEnrollmentPolicy.enhancedSumInsured.isPremiumVisible;
          } else if (element.relationshipId === 5 || element.relationshipId === 6) {
            dependentObject['isEsiPremiumVisible'] =
              element.preEnrollmentPolicy.enhancedSumInsuredParent.isPremiumVisible;
          } else if (element.relationshipId === 7 || element.relationshipId === 8) {
            dependentObject['isEsiPremiumVisible'] =
              element.preEnrollmentPolicy.enhancedSumInsuredParentInLaw.isPremiumVisible;
          }
        }

        if (dependentObject['Parental Sum Insured'] && element['psiPremiumModel'].length > 0) {
          element['psiPremiumModel'].forEach(premium => {
            if (premium.sumInsured === Number(dependentObject['Parental Sum Insured'].split(',').join(''))) {
              dependentObject['psiPremiumModel'] = premium.totalPremium;
              dependentObject['psiGst'] = premium.gst;
              dependentObject['psiLoading'] = premium.loading;
              dependentObject['psiPremiumAmount'] = premium.premiumAmount;
            }
          });
          if (element.relationshipId === 5 || element.relationshipId === 6) {
            dependentObject['isPsiPremiumVisible'] = element.preEnrollmentPolicy.parentSumInsured.isPremiumVisible;
          } else if (element.relationshipId === 7 || element.relationshipId === 8) {
            dependentObject['isPsiPremiumVisible'] = element.preEnrollmentPolicy.parentInLawSumInsured.isPremiumVisible;
          }
        }

        if (dependentObject['Topup Sum Insured'] && element['topupPremiumModel'].length > 0) {
          element['topupPremiumModel'].forEach(premium => {
            if (premium.sumInsured === Number(dependentObject['Topup Sum Insured'].split(',').join(''))) {
              dependentObject['topupPremiumModel'] = premium.totalPremium;
              dependentObject['topupGst'] = premium.gst;
              dependentObject['topupLoading'] = premium.loading;
              dependentObject['topupPremiumAmount'] = premium.premiumAmount;
            }
          });
          if (
            element.relationshipId !== 5 &&
            element.relationshipId !== 6 &&
            element.relationshipId !== 7 &&
            element.relationshipId !== 8
          ) {
            dependentObject['isTopupPremiumVisible'] = element.preEnrollmentPolicy.topupOption.isPremiumVisible;
          } else if (element.relationshipId === 5 || element.relationshipId === 6) {
            dependentObject['isTopupPremiumVisible'] = element.preEnrollmentPolicy.topupOptionParent.isPremiumVisible;
          } else if (element.relationshipId === 7 || element.relationshipId === 8) {
            dependentObject['isTopupPremiumVisible'] =
              element.preEnrollmentPolicy.topupOptionParentInLaw.isPremiumVisible;
          }
        }
        dependentObject['id'] = element.id;
        dependentObject['relationshipId'] = element.relationshipId;
        this.selfDependentDetailsList.push(dependentObject);
      });

      this.selfDependentDetailsList.sort(function(a, b) {
        return a.type - b.type || a.relationshipId - b.relationshipId;
      });

      this.selfCount = 0;
      this.parentCount = 0;
      this.parentInLawCount = 0;
      this.selfDependentDetailsList.forEach(element => {
        if (
          element.relationshipId !== 5 &&
          element.relationshipId !== 6 &&
          element.relationshipId !== 7 &&
          element.relationshipId !== 8
        ) {
          this.selfCount = this.selfCount + 1;
        } else if (element.relationshipId === 5 || element.relationshipId === 6) {
          this.parentCount = this.parentCount + 1;
        } else if (element.relationshipId === 7 || element.relationshipId === 8) {
          this.parentInLawCount = this.parentInLawCount + 1;
        }
      });
    }
    this.totalSelfPremium = 0;
    this.totalParentInLawPremium = 0;
    this.totalParentPremium = 0;

    this.selfDependentDetailsList.forEach(element => {
      if (
        element.relationshipId !== 5 &&
        element.relationshipId !== 6 &&
        element.relationshipId !== 7 &&
        element.relationshipId !== 8
      ) {
        if (element.siPremiumModel) {
          this.totalSelfPremium = this.totalSelfPremium + element.siPremiumModel;
        }
        if (element.psiPremiumModel) {
          this.totalSelfPremium = this.totalSelfPremium + element.psiPremiumModel;
        }
        if (element.esiPremiumModel) {
          this.totalSelfPremium = this.totalSelfPremium + element.esiPremiumModel;
        }
        if (element.topupPremiumModel) {
          this.totalSelfPremium = this.totalSelfPremium + element.topupPremiumModel;
        }
      } else if (element.relationshipId === 5 || element.relationshipId === 6) {
        if (element.psiPremiumModel) {
          this.totalParentPremium = this.totalParentPremium + element.psiPremiumModel;
        }
        if (element.esiPremiumModel) {
          this.totalParentPremium = this.totalParentPremium + element.esiPremiumModel;
        }
        if (element.topupPremiumModel) {
          this.totalParentPremium = this.totalParentPremium + element.topupPremiumModel;
        }
      } else if (element.relationshipId === 7 || element.relationshipId === 8) {
        if (element.psiPremiumModel) {
          this.totalParentInLawPremium = this.totalParentInLawPremium + element.psiPremiumModel;
        }
        if (element.esiPremiumModel) {
          this.totalParentInLawPremium = this.totalParentInLawPremium + element.esiPremiumModel;
        }
        if (element.topupPremiumModel) {
          this.totalParentInLawPremium = this.totalParentInLawPremium + element.topupPremiumModel;
        }
      }
    });

    this.selfDependentDetailsList.forEach(element => {
      for (const [key, value] of Object.entries(element)) {
        if (
          key === 'Sum Insured' ||
          key === 'Parental Sum Insured' ||
          key === 'Enhanced Sum Insured' ||
          key === 'Topup Sum Insured'
        ) {
          if (element[key]) {
            const numberFormatPipe = new NumberFormatPipe();
            element[key] = numberFormatPipe.transform(element[key]);
          }
        }
      }
    });

    if (this.totalSelfPremium) {
      const numberFormatPipe = new NumberFormatPipe();
      this.totalSelfPremium = numberFormatPipe.transform(this.totalSelfPremium.toString());
    }
    if (this.totalParentPremium) {
      const numberFormatPipe = new NumberFormatPipe();
      this.totalParentPremium = numberFormatPipe.transform(this.totalParentPremium.toString());
    }
    if (this.totalParentInLawPremium) {
      const numberFormatPipe = new NumberFormatPipe();
      this.totalParentInLawPremium = numberFormatPipe.transform(this.totalParentInLawPremium.toString());
    }
  }

  delete(dependent): void {
    const id = dependent.id;
    const name = dependent['Insured Name'];
    const empId = dependent['Employee Id'];
    let result;
    const message = `Are you sure you want to delete ` + name + ' (Employee ID - ' + empId + ')?';

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      result = dialogResult;
      if (result) {
        this.isLoadingDependent = true;
        this.dependentMember = null;
        this.preEnrollmentService
          .deleteMember(this.policy.id, id)
          .then(x => {
            this.getRelationships();

            let selfObject;
            this.selfDependentDetailsList.forEach(element => {
              if (element.Relationship === 'Self') {
                selfObject = element;
              }
            });
            this.selfDependentDetailsList = [];
            this.selfDependentDetailsList.push(selfObject);
            this.getDependents().then(d => {
              this.isLoadingDependent = false;
              this.alertService.success(name + ' (Employee ID - ' + empId + ') deleted successfully');
              this.resetRelationshipSelected();
            });
          })
          .catch(error => {
            this.alert.error('Error occured');
            this.resetRelationshipSelected();
          });
      }
    });
  }

  resetRelationshipSelected() {
    this.relationshipSeleted = { id: -1, Name: 'Select One' };
  }
  finalSubmission() {
    this.finalSubmissionProcessing = true;
    this.preEnrollmentService
      .setFinalSubmission(this.policy.id)
      .then(x => {
        this.alertService.success('Final submission done successfully');
        this.ngOnInit();
      })
      .catch(error => {
        const errorList = error.error;
        this.openErrorModal(errorList);
      })
      .finally(() => {
        this.finalSubmissionProcessing = false;
      });
  }

  openErrorModal(errorList) {
    this.errorMsg = [];
    errorList.forEach(errorDescription => {
      this.errorMsg.push(errorDescription.message);
    });

    const dialogData = new ErrorMsgDialog(this.errorMsg);

    this.dialog.open(ErrorMsgDialogComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  getRelationships() {
    this.preEnrollmentService.getRelationships(this.policy.id).then(x => {
      this.relationships = x;
    });
  }

  removeCommasFromSuminsured() {
    this.sumInsuredIntValue = parseInt(
      this.sumInsured
        .toString()
        .replace(/,/g, '')
        .trim(),
      10
    );
  }

  checkSumInsured() {
    if (
      this.policy.plan === 'Individual' &&
      this.policy.parentalSumInsured.isAllowed === true &&
      (this.relationshipSeleted === '5' ||
        this.relationshipSeleted === '6' ||
        this.relationshipSeleted === '7' ||
        this.relationshipSeleted === '8')
    ) {
      this.sumInsured = 0;
      this.sumInsuredIntValue = parseInt(
        this.sumInsured
          .toString()
          .replace(/,/g, '')
          .trim(),
        10
      );
    } else if (
      this.policy.plan === 'Individual' &&
      this.policy.parentalSumInsured.isAllowed === true &&
      this.relationshipSeleted !== '5' &&
      this.relationshipSeleted !== '6' &&
      this.relationshipSeleted !== '7' &&
      this.relationshipSeleted !== '8'
    ) {
      this.sumInsured = undefined;
      this.sumInsuredIntValue = undefined;
    } else if (this.policy.plan === 'Individual' && this.policy.parentalSumInsured.isAllowed === false) {
      this.sumInsured = undefined;
      this.sumInsuredIntValue = undefined;
    } else if (this.policy.plan === 'Floater') {
      this.sumInsured = 0;
      this.sumInsuredIntValue = parseInt(
        this.sumInsured
          .toString()
          .replace(/,/g, '')
          .trim(),
        10
      );
    }
  }

  checkPremiumAmountFormula() {
    if (this.policy) {
      const doj = this.selfMember.members[0].dateOfJoining;
      const startdate = this.policy.startDate;
      const enddate = this.policy.endDate;
      const dojj = moment(doj).toDate();
      const startdatee = moment(startdate).toDate();
      const enddatee = moment(enddate).toDate();
      const datediff = dojj;
      if (dojj <= startdatee) {
        this.premiumAmountFormula = 1;
      } else {
        this.premiumAmountFormula = 2;
        const time = enddatee.getTime() - dojj.getTime();
        this.dateDiff = time / (1000 * 3600 * 24); // Diference in Days.
        this.dateDiff = Math.floor(this.dateDiff);
      }
    }
  }

  openModel(item) {
    if (item === 'Self') {
      this.esiPremiumType = this.selfMember.members[0].preEnrollmentPolicy.enhancedSumInsured.premiumType;
      this.topupPremiumType = this.selfMember.members[0].preEnrollmentPolicy.topupOption.premiumType;
      this.psiPremiumType = null;
      this.siPremiumType = this.selfMember.members[0].preEnrollmentPolicy.enhancedSumInsured.premiumType;
    } else if (item === 'Parent') {
      this.esiPremiumType = this.selfMember.members[0].preEnrollmentPolicy.enhancedSumInsuredParent.premiumType;
      this.topupPremiumType = this.selfMember.members[0].preEnrollmentPolicy.topupOptionParent.premiumType;
      this.psiPremiumType = this.selfMember.members[0].preEnrollmentPolicy.parentSumInsured.premiumType;
      this.siPremiumType = null;
    } else if (item === 'ParentInLaw') {
      this.esiPremiumType = this.selfMember.members[0].preEnrollmentPolicy.enhancedSumInsuredParentInLaw.premiumType;
      this.topupPremiumType = this.selfMember.members[0].preEnrollmentPolicy.topupOptionParentInLaw.premiumType;
      this.psiPremiumType = this.selfMember.members[0].preEnrollmentPolicy.parentInLawSumInsured.premiumType;
      this.siPremiumType = null;
    }

    const premiumData = {
      siPremiumType: this.siPremiumType,
      esiPremiumType: this.esiPremiumType,
      psiPremiumType: this.psiPremiumType,
      topupPremiumType: this.topupPremiumType,
      item: item,
      totalSelfPremium: this.totalSelfPremium,
      totalParentPremium: this.totalParentPremium,
      totalParentInLawPremium: this.totalParentInLawPremium,
    };

    const dialogData = new PremiumTableDialogModal(
      this.policy,
      this.selfDependentDetailsList,
      this.premiumAmountFormula,
      premiumData
    );
    const dialogRef = this.dialog.open(PremiumTableComponent, {
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {});
  }
}
