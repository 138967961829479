<div class="list-component-wrapper">
  <div class="container px-0">
    <div class="list-component-body">
      <table>
        <thead>
          <tr>
            <!-- <th>Notification</th> -->
            <th>Effective From</th>
            <th>Effective To</th>
            <th>Subject</th>
            <th>Description</th>
            <th>Corporate</th>
            <th>Document</th>
            <th>
              <div class="float-right">Actions</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr [hidden]="isNotificationsLoaded">
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer primary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer secondary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer secondary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer secondary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer secondary"></div>
              </div>
            </td>
            <td>
              <div class="shimmer-wrapper">
                <div class="shimmer secondary"></div>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="isNotificationsLoaded && notifications | searchFilter: searchText:notificationProperty as result">
          <tr *ngIf="isNotificationsLoaded && result.length === 0">
            <td colspan="7" class="border-0">
              <div class="no-result-wrapper">
                <h5>No Notification Found</h5>
              </div>
            </td>
          </tr>
          <tr
            *ngFor="let notification of result | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize"
            [hidden]="false"
          >
            <td>
              <div>{{ notification.effectiveDateFrom | date: 'dd MMMM yyyy' }}</div>
            </td>
            <td>
              <div>{{ notification.effectiveDateTo | date: 'dd MMMM yyyy' }}</div>
            </td>
            <td>
              <div class="table-subj">{{ notification.subject }}</div>
            </td>
            <td>
              <div class="table-desc">{{ notification.content }}</div>
            </td>
            <td>
              <div *ngIf="notification.isAllCorporate === true">All</div>
              <div *ngIf="notification.isAllCorporate === false">{{ notification.corporateCode }}</div>
            </td>
            <td *ngIf="notification.file === 'Url' || notification.file === null || notification.file === ''">
              <div></div>
            </td>
            <td *ngIf="notification.file !== 'Url' && notification.file !== null && notification.file !== ''">
              <a href="{{ notification.file }}" target="_blank" class="text-secondary"
                ><span class="material-icons">description</span></a
              >
            </td>

            <td>
              <div class="position-relative">
                <button
                  type="button"
                  class="btn btn-link float-right dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="material-icons">more_vert</i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <button (click)="editNotification(notification)" class="dropdown-item">Edit</button>
                  <button (click)="deleteNotification(notification.id)" class="dropdown-item">Remove</button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="12" class="border-0 p-0">
              <div class="pagination-wrapper">
                <ngb-pagination
                  [collectionSize]="result.length"
                  [pageSize]="pageSize"
                  [(page)]="page"
                  [boundaryLinks]="true"
                  [maxSize]="3"
                  [rotate]="true"
                  [hidden]="result.length === 0 || isLoading"
                >
                </ngb-pagination>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
