import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EndorsementComponent } from './components/endorsement/endorsement.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MaterialModule } from '../material/material.module';
import { DyanamicFieldsFormModule } from '../dyanamic-fields-form/dyanamic-fields-form.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PoliciesDropdownComponent } from './components/policies-dropdown/policies-dropdown.component';
import { ApproveRejectComponent } from './components/approve-reject/approve-reject.component';
import { CpSideBarComponent } from './components/cp-side-bar/cp-side-bar.component';
import { CpHeaderComponent } from './components/cp-header/cp-header.component';
import { RouterModule } from '@angular/router';
import { TrackClaimsComponent } from './components/track-claims/track-claims.component';
import { IntimateClaimComponent } from './components/intimate-claim/intimate-claim.component';
import { PolicyCoverageComponent } from './components/policy-coverage/policy-coverage.component';
import { ECardsComponent } from './components/e-cards/e-cards.component';
import { DownloadFormsComponent } from './components/download-forms/download-forms.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FaqComponent } from './components/faq/faq.component';
import { ReportComponent } from './components/report/report.component';
import { HealthClaimMisComponent } from './components/report/health-claim-mis/health-claim-mis.component';
import { HealthEnrollmentMisComponent } from './components/report/health-enrollment-mis/health-enrollment-mis.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { CreateNotificationsComponent } from './components/notifications/create-notifications/create-notifications.component';
import { UserIdMisComponent } from './components/report/user-id-mis/user-id-mis.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { StaticDashboardComponent } from './components/dashboard/static-dashboard/static-dashboard.component';
import { DynamicDashboardComponent } from './components/dashboard/dynamic-dashboard/dynamic-dashboard.component';
import { EditNotificationComponent } from './components/notifications/edit-notification/edit-notification.component';
import { CorporatePortalBaseComponent } from './components/corporate-portal-base/corporate-portal-base.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FinalDeclarationComponent } from './components/final-declaration/final-declaration.component';
import { ActiveSessionsComponent } from './components/active-sessions/active-sessions.component';
import { GpaComponent } from './components/gpa/gpa.component';
import { ViewUploadedDocumentsComponent } from './components/track-claims/view-uploaded-documents/view-uploaded-documents.component';
import { PaciComponent } from './components/paci/paci.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
  declarations: [
    EndorsementComponent,
    PoliciesDropdownComponent,
    ApproveRejectComponent,
    CpHeaderComponent,
    CpSideBarComponent,
    TrackClaimsComponent,
    IntimateClaimComponent,
    PolicyCoverageComponent,
    ECardsComponent,
    DownloadFormsComponent,
    ContactUsComponent,
    FaqComponent,
    ReportComponent,
    HealthClaimMisComponent,
    HealthEnrollmentMisComponent,
    NotificationsComponent,
    CreateNotificationsComponent,
    UserIdMisComponent,
    DashboardComponent,
    StaticDashboardComponent,
    EditNotificationComponent,
    DynamicDashboardComponent,
    FinalDeclarationComponent,
    ActiveSessionsComponent,
    GpaComponent,
    ViewUploadedDocumentsComponent,
    PaciComponent,
  ],
  imports: [
    NgbModule,
    RouterModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    MaterialModule,
    DyanamicFieldsFormModule,
    SlickCarouselModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
  ],
  exports: [
    EndorsementComponent,
    PoliciesDropdownComponent,
    ApproveRejectComponent,
    CpHeaderComponent,
    CpSideBarComponent,
    TrackClaimsComponent,
    IntimateClaimComponent,
    PolicyCoverageComponent,
    ECardsComponent,
    DownloadFormsComponent,
    ContactUsComponent,
    FaqComponent,
    ReportComponent,
    HealthClaimMisComponent,
    HealthEnrollmentMisComponent,
    NotificationsComponent,
    CreateNotificationsComponent,
    UserIdMisComponent,
    DashboardComponent,
    StaticDashboardComponent,
    DynamicDashboardComponent,
    EditNotificationComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CorporatePortalModule {}
