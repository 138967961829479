<app-popup-model
  [title]="title"
  [saveButton]="saveButtonText"
  [isDisableSaveButton]="!isMemberDetailsFound"
  (close)="close()"
  (save)="save($event)"
  [showLoader]="showLoader"
>
  <div class="error-msg" *ngFor="let error of errorList">
    <p>{{ error.message }}</p>
  </div>
  <div class="row" [hidden]="currentStep !== 'firstStep'">
    <div class="col-12">
      <div class="form-group">
        <label>Code<sup>*</sup></label>
        <input
          type="text"
          (blur)="getBrokerByCode($event)"
          [(ngModel)]="code"
          [disabled]="disableCode"
          (input)="checkCodeValidity($event)"
          class="form-control"
        />
        <p [hidden]="isCodeValid" class="error-msg mb-0">
          <small>{{ invalidCodeMsg }}</small>
        </p>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label>Name<sup>*</sup></label>
        <input
          class="form-control"
          type="text"
          (blur)="getBrokerByName($event)"
          [disabled]="disableName"
          (input)="checkNameValidity($event)"
          [(ngModel)]="broker.brokerMaster.name"
        />
        <select
          class="form-control mt-2"
          *ngIf="brokerSearchList.length > 0"
          [(ngModel)]="broker.brokerMaster"
          (ngModelChange)="onNameDropdownChanged($event)"
        >
          <option *ngFor="let broker of brokerSearchList" [ngValue]="broker">
            {{ broker.name }} - {{ broker.code }}
          </option>
        </select>
        <p [hidden]="isNameValid" class="error-msg mb-0">
          <small>{{ invalidNameMsg }}</small>
        </p>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label>Email<sup>*</sup></label>
        <input
          type="text"
          [(ngModel)]="broker.brokerMaster.email"
          (input)="checkEmailValidity($event)"
          [disabled]="disableEmail"
          class="form-control"
        />

        <p [hidden]="isEmailValid" class="error-msg mb-0">
          <small>{{ invalidEmailMsg }}</small>
        </p>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label>Mobile Number<sup>*</sup></label>
        <input
          type="number"
          [(ngModel)]="broker.brokerMaster.mobileNo"
          [disabled]="disableMobileNo"
          (input)="checkMobileNoValidity($event)"
          class="form-control"
        />

        <p [hidden]="isMobileNoValid" class="error-msg mb-0">
          <small>{{ invalidMobileMsg }}</small>
        </p>
      </div>
    </div>
  </div>

  <div [hidden]="currentStep !== 'secondStep'">
    <app-privileges
      [user]="broker"
      (isFormValid)="isMemberDetailsFound = $event"
      (currentStep)="currentStep = $event"
      (saveButtonText)="saveButtonText = $event"
    ></app-privileges>
  </div>
</app-popup-model>
