import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../../model/form-fields';
import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
@Component({
  selector: 'app-select',
  template: `
    <div *ngIf="field.instructions !== null" style="color: green;">
      <p>{{ field.instructions }}</p>
    </div>
    <mat-form-field class="demo-full-width margin-top" [formGroup]="group">
      <mat-select
        [placeholder]="field.label"
        [(ngModel)]="field.value"
        [required]="field.isMandatory"
        [formControlName]="field.name"
      >
        <mat-option *ngFor="let item of field.options | keyvalue" [value]="item.value">{{ item.value }}</mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: [],
})
export class SelectComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor() {}

  ngOnInit() {
    if (typeof this.field.options === 'string') {
      this.field.options = this.toJson(this.field.options);
    }
    if (this.field.name === 'Sum Insured') {
      for (const key of Object.keys(this.field.options)) {
        const numberFormatPipe1 = new NumberFormatPipe();
        this.field.options[key] = numberFormatPipe1.transform(this.field.options[key]);
      }
      const numberFormatPipe = new NumberFormatPipe();
      this.field.value = numberFormatPipe.transform(this.field.value);
    }
  }

  toJson(str) {
    return JSON.parse(str);
  }
}
