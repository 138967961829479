<!-- <div class="card w-100">
  <div class="card-body">
    <div class="row"> -->
      
      
      <form class="w-100" [formGroup]="healthClaimForm" (ngSubmit)="onSubmit()">
        <div class="col-12 py-4">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>Form Date <sup>*</sup></label>
                <input
                  value="{{ date1 | date: 'dd MMMM yyyy' }}"
                  type="text"
                  class="form-control"
                  readonly
                  bsDatepicker
                  formControlName="fromDate"
                  (click)="StartDateSelected()"
                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                />
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>To Date <sup>*</sup></label>
                <input
                  value="{{ date2 | date: 'dd MMMM yyyy' }}"
                  type="text"
                  class="form-control"
                  readonly
                  bsDatepicker
                  [minDate]="healthClaimForm.value.fromDate"
                  formControlName="toDate"
                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                />
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>Claim Type <sup>*</sup></label>
                <select class="form-control" formControlName="claim">
                  <option value="Cashless" [disabled]="!cashless">Cashless</option>
                  <option value="Reimbursement" [disabled]="!reimbursement">Reimbursement</option>
                </select>
              </div>
            </div>
            <!-- <div class="col-sm-4">
              <div class="form-group">
                <label>Report Type</label>
                <select class="form-control" formControlName="type" (change)="viewType()">
                  <option value="Report">MIS Report</option>
                  <option value="Dashboard">Dashboard</option>
                </select>
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button [disabled]="!healthClaimForm.valid" class="btn btn-primary w-100">
                <span
                  *ngIf="!isExcelReportLoaded"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span> Download Report</span>
              </button>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf="isDashboardView && !isClaimMisLoaded" class="col-12">
        <ul class="list-unstyled shimmer-wrapper">
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
          <li class="shimmer"></li>
        </ul>
      </div>
      <div class="col-12 py-4" *ngIf="isDashboardView && isClaimMisLoaded">
        <div class="table-grid">
          <table>
            <thead>
              <tr>
                <th>Type of Claim</th>
                <th>Claim Status</th>
                <th>Count</th>
                <th>Amount (Rs.)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="6" class="border-right">
                  <div>Fresh</div>
                </td>
                <td>
                  <div>Settled <span class="badge status_1">Approved</span></div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_Settled_ApprovedCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_Settled_ApprovedAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Settled <span class="badge status_1">Rejected</span></div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_Settled_RejectedCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_Settled_RejectedAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Settled <span class="badge status_1">Closed Cancelled</span></div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_Settled_Closed_CancelledCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_Settled_Closed_CancelledAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Under Process <span class="badge status_1">Open Intimation</span></div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_UnderPro_OpenIntimationsCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_UnderPro_OpenIntimationsAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Under Process <span class="badge status_1">Queried</span></div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_UnderPro_QueriedCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_UnderPro_QueriedAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Under Process <span class="badge status_1">WIP</span></div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_UnderPro_WIPCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_UnderPro_WIPAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>Sub Total</td>
                <td></td>
                <td>
                  <div>{{ claimMis.fresh_SubTotalCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.fresh_SubTotalAmount }}</div>
                </td>
              </tr>
              <tr>
                <td rowspan="6" class="border-right">
                  <div>Enhancement</div>
                </td>
                <td>
                  <div>Settled <span class="badge status_1">Approved</span></div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_Settled_ApprovedCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_Settled_ApprovedAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Settled <span class="badge status_1">Rejected</span></div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_Settled_RejectedCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_Settled_RejectedAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Settled <span class="badge status_1">Closed Cancelled</span></div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_Settled_Closed_CancelledCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_Settled_Closed_CancelledAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Under Process <span class="badge status_1">Open Intimation</span></div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_UnderPro_QueriedCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_UnderPro_QueriedAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Under Process <span class="badge status_1">Queried</span></div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_UnderPro_WIPCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_UnderPro_WIPAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Under Process <span class="badge status_1">WIP</span></div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_UnderPro_WIPCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_UnderPro_WIPAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>Sub Total</td>
                <td></td>
                <td>
                  <div>{{ claimMis.enhancement_SubTotalCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.enhancement_SubTotalAmount }}</div>
                </td>
              </tr>

              <tr>
                <td rowspan="4" class="border-right">
                  <div>Pre Post</div>
                </td>
                <td>
                  <div>Settled <span class="badge status_1">Rejected</span></div>
                </td>
                <td>
                  <div>{{ claimMis.prePost_Settled_RejectedCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.prePost_Settled_RejectedAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Settled <span class="badge status_1">Closed Cancelled</span></div>
                </td>
                <td>
                  <div>{{ claimMis.prePost_Settled_Closed_CancelledCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.prePost_Settled_Closed_CancelledAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Under Process <span class="badge status_1">Queried</span></div>
                </td>
                <td>
                  <div>{{ claimMis.prePost_UnderPro_QueriedCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.prePost_UnderPro_QueriedAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Under Process <span class="badge status_1">WIP</span></div>
                </td>
                <td>
                  <div>{{ claimMis.prePost_UnderPro_WIPCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.prePost_UnderPro_WIPAmount }}</div>
                </td>
              </tr>
              <tr>
                <td>Sub Total</td>
                <td></td>
                <td>
                  <div>{{ claimMis.prePost_SubTotalCount }}</div>
                </td>
                <td>
                  <div>{{ claimMis.prePost_SubTotalAmount }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
   
   
    <!-- </div>   
  </div>
</div> -->
