<!-- <div class="card-title row justify-content-between align-items-center">
  <h4 class="m-0 header-title">{{ heading }}</h4> -->
<div class="form-group w-100">
  <div class="dropdown card-header-dropdown" *ngIf="selectedPolicy.policyId !== undefined && !hidePolicyDropdown">
    <button class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
      <span *ngIf="!selectedPolicy.hasOwnProperty('policyId')">
        <span class="shimmer-wrapper">
          <span class="shimmer primary"></span>
        </span>
      </span>
      <span *ngIf="selectedPolicy.hasOwnProperty('policyId')" class="policy-content" >
        <span *ngIf="selectedPolicy.isActive" class="logged"></span>
        <span *ngIf="!selectedPolicy.isActive" class="notLogged"></span>
        <span *ngIf="selectedPolicy.onScreenDisplayAvailable == false">
          {{ selectedPolicy.riskStartDate.split('-')[0] }}-{{ selectedPolicy.riskEndDate.split('-')[0] }} -
          {{ selectedPolicy.policyNumber }} - {{selectedPolicy.policyType}}</span
        >
        <span *ngIf="selectedPolicy.onScreenDisplayAvailable == true">{{selectedPolicy.onScreenDisplayPolicy}}</span>
      </span>
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <div *ngFor="let policy of allPolicies" (click)="onChangePolicy(policy)">
        <a class="dropdown-item">
          <span *ngIf="!selectedPolicy.hasOwnProperty('isActive')">
            <ul class="shimmer-wrapper">
              <li class="shimmer primary"></li>
            </ul>
          </span>
          <span *ngIf="policy.isActive" class="logged"></span>
          <span *ngIf="!policy.isActive" class="notLogged"></span>
          <span *ngIf="policy.onScreenDisplayAvailable == false">
            {{ policy.riskStartDate.split('-')[0] }}-{{ policy.riskEndDate.split('-')[0] }} -
            {{ policy.policyNumber }} - {{policy.policyType}}</span
          >
          <span *ngIf="policy.onScreenDisplayAvailable == true">{{policy.onScreenDisplayPolicy}}</span>
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="selectedPolicy.policyId === undefined" class="shimmer"></div>
</div>

<!-- </div> -->
