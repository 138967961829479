import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/auth.guard';
import { CpMemberProfileComponent } from './components/cp-member-profile/cp-member-profile.component';
import { CpMemberComponent } from './components/cp-member/cp-member.component';
import { CpMemberEnrollmentComponent } from './components/cp-member-enrollment/cp-member-enrollment.component';
import { EndorsementComponent } from './components/endorsement/endorsement.component';
import { TrackClaimsComponent } from '../corporate-portal/components/track-claims/track-claims.component';
import { IntimateClaimComponent } from '../corporate-portal/components/intimate-claim/intimate-claim.component';
import { PolicyCoverageComponent } from '../corporate-portal/components/policy-coverage/policy-coverage.component';
import { ECardsComponent } from '../corporate-portal/components/e-cards/e-cards.component';
import { DownloadFormsComponent } from '../corporate-portal/components/download-forms/download-forms.component';
import { FaqComponent } from '../corporate-portal/components/faq/faq.component';
import { ContactUsComponent } from '../corporate-portal/components/contact-us/contact-us.component';
import { DashboardComponent } from '../corporate-portal/components/dashboard/dashboard.component';
import { ReportComponent } from '../corporate-portal/components/report/report.component';
import { NotificationsComponent } from '../corporate-portal/components/notifications/notifications.component';
import { FinalDeclarationComponent } from '../corporate-portal/components/final-declaration/final-declaration.component';
import { ActiveSessionsComponent } from '../corporate-portal/components/active-sessions/active-sessions.component';
import { GpaComponent } from '../corporate-portal/components/gpa/gpa.component';
import { PaciComponent } from '../corporate-portal/components/paci/paci.component';
import { CpNomineeEnrollComponent } from './components/cp-nominee-enroll/cp-nominee-enroll.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { roles: ['CPMember', 'CPHR'] },
    children: [
      { path: 'profile', component: CpMemberProfileComponent },
      { path: 'track-claims', component: TrackClaimsComponent },
      { path: 'intimate-claim', component: IntimateClaimComponent },
      { path: 'policy-coverage', component: PolicyCoverageComponent },
      { path: 'e-cards', component: ECardsComponent },
      { path: 'download-forms', component: DownloadFormsComponent },
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'faq', component: FaqComponent },
      { path: 'contact', component: ContactUsComponent },
      { path: 'enrollment', component: CpMemberEnrollmentComponent },
      { path: 'endorsement', component: EndorsementComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'report', component: ReportComponent },
      { path: 'notification', component: NotificationsComponent },
      { path: 'final-decalartion', component: FinalDeclarationComponent },
      { path: 'attendance', component: ActiveSessionsComponent },
      { path: 'gpa', component: GpaComponent},
      { path:'gpm', component:GpaComponent },
      { path: 'paci', component: PaciComponent},
      { path: 'nominee-enroll', component: CpNomineeEnrollComponent}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CpMemberRoutingModule {
  constructor() {}
}
