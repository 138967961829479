import { Component, OnInit, Input, Injector, OnDestroy } from '@angular/core';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { PreEnrollmentService } from '../../services/preEnrollment.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporatePortalService } from '../../services/corporate-portal.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CorporatePortalAttachPolicyComponent } from './corporate-portal-attach-policy/corporate-portal-attach-policy.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-corporate-portal',
  templateUrl: './corporate-portal.component.html',
  styleUrls: ['./corporate-portal.component.scss'],
})
export class CorporatePortalComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() corporate: CorporateModel;
  selectedPolicy: any;
  selectedPolicyDetails: any;
  policies: any[];
  initialized = false;
  addNewPolicyRequest = false;

  constructor(
    injector: Injector,
    private readonly preEnrollmentService: PreEnrollmentService,
    private readonly corporatePortalService: CorporatePortalService,
    private modalService: NgbModal,
    private readonly router: Router,
    private readonly alertService: AlertService
  ) {
    super(injector);
  }

  async ngOnInit() {
    super.ngOnInit();
    const policy = await this.corporatePortalService.getPolicyByCorporate(this.corporate.id);
    if (policy.length > 0) {
      this.corporatePortalService.updatedPolicies.next(policy);
      this.policies = policy;
      this.sortPolicies();
      this.selectedPolicy = this.policies[0].policyNumber;
      this.selectedPolicyDetails = this.policies[0];
    }
    this.initialized = true;
  }

  sortPolicies() {
    this.policies.sort(function compare(a, b) {
      const dateA = new Date(a.riskStartDate);
      const dateB = new Date(b.riskStartDate);
      return <any>dateB - <any>dateA;
    });
    this.policies.sort(policy => {
      return policy.isActive === false ? 0 : policy ? -1 : 1;
    });
    const activePolicyArray = [];
    const inactivePolicyArray = [];
    this.policies.forEach(policy => {
      if (policy.isActive) {
        activePolicyArray.push(policy);
      } else {
        inactivePolicyArray.push(policy);
      }
    });
    activePolicyArray.reverse();
    this.policies = [];
    this.policies = [...activePolicyArray, ...inactivePolicyArray];
  }

  // onPolicyChanged(event) {
  //   if (event === 'Attach Policy') {
  //     this.addNewPolicyRequest = true;
  //   }
  // }

  async cancel() {
    this.router.navigate(['/biz-admin/corporate/' + this.corporate.id]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onPolicyDropdownChanged(policy) {
    if (policy === 'Attach Policy') {
      this.addNewPolicyRequest = true;
    } else {
      this.selectedPolicy = policy.policyNumber;
      this.selectedPolicyDetails = policy;
    }
  }
}
