import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { BehaviorSubject } from 'rxjs';
import { Broker } from 'src/app/shared/models/CorporatePortal/broker.model';
import { CorporatePolicyModel } from 'src/app/corporate-portal/models/corporate-policy.model';
import { Sm } from 'src/app/shared/models/CorporatePortal/sm.model';

@Injectable({
  providedIn: 'root',
})
export class SMService {
  smProfile: BehaviorSubject<any>;
  smPrivileges: Sm;

  constructor(private readonly backendService: BackendService) {
    this.smProfile = new BehaviorSubject<any>({});
  }

  async getSMProfile(): Promise<any> {
    // if (this.brokerProfile.value !== null) {
    //   return new Promise(async resolve => {
    //     resolve(this.brokerProfile);
    //   });
    // }
    return new Promise(async resolve => {
      const result = await this.backendService.get('SM');
      this.smProfile.next(result);
      resolve(result);
    });
  }

  async getSMByPolicy(policyId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.smPrivileges !== undefined && this.smPrivileges.policyId === policyId) {
        return resolve(this.smPrivileges);
      }
      await this.backendService.get(`SM/policy/${policyId}`).then(
        resul => {
          this.smPrivileges = resul;
          this.smPrivileges.policyId = policyId;
          return resolve(resul);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  async getSMEndorsement(policyId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.backendService.get(`CorporatePortal/policies/${policyId}/Endorsement/For/SM`).then(
        resul => {
          return resolve(resul);
        },
        error => {
          return reject(error);
        }
      );
    });
  }
}
