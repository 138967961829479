import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NumberFormatPipe } from '../../pipes/number-format.pipe';
import { PremiumTableDialogModal } from './premium-table-dialog-modal.model';

@Component({
  selector: 'app-premium-table',
  templateUrl: './premium-table.component.html',
  styleUrls: ['./premium-table.component.scss'],
})
export class PremiumTableComponent implements OnInit {
  policy: any;
  selfDependentDetailsList: any;
  premiumAmountFormula: number;
  rship: any;
  esiPremiumType: any;
  psiPremiumType: any;
  siPremiumType: string;
  topupPremiumType: any;
  memberCount: number;
  totalPremium: number;
  amountInWords: string;
  sumInsuredPlan: string;

  constructor(
    public dialogRef: MatDialogRef<PremiumTableDialogModal>,
    @Inject(MAT_DIALOG_DATA) public data: PremiumTableDialogModal
  ) {
    // Update view with given values
    this.policy = data.policy;
    this.selfDependentDetailsList = data.selfDependentDetailsList;
    this.premiumAmountFormula = data.premiumAmountFormula;
    this.rship = data.premiumData.item;
    this.siPremiumType = data.premiumData.siPremiumType;
    this.esiPremiumType = data.premiumData.esiPremiumType;
    this.psiPremiumType = data.premiumData.psiPremiumType;
    this.topupPremiumType = data.premiumData.topupPremiumType;

    if (this.rship === 'Self') {
      this.totalPremium = data.premiumData.totalSelfPremium;
      this.sumInsuredPlan = this.policy.plan;
    } else if (this.rship === 'Parent') {
      this.totalPremium = data.premiumData.totalParentPremium;
      this.sumInsuredPlan = this.policy.parentalSumInsured.plan;
    } else if (this.rship === 'ParentInLaw') {
      this.totalPremium = data.premiumData.totalParentInLawPremium;
      this.sumInsuredPlan = this.policy.parentInLawSumInsured.plan;
    }
  }

  ngOnInit() {
    this.amountInWords = this.NumInWords(this.totalPremium);
    // this.selfDependentDetailsList.forEach(element => {
    //   for (const [key, value] of Object.entries(element)) {
    //     if (
    //       key === 'psiPremiumModel' ||
    //       key === 'esiPremiumModel' ||
    //       key === 'topupPremiumModel'
    //     ) {
    //       if (element[key]) {
    //         const numberFormatPipe = new NumberFormatPipe();
    //         element[key] = numberFormatPipe.transform(element[key].toString());
    //       }
    //     }
    //   }
    // });

    let selfCount = 0;
    let parentCount = 0;
    let parentInLawCount = 0;
    this.selfDependentDetailsList.forEach(element => {
      if (
        element.relationshipId !== 5 &&
        element.relationshipId !== 6 &&
        element.relationshipId !== 7 &&
        element.relationshipId !== 8
      ) {
        selfCount = selfCount + 1;
      } else if (element.relationshipId === 5 || element.relationshipId === 6) {
        parentCount = parentCount + 1;
      } else if (element.relationshipId === 7 || element.relationshipId === 8) {
        parentInLawCount = parentInLawCount + 1;
      }
    });

    if (this.rship === 'Self') {
      this.selfDependentDetailsList = this.selfDependentDetailsList.filter(
        el => el.relationshipId !== 5 && el.relationshipId !== 6 && el.relationshipId !== 7 && el.relationshipId !== 8
      );
      this.memberCount = selfCount;
    } else if (this.rship === 'Parent') {
      this.selfDependentDetailsList = this.selfDependentDetailsList.filter(
        el => el.relationshipId === 5 || el.relationshipId === 6
      );
      this.memberCount = parentCount;
    } else if (this.rship === 'ParentInLaw') {
      this.selfDependentDetailsList = this.selfDependentDetailsList.filter(
        el => el.relationshipId === 7 || el.relationshipId === 8
      );
      this.memberCount = parentInLawCount;
    }
  }

  NumInWords(number) {
    number = parseFloat(number.replace(/,/g, ''));
    const first = [
      '',
      'One ',
      'Two ',
      'Three ',
      'Four ',
      'Five ',
      'Six ',
      'Seven ',
      'Eight ',
      'Nine ',
      'Ten ',
      'Eleven ',
      'Twelve ',
      'Thirteen ',
      'Fourteen ',
      'Fifteen ',
      'Sixteen ',
      'Seventeen ',
      'Eighteen ',
      'Nineteen ',
    ];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const mad = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
    let word = '';
    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + ' ' + word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            '-' +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            ' ' +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) {
        word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'Hunderd ' + word;
      }
    }
    return word;
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
