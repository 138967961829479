import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-final-declaration',
  templateUrl: './final-declaration.component.html',
  styleUrls: ['./final-declaration.component.scss'],
})
export class FinalDeclarationComponent implements OnInit {
  @Input() name: string;
  @Input() age: number;
  @Input() id: number;
  @Input() uhid: number;
  @Input() finalDeclarationDone: string;
  @Output() isFinalDeclared = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {}

  isFinalDeclarationDone(value) {
    this.isFinalDeclared.emit(value);
  }
}
