<div class="member-enrollment-component-wrapper">
  <div class="container-fluid">
    <div class="row">
      <!-- Spinner -->
      <div *ngIf="isLoading" class="spinner-center-wrapper">
        <div class="spinner-center"></div>
      </div>

      <div *ngIf="!isLoading && policy" class="col-xl-12">
        <div class="row">
          <div class="card w-100" *ngIf="!isLoading && !isPolicyStatusActive && !isLoadingDependent">
            <div class="alert alert-warning">You can not edit enrollment as period is not active.</div>
          </div>
          <div class="card w-100">
            <div class="btn-wrapper">
              <button
                class="btn btn-success float-right text-white"
                [disabled]="
                  isfinalSubmissionLocked ||
                  (relationships | json) === '{}' ||
                  isMaxCountReached ||
                  !isPolicyStatusActive
                "
                (click)="getRelationships()"
                data-toggle="modal"
                data-target="#addFamilyMemberModal"
              >
                Add new family member
              </button>
            </div>

            <!-- <div class="card-body pb-0">
              <div class="card-title row justify-content-between align-items-center">
                <h4 class="m-0 header-title">
                  {{ selfName }}
                  <a
                    class="text-white edit-btn"
                    [hidden]="isfinalSubmissionLocked || !isPolicyStatusActive"
                    routerLink="../edit/member"
                    ><span class="material-icons">
                      create
                    </span></a
                  >
                </h4>
                <div class="d-flex align-items-center">
                  {{ policy.year }}- {{ policy.name }}
                  <span class="logged"></span>
                </div>
              </div>

               Shimmers -->

            <!-- <div class="two-col-shimmer-wrapper">
                <ul class="list-unstyled" [hidden]="!isLoading">
                  <li><span class="shimmer"></span></li>
                  <li><span class="shimmer"></span></li>
                  <li><span class="shimmer"></span></li>
                  <li><span class="shimmer"></span></li>
                </ul>
              </div> -->

            <!-- <ul class="inner-cards two-col-rows">
                <ng-container *ngFor="let item of selfFields">
                  <ng-container>
                    <li>
                      <span class="field-name mr-4"> {{ item.label }} :</span>
                      <span class="field-data">{{ item.value }}</span>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </div>
          </div> -->

            <!-- Shimmer Card Start -->
            <div class="card w-100 two-col-shimmer-wrapper" [hidden]="!isLoadingDependent">
              <div class="card-body pb-0">
                <div class="card-title">
                  <ul class="list-unstyled w-50">
                    <li class="py-0"><span class="shimmer"></span></li>
                  </ul>
                </div>
                <ul class="list-unstyled">
                  <li><span class="shimmer"></span></li>
                  <li><span class="shimmer"></span></li>
                  <li><span class="shimmer"></span></li>
                  <li><span class="shimmer"></span></li>
                </ul>
              </div>
            </div>
            <!-- Shimmer Card End -->
            <!-- <ng-container *ngIf="!isLoadingDependent">
            <div class="card w-100" *ngFor="let dependent of dependentMembersList">
              <div class="card-body pb-0">
                <div class="card-title row justify-content-between align-items-center">
                  <h4 class="m-0 header-title">
                    {{ dependent.insuredName }}
                    <a
                      class="text-white edit-btn"
                      [hidden]="isfinalSubmissionLocked || !isPolicyStatusActive"
                      routerLink="../edit/member/family/{{ dependent.id }}"
                      ><span class="material-icons">
                        create
                      </span></a
                    >
                    <a
                      class="text-white delete-btn"
                      style="color: red;"
                      [hidden]="isfinalSubmissionLocked || !isPolicyStatusActive"
                      (click)="delete(dependent)"
                      ><span class="material-icons">
                        delete
                      </span></a
                    >
                  </h4>
                  <span>
                    {{ policy.year }}- {{ policy.name }}
                    <span class="logged"></span>
                  </span>
                </div>

                <ul class="inner-cards two-col-rows">
                  <ng-container *ngFor="let item of dependent.fields">
                    <ng-container>
                      <li>
                        <span class="field-name mr-4"> {{ item.label }} :</span>
                        <span class="field-data">{{ item.value }}</span>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </div>
            </div>
          </ng-container> -->

            <ng-container *ngIf="!isLoadingDependent">
              <div class="card w-100">
                <div class="card-body pb-0">
                  <div class="card-title row justify-content-between align-items-center">
                    <h4 class="m-0 header-title">
                      Member Details
                    </h4>
                    <span>
                      {{ policy.year }}- {{ policy.name }}
                      <span class="logged"></span>
                    </span>
                  </div>
                  <ng-container *ngIf="!isLoadingDependent">
                    <div class="list-component-wrapper">
                      <div class="container">
                        <div class="list-component-body">
                          <table>
                            <thead>
                              <tr>
                                <th>Insured Name</th>
                                <th>Relationship</th>
                                <th>Sum Insured</th>
                                <th *ngIf="!hidePSI">Parental Sum Insured</th>
                                <th *ngIf="!hideESIColumns">Enhanced Sum Insured</th>
                                <th *ngIf="!hideTSIColumns">Topup Sum Insured</th>
                                <th *ngIf="!hideTPColumns">Total Premium</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of selfDependentDetailsList">
                                <td>{{ item['Insured Name'] }}</td>
                                <td>{{ item.Relationship }}</td>

                                <td
                                  *ngIf="item.relationshipId === 1 && policy.plan === 'Floater'"
                                  [attr.rowspan]="selfCount"
                                >
                                  {{ item['Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    hidePSI && item.relationshipId > 4
                                  "
                                ></td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 5 &&
                                    policy.parentalSumInsured.plan === 'Floater' &&
                                    parentCount === 1 && !hidePSI
                                  "
                                >
                                  <!-- {{ item['Parental Sum Insured'] }} -->
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 6 &&
                                    policy.parentalSumInsured.plan === 'Floater' &&
                                    parentCount === 1 && !hidePSI
                                  "
                                >
                                  <!-- {{ item['Parental Sum Insured'] }} -->
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 5 &&
                                    policy.parentalSumInsured.plan === 'Floater' &&
                                    parentCount === 2 && !hidePSI
                                  "
                                  [attr.rowspan]="parentCount"
                                >
                                  <!-- {{ item['Parental Sum Insured'] }} -->
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 7 &&
                                    policy.parentInLawSumInsured.plan === 'Floater' &&
                                    parentInLawCount === 1 && !hidePSI
                                  "
                                >
                                  <!-- {{ item['Parental Sum Insured'] }} -->
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 8 &&
                                    policy.parentInLawSumInsured.plan === 'Floater' &&
                                    parentInLawCount === 1 && !hidePSI
                                  "
                                >
                                  <!-- {{ item['Parental Sum Insured'] }} -->
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 7 &&
                                    policy.parentInLawSumInsured.plan === 'Floater' &&
                                    parentInLawCount === 2 && !hidePSI
                                  "
                                  [attr.rowspan]="parentInLawCount"
                                >
                                  <!-- {{ item['Parental Sum Insured'] }} -->
                                </td>

                                <td
                                  *ngIf="
                                    (item.relationshipId !== 5 &&
                                      item.relationshipId !== 6 &&
                                      item.relationshipId !== 7 &&
                                      item.relationshipId !== 8) &&
                                    policy.plan !== 'Floater' && !hideSIColumns
                                  "
                                >
                                  <!-- {{ item['Sum Insured'] }} -->
                                </td>
                                <td
                                  *ngIf="
                                    (item.relationshipId === 5 || item.relationshipId === 6) &&
                                    policy.parentalSumInsured.plan !== 'Floater' && !hidePSI
                                  "
                                >
                                  {{ item['Parental Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    (item.relationshipId === 7 || item.relationshipId === 8) &&
                                    policy.parentInLawSumInsured.plan !== 'Floater' && !hidePSI
                                  "
                                >
                                  {{ item['Parental Sum Insured'] }}
                                </td>

                                <td
                                  *ngIf="
                                  item.relationshipId !== 5 &&
                                  item.relationshipId !== 6 &&
                                  item.relationshipId !== 7 &&
                                  item.relationshipId !== 8 && !hidePSI
                                  "
                                ></td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 5 &&
                                    policy.parentalSumInsured.plan === 'Floater' &&
                                    parentCount === 1 && !hidePSI
                                  "
                                >
                                  {{ item['Parental Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 6 &&
                                    policy.parentalSumInsured.plan === 'Floater' &&
                                    parentCount === 1 && !hidePSI
                                  "
                                >
                                  {{ item['Parental Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 5 &&
                                    policy.parentalSumInsured.plan === 'Floater' &&
                                    parentCount === 2 && !hidePSI
                                  "
                                  [attr.rowspan]="parentCount"
                                >
                                  {{ item['Parental Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 7 &&
                                    policy.parentInLawSumInsured.plan === 'Floater' &&
                                    parentInLawCount === 1 && !hidePSI
                                  "
                                >
                                  {{ item['Parental Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 8 &&
                                    policy.parentInLawSumInsured.plan === 'Floater' &&
                                    parentInLawCount === 1 && !hidePSI
                                  "
                                >
                                  {{ item['Parental Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 7 &&
                                    policy.parentInLawSumInsured.plan === 'Floater' &&
                                    parentInLawCount === 2 && !hidePSI
                                  "
                                  [attr.rowspan]="parentInLawCount"
                                >
                                  {{ item['Parental Sum Insured'] }}
                                </td>

                                <td
                                  *ngIf="
                                    (item.relationshipId === 5 || item.relationshipId === 6) &&
                                    policy.parentalSumInsured.plan !== 'Floater' && !hidePSI
                                  "
                                >
                                  {{ item['Parental Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    (item.relationshipId === 7 || item.relationshipId === 8) &&
                                    policy.parentInLawSumInsured.plan !== 'Floater' && !hidePSI
                                  "
                                >
                                  {{ item['Parental Sum Insured'] }}
                                </td>
                                <!-- Enhanced Sum Insured -->
                                <td
                                  *ngIf="item.relationshipId === 1 && policy.enhancedSumInsured.plan === 'Floater' && !hideESIColumns"
                                  [attr.rowspan]="selfCount"
                                >
                                  {{ item['Enhanced Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 5 &&
                                    policy.enhancedSumInsuredParent.plan === 'Floater' &&
                                    parentCount === 1 && !hideESIColumns
                                  "
                                >
                                  {{ item['Enhanced Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 6 &&
                                    policy.enhancedSumInsuredParent.plan === 'Floater' &&
                                    parentCount === 1 && !hideESIColumns
                                  "
                                >
                                  {{ item['Enhanced Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 5 &&
                                    policy.enhancedSumInsuredParent.plan === 'Floater' &&
                                    parentCount === 2 && !hideESIColumns
                                  "
                                  [attr.rowspan]="parentCount"
                                >
                                  {{ item['Enhanced Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 7 &&
                                    policy.enhancedSumInsuredParentInLaw.plan === 'Floater' &&
                                    parentInLawCount === 1 && !hideESIColumns
                                  " 
                                >
                                  {{ item['Enhanced Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 8 &&
                                    policy.enhancedSumInsuredParentInLaw.plan === 'Floater' &&
                                    parentInLawCount === 1 && !hideESIColumns
                                  "
                                >
                                  {{ item['Enhanced Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 7 &&
                                    policy.enhancedSumInsuredParentInLaw.plan === 'Floater' &&
                                    parentInLawCount === 2 && !hideESIColumns
                                  "
                                  [attr.rowspan]="parentInLawCount"
                                >
                                  {{ item['Enhanced Sum Insured'] }}
                                </td>

                                <td
                                  *ngIf="
                                    (item.relationshipId !== 5 &&
                                    item.relationshipId !== 6 &&
                                    item.relationshipId !== 7 &&
                                    item.relationshipId !== 8) &&
                                    policy.enhancedSumInsured.plan !== 'Floater' && !hideESIColumns
                                  "
                                >
                                  {{ item['Enhanced Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    (item.relationshipId === 5 || item.relationshipId === 6) &&
                                    policy.enhancedSumInsuredParent.plan !== 'Floater' && !hideESIColumns
                                  "
                                >
                                  {{ item['Enhanced Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    (item.relationshipId === 7 || item.relationshipId === 8) &&
                                    policy.enhancedSumInsuredParentInLaw.plan !== 'Floater' && !hideESIColumns
                                  "
                                >
                                  {{ item['Enhanced Sum Insured'] }}
                                </td>
                                <!-- Topup Sum Insured -->
                                <td
                                  *ngIf="item.relationshipId === 1 && policy.topupOption.plan === 'Floater' && !hideTSIColumns"
                                  [attr.rowspan]="selfCount"
                                >
                                  {{ item['Topup Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 5 &&
                                    policy.topupOptionParent.plan === 'Floater' &&
                                    parentCount === 1 && !hideTSIColumns
                                  "
                                >
                                  {{ item['Topup Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 6 &&
                                    policy.topupOptionParent.plan === 'Floater' &&
                                    parentCount === 1 && !hideTSIColumns
                                  "
                                >
                                  {{ item['Topup Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 5 &&
                                    policy.topupOptionParent.plan === 'Floater' &&
                                    parentCount === 2 && !hideTSIColumns
                                  "
                                  [attr.rowspan]="parentCount"
                                >
                                  {{ item['Topup Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 7 &&
                                    policy.topupOptionParentInLaw.plan === 'Floater' &&
                                    parentInLawCount === 1 && !hideTSIColumns
                                  "
                                >
                                  {{ item['Topup Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 8 &&
                                    policy.topupOptionParentInLaw.plan === 'Floater' &&
                                    parentInLawCount === 1 && !hideTSIColumns
                                  "
                                >
                                  {{ item['Topup Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    item.relationshipId === 7 &&
                                    policy.topupOptionParentInLaw.plan === 'Floater' &&
                                    parentInLawCount === 2 && !hideTSIColumns
                                  "
                                  [attr.rowspan]="parentInLawCount"
                                >
                                  {{ item['Topup Sum Insured'] }}
                                </td>

                                <td
                                  *ngIf="
                                    (item.relationshipId !== 5 &&
                                    item.relationshipId !== 6 &&
                                    item.relationshipId !== 7 &&
                                    item.relationshipId !== 8) &&
                                    policy.topupOption.plan !== 'Floater' && !hideTSIColumns
                                  "
                                >
                                  {{ item['Topup Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    (item.relationshipId === 5 || item.relationshipId === 6) &&
                                    policy.topupOptionParent.plan !== 'Floater' && !hideTSIColumns
                                  "
                                >
                                  {{ item['Topup Sum Insured'] }}
                                </td>
                                <td
                                  *ngIf="
                                    (item.relationshipId === 7 || item.relationshipId === 8) &&
                                    policy.topupOptionParentInLaw.plan !== 'Floater' && !hideTSIColumns
                                  "
                                >
                                  {{ item['Topup Sum Insured'] }}
                                </td>
                                <!-- Total Premium -->
                                <td *ngIf="item.relationshipId === 1 && !hideTPColumns" [attr.rowspan]="selfCount">
                                  <p *ngIf="totalSelfPremium !== 0 && selfPremiumVisible">
                                    <a (click)="openModel('Self')"
                                      ><u>{{ totalSelfPremium }}</u></a
                                    >
                                  </p>
                                  <p *ngIf="totalSelfPremium === 0 || !selfPremiumVisible"></p>
                                </td>

                                <td *ngIf="item.relationshipId === 5 && parentCount === 1 && !hideTPColumns">
                                  <p *ngIf="totalParentPremium !== 0 && parentPremiumVisible">
                                    <a (click)="openModel('Parent')"
                                      ><u>{{ totalParentPremium }}</u></a
                                    >
                                  </p>
                                  <p *ngIf="totalParentPremium === 0 || !parentPremiumVisible"></p>
                                </td>

                                <td *ngIf="item.relationshipId === 6 && parentCount === 1 && !hideTPColumns">
                                  <p *ngIf="totalParentPremium !== 0 && parentPremiumVisible">
                                    <a (click)="openModel('Parent')"
                                      ><u>{{ totalParentPremium }}</u></a
                                    >
                                  </p>
                                  <p *ngIf="totalParentPremium === 0 || !parentPremiumVisible"></p>
                                </td>

                                <td *ngIf="item.relationshipId === 5 && parentCount === 2 && !hideTPColumns" [attr.rowspan]="parentCount">
                                  <p *ngIf="totalParentPremium !== 0 && parentPremiumVisible">
                                    <a (click)="openModel('Parent')"
                                      ><u>{{ totalParentPremium }}</u></a
                                    >
                                  </p>
                                  <p *ngIf="totalParentPremium === 0 || !parentPremiumVisible"></p>
                                </td>

                                <td *ngIf="item.relationshipId === 7 && parentInLawCount === 1 && !hideTPColumns">
                                  <p *ngIf="totalParentInLawPremium !== 0 && parentInLawPremiumVisible">
                                    <a (click)="openModel('ParentInLaw')"
                                      ><u>{{ totalParentInLawPremium }}</u></a
                                    >
                                  </p>
                                  <p *ngIf="totalParentInLawPremium === 0 || !parentInLawPremiumVisible"></p>
                                </td>

                                <td *ngIf="item.relationshipId === 8 && parentInLawCount === 1 && !hideTPColumns">
                                  <p *ngIf="totalParentInLawPremium !== 0 && parentInLawPremiumVisible">
                                    <a (click)="openModel('ParentInLaw')"
                                      ><u>{{ totalParentInLawPremium }}</u></a
                                    >
                                  </p>
                                  <p *ngIf="totalParentInLawPremium === 0 || !parentInLawPremiumVisible"></p>
                                </td>

                                <td
                                  *ngIf="item.relationshipId === 7 && parentInLawCount === 2 && !hideTPColumns"
                                  [attr.rowspan]="parentInLawCount"
                                >
                                  <p *ngIf="totalParentInLawPremium !== 0 && parentInLawPremiumVisible">
                                    <a (click)="openModel('ParentInLaw')"
                                      ><u>{{ totalParentInLawPremium }}</u></a
                                    >
                                  </p>
                                  <p *ngIf="totalParentInLawPremium === 0 || !parentInLawPremiumVisible"></p>
                                </td>

                                <td *ngIf="item.Relationship === 'Self'">
                                  <div class="position-relative">
                                    <button
                                      type="button"
                                      class="btn btn-link float-right dropdown-toggle"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i class="material-icons">more_vert</i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <button
                                        class="dropdown-item"
                                        [hidden]="isfinalSubmissionLocked || !isPolicyStatusActive"
                                        routerLink="../edit/member"
                                      >
                                        Edit
                                      </button>
                                    </div>
                                  </div>
                                </td>
                                <td *ngIf="item.Relationship !== 'Self'">
                                  <div class="position-relative">
                                    <button
                                      type="button"
                                      class="btn btn-link float-right dropdown-toggle"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i class="material-icons">more_vert</i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <button
                                        class="dropdown-item"
                                        [hidden]="isfinalSubmissionLocked || !isPolicyStatusActive"
                                        routerLink="../edit/member/family/{{ item.id }}"
                                      >
                                        Edit
                                      </button>
                                      <button
                                        *ngIf="item.disableDependentDeletion !== true"
                                        class="dropdown-item"
                                        [hidden]="isfinalSubmissionLocked || !isPolicyStatusActive"
                                        (click)="delete(item)"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <div class="pb-5 col">
              <div class="btn-wrapper">
                <button
                  class="btn btn-primary px-4 float-right text-white"
                  [disabled]="isfinalSubmissionLocked || !isPolicyStatusActive || finalSubmissionProcessing"
                  data-toggle="modal"
                  data-target="#finalSubmission"
                >
                  Final Submission
                  <mat-icon
                    ><mat-spinner color="warn" diameter="23" *ngIf="finalSubmissionProcessing"> </mat-spinner
                  ></mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Final Submission Modal -->
  <div
    class="modal fade"
    id="finalSubmission"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    data-keyboard="false"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Do you really want to final submit ?</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group py-2 px-3 mb-0">
            <div>
              <h6>Once you submit the data cannot be editable.</h6>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-danger">No</button>
          <button type="button" data-dismiss="modal" (click)="finalSubmission()" class="btn btn-primary px-4">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--Add new family member Modal -->
  <div
    class="modal fade"
    id="addFamilyMemberModal"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    data-keyboard="false"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Family Member</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group py-2 px-3">
            <div *ngIf="relationships !== null">
              <h6>Select the Relationship of a member</h6>
              <select [(ngModel)]="relationshipSeleted" (ngModelChange)="checkSumInsured()" class="form-control">
                <option *ngFor="let item of relationships | keyvalue" [value]="item.value">{{ item.key }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-body" *ngIf="policy && relationshipSeleted !== undefined">
          <div class="form-group py-2 px-3">
            <div
              *ngIf="
                (policy.plan === 'Individual' && policy.parentalSumInsured.isAllowed === false) ||
                (policy.plan === 'Individual' &&
                  policy.parentalSumInsured.isAllowed === true &&
                  relationshipSeleted !== '5' &&
                  relationshipSeleted !== '6' &&
                  relationshipSeleted !== '7' &&
                  relationshipSeleted !== '8')
              "
            >
              <h6>Select sum insured</h6>
              <select [(ngModel)]="sumInsured" (ngModelChange)="removeCommasFromSuminsured()" class="form-control">
                <option *ngFor="let item of allowedSumInsuredString" [value]="item">{{ item }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer" *ngIf="policy">
          <button type="button" data-dismiss="modal" class="btn btn-danger">Cancel</button>
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-primary px-4"
            [disabled]="
              relationshipSeleted === null ||
              relationshipSeleted === undefined ||
              (policy.plan === 'Individual' &&
                policy.parentalSumInsured.isAllowed === false &&
                (sumInsured === null || sumInsured === undefined)) ||
              (policy.plan === 'Individual' &&
                policy.parentalSumInsured.isAllowed === true &&
                relationshipSeleted !== '5' &&
                relationshipSeleted !== '6' &&
                relationshipSeleted !== '7' &&
                relationshipSeleted !== '8' &&
                (sumInsured === null || sumInsured === undefined))
            "
            routerLink="../edit/member/family/new/{{ relationshipSeleted }}/{{ sumInsuredIntValue }}"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
