import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/business-admin/services/master.service';
import { PreEnrollmentService } from 'src/app/business-admin/services/preEnrollment.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CorporateModel } from 'src/app/shared/models/corporate/corporate.model';
import { EnhancedSumInsured } from 'src/app/shared/models/preEnrollment/policies/enhanced-sum-insured.model';
import { EsiValues } from 'src/app/shared/models/preEnrollment/policies/esi-values.model';
import { ParentalSumInsured } from 'src/app/shared/models/preEnrollment/policies/Parental-sum-insured.model';
import { PreEnrollmentPolicyModel } from 'src/app/shared/models/preEnrollment/policies/preEnrollment-policy.model';
import { PreEnrollmentAdditionSumOptionModel } from 'src/app/shared/models/preEnrollment/policies/PreEnrollmentAdditionSumOptionModel';
import { PsiPremium } from 'src/app/shared/models/preEnrollment/policies/psi-premium.model';
import { TopupModel } from 'src/app/shared/models/preEnrollment/policies/topup-model.model';
import { TopupValues } from 'src/app/shared/models/preEnrollment/policies/topup-values.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BackendService } from '../../../../../shared/services/backend.service';
import { PreEnrollmentPolicyMemberFieldModel } from '../../../../../shared/models/preEnrollment/policies/PreEnrollmentPolicyMemberFieldModel';
import { AllowedSumInsuredModel } from 'src/app/shared/models/preEnrollment/policies/allowedSumInsuredModel';
import { PeGradeSiModel } from 'src/app/shared/models/preEnrollment/policies/peGradeSiModel';
import { NoteMsgDialogModel } from 'src/app/shared/components/note-msg/note-msg-dialog-model.model';
import { NoteMsgComponent } from 'src/app/shared/components/note-msg/note-msg.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/ConfirmDialogModel';
import { SiExtrabenefits } from 'src/app/shared/models/preEnrollment/policies/si-extrabenefits.model';
import { PeSelfModel } from 'src/app/shared/models/preEnrollment/policies/pe-self-model.model';
import { PeExtraBenefits } from 'src/app/shared/models/preEnrollment/policies/pe-extra-benefits.model';
import { PremiumDetails } from 'src/app/shared/models/preEnrollment/premium/premium-details.model';

@Component({
  selector: 'app-configure-sumnsured',
  templateUrl: './configure-sumnsured.component.html',
  styleUrls: ['./configure-sumnsured.component.scss'],
})
export class ConfigureSumnsuredComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() relationshipType = '';
  @Input() sumInsuredType = '';
  @Input() policy: PreEnrollmentPolicyModel;
  @Input() corporate: CorporateModel;
  @Output() public cancelConfigurePremium = new EventEmitter<boolean>();

  setPolicyName = 1;
  sumInsuredValuesMissing = false;

  parentalSumInsuredOptionAvailable = false;
  areTwinsAllowed = false;
  parentInLawConditionAllowed = false;

  isSaveButtonEnable = true;
  isPlanNull = false;
  rshipFieldsValid = true;
  dynamicArray: any;
  populateArray: any;
  esiPremiums: any;

  repeatedSumInsuredValues = false;

  parentalSumInsured: ParentalSumInsured;
  enhancedSumInsured: EnhancedSumInsured;
  topupOption: TopupModel;
  siExtraBenefits: SiExtrabenefits;

  displayUi = false;

  isValueInvalid: boolean;
  isGradeInvalid: boolean;
  isMultipleInvalid: boolean;
  hideGradewise = false;
  hideSumInsuredFields = false;

  allowedSumInsured: AllowedSumInsuredModel;
  sumInsuredNotApplicable = false;

  currentExtraBenefitPremiumLength: number;
  currentSumInsuredType: string;
  showEditOption = false;
  errorList = [];

  premiumDetails: PremiumDetails = new PremiumDetails();

  constructor(
    injector: Injector,
    private readonly masterService: MasterService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly preenrollmentService: PreEnrollmentService,
    private backendService: BackendService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    console.log(this.relationshipType);
    console.log(this.sumInsuredType);
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.displayUi = false;
    this.showEditOption = false;
    this.errorList = [];
    this.currentExtraBenefitPremiumLength = undefined;
    this.currentSumInsuredType = undefined;
    await this.checkFieldsMapping();
    await this.checkSuminsuredMapping();
    if (changes['relationshipType'] !== undefined) {
      this.getPremiumDetails();
    }

    this.getSI().then(async result => {
      if (this.relationshipType === 'Self') {
        if (
          this.allowedSumInsured.sumInsuredList.length === 0 &&
          (this.sumInsuredType === 'ESI' || this.sumInsuredType === 'TopUp')
        ) {
          this.sumInsuredNotApplicable = true;
          this.displayUi = true;
        } else {
          this.sumInsuredNotApplicable = false;
          if (this.sumInsuredType === 'ESI') {
            this.getESIExtraBenefits();
          } else if (this.sumInsuredType === 'TopUp') {
            this.getTopupExtraBenefits();
          } else if (this.sumInsuredType === 'SI') {
            this.displayUi = true;
            await this.sleep(500);
            document.getElementById('si_div').classList.add('disabled');
            this.showEditOption = true;
          }
        }
      } else if (this.relationshipType === 'Parent' || this.relationshipType === 'Parent-In-Law') {
        if (this.allowedSumInsured.sumInsuredList.length === 0 && this.sumInsuredType === 'PSI') {
          this.sumInsuredNotApplicable = true;
          this.displayUi = true;
        } else {
          this.getPSIExtraBenefits().then(async result => {
            if (
              !this.parentalSumInsured.peExtraBenefits.isAllowed &&
              (this.sumInsuredType === 'ESI' || this.sumInsuredType === 'TopUp')
            ) {
              this.sumInsuredNotApplicable = true;
              this.displayUi = true;
            } else {
              this.sumInsuredNotApplicable = false;
              if (this.sumInsuredType === 'ESI') {
                this.getESIExtraBenefits().then(x => {
                  this.displayUi = true;
                });
              } else if (this.sumInsuredType === 'TopUp') {
                this.getTopupExtraBenefits().then(x => {
                  this.displayUi = true;
                });
              } else if (this.sumInsuredType === 'PSI') {
                this.displayUi = true;
                await this.sleep(500);
                document.getElementById('psi_div').classList.add('disabled');
                this.showEditOption = true;
              }
            }
          });
        }
      }
    });
  }

  async getSI() {
    await this.preenrollmentService
      .getSumInsured(this.corporate.id, this.policy.id)
      .then(result => {
        this.allowedSumInsured = new AllowedSumInsuredModel(result);
      })
      .catch(error => {
        this.allowedSumInsured = new AllowedSumInsuredModel(null);
      });
  }

  async checkFieldsMapping() {
    const promise = new Promise<PreEnrollmentPolicyMemberFieldModel[]>(async resolve => {
      let memberFields: any;
      memberFields = await this.backendService.get('FieldMaster/' + this.policy.id);
      resolve(memberFields);
    });

    const grade = (await promise).filter(x => x.label === 'Grade');
    if (grade.length > 0) {
      const gradeFound = grade[0];
      if (
        gradeFound.isEnabled === false ||
        gradeFound.isMandatoryForDependent === false ||
        gradeFound.isMandatoryForSelf === false
      ) {
        this.hideGradewise = true;
      } else {
        this.hideGradewise = false;
      }
    }
  }

  async checkSuminsuredMapping() {
    const promise = new Promise<PreEnrollmentPolicyMemberFieldModel[]>(async resolve => {
      let memberFields: any;
      memberFields = await this.backendService.get('FieldMaster/' + this.policy.id);
      resolve(memberFields);
    });

    const sumInsured = (await promise).filter(x => x.label === 'Sum Insured');
    if (sumInsured.length > 0) {
      const sumInsuredFound = sumInsured[0];
      if (
        sumInsuredFound.isEnabled === false ||
        sumInsuredFound.isMandatoryForDependent === false ||
        sumInsuredFound.isMandatoryForSelf === false
      ) {
        this.hideSumInsuredFields = true;
      } else {
        this.hideSumInsuredFields = false;
      }
    }
  }

  async getPSIExtraBenefits() {
    await this.preenrollmentService
      .getPSIExtraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.parentalSumInsured = new ParentalSumInsured(result);
          this.currentSumInsuredType = this.parentalSumInsured.peExtraBenefits.sumInsuredType;
          this.displayUi = true;
          this.changeSumInsuredValue('PSI');
        } else {
          this.parentalSumInsured = new ParentalSumInsured(null);
          this.displayUi = true;
        }
      })
      .catch(error => {
        this.parentalSumInsured = new ParentalSumInsured(null);
        this.displayUi = true;
      });
  }

  async getESIExtraBenefits() {
    await this.preenrollmentService
      .getESIExtraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.enhancedSumInsured = new EnhancedSumInsured(result);
          this.currentSumInsuredType = this.enhancedSumInsured.peExtraBenefits.sumInsuredType;
          this.displayUi = true;
          this.changeSumInsuredValue('ESI');
        } else {
          this.enhancedSumInsured = new EnhancedSumInsured(null);
          this.displayUi = true;
        }
      })
      .catch(error => {
        this.enhancedSumInsured = new EnhancedSumInsured(null);
        this.displayUi = true;
      });
  }

  async getTopupExtraBenefits() {
    await this.preenrollmentService
      .getTopupExtraBenefits(this.policy.id, this.relationshipType)
      .then(result => {
        if (result && 'peExtraBenefits' in result) {
          this.topupOption = new TopupModel(result);
          this.currentSumInsuredType = this.topupOption.peExtraBenefits.sumInsuredType;
          this.displayUi = true;
          this.changeSumInsuredValue('TopUp');
        } else {
          this.topupOption = new TopupModel(null);
          this.displayUi = true;
        }
      })
      .catch(error => {
        this.topupOption = new TopupModel(null);
        this.displayUi = true;
      });
  }

  changeSumInsuredValue(sumInsuredType) {
    if (sumInsuredType === 'PSI') {
      for (let index = 0; index < this.parentalSumInsured.pePsi.length; index++) {
        this.parentalSumInsured.pePsi[index]['stringValue'] = this.parentalSumInsured.pePsi[
          index
        ].sumInsured.toString();
      }
    } else if (sumInsuredType === 'ESI') {
      for (let index = 0; index < this.enhancedSumInsured.peEsi.length; index++) {
        this.enhancedSumInsured.peEsi[index]['stringValue'] = this.enhancedSumInsured.peEsi[
          index
        ].sumInsured.toString();
      }
    } else if (sumInsuredType === 'TopUp') {
      for (let index = 0; index < this.topupOption.peTopUp.length; index++) {
        this.topupOption.peTopUp[index]['stringValue'] = this.topupOption.peTopUp[index].sumInsured.toString();
      }
    }
  }

  addNewEntry() {
    if (this.sumInsuredType === 'PSI') {
      this.parentalSumInsured.pePsi.push(new PsiPremium(null));
    } else if (this.sumInsuredType === 'ESI') {
      this.enhancedSumInsured.peEsi.push(new EsiValues(null));
    } else if (this.sumInsuredType === 'TopUp') {
      this.topupOption.peTopUp.push(new TopupValues(null));
    } else if (this.sumInsuredType === 'SI') {
      this.allowedSumInsured.sumInsuredList.push(new PeGradeSiModel(null));
    }

    this.sumInsuredTypeChanged();
  }

  removeEntry(index) {
    if (this.sumInsuredType === 'PSI') {
      this.parentalSumInsured.pePsi.splice(index, 1);
    } else if (this.sumInsuredType === 'ESI') {
      this.enhancedSumInsured.peEsi.splice(index, 1);
    } else if (this.sumInsuredType === 'TopUp') {
      this.topupOption.peTopUp.splice(index, 1);
    } else if (this.sumInsuredType === 'SI') {
      this.allowedSumInsured.sumInsuredList.splice(index, 1);
    }
  }

  openAlertBox() {
    const message = `You can only change the sum insured type after deleting all the existing premium details`;
    const dialogData = new NoteMsgDialogModel('Alert', message);
    const dialogRef = this.dialog.open(NoteMsgComponent, {
      data: dialogData,
      disableClose: true,
    });
  }

  async sumInsuredTypeOptionChanged() {
    if (this.sumInsuredType === 'PSI') {
      await this.getPSIPremiumData(this.parentalSumInsured.peExtraBenefits.id);
      if (
        this.currentExtraBenefitPremiumLength !== null &&
        this.currentExtraBenefitPremiumLength !== undefined &&
        this.currentExtraBenefitPremiumLength === 0
      ) {
        this.parentalSumInsured.pePsi = [];
        this.addNewEntry();
      } else {
        this.parentalSumInsured.peExtraBenefits.sumInsuredType = this.currentSumInsuredType;
        this.openAlertBox();
      }
    } else if (this.sumInsuredType === 'ESI') {
      await this.getESIPremiumData(this.enhancedSumInsured.peExtraBenefits.id);
      if (
        this.currentExtraBenefitPremiumLength !== null &&
        this.currentExtraBenefitPremiumLength !== undefined &&
        this.currentExtraBenefitPremiumLength === 0
      ) {
        this.enhancedSumInsured.peEsi = [];
        this.addNewEntry();
      } else {
        this.enhancedSumInsured.peExtraBenefits.sumInsuredType = this.currentSumInsuredType;
        this.openAlertBox();
      }
    } else if (this.sumInsuredType === 'TopUp') {
      await this.getTopupPremiumData(this.topupOption.peExtraBenefits.id);
      if (
        this.currentExtraBenefitPremiumLength !== null &&
        this.currentExtraBenefitPremiumLength !== undefined &&
        this.currentExtraBenefitPremiumLength === 0
      ) {
        this.topupOption.peTopUp = [];
        this.addNewEntry();
      } else {
        this.topupOption.peExtraBenefits.sumInsuredType = this.currentSumInsuredType;
        this.openAlertBox();
      }
    }
  }

  sumInsuredTypeChanged() {
    if (this.sumInsuredType === 'PSI') {
      this.parentalSumInsured.pePsi.forEach(element => {
        element.type = this.parentalSumInsured.peExtraBenefits.sumInsuredType;
      });
    } else if (this.sumInsuredType === 'ESI') {
      this.enhancedSumInsured.peEsi.forEach(element => {
        element.type = this.enhancedSumInsured.peExtraBenefits.sumInsuredType;
      });
    } else if (this.sumInsuredType === 'TopUp') {
      this.topupOption.peTopUp.forEach(element => {
        element.type = this.topupOption.peExtraBenefits.sumInsuredType;
      });
    }
  }

  async saveSumInsured() {
    if (this.sumInsuredType === 'PSI') {
      this.addUpdatePSI();
    } else if (this.sumInsuredType === 'ESI') {
      this.addUpdateESI();
    } else if (this.sumInsuredType === 'TopUp') {
      this.addUpdateTopupSumInsured();
    } else if (this.sumInsuredType === 'SI') {
      this.addUpdateSumInsured();
    }
  }

  async addUpdateSumInsured() {
    this.errorList = [];
    const isSIValid = this.allowedSumInsured.isValid();
    if (isSIValid) {
      if (this.hideGradewise) {
        this.allowedSumInsured.selfSumInsuredType = 'Defined';
        this.allowedSumInsured.sumInsuredList.forEach(item => {
          item.grade = null;
        });
      } else {
        this.allowedSumInsured.selfSumInsuredType = 'GradeWiseDefined';
      }
      this.addUpdateSIExtrabenefit();
    }
  }

  async addUpdateSIExtrabenefit() {
    let status, resp;
    resp = await this.getSIextraBenefits();
    this.siExtraBenefits = new SiExtrabenefits(null);
    if (resp.peExtraBenefits.id !== 0) {
      status = 'update';
      this.siExtraBenefits.peExtraBenefits = resp.peExtraBenefits;
      this.siExtraBenefits.peExtraBenefits.sumInsuredType = this.allowedSumInsured.selfSumInsuredType;
    } else {
      status = 'create';
      this.siExtraBenefits.peExtraBenefits.id = 0;
      this.siExtraBenefits.peExtraBenefits.pePolicyId = this.policy.id;
      this.siExtraBenefits.peExtraBenefits.relationship = 'SELF';
      this.siExtraBenefits.peExtraBenefits.benefitType = this.sumInsuredType;
      this.siExtraBenefits.peExtraBenefits.isAllowed = true;
      this.siExtraBenefits.peExtraBenefits.sumInsuredType = this.allowedSumInsured.selfSumInsuredType;
      this.siExtraBenefits.peExtraBenefits.plan = this.policy.plan;
      this.siExtraBenefits.peExtraBenefits.isPremiumApplicable = this.premiumDetails.isPremiumApplicable;
      this.siExtraBenefits.peExtraBenefits.isPremiumVisible = this.premiumDetails.isPremiumVisible;
      this.siExtraBenefits.peExtraBenefits.isRefundApplicable = this.premiumDetails.isRefundApplicable;
      this.siExtraBenefits.peExtraBenefits.premiumType = this.premiumDetails.premiumType;
    }

    for (let i = 0; i < this.allowedSumInsured.sumInsuredList.length; i++) {
      this.siExtraBenefits.peSelfModels.push(new PeSelfModel(null));
      this.siExtraBenefits.peSelfModels[i].id = 0;
      this.siExtraBenefits.peSelfModels[i].singlePremium = 0;
      this.siExtraBenefits.peSelfModels[i].doublePremium = 0;
      this.siExtraBenefits.peSelfModels[i].relationship = 'SELF';
      this.siExtraBenefits.peSelfModels[i].discount = 0;
      this.siExtraBenefits.peSelfModels[i].gST = 0;
      this.siExtraBenefits.peSelfModels[i].total = 0;
      this.siExtraBenefits.peSelfModels[i].pePolicyId = this.policy.id;
      this.siExtraBenefits.peSelfModels[i].extraBenefitsId = this.siExtraBenefits.peExtraBenefits.id;
      this.siExtraBenefits.peSelfModels[i].sumInsured = this.allowedSumInsured.sumInsuredList[i].sumInsured;
      this.siExtraBenefits.peSelfModels[i].grade = this.allowedSumInsured.sumInsuredList[i].grade;
      this.siExtraBenefits.peSelfModels[i].type = this.siExtraBenefits.peExtraBenefits.sumInsuredType;
    }

    if (status === 'create') {
      this.createSIextraBenefits(this.siExtraBenefits);
    } else {
      this.updateSIextraBenefits(this.siExtraBenefits);
    }
  }

  async createSIextraBenefits(data) {
    this.errorList = [];
    await this.preenrollmentService
      .createSIextraBenefits(this.policy.id, data)
      .then(async result => {
        this.displayUi = false;
        this.alertService.success('Sum Insured Updated Successfully.');
        await this.preenrollmentService
          .updateSumInsured(this.corporate.id, this.policy.id, this.allowedSumInsured)
          .then(result => {
            this.getSI();
          });
        this.displayUi = true;
      })
      .catch(error => {
        this.errorList = error.error.errors;
        this.alertService.error('Error occured');
        this.displayUi = true;
      });
  }

  async updateSIextraBenefits(data) {
    this.errorList = [];
    await this.preenrollmentService
      .updateSIextraBenefits(this.policy.id, data)
      .then(async result => {
        this.displayUi = false;
        this.alertService.success('Sum Insured Updated Successfully.');
        await this.preenrollmentService
          .updateSumInsured(this.corporate.id, this.policy.id, this.allowedSumInsured)
          .then(result => {
            this.getSI();
          });
        this.displayUi = true;
      })
      .catch(error => {
        this.errorList = error.error.errors;
        this.alertService.error('Error occured');
        this.displayUi = true;
      });
  }

  async getSIextraBenefits() {
    const resp = await this.preenrollmentService.getSIextraBenefits(this.policy.id, 'SELF');
    return resp;
  }

  async addUpdatePSI() {
    this.errorList = [];
    const isPSIValid = this.parentalSumInsured.isValid();
    if (isPSIValid) {
      for (let i = 0; i < this.parentalSumInsured.pePsi.length; i++) {
        this.parentalSumInsured.pePsi[i].id = 0;
        this.parentalSumInsured.pePsi[i].singlePremium = 0;
        this.parentalSumInsured.pePsi[i].doublePremium = 0;
        this.parentalSumInsured.pePsi[i].relationship = 'SELF';
        this.parentalSumInsured.pePsi[i].discount = 0;
        this.parentalSumInsured.pePsi[i].gST = 0;
        this.parentalSumInsured.pePsi[i].total = 0;
        this.parentalSumInsured.pePsi[i].pePolicyId = this.parentalSumInsured.peExtraBenefits.pePolicyId;
        this.parentalSumInsured.pePsi[i].extraBenefitsId = this.parentalSumInsured.peExtraBenefits.id;
        this.parentalSumInsured.pePsi[i].sumInsured =
          this.parentalSumInsured.pePsi[i].stringValue !== undefined
            ? Number(this.parentalSumInsured.pePsi[i].stringValue.toString().replace(/,/g, ''))
            : 0;
      }
      this.parentalSumInsured.peExtraBenefits.benefitType = this.sumInsuredType;
      this.parentalSumInsured.peExtraBenefits.relationship = this.relationshipType;
      this.parentalSumInsured.peExtraBenefits.isPremiumApplicable = this.premiumDetails.isPremiumApplicable;
      this.parentalSumInsured.peExtraBenefits.isPremiumVisible = this.premiumDetails.isPremiumVisible;
      this.parentalSumInsured.peExtraBenefits.isRefundApplicable = this.premiumDetails.isRefundApplicable;
      this.parentalSumInsured.peExtraBenefits.premiumType = this.premiumDetails.premiumType;
      if (this.parentalSumInsured.peExtraBenefits.id !== 0) {
        this.updateESIPolicyPlan();
        this.updateTopupPolicyPlan();
        await this.preenrollmentService
          .updatePSIExtraBenefits(this.policy.id, this.parentalSumInsured)
          .then(result => {
            this.displayUi = false;
            this.alertService.success('PSI Details Updated Successfully.');
            this.getPSIExtraBenefits();
          })
          .catch(error => {
            this.errorList = error.error.errors;
            this.alertService.error('Error occured');
          });
      } else {
        this.updateESIPolicyPlan();
        this.updateTopupPolicyPlan();
        await this.preenrollmentService
          .addPSIExtraBenefits(this.policy.id, this.parentalSumInsured)
          .then(result => {
            this.displayUi = false;
            this.alertService.success('PSI Details Added Successfully.');
            this.getPSIExtraBenefits();
          })
          .catch(error => {
            this.errorList = error.error.errors;
            this.alertService.error('Error occured');
          });
      }
    }
  }

  async addUpdateESI() {
    this.errorList = [];
    const isESIValid = this.enhancedSumInsured.isValid();
    if (isESIValid) {
      this.enhancedSumInsured.peExtraBenefits.isPremiumApplicable = this.premiumDetails.isPremiumApplicable;
      this.enhancedSumInsured.peExtraBenefits.isPremiumVisible = this.premiumDetails.isPremiumVisible;
      this.enhancedSumInsured.peExtraBenefits.isRefundApplicable = this.premiumDetails.isRefundApplicable;
      this.enhancedSumInsured.peExtraBenefits.premiumType = this.premiumDetails.premiumType;
      if (this.enhancedSumInsured.peExtraBenefits.sumInsuredType !== 'EqualToSumInsured') {
        this.enhancedSumInsured.peExtraBenefits.benefitType = this.sumInsuredType;
        this.enhancedSumInsured.peExtraBenefits.relationship = this.relationshipType;
        for (let i = 0; i < this.enhancedSumInsured.peEsi.length; i++) {
          this.enhancedSumInsured.peEsi[i].id = 0;
          this.enhancedSumInsured.peEsi[i].singlePremium = 0;
          this.enhancedSumInsured.peEsi[i].doublePremium = 0;
          this.enhancedSumInsured.peEsi[i].relationship = 'SELF';
          this.enhancedSumInsured.peEsi[i].discount = 0;
          this.enhancedSumInsured.peEsi[i].gST = 0;
          this.enhancedSumInsured.peEsi[i].total = 0;
          this.enhancedSumInsured.peEsi[i].pePolicyId = this.enhancedSumInsured.peExtraBenefits.pePolicyId;
          this.enhancedSumInsured.peEsi[i].extraBenefitsId = this.enhancedSumInsured.peExtraBenefits.id;
          this.enhancedSumInsured.peEsi[i].sumInsured =
            this.enhancedSumInsured.peEsi[i].stringValue !== undefined
              ? Number(this.enhancedSumInsured.peEsi[i].stringValue.toString().replace(/,/g, ''))
              : 0;
        }
      } else {
        this.updateESIBasedOnRship();
      }

      if (this.enhancedSumInsured.peExtraBenefits.id !== 0) {
        await this.preenrollmentService
          .updateESIExtraBenefits(this.policy.id, this.enhancedSumInsured)
          .then(result => {
            this.displayUi = false;
            this.alertService.success('ESI Details Updated Successfully.');
            this.getESIExtraBenefits();
          })
          .catch(error => {
            this.errorList = error.error.errors;
            this.alertService.error('Error occured');
          });
      } else {
        if (this.relationshipType === 'Self') {
          this.enhancedSumInsured.peExtraBenefits.plan = this.policy.plan;
        } else {
          this.enhancedSumInsured.peExtraBenefits.plan = this.parentalSumInsured.peExtraBenefits.plan;
        }
        await this.preenrollmentService
          .addESIExtraBenefits(this.policy.id, this.enhancedSumInsured)
          .then(result => {
            this.displayUi = false;
            this.alertService.success('ESI Details Added Successfully.');
            this.getESIExtraBenefits();
          })
          .catch(error => {
            this.errorList = error.error.errors;
            this.alertService.error('Error occured');
          });
      }
    }
  }

  async addUpdateTopupSumInsured() {
    this.errorList = [];
    const isTopupValid = this.topupOption.isValid();
    if (isTopupValid) {
      this.topupOption.peExtraBenefits.isPremiumApplicable = this.premiumDetails.isPremiumApplicable;
      this.topupOption.peExtraBenefits.isPremiumVisible = this.premiumDetails.isPremiumVisible;
      this.topupOption.peExtraBenefits.isRefundApplicable = this.premiumDetails.isRefundApplicable;
      this.topupOption.peExtraBenefits.premiumType = this.premiumDetails.premiumType;
      if (this.topupOption.peExtraBenefits.sumInsuredType !== 'EqualToSumInsured') {
        this.topupOption.peExtraBenefits.benefitType = this.sumInsuredType;
        this.topupOption.peExtraBenefits.relationship = this.relationshipType;
        for (let i = 0; i < this.topupOption.peTopUp.length; i++) {
          this.topupOption.peTopUp[i].id = 0;
          this.topupOption.peTopUp[i].singlePremium = 0;
          this.topupOption.peTopUp[i].doublePremium = 0;
          this.topupOption.peTopUp[i].relationship = 'SELF';
          this.topupOption.peTopUp[i].discount = 0;
          this.topupOption.peTopUp[i].gST = 0;
          this.topupOption.peTopUp[i].total = 0;
          this.topupOption.peTopUp[i].pePolicyId = this.topupOption.peExtraBenefits.pePolicyId;
          this.topupOption.peTopUp[i].extraBenefitsId = this.topupOption.peExtraBenefits.id;
          this.topupOption.peTopUp[i].sumInsured =
            this.topupOption.peTopUp[i].stringValue !== undefined
              ? Number(this.topupOption.peTopUp[i].stringValue.toString().replace(/,/g, ''))
              : 0;
        }
      } else {
        this.updateTopupBasedOnRship();
      }

      if (this.topupOption.peExtraBenefits.id !== 0) {
        await this.preenrollmentService
          .updateTopupExtraBenefits(this.policy.id, this.topupOption)
          .then(result => {
            this.displayUi = false;
            this.alertService.success('Topup Sum Insured Details Updated Successfully.');
            this.getTopupExtraBenefits();
          })
          .catch(error => {
            this.errorList = error.error.errors;
            this.alertService.error('Error occured');
          });
      } else {
        if (this.relationshipType === 'Self') {
          this.topupOption.peExtraBenefits.plan = this.policy.plan;
        } else {
          this.topupOption.peExtraBenefits.plan = this.parentalSumInsured.peExtraBenefits.plan;
        }
        await this.preenrollmentService
          .addTopupExtraBenefits(this.policy.id, this.topupOption)
          .then(result => {
            this.displayUi = false;
            this.alertService.success('Topup Sum Insured Details Added Successfully.');
            this.getTopupExtraBenefits();
          })
          .catch(error => {
            this.errorList = error.error.errors;
            this.alertService.error('Error occured');
          });
      }
    }
  }

  async updateTopupBasedOnRship() {
    if (this.relationshipType === 'Self') {
      this.topupOption.peExtraBenefits.benefitType = 'TopUp';
      this.topupOption.peExtraBenefits.relationship = 'SELF';
      this.topupOption.peExtraBenefits.plan = this.policy.plan;

      for (let i = 0; i < this.allowedSumInsured.sumInsuredList.length; i++) {
        this.topupOption.peTopUp[i] = new TopupValues(null);
        this.topupOption.peTopUp[i].id = 0;
        this.topupOption.peTopUp[i].singlePremium = 0;
        this.topupOption.peTopUp[i].doublePremium = 0;
        this.topupOption.peTopUp[i].relationship = 'SELF';
        this.topupOption.peTopUp[i].discount = 0;
        this.topupOption.peTopUp[i].gST = 0;
        this.topupOption.peTopUp[i].total = 0;
        this.topupOption.peTopUp[i].grade = this.allowedSumInsured.sumInsuredList[i].grade;
        this.topupOption.peTopUp[i].pePolicyId = this.topupOption.peExtraBenefits.pePolicyId;
        this.topupOption.peTopUp[i].extraBenefitsId = this.topupOption.peExtraBenefits.id;
        this.topupOption.peTopUp[i].sumInsured = Number(
          this.allowedSumInsured.sumInsuredList[i].sumInsured.toString().replace(/,/g, '')
        );
      }
    } else if (this.relationshipType === 'Parent' || this.relationshipType === 'Parent-In-Law') {
      this.topupOption.peExtraBenefits.benefitType = 'TopUp';
      this.topupOption.peExtraBenefits.relationship = this.relationshipType;
      this.topupOption.peExtraBenefits.plan = this.parentalSumInsured.peExtraBenefits.plan;

      for (let i = 0; i < this.parentalSumInsured.pePsi.length; i++) {
        this.topupOption.peTopUp[i] = new TopupValues(null);
        this.topupOption.peTopUp[i].id = 0;
        this.topupOption.peTopUp[i].singlePremium = 0;
        this.topupOption.peTopUp[i].doublePremium = 0;
        this.topupOption.peTopUp[i].relationship = this.relationshipType;
        this.topupOption.peTopUp[i].discount = 0;
        this.topupOption.peTopUp[i].gST = 0;
        this.topupOption.peTopUp[i].total = 0;
        this.topupOption.peTopUp[i].grade = this.parentalSumInsured.pePsi[i].grade;
        this.topupOption.peTopUp[i].pePolicyId = this.topupOption.peExtraBenefits.pePolicyId;
        this.topupOption.peTopUp[i].extraBenefitsId = this.topupOption.peExtraBenefits.id;
        this.topupOption.peTopUp[i].sumInsured = Number(
          this.parentalSumInsured.pePsi[i].sumInsured.toString().replace(/,/g, '')
        );
      }
    }
  }

  async updateESIBasedOnRship() {
    if (this.relationshipType === 'Self') {
      this.enhancedSumInsured.peExtraBenefits.benefitType = 'ESI';
      this.enhancedSumInsured.peExtraBenefits.relationship = 'SELF';
      this.enhancedSumInsured.peExtraBenefits.plan = this.policy.plan;

      for (let i = 0; i < this.allowedSumInsured.sumInsuredList.length; i++) {
        this.enhancedSumInsured.peEsi[i] = new EsiValues(null);
        this.enhancedSumInsured.peEsi[i].id = 0;
        this.enhancedSumInsured.peEsi[i].singlePremium = 0;
        this.enhancedSumInsured.peEsi[i].doublePremium = 0;
        this.enhancedSumInsured.peEsi[i].relationship = 'SELF';
        this.enhancedSumInsured.peEsi[i].discount = 0;
        this.enhancedSumInsured.peEsi[i].gST = 0;
        this.enhancedSumInsured.peEsi[i].total = 0;
        this.enhancedSumInsured.peEsi[i].grade = this.allowedSumInsured.sumInsuredList[i].grade;
        this.enhancedSumInsured.peEsi[i].pePolicyId = this.enhancedSumInsured.peExtraBenefits.pePolicyId;
        this.enhancedSumInsured.peEsi[i].extraBenefitsId = this.enhancedSumInsured.peExtraBenefits.id;
        this.enhancedSumInsured.peEsi[i].sumInsured = Number(this.allowedSumInsured.sumInsuredList[i].sumInsured);
      }
    } else if (this.relationshipType === 'Parent' || this.relationshipType === 'Parent-In-Law') {
      this.enhancedSumInsured.peExtraBenefits.benefitType = 'ESI';
      this.enhancedSumInsured.peExtraBenefits.relationship = this.relationshipType;
      this.enhancedSumInsured.peExtraBenefits.plan = this.parentalSumInsured.peExtraBenefits.plan;

      for (let i = 0; i < this.parentalSumInsured.pePsi.length; i++) {
        this.enhancedSumInsured.peEsi[i] = new EsiValues(null);
        this.enhancedSumInsured.peEsi[i].id = 0;
        this.enhancedSumInsured.peEsi[i].singlePremium = 0;
        this.enhancedSumInsured.peEsi[i].doublePremium = 0;
        this.enhancedSumInsured.peEsi[i].relationship = this.relationshipType;
        this.enhancedSumInsured.peEsi[i].discount = 0;
        this.enhancedSumInsured.peEsi[i].gST = 0;
        this.enhancedSumInsured.peEsi[i].total = 0;
        this.enhancedSumInsured.peEsi[i].grade = this.parentalSumInsured.pePsi[i].grade;
        this.enhancedSumInsured.peEsi[i].pePolicyId = this.enhancedSumInsured.peExtraBenefits.pePolicyId;
        this.enhancedSumInsured.peEsi[i].extraBenefitsId = this.enhancedSumInsured.peExtraBenefits.id;
        this.enhancedSumInsured.peEsi[i].sumInsured = Number(
          this.parentalSumInsured.pePsi[i].sumInsured.toString().replace(/,/g, '')
        );
      }
    }
  }

  async getESIPremiumData(extraBenefitId) {
    await this.preenrollmentService
      .getESIPremium(extraBenefitId)
      .then(result => {
        console.log(result);
        this.currentExtraBenefitPremiumLength = result.length;
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getPSIPremiumData(extraBenefitId) {
    await this.preenrollmentService
      .getPSIPremium(extraBenefitId)
      .then(result => {
        console.log(result);
        this.currentExtraBenefitPremiumLength = result.length;
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getTopupPremiumData(extraBenefitId) {
    await this.preenrollmentService
      .getTopupPremium(extraBenefitId)
      .then(result => {
        console.log(result);
        this.currentExtraBenefitPremiumLength = result.length;
      })
      .catch(error => {
        console.log(error);
      });
  }

  async editSumInsured() {
    await this.getESIExtraBenefits();
    await this.getTopupExtraBenefits();
    if (
      (this.enhancedSumInsured.peExtraBenefits.isAllowed &&
        this.enhancedSumInsured.peExtraBenefits.sumInsuredType !== 'Defined') ||
      (this.topupOption.peExtraBenefits.isAllowed && this.topupOption.peExtraBenefits.sumInsuredType !== 'Defined')
    ) {
      let result;
      const message = `You can only edit once you delete all other extrabenfits data depend on Base suminsured. Are you sure you want to delete all dependent data including suminusred and premiums?`;
      const dialogData = new ConfirmDialogModel('Confirm Action', message);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(async dialogResult => {
        result = dialogResult;
        if (result) {
          console.log(result);
          this.deleteDataInBulk();
        }
      });
    } else {
      if (this.relationshipType === 'Parent') {
        this.currentSumInsuredType = this.parentalSumInsured.peExtraBenefits.sumInsuredType;
      }
      this.enableEditOption();
    }
  }

  async deleteDataInBulk() {
    if (
      this.enhancedSumInsured.peExtraBenefits.isAllowed &&
      this.enhancedSumInsured.peExtraBenefits.sumInsuredType !== 'Defined'
    ) {
      await this.getESIPremiumData(this.enhancedSumInsured.peExtraBenefits.id);
      this.bulkDelete('ESI', this.enhancedSumInsured.peExtraBenefits.id);
      if (this.currentExtraBenefitPremiumLength > 0) {
        this.bulkDeletePremium('ESI', this.enhancedSumInsured.peExtraBenefits.id);
      }
    }
    if (this.topupOption.peExtraBenefits.isAllowed && this.topupOption.peExtraBenefits.sumInsuredType !== 'Defined') {
      await this.getTopupPremiumData(this.topupOption.peExtraBenefits.id);
      this.bulkDelete('TopUp', this.topupOption.peExtraBenefits.id);
      if (this.currentExtraBenefitPremiumLength > 0) {
        this.bulkDeletePremium('TopUp', this.topupOption.peExtraBenefits.id);
      }
    }
  }

  async bulkDelete(extraBenefitType, id) {
    await this.preenrollmentService
      .deleteBulkExtrabenefit(this.policy.id, id, extraBenefitType)
      .then(result => {
        this.enableEditOption();
      })
      .catch(error => {
        this.displayUi = true;
      });
  }

  async bulkDeletePremium(extraBenefitType, id) {
    await this.preenrollmentService
      .deleteBulkPremium(this.policy.id, id, extraBenefitType)
      .then(result => {
        this.enableEditOption();
      })
      .catch(error => {
        this.displayUi = true;
      });
  }

  enableEditOption() {
    this.showEditOption = false;
    if (this.relationshipType === 'Self') {
      document.getElementById('si_div').classList.remove('disabled');
    } else if (this.relationshipType === 'Parent' || this.relationshipType === 'Parent-In-Law') {
      document.getElementById('psi_div').classList.remove('disabled');
    }
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async updateESIPolicyPlan() {
    this.preenrollmentService.getESIExtraBenefits(this.policy.id, this.relationshipType).then(result => {
      if (result && 'peExtraBenefits' in result && result.peExtraBenefits.id !== 0) {
        this.enhancedSumInsured = new EnhancedSumInsured(result);
        if (this.enhancedSumInsured.peExtraBenefits.plan !== this.parentalSumInsured.peExtraBenefits.plan) {
          this.enhancedSumInsured.peExtraBenefits.plan = this.parentalSumInsured.peExtraBenefits.plan;
          for (let i = 0; i < this.enhancedSumInsured.peEsi.length; i++) {
            this.enhancedSumInsured.peEsi[i].id = 0;
          }
          this.preenrollmentService.updateESIExtraBenefits(this.policy.id, this.enhancedSumInsured);
        }
      }
    });
  }

  async updateTopupPolicyPlan() {
    this.preenrollmentService.getTopupExtraBenefits(this.policy.id, this.relationshipType).then(result => {
      if (result && 'peExtraBenefits' in result && result.peExtraBenefits.id !== 0) {
        this.topupOption = new TopupModel(result);
        if (this.topupOption.peExtraBenefits.plan !== this.parentalSumInsured.peExtraBenefits.plan) {
          this.topupOption.peExtraBenefits.plan = this.parentalSumInsured.peExtraBenefits.plan;
          for (let i = 0; i < this.topupOption.peTopUp.length; i++) {
            this.topupOption.peTopUp[i].id = 0;
          }
          this.preenrollmentService.updateTopupExtraBenefits(this.policy.id, this.topupOption);
        }
      }
    });
  }

  getPremiumDetails() {
    let rship;
    if (this.relationshipType === 'Self') {
      rship = 'self';
    } else if (this.relationshipType === 'Parent') {
      rship = 'parent';
    } else if (this.relationshipType === 'Parent-In-Law') {
      rship = 'parentinlaw';
    }
    this.preenrollmentService.getPremiumDetails(this.policy.id, rship).then(x => {
      if (x === null) {
        this.premiumDetails.isPremiumApplicable = false;
        this.premiumDetails.isPremiumVisible = false;
        this.premiumDetails.isRefundApplicable = false;
        this.premiumDetails.premiumType = null;
      } else {
        this.premiumDetails = x;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
