<app-popup-model
  title="Approve"
  [saveButton]="
    approveOrReject !== null && approveOrReject !== undefined ? approveOrReject.toUpperCase() : 'APPROVE/REJECT'
  "
  [isDisableSaveButton]="approveOrReject === null || approveOrReject === undefined || invalidRemark"
  (close)="close()"
  (save)="approveOrRejectFunc($event)"
>
  <label class="form-check-label w-100">
    <h5>Remarks</h5>
    <input type="text" class="form-control" (input)="validateRemark()" [(ngModel)]="remark" />
    <p [hidden]="!invalidRemark" class="error-msg mb-0">
      <small>{{ invalidRemarkMsg }}</small>
    </p>
  </label>

  <div class="form-group mb-0">
    <div class="form-control p-0 border-0">
      <!-- Material inline 1 -->
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" value="approve" [(ngModel)]="approveOrReject" />
          <span>Approve</span>
        </label>
      </div>

      <!-- Material inline 2 -->
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" value="reject" [(ngModel)]="approveOrReject" />
          <span>Reject</span>
        </label>
      </div>
    </div>
  </div>
</app-popup-model>
