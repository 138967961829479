import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpInterceptors } from './shared/http-interceptors/http-interceptors';
import { AlertService } from './shared/services/alert.service';
import { BackendService } from './shared/services/backend.service';
import { ErrorsHandler } from './shared/services/error-handler.service';
import { LoggerService } from './shared/services/logger.service';
import { SecuredStorageProviderService } from './shared/services/secured-storage-provider.service';
import { SharedModule } from './shared/shared.module';
import { BusinessAdminModule } from './business-admin/business-admin.module';
import { GeoService } from './shared/services/geo.service';
import { MemberModule } from './member/member.module';
import { MaterialModule } from './material/material.module';
import { DyanamicFieldsFormModule } from './dyanamic-fields-form/dyanamic-fields-form.module';
import { BnNgIdleService } from 'bn-ng-idle';
import { CpMemberModule } from './cp-member/cp-member.module';
import { BrokerModule } from './broker/broker.module';
import { BrokerRoutingModule } from './broker/broker-routing.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    SharedModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    AppRoutingModule,
    SharedModule,
    BusinessAdminModule,
    CpMemberModule,
    MemberModule,
    MaterialModule,
    BrokerModule,
    ReactiveFormsModule,
    DyanamicFieldsFormModule,
  ],
  providers: [
    AlertService,
    BackendService,
    ErrorsHandler,
    LoggerService,
    GeoService,
    SecuredStorageProviderService,
    BnNgIdleService,
    { provide: ErrorHandler, useClass: ErrorsHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptors, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
