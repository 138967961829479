import { NgModule, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListCorporateComponent } from './components/list-corporate/list-corporate.component';
import { CreateCorporateComponent } from './components/create-corporate/create-corporate.component';
import { CorporateDetailsComponent } from './components/corporate-details/corporate-details.component';
import { AuthGuard } from '../shared/auth.guard';
import { PageNotFoundComponent } from '../shared/components/page-not-found/page-not-found.component';
import { NotificationComponent } from './components/notification/notification.component';
import { CreateNotificationComponent } from './components/notification/create-notification/create-notification.component';
import { ConfigurePremiumComponent } from './components/pre-enrollment/premium-calculator/configure-premium/configure-premium.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { roles: ['BusinessAdmin'] },
    children: [
      { path: 'corporate/list', component: ListCorporateComponent },
      { path: 'corporate/create', component: CreateCorporateComponent },
      { path: 'corporate/:corporateId', component: CorporateDetailsComponent },
      { path: 'notification/list', component: NotificationComponent },
      { path: 'notification/create', component: CreateNotificationComponent },
      { path: '', redirectTo: 'corporate/list', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BusinessAdminRoutingModule {}
