<app-popup-model
  [title]="title"
  [saveButton]="saveButtonText"
  [isDisableSaveButton]="!isMemberDetailsFound"
  (close)="close()"
  (save)="save($event)"
  [showLoader]="showLoader"
>
  <div class="error-msg" *ngFor="let error of errorList">
    <p>{{ error.message }}</p>
  </div>
  <div class="row" [hidden]="currentStep !== 'firstStep'">
    <div class="col-12">
      <div class="form-group">
        <label>Employee Id</label>
        <input
          type="text"
          (blur)="searchMemberWithEmpId($event)"
          [disabled]="disableEmpId"
          (input)="checkEmpIdValidity($event)"
          [(ngModel)]="empId"
          class="form-control"
        />
        <div class="dropdown select-hr-dropdown">
          <button
            class="form-control text-left dropdown-toggle"
            *ngIf="empIdSearchList.length > 0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <span *ngIf="searchHrDetails.name == undefined">Select Member</span>
            <span *ngIf="searchHrDetails.name != undefined"
              >{{ searchHrDetails.name }} - {{ searchHrDetails.policyNo }}</span
            >
          </button>
          <div class="dropdown-menu">
            <div *ngFor="let hr of empIdSearchList">
              <a class="dropdown-item" (click)="onEmpidDropdownChanged(hr)">
                <span *ngIf="hr.isActive" class="tag tag-active"></span>
                <span *ngIf="!hr.isActive" class="tag tag-inactive"></span>
                <span>{{ hr.insuredName }} - {{ hr.policyNo }}</span>
              </a>
            </div>
          </div>
        </div>
        <p [hidden]="isCodeValid" class="error-msg mb-0">
          <small>{{ invalidCodeMsg }}</small>
        </p>
        <!-- <p [hidden]="isMemberFound" class="error-msg mb-0">
          <small>{{ memberNotFoundMsg }}</small>
        </p> -->
      </div>
    </div>
    <div class="col-12" *ngIf="title === 'Create HR'">
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          [(ngModel)]="hr.name"
          [disabled]="disableName"
          (blur)="getHrByName($event)"
          (input)="checkNameValidity($event)"
          class="form-control"
        />
        <div class="dropdown select-hr-dropdown">
          <button
            class="form-control text-left mt-2 dropdown-toggle"
            *ngIf="hrSearchList.length > 0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <span *ngIf="searchHrDetails.name == undefined">Select Member</span>
            <span *ngIf="searchHrDetails.name != undefined"
              >{{ searchHrDetails.name }} - {{ searchHrDetails.policyNo }}</span
            >
          </button>
          <div class="dropdown-menu">
            <div *ngFor="let hr of hrSearchList">
              <a class="dropdown-item" (click)="onNameDropdownChanged(hr)">
                <span *ngIf="hr.isActive" class="tag tag-active"></span>
                <span *ngIf="!hr.isActive" class="tag tag-inactive"></span>
                <span>{{ hr.insuredName }} - {{ hr.policyNo }}</span>
              </a>
            </div>
          </div>
        </div>
        <p [hidden]="isNameValid" class="error-msg mb-0">
          <small>{{ invalidNameMsg }}</small>
        </p>
      </div>
    </div>
    <div class="col-12" *ngIf="title === 'Edit HR'">
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          [(ngModel)]="hr.name"
          [disabled]="disableName"
          (input)="checkNameValidity($event)"
          class="form-control"
        />
        <p [hidden]="isNameValid" class="error-msg mb-0">
          <small>{{ invalidNameMsg }}</small>
        </p>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label>Email</label>
        <input
          type="text"
          [(ngModel)]="hr.email"
          [disabled]="disableEmail"
          (input)="checkEmailValidity($event)"
          class="form-control"
        />
        <p [hidden]="isEmailValid" class="error-msg mb-0">
          <small>{{ invalidEmailMsg }}</small>
        </p>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label>Mobile Number</label>
        <input
          type="number"
          [(ngModel)]="hr.mobileNo"
          [disabled]="disableMobileNo"
          (input)="checkMobileNoValidity($event)"
          class="form-control"
        />
        <p [hidden]="isMobileNoValid" class="error-msg mb-0">
          <small>{{ invalidMobileMsg }}</small>
        </p>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label>Policy<sup>*</sup></label>
        <div class="dropdown select-hr-dropdown">
          <button
            class="form-control text-left mt-2 dropdown-toggle"
            *ngIf="policies.length > 0"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <span *ngIf="selectedPolicy == undefined">Select Policy</span>
            <span *ngIf="selectedPolicy != undefined && selectedPolicy.isActive" class="logged"></span>
            <span *ngIf="selectedPolicy != undefined && !selectedPolicy.isActive" class="not-logged"></span>
            <span *ngIf="selectedPolicy != undefined">{{ selectedPolicy.policyNumber }}</span>
          </button>
          <div class="dropdown-menu">
            <div *ngFor="let policy of policies">
              <a class="dropdown-item" (click)="onPolicyDropdownChanged(policy)">
                <span *ngIf="policy.isActive" class="tag tag-active"></span>
                <span *ngIf="!policy.isActive" class="tag tag-inactive"></span>
                <span>{{ policy.policyNumber }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <!-- <label>Comm Code*</label>
        <div [hidden]="!isUpdatingHr && currentStep !== 'secondStep'">
          <span *ngFor="let cc of exisitngcommCodes"> {{ cc }}</span>
        </div> -->
        <!-- <div>
          <select [(ngModel)]="selectedCommCode" (ngModelChange)="onCommCodeSelected($event)" class="form-control"
            multiple>
            <option *ngFor="let cc of commCodes" [ngValue]="cc">
              <div class="selected" *ngIf="exisitngcommCodes.includes(cc)">{{ cc }}</div>
              <div class="notselected" *ngIf="!exisitngcommCodes.includes(cc)">{{ cc }}</div>
            </option>
          </select>
        </div> -->
        <div *ngIf="!isCommCodesLoaded && selectedPolicy !== undefined" class="spinner-center-wrapper">
          <div class="spinner-center"></div>
        </div>
        <div *ngIf="isCommCodesLoaded && selectedPolicy !== undefined">
          <ng-multiselect-dropdown
            [placeholder]="'Communication Code'"
            [data]="commCodes"
            [(ngModel)]="selectedCommCodeForCurrentPolicy"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="onDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div [hidden]="currentStep !== 'secondStep'">
    <app-privileges
      [user]="hr"
      (isFormValid)="isMemberDetailsFound = $event"
      (currentStep)="currentStep = $event"
      (saveButtonText)="saveButtonText = $event"
    ></app-privileges>
  </div>
</app-popup-model>
