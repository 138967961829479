<div class="edit-profile-component-wrapper">
  <!-- Spinner -->
  <div *ngIf="isLoading" class="spinner-center-wrapper">
    <div class="spinner-center"></div>
  </div>

  <div *ngIf="!isLoading" class="card w-100 bg-white">
    <div class="card-body pt-0">
      <div class="row">
        <div class="col">
          <div class="form" *ngIf="policy.allowEmployeeEditProfile && isPolicyStatusActive">
            <dynamic-form [fields]="regConfig" (submit)="submit($event)" submitButtonName="Save Profile">
            </dynamic-form>
            <div class="margin-top"></div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!policy.allowEmployeeEditProfile">
        <h3 class="no-permission-msg">Policy do not allow you to edit your profile</h3>
      </div>
      <div class="row" *ngIf="!isPolicyStatusActive">
        <h3 class="no-permission-msg">You cannot edit your Profile as period is not active</h3>
      </div>
    </div>
  </div>
</div>
