<app-popup-model
  title="Attach Policy"
  saveButton="Add"
  [isDisableSaveButton]="!attachPolicyForm.valid"
  (close)="close()"
  (save)="save($event)"
  [showLoader]="showLoader"
>
  <div class="error-msg" *ngFor="let error of errorList">
    <p>{{ error.message }}</p>
  </div>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="attachPolicyForm">
        <div class="col-md-12">
          <div class="form-group">
            <label>Policy Number<sup>*</sup></label>
            <input
              type="text"
              class="form-control"
              (input)="setPolicyDetailsNull()"
              (blur)="searchPolicyNumber($event)"
              formControlName="policyNumber"
            />
            <span
              class="error-msg small"
              *ngIf="
                attachPolicyForm.get('policyNumber').dirty && attachPolicyForm.get('policyNumber').status === 'INVALID'
              "
              >Invalid Policy Number <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Insured Name<sup>*</sup></label>
            <input type="text" class="form-control" readonly disabled="true" formControlName="insuredName" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Risk Start Date<sup>*</sup></label>
            <input
              value="{{ date2 | date: 'dd MMMM yyyy' }}"
              type="text"
              class="form-control"
              readonly
              disabled="true"
              bsDatepicker
              formControlName="riskStartDate"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Risk End Date<sup>*</sup></label>
            <input
              value="{{ date2 | date: 'dd MMMM yyyy' }}"
              type="text"
              class="form-control"
              readonly
              disabled="true"
              bsDatepicker
              formControlName="riskEndDate"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Issue Date<sup>*</sup></label>
            <input
              value="{{ date2 | date: 'dd MMMM yyyy' }}"
              type="text"
              class="form-control"
              readonly
              disabled="true"
              bsDatepicker
              formControlName="issueDate"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Grace Period Type</label>
            <select class="form-control" (change)="periodTypeChanged()" formControlName="gracePeriodType">
              <option *ngFor="let item of periodTypes" [value]="item">{{ item }}(s)</option>
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Grace Period Value</label>
            <select class="form-control" formControlName="gracePeriodValue">
              <option *ngFor="let item of periodValues" [value]="item"
                >{{ item }} {{ attachPolicyForm.get('gracePeriodType').value }}</option
              >
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Claim SPOC Available</label>
            <div class="custom-checkbox">
              <label>
                <input
                  type="checkbox"
                  class="custom-control-input" formControlName="claimSpoc" #claimSPOC
                />
                <span></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="claimSPOC.checked">
          <div class="form-group">
            <label>Claim SPOC EmailID<sup>*</sup></label>
            <input type="text" class="form-control" formControlName="claimSpocMailId" />
            <span
              class="error-msg small"
              *ngIf="
                attachPolicyForm.get('claimSpocMailId').dirty && attachPolicyForm.get('claimSpocMailId').status === 'INVALID'
              "
              >Invalid Email ID<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Display Policy Name</label>
            <input type="text" class="form-control" formControlName="onscreenDisplay"
            [maxLength]="30" />
            <!-- <span
              class="error-msg small"
              *ngIf="
                attachPolicyForm.get('onscreenDisplay').dirty && attachPolicyForm.get('onscreenDisplay').status === 'INVALID'
              "
              >Invalid Display Policy Name<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span> -->
            <span
              class="error-msg small"
              *ngIf="(attachPolicyForm.get('onscreenDisplay').dirty && attachPolicyForm.controls['onscreenDisplay'].errors?.pattern)"
              >Special characters not allowed<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
            <span
              class="error-msg small"
              *ngIf="(attachPolicyForm.get('onscreenDisplay').dirty 
                    && attachPolicyForm.controls['onscreenDisplay'].errors?.pattern
                    && attachPolicyForm.controls['onscreenDisplay'].errors?.maxlength)"
              > and <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
            <span
              class="error-msg small"
              *ngIf="(attachPolicyForm.get('onscreenDisplay').dirty && attachPolicyForm.controls['onscreenDisplay'].errors?.maxlength)"
              >Maximum 30 characters allowed<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</app-popup-model>
