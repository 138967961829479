export class PeGradeSiModel {
  grade: string;
  sumInsured: number;
  isValueInvalid: boolean;
  isGradeInvalid: boolean;

  constructor(model) {
    Object.assign(this, model);
  }

  isValid(type) {
    if (type === 'Defined' || type === 'GradeWiseDefined') {
      if (this.sumInsured !== undefined) {
        const insuredIntValue = Number(this.sumInsured);
        const isFloat = Number(insuredIntValue) === insuredIntValue && insuredIntValue % 1 !== 0;
        if (isFloat) {
          this.isValueInvalid = true;
        } else {
          this.isValueInvalid =
            !insuredIntValue || isNaN(insuredIntValue) || Number(insuredIntValue.toString().trim()) <= 0;
        }

        if (!this.isValueInvalid) {
          this.sumInsured = Number(this.sumInsured);
        }
      } else {
        this.isValueInvalid = true;
      }
    }

    if (type === 'GradeWiseMultiples' || type === 'GradeWiseDefined') {
      this.isGradeInvalid = !this.grade;
    }
    return !(this.isValueInvalid || this.isGradeInvalid);
  }
}
