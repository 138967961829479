import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (value === '' || value === undefined) {
      return;
    } else if (isNaN(Number(value.replace(/,/g, '')))) {
      return value;
    } else {
      try {
        const x = value.replace(/,/g, '');
        return Number(x).toLocaleString('en-IN');
      } catch (e) {}
    }
  }
}
