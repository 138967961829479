<div class="gpa-wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-6 col-md-8 col-lg-8">
                <div class="card card-style-1">
                    <div class="card-body pb-0">
                        <div class="card-title row justify-content-between align-items-center">
                            <h4 class="m-0 header-title">{{ intimationType }} Claim Intimation</h4>
                        </div>
                        <div>
                            <div>
                            <h5 class="text-left fw-light fs-5" style="padding-left:15px;padding-top: 10px;">Please share a few details</h5>
                            </div>
                            <form [formGroup]="MailForm">
                                <div class="form contact-form-wrapper">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                          <label>Email<sup>*</sup></label>
                                          <input class="form-control" type="text"
                                          formControlName="emailId"
                                          />
                                          <span
                                          class="error-msg small"
                                          *ngIf="
                                            MailForm.get('emailId').dirty && MailForm.get('emailId').status === 'INVALID'
                                          "
                                          >Invalid Email<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                        </span>
                                          <!-- <span
                                            class="error-msg small"
                                            [hidden]="hidEmailError"
                                            >Invalid Email
                                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                          </span> -->
                                        </div>
                                    </div>
                                  <div class="col-sm-12">
                                    <div class="form-group">
                                      <label>Claim Intimation For<sup>*</sup></label>
                                      <input class="form-control" type="text" 
                                      formControlName="subject" 
                                         required/>
                                         <span
                                          class="error-msg small"
                                          *ngIf="
                                            MailForm.get('subject').dirty && MailForm.get('subject').status === 'INVALID'
                                          "
                                          >Invalid subject(Mininmum 1 char & Maximum is 255 char)<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                        </span>
                                         <!-- <span
                                        class="error-msg small"
                                        [hidden]="hidSubjectError"
                                        >Invalid subject(Mininmum 1 char & Maximum is 255 char)
                                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                      </span> -->
                                    </div>
                                  </div>
                                  
                                  <div class="col-sm-12">
                                    <div class="form-group">
                                      <label>Description<sup>*</sup></label>
                                      <textarea class="form-control" rows="3" maxlength="4000" minlength="100" 
                                       formControlName="message" required></textarea>
                                      <span
                                          class="error-msg small"
                                          *ngIf="
                                            MailForm.get('message').dirty && MailForm.get('message').status === 'INVALID'
                                          "
                                          >Invalid Description(Mininmum 100 char & Maximum is 4000 char)<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                        </span>
                                      <!-- <span[(ngModel)]="gpaMail.message"
                                        class="error-msg small"
                                        [hidden]="hidMessageError"
                                        >Invalid Description(Mininmum 10 char & Maximum is 4000 char)
                                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                      </span> -->
                                    </div>
                                  </div>
                                  <div class="col-sm-12">
                                    <div class="form-group">
                                      <label>Mobile Number<sup>*</sup></label>
                                      <input class="form-control" type="text"
                                        formControlName="mobileNumber"
                                          required/> <!--[disabled]="gpaMail.mobileNumber!=''"-->
                                          <span
                                          class="error-msg small"
                                          *ngIf="
                                            MailForm.get('mobileNumber').dirty && MailForm.get('mobileNumber').status === 'INVALID'
                                          "
                                          >Invalid Mobile Number<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                        </span>
                                          <!-- <span[(ngModel)]="gpaMail.mobileNumber"
                                        class="error-msg small"
                                        [hidden]="hidNumError"
                                        >Invalid Mobile Number
                                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                      </span> -->
                                    </div>
                                  </div>
                                  <div class="col-sm-12">
                                    <div class="form-group">
                                      <button class="btn px-4 btn-sm btn-primary" type="button" (click)="fileInput.click()">
                                        <span>Attach File</span>
                                        <input
                                        #fileInput
                                        id="file"
                                        type="file"
                                        (change)="onFileChange($event.target.files)"
                                        style="display:none;" multiple
                                        accept=".jpg, .png, .jpeg, .pdf"
                                      />
                                        </button> 
                                        <p style="color: red;font-size: .7rem">(At least one document upload is mandatory)</p>
                                          <div class="row  mt-3">
                                        <div class="col-md-6"  *ngFor="let file of files | keyvalue">
                                        <div class="position-relative" style="padding-bottom: 5px;" >
                                          <input
                                            id="doc_para"
                                            type="text"
                                            class="form-control"
                                            [value]="file.key"
                                            placeholder=""
                                            readonly
                                          />
                                          <button id="doc_delete"  class="btn btn-sm remove-file-wrapper">
                                            <i class="material-icons" (click)="deleteFile(file.key)">delete</i>
                                          </button>
                                        </div>
                                        <span *ngIf="file.value!= undefined && file.value!==''" class="error-msg small" id="doc_invalid">
                                          {{ file.value }}
                                        </span>
                                          </div>
                                      <div class="col-md-6"></div>
                                      </div>
                                      <span [hidden]="isValid" class="error-msg small" id="doc_invalid">
                                        {{ uploadImageInvalidMsg }}
                                      </span>
                                        
                                        </div>
                                  </div>

                                  <div class="col-sm-12">
                                    <div class="spinner-border text-primary mr-3" style="width: 10%;" [hidden]="!isLoading" *ngIf="isLoading"></div>
                                    <button type="submit"
                                      class="btn btn-primary mt-1 w-100"
                                      [disabled]="!isSizeValid || disableSubmitButton || isLoading || !MailForm.valid" 
                                      [hidden]="isLoading"data-toggle="modal"
                                      data-target="#submit_Popup"
                                      (click)="emailSubmit()"
                                    >   <!--!form.valid ||-->
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Date Modal-->
    <div id="submit_Popup"
    class="modal fade" *ngIf="showModalBox"
    tabindex="-1"
    role="dialog"
    aria-labelledby="submit_Popup"
    aria-hidden="true"
    data-keyboard="false">
      <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Intimate {{ intimationType }}<small></small></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="OK()" >
            <span aria-hidden="true">&times;</span>
          </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="form-group">
                <!-- Modal body -->
        <div class="modal-body">
          Thank you, we shall process claim and update with intimation number shortly!!
        </div>
              </div>
              <div style="text-align: center;">
                <button (click)="OK()" data-dismiss="modal" type="button"  class="btn btn-secondary applybutton">Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</div>
