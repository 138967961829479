<div class="attendace-wrapper pb-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-6 col-md-8 col-lg-8">
                <div class="card w-100 card-style-1">
                    <div class="card-body pb-0">
                        <div class="card-title row justify-content-between align-items-center">
                            <h4 class="m-0 header-title">Policy Details</h4>
                        </div>
                        <div class="row">
                            
                            <div class="col-12 policy-details">
                                <span class="m-0 policy-name">Reliance Group Health Insurance Policy</span>
                                <p class="m-0 policy-notice">This policy covers you only on active days</p>
                                <div class="row">
                                <div class="col-md-6 col-lg-6" >
                                <ul class="inner-cards profile-details">
                                    
                                    
                                    <li>
                                      <ul style="padding-left:0%;flex-direction: inline;">
                                        <li>                            
                                          <span class="field-name mr-2">Period:</span>
                                          <span class="field-data">{{ currentPolicy.riskStartDate | date:'d MMM, y'}} ------- {{ currentPolicy.riskEndDate | date:'d MMM, y'}}</span>
                                          </li>
                                      <li>
                                <span class="field-name  mr-2">Policy Number:</span>
                                <span class="field-data">{{ currentPolicy.policyNumber }}</span>
                                      </li>
                              <li>
                                <span class="field-name  mr-2">Policy holder name:</span>
                                <span class="field-data">{{ self.insuredName }}</span>
                              </li>
                              <li>
                                <span class="field-name  mr-2">Abha ID:</span>
                                <span class="field-data">{{ self.insuredABHAId }}</span>
                              </li>
                            </ul>
                                </li>
                                </ul>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                <ul class="inner-cards profile-details">
                                    <li class="download-policy">
                                      <span class="field-name mr-2">Email ID:</span>
                                      <span class="field-data">{{ self.emailID }}</span>
                                    </li>
                                    <li>
                                      <span class="field-name mr-2">Mobile No</span>
                                      <span class="field-data">{{ self.mobileNo }}</span>
                                    </li>
                                    <li>
                                      <span class="field-name mr-2">UHID</span>
                                      <span class="field-data">{{ self.uhid }}</span>
                                    </li>
                                    <li>
                                      <span class="field-name  mr-2">Abha Address:</span>
                                      <span class="field-data">{{ self.insuredABHAAddress }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                            </div>

                            <!-- <div class="col-6 inner-cards mt-2">
                                <span>Dowload Policy</span>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="card w-100 card-style-1">
                    <div class="card-body pb-0">
                        <div class="card-title row justify-content-between align-items-center">
                            <h4 class="m-0 header-title">Active Sessions</h4>
                            <h4 class="m-0 header-title align-items-right">
                              <button class="btn btn-sm dateslot" data-toggle="modal"
                              data-target="#selectDate_Popup"> Select date slot &nbsp;&nbsp;<span class="arrow down"></span> </button>
                              
                            </h4>
                        </div>
                        <div class="container">
                            <div class="list-attendance-component-body">
                              <span [hidden]="isLoading" *ngIf="!attendances || attendances.length === 0">No Record found!!</span>
                                <table>
                                    <tbody>
                                        <tr [hidden]="!isLoading">                                          
                                          <td>
                                            <div class="shimmer-wrapper">
                                              <div class="shimmer secondary"></div>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="shimmer-wrapper">
                                              <div class="shimmer secondary"></div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr [hidden]="!isLoading">
                                          <td>
                                            <div class="shimmer-wrapper">
                                              <div class="shimmer secondary"></div>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="shimmer-wrapper">
                                              <div class="shimmer secondary"></div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tbody *ngIf="attendances">
                                        <tr *ngFor="let attendance of attendances" [hidden]="isLoading || attendances.length === 0">
                                          <td>
                                            {{ attendance.Date_Range }}
                                          </td>
                                          <td>
                                            {{ attendance.Policy_plan }}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="3" class="border-0 p-0">
                                            <div class="pagination-wrapper">
                                              <ngb-pagination
                                                [collectionSize]="totalAttendanceCount"
                                                [pageSize]="pageSize"
                                                [(page)]="page"
                                                [maxSize]="5"
                                                (pageChange)="loadPage($event)"
                                                [boundaryLinks]="true"
                                                [rotate]="true"
                                                [hidden]="attendances.length === 0 || isLoading || hidePagignation"
                                              ></ngb-pagination>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xs-6 col-md-4 col-lg-4">
                <div class="card card-style-1 w-100">
                  <div class="card-body pb-0">
                    <div class="card-title row justify-content-between align-items-center">
                        <span class="m-0 header-title">Cashless Card</span>
                    </div>
                    <div class="container">
                      <div>
                      <span class="header-title">{{ self.insuredName }}</span>
                    </div>
                    <div class="policy-details">
                      <ul class="inner-cards profile-details">
                        <li>
                          <span class="field-name mr-2">Period:</span>
                                    <span class="field-data">{{ currentPolicy.riskStartDate }} ------- {{ currentPolicy.riskEndDate }}</span>
                        </li>
                        <li>
                          <div>
                          <span class="field-name mr-2">Policy Number</span>
                          <span class="field-data">{{ currentPolicy.policyNumber }}</span>
                        </div>
                        <div>
                          <span class="field-name mr-2">UHID</span>
                          <span class="field-data">{{ self.uhid }}</span>
                        </div>
                        </li>
                      </ul>
                    </div>
                    </div>
                  </div>
                </div>

                <div class="card w-100 card-style-1">
                  <div class="card-body pb-0">
                    <div class="card-title row justify-content-between align-items-center">
                      <span class="m-0 header-title">Policy Details</span>
                    </div>
                    <div class="container">
                      <div class="col-12 policy-details">
                        <div class="row">
                        
                        <ul class="inner-cards profile-details">
                          <li>                            
                            <span class="field-name mr-2">Policy Type</span>
                            <span class="field-data"></span>
                            </li>
                            <li>                            
                            <span class="field-name mr-2">Policy Duration</span>
                            <span class="field-data">{{ currentPolicy.riskStartDate }} ------- {{ currentPolicy.riskEndDate }}</span>
                            </li>
                            <li>                            
                              <span class="field-name mr-2">Policy holder name</span>
                              <span class="field-data">{{ self.insuredName }}</span>
                            </li>
                            <li>                            
                              <span class="field-name mr-2">Mobile Number</span>
                              <span class="field-data">{{ self.mobileNo }}</span>
                            </li>
                            <li>                            
                              <span class="field-name mr-2">Email Address</span>
                              <span class="field-data">{{ self.emailID }}</span>
                            </li>
                        </ul>
                        
                    </div>
                </div>
                    </div>
                    </div>
                  </div>
                </div> -->
            </div>
    </div>

    <!--Date Modal-->
    <div id="selectDate_Popup"
    class="modal fade" #selectDate_Popup
    tabindex="-1"
    role="dialog"
    aria-labelledby="selectDate_Popup"
    aria-hidden="true"
    data-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select Date Slot<small></small></h5>
          <button type="button" id="closeModalButton" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="form-group">
                <label>Date From <sup>*</sup></label>
                <input
                  [(ngModel)]="startDate"
                  placeholder="Choose Date"
                  type="text"
                  class="form-control"
                  [maxDate]="endDate"
                  bsDatepicker
                  readonly
                  
                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                />
                <!-- <div class="errorMsg" [hidden]="!policyStartDateInvalid">
                  Policy start date should be set to a future date
                </div> (ngModelChange)="startDateChanged()" -->
              </div>

              <div class="form-group">
                <label>To Date <sup>*</sup></label>
                <input
                  [minDate]="startDate"
                  placeholder="Choose Date"
                  type="text"
                  class="form-control"
                  bsDatepicker
                  [(ngModel)]="endDate"
                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMMM YYYY' }"
                />  <!--value="{{ policy.endDate | date: 'dd MMMM yyyy' }}" [minDate]="startDate"-->
                <div class="errorMsg" [hidden]="!EndDateInvalid">
                  Policy end date should be set to a date equal or greater than policy start date
                </div>
              </div>

              <div style="text-align: center;">
                <button (click)="GetDatesSession()" data-dismiss="modal" class="btn btn-secondary applybutton">Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

