import { IntimationStage } from './intimation-stage.model';
import { DocumentationStage } from './documentation-stage.model';
import { RegistrationStage } from './registration-stage.model';
import { ClosureStage } from './closure-stage.model';
import { RejectionStage } from './rejection-stage.model';
import { SettlementStage } from './settlement-stage.model';
import { ApprovalStage } from './approval-stage.model';
import { QueriedStage } from './queried-stage.model';
import { MedicalScrutinyStage } from './medical-scrutiny-stage.model';
import { PolicyVerificationStage } from './policy-verification-stage.model';

export class ClaimJourney {
  claimType: string;
  isClaimValid: boolean;
  claimStatus: string;
  s1 = new IntimationStage();
  s2 = new DocumentationStage();
  s3 = new RegistrationStage();
  s4 = new PolicyVerificationStage();
  s5 = new MedicalScrutinyStage();
  s6 = new QueriedStage();
  s7 = new ApprovalStage();
  s8 = new SettlementStage();
  s9 = new RejectionStage();
  s10 = new ClosureStage();
}
