import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { RoleService } from 'src/app/shared/services/role.service';
import { HRService } from 'src/app/member/services/hr.service';
import { HRProfile } from 'src/app/cp-member/models/hr.profile.model';
import { CPMemberService } from 'src/app/cp-member/services/CPMember.service';
import { BrokerService } from 'src/app/broker/service/broker.service';
import { CPPolicyService } from '../../services/cp-policy.service';
import { Broker } from 'src/app/shared/models/CorporatePortal/broker.model';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { Policy } from 'src/app/cp-member/models/policy.model';
import { SMService } from 'src/app/sm/service/sm.service';
import { Sm } from 'src/app/shared/models/CorporatePortal/sm.model';
import { OperationService } from 'src/app/operation/services/operation.service';

@Component({
  selector: 'app-cp-side-bar',
  templateUrl: './cp-side-bar.component.html',
  styleUrls: ['./cp-side-bar.component.scss'],
})
export class CpSideBarComponent implements OnInit {
  name: string;
  userRole: string;
  routeByRole: string;

  currentPolicy: Policy = new Policy();
  policies = [];

  // HR Member
  hr: HRProfile = new HRProfile();
  selfMember: any;
  isRoleHR = false;
  renderedOn: string;
  isHrView = false;
  isHr: boolean;
  isMember = false;
  unseenNotificationCount = 0;
  policyNumber: any;

  // Broker
  brokerPrivileges: Broker = new Broker();

  // SM
  smPrivileges: Sm = new Sm();

  //Operation

  email = '';

  constructor(
    private authService: AuthService,
    private roleService: RoleService,
    private cpMemberService: CPMemberService,
    private brokerService: BrokerService,
    private smService: SMService,
    private policyService: CPPolicyService,
    private hrService: HRService,
    private operationService: OperationService
  ) {}

  async ngOnInit() {
    this.userRole = this.authService.role;
    this.authService.userName.subscribe(name => (this.name = name));
    //debugger;
    if (this.userRole === Roles.CPHR || this.userRole === Roles.CPMember) {
      await this.policyService.getMemberHrPolicies().then(p => {
        const unsortedPolicies = p[0].policies;
        const sortedPolicies = this.sortPolicies(unsortedPolicies);
        this.policyService.allPolicies.next(sortedPolicies);
        this.policyService.policies.next(sortedPolicies);
        this.policyService.currentPolicy.next(sortedPolicies[0]);
      });

      this.policyService.currentPolicy.subscribe(async (policy: any) => {
        this.currentPolicy = policy;
        this.policyNumber = this.currentPolicy.policyNumber;
        console.log('polic number is', this.policyNumber);
      });

      await this.cpMemberService
        .getMemberFamily(this.policyNumber)
        .then(x => {
          console.log(x);
        })
        .catch(x => {
          console.log('error');
        });
    }

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.isHr = this.cpMemberService.isHr;
      this.isMember = this.cpMemberService.isMember;
    });

    if (this.isHr && this.isMember) {
      this.isHrView = true;
    }

    if (this.userRole === Roles.CPHR || this.userRole === Roles.CPMember) {
      this.routeByRole = 'cp-member';
      this.memberHRSidebarInit();
    } else if (this.userRole === Roles.Broker) {
      this.routeByRole = 'broker';
      this.brokerSideBarInit();
    } else if (this.userRole === Roles.SM) {
      this.routeByRole = 'sm';
      this.smSideBarInit();
    } else if (this.userRole === Roles.Operation) {
      this.routeByRole = 'operation';
    }
    this.getMemberDetails();
  }

  sortPolicies(policies) {
    policies.sort(function compare(a, b) {
      const dateA = new Date(a.riskStartDate);
      const dateB = new Date(b.riskStartDate);
      return <any>dateB - <any>dateA;
    });
    policies.sort(policy => {
      return policy.isActive === false ? 0 : policy ? -1 : 1;
    });
    const activePolicyArray = [];
    const inactivePolicyArray = [];
    policies.forEach(policy => {
      if (policy.isActive) {
        activePolicyArray.push(policy);
      } else {
        inactivePolicyArray.push(policy);
      }
    });
    activePolicyArray.reverse();
    policies = [];
    policies = [...activePolicyArray, ...inactivePolicyArray];

    return policies;
  }

  async getMemberDetails() {
    try {
      this.cpMemberService.selfMemberDetails.subscribe(m => {
        this.selfMember = m;
      });
    } catch (error) {
      console.log(error);
    }
  }

  async operationSidebarInit() {
    this.operationService.operationProfile.subscribe(res => {
      this.name = res.name;
      this.email = res.email;
    });
  }

  async brokerSideBarInit() {
    this.brokerService.brokerProfile.subscribe(x => {
      this.name = x.name;
      this.email = x.email;
    });

    this.policyService.currentPolicy.subscribe(async p => {
      this.currentPolicy = p;
      if (p.policyId !== undefined) {
        await this.brokerService.getBrokerByPolicy(p.policyId).then(x => {
          this.brokerPrivileges = x;
        });
      }
    });
  }

  async smSideBarInit() {
    this.smService.smProfile.subscribe(x => {
      this.name = x.name;
      this.email = x.email;
    });

    this.policyService.currentPolicy.subscribe(async p => {
      this.currentPolicy = p;
      if (p.policyId !== undefined) {
        await this.smService.getSMByPolicy(p.policyId).then(x => {
          this.smPrivileges = x;
        });
      }
    });
  }

  async memberHRSidebarInit() {
    let selfMember;
    this.policyService.currentPolicy.subscribe(async p => {
      this.currentPolicy = p;
    });
    //debugger;
    this.cpMemberService.selfMemberDetails.subscribe(member => {
      selfMember = member;
    });

    this.cpMemberService.hrDetails.subscribe(m => {
      this.hr = m;
      //debugger;
      this.name = m.name;
      this.email = m.email;
      if (this.name === undefined) {
        this.name = selfMember.insuredName;
        this.email = selfMember.emailID;
      }
    });

    this.cpMemberService.isHrView.subscribe(v => {
      this.isHrView = v;
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.currentPolicy = policy;
      if (this.isHrView) {
        await this.cpMemberService.GetUnSeenHrNotificationCount().then(x => {
          this.unseenNotificationCount = x.count;
        });
      } else {
        await this.cpMemberService.GetUnSeenMemberNotificationCount().then(x => {
          this.unseenNotificationCount = x.count;
        });
      }
    });
    if (navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)/i)) {
      this.renderedOn = 'Mobile';
    } else {
      this.renderedOn = 'Desktop';
    }
  }

  async displayInsuredName() {}

  async signout() {
    await this.authService.signout();
  }

  closeSidebarMobile(event) {
    if (this.renderedOn === 'Mobile') {
      //this.preEnrollmentMemberService.closedSideBarEventUpdate.next(event);
      event.currentTarget.offsetParent.parentElement.offsetParent.classList.remove('close-sidebar');
      event.currentTarget.offsetParent.parentElement.offsetParent.firstChild.classList.remove('closed');
    }
  }

  IntimateGPAM(type: string) {
    this.policyService.intimationType.next(type);
    console.log('Current intimation type is:- ' + type);
  }

  toggleSidebar(event) {
    console.log('hello');
  }

  setMemberViewType() {
    this.cpMemberService.isHrView.next(this.isHrView);
    this.policies = [];
    this.policyService.policies.next([]);
    this.policyService.allPolicies.value.forEach(x => {
      if (x.isMemberEnrolled) {
        this.policies.push(x);
      }
    });
    this.policyService.policies.next(this.policies);

    this.currentPolicy = this.policies.find(x => x.isMemberEnrolled);
    this.policyService.currentPolicy.next(this.currentPolicy);
    this.email = this.selfMember.emailID;
  }

  setHrViewType() {
    this.cpMemberService.isHrView.next(this.isHrView);
    this.policies = [];
    this.policyService.policies.next([]);
    this.policyService.allPolicies.value.forEach(x => {
      this.policies.push(x);
    });
    this.policyService.policies.next(this.policies);
    this.currentPolicy = this.policies[0];
    this.policyService.currentPolicy.next(this.currentPolicy);
  }

  setViewType() {
    this.isHrView = !this.isHrView;
    if (this.isHrView) {
      this.setHrViewType();
    } else {
      this.setMemberViewType();
    }
  }
}
