<div class="pre-enrollment-component-wrapper">
  <div *ngIf="!initialized" class="spinner-center-wrapper">
    <div class="spinner-center"></div>
  </div>
  <div class="pre-enrollment-component-header" *ngIf="initialized && selectedPolicy && !addNewPolicyRequest">
    <div class="container position-relative">
      <nav>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="Pre-enrollment-members-tab"
              data-toggle="tab"
              href="#Pre-enrollment-members"
              role="tab"
              aria-controls="Pre-enrollment-members"
              aria-selected="true"
            >
              Members
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Pre-enrollment-periods-tab"
              data-toggle="tab"
              href="#Pre-enrollment-periods"
              role="tab"
              aria-controls="Pre-enrollment-periods"
              aria-selected="false"
            >
              Periods
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Pre-enrollment-policies-tab"
              data-toggle="tab"
              href="#Pre-enrollment-policies"
              role="tab"
              aria-controls="Pre-enrollment-policies"
              aria-selected="false"
            >
              Configure
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Premium-calculator-tab"
              data-toggle="tab"
              href="#Premium-calculator"
              role="tab"
              aria-controls="Premium-calculator"
              aria-selected="false"
            >
              SI & Premium Calculator
            </a>
          </li>
        </ul>
      </nav>

      <div class="policy-dropdown">
        <div class="input-group">
          <label>Select policy</label>
          <select class="form-control" [(ngModel)]="selectedPolicy">
            <option *ngFor="let policy of policies" [ngValue]="policy">
              {{ policy.name }}
            </option>
          </select>
        </div>
      </div>

      <div style="position: absolute;top: 6px;right: 80px;">
        <button (click)="addNewPolicyRequest = true" class="btn btn-primary">Add new policy</button>
        <div style="position: absolute;top: 0px;left: 140px;">
          <button (click)="detachPreEnrollmentPolicy()" class="btn btn-danger">
            <i class="material-icons">delete</i>
            <!-- <button (click)="detachPreEnrollmentPolicy()" class="btn btn-danger" [disabled]="deletePolicyProcessing">
            Delete
            <mat-icon><mat-spinner color="warn" diameter="23" *ngIf="deletePolicyProcessing"> </mat-spinner></mat-icon> -->
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="pre-enrollment-component-body" *ngIf="initialized && selectedPolicy && !addNewPolicyRequest">
    <div class="">
      <div class="tab-content">
        <div class="tab-pane active" id="Pre-enrollment-members" role="tabpanel">
          <div class="container">
            <div class="form-box">
              <app-pre-enrollment-members
                [corporate]="corporate"
                [policy]="selectedPolicy"
              ></app-pre-enrollment-members>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="Pre-enrollment-periods" role="tabpanel">
          <div class="container">
            <div class="form-box">
              <app-pre-enrollment-periods
                [corporate]="corporate"
                [policy]="selectedPolicy"
                (closePeriodModal)="closePeriodModal($event)"
              ></app-pre-enrollment-periods>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="Pre-enrollment-policies" role="tabpanel">
          <div class="form-edit-box">
            <div class="container">
              <app-configure-pre-enrollment
                [corporate]="corporate"
                [policy]="selectedPolicy"
              ></app-configure-pre-enrollment>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="Premium-calculator" role="tabpanel">
          <div class="form-box">
            <div class="container">
              <app-premium-calculator
                [corporate]="corporate"
                [policy]="selectedPolicy"
                (closePeriodModal)="closePeriodModal($event)"
              ></app-premium-calculator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <a class="link-go-back" [hidden]="!addNewPolicyRequest" (click)="addNewPolicyRequest = false">
      <svg fill="none" height="11" viewBox="0 0 10 11" width="10" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M5.44212 1.56699C5.68619 1.32291 5.68619 0.927191 5.44212 0.683117C5.19804 0.439042 4.80232 0.439042 4.55824 0.683117L0.1833 5.05806C-0.0607745 5.30213 -0.0607745 5.69786 0.1833 5.94193L4.55824 10.3169C4.80232 10.561 5.19804 10.561 5.44212 10.3169C5.68619 10.0728 5.68619 9.67708 5.44212 9.433L2.1341 6.12498H9.37513C9.7203 6.12498 10.0001 5.84517 10.0001 5.49999C10.0001 5.15482 9.7203 4.875 9.37513 4.875H2.1341L5.44212 1.56699Z"
          fill-rule="evenodd"
        ></path>
      </svg>
      Cancel
    </a>
  </div>

  <app-create-pre-enrollment [corporate]="corporate" *ngIf="initialized && (!policies || addNewPolicyRequest)">
  </app-create-pre-enrollment>
</div>
